import React from 'react';
import {Card} from "../components/Card";
import {NavLink} from "react-router-dom";
import {Changelog} from "../components/Changelog";
import {SimulationRequestJson} from "../business/ApiJsonTypes";


interface SimulationCriteriaExample {
    name: string,
    criteria: SimulationRequestJson
}

const examples: SimulationCriteriaExample[] = [
    // {
    //
    //     name: "Typowa udana symulacja wirnika wraz z mechaniką",
    //     criteria: {
    //         fanSelectionCriteria: {
    //             airDensity: 1.115,
    //             airFlow: 35,
    //             airflowRelativeClutchMountingSide: "CLUTCH_ON_INLET",
    //             bladeAngle: null,
    //             bladeManufacturingTypeCode: "standard",
    //             bladeProfileNames: [],
    //             crosswind: null,
    //             diffuser: null,
    //             fanApplicationTypeId: "AIR_COOLER_HEAT_EXCHANGER",
    //             fanMountingOrientation: "HORIZONTAL",
    //             impellerDiameter: 2772,
    //             impellerHeight: null,
    //             inletShapeId: "straight-cyliner",
    //             inletTemperature: 25,
    //             operationalSetupId: "INDUCED_DRAFT",
    //             preferredConstructionMaterialCode: "HDG",
    //             preferredFastenersMaterialCode: "HDG",
    //             rotationalSpeed: 200,
    //             shaftDiameter: null,
    //             shaftLength: null,
    //             staticPressure: null,
    //             staticPressureSubject: "INSTALLATION",
    //             supportAndClutchDiscSide: "SUPPORTS_AND_CLUTCH_OPPOSITE_SIDES",
    //             inletObstacle: null,
    //             outletObstacle: null,
    //             bladeTipClearancePercent: 0.5,
    //             softStartPresent: false,
    //             supportAndDiscSideChangeAllowed: true
    //         },
    //         simulationFanAdjustmentReference: {
    //             adjustableImpellerId: "wirnik-wielomianowy-demo-3",
    //             bladeAngle: 19.1
    //         }
    //     }
    // },
    // {
    //     name: "Brak rozwiązania mechanicznego (długość wału zadana: 1000mm)",
    //     criteria: {
    //         fanSelectionCriteria: {
    //             "airDensity": 1.115,
    //             "airFlow": 17,
    //             "airflowRelativeClutchMountingSide": "CLUTCH_ON_INLET",
    //             "bladeAngle": null,
    //             "bladeManufacturingTypeCode": "standard",
    //             "bladeProfileNames": [],
    //             "crosswind": null,
    //             "diffuser": null,
    //             "fanApplicationTypeId": "AIR_COOLER_HEAT_EXCHANGER",
    //             "fanMountingOrientation": "HORIZONTAL",
    //             "impellerDiameter": 2772,
    //             "impellerHeight": null,
    //             "inletShapeId": "straight-cyliner",
    //             "inletTemperature": 25,
    //             "operationalSetupId": "INDUCED_DRAFT",
    //             "preferredConstructionMaterialCode": "HDG",
    //             "preferredFastenersMaterialCode": "HDG",
    //             "rotationalSpeed": 200,
    //             "shaftDiameter": null,
    //             "shaftLength": 1000,
    //             "staticPressure": null,
    //             "staticPressureSubject": "INSTALLATION",
    //             "supportAndClutchDiscSide": "SUPPORTS_AND_CLUTCH_OPPOSITE_SIDES",
    //             "inletObstacle": null,
    //             "outletObstacle": null,
    //             "bladeTipClearancePercent": 0.5,
    //             "softStartPresent": false,
    //             supportAndDiscSideChangeAllowed: true
    //         },
    //         simulationFanAdjustmentReference: {
    //             "adjustableImpellerId": "wirnik-wielomianowy-demo-3",
    //             "bladeAngle": 12
    //         }
    //     }
    // }
];

/* TODO [
{
    name: "Typowa udana symulacja wirnika Demo-4 przy 2800mm i 150 RPM dla wydajności 25 m/s",
    criteria: {
        airDensity: 1.25,
        coolingCellArea: 20,
        correctorIds: [],
        housingDiameter: 2800,
        impellerDiameter: 2795,
        requestedAirFlow: 25,
        requestedImpellerHeight: 99,
        requestedShaftDiameter: 38,
        requestedShaftLength: 38,
        rpm: 150,
        selectedAngle: 20.1,
        selectedImpellerId: "wirnik-wielomianowy-demo-4",
        bladeManufacturingType: "standard",
        preferredFastenersMaterialCode: "HDG",
        preferredConstructionMaterialCode: "HDG",
        inletObstacleParameters: null,
        outletObstacleParameters: null,
        diffuserDetails: null,
        crosswindDetails: null
    }
},
{
    name: "Przykład całkowicie błędnej symulacji - wirnik większy niż obudowa",
    criteria: {
        airDensity: 1.25,
        coolingCellArea: 20,
        correctorIds: [],
        housingDiameter: 2800,
        impellerDiameter: 3000,
        requestedAirFlow: 25,
        requestedImpellerHeight: 140,
        requestedShaftDiameter: 38,
        requestedShaftLength: 80,
        rpm: 150,
        selectedAngle: 20.1,
        selectedImpellerId: "wirnik-wielomianowy-demo-4",
        bladeManufacturingType: "standard",
        preferredFastenersMaterialCode: "HDG",
        preferredConstructionMaterialCode: "HDG",
        inletObstacleParameters: null,
        outletObstacleParameters: null,
        diffuserDetails: null,
        crosswindDetails: null
    },
},
{
    name: "Zysk ciśnienia na przykładzie podstawowym przez dodanie dyfuzora kąt=8,5 H/D=0,2",
    criteria: {
        airDensity: 1.25,
        coolingCellArea: 20,
        correctorIds: [
            "dyfuzor-8.5-0.2"
        ],
        housingDiameter: 2800,
        impellerDiameter: 2795,
        requestedAirFlow: 25,
        requestedImpellerHeight: 140,
        requestedShaftDiameter: 38,
        requestedShaftLength: 80,
        rpm: 150,
        selectedAngle: 20.1,
        selectedImpellerId: "wirnik-wielomianowy-demo-4",
        bladeManufacturingType: "standard",
        preferredFastenersMaterialCode: "HDG",
        preferredConstructionMaterialCode: "HDG",
        inletObstacleParameters: null,
        outletObstacleParameters: null,
        diffuserDetails: null,
        crosswindDetails: null
    }
},
{
    name: "Strata ciśnienia wywołana przeszkodą",
    criteria: {
        airDensity: 1.25,
        coolingCellArea: 20,
        correctorIds: [
            "przeszkoda-za-aprzacal0.22-hd-0.24"
        ],
        housingDiameter: 2800,
        impellerDiameter: 2795,
        requestedAirFlow: 25,
        requestedImpellerHeight: 140,
        requestedShaftDiameter: 38,
        requestedShaftLength: 80,
        rpm: 150,
        selectedAngle: 20.1,
        selectedImpellerId: "wirnik-wielomianowy-demo-4",
        bladeManufacturingType: "standard",
        preferredFastenersMaterialCode: "HDG",
        preferredConstructionMaterialCode: "HDG",
        inletObstacleParameters: null,
        outletObstacleParameters: null,
        diffuserDetails: null,
        crosswindDetails: null
    }
}
,
{
    name: "Podwójny czynnik korygujący ciśnienie - przeszkoda i stożkowy kształt wlotu",
    criteria: {
        airDensity: 1.25,
        coolingCellArea: 20,
        correctorIds: [
            "przeszkoda-za-aprzacal0.22-hd-0.24",
            "stozek-hd0.15"
        ],
        housingDiameter: 2800,
        impellerDiameter: 2795,
        requestedAirFlow: 25,
        requestedImpellerHeight: 140,
        requestedShaftDiameter: 38,
        requestedShaftLength: 80,
        rpm: 150,
        selectedAngle: 20.1,
        selectedImpellerId: "wirnik-wielomianowy-demo-4",
        bladeManufacturingType: "standard",
        preferredFastenersMaterialCode: "HDG",
        preferredConstructionMaterialCode: "HDG",
        inletObstacleParameters: null,
        outletObstacleParameters: null,
        diffuserDetails: null,
        crosswindDetails: null
    }
},
{
    name: "Dobór nieudany przez przekroczenie dozwolonej prędkości łopaty zdefiniowanej ustawieniami profilu",
    criteria: {
        airDensity: 1.25,
        coolingCellArea: 20,
        correctorIds: [],
        housingDiameter: 2800,
        impellerDiameter: 2795,
        requestedAirFlow: 25,
        requestedImpellerHeight: 140,
        requestedShaftDiameter: 38,
        requestedShaftLength: 80,
        rpm: 600,
        selectedAngle: 20.1,
        selectedImpellerId: "wirnik-wielomianowy-demo-4",
        bladeManufacturingType: "standard",
        preferredFastenersMaterialCode: "HDG",
        preferredConstructionMaterialCode: "HDG",
        inletObstacleParameters: null,
        outletObstacleParameters: null,
        diffuserDetails: null,
        crosswindDetails: null
    }
},
{
    name: "Brak możliwości konstrukcji mechanicznej wentylatora - nietypowa średnica wału 41mm",
    criteria: {
        airDensity: 1.25,
        coolingCellArea: 20,
        correctorIds: [],
        housingDiameter: 2800,
        impellerDiameter: 2795,
        requestedAirFlow: 25,
        requestedImpellerHeight: 140,
        requestedShaftDiameter: 41,
        requestedShaftLength: 80,
        rpm: 150,
        selectedAngle: 20.1,
        selectedImpellerId: "wirnik-wielomianowy-demo-4",
        bladeManufacturingType: "standard",
        preferredFastenersMaterialCode: "HDG",
        preferredConstructionMaterialCode: "HDG",
        inletObstacleParameters: null,
        outletObstacleParameters: null,
        diffuserDetails: null,
        crosswindDetails: null
    }
},
];*/


export function SimulationExamplesPage(props: any) {
    return <>


        <Card header={"Historia wersji"} subHeader={""} additionalClassNames={"mb-3"}>


            {/*PROBLEMY
            - ***sypie sie gyd nie ma danych, nic na wynikach zlzoenia wirnika
            - super brudny log gdy importujemy wielomiaony
            - na tablicy wentechu rozbic raprot kelnczara z wielomionych na wiele zadan
            - nie zdayzlem zaadresowac mini zadan
            - plik estymat naprezen nalezy dodac do referencyjnych!
            -0 wgrywanie estymat naprezen ze zlymi kolumnami nie pokazuje przy nieodgadniecu w xls mozliwosci estymat!! tj jakby tylko inne pliki rozponawal
            */}

            <Changelog/>

        </Card>

        <Card header={"Portal informacyjny"} subHeader={"Informacje o bieżącym rozwoju oprogramowania Optisel 2"} additionalClassNames={"mb-3"}>

            <div className={"row"}>
                <div className={"col-6"}>
                    <h4>Informacje ogólne</h4>
                    <p>
                        Walidację mechaniczną najlepiej przeprowadzać na podstronie <NavLink to={"/impeller-assembly"}>Złożenie wirnika</NavLink>.
                        Istnieje już możliwość symulowania konkretnych wyników z prototypowego doboru (jednak jeszcze nie ze "Złożenia wirnika").
                    </p>

                    <p>
                        Zapis doboru mechanicznego do późniejszego przeglądania występuje tylko na stronie symulacja oraz z Optisel 1. Dobory obliczane podczas prototypowego doboru przepływowego lub "Złożenia wirnika" nie są zapisywane do historii.
                        Katalog zapisanych/przeszłych doborów najpewniej będzie jeszcze usprawniany i rozwijany.
                    </p>

                    <p>
                        Istnieje już możliwość edycji istniejących profilów łopatowych.
                    </p>

                    <p>
                        Zbudowana została świadomość wpływu korygowania ciśnień na dobór wentylatora. Należy zwrócić uwagę, że obecnie funkcjonalnośc symulacji, skupia się na przeprowadzeniu symulacji konkretnego wirnika (nastawionego
                        na dany kąt) w konkretnych warunkach. Stąd też w przypadku wystąpienia korekt ciśnienia (np. przeszkód), wynikowa charakterystyka będzie miała ciśnienie obniżone, a skorygowana przeszkodą charakterystyka jest "pod"
                        wyjsciąwą krzywą wirnika w przypadku strat ciśnienia. Prezentowany jest także zawsze wybrany kąt. Natomiast, gdy rozwijany będzie dobór, będzie on starał się dobrać wentylatory na ciśnienie wyższe niż zadane,
                        uwzględniając korekty (których jeszcze nie ma w funkcji doboru). Oznacza to też także, że zmiana korekty ciśnienia wraz np. ze wzrostem prędkości powietrza czyli także wydajności, będzi mogła wpłynąć na dobranie "kolejnego" kąta
                        wentylatora tzn. jest spora szansa, że nawet zmieniając punkt pracy wzdłóż oryginalnej charakterystyki wentylatora, wraz ze wzrostem wydajności mimo zadania kolejnych punktów jak na charaketerystyce wentylatora, moża nastąpić
                        przeskok na wyższy kąt, aby nadrobić straty ciśnienia wraz z rosnącą prędkością powietrza przez wentylator.
                    </p>

                </div>
                <div className={"col-6"}>

                    <h4>Znane limitacje istniejących funkcjonalności</h4>
                    <ul>
                        <li>Nazwy kształtów wlotu, mimo iż wewnętrznie w programie posiadają tłumaczenia, często są wyświetlane zawsze na stałe w języku polskim</li>
                        <li>Cały algorytm związany z pozycją wirnika lub jej elementami (strony sprzęgła, orientacja, układ pracy, zezwolenie na przerzucanie na drugą stronę tarczy) stał się prawdopodobnie zbyt złożony i należy go uprościć</li>
                        <li>Pozycja wirnika musi być zadana poczas złożenia, lub z poziomu Optisel 1 jest domyślnie utrwalana jako sprzęgło i podpory po innych stronach tarczy - nie ma automatycznego "przerzucania" podpór na stronę sprzęgła</li>
                        <li>Najprawdopodobniej pliki Driveworks nie są wystarczające do konstrukcji z kołpakami</li>
                        <li>Nie istnieje algorytm "przerzucający" podpory na drugą stronę tarczy (zmieniając pozycję wirnika) w celu rozmieszczenia elementów w zależności od potrzbnej długości wału, wysokości wirnika itd. (ma to znaczenie dla zapytań przez Optisel 1 - jest po prostu przyjmowana konkretna pozycja)</li>
                        <li>Część kodu dotyczącego wzmocnień łopat jest zbyt "rozlana" po kodzie aplikacji i zasługuje na własny moduł, osobny plik XLS itp. - ogólnie wymaga refaktoryzacji</li>
                        <li>Niektóre błędy XLS (braku danych) nadal potrafią spowodować błąd całęgo procesu składania wirnika i serwera iw konswekwecji nie wyświetlenie niczego zamiast rezultatów lub sensownego komunikatu błędu</li>
                    </ul>

                    <h4>Najbliższe prawdopodobne plany / obecne limitacje</h4>
                    <ul>
                        <li>Przerzucanie łopat na drugą stronę tarczy</li>
                        <li>Konta użytkowników, rejestracja/logowanie/odzyskiwanie hasła</li>
                        <li>Logowanie bezpośrednie (SSO) z Optisel 1 (pozycja "Mechanika" w menu Optisel 1)</li>
                        <li>Wydruki PDF</li>
                        <li>Poprawne tłumaczenie stron dla końcowych użytkowników (symulacja, dobór)</li>
                        <li>Drugi etap zaawansowania kalkulacji rezonansów - Korekta FDW, delty/powtarzalność produkcji i rozbudowa wykresu Campbell</li>
                        <li>Obsługa wirników o stałej szerokości łopaty</li>
                        <li>Możliwość symulacji złożenia mechanicznego</li>
                        <li>"Muszlowe" wykresy sprawności - linie naniesione na charakterystykę przepływową</li>
                        <li>Wykorzystanie faktu opcjonalności danych wału przy doborze mechaniki i rozwinięcie Optisel 1 o dobór mechaniki bez podawania danych wału</li>
                        <li>Mozliwość wgrywania obrazów dla profili</li>
                        <li>Autoryzacja - ograniczenie widoczności wielu danych mechanicznych tylko wewnątrz firmy</li>
                        <li>Preferencje lub profile ustawieniowe użytkownika</li>
                    </ul>

                </div>

            </div>


        </Card>

        <Card header="Symulacje - Katalog przykładów" subHeader={"Celem storny jest umożliwienie obserwacji przykładów bez znajomości wartości szeregu kryteriów jakie trzeba zadać do uzyskania konkretnego przypadku doboru."} additionalClassNames={"mb-3"}>
            {examples.map(example => <div>

                <NavLink to={"/simulation?b64Criteria=" +btoa(JSON.stringify(example.criteria))} className={"btn btn-sm btn-primary me-3"}><i className="fas fa-chart-line"></i> Symuluj</NavLink>
                {example.name}
                <div className="separator my-5"></div>
            </div>)}
        </Card>

    </>;
}

SimulationExamplesPage.propTypes = {}

SimulationExamplesPage.defaultProps = {}
