import {useEffect, useState} from "react";
import {GeneralSimulationResultJson} from "../../business/useImpellerSimulation";
import {SelectionRequestJson, SimulationRequestJson} from "../../business/ApiJsonTypes";
import AjaxService from "../../platform/ajax/AjaxService";

export type AUTOSELECTED = null;


export interface FanSelectionMatchedFan {
    adjustableImpellerId: string, // TODO introduce type of UidCode?
    generalSimulationResult: GeneralSimulationResultJson,
    airFlowError: number,
    pressureError: number,
    selectionStrategyCode: string,
    humanFriendlyDescription: string,
    matchIdentifier: string // a one-of client side identifier to identify a fan since last selection exectuion (impeller id may result in many matches thus artificial id is necessary)
}

export interface FanSelectionResult {
    fanSelectionMatchedFans: FanSelectionMatchedFan[],
    criteriaUsed: SelectionRequestJson | null,
    notifications: string[]
}

let uniqid = 0;
export function generateUniqueId(): string {
    return 'localuniqid' + uniqid++ + '';
}

export default function useFanSelection(seletionRequest: SelectionRequestJson | "incorrect-request"): {
    results: FanSelectionResult,
    loading: boolean
} {
    const [results, setResults] = useState<FanSelectionResult>({
        fanSelectionMatchedFans: [],
        notifications: [],
        criteriaUsed: null
    }); // TODO improve. make whoel result optional/nullable. not the criteria inside
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setResults({
            fanSelectionMatchedFans: [],
            notifications: [],
            criteriaUsed: null
        })

        // TODO: Absolutelly implement request/response races protection here! Already confirmed race conditions between requests.
        if (seletionRequest === "incorrect-request") {
            return;
        }
        setLoading(true);

        let abortController: AbortController | null = new AbortController();

        AjaxService.postJson("/api/fan-selection", seletionRequest)
            .then((response) => {
                console.log("Received response", response);
                if (response.status < 200 || response.status > 299) {
                    throw new Error("Failed to fetch"); // TODO more error details
                }

                const results = {...response.json, criteriaUsed: seletionRequest} as FanSelectionResult;
                for (const fanSelectionMatchedFan of results.fanSelectionMatchedFans) {
                    fanSelectionMatchedFan.matchIdentifier = generateUniqueId();
                }
                setResults(results);
            }).finally(() => {
            if (abortController !== null) {
                setLoading(false);
                abortController = null;
            }
        });

        return () => {
            if (abortController != null) {
                abortController.abort();
                abortController = null;
            }
        }

    }, [JSON.stringify(seletionRequest)]);

    return {
        results,
        loading
    };
}

export function matchedFanToSimulationCriteria(criteriaUsed: SelectionRequestJson, matchedFan: FanSelectionMatchedFan): SimulationRequestJson {


    // Note that air flow to DEMO INSTALLATION (because simulation is demoing INSTALLATION) is the same as for selection.
    // But demoed pressure on the installation page may be:
    // - lower than result pressure, because result is FAN where simulation is INSTALLATION (still, ever than lowe, it should be close to REQUESTED pressure)
    // - same as result pressure, if someone was querying for known installation pressure. In this way selectin will not select significanlly more-pressureized fan
    const installationAirFlowForSimulationDemo =
        matchedFan.generalSimulationResult.flowSimulationResult.installationStaticPressureWorkingPoint.flow;


    // housingDiameter = impellerDiameter / (1.0 - 2.0 * (gap / 100.0) );
    // housingDiameter / impellerDiameter = 1 / (1.0 - 2.0 * (gap / 100.0) )
    // (1.0 - 2.0 * (gap / 100.0) ) * (housingDiameter / impellerDiameter) = 1
    // 1.0 - 2.0 * (gap / 100.0) = 1 / (housingDiameter / impellerDiameter)
    //  2.0 * (gap / 100.0) = 1 / (housingDiameter / impellerDiameter) - 1
    //  gap / 100.0 = 1 / 2 * (housingDiameter / impellerDiameter) - 1/2
    //  gap = 100 / 2.0 * (housingDiameter / impellerDiameter) - 100/2.0

    const gap = Number(
        (
            100 * (matchedFan.generalSimulationResult.parameterCatalog.housingDiameter - matchedFan.generalSimulationResult.parameterCatalog.impellerDiameter) / (2 * matchedFan.generalSimulationResult.parameterCatalog.housingDiameter)
        ).toFixed(2)
    );


    return {
        demoAirFlow: installationAirFlowForSimulationDemo,
        fanFlowSpecification: {
            adjustableImpellerId: matchedFan.adjustableImpellerId,
            bladeAngle: matchedFan.generalSimulationResult.parameterCatalog.bladeAngle,
            fanDiameters: {
                gap: gap,
                impellerDiameter: matchedFan.generalSimulationResult.parameterCatalog.impellerDiameter
            },
            fluidSpecification: criteriaUsed.fanFlowPreferences.fluidSpecification,
            rpm: matchedFan.generalSimulationResult.parameterCatalog.rotationsPerMinute
        },
        installationSpecification: criteriaUsed.installationSpecification,
        manufacturingSpecification: criteriaUsed.manufacturingSpecification,
        // This is a bit dirty. When selecting a fan, we could potentially talk about exact mechganics.
        // But because selection result do not have mechanics yey, we basicall "rewrute query" for mechanics to simulation,
        // and potentailly mechanics part will be discovered in simulation.
        // But, if the selection would already came up with mechanical solution, this one would be tricky TODO
        // (we could try to find exact criteria to be sure about 1:1 mechanics ,match, or create all the discriminators that
        // define exact mechanical solution. but this probably would requioree a huge object
        mechanicalPreferences: criteriaUsed.mechanicalPreferences,
        softStartPresent: criteriaUsed.softStartPresent,
        realizeWithClutch: criteriaUsed.realizeWithClutch
    }
}