import {D3ChartRenderable, D3ChartSize, D3GSelection, D3SvgSelection} from "./D3Chart";
import * as d3Scale from "d3-scale";


export class D3ChartBar<Point> implements D3ChartRenderable {

    private g: D3GSelection | undefined;

    private rendered: boolean = false;

    constructor(public scaleX: d3Scale.ScaleBand<string>,
                public scaleY: d3Scale.ScaleLinear<number, number, never>,
                public points: Point[],
                public domainExtractor: (point: Point) => string,
                public barSubLabelExtractor: (point: Point) => string | undefined,
                public yExtractor: (point: Point) => number,
                public className: string = "d3-stroke-1 d3-stroke-black",
                private valueAboveBarPresenter?: (point: Point) => string) {
    }


    initialize(svgSelection: D3SvgSelection, rootG: D3GSelection, unClippedRootG: D3GSelection, size: D3ChartSize): void {
        this.g = rootG.append("g"); // TODO it is needed to add g? maybe path directly?
    }

    onMouseMove(x: number, y: number): "repaint" | "norepaint" {
        return "norepaint";
    }

    render = (): void => {
        if (this.rendered || !this.g) {
            return; // note line is STATIC, and thus after first render we can skip next renders.
        }

        this.g.select("rect").remove();
        for (const point of this.points) {
            const domain = this.domainExtractor(point);
            const subLabel = this.barSubLabelExtractor(point);
            const val = this.yExtractor(point);

            const x = this.scaleX(domain) ?? -100;
            const y = this.scaleY(val);
            const width = this.scaleX.bandwidth();
            const height = this.scaleY(0) - this.scaleY(val);
            this.g.append("rect")
                .attr("x", x)
                .attr("y", y)
                .attr("width", width)
                .attr("height", height)
                .attr("fill", "rgb(90,90,220)");

            if (this.valueAboveBarPresenter !== undefined) {

                this.g.append("text")
                    .attr("font-size", "12px")
                    .attr("fill", "black")
                    .attr("x", x + (width/2.0))
                    //.attr("width", width)
                    .attr("y", y - 5)
                    .style("text-anchor", "middle")
                    .text(this.valueAboveBarPresenter(point));
            }
            if ( subLabel !== undefined ) {
                this.g.append("text")
                    .attr("font-size", "12px")
                    .attr("fill", "#ccc")
                    .attr("x", x + (width/2.0))
                    //.attr("width", width)
                    .attr("y", y + 13)
                    .style("text-anchor", "middle")
                    .text(subLabel);
            }
        }

        this.rendered = true;
    }

}