import React from 'react';
import DynamicWebServiceDrivenForm from "../../platform/webform/DynamicWebServiceDrivenForm";
import {useNavigate, useParams} from "react-router";
import {createInternationalizationPrefixer} from "../i18n/i18n";
import {Card} from "../../components/Card";


const t = createInternationalizationPrefixer("AdminChangeEntitilementsPage");


export default function AdminChangeEntitlementsPage(props) {

    const {id} = useParams();
    const navigate = useNavigate();

    return <Card header={t('Zmień uprawnienia użytkownika (role)')}>
        <DynamicWebServiceDrivenForm
            dslUrl={"/api/user-account/" + id + "/change-roles"}
            submissionUrl={"/api/user-account/" + id + "/change-roles"}
            submitLabel={"Zapisz"}
            successHandler={() => {
                alert(t("Uprawnienia zostały zmienione!"));
                navigate('/accounts')
            }}/>
    </Card>;
}
