import {MaterialPreferenceJson, SelectionRequestJson} from "../../../business/ApiJsonTypes";

export function convertCriteriaToExportFormat(criteria: SelectionRequestJson): ExportedCriteriaV1 {
    return {
        criteriaVersion: 1,
        ...criteria
    };
}

export interface AbstractExportedCriteria {
    criteriaVersion : number
}
/**
 * This criteria was generated based on "flattented" object model of SimulationRequestJson to make a type-snapshot
 * at 26-11-2023, as since that point backward compatibility may be necessary, as clients may have exported file
 * saved on local PC
 */
export type ExportedCriteriaV1 = {
    criteriaVersion: 1, // Version discrimanator for future detection/compatibility/just information
    fanFlowPreferences: {
        requestedBladeAngle: number | null, // double
        requestedFanDiameters: {
            impellerDiameter: number, // Double
            gap: number // Double (in % eg. 5.0 for 5%)
        } | null,
        requestedRpm: number | null, // double
        requestedBladeProfileNames: string[] | null,
        fluidSpecification: {
            density: number, // Double
            temperature: number // Double
        }
    },
    fanWorkingPointQuery: {
        requestedFanStaticPressure: number | null, // null for autoselection
        requestedFanAirFlow: number | null // null for autoselection
    } | null, // Only one of ...WorkingPointQuery should be non-null
    installationWorkingPointQuery: {
        requestedInstallationStaticPressure: number | null, // null for autoselection
        requestedInstallationAirFlow: number | null // null for autoselection
    } | null, // Only one of ...WorkingPointQuery should be non-null
    installationSpecification: {
        fanApplicationTypeCode: string,
        operationalSetupCode: string,
        inletShapeId: string,
        diffuserDetails: {
            heightMeters: number, // Double
            angle: number // Double
        } | null,
        crosswindDetails: {
            metersPerSecond: number // Double
        } | null,
        inletObstacleParameters: {
            obstacleToImpellerArea_ApToAc: number,
            obstacleDistanceToHousingDiameter_LToD: number
        } | null,
        outletObstacleParameters: {
            obstacleToImpellerArea_ApToAc: number,
            obstacleDistanceToHousingDiameter_LToD: number
        } | null,
    },
    mechanicalPreferences: {
        fanMountingOrientationCode: string | null,
        airflowRelativeClutchMountingSideCode: string | null,
        preferredSupportAndClutchDiscSideCode: string | null,
        shaftDiameter: number | null, // Long
        shaftLength: number | null, // Long
        expectedImpellerHeight: number | null
    },
    manufacturingSpecification: {
        bladeManufacturingTypeCode: string,
        materialPreference: MaterialPreferenceJson
    },
    softStartPresent: boolean
}