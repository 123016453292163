import {D3ChartRenderable, D3ChartSize, D3GSelection, D3SvgSelection} from "./D3Chart";
import * as d3Scale from "d3-scale";

export class D3ChartPoint implements D3ChartRenderable {

    private g: D3GSelection | undefined;

    constructor(public scaleX: d3Scale.ScaleLinear<number, number, never>,
                public scaleY: d3Scale.ScaleLinear<number, number, never>,
                public x: number,
                public y: number,
                public radius: number,
                public fill: string = "",
                public className : string = "",
                public strokeDashArray: string = "") {
    }

    initialize(svgSelection: D3SvgSelection, rootG: D3GSelection, unClippedRootG: D3GSelection, size: D3ChartSize): void {
        this.g = rootG.append("g");
        this.g.append("circle");
    }

    render(): void {
        this.g?.select("circle")
            .attr("cx", this.scaleX(this.x))
            .attr("cy", this.scaleY(this.y))
            .attr("r", this.radius)
            .attr("class",this.className)
            .attr("stroke-dasharray", this.strokeDashArray)
            .attr("fill", this.fill);
    }

}