import React, {useState, useRef} from 'react';
import useClickOutsideListener from "../platform/hooks/PlatformHooks";
import PropTypes from 'prop-types';

export function ExpandableMenuItem(props) {

    const [opened, setOpened] = useState(false);

    const ref = useRef(null);
    useClickOutsideListener(ref, () => setOpened(false));

    return <div ref={ref} className="menu-item menu-lg-down-accordion me-lg-1">
        {opened && <div style={{position: "relative"}}>
            <div style={{position: "absolute", top: "20px"}} className=" menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown menu-rounded-0 py-lg-4 w-lg-225px">
                {props.children}
            </div>
        </div>}
        <a className="menu-link py-3" href="#/" onClick={() => setOpened(true)}>
            <span className="menu-title">{props.label}</span>
            <span className="menu-arrow d-lg-none"></span>
        </a>

    </div>;
}

ExpandableMenuItem.propTypes = {
    label: PropTypes.string.isRequired
}

ExpandableMenuItem.defaultProps = {}
