import React, {useCallback, useEffect, useState} from 'react';
import WorkspaceService from "./WorkspaceService";
import AuthenticationService from "../authentication/AuthenticationService";

export const WorkspaceContext = React.createContext();

export default function WorkspaceContextProvider({children}) {

    const [workspaceData, setWorkspaceData] = useState({});

    const setWorkspaceDataxx = d => {
        setWorkspaceData(wd => {
            return {...wd, ...d}; // Note workspaceData cannot be used, becasue we build state based on current state (workspaceData may be outdated)
        });
    }

    const contextValue = {
        data: workspaceData,
        applyNewData: setWorkspaceDataxx
    };

    const reloadWorkspaceData = useCallback(() => {
        console.log("Refetching workspace data");
        setWorkspaceData({});
        WorkspaceService.getWorkspaceData().then(workspaceData => {
            setWorkspaceDataxx(workspaceData);
        });
    }, []);

    useEffect(() => {
        reloadWorkspaceData();
        AuthenticationService.addAuthStatusChangeListener(function (newKnownAuthStatus) {
            reloadWorkspaceData();
        });
    }, [reloadWorkspaceData]);

    return <WorkspaceContext.Provider value={contextValue}>
        {children}
    </WorkspaceContext.Provider>;
}
