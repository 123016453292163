import React from 'react';
import {MetronicInvalidFeedbackList} from "./MetronicInvalidFeedbackList";

export function MetronicInputText(props) {
    if (!Array.isArray(props.errors)) {
        console.error("Cannot render input text with 'errors' not being an array. Errors is: ", props.errors);
        return;
    }
    return <div className="row mb-4">
        <label className="col-12 col-md-4 col-form-label text-md-end">{props.label}</label>
        <div className="col-12 col-md-8 col-xl-6">
            <input value={props.value}
                   type={props.type}
                   className={"form-control" + (props.errors.length > 0 ? " is-invalid" : "")}
                   placeholder={props.placeholder}
                   onChange={props.onChange}/>
            <MetronicInvalidFeedbackList errors={props.errors}/>
        </div>
    </div>;
}

MetronicInputText.propTypes = {}

MetronicInputText.defaultProps = {}
