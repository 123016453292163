import {FanSelectionMatchedFan, matchedFanToSimulationCriteria} from "./SelectionService";
import {SelectionRequestJson, SimulationRequestJson} from "../../business/ApiJsonTypes";

export type FanSelectionMatch = {
    fanName: string,
    airFlow: number,
    installationStaticPressure: number,
    airFlowErrorFraction: number,
    installationStaticPressureErrorFraction: number,
    impellerDiameter: number,
    rpm: number,
    bladeEndSpeed: number,
    staticEfficiencyFactor: number,
    workingPointInstallationTotalEfficiencyPercentProperty: number,
    bladeAngle: number,
    pwlInOptimalConditions: number,
    workingPointPwl: number,
    fanPowerWorkingPoint: number,
    airFlowError: number,
    pressureError: number,
    apiAirflowReserve: number,
    apiPressureReserve: number,
    matchIdentifier: string,
    criteriaToSimulate?: SimulationRequestJson // for now left optional just in case the match is treated as persisted value object for evennts etc. This criteria is VERY heavy comparing to rest of the object
}

export function toFanSelectionMatches(fans: FanSelectionMatchedFan[], selectionRequestUsed: SelectionRequestJson): FanSelectionMatch[] {
    return fans.map(fan => ({
        airFlow: fan.generalSimulationResult.flowSimulationResult.fanStaticPressureWorkingPoint.flow,
        installationStaticPressure: fan.generalSimulationResult.flowSimulationResult.fanStaticPressureWorkingPoint.value,
        airFlowErrorFraction: fan.airFlowError,
        installationStaticPressureErrorFraction: fan.pressureError,
        bladeAngle: fan.generalSimulationResult.parameterCatalog.bladeAngle,
        fanName: fan.generalSimulationResult.parameterCatalog.fanName,
        impellerDiameter: fan.generalSimulationResult.parameterCatalog.impellerDiameter,
        rpm: fan.generalSimulationResult.parameterCatalog.rotationsPerMinute,
        bladeEndSpeed: fan.generalSimulationResult.parameterCatalog.bladeEndSpeed,
        staticEfficiencyFactor: fan.generalSimulationResult.flowSimulationResult.installationStaticEfficiencyWorkingPoint.value,
        workingPointInstallationTotalEfficiencyPercentProperty: fan.generalSimulationResult.parameterCatalog.workingPointInstallationTotalEfficiencyPercent,
        pwlInOptimalConditions: fan.generalSimulationResult.soundSimulationResultCatalog.pwlInOptimalConditions,
        workingPointPwl: fan.generalSimulationResult.soundSimulationResultCatalog.workingPointPwl,
        fanPowerWorkingPoint: fan.generalSimulationResult.flowSimulationResult.fanPowerWorkingPoint.value,
        airFlowError: fan.airFlowError,
        pressureError: fan.pressureError,
        apiAirflowReserve: fan.generalSimulationResult.parameterCatalog.apiAirflowReserve,
        apiPressureReserve: fan.generalSimulationResult.parameterCatalog.apiPressureReserve,
        matchIdentifier: fan.matchIdentifier,
        criteriaToSimulate: matchedFanToSimulationCriteria(selectionRequestUsed, fan)
    }));
}