import React from 'react';
import UboldSpinner from "../../ubold-components/UboldSpinner";

export default function TableBrowserResultsComponent(props) {


    console.log("Props",{props})
    if (props.fetching || props.results === undefined) {
        return <div className={"text-center mt-3"}><UboldSpinner/></div>;
    }


    if (props.results.length === 0) {

        if (React.isValidElement(props.noResultsContent)) {
            return props.noResultsContent;
        } else {
            return <div className="text-center mt-4 h4 text-muted">{props.noResultsContent}</div>;
        }
    }

    const ths = [];
    for (const [key, value] of Object.entries(props.headerMapping)) {
        if (React.isValidElement(value)) {
            ths.push(value);
        } else {
            ths.push(<th key={key}>{value}</th>);
        }
    }

    const trs = [];
    console.log("R",props.results);
    for (const result of props.results) {
        const tds = [];
        for (const [key,] of Object.entries(props.headerMapping)) {
            if (props.customCellRenderers && props.customCellRenderers.hasOwnProperty(key)) {
                tds.push(<td key={key}>{props.customCellRenderers[key](result)}</td>);
            } else {
                tds.push(<td key={key}>{result[key]}</td>);
            }

        }
        trs.push(<tr key={result.id}>{tds}</tr>);
    }

    return <div className={"table-responsive"}>
        <table className={"table table-centered mb-0 mt-3"}>
            <thead className={"fw-bolder"}>
            <tr>
                {ths}
            </tr>
            </thead>
            <tbody>
            {trs}
            </tbody>
        </table>
    </div>;
}

TableBrowserResultsComponent.propTypes = {}

TableBrowserResultsComponent.defaultProps = {}