import {BaseEditor, Editor} from "../Editor";
import {ReactElement} from "react";

export class OptionalEditor<OBJECT> extends BaseEditor<OBJECT | null> {

    private _enabled: boolean;

    constructor(private readonly delegate: Editor<OBJECT>,
                private readonly initiallyEnabled: boolean,
                private readonly renderDisabled: (toggleEnablement: () => void, delegate: Editor<OBJECT>) => ReactElement,
                private readonly renderEnabled: (DelegatedEditorComponent: () => ReactElement, toggleEnablement: () => void) => ReactElement,
                public meta = "",// TODO Improve design, this is just to carry some additonal metadetails by client, a bit of shortcut
    ) {
        super();
        this._enabled = initiallyEnabled;
        delegate.addChangeListener(this.notifyListeners);
    }

    Component = (): JSX.Element => {
        if (this._enabled) {
            return this.renderEnabled(this.delegate.Component, this.toggleEnablement);
        } else {
            return this.renderDisabled(this.toggleEnablement, this.delegate);
        }
    }

    commit = (): void => {
        this.delegate.commit();
    }

    get errorList(): string[] {
        return this._enabled ? this.delegate.errorList : [];
    }

    get enabled(): boolean {
        return this._enabled;
    }

    populate = (value: OBJECT): void => {
        this.delegate.populate(value);
    }

    reset = (): void => {
        this._enabled = this.initiallyEnabled;
        this.delegate.reset();
    }

    get value(): OBJECT | null {
        if (!this._enabled) {
            return null;
        } else {
            return this.delegate.value;
        }
    }

    toggleEnablement = () => {
        this._enabled = !this._enabled;
        this.notifyListeners();
    }

    enable = () => {
        this._enabled = true;
        this.notifyListeners();
    }

    disable = () => {
        this._enabled = false;
        this.notifyListeners();
    }

}