import {SelectionRequestJson} from "../../../business/ApiJsonTypes";
import React, {useRef, useState} from "react";


export function SelectionCriteriaUploader(props: { onUpload: (criteria: SelectionRequestJson) => void }) {

    const [inputKeyForComponentReset, setInputKeyForComponentReset] = useState(1);

    // Hiding original for styling
    const hiddenFileInput = useRef<HTMLInputElement>(null);


    const proxyClick = () => {
        if( hiddenFileInput.current == null ) {
            return;
        }
        hiddenFileInput.current.click();
    }

    const handleChange: React.ChangeEventHandler<HTMLInputElement> = e => {
        if (e.target.files === null) {
            console.log("no files selected on change.");
            return;
        }
        const fileReader = new FileReader();
        fileReader.readAsText(e.target.files[0], "UTF-8");
        fileReader.onload = e => {
            if (e.target == null || e.target.result == null) {
                alert("Cannot load crieria");
                return;
            }

            let json: string;
            if (e.target.result instanceof ArrayBuffer) {
                json = new TextDecoder().decode(e.target.result)
            } else {
                json = e.target.result;
            }

            const parsed: any = JSON.parse(json);
            if (parsed.hasOwnProperty("criteriaVersion")) {
                if (parsed.criteriaVersion === 1) {
                    console.log("Parsed uploaded criteria: ", parsed)
                    props.onUpload({...parsed, signalIdentifier: "" + Math.random()});
                    setInputKeyForComponentReset(prev => prev + 1);
                    return;
                }
                alert("Cannot load criteria: unsupported criteria file version");
            }
            alert("Cannot load criteria: no version");

        };
    };
    return (
        <>
            <button className={"btn btn-sm btn-outline btn-outline-primary"} onClick={proxyClick}><i className="fas fa-folder-open"></i></button>
            <input ref={hiddenFileInput} style={{ display: "none" }}  key={inputKeyForComponentReset} type="file" onChange={handleChange}/>
        </>
    );
}