import React from 'react';
import {D3ChartAxis} from "../../platform/d3chart/D3ChartAxis";
import {D3ChartGridLines} from "../../platform/d3chart/D3ChartGridLines";
import {D3ChartLine} from "../../platform/d3chart/D3ChartLine";
import {curveToPoints, FlowSimulationResultJson} from "../../business/useImpellerSimulation";
import {D3ChartPoint} from "../../platform/d3chart/D3ChartPoint";
import {D3ChartScaleManager} from "../../platform/d3chart/D3ChartScaleManager";
import {D3ChartRenderable} from "../../platform/d3chart/D3Chart";
import {ResistanceCurveD3ChartRenderable} from "../../charting/ResistanceCurveD3ChartRenderable";
import {D3ChartComponent} from "../../platform/d3chart/D3ChartComponent";
import {MouseGuidesD3ChartRenderable} from "../../charting/MouseGuidesD3ChartRenderable";
import {HoverExplainerD3ChartRenderable} from "charting/HoverExplainerD3ChartRenderable";
import {FlowCurve, FlowPoint} from "../../business/core";

export function FanEfficiencyChart(props: {
    pressureCurve: FlowCurve,
    efficiencyCurve: FlowCurve,
    workingPoint: FlowPoint,
}) {

    const chartSize = {width: 500, height: 350};
    const chartMargins = {left: 60, top: 0, right: 5, bottom: 40};

    const flowAxis = new D3ChartAxis([0.0, -1.0], [0.0, chartSize.width-chartMargins.left-chartMargins.right], "bottom", 15, "d3-axis-slight-domain-line",0,0,"Q [m³/s] [m³/h]");
    const flowAxis_m3h = new D3ChartAxis([0, -1], [0, chartSize.width-chartMargins.left-chartMargins.right], "bottom", 7, "d3-axis-nodomainline", 20);
    const pressureAxis = new D3ChartAxis([-1.0, 0.0], [0.0, chartSize.height - chartMargins.top - chartMargins.bottom], "left", 10, "d3-axis-nodomainline", 0, -30);
    const efficiencyAxis = new D3ChartAxis([-1.0, 0.0], [0.0, chartSize.height - chartMargins.top - chartMargins.bottom], "left", 10, "d3-axis-slight-domain-line",0,0,"η [%], Pst [Pa]");

    const verticalGridLines = new D3ChartGridLines(flowAxis.scale, flowAxis, "vertical", "d3-stroke-1 d3-opacity-05 d3-stroke-black");
    const horizontalGridLines = new D3ChartGridLines(efficiencyAxis.scale, efficiencyAxis, "horizontal", "d3-stroke-1 d3-opacity-05 d3-stroke-black");
    //const horizontalGridLinesStrong = new D3ChartGridLines(efficiencyAxis.scale, "horizontal", 10, "d3-stroke-1 d3-opacity-10 d3-stroke-black");

    const pressureLine = new D3ChartLine<{ flow: number, value: number }>(
        flowAxis.scale,
        pressureAxis.scale,
        curveToPoints(props.pressureCurve),
        chPoint => chPoint.flow,
        chPoint => chPoint.value,
        "d3-stroke-1 d3-stroke-primary",
        "3,3");

    const efficiencyLine = new D3ChartLine<{ flow: number, value: number }>(
        flowAxis.scale,
        efficiencyAxis.scale,
        curveToPoints(props.efficiencyCurve),
        chPoint => chPoint.flow,
        chPoint => chPoint.value * 100,
        "d3-stroke-3 d3-stroke-efficiency");
    efficiencyLine.setDescriber(p=>p.flow.toFixed(2)+" ; "+(p.value*100).toFixed(2)+"%");

    const workingPoint = new D3ChartPoint(flowAxis.scale, pressureAxis.scale, props.workingPoint.flow, props.workingPoint.value, 5, undefined, "d3-fill-primary");
    const efficiencyScaleManager = new D3ChartScaleManager([efficiencyLine], 1.2);
    const scaleManager = new D3ChartScaleManager([pressureLine], 1.2);
    const scaleManager_m3h = new D3ChartScaleManager([pressureLine], 1.2 * 3600, flowAxis_m3h.scale, null);

    const renderables: D3ChartRenderable[] = [
        scaleManager, // Scale manager comes first, to recalculate axes scales!
        scaleManager_m3h,
        efficiencyScaleManager,
        flowAxis,
        flowAxis_m3h,
        pressureAxis,
        efficiencyAxis,
        verticalGridLines,
        horizontalGridLines,
        //   horizontalGridLinesStrong,
        new ResistanceCurveD3ChartRenderable(flowAxis.scale, pressureAxis.scale),
        pressureLine,
        efficiencyLine,
        workingPoint,
        new MouseGuidesD3ChartRenderable(flowAxis.scale, efficiencyAxis.scale),
        new HoverExplainerD3ChartRenderable([efficiencyLine])
    ];

    return <D3ChartComponent width={chartSize.width} height={chartSize.height} chartRenderables={renderables}
                             margins={chartMargins}/>
}
