import UserUnathorizedException from "../authorization/UserUnauthorizedException";
import AuthTokenStorageService from "../authentication/AuthTokenStorageService";

export default class AjaxService {

    static fetchWithDefaultTimeout = (url, {signal, ...options} = {}, customAbortController) => {
        const controller =  customAbortController !== undefined ? customAbortController : new AbortController();
        const promise = fetch(url, {signal: controller.signal, ...options});
        var abortRequest = function() {
            console.error("Aborting request due to timeout" , {url , fetchPromise: promise});
            controller.abort();
        }
        if (signal) {
            signal.addEventListener("abort", () => abortRequest());
        }
        const timeout = setTimeout(() => abortRequest(), 45000);
        return promise.finally(() => clearTimeout(timeout));
    };

    static getJson(url) {
        return AjaxService.fetchWithDefaultTimeout(url, {
            method: 'GET',
            cache: 'no-cache',
            redirect: 'manual',
            headers: this.enhanceHeadersWithAuth({
                'Accept': 'application/json'
            })
        })
            .then(this.wait(50))
            .then(res => {
                if (res.status === 401) {
                    throw new UserUnathorizedException("401 received when calling " + url);
                }
                if (res.status >= 500) {
                    console.warn(res);
                    document.body.innerHTML = "Error fetching data from server "+url;
                    throw new Error("Cannot get JSON from " + url + " - HTTP " + res.status + "; response will be logged as warn");
                }

                // Mapping to project-specific ajax-service response, to avoid leaking specific AJAX api return format from this class
                return res.text()
                    .then(text => {
                        try {
                            const ret = JSON.parse(text);
                            return ret;
                        } catch (error) {
                            console.error("Cannot process response as JSON: ", {status: res.status, body: text});
                            throw new Error("Failed to parse received body as JSON: " + error + "; body was: " + text);
                        }

                    })
                    .then(receivedJson => {
                        return {
                            json: receivedJson,
                            ok: res.ok,
                            status: res.status
                        };
                    })
            });
    }

    static postJson(url, jsonObject, unauthorizedInterceptor, requestId, customAbortController) {
        return AjaxService.fetchWithDefaultTimeout(url, {
            method: 'POST',
            cache: 'no-cache',
            headers: this.enhanceHeadersWithAuth({
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }),
            redirect: 'manual',
            body: JSON.stringify(jsonObject),
        }, customAbortController).then(this.wait(50))
            .then(res => {
                if (res.status === 401) {
                    if (unauthorizedInterceptor) {
                        unauthorizedInterceptor(res);
                    } else {
                        throw new UserUnathorizedException("401 received when calling " + url);
                    }
                }
                if (res.status >= 500) {
                    console.error(res);
                    document.body.innerHTML = "Something went wrong - operation failed.";
                    throw new Error("Cannot POST JSON to " + url + " - HTTP " + res.status + "; response will be logged as warn");
                }
                // Mapping to project-specific ajax-service response, to avoid leaking specific AJAX api return format from this class
                return res.text()
                    .then(text => {
                        try {
                            const ret = JSON.parse(text);
                            return ret;
                        } catch (error) {
                            console.error("Cannot process response as JSON: ", {status: res.status, body: text});
                            throw new Error("Failed to parse received body as JSON: " + error + "; body was: " + text);
                        }

                    })
                    .then(receivedJson => {
                        return {
                            json: receivedJson, // TODO: this should be named OBJECT, as its object/value, not a string
                            ok: res.ok,
                            status: res.status,
                            requestId: requestId
                        };
                    })
            });
    }

    static delete(url, unauthorizedInterceptor) {
        return AjaxService.fetchWithDefaultTimeout(url, {
            method: 'DELETE',
            cache: 'no-cache',
            headers: this.enhanceHeadersWithAuth({
                'Content-Type': 'application/json'
            }),
            redirect: 'manual'
        }).then(this.wait(50))
            .then(res => {
                if (res.status === 401) {
                    if (unauthorizedInterceptor) {
                        unauthorizedInterceptor(res);
                    } else {
                        throw new UserUnathorizedException("401 received when calling " + url);
                    }
                }
                // Mapping to project-specific ajax-service response, to avoid leaking specific AJAX api return format from this class
                return res.json()
                    .then(receivedJson => {
                        return {
                            json: receivedJson,
                            ok: res.ok,
                            status: res.status
                        };
                    })
            });
    }

    /**
     * Artificial wait to avoid too fast loadings impossible to be noticed by the user
     * which may be sometimes confusing
     */
    static wait(ms) {
        return function (x) {
            return new Promise(resolve => setTimeout(() => resolve(x), ms));
        };
    }

    static enhanceHeadersWithAuth(headers) {
        const currentToken = AuthTokenStorageService.getCurrentToken();
        if (currentToken) {
            headers.Authorization = 'Bearer ' + currentToken;
        }
        return headers; // TECHDEBT immutable
    }
}
