import React from 'react';

import {ResonanceCurveJson, ResonancesAtSpeedJson} from "../../business/useImpellerSimulation";
import {D3ChartAxis} from "../../platform/d3chart/D3ChartAxis";
import {D3ChartGridLines} from "../../platform/d3chart/D3ChartGridLines";
import {D3ChartLine} from "../../platform/d3chart/D3ChartLine";
import {D3ChartScaleManager} from "../../platform/d3chart/D3ChartScaleManager";
import {D3ChartRenderable} from "../../platform/d3chart/D3Chart";
import {D3ChartComponent} from "../../platform/d3chart/D3ChartComponent";
import {D3ChartPoint} from "../../platform/d3chart/D3ChartPoint";
import {MouseGuidesD3ChartRenderable} from "../../charting/MouseGuidesD3ChartRenderable";
import {D3ChartLabeler} from "../../platform/d3chart/D3ChartLabeler";


function rpmCurveToPoints(rpms: number[], values: number[]) {
    const points: { rpm: number, frequency: number }[] = [];
    for (const idx in rpms) {
        points.push({rpm: rpms[idx], frequency: values[idx]});
    }
    return points;
}


export function ResonanceCampbellChart({resonanceCurve, workingPointResonances}: { resonanceCurve: ResonanceCurveJson, workingPointResonances: ResonancesAtSpeedJson }) {


    const chartSize = {width: 500, height: 350};
    const chartMargins = {left: 60, top: 0, right: 5, bottom: 40};


    const rpmAxis = new D3ChartAxis([0.0, workingPointResonances.rpm * 1.1], [0.0, chartSize.width - chartMargins.left - chartMargins.right], "bottom", 10, "d3-axis-slight-domain-line", 0, 0, "RPM [min-1]");
    const frequencyAxis = new D3ChartAxis([workingPointResonances.fDynFrequency * 1.3, 0.0], [0.0, chartSize.height - chartMargins.top - chartMargins.bottom], "left", 10, "d3-axis-slight-domain-line", 0, 0, "f [Hz]");

    const verticalGridLines = new D3ChartGridLines(rpmAxis.scale, rpmAxis, "vertical", "d3-stroke-1 d3-opacity-05 d3-stroke-black");
    const horizontalGridLines = new D3ChartGridLines(frequencyAxis.scale, frequencyAxis, "horizontal", "d3-stroke-1 d3-opacity-05 d3-stroke-black");
    const horizontalGridLinesStrong = new D3ChartGridLines(frequencyAxis.scale, frequencyAxis, "horizontal", "d3-stroke-1 d3-opacity-10 d3-stroke-black");

    const fDynCurve = new D3ChartLine<{ rpm: number, frequency: number }>(
        rpmAxis.scale,
        frequencyAxis.scale,
        rpmCurveToPoints(resonanceCurve.rpms, resonanceCurve.fDynFrequenciesForRpms),
        chPoint => chPoint.rpm,
        chPoint => chPoint.frequency,
        "d3-stroke-2 d3-stroke-primary");

    const rfCurve = new D3ChartLine<{ rpm: number, frequency: number }>(
        rpmAxis.scale,
        frequencyAxis.scale,
        rpmCurveToPoints(resonanceCurve.rpms, resonanceCurve.rfFrequenciesForRpms),
        chPoint => chPoint.rpm,
        chPoint => chPoint.frequency,
        "d3-stroke-1 d3-stroke-black");

    const rfDoubledCurve = new D3ChartLine<{ rpm: number, frequency: number }>(
        rpmAxis.scale,
        frequencyAxis.scale,
        rpmCurveToPoints(resonanceCurve.rpms, resonanceCurve.rfDoubledFrequenciesForRpm),
        chPoint => chPoint.rpm,
        chPoint => chPoint.frequency,
        "d3-stroke-1 d3-stroke-black");

    const bpfCurve = new D3ChartLine<{ rpm: number, frequency: number }>(
        rpmAxis.scale,
        frequencyAxis.scale,
        rpmCurveToPoints(resonanceCurve.rpms, resonanceCurve.bpfFrequenciesForRpms),
        chPoint => chPoint.rpm,
        chPoint => chPoint.frequency,
        "d3-stroke-1 d3-stroke-black");

    const lowerSafetyFrequencyCurve = new D3ChartLine<{ rpm: number, frequency: number }>(
        rpmAxis.scale,
        frequencyAxis.scale,
        rpmCurveToPoints(resonanceCurve.rpms, resonanceCurve.lowerSafetyFrequenciesForRpms),
        chPoint => chPoint.rpm,
        chPoint => chPoint.frequency,
        "d3-stroke-1 d3-stroke-rgb150",
        "3,3");

    const upperSafetyFrequencyCurve = new D3ChartLine<{ rpm: number, frequency: number }>(
        rpmAxis.scale,
        frequencyAxis.scale,
        rpmCurveToPoints(resonanceCurve.rpms, resonanceCurve.upperSafetyFrequenciesForRpms),
        chPoint => chPoint.rpm,
        chPoint => chPoint.frequency,
        "d3-stroke-1 d3-stroke-rgb150",
        "3,3");

    const workingPoint = new D3ChartPoint(rpmAxis.scale, frequencyAxis.scale, workingPointResonances.rpm, workingPointResonances.fDynFrequency, 5, undefined, "d3-fill-primary");

    const scaleManager = new D3ChartScaleManager([lowerSafetyFrequencyCurve,
        upperSafetyFrequencyCurve,
        bpfCurve,
        rfDoubledCurve,
        rfCurve,
        fDynCurve], 1.2, undefined, undefined, false, false);

    const lineLabeler = new D3ChartLabeler([
            {line: fDynCurve, label: "BONF",anchorXFactor: 0.6, anchorYFactor: 0.5},
            {line: rfCurve, label: "m=1",anchorXFactor: 0.5, anchorYFactor: 0.3},
            {line: rfDoubledCurve, label: "m=2",anchorXFactor: 0.5, anchorYFactor: 0.3},
            {line: bpfCurve, label: "BPF",anchorXFactor: 0.5, anchorYFactor: 0.3},
            {line: lowerSafetyFrequencyCurve, label: "Δ+SAFE",anchorXFactor: 0.1, anchorYFactor: 0.3},
            {line: upperSafetyFrequencyCurve, label: "Δ-SAFE",anchorXFactor: 0.1, anchorYFactor: 0.3, positionTextBelow: true}
        ]
    );
    const renderables: D3ChartRenderable[] = [
        //  scaleManager, // Scale manager comes first, to recalculate axes scales!
        rpmAxis,
        frequencyAxis,
        verticalGridLines,
        horizontalGridLines,
        horizontalGridLinesStrong,
        lowerSafetyFrequencyCurve,
        upperSafetyFrequencyCurve,
        bpfCurve,
        rfDoubledCurve,
        rfCurve,
        fDynCurve,
        workingPoint,
        lineLabeler,
        new MouseGuidesD3ChartRenderable(rpmAxis.scale, frequencyAxis.scale),
    ];

    return <D3ChartComponent width={chartSize.width} height={chartSize.height} chartRenderables={renderables} margins={chartMargins}/>
}

ResonanceCampbellChart.propTypes = {}

ResonanceCampbellChart.defaultProps = {}
