import {D3ChartRenderable, D3ChartSize, D3GSelection, D3SvgSelection} from "./D3Chart";
import {D3ChartLine} from "./D3ChartLine";
import * as d3Scale from "d3-scale";


export class D3ChartScaleManager implements D3ChartRenderable {

    /**
     *
     * @param lines
     * @param maxMultiplier
     * @param scaleXToManage If scale will be specified, then it will be managed, if not, manager will manage LINE's underlying scales (useful to manage separate-unit scale without affecting lines scale). Null will not perform management (useful to ignore single axis)
     * @param scaleYToManage If scale will be specified, then it will be managed, if not, manager will manage LINE's underlying scales (useful to manage separate-unit scale without affecting lines scale). Null will not perform management (useful to ignore single axis)
     */
    constructor(private lines: D3ChartLine<any>[],
                public maxMultiplier: number = 1.2,
                public scaleXToManage: d3Scale.ScaleLinear<number, number, never> | undefined | null = undefined,
                public scaleYToManage: d3Scale.ScaleLinear<number, number, never> | undefined | null = undefined,
                private autoManageXByLines: boolean = true,
                private autoManageYByLines: boolean = true) { // TODO Introduce interface of something scales-aware, instead of hard coupling with line
    }

    initialize(svgSelection: D3SvgSelection, rootG: D3GSelection, unClippedRootG: D3GSelection, size: D3ChartSize): void {
        let overalXMax = -Infinity;
        let overalYMax = -Infinity;
        this.lines.map(line => line.calculateMaximumValues()).forEach(({xMax, yMax}) => {
            if (xMax > overalXMax) {
                overalXMax = xMax;
            }
            if (yMax > overalYMax) {
                overalYMax = yMax;
            }
        });

        if (this.scaleXToManage !== undefined && this.scaleYToManage !== undefined) {
            this.scaleXToManage?.domain([0, overalXMax * this.maxMultiplier]);
            this.scaleYToManage?.domain([overalYMax * this.maxMultiplier, 0]);
            return
        }


        this.lines.forEach(line => {
            // Theoretically, all lines should use the same scalle, so any line could be used to get scales reference. But just in case update all
            // TODO: Better design
            //console.log("Scale manager detected max of X " + overalXMax + " and max of Y " + overalYMax);
            if (this.autoManageXByLines) {
                line.scaleX.domain([0, overalXMax * this.maxMultiplier]);
            }
            if (this.autoManageYByLines) {
                line.scaleY.domain([overalYMax * this.maxMultiplier, 0]);
            }
        })
    }

    render(): void {
        // SCALE MANAGER IS NOT ACTUALLY RENDERABLE. IT IS ATTACHED TO UPDATE D3 SCALES ACCORDING TO CHARTS (e.g. LINES) THAT USE THEM

        // In the future, render() should contain real-time scale application when data is changed live, or zoom used etc.
    }

}