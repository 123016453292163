import React from 'react';

import {curveToPoints} from "../../business/useImpellerSimulation";
import {D3ChartAxis} from "../../platform/d3chart/D3ChartAxis";
import {D3ChartGridLines} from "../../platform/d3chart/D3ChartGridLines";
import {D3ChartLine} from "../../platform/d3chart/D3ChartLine";
import {D3ChartScaleManager} from "../../platform/d3chart/D3ChartScaleManager";
import {D3ChartRenderable} from "../../platform/d3chart/D3Chart";
import {ResistanceCurveD3ChartRenderable} from "../../charting/ResistanceCurveD3ChartRenderable";
import {D3ChartComponent} from "../../platform/d3chart/D3ChartComponent";
import {D3ChartPoint} from "../../platform/d3chart/D3ChartPoint";
import {MouseGuidesD3ChartRenderable} from "../../charting/MouseGuidesD3ChartRenderable";
import {HoverExplainerD3ChartRenderable} from "charting/HoverExplainerD3ChartRenderable";
import {FlowCurve, FlowPoint} from "../../business/core";


export function PowerCurvesChart(props: {
    allAngleVariantsPowerCurves: { angle: number, flowCurve: FlowCurve }[],
    powerCurve: FlowCurve,
    powerWorkingPoint: FlowPoint,
    mainBladeAngleFormatted: string
}) {

    const chartSize = {width: 500, height: 350};
    const chartMargins = {left: 30, top: 0, right: 5, bottom: 40};

    const flowAxis = new D3ChartAxis([0.0, -1.0], [0.0, chartSize.width - chartMargins.left - chartMargins.right], "bottom", 15, "d3-axis-slight-domain-line", 0, 0, "Q [m³/s] [m³/h]");
    const flowAxis_m3h = new D3ChartAxis([0, -1], [0, chartSize.width - chartMargins.left - chartMargins.right], "bottom", 7, "d3-axis-nodomainline", 20);
    const powerAxis = new D3ChartAxis([-1.0, 0.0], [0.0, chartSize.height - chartMargins.top - chartMargins.bottom], "left", 20, "d3-axis-slight-domain-line", 0, 0, "N [kW]");

    const verticalGridLines = new D3ChartGridLines(flowAxis.scale, flowAxis, "vertical", "d3-stroke-1 d3-opacity-05 d3-stroke-black");
    const horizontalGridLinesStrong = new D3ChartGridLines(powerAxis.scale, powerAxis, "horizontal", "d3-stroke-1 d3-opacity-10 d3-stroke-black");

    const alternativePowerLines = props.allAngleVariantsPowerCurves
        .map(angleVariant => {
            const line = new D3ChartLine<{ flow: number, value: number }>(
                flowAxis.scale,
                powerAxis.scale,
                curveToPoints(angleVariant.flowCurve),
                chPoint => chPoint.flow,
                chPoint => chPoint.value * 0.001, // W to kW
                "d3-stroke-0_3 d3-stroke-black d3-opacity-50 ");
            line.setDescriber(point => "... " + angleVariant.angle.toFixed(2) + "° " + point.flow.toFixed(2) + " m3/s " + (point.value * 0.001).toFixed(2) + " kW");
            return line;
        });

    const powerLine = new D3ChartLine<{ flow: number, value: number }>(
        flowAxis.scale,
        powerAxis.scale,
        curveToPoints(props.powerCurve),
        chPoint => chPoint.flow,
        chPoint => chPoint.value * 0.001, // W to kW
        "d3-stroke-2 d3-stroke-primary");
    powerLine.setDescriber(point => "Dobrany " + props.mainBladeAngleFormatted + "°: " + point.flow.toFixed(2) + " m3/s " + (point.value * 0.001).toFixed(2) + " kW");

    const workingPoint = new D3ChartPoint(flowAxis.scale,
        powerAxis.scale,
        props.powerWorkingPoint.flow,
        props.powerWorkingPoint.value, // W to kW
        5,
        undefined,
        "d3-fill-primary");

    const scaleManager = new D3ChartScaleManager([powerLine, ...alternativePowerLines], 1.2, flowAxis.scale);
    const scaleManager_m3h = new D3ChartScaleManager([powerLine, ...alternativePowerLines], 1.2 * 3600, flowAxis_m3h.scale, null);

    const renderables: D3ChartRenderable[] = [
        scaleManager, // Scale manager comes first, to recalculate axes scales!
        scaleManager_m3h,
        flowAxis,
        flowAxis_m3h,
        powerAxis,
        verticalGridLines,
        //horizontalGridLines,
        horizontalGridLinesStrong,
        new ResistanceCurveD3ChartRenderable(flowAxis.scale, powerAxis.scale),
        ...alternativePowerLines,
        powerLine,
        workingPoint,
        new MouseGuidesD3ChartRenderable(flowAxis.scale, powerAxis.scale),
        new HoverExplainerD3ChartRenderable([...alternativePowerLines, powerLine])
    ];

    return <D3ChartComponent width={chartSize.width} height={chartSize.height} chartRenderables={renderables}
                             margins={chartMargins}/>
}
