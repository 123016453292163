import {D3ChartRenderable, D3ChartSize, D3GSelection, D3SvgSelection} from "../platform/d3chart/D3Chart";
import * as d3Scale from "d3-scale";
import * as d3Shape from 'd3-shape';

export class ResistanceCurveD3ChartRenderable implements D3ChartRenderable {

    private g: D3GSelection | undefined;
    private targetPoint: { x: number, y: number } = {x: 0, y: 0};
    private mouseEnabled: boolean = true;
    private forcedTargetPoint: { x: number, y: number } | null = null;

    constructor(private scaleX: d3Scale.ScaleLinear<number, number, never>,
                private scaleY: d3Scale.ScaleLinear<number, number, never>) {
    }

    initialize(svgSelection: D3SvgSelection, rootG: D3GSelection, unClippedRootG: D3GSelection, size: D3ChartSize): void {
        this.g = rootG.append("g"); // TODO it is needed to add g? maybe path directly?
        this.g.append("path"); // actual line

        if (this.forcedTargetPoint !== null) {
            // We recalculate forced target point in initialize, not setter, to be sure potential axis scale manager was initalized already
            this.targetPoint = {
                x: this.scaleX(this.forcedTargetPoint.x),
                y: this.scaleY(this.forcedTargetPoint.y)
            }
        }
    }

    disableMouse() {
        this.mouseEnabled = false;
        return this;
    }

    withTargetPoint(xDomain: number, yDomain: number) {
        // Intentionally do not set targetPoint but buffer it to initalize, to wait for potential axes cale manipulation
        // TODO: implement kind of listener for scale change, so that dynamic changing scale can be supported
        this.forcedTargetPoint = {
            x: xDomain,
            y: yDomain
        }
        return this;
    }

    onMouseMove(x: number, y: number): "repaint" | "norepaint" {
        if (this.mouseEnabled) {
            this.targetPoint = {x, y};
            return "repaint";
        } else {
            return "norepaint";
        }
    }

    onMouseOut(): "repaint" | "norepaint" {
        if (this.mouseEnabled) {
            this.targetPoint = {x: -1, y: -1}; // TODO improve, may fail on charts with negative values presented
            return "repaint";
        } else {
            return "norepaint";
        }
    }


    render(): void {
        const minXDomain = this.scaleX.domain()[0];
        const maxXDomain = this.scaleX.domain()[1];
        const minYDomain = this.scaleY.domain()[1];
        const maxYDomain = this.scaleY.domain()[0]; // 0 because Y-domain is usually inverted e.g. [10,0]

        const pickedPressure = this.scaleY.invert(this.targetPoint.y);
        const pickedFlow = this.scaleX.invert(this.targetPoint.x);
        var resistanceFactor = pickedPressure / (pickedFlow * pickedFlow);

        if (pickedPressure < minYDomain ||
            pickedPressure > maxYDomain ||
            pickedFlow > maxXDomain ||
            pickedFlow < minXDomain) {
            this.g?.select("path")
                .attr("stroke-dasharray", "0,1")
            return;
        }

        var resistanceCurve = function (flow: number): number {
            return flow * flow * resistanceFactor;
        };

        var resistanceCurvePoints: [number, number][] = [];
        var progressFactor = 0;
        do {
            var currentFlow = progressFactor * maxXDomain;
            var currentPressure = resistanceCurve(currentFlow);

            if (currentFlow <= maxXDomain && currentPressure <= maxYDomain) {
                resistanceCurvePoints.push([currentFlow, currentPressure]);
            }
            if (progressFactor < 0.1) {
                progressFactor += 0.001;
            } else {
                progressFactor += 0.01;
            }
        } while (currentFlow <= maxXDomain && currentPressure <= maxYDomain);


        const lineFunc = d3Shape.line()
            .x(d => this.scaleX(d[0]))
            .y(d => this.scaleY(d[1]));

        this.g?.select("path")
            .attr("stroke-dasharray", "20,10")
            .attr("class", "d3-stroke-1 d3-stroke-rgb200")
            .attr("d", lineFunc(resistanceCurvePoints))
    }

}
