/*
case 0:
				// Obliczanie srednicy obudowy(D) z srednicy wirnika(dw) i szczeliny(S)
				//D = -((dw*100)/(S-100));
				//D = -((dw*100)/((S/2)-100));
				D=dw/(1-2*(S/100));
				cd("Obliczam srednice D z dw="+dw+" i S="+S+"  D=Dw/(1-2*(S/100))");
				break;
			case 1:
				// Obliczanie szczeliny(S) z srednicy wirnika(dw) i srednicy obudowy(D)
				//S = ((D-dw)/D)*100;
				//S = 2*(100-(dw*100) / D);
				 S=100*(D-dw)/(2*D);
				cd("Obliczam szczeline S z dw="+dw+" i D="+D+"    S=100*(D-Dw)/(2*D)");
				break;
			case 2:
				// Obliczanie srednicy wirnika(dw) ze szczeliny(S) i srednicy obudowy(D)
				//dw = ((100-S)/100)*D;
				//dw = (-D*( (S/2)-100 ))/100;
				//dw=D-(S/100)*(2*D)
				dw=D-(2*D*S/100);
 */


import {WentechMeasuringStandards} from "../measurement/WentechMeasurementStandards";
import {useEditor} from "../../form/EditorReact";
import {createEditorForMeasurementStandard, MeasureEditorValue} from "../../form/editors/MeasureEditor";
import {LabeledEditor} from "../../pages/selection/useFanCriteria";
import {createInternationalizationPrefixer, i18n} from "../../platform/i18n/i18n";
import React, {useEffect, useState} from "react";
import {MeasurementStandard} from "../../platform/measurement/MeasurementStandard";
import {OptionalEditor} from "../../form/editors/OptionalEditor";
import {BladeManufacturingTypeCode} from "../../pages/mechanical/ImpellerAssemblyService";

export interface DimensionDetails {
    impellerDiameter: number,
    /**
     * Tip clearance is ratio of HOUSING diameter.
     * Eg. clearance of 0.1 is when housing diameter of 5000 has impeller diameter 4000
     * (so the clearance is 500 each side, which is 10% of housing diameter)
     */
    bladeTipClearanceRatio: number
}

export interface DimensionsEditorValue {
    dimensionDetails: DimensionDetails | null,
    editorComponent: JSX.Element,
    reset: () => void,
    prepopulate: (dimensionDetails: DimensionDetails) => void
}

enum DimensionsEditorMode {
    CALCULATE_IMPELLER_DIAMETER,
    CALCULATE_BLADE_TIP_CLEARANCE,
    CALCULATE_HOUSING_DIAMETER
}

const t = createInternationalizationPrefixer("DimensionEditor");

export class DimensionRestrictions {
    public static FOR_STANDARD_MANUFACTURING = new DimensionRestrictions(0.35, 0.65);
    public static FOR_ASSG_MANUFACTURING: DimensionRestrictions = new DimensionRestrictions(0.5, 1.0);

    public static createForManufacturing = (manufacturing: BladeManufacturingTypeCode): DimensionRestrictions => {
        if (manufacturing === "standard") {
            return DimensionRestrictions.FOR_STANDARD_MANUFACTURING;
        }
        if (manufacturing === "assg") {
            return DimensionRestrictions.FOR_ASSG_MANUFACTURING;
        }
        throw new Error("No defined dimensions strategy restriction for manufacturing: " + manufacturing);
    }

    constructor(readonly minTipClearancePercent: number,
                readonly maxTipClearancePercent: number) {
    }

    hashcode = () => {
        return this.minTipClearancePercent + 1000 * this.maxTipClearancePercent;
    }
}

export function useDimensionsEditorFacade(dimensionRestrictions: DimensionRestrictions): DimensionsEditorValue {

    const [mode, setMode] = useState<DimensionsEditorMode>(DimensionsEditorMode.CALCULATE_HOUSING_DIAMETER);

    const impellerDiameterEditor = useOptionalDimensionEditor(WentechMeasuringStandards.IMPELLER_DIAMETER, true, () => {
        setMode(DimensionsEditorMode.CALCULATE_IMPELLER_DIAMETER)
    });
    const bladeTipClearanceEditor = useOptionalDimensionEditor(WentechMeasuringStandards.BLADE_TIP_CLEARANCE, true, () => {
        setMode(DimensionsEditorMode.CALCULATE_BLADE_TIP_CLEARANCE)
    });
    const housingDiameterEditor = useOptionalDimensionEditor(WentechMeasuringStandards.HOUSING_DIAMETER, true, () => {
        setMode(DimensionsEditorMode.CALCULATE_HOUSING_DIAMETER)
    });

    useEffect(() => {
        mode === DimensionsEditorMode.CALCULATE_BLADE_TIP_CLEARANCE ? bladeTipClearanceEditor.disable() : bladeTipClearanceEditor.enable();
        mode === DimensionsEditorMode.CALCULATE_IMPELLER_DIAMETER ? impellerDiameterEditor.disable() : impellerDiameterEditor.enable();
        mode === DimensionsEditorMode.CALCULATE_HOUSING_DIAMETER ? housingDiameterEditor.disable() : housingDiameterEditor.enable();
    }, [mode]);


    let impellerDiameter: MeasureEditorValue;
    let bladeTipClearanceRatio: MeasureEditorValue;
    let housingDiameter: MeasureEditorValue;
    if (mode !== DimensionsEditorMode.CALCULATE_IMPELLER_DIAMETER) {
        impellerDiameter = impellerDiameterEditor.value;
    } else {
        if (housingDiameterEditor.value == null || bladeTipClearanceEditor.value == null) {
            // We cannot calculate if one of inputs is incorrect
            impellerDiameter = null;
        } else {
            impellerDiameter = housingDiameterEditor.value - (2 * housingDiameterEditor.value * bladeTipClearanceEditor.value / 100);
        }
    }

    if (mode !== DimensionsEditorMode.CALCULATE_BLADE_TIP_CLEARANCE) {
        bladeTipClearanceRatio = bladeTipClearanceEditor.value;
    } else {
        if (housingDiameterEditor.value == null || impellerDiameterEditor.value == null) {
            // We cannot calculate if one of inputs is incorrect
            bladeTipClearanceRatio = null;
        } else {
            bladeTipClearanceRatio = Number((100 * (housingDiameterEditor.value - impellerDiameterEditor.value) / (2 * housingDiameterEditor.value)).toFixed(2));
        }
    }

    if (mode !== DimensionsEditorMode.CALCULATE_HOUSING_DIAMETER) {
        housingDiameter = housingDiameterEditor.value;
    } else {
        if (impellerDiameterEditor.value == null || bladeTipClearanceEditor.value == null) {
            housingDiameter = null;
        } else {
            housingDiameter = impellerDiameterEditor.value / (1 - 2 * (bladeTipClearanceEditor.value / 100));
        }
    }

    // Propagate calculated value back to editor if changed
    useEffect(() => {
        if (mode === DimensionsEditorMode.CALCULATE_IMPELLER_DIAMETER) {
            impellerDiameterEditor.populate(impellerDiameter);
        }
    }, [impellerDiameter])
    useEffect(() => {
        if (mode === DimensionsEditorMode.CALCULATE_BLADE_TIP_CLEARANCE) {
            bladeTipClearanceEditor.populate(bladeTipClearanceRatio);
        }
    }, [bladeTipClearanceRatio])
    useEffect(() => {
        if (mode === DimensionsEditorMode.CALCULATE_HOUSING_DIAMETER) {
            housingDiameterEditor.populate(housingDiameter);
        }
    }, [housingDiameter]);


    const reset = () => {
        if (mode !== DimensionsEditorMode.CALCULATE_IMPELLER_DIAMETER) impellerDiameterEditor.reset();
        if (mode !== DimensionsEditorMode.CALCULATE_BLADE_TIP_CLEARANCE) bladeTipClearanceEditor.reset();
        if (mode !== DimensionsEditorMode.CALCULATE_HOUSING_DIAMETER) housingDiameterEditor.reset();
    }

    const prepopulate = (dimensionDetails: DimensionDetails) => {
        setMode(DimensionsEditorMode.CALCULATE_HOUSING_DIAMETER);
        impellerDiameterEditor.populate(dimensionDetails.impellerDiameter);
        bladeTipClearanceEditor.populate(dimensionDetails.bladeTipClearanceRatio);
    }

    let tipClearanceOutOfRange = false;
    if (bladeTipClearanceRatio !== null && (bladeTipClearanceRatio < dimensionRestrictions.minTipClearancePercent || bladeTipClearanceRatio > dimensionRestrictions.maxTipClearancePercent)) {
        tipClearanceOutOfRange   = true;
    }

    const dimensionDetails = (impellerDiameter == null || housingDiameter == null || bladeTipClearanceRatio == null || tipClearanceOutOfRange) ? null : {
        impellerDiameter,
        bladeTipClearanceRatio
    };

    const editorComponent = <>
        <LabeledEditor label={t("Średnica wirnika")} editor={impellerDiameterEditor}/>
        <LabeledEditor label={t("Średnica obudowy")} editor={housingDiameterEditor}/>
        <LabeledEditor label={t("Szczelina (% średnicy na stronę)")} editor={bladeTipClearanceEditor}/>

        {tipClearanceOutOfRange && <div className={"alert alert-warning text-center p-1"}><i className="fas fa-exclamation-triangle"></i> {t("Szczelina poza dopuszczalnym zakresem")} {dimensionRestrictions.minTipClearancePercent} - {dimensionRestrictions.maxTipClearancePercent} %</div>}
    </>;


    return {
        dimensionDetails,
        editorComponent,
        reset,
        prepopulate
    };
}

export function useOptionalDimensionEditor(standard: MeasurementStandard, initiallyEnabled: boolean = true, onRequestToCalculate: () => void): OptionalEditor<MeasureEditorValue> {
    return useEditor<OptionalEditor<MeasureEditorValue>>(() =>
        new OptionalEditor<MeasureEditorValue>(
            createEditorForMeasurementStandard(standard),
            initiallyEnabled,
            (toggleEnablement, delegate) =>
                <>
                    <div className={"pt-2 pb-2"}>
                        {delegate.value} {standard.measuringOptions.baseUnit.displayText}
                    </div>
                </>,
            (DelegatedEditorComponent, toggleEnablement) =>
                <div className={"d-flex"}>
                    <div>
                        <DelegatedEditorComponent/>
                    </div>
                    <div className={"ps-3"}>
                        <button className={"btn btn-xxs btn-outline btn-outline-success p-2"}
                                onClick={onRequestToCalculate}>{t("Oblicz")}
                        </button>
                    </div>
                </div>
        )
    )
}