import React, {ChangeEventHandler} from 'react';
import {OrderId, SearchFilter, SearchFilterManager, SearchResultsOrder, SelectOption} from "../../platform/search/PlatformSearch";

export function SearchQueryBar(props: {
    queryFilterManager: {
        humanFriendlyName: string;
        onChange: (event: React.FormEvent<HTMLInputElement>) => void;
        currentFilterOrNull: SearchFilter | null;
        applyCriteria: () => void; reset: () => void; text: string
    },
    reset: () => void,
    refresh: () => void
}) {
    return <div className={"d-flex align-items-center"}>
        <div className="position-relative me-3 flex-fill">
            <span className="position-absolute top-50 translate-middle ms-6"><i className={"fas fa-search"}/></span>
            <input type="text" className="form-control form-control-solid ps-10" value={props.queryFilterManager.text} onChange={props.queryFilterManager.onChange} placeholder={props.queryFilterManager.humanFriendlyName}/>
        </div>
        <button className={"btn btn-xs btn-light-primary me-3"} onClick={props.reset}><i className="fas fa-undo"/> Resetuj filtry</button>
        <button className={"btn btn-xs btn-light-primary me-3"} onClick={props.refresh}><i className="fas fa-sync"/> Odśwież</button>
    </div>;
}

export function AdditionalTextFilter(props: {
    fanNameFilterManager: { humanFriendlyName: string; onChange: (event: React.FormEvent<HTMLInputElement>) => void; currentFilterOrNull: SearchFilter | null; applyCriteria: () => void; reset: () => void; text: string }
}) {
    return <>
        <label className="fs-7 form-label fw-bolder text-gray-600">{props.fanNameFilterManager.humanFriendlyName}</label>
        <input type="text" className="form-control form-control form-control-solid fs-7 p-2" value={props.fanNameFilterManager.text} onChange={props.fanNameFilterManager.onChange}/>
    </>;
}

export function AdditionalSelectFilter(props: {
    filterManager: { humanFriendlyName: string; onChange: ChangeEventHandler<HTMLSelectElement>; currentFilterOrNull: SearchFilter | null; applyCriteria: () => void; reset: () => void; currentValue: string, options:SelectOption[]}
}) {
    return <>
        <label className="fs-7 form-label fw-bolder text-gray-600">{props.filterManager.humanFriendlyName}</label>
        <select className="form-control form-control form-control-solid fs-7 p-2" value={props.filterManager.currentValue} onChange={props.filterManager.onChange as unknown as ChangeEventHandler<HTMLSelectElement>}>
            {props.filterManager.options.map(opt=><option value={opt.value}>{opt.label}</option>)}
        </select>
    </>;
}

export function CurrentlyUsedFilters({currentlyActiveFilterManagers}: { currentlyActiveFilterManagers: SearchFilterManager[] }) {
    return <>
        {currentlyActiveFilterManagers.length > 0 && <span className={"fw-bold text-gray-400"}>Wyszukiwanie dla: </span>}
        {currentlyActiveFilterManagers.map(manager =>
            <span className={"badge badge-primary me-2"}>
                            <span className="fw-lighter">{manager.currentFilterOrNull?.humanFriendlyName}:</span>
                            <strong>{manager.currentFilterOrNull?.humanFriendlyTextualValue}</strong>
                            <button className={"text-white fas fa-times ms-2 btn btn-flush"} onClick={manager.reset}></button>
                    </span>)}
    </>;
}

export function OrderSelector(props: { changeOrderId: (orderId: OrderId) => void, currentOrderId: string | undefined, availableOrders: SearchResultsOrder[] }) {
    return <>
        <span className={"fw-bold text-gray-400 pt-3"}><i className="fas fa-sort-amount-down text-primary"/></span>
        <select className=" fs-7 form-control form-control-flush" onChange={e => props.changeOrderId(e.currentTarget.value)} value={props.currentOrderId}>
            {props.availableOrders.map(order => <option value={order.id}>{order.name} {order.informationalDirection === "asc" ? '↑' : "↓"}</option>)}
        </select>
    </>;
}
