import React from 'react';
import PropTypes from 'prop-types';


export function Button(props) {
    const {type, bootstrapStyle, ...otherProps} = props;
    if (props.type === 'standard') {
        return <button {...otherProps} className={"btn btn-" + props.bootstrapStyle + " " + props.className}>{props.children}</button>
    } else if (props.type === 'active-only') {
        return <button {...otherProps} className={"btn btn-active-" + props.bootstrapStyle + " " + props.className}>{props.children}</button>
    } else if (props.type === 'link') {
        return <button {...otherProps} className={"btn btn-link btn-color-" + props.bootstrapStyle + " btn-active-color-" + props.bootstrapStyle + " " + props.className}>{props.children}</button>
    } else if (props.type === 'flush') {
        return <button {...otherProps} className={"btn btn-flush " + props.className}>{props.children}</button>
    }
    throw new Error("button type not supported: " + props.type);
}

Button.propTypes = {
    bootstrapStyle: PropTypes.oneOf(['white', 'primary', 'light', 'secondary', 'success', 'info', 'warning', 'danger', 'dark']),
    /**
     * active-only - no background/border by default, only when hover/active
     * link - no background/border but still taktes place like a button (with padding etc)
     * flush = no paddings, border, backgroun etc.
     */
    type: PropTypes.oneOf(['standard', 'active-only', 'link', 'flush'])


};

Button.defaultProps = {
    bootstrapStyle: 'primary',
    type: 'standard',
    flushed: false
};
