import {D3ChartRenderable, D3ChartSize, D3GSelection, D3SvgSelection} from "platform/d3chart/D3Chart";
import {D3ChartLine} from "platform/d3chart/D3ChartLine";


export class HoverExplainerD3ChartRenderable<T> implements D3ChartRenderable {

    private g: D3GSelection | undefined;
    private labels: string[] = [];
    private hoverPoints: { x: number, y: number }[] = [];

    constructor(public readonly lines: D3ChartLine<T>[], private readonly onlySingleClosestPoint = false) {
    }

    initialize(svgSelection: D3SvgSelection, rootG: D3GSelection, unClippedRootG: D3GSelection, size: D3ChartSize): void {
        this.g = rootG.append("g"); // TODO it is needed to add g? maybe path directly?
        this.g.append("circle")
            .attr("r", 0) // TODO present multiple points or most important point. for now it's just labels
            .attr("fill", "red");
    }

    onMouseMove(x: number, y: number): "repaint" | "norepaint" {

        let popupTexts: string[] = [];
        let globalBestError = Number.MAX_VALUE;
        let hoverPoints: { x: number, y: number }[] = [];
        for (const line of this.lines) {
            if (!line.canDescribePoints()) {
                continue;
            }
            let bestError = Number.MAX_VALUE;
            let bestPoint: T | null = null;

            for (const point of line.points) {
                const px = line.scaleX(line.xExtractor(point))
                const py = line.scaleY(line.yExtractor(point));

                const err = (px - x) * (px - x) + (py - y) * (py - y);
                if (err < bestError) {
                    bestError = err;
                    bestPoint = point;
                }

            }
            if (bestPoint != null && bestError < 100) {
                if (this.onlySingleClosestPoint) {
                    if (bestError < globalBestError) {
                        globalBestError = bestError;
                        popupTexts.length = 0;
                        hoverPoints.length = 0;
                        popupTexts.push(line.describe(bestPoint));
                        hoverPoints.push({
                            x: line.scaleX(line?.xExtractor(bestPoint)),
                            y: line.scaleY(line?.yExtractor(bestPoint))
                        });
                    }
                } else {
                    popupTexts.push(line.describe(bestPoint));
                    hoverPoints.push({
                        x: line.scaleX(line?.xExtractor(bestPoint)),
                        y: line.scaleY(line?.yExtractor(bestPoint))
                    });
                }
            }
        }


        if (popupTexts.length > 0) {
            this.labels = popupTexts;
            this.hoverPoints = hoverPoints;
        } else {
            this.hoverPoints = [];
        }

        return "repaint";
    }

    onMouseOut(): "repaint" | "norepaint" {
        this.hoverPoints = [];
        this.labels = [];
        return "repaint";
    }

    render(): void {

        //console.log(this.hoverPoints);

        const firstPointX = this.hoverPoints?.[0]?.x ?? -100;
        const firstPointY = this.hoverPoints?.[0]?.y ?? -100;

        //this.g!
        //  .attr("transform", "translate(" + this.hoverPoints?.[0]?.x ?? "-100" + "," + this.hoverPoints?.[0]?.y ?? "-100" + ")");
        //.attr("y", this.hoverPoints?.[0]?.y ?? "-100");

        const data = this.g!.selectAll<SVGTextElement, string>("text").data(this.labels, val => Math.random());
        data.enter().append("text")
            .attr("x", (datum, q) => firstPointX + 15)
            .attr("y", (datum, q) => firstPointY - 15 + q * 20)
            .text(datum => datum)
            .attr("font-size", "12px")
            .attr("fill", "black")
        data.exit().remove();

        const pointData = this.g!.selectAll<SVGCircleElement, { x: number, y: number }>("circle").data(this.hoverPoints, p => Math.random());
        pointData.enter().append("circle")
            .attr("cx", (datum, q) => datum.x)
            .attr("cy", (datum, q) => datum.y)
            .attr("r", 2) // TODO present multiple points or most important point. for now it's just labels
            .attr("fill", "black");
        //
        // type Reference = { point:  { x: number, y: number }, label : { x: number, y: number }};
        // const referenceData : Reference[] = [];
        // for ( let idx = 0; idx < this.hoverPoints.length ; idx++ ) {
        //     referenceData.push({
        //         point: this.hoverPoints[idx],
        //         label: {
        //             x:firstPointX + 25,
        //             y:firstPointY - 15 - idx * 20
        //         }
        //     });
        // }
        //
        // const referencesSelection = this.g!.selectAll<SVGLineElement, Reference>("line").data(referenceData, p => Math.random());
        //
        // referencesSelection.enter().append("line")
        //     .attr("x1", (datum, q) => datum.point.x)
        //     .attr("y1", (datum, q) => datum.point.y)
        //     .attr("x2", (datum, q) => datum.label.x)
        //     .attr("y2", (datum, q) => datum.label.y)
        //     .attr("stroke", "black");
        // referencesSelection.exit().remove();


        // .attr("class",this.className)
        // .attr("stroke-dasharray", this.strokeDashArray)

        pointData.exit().remove();


    }
}