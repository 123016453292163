import {useNavigate} from "react-router";
import {useEffect} from "react";

export function HomePage(props) {

    const nav = useNavigate();
    useEffect(() => {
        nav("/selection");
    }, []);
    return null;
}

HomePage.propTypes = {}

HomePage.defaultProps = {}
