import React, {ChangeEvent, ChangeEventHandler, EventHandler, useCallback, useState} from 'react';
import {debounce} from "lodash";
import useDeepCompareEffect from "use-deep-compare-effect";
import axios, {AxiosResponse} from "axios";
import AuthTokenStorageService from "../authentication/AuthTokenStorageService";

export type AsyncSearchResultsProvider<CRITERIA, RESULT> = (criteria: CRITERIA, orderId?: OrderId, part?: SearchResultsPart) => Promise<RESULT[]>;

export type FilterId = string;

export type OrderId = string;

export interface SearchFilter {
    id: FilterId,
    humanFriendlyName: string,
    value: string | number | boolean | object, // TODO maybe create interface for "something jsonable"? ... or generify as sometimes it might be useful to set value as "yes" | "no" etc.
    humanFriendlyTextualValue: string | JSX.Element
}

export interface SearchResultsOrder {
    id: OrderId,
    name: string,
    informationalDirection: "asc" | "desc"
}

export interface SearchResultsPart {
    offset: number,
    limit: number
}

export interface SearchFilterManager {
    currentFilterOrNull: SearchFilter | null,
    reset: () => void
}


function buildCriteriaFromFilters<T>(filters: SearchFilter[]): T {
    let criteriaObject: any = {}; // TODO understand why this compiles (any can be returned as T)
    for (const filter of filters) {
        criteriaObject[filter.id] = filter.value;
    }
    return criteriaObject;
}

export function useSearch<CRITERIA, RESULT>(asyncResultsProvider: AsyncSearchResultsProvider<CRITERIA, RESULT>,
                                            filterManagers: SearchFilterManager[],
                                            availableOrders: SearchResultsOrder[],
                                            resultsOnPage: number,
                                            additionalRefreshDependencies?: any[]) {
    const [currentResults, setCurrentResults] = useState<RESULT[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [currentOrderId, setCurrentOrderId] = useState<OrderId | undefined>(availableOrders.length > 0 ? availableOrders[0].id : undefined);
    const [currentPart, setCurrentPart] = useState<SearchResultsPart | undefined>({offset: 0, limit: resultsOnPage});

    const currentlyActiveFilterManagers = filterManagers.filter(m => m.currentFilterOrNull !== null);
    const currentlyActiveFilters = currentlyActiveFilterManagers.map(f => f.currentFilterOrNull) as SearchFilter[];

    const cancellableCallApi = () => {
        let cancelled = false;

        const callApi = async () => {
            setIsLoading(true);
            setCurrentResults([]);
            try {
                const results = await asyncResultsProvider(buildCriteriaFromFilters<CRITERIA>(currentlyActiveFilters), currentOrderId, currentPart);
                if (!cancelled) {
                    setCurrentResults(results);
                }
            } finally {
                if (!cancelled) {
                    setIsLoading(false);
                }
            }
        }

        callApi();

        return () => {
            cancelled = true;
        }
    }

    useDeepCompareEffect(() => {
        console.log("Calling effect because active filters is", currentlyActiveFilters);
        const cancelFunction = cancellableCallApi(); // TODO use one of npm libs for async effect
        return cancelFunction;
    }, [currentlyActiveFilters, currentOrderId, currentPart, ...additionalRefreshDependencies || []]);

    useDeepCompareEffect(() => {
        if (currentPart?.offset && currentPart?.offset > 0) {
            // Note every filter change or order chang need to reset pagination
            setCurrentPart({offset: 0, limit: resultsOnPage});
        }
    }, [currentlyActiveFilters, currentOrderId]);

    const reset = () => {
        filterManagers.forEach(m => m.reset());
        setCurrentPart({offset: 0, limit: resultsOnPage});
        // TODO Rething bahavior when this component is reused on multiple URL paths and switching URL needs trully complete reset (what with isLoading?)
    }

    const changeOrderId = (orderId: OrderId) => {
        if (!availableOrders) {
            throw new Error("Cannot set order of " + orderId + " when there is none order available");
        }
        if (availableOrders.map(o => o.id).filter(id => id === orderId).length === 0) {
            console.error("incorrect order id: " + orderId);
            return;
        }
        setCurrentOrderId(orderId);
    }

    const {nextPageSwitchAvailable, switchToNextPage, currentPage} = calculatePaginationForSearch(resultsOnPage, isLoading, currentResults, setCurrentPart, currentPart);

    return {
        results: currentResults,
        isLoading,
        currentlyActiveFilters,
        currentlyActiveFilterManagers,
        currentOrderId,
        changeOrderId,
        refresh: () => {
            cancellableCallApi(); // TODO investigate possibitilies of non-cancellation and race condition
        },
        reset,
        currentPart,
        setCurrentPart,
        paginationDriver: {
            nextPageSwitchAvailable,
            switchToNextPage,
            currentPage
        }
    };
}

/**
 *
 * @param id
 * @param humanFriendlyName
 * @param autoFilterUpdate Whenever filter changs is applied on change (after onChanged with debounce) or must be triggered manually (applyCriteria)
 * @param defaultValue Default/initial value (textual)
 */
export function useTextFilterManager(id: string, humanFriendlyName: string, autoFilterUpdate = true, defaultValue = "", fixNumbers = false) {

    function getFilterValueFromText(text: string): SearchFilter | null {
        if (text.trim() === "") {
            return null;
        } else {
            return {
                id: id,
                humanFriendlyName: humanFriendlyName,
                value: text,
                humanFriendlyTextualValue: text
            }
        }
    }

    const [text, setText] = useState<string>(defaultValue);
    const [activeFilter, setActiveFilter] = useState<SearchFilter | null>(getFilterValueFromText(defaultValue));

    const updateFilter = (val: string) => {
        if (fixNumbers) {
            val = val.replace(",", ".");
        }
        setActiveFilter(getFilterValueFromText(val));
    }


    // TODO: what if someone clicked reset/remove where debounce is in progress? (actually new empty-text debounce should be shceduled, but it may involve duplication)
    const debouncedAction = useCallback(debounce((val: string) => {
        updateFilter(val);
    }, 500), []); // TODO see linter warning


    const onChange = (event: React.FormEvent<HTMLInputElement>) => {
        let newText = event.currentTarget.value;
        if (fixNumbers) {
            newText = newText.replace(",", ".");
        }
        setText(newText);
        if (autoFilterUpdate) {
            debouncedAction(event.currentTarget.value); // TODO consider alternative of reacting to text by effect
        }
    }

    const applyCriteria = () => {
        updateFilter(text);
    }

    const reset = () => {
        setText("");
        setActiveFilter(null);
        // Call to debounced action with empty string is required to kill any pending debounced non-empty string that could trigger moments after immediate reset
        debouncedAction("");
    }

    return {
        text,
        humanFriendlyName,
        onChange,
        applyCriteria,
        currentFilterOrNull: activeFilter,
        reset
    }
}

export interface SelectOption {
    label: string;
    value: string;
}

export function useSelectFilterManager(id: string, humanFriendlyName: string, options: SelectOption[], autoFilterUpdate = true) {
    if (options.length === 0) {
        options = [{label: "", value: ""}];
    }
    const [currentValue, setCurrentValue] = useState(options[0].value);
    const [activeFilter, setActiveFilter] = useState<SearchFilter | null>(currentValue === null ? null : {
        id: id,
        humanFriendlyName: humanFriendlyName,
        value: currentValue,
        humanFriendlyTextualValue: options.filter(o => o.value === currentValue)[0].label
    });

    const updateFilter = (val: string) => {
        if (val.trim() === "") {
            setActiveFilter(null);
        } else {
            setActiveFilter({
                id: id,
                humanFriendlyName: humanFriendlyName,
                value: val,
                humanFriendlyTextualValue: options.filter(o => o.value === val)[0].label
            });
        }
    }

    const onChange = (event: ChangeEvent<any>) => {
        setCurrentValue(event.currentTarget.value);
        if (autoFilterUpdate) {
            updateFilter(event.currentTarget.value); // TODO consider alternative of reacting to text by effect
        }
    }

    const applyCriteria = () => {
        updateFilter(currentValue);
    }

    const setCurrentValueExternally = (val: string) => {
        setCurrentValue(val);
        if (autoFilterUpdate) {
            updateFilter(val); // TODO consider alternative of reacting to text by effect
        }
    }

    const reset = () => {
        setCurrentValue(options[0].value);
        setActiveFilter(null);
    }

    return {
        setCurrentValueExternally,
        currentValue: currentValue,
        options: options,
        humanFriendlyName,
        onChange,
        applyCriteria,
        currentFilterOrNull: activeFilter,
        reset
    }
}

export function calculatePaginationForSearch(resultsOnPage: number, isLoading: boolean, results: any[], setCurrentPart: (p: SearchResultsPart) => any, currentPart?: SearchResultsPart) {
    // TODO introduce more sophosticated management of requesting limit+1 object and present next page only if there will be more than limit results
    const switchToNextPage = () => {
        if (currentPart) {
            setCurrentPart({...currentPart, offset: currentPart.offset + resultsOnPage});
        } else {
            setCurrentPart({offset: resultsOnPage, limit: resultsOnPage});
        }
    }

    const nextPageSwitchAvailable = !isLoading && results && results.length === resultsOnPage;

    let currentPage = 1;
    if (currentPart) {
        currentPage = currentPart.offset / resultsOnPage + 1;
    }

    return {
        nextPageSwitchAvailable: nextPageSwitchAvailable,
        switchToNextPage,
        currentPage
    }
}

export function createAsyncResultsProviderViaApiWithGetParams<R>(url: string): AsyncSearchResultsProvider<any, R> {
    return async (criteria: any, orderId?: OrderId, part?: SearchResultsPart): Promise<R[]> => {
        let paramsToSend: any = criteria;
        if (orderId) {
            paramsToSend.orderId = orderId;
        }
        if (part) {
            paramsToSend.offset = part.offset;
            paramsToSend.limit = part.limit;
        }

        const currentToken = AuthTokenStorageService.getCurrentToken();

        return axios.get<R[]>(url, {
            timeout: 5000,
            maxRedirects: 0,
            responseEncoding: "UTF-8",
            headers: {
                "Authorization": 'Bearer ' + currentToken
            } ,
            responseType: "json",
            params: paramsToSend
        }).then((axiosResponse: AxiosResponse<R[]>) => {
            console.log("Received Axios response", axiosResponse);
            if (axiosResponse.status < 200 || axiosResponse.status > 299) {
                throw new Error("Failed to fetch"); // TODO more error details
            }
            return axiosResponse.data;
        });
    }

}
