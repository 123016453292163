import React from 'react';
import {D3ChartAxis} from "../../platform/d3chart/D3ChartAxis";
import {D3ChartGridLines} from "../../platform/d3chart/D3ChartGridLines";
import {D3ChartLine} from "../../platform/d3chart/D3ChartLine";
import {curveToPoints, FlowSimulationResultJson} from "../../business/useImpellerSimulation";
import {D3ChartPoint} from "../../platform/d3chart/D3ChartPoint";
import {D3ChartScaleManager} from "../../platform/d3chart/D3ChartScaleManager";
import {D3ChartRenderable} from "../../platform/d3chart/D3Chart";
import {ResistanceCurveD3ChartRenderable} from "../../charting/ResistanceCurveD3ChartRenderable";
import {D3ChartComponent} from "../../platform/d3chart/D3ChartComponent";
import {MouseGuidesD3ChartRenderable} from "../../charting/MouseGuidesD3ChartRenderable";
import {FlowCurve} from "../../business/core";

export function FanTorqueChart(props: {
    torqueCurve: FlowCurve,
}) {

    const chartSize = {width: 500, height: 350};
    const chartMargins = {left: 60, top: 0, right: 5, bottom: 40};

    const rpmAxis = new D3ChartAxis([0.0, -1.0], [0.0, chartSize.width], "bottom", 15, "d3-axis-slight-domain-line",0,0,"n[min-1]");
    const torqueAxis = new D3ChartAxis([-1.0, 0.0], [0.0, chartSize.height - chartMargins.top - chartMargins.bottom], "left", 10, "d3-axis-slight-domain-line",0,0,"M[Nm]");

    const verticalGridLines = new D3ChartGridLines(rpmAxis.scale, rpmAxis, "vertical", "d3-stroke-1 d3-opacity-05 d3-stroke-black");
    const horizontalGridLines = new D3ChartGridLines(torqueAxis.scale, torqueAxis, "horizontal", "d3-stroke-1 d3-opacity-05 d3-stroke-black");
    const horizontalGridLinesStrong = new D3ChartGridLines(torqueAxis.scale, torqueAxis, "horizontal", "d3-stroke-1 d3-opacity-10 d3-stroke-black");

    const torqueLine = new D3ChartLine<{ flow: number, value: number }>(
        rpmAxis.scale,
        torqueAxis.scale,
        curveToPoints(props.torqueCurve),
        chPoint => chPoint.flow,
        chPoint => chPoint.value,
        "d3-stroke-3 d3-stroke-efficiency");

    // TODO mark working point on chart
    const torqueScaleManager = new D3ChartScaleManager([torqueLine], 1.2);


    const renderables: D3ChartRenderable[] = [
        torqueScaleManager, // Scale manager comes first, to recalculate axes scales!
        rpmAxis,
        torqueAxis,
        verticalGridLines,
        horizontalGridLines,
        horizontalGridLinesStrong,
        torqueLine,
        new MouseGuidesD3ChartRenderable(rpmAxis.scale, torqueAxis.scale)
    ];

    return <D3ChartComponent width={chartSize.width} height={chartSize.height} chartRenderables={renderables}
                             margins={chartMargins}/>
}
