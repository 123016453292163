import React from 'react';
import UboldAlert from "./UboldAlert";
import SimpleVersionNumberFromVersionJson from "../platform/version/SimpleVersionNumberFromVersionJson";
import {Card} from "../components/Card";

export default function UboldFullPageSignIn(props) {
    const credentialsForm = <form action="#">
        <div className="form-group mb-3">
            <label htmlFor="emailaddress">{props.emailLabel}</label>
            <input className="form-control"
                   type="email"
                   required=""
                   placeholder={props.emailPlaceholder}
                   onChange={props.onLoginChange}
                   value={props.loginValue}/>
        </div>
        <div className="form-group mb-3">
            <label htmlFor="password">{props.passwordLabel}</label>
            <div className="input-group input-group-merge">
                <input type="password"
                       id="password"
                       className="form-control"
                       placeholder={props.passwordPlaceholder}
                       onChange={props.onPasswordChange}
                       value={props.passwordValue}/>

            </div>
        </div>
        <div className="form-group mb-0 text-center">
            <button type="submit" className="btn btn-primary btn-rounded" disabled={props.processingInProgress} onClick={props.onSubmit}>
                {props.submitLabel}
                {(props.processingInProgress ? <span className="spinner-border spinner-border-sm ms-3"></span> : null)}
            </button>
        </div>
    </form>;

    return <div>
        <div className="account-pages mt-5 mb-5">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-8 col-lg-6 col-xl-5">
                        <Card>
                            <div className="text-center ">
                                <div className={"clearfix text-center pt-20 pb-20"}>
                                    <img src={props.logoImgSrc} width={"60%"} alt="logo"/>
                                    {props.intro}
                                </div>

                            </div>
                            {(props.errorMessage && <UboldAlert message={props.errorMessage} severity={"error"}/>)}
                            {credentialsForm}
                            {props.additionalComponents}
                        </Card>
                    </div>
                </div>


            </div>
        </div>


        <footer className="footer footer-alt text-dark text-center">
            {(new Date().getFullYear())} &copy; {props.copyrightVendor} <SimpleVersionNumberFromVersionJson/>
        </footer>
    </div>;
}

UboldFullPageSignIn.propTypes = {}

UboldFullPageSignIn.defaultProps = {}