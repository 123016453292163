import {OptionalEditor} from "../../form/editors/OptionalEditor";
import {i18n} from "../../platform/i18n/i18n";
import {useEditor} from "../../form/EditorReact";
import {ObjectEditor, SubEditorMapping} from "../../form/editors/ObjectEditor";
import {NumberEditorValue} from "../../form/editors/NumberEditor";
import {createEditorForMeasurementStandard} from "../../form/editors/MeasureEditor";
import {WentechMeasuringStandards} from "../measurement/WentechMeasurementStandards";
import React from "react";


export interface ObstacleParams {
    obstacleToImpellerArea: NumberEditorValue,
    obstacleDistanceToHousingDiameter: NumberEditorValue
}


export function useObstacleParamsEditor(): OptionalEditor<ObstacleParams> {
    const t = (txt: string) => i18n("ObstacleEditor:" + txt);

    return useEditor(() => {
        const obstacleToImpellerAreaMapping: SubEditorMapping<NumberEditorValue> = {
            editor: createEditorForMeasurementStandard(WentechMeasuringStandards.AREA_RATIO),
            propertyName: "obstacleToImpellerArea",
            propertyType: "number",
            propertyDisplayName: t("Stosunek powierzchni do wirnika")
        };
        const obstacleDistanceToHousingDiameterMapping: SubEditorMapping<NumberEditorValue> = {
            editor: createEditorForMeasurementStandard(WentechMeasuringStandards.DISTANCE_RATIO),
            propertyName: "obstacleDistanceToHousingDiameter",
            propertyType: "number",
            propertyDisplayName: t("Stosunek odległości do średnicy wirnika")
        };

        const obstacleParamsEditor = new ObjectEditor<ObstacleParams>([obstacleToImpellerAreaMapping, obstacleDistanceToHousingDiameterMapping]);

        return new OptionalEditor<ObstacleParams>(
            obstacleParamsEditor,
            false,
            (toggleEnablement) =>
                <></>,
            (DelegatedEditorComponent, toggleEnablement) =>
                <div className="text-center pt-3">
                    <DelegatedEditorComponent/>
                    <div className={"d-flex flex-row-reverse"}>
                        <button className={"btn btn-link btn-sm"} onClick={toggleEnablement}><i className="fas fa-times fa-3x"/> {t("Nie uwzględniaj tej przeszkody")}</button>
                    </div>
                </div>
        )
    });
}