import React from 'react';
import {NavLink} from "react-router-dom";
import {FanSelectionMatchedFan, matchedFanToSimulationCriteria} from "../selection/SelectionService";
import {i18n} from "../../platform/i18n/i18n";
import {SelectionRequestJson, SimulationRequestJson} from "../../business/ApiJsonTypes";


export function DatasheetButtonForSelectionResult(props: { selectionRequestUsed: SelectionRequestJson, matchedFan: FanSelectionMatchedFan }) {
    const simulationCriteria = matchedFanToSimulationCriteria(props.selectionRequestUsed, props.matchedFan);
    return <DatasheetButton simulationRequest={simulationCriteria}/>
}

export function DatasheetButton(props: { simulationRequest: SimulationRequestJson }) {

    const base64Criteria = btoa(JSON.stringify(props.simulationRequest));
    return <NavLink to={"/datasheet?b64Criteria=" + base64Criteria} className={"btn btn-sm btn-primary me-3 text-nowrap"}><i className="far fa-file-alt"></i> {i18n("DatasheetButton:Karta")}</NavLink>;
}
