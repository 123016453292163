import React, {useState} from 'react';
import {AssemblyDetails} from "./AssemblyDetails";
import {calculateMocowanieLopatMass, ImpellerAssemblyJson, ResonanceReportJson} from "../../pages/mechanical/ImpellerAssemblyService";
import {AssemblyPublisher} from "./AssemblyPublisher";
import {DimensionalImageDescriptor} from "../../business/core";
import {ResonanceCampbellChart} from "../domain/ResonanceCampbellChart";

export function AssemblyOverview({
                                     assembly,
                                     detailsExpandedByDefault = false,
                                     displayActionsPanel = false,
                                     includeMaterialTable = false,
                                     dimensionalImageDescriptors,
                                     resonanceReport

                                 }: {
    assembly: ImpellerAssemblyJson,
    detailsExpandedByDefault?: boolean,
    displayActionsPanel?: boolean,
    includeMaterialTable?: boolean,
    dimensionalImageDescriptors: DimensionalImageDescriptor[],
    resonanceReport: ResonanceReportJson | null // WARNING... this may be nuller because resonances were not calculated, or EXPLICITLY in this UI scenario we do not want to show resonances. So assumption like "reson. was not calculated" should be NOT presented here
}) {

    const [detailsExpanded, setDetailsExpanded] = useState(detailsExpandedByDefault);

    return <>
        <div className="d-flex flex-nowrap">
            <div className=" border-gray-300 rounded py-3 px-4 me-3 mb-3 d-flex flex-column align-items-start">
                <div className="fs-4 flex-fill fw-bolder">
                    <div className={"text-nowrap"}>
                        ⌀ {assembly.clutch.shaftHoleDiameter.toFixed(0)}
                    </div>
                    <div className={"text-nowrap"}>
                        L {assembly.shaftLength.toFixed(0)}
                    </div>
                </div>
                <div className="fw-bold fs-6 text-gray-400">Wał</div>
            </div>
            <div className=" border-gray-300 rounded py-3 px-4 me-3 mb-3 d-flex flex-column align-items-start">
                <div className="fs-4 flex-fill fw-bolder">{assembly.clutch.clutchDisplayName}</div>
                <div className="fw-bold fs-6 text-gray-400">Sprzęgło ({assembly.clutch.mountingScrewCount} x śr. TS)</div>
                <div className="fw-bold fs-6 text-success">{assembly.clutch.mass.toFixed(2)} kg</div>
            </div>
            <div className=" border-gray-300 rounded py-3 px-4 me-3 mb-3 d-flex flex-column align-items-start">
                <div className="fs-4 flex-fill fw-bolder">{assembly.disc.displayName}</div>
                <div className="fw-bold fs-6 text-gray-400">Tarcza</div>
                <div className="fw-bold fs-6 text-success">{assembly.disc.mass.toFixed(2)} kg</div>
                {assembly.hubCap !== null && <div>
                    <div className="fs-4 flex-fill fw-bolder">Kołpak {assembly.hubCap.diameter.toFixed(0)} {assembly.hubCap.thickness.toFixed(1)}mm</div>
                    <div className="fw-bold fs-6 text-success">{assembly.hubCap.mass.toFixed(2)} kg</div>
                </div>}

            </div>
            <div className=" border-gray-300 rounded py-3 px-4 me-3 mb-3 d-flex flex-column align-items-start">
                <div className="fs-7 flex-fill fw-bolder">
                    <div>
                        {assembly.podpora.name} (x{assembly.podpora.pieceCount})
                    </div>
                    {assembly.nakladka !== null && <div>
                        {assembly.nakladka.name} (x{assembly.nakladka.pieceCount})
                    </div>}
                    {assembly.obejma !== null && <div>
                        {assembly.obejma.name} (x{assembly.obejma.pieceCount})
                    </div>}
                </div>
                <div className="fw-bold fs-6 text-gray-400">Mocowanie łopat</div>
                <div className="fw-bold fs-6 text-success">{calculateMocowanieLopatMass(assembly).toFixed(2)} kg</div>
            </div>
            <div className="flex-fill border-gray-300 rounded py-3 px-4 me-3 mb-3 d-flex flex-column align-items-start">
                <div className="fs-4 flex-fill fw-bolder">{assembly.blade.displayName}</div>
                <div className="fw-bold fs-6 text-gray-400">Forma/łopata</div>
                <div className="fw-bold fs-6 text-gray-600">{assembly.blade.enforcementStrategySymbol}</div>
                <div className="fw-bold fs-6 text-success">{assembly.blade.mass.toFixed(2)} kg (x{assembly.bladeCount})</div>
            </div>
            <div className="border border-gray-300 border-dashed rounded py-3 px-4 me-3 mb-3 d-flex flex-column align-items-center bg-gray-100">
                <div className="fs-2 fw-bolder flex-column-auto">{assembly.totalMass.toFixed(2)} kg</div>


                <div className="fs-5 fw-bolder">H {assembly.impellerHeight.toFixed(0)} mm</div>
                <div className="fw-bold fs-7 text-success">(H1 {assembly.clutch.dlugoscSprzeglaH1.toFixed(1)} mm)</div>
            </div>

            {displayActionsPanel === true && <>
                <div className="border border-gray-300 border-dashed rounded py-3 px-4 me-3 mb-3 d-flex flex-column bg-gray-100">
                    {detailsExpanded === false && <button className={"btn btn-secondary text-nowrap mb-3"} onClick={() => setDetailsExpanded(true)}><i className="fas fa-search"></i> Szczegóły</button>}
                    {detailsExpanded === true && <button className={"btn btn-warning text-nowrap mb-3"} onClick={() => setDetailsExpanded(false)}><i className="fas fa-search"></i> Szczegóły</button>}
                    {/* {assembly.memoizedAsBlueprintId !== null && <button className={"btn btn-white text-nowrap"} onClick={() => alert("Możliwość symulacji złożenia jeszcze nie zaimplementowana.")}><i className="fas fa-chart-line"></i> Symuluj</button>}*/}
                    <AssemblyPublisher assembly={assembly}/>
                </div>
            </>}
        </div>

        {detailsExpanded === true && <AssemblyDetails assembly={assembly} includeMaterialTable={includeMaterialTable} dimensionalImageDescriptors={dimensionalImageDescriptors}/>}

        {detailsExpanded === true && resonanceReport !== null &&
            <div className={"text-center mt-5 mb-2"}><ResonanceCampbellChart resonanceCurve={resonanceReport.resonanceCurve} workingPointResonances={resonanceReport.workingPointResonances}/></div>}

    </>;
}
