import {createInternationalizationPrefixer} from "../platform/i18n/i18n";

const t = createInternationalizationPrefixer("FanSelectionCriteriaControls");

export function MechanicalConfigPreviewImage(props: {
    orientantion: "horizontal" | "vertical",
    clutchOnInlet: boolean,
    silent: boolean,
    sameSide: boolean
}) {

    let imgName: string | "no-preview-available";

    if (props.orientantion === "horizontal") {

        if (props.clutchOnInlet) {
            // WAS
            if (props.sameSide) {
                // WAS2
                imgName = "was2";
            } else {
                // WAS1
                imgName = "was1";
            }
        } else {
            // WBS
            if (props.sameSide) {
                // WBS2
                imgName = "wbs2";
            } else {
                // WBS1
                imgName = "wbs1";
            }
        }
    } else {
        if (props.clutchOnInlet) {
            // WCS
            if (props.sameSide) {
                // WBS2
                imgName = "wcs2";
            } else {
                // WBS1
                imgName = "wcs1";
            }
        } else {
            imgName = "no-preview-available";
        }
    }

    if (props.silent && imgName !== "no-preview-available") {
        imgName += "silent";
    }

    if (imgName !== "no-preview-available") {
        imgName += ".png";
    }

    return <>
        {imgName !== "no-preview-available" && <img className={"mh-250px"} src={"/img/position/" + imgName}/>}
        {imgName === "no-preview-available" && <div className={"text-center fs-5 text-gray-600"}>{t("Brak podglądu")}</div>}
    </>
}