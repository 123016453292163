import {useFetchAllObjs} from "../../platform/hooks/PlatformTypeScriptHooks";


export interface BladeProfileJson {
    name: string,
    displayName: string
};

export function useFetchAllBladeProfiles(): BladeProfileJson[] | null {
    return useFetchAllObjs<BladeProfileJson[]>("/api/blade-profiles");
}

