export default function UserUnathorizedException(message) {
    this.message = message;
    // Use V8's native method if available, otherwise fallback
    if ("captureStackTrace" in Error)
        Error.captureStackTrace(this, UserUnathorizedException);
    else
        this.stack = (new Error()).stack;
}

UserUnathorizedException.prototype = Object.create(Error.prototype);
UserUnathorizedException.prototype.name = "UserUnathorizedException";
UserUnathorizedException.prototype.constructor = UserUnathorizedException;