import React, {useState} from 'react';
import {StandardLayout} from "../layout/StandardLayout";
import {ResistanceCurveD3ChartRenderable} from "../charting/ResistanceCurveD3ChartRenderable";
import {D3ChartComponent} from "../platform/d3chart/D3ChartComponent";
import {D3ChartAxis} from "../platform/d3chart/D3ChartAxis";
import {D3ChartGridLines} from "../platform/d3chart/D3ChartGridLines";
import {D3ChartLine} from "../platform/d3chart/D3ChartLine";
import {D3ChartRenderable} from "../platform/d3chart/D3Chart";
import {D3ChartScaleManager} from "../platform/d3chart/D3ChartScaleManager";
import {EfficiencyHeatMapD3ChartRenderable, EfficiencyHeatMapEntry} from "../charting/EfficiencyHeatMapD3ChartRenderable";
import {D3ChartPoint} from "../platform/d3chart/D3ChartPoint";
import {MouseGuidesD3ChartRenderable} from "../charting/MouseGuidesD3ChartRenderable";

interface InstalledFanChartDataAlternativeAdjustment {
    bladeAngle: number,
    characteristics: {
        flow: number,
        pressure: number,
        efficiency: number
    }[]
}

interface InstalledFanChartData {
    bladeAngle: number,
    characteristics: {
        flow: number,
        pressure: number,
        efficiency: number,
        uncorrectedPressure: number
    }[],
    requestedWorkingPoint: {
        flow: number,
        pressure: number
    },
    workingPoint: {
        flow: number,
        pressure: number,
        efficiency: number
    },
    alternativeAdjustments: InstalledFanChartDataAlternativeAdjustment[]
}

export function ChartShowcasePage(props: {}) {

    const [chartData, setChartData] = useState<InstalledFanChartData>({
        bladeAngle: 8,
        characteristics: [
            {flow: 2, pressure: 11, efficiency: 0.76, uncorrectedPressure: 10.9},
            {flow: 3, pressure: 10, efficiency: 0.86, uncorrectedPressure: 9.8},
            {flow: 4, pressure: 9, efficiency: 0.76, uncorrectedPressure: 8.7},
            {flow: 5, pressure: 8, efficiency: 0.66, uncorrectedPressure: 7.6},
            {flow: 6, pressure: 4, efficiency: 0.56, uncorrectedPressure: 3.5},
        ],
        requestedWorkingPoint: {
            flow: 4,
            pressure: 9.9
        },
        workingPoint: {
            flow: 4,
            pressure: 9,
            efficiency: 0.76
        },
        alternativeAdjustments: [
            {
                bladeAngle: 4,
                characteristics: [
                    {flow: 1, pressure: 10, efficiency: 0.76},
                    {flow: 2, pressure: 9, efficiency: 0.86},
                    {flow: 3, pressure: 8, efficiency: 0.76},
                    {flow: 4, pressure: 6, efficiency: 0.66},
                    {flow: 5, pressure: 2, efficiency: 0.56},
                ]
            },
            {
                bladeAngle: 12,
                characteristics: [
                    {flow: 3, pressure: 12, efficiency: 0.76},
                    {flow: 4, pressure: 11, efficiency: 0.86},
                    {flow: 5, pressure: 10, efficiency: 0.76},
                    {flow: 6, pressure: 8, efficiency: 0.66},
                    {flow: 7, pressure: 6, efficiency: 0.56},
                ]
            },
            {
                bladeAngle: 16,
                characteristics: [
                    {flow: 4, pressure: 13, efficiency: 0.71},
                    {flow: 5, pressure: 12, efficiency: 0.81},
                    {flow: 6, pressure: 11, efficiency: 0.71},
                    {flow: 7, pressure: 9, efficiency: 0.61},
                    {flow: 8, pressure: 7, efficiency: 0.51},
                ]
            }
        ]
    });


    const flowAxis = new D3ChartAxis([0, -1], [0, 900], "bottom", 15, "d3-axis-nodomainline");
    const flowAxis_m3h = new D3ChartAxis([0, -1], [0, 900], "bottom", 7, "d3-axis-nodomainline", 20);
    const pressureAxis = new D3ChartAxis([-1, 0], [0, 500 - 40 - 40], "left", 10, "d3-axis-nodomainline");

    const verticalGridLines = new D3ChartGridLines(flowAxis.scale, flowAxis,"vertical",  "d3-stroke-1 d3-opacity-05 d3-stroke-black");
    const horizontalGridLines = new D3ChartGridLines(pressureAxis.scale, pressureAxis, "horizontal",  "d3-stroke-1 d3-opacity-05 d3-stroke-black");
    const horizontalGridLinesStrong = new D3ChartGridLines(pressureAxis.scale, pressureAxis,"horizontal",  "d3-stroke-1 d3-opacity-10 d3-stroke-black");

    const alternativePressureLines = chartData.alternativeAdjustments.map(alternativeAdjustment => {
        return new D3ChartLine<{ flow: number, pressure: number }>(
            flowAxis.scale,
            pressureAxis.scale,
            alternativeAdjustment.characteristics,
            chPoint => chPoint.flow,
            chPoint => chPoint.pressure,
            "d3-stroke-0_3 d3-stroke-black");
    });

    const pressureLine = new D3ChartLine<{ flow: number, pressure: number }>(
        flowAxis.scale,
        pressureAxis.scale,
        chartData.characteristics,
        chPoint => chPoint.flow,
        chPoint => chPoint.pressure,
        "d3-stroke-2 d3-stroke-primary");

    const uncorrectedPressureLine = new D3ChartLine<{ flow: number, uncorrectedPressure: number }>(
        flowAxis.scale,
        pressureAxis.scale,
        chartData.characteristics,
        chPoint => chPoint.flow,
        chPoint => chPoint.uncorrectedPressure,
        "d3-stroke-1 d3-stroke-primary",
        "5,5");

    const scaleManager = new D3ChartScaleManager([...alternativePressureLines, uncorrectedPressureLine, pressureLine], 1.2);
    const scaleManager_m3h = new D3ChartScaleManager([...alternativePressureLines, uncorrectedPressureLine, pressureLine], 1.2 * 3600, flowAxis_m3h.scale, null);

    const efficiencyHeatMapEntries: EfficiencyHeatMapEntry[] = [];

    chartData.characteristics.forEach((chEntry: EfficiencyHeatMapEntry) => efficiencyHeatMapEntries.push(chEntry));
    chartData.alternativeAdjustments.forEach(adjustment => adjustment.characteristics.forEach((chEntry: EfficiencyHeatMapEntry) => efficiencyHeatMapEntries.push(chEntry)));

    const requestedWorkingPoint = new D3ChartPoint(flowAxis.scale, pressureAxis.scale, chartData.requestedWorkingPoint.flow, chartData.requestedWorkingPoint.pressure, 5, undefined, "d3-stroke-black d3-stroke-2", "2 2 ");
    const workingPoint = new D3ChartPoint(flowAxis.scale, pressureAxis.scale, chartData.workingPoint.flow, chartData.workingPoint.pressure, 5, undefined, "d3-fill-primary");
    const efficiencyHeatMap = new EfficiencyHeatMapD3ChartRenderable(flowAxis.scale, pressureAxis.scale, 50, 25, efficiencyHeatMapEntries);

    const renderables: D3ChartRenderable[] = [
        scaleManager, // Scale manager comes first, to recalculate axes scales!
        scaleManager_m3h,
        flowAxis,
        flowAxis_m3h,
        pressureAxis,
        efficiencyHeatMap,
        verticalGridLines,
        horizontalGridLines,
        horizontalGridLinesStrong,
        new ResistanceCurveD3ChartRenderable(flowAxis.scale, pressureAxis.scale),
        ...alternativePressureLines,
        uncorrectedPressureLine,
        pressureLine,
        requestedWorkingPoint,
        workingPoint,
        new MouseGuidesD3ChartRenderable(flowAxis.scale, pressureAxis.scale)

    ];

    const randomizePressureCurve = () => {
        //  console.log("RANDO!");
        flowAxis.scale.domain([Math.random(), Math.random() * 99 + 1]);

        //console.log("EFF: "+flowAxis.scale.domain())
        //chartRef.current?.render();
        // setPressureCurve([
        //     {x: 0.0001, y: 10},
        //     {...pressureCurve[1], y: pressureCurve[1].y * (0.7 + Math.random() * 0.5)},
        //     {...pressureCurve[2], y: pressureCurve[2].y * (0.7 + Math.random() * 0.5)},
        //     {...pressureCurve[3], y: pressureCurve[3].y * (0.7 + Math.random() * 0.5)},
        //     {...pressureCurve[4], y: pressureCurve[4].y * (0.7 + Math.random() * 0.5)}
        // ]);
    };


    return <>
        <div className="card-body ">
        <h2>Chart showcase</h2>
        <button className={"btn btn-sm btn-primary"} onClick={() => randomizePressureCurve()}>Randomize pressure curve</button>
        <div>
            <D3ChartComponent width={900} height={500} chartRenderables={renderables} margins={{left: 40, top: 40, right: 5, bottom: 40}}/>
        </div>
        </div>

    </>
}

ChartShowcasePage.propTypes = {}

ChartShowcasePage.defaultProps = {}
