import React, {useContext, useRef, useState} from 'react';
import {NavLink} from "react-router-dom";
import useClickOutsideListener from "../platform/hooks/PlatformHooks";
import {AuthenticationContext} from "../platform/authentication/AuthenticationContextProvider";
import AuthenticationService from "../platform/authentication/AuthenticationService";
import {createInternationalizationPrefixer} from "../platform/i18n/i18n";

const t = createInternationalizationPrefixer("OptiselProfileBox");

export function OptiselProfileBox(props) {

    // Expanding
    const [expanded, setExpanded] = useState(false);
    const toggleExpansion = () => {

        setExpanded(!expanded);
    };
    const ref = useRef(null);
    useClickOutsideListener(ref, () => setExpanded(false));


    const authenticationContext = useContext(AuthenticationContext);
    const authenticated = authenticationContext.isAuthenticated();

    if (!authenticated) {
        return null;
    }

    const logoutButton = <button key={3} className="dropdown-item notify-item btn-link text-dark" onClick={AuthenticationService.deAuthenticate}><span>{t("Wyloguj")}</span></button>;

    // TECHDEBT remove duplication
    if (!authenticationContext.isAuthenticationStatusKnown()) {
        return <li>...</li>;
    }


    return <div  ref={ref} className="d-flex align-items-center me-n3 ms-1 ms-lg-3" id="kt_header_user_menu_toggle" onClick={toggleExpansion}>
        <div className="btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px" data-kt-menu-trigger="click" data-kt-menu-attach="parent" data-kt-menu-placement="bottom-end">
            <img className="h-30px w-30px rounded" src="/img/avatar_placeholder.png" alt=""/>

        </div>
        <div className={"text-white"} style={{cursor: "pointer"}}>
            &nbsp; {authenticationContext.getAccountName()}
        </div>
        {expanded && <div style={{position:"relative"}}>
            <div style={{position:"absolute",left: "-275px"}}>
                <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px">
                    <div className="menu-item px-3">
                        <div className="menu-content d-flex align-items-center px-3">
                            <div className="d-flex flex-column">
                                <div className="fw-bolder d-flex align-items-center fs-5">{authenticationContext.getAccountName()}</div>

                            </div>
                        </div>
                    </div>
                    <div className="separator my-2"></div>
                    <div className="menu-item px-5">
                        <NavLink key={"cp"} to="/change-password" className="dropdown-item  btn-link text-dark"><span>{t("Zmień hasło")}</span></NavLink>
                    </div>
                    <div className="menu-item px-5">
                        {logoutButton}
                    </div>
                </div>
            </div>
        </div>}
    </div>;


    // return <li ref={ref} onClick={toggleExpansion} className="nav-item">
    //     <div style={{position: "relative"}}>
    //         <div className={"dropdown-menu p-3 " + (expanded ? " show" : "")}>
    //
    //
    //
    //
    //
    //         </div>
    //     </div>
    //     <a className="nav-link" href="#">
    //         {authenticationContext.getAccountName()}
    //     </a>
    // </li>;


    /*<React.Fragment>

    </React.Fragment>
        ;*/
}
