import React from 'react';
import UboldInvalidFeedbackList from "../UboldInvalidFeedbackList";
import PropTypes from "prop-types";

export default function UboldInputTextarea(props) {
    return <div className="row mt-2">
        <label className="col-4 col-form-label">{props.label}</label>
        <div className="col-8">
            <textarea
                rows={5}
                className={"form-control" + (props.errors.length > 0 ? " is-invalid" : "")}
                placeholder={props.placeholder}
                onChange={props.onChange}
                value={props.value}/>
            <UboldInvalidFeedbackList errors={props.errors}/>
        </div>
    </div>;
}

UboldInputTextarea.propTypes = {
    label: PropTypes.string,
    value: PropTypes.string,
    errors: PropTypes.arrayOf(PropTypes.string),
    onChange: PropTypes.func

}

UboldInputTextarea.defaultProps = {}