import React from 'react';
import FormComponentByDsl from "./FormComponentByDsl";
import {cloneJson} from "../util/JsonObjectCloner";

export default function GroupWebFormComponent(props) {
    const dsl = props.dsl;

    const fields = [];

    for( const errorMessage of props.dsl.errors.wholeGroupLevelErrors) {
        fields.push(<h1>Błąd: {errorMessage}</h1>);
    }

    //console.log("Degrouping",dsl);
    for (const [path, config] of Object.entries(dsl.configuration.configurations)) {
        const fieldDsl = {
            configuration: config,
            value: dsl.value[path],
            errors: dsl.errors.editorErrors[path]
        };

        function isFunction(functionToCheck) {
            return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
        }

        function onFieldDslChange(proposedDsl) {
            const newDsl = cloneJson(dsl);

            if (isFunction(proposedDsl)) {
                console.log('FUNCTIONAL DSL UPDATE TRIGGERED IN GROUP');
                const calculatedDsl = proposedDsl(newDsl);
                newDsl.value[path] = calculatedDsl.value;
                newDsl.errors.editorErrors[path] = calculatedDsl.errors;
                props.onDslChange(newDsl);
            } else {
                newDsl.value[path] = proposedDsl.value;
                newDsl.errors.editorErrors[path] = proposedDsl.errors;
                props.onDslChange(newDsl);
            }

        }

        //console.log("Pushing one field", fieldDsl);
        fields.push(<FormComponentByDsl key={path} dsl={fieldDsl} onDslChange={onFieldDslChange}/>)
    }

    return fields;
}

GroupWebFormComponent.propTypes = {}

GroupWebFormComponent.defaultProps = {}