import {Editor} from "../../form/Editor";
import {i18n} from "../../platform/i18n/i18n";
import {useEditor} from "../../form/EditorReact";
import {ObjectEditor, SubEditorMapping} from "../../form/editors/ObjectEditor";
import {NumberEditorValue} from "../../form/editors/NumberEditor";
import {createEditorForMeasurementStandard} from "../../form/editors/MeasureEditor";
import {WentechMeasuringStandards} from "../measurement/WentechMeasurementStandards";
import {OptionalEditor} from "../../form/editors/OptionalEditor";
import React from "react";

// Warning - may be used as JSON in simulation request part
export interface DiffuserDetails {
    heightMeters: NumberEditorValue,
    angle: NumberEditorValue // Note this is because sub editor mapping unimplemented  TODO instead of non type save propertyName, use: applicator: (obj:OBJECT, editorValue: T)=>any; and "populator" (reverse way)
}

export function useDiffuserEditorLegacy(): Editor<DiffuserDetails | null> {
    const t = (txt: string) => i18n("DiffuserEditor:" + txt);

    return useEditor<Editor<DiffuserDetails | null>>(() => {
        const heightMapping: SubEditorMapping<NumberEditorValue> = {
            editor: createEditorForMeasurementStandard(WentechMeasuringStandards.DIFFUSER_HEIGHT),
            propertyName: "heightMeters",
            propertyType: "number",
            propertyDisplayName: t("Wysokość części dyfuzorowej")
        };
        const angleMapping: SubEditorMapping<NumberEditorValue> = {
            editor: createEditorForMeasurementStandard(WentechMeasuringStandards.DIFFUSER_ANGLE),
            propertyName: "angle",
            propertyType: "number",
            propertyDisplayName: t("Kąt części dyfuzorowej")
        };

        const diffuserDetailsEditor = new ObjectEditor<DiffuserDetails>([heightMapping, angleMapping]);

        return new OptionalEditor<DiffuserDetails>(
            diffuserDetailsEditor,
            false,
            (toggleEnablement) =>
                <div className="text-center pt-3">
                    <button className="btn btn-sm btn-light" onClick={toggleEnablement}>{t("Uwzględnij dyfuzor")}</button>
                </div>,
            (DelegatedEditorComponent, toggleEnablement) =>
                <div className="text-center pt-3">
                    <DelegatedEditorComponent/>
                    <div className={"d-flex flex-row-reverse"}>
                        <button className={"btn btn-link btn-sm"} onClick={toggleEnablement}><i className="fas fa-times fa-3x"/> {t("Nie uwzględniaj dyfuzora")}</button>
                    </div>
                </div>
        )
    });
}


export function useDiffuserEditor(enabled: boolean): OptionalEditor<DiffuserDetails> {
    const t = (txt: string) => i18n("DiffuserEditor:" + txt);

    const editor= useEditor(() => {
        const heightMapping: SubEditorMapping<NumberEditorValue> = {
            editor: createEditorForMeasurementStandard(WentechMeasuringStandards.DIFFUSER_HEIGHT),
            propertyName: "heightMeters",
            propertyType: "number",
            propertyDisplayName: t("Wysokość części dyfuzorowej")
        };
        const angleMapping: SubEditorMapping<NumberEditorValue> = {
            editor: createEditorForMeasurementStandard(WentechMeasuringStandards.DIFFUSER_ANGLE),
            propertyName: "angle",
            propertyType: "number",
            propertyDisplayName: t("Kąt części dyfuzorowej")
        };

        const diffuserDetailsEditor = new ObjectEditor<DiffuserDetails>([heightMapping, angleMapping]);

        return new OptionalEditor<DiffuserDetails>(
            diffuserDetailsEditor,
            false,
            (toggleEnablement) =>
                <></>,
            (DelegatedEditorComponent, toggleEnablement) =>
                <div className="text-center pt-3">
                    <DelegatedEditorComponent/>
                    <div className={"d-flex flex-row-reverse"}>
                        <button className={"btn btn-link btn-sm"} onClick={toggleEnablement}><i className="fas fa-times fa-3x"/> {t("Nie uwzględniaj dyfuzora")}</button>
                    </div>
                </div>
        )
    });

    return editor;
}