import React from 'react';
import {cloneJson} from "../util/JsonObjectCloner";
import UboldDownshift from "../../ubold-components/form/UboldDownshift";
import Downshift from "downshift";

export default function AsyncAutocompleteWebFormComponent(props) {
    const dsl = props.dsl;

    function onDownshiftItemChange(selectedItem){
        console.log("The ubold downshit requested change: ", selectedItem);
    }

    function downshiftStateReducer(state, changes) {
        console.log("The ubold downshit requested change: ", changes);
        switch (changes.type) {
            //case Downshift.stateChangeTypes.keyDownEnter:
            case Downshift.stateChangeTypes.clickItem:
                console.log("UPDATING DSL! because of selection!");
                const newDsl = cloneJson(dsl);
                newDsl.value = changes.selectedItem;
                newDsl.errors = []; // Clear errors on item change
                props.onDslChange(newDsl);
                return {...changes };
            default:
                return {...changes };

        }
    }

    console.log("Rendering ubold downshift with selected item", dsl.value);
    return <UboldDownshift label={dsl.configuration.label}
                           //initiallySelectedItem={dsl.value != null ? dsl.value : null}
                            selectedItem={dsl.value}
                           placeholder={"Type search query"}
                           errors={dsl.errors}
                           downshiftStateReducer={downshiftStateReducer}
                           internalDownshiftOnChange={onDownshiftItemChange}
                           autocompleteUrl={dsl.configuration.queryUrlPrefix}/>
}

AsyncAutocompleteWebFormComponent.propTypes = {}

AsyncAutocompleteWebFormComponent.defaultProps = {}