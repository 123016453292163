import React from 'react';
import {useParams} from "react-router";
import {StandardLayout} from '../../layout/StandardLayout';
import {useFetchAdjustableImpeller} from "./AdjustableImpellerService";
import {Card} from "../../components/Card";
import UboldSpinner from "../../ubold-components/UboldSpinner";
import {NavLink} from "react-router-dom";
import {SimpleMulticurveChart} from "./SimpleMulticurveChart";
import {Summaries} from "../../components/Summaries";

export function AdjustableImpellerPage({}) {
    const {id} = useParams<string>();
    const adjustableImpeller = useFetchAdjustableImpeller(id!);

    if (adjustableImpeller === null) {
        return <><UboldSpinner/></>;
    }


    return <>
        <Card header="Informacje o wirniku (w domenie przepływowej)"
              subHeader="Informacje o wirniku w rozumieniu nastawialnym (bez zadanego kąta łopat)"
              toolbarElements={<NavLink to={"/adjustable-impellers"} className="btn btn-primary btn-sm"><i
                  className="fas fa-undo"></i> Powrót do listy wirników</NavLink>}>


            <p>
                Uwaga, poniższe informacje dotyczą jedynie przepływowej domeny wentylatora i nie biorą udziału w
                symulacji mechaniki.
            </p>

            <div className={"mt-4"}/>

            <Summaries>
                <Summaries.Box label={"Nazwa (bez średnicy)"} value={adjustableImpeller.diameterAgnosticName}/>
                <Summaries.Box label={"Szablon nazwy"} value={adjustableImpeller.nameTemplate}/>
                <Summaries.Box label={"Ilość nastawów kątowych"}
                               value={adjustableImpeller.impellerAdjustOptions.length}/>
                <Summaries.Box label={"Zakres opcji kątowych"} value={<>
                    {Math.min(...adjustableImpeller.impellerAdjustOptions.map(ao => ao.bladeAngle)).toFixed(2)}
                    &nbsp;-&nbsp;
                    {Math.max(...adjustableImpeller.impellerAdjustOptions.map(ao => ao.bladeAngle)).toFixed(2)}
                </>}/>
                <Summaries.Box label={"Zakres średnic [mm]"}
                               value={adjustableImpeller.minimumAllowedDiameter.toFixed(0) + " - " + adjustableImpeller.maximumAllowedDiameter.toFixed(0)}/>
            </Summaries>

            <div className={"mt-4"}/>

            <h4>Krzywe kątowe Psi/Fi</h4>
            <SimpleMulticurveChart xLabel={"Fi [-]"} yLabel={"Psi [-]"} curves={adjustableImpeller.impellerAdjustOptions.map(option => ({
                ...option.psiFiCurve,
                pointDescriber: point => option.bladeAngle.toFixed(1) + "° fi=" + point.fi.toFixed(2) + " Psi=" + point.value.toFixed(2)
            }))}/>

            <div className="row">
                <div className={"col-6"}>
                    <h4>Krzywe kątowe Lambda/Fi</h4>
                    <SimpleMulticurveChart xLabel={"Fi [-]"} yLabel={"Psi [-]"} width={500} curves={adjustableImpeller.impellerAdjustOptions.map(option => ({
                        ...option.lambdaFiCurve,
                        pointDescriber: point => option.bladeAngle.toFixed(1) + "° fi=" + point.fi.toFixed(2) + " Lambda=" + point.value.toFixed(2)
                    }))}/>
                </div>
                <div className={"col-6"}>
                    <h4>Krzywe kątowe poprawek akustycznych/Fi</h4>
                    <SimpleMulticurveChart xLabel={"Fi [-]"} yLabel={"SPL [dB(A)]"} width={500} curves={adjustableImpeller.impellerAdjustOptions.map(option => ({
                        ...option.acousticAmendmentFiCurve,
                        pointDescriber: point => option.bladeAngle.toFixed(1) + "° fi=" + point.fi.toFixed(2) + " SPL=" + point.value.toFixed(2)
                    }))}/>
                </div>
                <div className={"col-6"}>
                    <h4>Krzywe kątowe Eta/Fi</h4>
                    <SimpleMulticurveChart xLabel={"Fi [-]"} yLabel={"Eta [-]"} width={500} curves={adjustableImpeller.impellerAdjustOptions.map(option => ({
                        ...option.efficiencyFiCurve,
                        pointDescriber: point => option.bladeAngle.toFixed(1) + "° fi=" + point.fi.toFixed(2) + " eta=" + point.value.toFixed(2)
                    }))}/>
                </div>
            </div>


        </Card>
    </>;
}
