import React from 'react';
import DynamicWebServiceDrivenForm from "../../platform/webform/DynamicWebServiceDrivenForm";
import {StandardLayout} from "../../layout/StandardLayout";
import {Card} from "../../components/Card";
import {useNavigate} from "react-router";

export function CreateBladeProfilePage(props) {
    var navigate = useNavigate();
    return <>
        <Card header={"Dodaj profil"}>
            <DynamicWebServiceDrivenForm dslUrl="/api/blade-profile/form"
                                         submissionUrl="/api/blade-profile/submit"
                                         submitLabel="Utwórz"
                                         successHandler={() => {
                                             alert('Profil został utworzony');
                                             navigate("/blade-profiles");
                                         }}/>
        </Card>
    </>
}

CreateBladeProfilePage.propTypes = {}

CreateBladeProfilePage.defaultProps = {}
