import {D3ChartRenderable, D3ChartSize, D3GSelection, D3SvgSelection} from "./D3Chart";
import {D3ChartLine} from "./D3ChartLine";


export type LineLabeler = {
    line: D3ChartLine<any>,
    label: string,
    anchorXFactor: number,
    anchorYFactor: number,
    positionTextBelow?: boolean
}

export class D3ChartLabeler implements D3ChartRenderable {

    private g: D3GSelection | undefined;
    private rendered: boolean = false;

    constructor(private lineLabelers: LineLabeler[]) {

    }

    initialize(svgSelection: D3SvgSelection, rootG: D3GSelection, unClippedRootG: D3GSelection, size: D3ChartSize): void {
        this.g = rootG.append("g");
        this.g.append("path");
    }

    render(): void {
        if (this.rendered || !this.g) {
            return; // note labels is STATIC and do not suppoty moving, and thus after first render we can skip next renders.
        }

        for (const lineLabeler of this.lineLabelers) {

            const line = lineLabeler.line;
            if (line.points.length < 1) {
                return;
            }
            const anchorX = line.scaleX(line.scaleX.domain()[1] * lineLabeler.anchorXFactor + line.scaleX.domain()[0]);
            const anchorY = line.scaleY(line.scaleY.domain()[0] * lineLabeler.anchorYFactor + line.scaleY.domain()[1]);

            // for testing anchors visually
            // this.g.append("circle").attr("cx", anchorX)
            //     .attr("cy", anchorY)
            //     .attr("r", 4)
            //     //.attr("class",this.className)
            //     //.attr("stroke-dasharray", this.strokeDashArray)
            //     .attr("fill", "black");


            let bestPoint = line.points[0];
            let bestDistance: null | number = null;
            for (const point of line.points) {
                const pDomainX = line.xExtractor(point);
                const pDomainY = line.yExtractor(point);
                const px = line.scaleX(pDomainX);
                const py = line.scaleY(pDomainY);
                const distanceToAnchor = Math.sqrt((px - anchorX) * (px - anchorX) + (py - anchorY) * (py - anchorY))
                if (bestDistance == null || bestDistance > distanceToAnchor) {
                    bestDistance = distanceToAnchor;
                    bestPoint = point;
                }
            }


            const x = line.scaleX(line.xExtractor(bestPoint));
            const y = line.scaleY(line.yExtractor(bestPoint));

            this.g.append("text")
                .attr("font-size", "14px")
                .attr("fill", "black")
                .attr("x", x + 4)
                //.attr("width", width)
                .attr("y", y - (lineLabeler.positionTextBelow === true ? -12 : 5))
                .style("text-anchor", "start")
                .text(lineLabeler.label);
        }

        this.rendered = true;
    }

}