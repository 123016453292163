import {useEffect, useReducer, useRef, useState} from "react";
import {Editor} from "form/Editor";

export const useForcedRerender = (debugDescription?: string) => {
    const [, forceReRender] = useReducer((x) => x + 1, 0);
    return forceReRender;
};

export function useEditor<EDITOR extends Editor<any>>(editorFactory: () => EDITOR, editorRecreateDependencies: any[] = [], debugDescription?: string) {
    const rerender = useForcedRerender(debugDescription);
    const [editor, setEditor] = useState<EDITOR>(() => {
        const editor = editorFactory();
        editor.addChangeListener(rerender);
        return editor;
    })


    const didMount: React.MutableRefObject<boolean> = useRef<boolean>(false);

    useEffect(() => {
        // didMount = prevent effect run on first intial render, as editor was created as initial useState value
        if (didMount.current) {
            console.log("XX RE!-creating editor" + debugDescription)
            const oldEditor = editor;
            const newEditor = editorFactory();
            newEditor.addChangeListener(rerender);
            newEditor.populate(editor.value); // Try to repopulate the value. Maybe only select options changed or something, but value was not reset
            setEditor(newEditor);
        } else {
            didMount.current = true;
        }
    }, editorRecreateDependencies)


    return editor;
}
