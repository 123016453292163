import React from 'react';
import DynamicWebServiceDrivenForm from "../../platform/webform/DynamicWebServiceDrivenForm";
import {StandardLayout} from "../../layout/StandardLayout";
import {Card} from "../../components/Card";
import {useNavigate, useParams} from "react-router";

export function EditBladeProfilePage(props:{}) {
    const {id} = useParams();
    var navigate = useNavigate();

    return <>
        <Card header={"Edytui profil"}>
            <DynamicWebServiceDrivenForm dslUrl={`/api/blade-profile/edit/${id}/form`}
                                         submissionUrl={`/api/blade-profile/edit/${id}/submit`}
                                         submitLabel="Zapisz"
                                         successHandler={() => {
                                             alert('Profil został zaktualizowany');
                                             navigate("/blade-profiles");
                                         }}/>
        </Card>
    </>
}
