import React, {ReactNode, useContext, useState} from "react";
import {OptiselModal} from "../../components/modal/OptiselModal";
import {useFetchAllObjs} from "../../platform/hooks/PlatformTypeScriptHooks";
import {IssueApiJson} from "./IssueApiJson";
import UboldSpinner from "../../ubold-components/UboldSpinner";
import {callApiAttachOptiselEventToIssue} from "./useIssueApi";
import {NavLink} from "react-router-dom";
import {HotIssueContext} from "./hotssue/HotIssueContextProvider";


export function EventToIssueAttacher(props: {
    eventId: string,
    renderer: (openDialog: () => any) => React.ReactNode
}) {
    const [opened, setOpened] = useState(false);


    return <>
        <OptiselModal key={1} opened={opened}
                      closeable={true}
                      header={"Przypnij do sprawy"} onClose={() => {
            setOpened(false)
        }}>
            <div>
                <EventToIssueInternalAttacher eventId={props.eventId}/>
            </div>
        </OptiselModal>
        {props.renderer(() => setOpened(true))}
    </>;
}

export function EventToIssueInternalAttacher(props: {
    eventId: string
}): JSX.Element {

    const recentIssues = useFetchAllObjs<IssueApiJson[]>("/api/issues/recent");
    const [status, setStatus] = useState<"picking" | "processing" | "done">("picking");
    const [issueIdUsedToAttach, setIssueIdUsedToAttach] = useState<string | null>(null);

    const {currentHotIssueSummary} = useContext(HotIssueContext);


    const performAttachement = async (issueId: string): Promise<void> => {
        setStatus("processing");
        await callApiAttachOptiselEventToIssue(props.eventId, issueId);
        setStatus("done");
        setIssueIdUsedToAttach(issueId)
    }

    if (recentIssues == null) {
        return <UboldSpinner/>;
    }

    if (status === "processing") {
        return <div>
            <div>Przypisywanie...</div>
            <div><UboldSpinner/></div>
        </div>;
    }

    if (status === "picking") {
        return <>
            {currentHotIssueSummary?.id && <div className={"mb-4"}>
                <h3>Obecnie aktywna:</h3>
                <div className={"separator separator-dashed mb-2"}/>
                <button className={"btn btn-xxs btn-secondary"} onClick={() => performAttachement(currentHotIssueSummary.id)}>{currentHotIssueSummary.name}</button>

            </div>}
            <h3>Ostatnio aktywne:</h3>
            <div className={"separator separator-dashed mb-2"}/>
            {recentIssues.slice(0, 10).map(issue => <div className={"mb-2"}>
                <button className={"btn btn-xxs btn-secondary"} onClick={() => performAttachement(issue.id)}>{issue.name}</button>
            </div>)}
        </>
    }

    if (status === "done") {
        return <div>
            <div>Przypisano.</div>
            <div>
                <NavLink to={`/show-issue/${issueIdUsedToAttach}`}>Skocz do sprawy</NavLink>
            </div>
        </div>;
    }

    return <></>;

}