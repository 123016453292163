import React from "react";
import PropTypes from "prop-types";

export function Card(props) {
    const extraProps = {...props};
    delete extraProps.additionalClassNames;
    delete extraProps.subHeader;
    delete extraProps.toolbarElements;
    delete extraProps.className;
    delete extraProps.additionalBodyClassNames;
    return <div className={"card" + (props.additionalClassNames ? " " + props.additionalClassNames : "")} {...extraProps}>
        {props.header &&
            <div className={"card-header"}>
                <h3 className="card-title align-items-start flex-column">
                    <span className="card-label fw-bolder text-dark">{props.header}</span>
                    {props.subHeader && <span className="text-gray-400 mt-1 fw-bold fs-6">{props.subHeader}</span>}
                </h3>
                {props.toolbarElements && <div className={"card-toolbar"}>{props.toolbarElements}</div>}
            </div>}

        <div className={"card-body" + (props.additionalBodyClassNames ? " " + props.additionalBodyClassNames : "")}>
            {props.children}
        </div>
    </div>;
}

Card.propTypes = {
    children: PropTypes.node.isRequired,
    additionalClassNames: PropTypes.string,
    additionalBodyClassNames: PropTypes.string,
    header: PropTypes.string,
    subHeader: PropTypes.any,
    toolbarElements: PropTypes.any,
    className: PropTypes.any
};

Card.defaultProps = {};
