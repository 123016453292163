import {useEffect, useState} from "react";
import {IssueApiJson} from "../IssueApiJson";
import AjaxService from "../../../platform/ajax/AjaxService";


export type HotIssueSummary = {
    id: string;
    name: string;
    creationTimestampIso: string;
    originalCreatorName: string;
}

export function useHotIssue():
    {
        currentHotIssueSummary: null | HotIssueSummary;
        clearHotIssue: () => void;
        makeHotIssue: (issueId: string) => void;
    } {

    const [currentHotIssueId, setCurrentHotIssueId] = useState<string | null>(null);
    const [loadedHodIssue, setLoadedHotIssue] = useState<IssueApiJson | null>(null);

    useEffect(() => {
        const storedHotIssue = sessionStorage.getItem("currentHotIssueId");
        if (storedHotIssue != null) {
            setCurrentHotIssueId(storedHotIssue);
        }
    }, []);

    const clearHotIssue = () => {
        sessionStorage.removeItem("currentHotIssueId");
        setCurrentHotIssueId(null);
    }

    const makeHotIssue = (issueId: string) => {
        sessionStorage.setItem("currentHotIssueId", issueId);
        setCurrentHotIssueId(issueId);
    }

    useEffect(() => {
        if (currentHotIssueId == null) {
            setLoadedHotIssue(null);
            return;
        }
        AjaxService.getJson(`/api/issue/${currentHotIssueId}?origin=hot`).then((obj) => {
            setLoadedHotIssue(obj.json as IssueApiJson);
            console.log("Loaded hot issue", obj.json);
        });
    }, [currentHotIssueId]);

    const currentHotIssueSummary: HotIssueSummary | null = loadedHodIssue == null ? null : {
        id: loadedHodIssue.id,
        name: loadedHodIssue.name,
        creationTimestampIso: loadedHodIssue.creationInstant,
        originalCreatorName: loadedHodIssue.originalCreatorName
    }

    return {
        currentHotIssueSummary,
        clearHotIssue,
        makeHotIssue,
    }
}