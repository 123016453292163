import React, {useEffect, useState} from 'react';
import AuthenticationService from "./AuthenticationService";
import * as Sentry from "@sentry/react";

export const AuthenticationContext = React.createContext();

export default function AuthenticationContextProvider({children}) {

    const [authStatus, setAuthStatus] = useState(AuthenticationService.getCurrentAuthStatus());

    const contextValues = {
        isAuthenticated: function () {
            return authStatus.authenticated;
        },
        isAuthenticationStatusKnown: function () {
            return authStatus.authenticationStatusKnown;
        },
        getAccountName: function () {
            if (!authStatus.authenticated) {
                throw new Error("Cannot get account name while unauthenticated!");
            }
            return authStatus.accountName;
        },
        getAccountId: function () {
            if (!authStatus.authenticated) {
                throw new Error("Cannot get account id while unauthenticated!");
            }
            return authStatus.accountId;
        }
    }

    useEffect(() => {
        AuthenticationService.addAuthStatusChangeListener(function (newKnownAuthStatus) {
            console.log("Updating auth status in context", newKnownAuthStatus);
            setAuthStatus(newKnownAuthStatus);
            if ( newKnownAuthStatus.authenticated) {
                console.info("Setting up sentry tag account name for ",newKnownAuthStatus.accountName);
                Sentry.setTag("authenticated_user",newKnownAuthStatus.accountName);
            } else {
                Sentry.setTag("authenticated_user", "anonymous");
            }

        });
    }, []);

    // TECHDEBT: Consider movice an "service instance" with plain JS and multiple auth related methods here as only instance access, with no logic
    // .. but what with useState?

    return <AuthenticationContext.Provider value={contextValues}>
        {children}
    </AuthenticationContext.Provider>;
}
