import React from "react";
import {Card} from "../../components/Card";
import UboldSpinner from "../../ubold-components/UboldSpinner";
import {useFetchAllObjs} from "../../platform/hooks/PlatformTypeScriptHooks";


export type HistoricalUploadMetadataJson = {
    id: string,
    createdAtMillis: number,
    performerName: string,
    originalFileName: string,
}

export function HistoricalUploadsPage(props:{}) {
    const uploadMetas = useFetchAllObjs<HistoricalUploadMetadataJson[]>("/api/historical-upload");

    if (uploadMetas === null) {
        return <UboldSpinner/>;
    }

    return <Card header={"Historia wgranych plików"}>
        <p>
            (po 10 ostatnich plików per typ. Dla plików mechanicznych typem jest rodzaj pliku mechanicznego - np. manufakturyzacje sprzęgieł. Dla plików wielomianowych wirników typem jest profil wirnika, więc przechowywane jest 10 ostatnich plików per profil)
        </p>
        <table className={"table table-row-bordered table-row-gray-100 align-middle gs-0 gy-1"}>
            <thead>
            <tr className="fw-bolder text-muted">
                <th>Czas załadowania</th>
                <th>Nazwa pliku</th>
                <th>Wgrywający</th>
                <th>Pobieranie</th>
            </tr>
            </thead>
            <tbody>
            {uploadMetas.map(uploadMeta => <tr>
                <td>{new Date(uploadMeta.createdAtMillis).toLocaleString('pl')}</td>
                <td>{uploadMeta.originalFileName}</td>
                <td>{uploadMeta.performerName}</td>
                <td>
                    <a className={"btn btn-secondary btn-sm"}  href={`/api/historical-upload/${uploadMeta.id}/download`}><i className="fas fa-download"></i> Pobierz</a>
                </td>
            </tr>)}

            </tbody>

        </table>

    </Card>;
}
