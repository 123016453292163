import React from "react";
import PropTypes from "prop-types";

export default function UboldAlert(props) {

    const className = props.severity === 'success' ? "alert-success" : "alert-danger";
    const iconName = props.severity === 'success' ? 'mdi-check-all' : 'mdi-alert';
    return <div className={"alert animated zoomIn " + className} role="alert">
        <i className={"mdi "+iconName+" mr-2"}></i> {props.message}
        <div></div>
    </div>;
};

UboldAlert.propTypes = {
    message: PropTypes.string.isRequired, // TECHDEBT consider children components instead of message? e.g. React.PropTypes.node
    severity: PropTypes.oneOf(['error', 'success']).isRequired
};