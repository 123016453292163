import React, {useState} from 'react';
import {useObjectFromUrlResourceOrNull} from "../../platform/hooks/PlatformHooks";
import AjaxService from "../../platform/ajax/AjaxService";
import UboldSpinner from "../../ubold-components/UboldSpinner";
import UBoldModal from "../../ubold-components/UBoldModal";
import {NavLink} from "react-router-dom";
import {createInternationalizationPrefixer} from "../i18n/i18n";
import {Card} from "../../components/Card";

const t = createInternationalizationPrefixer("RolesPage");


export default function RolesPage(props) {

    const roles = useObjectFromUrlResourceOrNull("/api/role");
    const [idToDelete, setIdToDelete] = useState(null);

    const commitDeletion = id => {
        AjaxService.delete("/api/role/" + id).then(response => {
            if (response.ok) {
                setIdToDelete(null);
                window.location.reload(false);
            }
        });
    };

    let rolesOrNoRolesText = t('Brak utworzonych ról');
    if (roles !== null && roles.length > 0) {
        rolesOrNoRolesText = <React.Fragment>
            <table className={"table  mb-0 mt-3"}>
                <thead className={""}>
                <tr className={"fw-bolder"}>
                    <th>{t('Nazwa')}</th>
                    <th>{t('Uprawnienia')}</th>
                    <th>{t('Przypisani użytkownicy')}</th>
                    <th>{t('Akcje')}</th>
                </tr>
                </thead>
                <tbody>
                {roles.map(r => <tr key={r.id}>
                    <td>{r.name}</td>
                    <td>{r.privilegeNames.map(n => <div>{n}</div>)}</td>
                    <td>{r.assignedUserNames.map(n => <div>{n}</div>)}</td>
                    <td>
                        <NavLink className="action-icon" to={"/roles/edit/" + r.id}> <i className="fas fa-edit"></i> Edytuj</NavLink>
                        <a className="action-icon" href="#" onClick={() => setIdToDelete(r.id)}> <i className="fas fa-trash-alt"></i> Usuń</a>
                    </td>
                </tr>)}
                </tbody>
            </table>
        </React.Fragment>;
    }

    const rolesOrSpinner = roles === null ? <UboldSpinner/> : rolesOrNoRolesText;

    return <React.Fragment>
        <div className="row justify-content-center">
            <Card header={"Wszystkie role"} additionalClassNames={"col-9"}>
                {rolesOrSpinner}
                <UBoldModal key={1} opened={idToDelete !== null} onClose={() => setIdToDelete(null)}
                            header={t('Czy na pewno usunąć?')}>
                    <div className="text-center">
                        <button className="btn btn-warning mr-2"
                                onClick={() => commitDeletion(idToDelete)}>{t('Tak')}
                        </button>
                        <button className="btn btn-primary" onClick={() => setIdToDelete(null)}>{t('Anuluj')}</button>
                    </div>
                </UBoldModal>
            </Card>
            <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12">
               <Card>
                   <h4 className="mt-2 card-title text-success"><i className="fe-info fe-xl"></i> Informacje</h4>
                   <React.Fragment>
                       <p>
                           Tutaj można przeglądać utworzone role z ustawionymi dla nich przywilejami oraz przypisanymi użytkownikami.
                       </p>
                       <p>
                           W celu ułatwienia zarządzania dostępem do systemu, przywileje nie są przypisywane bezpośrednio do użytkowników,
                           lecz zamiast tego do ról, a następnie to role są przyznawane użytkownikom (tzw. RBAC). Jedna rola może posiadać
                           wiele przypisanych przywilejów. Wielu użytkowników może mieć przypisaną tę samą rolę i jednocześnie użytkownik może
                           mieć przypisaną więcej niż jedną rolę.
                       </p>
                   </React.Fragment>

                   <NavLink className="btn btn-primary" to={"/roles/add"}><i className={"mdi mdi-plus"}/> {t('Zdefiniuj nową rolę')}</NavLink>
               </Card>
            </div>
        </div>
    </React.Fragment>;
}

RolesPage.propTypes = {}

RolesPage.defaultProps = {}