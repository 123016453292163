import React from 'react';
import {ExpandableMenuItem} from "./ExpandableMenuItem";
import {NavLink} from "react-router-dom";
import {NavLinkMenuItem} from "./NavLinkMenuItem";
import {createInternationalizationPrefixer, i18n, LanguageSelector} from "../platform/i18n/i18n";
import {OptiselProfileBox} from "../components/OptiselProfileBox";
import {useCurrentUserPrivileges} from "../platform/hooks/PlatformHooks";
import {LATEST_OPTISEL_VERSION} from "../components/Changelog";
import {AdminOnly} from "./AdminOnly";
import {Protected} from "./Protected";
import {HotIssueInformationBanner} from "../pages/issues/hotssue/HotIssueInformationBanner";


const t = createInternationalizationPrefixer("StandardLayout");

export function StandardLayout(props) {

    const privileges = useCurrentUserPrivileges();

    const mobileMenuButton = <div className="d-flex topbar align-items-center d-lg-none ms-n2 me-3" title="Show aside menu">
        <div className="btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px" id="kt_header_menu_mobile_toggle">
                            <span className="svg-icon svg-icon-2x">
										<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
											<path d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z" fill="black"/>
											<path opacity="0.3" d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z" fill="black"/>
										</svg>
                            </span>
        </div>
    </div>;

    const headerLogoSection = <div className="header-logo me-5 me-md-10 flex-grow-1 flex-lg-grow-0">
        <a href="/">
            <h2 className="text-white m-0">Optisel 2.0</h2>
            <div className={"m-0 p-0 fs-8 text-white text-right"}>{LATEST_OPTISEL_VERSION}</div>

        </a>
    </div>;

    // TODO remove <NavLinkMenuItem to={"/fan-selection"} label={i18n("Dobór (prototypowy)")}/>
    const menuNavigation = <div className="header-menu align-items-stretch" data-kt-drawer="true" data-kt-drawer-name="header-menu" data-kt-drawer-activate="{default: true, lg: false}" data-kt-drawer-overlay="true" data-kt-drawer-width="{default:'200px', '300px': '250px'}" data-kt-drawer-direction="start"
                                data-kt-drawer-toggle="#kt_header_menu_mobile_toggle" data-kt-swapper="true" data-kt-swapper-mode="prepend" data-kt-swapper-parent="{default: '#kt_body', lg: '#kt_header_nav'}">
        <div className="menu menu-lg-rounded menu-column menu-lg-row menu-state-bg menu-title-gray-700 menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-400 fw-bold my-5 my-lg-0 align-items-stretch" id="#kt_header_menu" data-kt-menu="true">
            <NavLinkMenuItem to={"/selection"} label={i18n("Dobór")}/>
            <Protected privilege={"IMPELLER_SIMULATION"}><NavLinkMenuItem to={"/simulation"} label={i18n("Symulacja")}/></Protected>
            <Protected privilege={"IMPELLER_ASSEMBLY"}><NavLinkMenuItem to={"/impeller-assembly"} label={i18n("Złożenie wirnika")}/></Protected>

            <AdminOnly>
                <ExpandableMenuItem label={t("Administracja")}>
                    <Protected privilege={"SELECTION_HISTORY"}>
                        <div className="menu-item">
                            <NavLink className="menu-link py-3" to="/browse-issues">
                                <i className="mdi mdi-file-document-box"></i>
                                <span className="menu-title">{t("Sprawy")}</span>
                            </NavLink>
                            <NavLink className="menu-link py-3" to={"/event-history"}><i className="mdi mdi-square-edit-outline"></i>
                                <span className="menu-title">{t('Historia doborów')}</span>
                            </NavLink>
                        </div>
                        <hr/>
                    </Protected>
                    <Protected privilege={"IMPELLER_DATA_ADMINISTRATION"}>
                        <NavLinkMenuItem to={"/simulation-examples"} label={i18n("Portal")}/>
                        <NavLinkMenuItem to={"/blade-profiles"} label={i18n("Profile łopatowe")}/>
                        <NavLinkMenuItem to={"/adjustable-impellers"} label={i18n("Wirniki")}/>
                        <NavLinkMenuItem to={"/upload-xls-data"} label={i18n("Załaduj dane")}/>
                        <NavLinkMenuItem to={"/historical-uploads"} label={i18n("Historia wgranych plików")}/>
                        <NavLinkMenuItem to={"/mechanical-settings"} label={i18n("Ustawienia mechaniczne")}/>
                        <NavLinkMenuItem to={"/translation-rules"} label={i18n("Tłumaczenia")}/>
                        <NavLinkMenuItem to={"/standard-listing"} label={t("Typoszeregi")}/>
                    </Protected>
                </ExpandableMenuItem>
            </AdminOnly>


            <Protected privilege={"IMPELLER_DATA_ADMINISTRATION"}>
                <ExpandableMenuItem label={t("Dane mechaniczne")}>

                    <div className="menu-item">

                        <NavLink className="menu-link py-3" to={"/mechanical-component/blade-form"}><i className="mdi mdi-square-edit-outline"></i>
                            <span className="menu-title">{t("Dane - Formy łopat")}</span>
                        </NavLink>
                        <NavLink className="menu-link py-3" to={"/mechanical-component/blade-form-clutch-compatibility"}><i className="mdi mdi-square-edit-outline"></i>
                            <span className="menu-title">{t("Dane - Kompatybilność typów sprzęgieł")}</span>
                        </NavLink>
                        <NavLink className="menu-link py-3" to={"/mechanical-component/blade-mount-strategy"}><i className="mdi mdi-square-edit-outline"></i>
                            <span className="menu-title">{t("Dane - Strategie montażu łopat")}</span>
                        </NavLink>
                        <NavLink className="menu-link py-3" to={"/mechanical-component/clutch-manufacturing"}><i className="mdi mdi-square-edit-outline"></i>
                            <span className="menu-title">{t("Dane - Manufakturyzacje sprzęgieł")}</span>
                        </NavLink>
                        <NavLink className="menu-link py-3" to={"/mechanical-component/disc-thickness-requirement"}><i className="mdi mdi-square-edit-outline"></i>
                            <span className="menu-title">{t("Dane - Wymogi grubości tarcz")}</span>
                        </NavLink>
                        <NavLink className="menu-link py-3" to={"/mechanical-component/pin-bladesheet-tension-estimation"}><i className="mdi mdi-square-edit-outline"></i>
                            <span className="menu-title">{t("Dane - Estymaty naprężeń czop-płat")}</span>
                        </NavLink>
                    </div>
                </ExpandableMenuItem>
            </Protected>

            <ExpandableMenuItem label={t("Konto")}>
                <div className="menu-item">
                    {privileges.includes("USER_ACCOUNT_MANAGEMENT") && <NavLink className="menu-link py-3" to={"/accounts"}><i className="mdi mdi-user"></i>
                        <span className="menu-title">{t("Konta użytkowników")}</span>
                    </NavLink>}
                    {privileges.includes("USER_ACCOUNT_MANAGEMENT") && <NavLink className="menu-link py-3" to={"/create-account"}><i className="mdi mdi-user-plus"></i>
                        <span className="menu-title">{t("Utwórz konto")}</span>
                    </NavLink>}
                    <NavLink className="menu-link py-3" to={"/change-password"}><i className="mdi mdi-lock"></i>
                        <span className="menu-title">{t("Zmień swoje hasło")}</span>
                    </NavLink>

                    {privileges.includes("AUTHORIZATION_MANAGEMENT") && <NavLink className="menu-link py-3" to={"/roles"}><i className="mdi mdi-shield"></i>
                        <span className="menu-title">{t("Role")}</span>
                    </NavLink>}
                    {privileges.includes("AUTHORIZATION_MANAGEMENT") && <NavLink className="menu-link py-3" to={"/roles/add"}><i className="mdi mdi-plus"></i>
                        <span className="menu-title">{t("Utwórz nową role")}</span>
                    </NavLink>}
                </div>
            </ExpandableMenuItem>

            <div className="menu-item">
                <LanguageSelector/>
            </div>

        </div>
    </div>;

    const userProfileBox = <OptiselProfileBox/>


    const footer = <div className="footer py-4 d-flex flex-lg-column" id="kt_footer">
        <div className="container-xxl d-flex flex-column flex-md-row align-items-center justify-content-between">
            <div className="text-dark order-2 order-md-1">
                <span className="text-muted fw-bold me-1">&copy;</span>
                <a href="/" rel="noopener noreferrer" target="_blank" className="text-gray-800 text-hover-primary">Optisel</a>
            </div>

            <ul className="menu menu-gray-600 menu-hover-primary fw-bold order-1">
                <li className="menu-item">
                    <a href="https://wentech.com.pl" rel="noopener noreferrer" target="_blank" className="menu-link px-2">Wentech</a>
                </li>

            </ul>

        </div>
    </div>;


    return <div className="page d-flex flex-row flex-column-fluid">
        <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">

            <div id="kt_header" className="header align-items-stretch" data-kt-sticky="true" data-kt-sticky-name="header" data-kt-sticky-offset="{default: '200px', lg: '300px'}">
                <div className="container-xxl d-flex align-items-center">
                    {mobileMenuButton}
                    {headerLogoSection}

                    <div className="d-flex align-items-stretch justify-content-between flex-lg-grow-1">
                        <div className="d-flex align-items-stretch" id="kt_header_nav">
                            {menuNavigation}
                        </div>

                        <div className="d-flex align-items-stretch flex-shrink-0">
                            <div className="topbar d-flex align-items-stretch flex-shrink-0">
                                {userProfileBox}
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div id="kt_content_container" className="d-flex flex-column-fluid align-items-start container-xxl mt-15">
                <div className="content flex-row-fluid" id="kt_content">

                    <Protected privilege={"SELECTION_HISTORY"}>
                        <HotIssueInformationBanner/>
                    </Protected>


                    {props.includeContentCard ? <div className="card" style={{minHeight: "300px"}}>
                        <div className="card-body ">
                            {props.children}
                        </div>
                    </div> : props.children}
                </div>
            </div>

            {footer}
        </div>

    </div>;
}
