import React, {useCallback} from "react";
import {OneOfEditor} from "form/editors/OneOfEditor";

export function MetronicMultiButtonOneOfEditor(props: { editor: OneOfEditor }) {
    return <div className={"d-inline-flex gap-1"}>
        {props.editor.options.map(opt => <button key={opt.key} className={"btn btn-sm " + (props.editor.value == opt.key ? "btn-primary" : "btn-secondary")} onClick={() => props.editor.populate(opt.key)}>{opt.label}</button>)}
    </div>
}

export function MetronicMultiButtonOneOfEditorXxs(props: { editor: OneOfEditor }) {
    return <div className={"d-inline-flex gap-2 flex-wrap pt-1"}>
        {props.editor.options.map(opt => <button key={opt.key} className={"btn btn-xxs " + (props.editor.value == opt.key ? "btn-primary" : "btn-secondary")} onClick={() => props.editor.populate(opt.key)}>{opt.label}</button>)}
    </div>
}