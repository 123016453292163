import React from 'react';
import UboldInvalidFeedbackList from "../../ubold-components/UboldInvalidFeedbackList";
import {cloneJson} from "../util/JsonObjectCloner";

function UboldKeyValue(props) {
    return <div className="row mt-2">
        <div className="col-4">
        </div>
        <div className="col-4">
            <input value={props.keyValue} type="text" className={"form-control" + (props.errors.length > 0 ? " is-invalid" : "")} placeholder={props.keyPlaceholder} onChange={props.onKeyChange}/>
        </div>
        <div className="col-4">
            <input value={props.valueValue} type="text" className={"form-control" + (props.errors.length > 0 ? " is-invalid" : "")} placeholder={props.valuePlaceholder} onChange={props.onValueChange}/>
        </div>
        <div className="col-8 offset-4">
            <UboldInvalidFeedbackList errors={props.errors}/>
        </div>
    </div>;
}

export default function KeyValueWebFormComponent(props) {

    const dsl = props.dsl;

    function keyChange(e) {
        const newDsl = cloneJson(dsl);
        newDsl.value.key = e.target.value;
        props.onDslChange(newDsl);
    }

    function valueChange(e) {
        const newDsl = cloneJson(dsl);
        newDsl.value.value = e.target.value;
        props.onDslChange(newDsl);
    }

    return <UboldKeyValue
        keyPlaceholder={dsl.configuration.keyPlaceholder}
        valuePlaceholder={dsl.configuration.valuePlaceholder}
        keyValue={dsl.value.key}
        valueValue={dsl.value.value}
        onKeyChange={keyChange}
        onValueChange={valueChange}
        errors={dsl.errors}/>
}

KeyValueWebFormComponent.propTypes = {}

KeyValueWebFormComponent.defaultProps = {}