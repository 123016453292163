import React, {useState} from 'react';
import {NavLink} from "react-router-dom";
import StandardAdminBrowserLayout from "../../platform/browser/StandardAdminBrowserLayout";
import TableBrowserResultsComponent from "../../platform/browser/TableBrowserResultsComponent";
import StandardBrowserPaginationComponent from "../../platform/browser/StandardBrowserPaginationComponent";
import UBoldModal from "../../ubold-components/UBoldModal";
import BrowserContainer from "../../platform/browser/BrowserContainer";
import AjaxService from "../../platform/ajax/AjaxService";
import {createInternationalizationPrefixer} from "../i18n/i18n";

const t = createInternationalizationPrefixer("BrowseUserAccountPage");


export default function BrowseUserAccountPage(props) {

    const [idToDelete, setIdToDelete] = useState(null);

    const customCellRenderers = {
        name: result => <strong>{result.name}</strong>,
        entitlements: result =>
            <React.Fragment>
                {(!result.entitlements.length ?
                    <span className="text-muted">{t('Brak')}</span> : result.entitlements.map(m => <div key={m.code}>{m.name}</div>))}
                <NavLink className="action-icon" to={"/accounts/change-entitlements/" + result.id}> <i className="fas fa-edit"></i> Zmień</NavLink>
            </React.Fragment>,
        actions: result => <div className="text-center">
            <NavLink className="action-icon" to={"/accounts/change-password/" + result.id}> <i className="fas fa-key"></i> Zmień hasło</NavLink>
            <a className="action-icon" href="#" onClick={() => setIdToDelete(result.id)}> <i className="fas fa-trash-alt"></i> Usuń</a>
        </div>
    };

    const tableHeaderMapping = {
        email: t('E-mail'),
        name: t('Nazwa'),
        entitlements: t('Uprawnienia'),
        actions: <th key={"actions"} className={"text-center"}>{t('Akcje')}</th>,
    };

    const browserLayoutFactory = (browserData) =>
        <StandardAdminBrowserLayout
            header={"Konta użytkowników"}
            criteriaComponent={<div><NavLink to={"/create-account"} className={"btn btn-primary"}><i className="fas fa-user-plus"></i> Utwórz nowe konto</NavLink></div>}
            resultsComponent={<TableBrowserResultsComponent
                results={browserData.results}
                fetching={browserData.fetching}
                headerMapping={tableHeaderMapping}
                customCellRenderers={customCellRenderers}
                noResultsContent={<div className="text-center mt-4 h4 text-muted">{t('Brak wyników')}</div>}
            />}
            paginationComponent={<StandardBrowserPaginationComponent
                criteria={browserData.criteria}
                fetching={browserData.fetching}
                results={browserData.results}
                onCriteriaChange={browserData.changeCriteriaListener}/>}
        />;

    const commitDeletion = id => {
        AjaxService.delete("/api/user-account/" + id).then(response => {
            if (response.ok) {
                setIdToDelete(null);
                window.location.reload(false);
            }
        });
    };

    return [
        <UBoldModal key={1} opened={idToDelete !== null} onClose={() => setIdToDelete(null)}
                    header={t("Czy na pewno usunąć?")}>
            <div className="text-center">
                <button className="btn btn-warning mr-2"
                        onClick={() => commitDeletion(idToDelete)}>Tak
                </button>
                <button className="btn btn-primary" onClick={() => setIdToDelete(null)}>{t('Anuluj')}</button>
            </div>
        </UBoldModal>,
        <BrowserContainer
            key={2}
            url={"/api/user-account"}
            initialCriteria={{limit: 10, offset: 0}}
            layoutComponentFactory={browserLayoutFactory}/>];
}
