import {useContext, useEffect, useState} from "react";
import AjaxService from "../ajax/AjaxService";
import {WorkspaceContext} from "../workspace/WorkspaceContextProvider";

/**
 * Allows to call function when user clicked outside of component refered by ref
 * @param ref Reference to component e.g. "const cref = useRef(null);" then "<div ref={cref}..."
 * @param func Function that will be called when user clicked outside of component
 */
export default function useClickOutsideListener(ref, func) {
    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                //setExpanded(false);
                func();
            }
            return true;
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref, func]);
}

export function useObjectFromUrlResourceOrNull(url, otherDependenciesToTriggerRefetch) {
    const [objectData, setObjectData] = useState(null);

    useEffect(() => {
        if (url === null) {
            return;
        }
        setObjectData(null);
        AjaxService.getJson(url)
            .then(r => {
                setObjectData(r.json);
            });
    }, [url, otherDependenciesToTriggerRefetch]);

    return objectData;
}

export function useCurrentUserHasPrivilege(privilegeCode) {
    const workspaceContext = useContext(WorkspaceContext);
    if (workspaceContext && workspaceContext.data && workspaceContext.data.privileges) {
        return workspaceContext.data.privileges.includes(privilegeCode);
    }
    return false;
}

export function useCurrentUserPrivileges()  {
    const workspaceContext = useContext(WorkspaceContext);

    if (workspaceContext && workspaceContext.data && workspaceContext.data.privileges) {
        return workspaceContext.data.privileges;
    }
    return "loading";
}

export function useCurrentlyManagedOrganizationIdOrNull(url, otherDependenciesToTriggerRefetch) {
    const workspaceContext = useContext(WorkspaceContext);
    if (!workspaceContext || !workspaceContext.data || !workspaceContext.data.managedOrganization || !workspaceContext.data.managedOrganization.id) {
        return;
    }

    return workspaceContext.data.managedOrganization.id;
}

