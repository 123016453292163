import {SimulationRequestJson} from "./ApiJsonTypes";
import {useEffect, useState} from "react";
import AjaxService from "../platform/ajax/AjaxService";
import {FailedSimulationResponse, GeneralSimulationResultJson, SimulationResponse, SuccessfulSimulationResponse} from "./useImpellerSimulation";
import {InstalledFanDatasheet} from "./InstalledFanDatasheet";
import {LATEST_OPTISEL_VERSION} from "../components/Changelog";
import {H3RangeEstimationJson, MechanicalSelectionH3RangeEstimationJson} from "../pages/mechanical/ImpellerAssemblyService";


let simIdForDatasheet = ""; // TODO avoid global variable


export interface SuccessfulDatasheetResponse {
    result: InstalledFanDatasheet,
    memoizedId: string,
    blueprintId: string | null,
    h3RangeEstimationJson: MechanicalSelectionH3RangeEstimationJson | null
}

export type SimulatedDatasheetResponse = SuccessfulDatasheetResponse | FailedSimulationResponse;

export function isSuccessful(resp?: SimulatedDatasheetResponse): resp is SuccessfulDatasheetResponse {
    return resp !== undefined && resp.hasOwnProperty("result");
}

export function isFailed(resp?: SimulatedDatasheetResponse): resp is FailedSimulationResponse {
    return resp !== undefined && resp.hasOwnProperty("error");
}

// for now, the datasheet is created usisng simulation request, but the reponse is different
export function useImpellerDatasheet(simulationRequest: SimulationRequestJson | "incorrect-request"): {
    datasheetResponse?: SimulatedDatasheetResponse,
    loading: boolean
} {

    const [datasheetResponse, setDatasheetResponse] = useState<SimulatedDatasheetResponse>();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (simulationRequest === "incorrect-request") {
            setDatasheetResponse(undefined)
            return;
        }

        let abortController: AbortController | null = new AbortController();

        simIdForDatasheet = "__simid_" + Math.round((Math.random() * 10000000));
        setLoading(true);
        // TODO cancel legacy pending request
        AjaxService.postJson(
            "/api/datasheet-simulated?appVersion=" + LATEST_OPTISEL_VERSION,
            simulationRequest,
            undefined,
            simIdForDatasheet,
            abortController).then(result => {
            if (simIdForDatasheet === result.requestId) {
                setDatasheetResponse(result.json as SimulatedDatasheetResponse);
                setLoading(false); // Note this line is a lot more meaningful that it may be usspected. Non-loading causes remount of chart, and thus whole D3 reinitialization.
                // Making setLoading(false) in finally could result to remount and recalculation of the chart before proper result (simId) returns, making chart to be presented obsoletely on quick criteria change
                // TODO address this better than removing loading false from finally
            } else {
                console.log("Dropped response " + result.requestId + " at it does not serve latest request", result);
            }
        }).catch(err => {

        }).finally(() => {
            abortController = null; // Request completed or failed, no need to hold reference to abort controller
        });

        return () => {
            if (abortController != null) {
                console.log("Aborting request due to unmount or criteria change");
                abortController.abort();
            }
        }
    }, [JSON.stringify(simulationRequest)]);

    return {
        datasheetResponse: datasheetResponse,
        loading
    }
}