import React, {useEffect} from 'react';
import UboldSpinner from "../../ubold-components/UboldSpinner";

import {Card} from "../../components/Card";
import {createAsyncResultsProviderViaApiWithGetParams, SearchResultsOrder, useSearch, useSelectFilterManager, useTextFilterManager} from "../../platform/search/PlatformSearch";
import {AdditionalSelectFilter, AdditionalTextFilter, CurrentlyUsedFilters, OrderSelector, SearchQueryBar} from "../../components/search/MetronicSearchComponents";
import {OptiselEventTable} from "./OptiselEventTable";

export interface OptiselEventPayloadJson {
    typeCode: string
}

export type OptiselEventPayloadsNotLoaded = null;

export interface OptiselEventJson {
    id: string,
    creationMillis: number,
    type: string,
    typeDisplayName: string,
    discriminatorName: string,
    sourceSystemName: string,
    persistent: boolean,
    performerDisplayName: string,
    performerUserAccountIdentifier: string,
    caseIdentification: { // DEPRECATED IN EVENT LEVEL!!!!! MOST MOVED TO ISSUE LEVEL?
        inquiryNumber?: string,
        caseNumber?: string,
        mechanicalDesignIdentifier?: string
    },
    payloads: OptiselEventPayloadJson[] | OptiselEventPayloadsNotLoaded // TODO in future split into separate interfaces for short info vs full
}


export function OptiselEventHistoryPage(props: {}) {
    const queryFilterManager = useTextFilterManager("query", "Szukaj");
    const caseNumberFilterManager = useTextFilterManager("caseNumberFilter", "Numer sprawy");
    const inquiryNumberFilterManager = useTextFilterManager("inquiryNumberFilter", "Numer zapytania");
    const fanDesignIdentificatorFilterManager = useTextFilterManager("fanDesignIdentificatorFilter", "Identyfikator złożenia");
    const persistedOnlyFilterManager = useSelectFilterManager("persistedOnlyFilter", "Tylko utrwalone", [
        {label: "Nie", value: ""},
        {label: "Tak", value: "true"},
    ]);

    useEffect(() => {

        persistedOnlyFilterManager.setCurrentValueExternally("true");
    }, []);
    // TODO "Persisted only"

    const availableOrders: SearchResultsOrder[] = [
        {id: "creation-desc", name: "Najnowsze wg czasu utworzenia", informationalDirection: "desc"},
        {id: "id-asc", name: "Identyfikator", informationalDirection: "asc"},
        {id: "name-asc", name: "Nazwa", informationalDirection: "asc"},
        {id: "case-number-asc", name: "Numer sprawy", informationalDirection: "asc"}
    ];

    const {
        currentlyActiveFilterManagers,
        refresh,
        isLoading,
        results,
        reset,
        currentOrderId,
        changeOrderId,
        paginationDriver: {
            currentPage,
            switchToNextPage,
            nextPageSwitchAvailable,
        }
    } = useSearch<any, OptiselEventJson>(
        createAsyncResultsProviderViaApiWithGetParams<OptiselEventJson>("/api/optisel-event-history/search"),
        [queryFilterManager, fanDesignIdentificatorFilterManager, caseNumberFilterManager, inquiryNumberFilterManager, persistedOnlyFilterManager],
        availableOrders,
        20);


    return <>
        <Card additionalClassNames={"card mb-4"}>
            <SearchQueryBar queryFilterManager={queryFilterManager} reset={reset} refresh={refresh}/>
            <div className="separator separator-dashed mt-6 mb-4"/>
            <div className="row">
                <div className="col-3">
                    <AdditionalTextFilter fanNameFilterManager={fanDesignIdentificatorFilterManager}/>
                </div>
                <div className="col-3">
                    <AdditionalTextFilter fanNameFilterManager={caseNumberFilterManager}/>
                </div>
                <div className="col-3">
                    <AdditionalTextFilter fanNameFilterManager={inquiryNumberFilterManager}/>
                </div>
                <div className="col-3">
                    <AdditionalSelectFilter filterManager={persistedOnlyFilterManager}/>
                </div>

            </div>
        </Card>
        <Card>
            <div className={"d-flex mb-2"}>
                <div className={"flex-grow-1"}>
                    <CurrentlyUsedFilters currentlyActiveFilterManagers={currentlyActiveFilterManagers}/>
                </div>
                <div className={"d-flex align-content-center"}>
                    <OrderSelector changeOrderId={changeOrderId} currentOrderId={currentOrderId} availableOrders={availableOrders}/>
                </div>
            </div>

            <div>
                {isLoading ? <div className="text-center mt-5 mb-5"><UboldSpinner/></div> : null}
            </div>
            {!isLoading && results.length === 0 && <div className="text-center fs-4 text-gray-600 mt-5 mb-5">No results found</div>}

            {!isLoading && results.length > 0 && <div className={"table-responsive"}>
                <OptiselEventTable results={results}/>

                {nextPageSwitchAvailable && <div>
                    <span className={"text-muted me-4"}>Strona {currentPage}</span>
                    <button className={"btn btn-outline btn-outline-dashed btn-sm"} onClick={switchToNextPage}>Szukaj więcej</button>
                </div>}
            </div>}
        </Card>
    </>;
}


