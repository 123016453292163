import {useParams} from "react-router";
import {useFetchAllObjs} from "../../platform/hooks/PlatformTypeScriptHooks";
import {Card} from "../../components/Card";
import React, {useContext} from "react";
import {IssueApiJson} from "./IssueApiJson";
import {NavLink} from "react-router-dom";
import {callApiDetachOptiselEventToIssue, useAllIssueAttachedOptiselEvents} from "./useIssueApi";
import UboldSpinner from "../../ubold-components/UboldSpinner";
import {OptiselEventTable} from "../optiselevent/OptiselEventTable";
import {HotIssueContext} from "./hotssue/HotIssueContextProvider";

export function ShowIssuePage() {

    const {id} = useParams();

    const issue = useFetchAllObjs<IssueApiJson>(`/api/issue/${id}`);

    const {result: events, refetch: refetchEvents} = useAllIssueAttachedOptiselEvents(id!);

    const {makeHotIssue} = useContext(HotIssueContext);

    const unAttachEvent = (eventId: string) => {
        if (window.confirm("Czy na pewno odłączyć?")) {
            callApiDetachOptiselEventToIssue(eventId, id!).finally(() => {
                refetchEvents();
            })
        }
    }

    if (id == null) {
        throw new Error("Missing issue id");
    }
    if (issue == null) {
        return "...";
    }


    return <>
        <Card header={"Sprawa: " + issue.name}>
            <div className="card-body">
                <div className="row">
                    <div className="col-md-6">
                        <div className="mb-3">
                            <strong>Utworzono przez:</strong> {issue.originalCreatorName}
                        </div>
                        <div className="mb-3">
                            <strong>Utworzono:</strong> {new Date(issue.creationInstant).toLocaleString("pl-PL")}
                        </div>
                        <div className={"d-flex gap-2"}>
                            <button className={"btn btn-sm btn-primary"} onClick={() => makeHotIssue(issue.id)}>Pracuj z</button>
                            <NavLink to={"/edit-issue/" + issue.id} className={"btn btn-sm btn-secondary"}><i className={"fas fa-plus-circle"}/> Edytuj sprawę</NavLink>
                        </div>
                    </div>
                    <div className="col-md-6">
                        {issue.issueReferences.wentechCaseNumber && (
                            <div className="mb-3">
                                <strong>Numer sprawy:</strong> {issue.issueReferences.wentechCaseNumber}
                            </div>
                        )}
                        {issue.issueReferences.wentechInquiryNumber && (
                            <div className="mb-3">
                                <strong>Numer zapytania:</strong> {issue.issueReferences.wentechInquiryNumber}
                            </div>
                        )}
                        {issue.issueReferences.customerOrderNumber && (
                            <div className="mb-3">
                                <strong>Numer zapytania klienta:</strong> {issue.issueReferences.customerOrderNumber}
                            </div>
                        )}
                        {issue.issueReferences.customerReferenceNumber && (
                            <div className="mb-3">
                                <strong>Numer referencyjny klienta:</strong> {issue.issueReferences.customerReferenceNumber}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </Card>
        <Card header="Przypisane zdarzenia" additionalClassNames={"mt-3"}>
            {events === "loading" ? <UboldSpinner/> : <div className={"table-responsive"}>
                <OptiselEventTable results={events} additionalActionComponents={eventId => <>
                    <button className={"btn btn-sm btn-light ms-2"} onClick={() => unAttachEvent(eventId)}><i className={"fas fa-trash"}/> Odłącz</button>
                </>}/>
            </div>}
        </Card>
    </>;


}