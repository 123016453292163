import {Editor, EditorChangeListener} from "form/Editor";
import {NumberEditor} from "form/editors/NumberEditor";
import {MeasurementUnit, MeasuringOptions} from "platform/measurement/MeasurementUnits";
import {OneOfEditor, SelectionKey, SelectionOption} from "form/editors/OneOfEditor";
import {MetronicMeasureEditor} from "../metronic/MetronicMeasureEditor";
import {ValidNumber} from "../../platform/measurement/ValidNumber";
import {MeasurementStandard} from "../../platform/measurement/MeasurementStandard";

export type MeasureEditorValue = ValidNumber | null;

export class MeasureEditor implements Editor<MeasureEditorValue> {

    private numberEditor: NumberEditor;
    private unitEditor: OneOfEditor;
    private currentlySelectedUnitId: string;
    private unitOptions: MeasurementUnit[];
    private listeners: EditorChangeListener[] = [];

    constructor(private initialBaseUnitValue: MeasureEditorValue,
                private readonly baseDecimalPlaces: number,
                private readonly measuringOptions: MeasuringOptions,
                private readonly baseUnitConstraint?: { min: number, max: number }) {
        if (initialBaseUnitValue !== null && (isNaN(initialBaseUnitValue) || initialBaseUnitValue === Infinity || initialBaseUnitValue === -Infinity)) {
            throw new Error("Initial value cannot be NaN/infinity");
        }

        this.unitOptions = [measuringOptions.baseUnit, ...measuringOptions.customUnits];
        const unitEditorOptions = this.unitOptions.map((unitOption: MeasurementUnit): SelectionOption => ({
            key: unitOption.id,
            label: unitOption.displayText
        }));

        this.currentlySelectedUnitId = measuringOptions.baseUnit.id;

        this.unitEditor = new OneOfEditor(
            unitEditorOptions,
            undefined,
            measuringOptions.baseUnit.id);
        this.unitEditor.addChangeListener(() => {
            this.onUnitEditorValueChange()
        });


        this.numberEditor = NumberEditor.defaultWithFixedDecimalPlaces(
            initialBaseUnitValue,
            () => baseDecimalPlaces + this.getCurrentlySelectedMeasurementUnit().additionalDecimalPlaces);
        this.numberEditor.addChangeListener(() => {
            this.constraintNumberEditorValueToMinMaxIfSet();
        });
    }

    Component = (): JSX.Element => {
        return <MetronicMeasureEditor editor={this}/>;
    }

    get errorList(): string[] {
        return [];
    }

    get isValid(): boolean {
        return true;
    }

    get numericEditorReadOnly(): boolean {
        return this.numberEditor.readOnly;
    }

    /**
     * Makes numeric editor read only preserving current unit editor state
     * (useful if you want to drive the editor externally, but still allow for unit change.
     */
    makeNumericEditorReadOnly = () => {
        this.numberEditor.makeReadonly();
    }

    /**
     * Make numeric editor editable preserving current unit editor state
     */
    makeNumericEditorEditable = () => {
        this.numberEditor.makeEditable();
    }

    toggleNumericEditorEditability = () => {
        if (this.numberEditor.readOnly) {
            this.makeNumericEditorEditable();
        } else {
            this.makeNumericEditorReadOnly();
        }
    }

    private onUnitEditorValueChange = () => {
        try {
            // TODO recalculate!
            this.numberEditor.commit();
            const valueBeforeChange = this.numberEditor.value;
            if (valueBeforeChange === null) {
                // Do nothing as current value is not a valid number. No point of recalculating empty or "incorrect text" to new unit
                return;
            }
            const selectedMeasurementUnit = this.getCurrentlySelectedMeasurementUnit();
            // First convert to base
            const previousMeasurementUnit = this.unitOptions.find(unitOption => unitOption.id === this.currentlySelectedUnitId)!;
            const newBaseUnitValue = previousMeasurementUnit.toBaseUnit(valueBeforeChange);
            const newCustomUnitValue = selectedMeasurementUnit.fromBaseUnit(newBaseUnitValue);
            this.currentlySelectedUnitId = selectedMeasurementUnit.id;
            // Note that this populate will use formatter that has number of decimals linked to currently selected unit
            this.numberEditor.populate(newCustomUnitValue);
        } finally {
            // Parent may be always interested that unit was changed, even if not correct value was converted etc.
            this.listeners.forEach(l => l());
        }
    }

    private constraintNumberEditorValueToMinMaxIfSet = () => {
        if (this.numberEditor.value !== null) {
            if (this.baseUnitConstraint !== undefined) {
                if (this.baseUnitConstraint.max < this.baseUnitConstraint.min) {
                    throw new Error("Base unit constraint max is smaller than min! " + this.baseUnitConstraint.min + " - " + this.baseUnitConstraint.max);
                }

                const currentUnitMinimum = this.numberEditor.probeValueAfterReparsing(this.getCurrentlySelectedMeasurementUnit().fromBaseUnit(this.baseUnitConstraint.min));
                if (currentUnitMinimum == null) {
                    console.warn("The specified minimum of " + this.baseUnitConstraint.min + " failed at reparsing so number field will be reset");
                    this.numberEditor.reset();
                    return;
                }
                if (this.numberEditor.value < currentUnitMinimum) {
                    this.numberEditor.populate(currentUnitMinimum);
                    return; // Do not notify clients on change - editor will trigger the change again, but with constrained value
                }

                const currentUnitMaximum = this.numberEditor.probeValueAfterReparsing(this.getCurrentlySelectedMeasurementUnit().fromBaseUnit(this.baseUnitConstraint.max));
                if (currentUnitMaximum == null) {
                    console.warn("The specified maximum of " + this.baseUnitConstraint.min + " failed at reparsing so number field will be reset");
                    this.numberEditor.reset();
                    return;
                }
                if (this.numberEditor.value > currentUnitMaximum) {
                    this.numberEditor.populate(currentUnitMaximum);
                    return; // Do not notify clients on change - editor will trigger the change again, but with constrained value
                }
            }
        }
        this.listeners.forEach(l => l());
    }

    /**
     * Value is returned always in base unit
     */
    get value(): MeasureEditorValue {
        if (this.numberEditor.value === null) {
            return null;
        }
        return this.getCurrentlySelectedMeasurementUnit().toBaseUnit(this.numberEditor.value);
    }

    get currentTextInSelectedUnit(): string {
        return this.numberEditor.currentText;
    }

    commit = (): void => {
        this.numberEditor.commit();
        this.unitEditor.commit(); // Probably not necessary but just in case for future
    }

    populate(value: ValidNumber): void {
        const valueInCurrentlySelectedUnit = this.getCurrentlySelectedMeasurementUnit().fromBaseUnit(value);
        this.numberEditor.populate(value);
        // Note change listener not needed as number editor listener already propagates to this measure editor listeners
    }

    reset(): void {
        // Reset order is important here, because unit reset will reconvert the numeric value
        this.unitEditor.reset();
        this.numberEditor.reset();
        // Note change listener not needed as number editor listener already propagates to this measure editor listeners
    }

    private getCurrentlySelectedMeasurementUnit = (): MeasurementUnit => {
        const selectedMeasurementUnit = this.unitOptions.find(unitOption => unitOption.id === this.unitEditor.value);
        if (selectedMeasurementUnit === undefined) {
            console.error("Failed to convert units", {
                unitOptions: this.unitOptions,
                selectedkey: this.unitEditor.value
            });
            throw new Error("Cannot perform unit recalculation as selected unit cannot be found against options, see error logs");
        }
        return selectedMeasurementUnit;
    }

    get unitSelectionOptions(): SelectionOption[] {
        return this.unitEditor.options;
    }

    populateUnitSelection(value: SelectionKey): void {
        this.unitEditor.populate(value);
    }

    get selectedUnitKey(): SelectionKey {
        return this.unitEditor.value;
    }

    get unparseableTextPresent(): boolean {
        return this.numberEditor.unparseableTextPresent;
    }

    updateNumericValueText(text: string) {
        this.numberEditor.updateText(text);
    }

    addChangeListener = (listener: EditorChangeListener): void => {
        this.listeners.push(listener);
    }
}

export function createEditorForMeasurementStandard(standard: MeasurementStandard): MeasureEditor {
    return new MeasureEditor(standard.defaultValueInBaseUnit, standard.baseDecimalPlaces, standard.measuringOptions, standard.baseUnitConstraint)
}