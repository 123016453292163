import React, {useState} from 'react';
import Downshift from 'downshift'
import UboldSpinner from "../UboldSpinner";
import UboldInvalidFeedbackList from "../UboldInvalidFeedbackList";

export default function UboldDownshift(props) {

    const [proposals, setProposals] = useState([]);
    const [loading, setLoading] = useState(false);


    function fetchProposals(query) {
        setLoading(true);
        fetch(`${props.autocompleteUrl}${query}`, {
            method: 'GET'
        }).then(res => res.json())
            .then(response => {
                setProposals(response);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    function inputOnChange(event) {
        if (!event.target.value) {
            return;
        }
        fetchProposals(event.target.value);
    }

    // function downshiftOnChange(selectedMovie) {
    //     console.log(`your favourite movie is ${selectedMovie.id}`);
    // }

    // function stateReducer(state, changes) {
    //     console.log(changes);
    //     switch (changes.type) {
    //         case Downshift.stateChangeTypes.keyDownEnter:
    //         case Downshift.stateChangeTypes.clickItem:
    //         default:
    //             return changes
    //     }
    // }

    const downshiftComponent = <div>
        <Downshift
            onChange={props.internalDownshiftOnChange}
            stateReducer={props.downshiftStateReducer}
            //initialSelectedItem={props.initiallySelectedItem}
            selectedItem={props.selectedItem != null ? props.selectedItem : null}
            //inputValue={props.selectedItem != null ? props.selectedItem.label : ""}
            itemToString={item => (item ? item.label : "")}>
            {({
                  selectedItem,
                  getInputProps,
                  getItemProps,
                  getRootProps,
                  highlightedIndex,
                  isOpen,
                  inputValue,
                  getLabelProps
              }) => (
                <div style={{/*border: "1px dashed blue",*/ position: "relative"}}>
                    <input {...getInputProps({placeholder: props.placeholder, onChange: inputOnChange, type: "text", className: "form-control"})}/>
                    {isOpen ? (
                        <div className="downshift-dropdown autocomplete-suggestions" style={{position: "absolute", /*border: "1px solid red",*/ width: "100%", zIndex: "99"}}>
                            {loading ? <div style={{margin: "10px"}}><UboldSpinner/></div> : null}
                            {!loading && proposals
                                .filter(item => !inputValue || item.label.toLowerCase().includes(inputValue.toLowerCase()))
                                .slice(0, 10)
                                .map((item, index) => (
                                    <div
                                        className="dropdown-item"
                                        {...getItemProps({key: index, index, item})}
                                        style={{
                                            backgroundColor: highlightedIndex === index ? "#f6f6f6" : "white",
                                            fontWeight: selectedItem != null && selectedItem.id === item.id ? "bold" : "normal",
                                            cursor: "pointer"
                                        }}>
                                        {item.label}
                                    </div>
                                ))}
                        </div>
                    ) : null}

                </div>
            )}
        </Downshift>
    </div>;

    return <div className="row mt-2">
        <label className="col-4 col-form-label">{props.label}</label>
        <div className={props.rightColumnClasses ||"col-8"}>
            {downshiftComponent}
            <UboldInvalidFeedbackList errors={props.errors}/>
        </div>
        {props.childrenToTheRight}
    </div>;
}

UboldDownshift.propTypes = {}

UboldDownshift.defaultProps = {}