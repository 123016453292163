import React from "react";
import {ManyOfEditor} from "../editors/ManyOfEditor";

export function MetronicMultiButtonManyOfEditor(props: { editor: ManyOfEditor }) {
    return <div className={"d-inline-flex gap-1"}>
        {props.editor.options.map(opt => <button key={opt.key}  className={"btn btn-sm " + (props.editor.value.includes(opt.key) ? "btn-primary" : "btn-secondary")} onClick={() => props.editor.toggle(opt.key)}>{opt.label}</button>)}
    </div>
}

export function MetronicMultiButtonManyOfEditorXxs(props: { editor: ManyOfEditor }) {
    return <div className={"d-inline-flex gap-2 flex-wrap pt-1"}>
        {props.editor.options.map(opt => <button key={opt.key}  className={"btn btn-xxs " + (props.editor.value.includes(opt.key) ? "btn-primary" : "btn-secondary")} onClick={() => props.editor.toggle(opt.key)}>{opt.label}</button>)}
    </div>
}