import React, {useCallback} from "react";
import {MeasureEditor} from "form/editors/MeasureEditor";

export function MetronicMeasureEditor(props: { editor: MeasureEditor }) {
    const propagateNumericValueChangeToEditor = useCallback((event: React.FormEvent<HTMLInputElement>) => {
        props.editor.updateNumericValueText(event.currentTarget.value);
    }, [props.editor]);

    const propagateUnitChangeToEditor = useCallback((event: React.FormEvent<HTMLSelectElement>) => {
        props.editor.populateUnitSelection(event.currentTarget.value);
    }, [props.editor]);

    return <>

        <div style={{display: "flex", gap: "10px"}}>
            <div style={{display: "inline-block", flexGrow: "5"}}>
                <input type="text"
                       className="form-control form-control fs-7 p-2"
                       value={props.editor.currentTextInSelectedUnit}
                       onChange={propagateNumericValueChangeToEditor}
                       onBlur={props.editor.commit}
                       style={{width: "100%"}}
                       disabled={props.editor.numericEditorReadOnly}
                />
            </div>
            <div style={{display: "inline-block", width: "70px"}}>
                <select className="form-control form-control-solid fs-7 p-2 text-center"
                        value={props.editor.selectedUnitKey}
                        onChange={propagateUnitChangeToEditor}
                        style={{}}>
                    {props.editor.unitSelectionOptions.map(opt => <option value={opt.key} key={opt.key}>{opt.label}</option>)}
                </select>
            </div>
        </div>

        {props.editor.unparseableTextPresent ? <strong className={"text-danger"}>X</strong> : null}
    </>;

}

export function MetronicMeasureEditorDummyWithButton(props: {
    fieldText: string,
    buttonLabel: string,
    onButtonClick: () => void,
    unitDummyText?: string
}) {
    return <>
        <div style={{display: "flex", gap: "10px"}}>
            <div style={{display: "inline-block", flexGrow: "5"}}>
                <input type="text"
                       className="form-control form-control fs-7 p-2"
                       value={props.fieldText}
                       disabled={true}/>
            </div>
            <div style={{display: "inline-block", width: "70px"}}>
                <select className="form-control form-control form-control-solid fs-7 p-2" disabled={true}>
                    <option value="">{props.unitDummyText ?? "-"}</option>
                </select>
            </div>
            <button className={"btn btn-xxs btn-outline btn-outline-success "} onClick={props.onButtonClick}>{props.buttonLabel}</button>
        </div>
    </>;
}