import React, {useState} from 'react';
import UBoldModal from "../../ubold-components/UBoldModal";
import DynamicWebServiceDrivenForm from "../../platform/webform/DynamicWebServiceDrivenForm";
import {createInternationalizationPrefixer} from "../i18n/i18n";
import {Card} from "../../components/Card";
import {useNavigate} from "react-router";


const t = createInternationalizationPrefixer("AddRolePage");



export const FORMATTED_ROLE_INFO = <React.Fragment>
    <React.Fragment>
        <p>
            Po zdefiniowaniu roli będzie można przypisywać rolę (lub wiele ról) użytkownikom.
        </p>
        <p>
            Rola posiada przypisany zestaw przywilejów, który będzie przyznany użytkownikowi, jeżeli użytkownik będzie miał
            nadaną tę rolę.
        </p>
        <p>
            Zaleca się definiować role w taki sposób, by odzwierciedlić funkcje pełnione w organizacji.
        </p>
        <p>
            Dobrą praktyką jest unikanie tworzenia ról dedykowanych przywilejowi tzn. ról z wyłącznie jednym przywilejem o nazwie
            bardzo podobnie brzmiącej jak ten przywilej, gdyż niweluje to wartość z zarządzania dostępem opartego o role i sprowadza się
            niemalże do tego samego co przypisywanie pojedynczych przywilejów użytkownikom, co jest ciężkie w utrzymaniu na dłuższą metę.
        </p>
    </React.Fragment>
</React.Fragment>;

export default function AddRolePage(props) {

    const [showSuccess, setShowSuccess] = useState(false);
    const navigate = useNavigate();

    return <React.Fragment>
        <UBoldModal header={t('AddRolePage:Sukces')} opened={showSuccess} closeable={false}>
            <div className="text-center">
                <div className={"h3 mb-3"}>{t('Rola została dodana')}</div>
                <button className="btn btn-success" onClick={() => navigate('/roles')}>{t('OK')}</button>
            </div>
        </UBoldModal>
        <div className="row justify-content-center">
            <div className="col-xl-9 col-lg-9 col-md-12 col-sm-12">
                <Card header={t('Stwórz nową rolę')}>
                    <DynamicWebServiceDrivenForm dslUrl="/api/role/form"
                                                 submitLabel={"Utwórz"}
                                                 submissionUrl="/api/role/create"
                                                 successHandler={() => setShowSuccess(true)}/>
                </Card>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12">
                <div className="card">
                    <div className="card-body">
                        <h4 className="card-title text-success"><i className="fe-info fe-xl"></i> {t('Informacje')}</h4>
                        {FORMATTED_ROLE_INFO}
                    </div>
                </div>
            </div>
        </div>

    </React.Fragment>;
}

AddRolePage.propTypes = {}

AddRolePage.defaultProps = {}