import React from 'react';
import {Card} from "../../components/Card";

export default function StandardAdminBrowserLayout(props) {


    return <Card header={props.header ?? "Przeglądaj"}>
        {props.criteriaComponent}
        {props.resultsComponent}
        {props.paginationComponent}
    </Card>
}

StandardAdminBrowserLayout.propTypes = {

}

StandardAdminBrowserLayout.defaultProps = {

}