import AjaxService from "../../platform/ajax/AjaxService";

export default class MechanicalVerificationService {

    static performVerificationAsync(mechanicalVerificationRequest) {
        return new Promise((resolve, reject) => {
            AjaxService.postJson("/api/mechanical/solve", mechanicalVerificationRequest)
                .then(response => {
                    if ( !response.ok ) {
                        reject();
                        return;
                    }
                    resolve(response.json);
                });
        });
    }

    static getAvailableProfiles() {
        return new Promise((resolve, reject) => {
            AjaxService.getJson("/api/mechanical/available-profiles")
                .then(response => {
                    if ( !response.ok ) {
                        reject();
                        return;
                    }
                    resolve(response.json);
                });
        });
    }
}