import React from 'react';
import DynamicWebServiceDrivenForm from "../../platform/webform/DynamicWebServiceDrivenForm";
import {Card} from "../../components/Card";

export function MainLayoutWebFormPage(props) {
    return <Card header={props.title}>
        <DynamicWebServiceDrivenForm
            submitLabel={props.submitLabel}
            dslUrl={props.dslUrl || "/api/webform/" + props.webFormContext + "?" + props.webFormContext + (new URL(document.location)).searchParams}
            submissionUrl={props.submissionUrl || "/api/webform/" + props.webFormContext + "?" + (new URL(document.location)).searchParams}
            successHandler={props.successHandler}/>
    </Card>;

}
