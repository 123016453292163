import {
    CELCIUS_BASE_UNIT,
    CUBIC_FOOT_PER_HOUR_ON_CUBIC_METER_PER_SECOND,
    CUBIC_METER_PER_HOUR_ON_CUBIC_METER_PER_SECOND,
    CUBIC_METER_PER_SECOND_BASE_UNIT,
    DEGREE_BASE_UNIT,
    FARENHEIT_ON_CELCIUS,
    FEET_UNIT_ON_METER_BASE,
    FOOT_UNIT_ON_MILLIMETER_BASE,
    HECTO_PASCAL_BASE_UNIT,
    HECTO_PASCAL_UNIT_ON_PASCAL_BASE,
    INCH_UNIT_ON_METER_BASE,
    KELVIN_ON_CELCIUS,
    KILO_PASCAL_UNIT_ON_HECTO_PASCAL_BASE,
    KILO_PASCAL_UNIT_ON_PASCAL_BASE,
    KILOGRAM_PER_CUBIC_METER_BASE_UNIT,
    KILOMETER_PER_HOUR_ON_METER_PER_SECOND,
    METER_BASE_UNIT,
    METER_PER_SECOND_BASE_UNIT,
    METER_UNIT_ON_MILLIMETER_BASE,
    MILIMETER_UNIT_ON_METER_BASE,
    MILLIMETER_BASE_UNIT,
    MMH2O_ON_PASCAL,
    PASCAL_BASE_UNIT,
    PERCENT_BASE_UNIT,
    POUNDS_PER_CUBIC_FEETON_KILOGRAM_PER_CUBIC_METER_BASE_UNIT,
    PSI_ON_PASCAL,
    ROTATION_PER_MINUTE_BASE_UNIT,
    UNMEASURABLE_BASE_UNIT
} from "business/measurement/WentechMeasurementUnits";
import {MeasurementStandard} from "../../platform/measurement/MeasurementStandard";

/**
 * Warning about baseUnitConstraint
 * This is business-requirement impossible to achieve exact base-unit constraint together with rules
 * of fixed decimal places formatting and unit conversion. Thus, constraint specified in base unit
 * is only applied to base unit, where constraints for custom units will be re-parsed thus potentially
 * lower-than-min or larger-than-max value may be achievable in custom unit - all depends on information
 * being lost by custom unit additional decimal places. With correct configuration of decimal places
 * there should be 1:1 aligment to base unit, or extemally small extension to the allowed range.
 * But with incorrect decimal places it may grow. E.g. allowing 300-900 millimeter, but creating a
 * formatter/parser to metter with no decimal places (so 0.3 or 0.9m cannot be formatted), the logic
 * in measuring edtior may actually allow values of 0 and 1 because meter's minimum will be format(millimeter minimum)
 * and meter's maximum will be format(millimeter maximum)
 */
export const WentechMeasuringStandards = {
    IMPELLER_PRESSURE: {
        baseUnitConstraint: {min: 1, max: 4000},
        defaultValueInBaseUnit: 123,
        measuringOptions: {
            baseUnit: PASCAL_BASE_UNIT,
            customUnits: [KILO_PASCAL_UNIT_ON_PASCAL_BASE, HECTO_PASCAL_UNIT_ON_PASCAL_BASE, MMH2O_ON_PASCAL, PSI_ON_PASCAL]
        },
        baseDecimalPlaces: 1
    },
    IMPELLER_DIAMETER: {
        baseUnitConstraint: {min: 0, max: 20000},
        defaultValueInBaseUnit: 4877,
        measuringOptions: {
            baseUnit: MILLIMETER_BASE_UNIT,
            customUnits: [METER_UNIT_ON_MILLIMETER_BASE, FOOT_UNIT_ON_MILLIMETER_BASE]
        },
        baseDecimalPlaces: 0
    } as MeasurementStandard,
    HOUSING_DIAMETER: {
        baseUnitConstraint: {min: 0, max: 20000},
        defaultValueInBaseUnit: 4877,
        measuringOptions: {
            baseUnit: MILLIMETER_BASE_UNIT,
            customUnits: [METER_UNIT_ON_MILLIMETER_BASE, FOOT_UNIT_ON_MILLIMETER_BASE]
        },
        baseDecimalPlaces: 0
    } as MeasurementStandard,
    BLADE_TIP_CLEARANCE: {
        baseUnitConstraint: {min: 0, max: 50}, // Due to how diameter editor work, we need to constraint it programatically in high level composite editor, not on single editor, thus full range allowed here
        defaultValueInBaseUnit: 0.5,
        measuringOptions: {
            baseUnit: PERCENT_BASE_UNIT,
            customUnits: []
        },
        baseDecimalPlaces: 2
    } as MeasurementStandard,
    DIFFUSER_HEIGHT: {
        baseUnitConstraint: {min: 0, max: 5},
        defaultValueInBaseUnit: 0,
        measuringOptions: {
            baseUnit: METER_BASE_UNIT,
            customUnits: [FEET_UNIT_ON_METER_BASE, MILIMETER_UNIT_ON_METER_BASE, INCH_UNIT_ON_METER_BASE]
        },
        baseDecimalPlaces: 3
    } as MeasurementStandard,
    DIFFUSER_ANGLE: {
        baseUnitConstraint: {min: 6, max: 8.5}, // TODO what actual limits
        defaultValueInBaseUnit: 7.5,
        measuringOptions: {
            baseUnit: DEGREE_BASE_UNIT,
            customUnits: []
        },
        baseDecimalPlaces: 3
    } as MeasurementStandard,
    WIND_SPEED: {
        baseUnitConstraint: {min: 0, max: 10},
        defaultValueInBaseUnit: 0,
        measuringOptions: {
            baseUnit: METER_PER_SECOND_BASE_UNIT,
            customUnits: [KILOMETER_PER_HOUR_ON_METER_PER_SECOND]
        },
        baseDecimalPlaces: 1
    } as MeasurementStandard,
    AIR_DENSITY: {
        baseUnitConstraint: {min: 0.6, max: 1.5},
        defaultValueInBaseUnit: 1.155,
        measuringOptions: {
            baseUnit: KILOGRAM_PER_CUBIC_METER_BASE_UNIT,
            customUnits: [POUNDS_PER_CUBIC_FEETON_KILOGRAM_PER_CUBIC_METER_BASE_UNIT]
        },
        baseDecimalPlaces: 3
    } as MeasurementStandard,
    AIR_TEMPERATURE: {
        baseUnitConstraint: {min: -60, max: 600},
        defaultValueInBaseUnit: 35,
        measuringOptions: {
            baseUnit: CELCIUS_BASE_UNIT,
            customUnits: [FARENHEIT_ON_CELCIUS, KELVIN_ON_CELCIUS]
        },
        baseDecimalPlaces: 1
    } as MeasurementStandard,
    AIR_FLOW: {
        baseUnitConstraint: {min: 0, max: 1000},
        defaultValueInBaseUnit: 234.0,
        measuringOptions: {
            baseUnit: CUBIC_METER_PER_SECOND_BASE_UNIT,
            customUnits: [CUBIC_METER_PER_HOUR_ON_CUBIC_METER_PER_SECOND, CUBIC_FOOT_PER_HOUR_ON_CUBIC_METER_PER_SECOND]
        },
        baseDecimalPlaces: 1
    } as MeasurementStandard,
    ROTATIONAL_SPEED: {
        baseUnitConstraint: {min: 0, max: 5000},
        defaultValueInBaseUnit: 202,
        measuringOptions: {
            baseUnit: ROTATION_PER_MINUTE_BASE_UNIT,
            customUnits: []
        },
        baseDecimalPlaces: 1
    } as MeasurementStandard,
    BLADE_END_SPEED: {
        baseUnitConstraint: {min: 0, max: 200},
        defaultValueInBaseUnit: 30,
        measuringOptions: {
            baseUnit: METER_PER_SECOND_BASE_UNIT,
            customUnits: []
        },
        baseDecimalPlaces: 1
    } as MeasurementStandard,
    BLADE_ANGLE: {
        baseUnitConstraint: {min: 2, max: 40}, // TODO what actual limits
        defaultValueInBaseUnit: 20,
        measuringOptions: {
            baseUnit: DEGREE_BASE_UNIT,
            customUnits: []
        },
        baseDecimalPlaces: 1
    } as MeasurementStandard,
    SHAFT_DIAMETER: {
        baseUnitConstraint: {min: 0, max: 200},
        defaultValueInBaseUnit: 38,
        measuringOptions: {
            baseUnit: MILLIMETER_BASE_UNIT,
            customUnits: []
        },
        baseDecimalPlaces: 0
    } as MeasurementStandard,
    SHAFT_LENGTH: {
        baseUnitConstraint: {min: 0, max: 1000},
        defaultValueInBaseUnit: 38,
        measuringOptions: {
            baseUnit: MILLIMETER_BASE_UNIT,
            customUnits: []
        },
        baseDecimalPlaces: 0
    } as MeasurementStandard,
    IMPELLER_HEIGHT: {
        baseUnitConstraint: {min: 0, max: 1000},
        defaultValueInBaseUnit: 100,
        measuringOptions: {
            baseUnit: MILLIMETER_BASE_UNIT,
            customUnits: []
        },
        baseDecimalPlaces: 0
    } as MeasurementStandard,
    AREA_RATIO: {
        baseUnitConstraint: {min: 0, max: 0.3},
        defaultValueInBaseUnit: 0.1,
        measuringOptions: {
            baseUnit: UNMEASURABLE_BASE_UNIT,
            customUnits: []
        },
        baseDecimalPlaces: 2
    } as MeasurementStandard,
    DISTANCE_RATIO: {
        baseUnitConstraint: {min: 0.05, max: 1},
        defaultValueInBaseUnit: 0.15,
        measuringOptions: {
            baseUnit: UNMEASURABLE_BASE_UNIT,
            customUnits: []
        },
        baseDecimalPlaces: 2
    } as MeasurementStandard,

    AIR_HUMIDITY: {
        baseUnitConstraint: {min: 0, max: 100},
        defaultValueInBaseUnit: 70,
        measuringOptions: {
            baseUnit: PERCENT_BASE_UNIT,
            customUnits: []
        },
        baseDecimalPlaces: 0
    },
    BAROMETRIC_PRESSURE: {
        baseUnitConstraint: {min: 750, max: 1250},
        defaultValueInBaseUnit: 1013,
        measuringOptions: {
            baseUnit: HECTO_PASCAL_BASE_UNIT,
            customUnits: [KILO_PASCAL_UNIT_ON_HECTO_PASCAL_BASE]
        },
        baseDecimalPlaces: 0
    },
    HEIGHT_ABOVE_SEA_LEVEL: {
        baseUnitConstraint: {min:0, max:9000},
        defaultValueInBaseUnit: 258,
        measuringOptions: {
            baseUnit: METER_BASE_UNIT,
            customUnits: []
        },
        baseDecimalPlaces: 0
    }
}