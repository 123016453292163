import {useOptionalMeasurementEditor, useOptionalOneOfEditor} from "../pages/selection/useFanCriteria";
import {WentechMeasuringStandards} from "../business/measurement/WentechMeasurementStandards";
import {useEditor} from "../form/EditorReact";
import {OneOfEditor} from "../form/editors/OneOfEditor";
import {MetronicMultiButtonOneOfEditorXxs} from "../form/metronic/MetronicMultiButtonOneOfEditor";
import {createInternationalizationPrefixer} from "../platform/i18n/i18n";
import {SimulationRequestJson} from "../business/ApiJsonTypes";
import React, {useEffect} from "react";
import {MechanicalConfigurator} from "./MechanicalConfigurator";
import {useObjectFromUrlResourceOrNull} from "../platform/hooks/PlatformHooks";
import {ShaftLengthBonusElements} from "./ShaftLengthBonusElements";

// Note prefix is reused with criteria due to duplication and to avoid duplicating language keys
const t = createInternationalizationPrefixer("FanSelectionCriteriaControls");


export function useMechanicalConfiguratorCriteriaControls(availableShaftDiameters: number[], predefinedCriteria: SimulationRequestJson) {

    const shaftDiameterEditor = useOptionalOneOfEditor(availableShaftDiameters.map(asd => ({key: "" + asd, label: asd + " mm"})), "" + availableShaftDiameters[0], false);
    console.log('shaftDiameterEditor.value', shaftDiameterEditor.value);

    const shaftLengthEditor = useOptionalMeasurementEditor(WentechMeasuringStandards.SHAFT_LENGTH, false, (editor) => <ShaftLengthBonusElements editor={editor} shaftDiameterEditor={shaftDiameterEditor}/>);


    const impellerHeightEditor = useOptionalMeasurementEditor(WentechMeasuringStandards.IMPELLER_HEIGHT, false);
    const fanMountingOrientationEditor = useEditor<OneOfEditor>(() => new OneOfEditor([
        {key: "HORIZONTAL", label: t("Poziomo  (wał pionowy)")},
        {key: "VERTICAL", label: t("Pionowo (wał poziomy)")},
    ], MetronicMultiButtonOneOfEditorXxs));
    const airflowRelativeClutchMountingSideEditor = useEditor<OneOfEditor>(() => new OneOfEditor([
        {key: "CLUTCH_ON_INLET", label: t("Sprzęgło na wlocie")},
        {key: "CLUTCH_ON_OUTLET", label: t("Sprzęgło na wylocie")},
    ], MetronicMultiButtonOneOfEditorXxs));
    const supportAndClutchDiscSideEditor = useEditor<OneOfEditor>(() => new OneOfEditor([
        {key: "SUPPORTS_AND_CLUTCH_OPPOSITE_SIDES", label: t("Przeciwne strony")},
        {key: "SUPPORTS_AND_CLUTCH_SAME_SIDE", label: t("Ta sama strona")},
    ], MetronicMultiButtonOneOfEditorXxs));
    const realizeWithClutchEditor = useEditor<OneOfEditor>(() => new OneOfEditor([
        {key: "true", label: t("Ze sprzęgłem")},
        {key: "false", label: t("Bez sprzęgła")},
    ], MetronicMultiButtonOneOfEditorXxs));

    useEffect(function preopopulate() {
        const mechanicalPrefs = predefinedCriteria.mechanicalPreferences;
        if (mechanicalPrefs.shaftDiameter != null) {
            shaftDiameterEditor.populate(mechanicalPrefs.shaftDiameter.toString())
            shaftDiameterEditor.enable();
        }
        if (mechanicalPrefs.shaftLength != null) {
            shaftLengthEditor.populate(mechanicalPrefs.shaftLength)
            shaftLengthEditor.enable();
        }
        if (mechanicalPrefs.expectedImpellerHeight != null) {
            impellerHeightEditor.populate(mechanicalPrefs.expectedImpellerHeight)
            impellerHeightEditor.enable();
        }
        mechanicalPrefs.fanMountingOrientationCode != null && fanMountingOrientationEditor.populate(mechanicalPrefs.fanMountingOrientationCode);
        mechanicalPrefs.airflowRelativeClutchMountingSideCode != null && airflowRelativeClutchMountingSideEditor.populate(mechanicalPrefs.airflowRelativeClutchMountingSideCode);
        mechanicalPrefs.preferredSupportAndClutchDiscSideCode != null && supportAndClutchDiscSideEditor.populate(mechanicalPrefs.preferredSupportAndClutchDiscSideCode);
        predefinedCriteria.realizeWithClutch != null && realizeWithClutchEditor.populate(predefinedCriteria.realizeWithClutch ? "true" : "false");
    }, []); // Expected to be run only once when conifgurator is instantiated

    const currentlyConfiguredSimulationRequestJson: SimulationRequestJson = {
        demoAirFlow: predefinedCriteria!.demoAirFlow,
        fanFlowSpecification: predefinedCriteria!.fanFlowSpecification,
        installationSpecification: predefinedCriteria!.installationSpecification,
        manufacturingSpecification: predefinedCriteria!.manufacturingSpecification,
        mechanicalPreferences: {
            airflowRelativeClutchMountingSideCode: airflowRelativeClutchMountingSideEditor.value,
            expectedImpellerHeight: impellerHeightEditor.value,
            fanMountingOrientationCode: fanMountingOrientationEditor.value,
            preferredSupportAndClutchDiscSideCode: supportAndClutchDiscSideEditor.value,
            shaftDiameter: parseInt(shaftDiameterEditor.value!),
            shaftLength: shaftLengthEditor.value

        },
        softStartPresent: predefinedCriteria!.softStartPresent,
        realizeWithClutch: predefinedCriteria!.realizeWithClutch
    };


    const configuratorComponent = <MechanicalConfigurator predefinedCriteria={currentlyConfiguredSimulationRequestJson}
                                                          supportAndClutchDiscSideEditor={supportAndClutchDiscSideEditor}
                                                          airflowRelativeClutchMountingSideEditor={airflowRelativeClutchMountingSideEditor}
                                                          fanMountingOrientationEditor={fanMountingOrientationEditor}
                                                          shaftDiameterEditor={shaftDiameterEditor}
                                                          shaftLengthEditor={shaftLengthEditor}
                                                          impellerHeightEditor={impellerHeightEditor}
                                                          availableShaftDiameters={availableShaftDiameters}
                                                          currentlyConfiguredSimulationRequestJson={currentlyConfiguredSimulationRequestJson}/>;


    return {
        shaftDiameterEditor,
        shaftLengthEditor,
        impellerHeightEditor,
        fanMountingOrientationEditor,
        airflowRelativeClutchMountingSideEditor,
        supportAndClutchDiscSideEditor,
        currentlyConfiguredSimulationRequestJson,
        configuratorComponent
    }
}