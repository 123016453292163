export interface Editor<VAL> {

    readonly value: VAL;

    /**
     * Populate with value, end ensures that value is commited (no scheduled/pending change - should be instant)
     */
    populate(value: VAL): void;

    reset(): void;

    /**
     * Finishes any "pending" changes like debounces, unfinished edits etc.
     * and makes sure latest state is propagated to value
     * TODO: In future consider commit to return aggreement to commit or promise that client should wait for etc.
     */
    commit(): void;

    addChangeListener(listener: EditorChangeListener): void; // TODO rething. added especially for generic useEditor hook

    Component(): JSX.Element;

    readonly errorList: string[]

    readonly isValid: boolean
}

export abstract class BaseEditor<VAL> implements Editor<VAL> {

    readonly listeners: EditorChangeListener[] = [];

    addChangeListener = (listener: EditorChangeListener): void => {
        this.listeners.push(listener);
    }

    notifyListeners = (msg?:string) => {
        if ( msg == undefined ) {
            this.listeners.forEach(l => l());
        } else {
            this.listeners.forEach(l => l(msg));
        }
    }

    get isValid(): boolean {
        return this.errorList.length === 0;
    }

    abstract get errorList(): string[];

    abstract commit(): void;

    abstract populate(value: VAL): void;

    abstract reset(): void;

    abstract Component(): JSX.Element;

    abstract get value(): VAL;
}

export type EditorChangeListener = (msg?:string) => void;


