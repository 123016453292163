import React, {useCallback} from 'react';
import Dropzone from 'react-dropzone-uploader'
import 'react-dropzone-uploader/dist/styles.css'
import './dropzone-styles-customizer.css'
import {cloneJson} from "../util/JsonObjectCloner";
import UboldInvalidFeedbackList from "../../ubold-components/UboldInvalidFeedbackList";
import {getDatatransferFixed, getFilesFromEventFixed} from "./BasicDropzoneImageUploadWebFormComponent";

export const statusTranslations = {
    'rejected_file_type': 'Unsupported file type',
    'rejected_max_files': 'Too many files',
    'preparing': 'Preparing...',
    'error_file_size': 'Bad file size',
    'error_validation': 'File did not pass validation',
    'ready': 'Ready',
    'started': 'Upload started',
    'getting_upload_params': 'Getting upload params',
    'error_upload_params': 'Incorrect upload params',
    'uploading': 'Uploading...',
    'exception_upload': 'Error during upload',
    'aborted': 'Aborted',
    'restarted': 'Restarted',
    'removed': 'Removed',
    'error_upload': 'Processing error',
    'headers_received': 'Headers received',
    'done': 'Done'
};

export const removeFileFromDslValueById = (e, sourceDsl, hostedFileId, dslChangeConsumer) => {
    e.preventDefault();
    const newDsl = cloneJson(sourceDsl);
    let idx;
    for (let i in sourceDsl.value) {
        if (sourceDsl.value[i].id === hostedFileId) {
            idx = i;
        }
    }
    newDsl.value.splice(idx, 1);
    newDsl.errors=[];
    dslChangeConsumer(newDsl);
};

export const SimpleFilenamePresenterForValueFile = (props) => {
    return <div className="row mt-2 mb-2 ">
        <div className="col-1 align-items-center">
            <button className="btn btn-xs m-1 p-1 " onClick={(e) => removeFileFromDslValueById(e, props.dsl, props.fileValue.id, props.onDslChange)}><i className="fas fa-times-circle text-danger"></i></button>
        </div>
        <div className="col-11 p-1">
            <b><i className="fe-file text-info fs-2"></i>&nbsp;{props.fileValue.originalFilename}</b>
        </div>
    </div>
}


// Dropzone's preview is used only to preview PENDING/ERROR files! actual "dsl value" files are handled separately
// Dropzone is used only to SEND FILES (and pending preview), it is not used to maintain current value presentation (e.g. queued for submission file list)
export const PreviewForPendingFileWithUboldProgressBar = (q) => {
    const removeHandler = q.fileWithMeta.remove;

    let meta = q.meta;
    const {name, percent, status} = meta
    if (status === 'done') {
        return null;
    }

    const progressBar = <div className="col-12">
        <div className="progress mb-2">
            <div className="progress-bar progress-bar-striped" role="progressbar" style={{width: Math.round(percent) + "%"}}></div>
        </div>
    </div>;

    return (
        <div className="row mt-2 mb-2">
            <div className="col-8 text-left">
                <b><i className="fe-file text-info h3"></i>&nbsp;{name}</b> <span className={"badge text-muted"}>{(Math.round(percent) >= 100 ? null : Math.round(percent) + " %")}</span>
            </div>
            <div className="col-3 text-center mt-2">
                <span className={status === 'done' ? 'text-success' : 'text-warning'}>{statusTranslations[status]}</span> &nbsp;
            </div>
            <div className="col-1 text-right">
                <button className="btn btn-xs  btn-link" onClick={removeHandler}><i className="fas fa-times-circle text-danger"></i></button>
            </div>
            {(Math.round(percent) >= 100 ? null : progressBar)}
        </div>
    )
};

export const processStatusChange = (fileWithMeta, status, filesWithMeta, sourceDsl, onDslChange) => {
    let uploadedFileValues = [];
    console.log("Process status change", filesWithMeta);
    for (const f of filesWithMeta) {
        if (f.xhr && f.xhr.readyState === 4 && f.meta.status === 'done') {
            const fileResponse = JSON.parse(f.xhr.response);
            if (fileResponse.id) {
                // For each completed file - immediately remove it from dropzone context (because it would be repeated when uploading next files)
                // and move it to temporary variable that will be pumped into form dsla value [] array (outside of Dropzone control)
                uploadedFileValues.push(fileResponse);
                f.remove();
            } else {
                //TECHDEBT console.error("Ignoring file submission as upload response do not contain valid uid", f.xhr);
            }
        } else {
            //TECHDEBT console.error("Ignoring file submission as upload request ready state not 4 (not ok) or meta status not 'done'", f.xhr);
        }
    }
    // Becasue lot of events may be triggered from Dropzone uploader, and it's not in-sync with React lifecycle, we cannot use sourceDsl to build new dsl!
    // We need to update dsl by function based on old state
    // Fixed defect of persisted internal dropzone files resulting in inability to replace image uploaded once or recovering removed uploaded files on every new file upload (because they were cached in dropzone)
    onDslChange(x => {
        const newDsl = cloneJson(sourceDsl)

        for (const ufv of uploadedFileValues) {
            newDsl.value.push(ufv);
        }
        newDsl.errors = [];
        return newDsl;
    });

}

export default function BasicDropzoneUploadWebFormComponent(props) {

    const getUploadParams = ({file, meta}) => {
        return {
            mathod: 'post',
            url: '/api/upload/create',
            headers: {"Accept": "application/json"}
        }
    };

    const dropFilesHere = <div className="text-center mt-3 mb-2">
        <i className="fas fa-file-upload fa-2x m-3"></i>
        <div className="fs-3">{props.dsl.configuration.uploadLabel}</div>
    </div>;

    // Input customization is done mostly because original <label> thrown warning about no key=
    const Input = ({accept, onFiles, files, getFilesFromEvent}) => {
        return (
            <label style={{cursor: 'pointer'}}>
                <input
                    style={{display: 'none'}}
                    type="file"
                    accept={accept}
                    multiple={props.dsl.configuration.maxFileCount < 2 ? null : true}
                    onChange={async e => {
                        const target = e.target
                        const chosenFiles = await getFilesFromEvent(e)
                        onFiles(chosenFiles)
                        target.value = null
                    }}
                />
                {dropFilesHere}
            </label>
        )
    };

    const Lay = useCallback(({input, previews, submitButton, dropzoneProps, files, extra}) => {
        const maxFiles = extra.maxFiles;
        const dsl = props.dsl;
        return (
            <div>
                <div key={"previews"}>
                    {previews}
                </div>
                {dsl.value.length < maxFiles &&
                [
                    <div key={"input"} {...dropzoneProps}>
                        {dsl.value.length < maxFiles && input}
                    </div>,
                    <div key={"unused-submit"}>
                        {dsl.value.length > 0 && submitButton}
                    </div>
                ]}
            </div>
        )
    }, [props]);

    return <div className="row mb-6">
        <div className="col-4">
        </div>
        <div className="col-12 col-md-8 col-xl-6">
            <div className="separator separator-content mb-6 mt-6 text-nowrap"><strong>{props.dsl.configuration.label}</strong></div>

            {(props.dsl.value.map(v => <SimpleFilenamePresenterForValueFile key={v.id} fileValue={v} dsl={props.dsl} onDslChange={props.onDslChange}/>))}
            <Dropzone accept={"*"}
                      getFilesFromEvent={getFilesFromEventFixed}
                      getDataTransferItemsFromEvent={getDatatransferFixed}
                      multiple={true}
                      autoUpload={true}
                      disabled={false}
                      canCancel={true}
                      canRemove={true}
                      getUploadParams={getUploadParams}
                      canRestart={false}
                      inputContent={dropFilesHere}
                      inputWithFilesContent={dropFilesHere}
                      submitButtonDisabled={true}
                      submitButtonContent={<span></span>}
                      LayoutComponent={Lay}
                      InputComponent={Input}
                      SubmitButtonComponent={null}
                      PreviewComponent={PreviewForPendingFileWithUboldProgressBar}
                      onChangeStatus={(fileWithMeta, status, filesWithMeta) => processStatusChange(fileWithMeta, status, filesWithMeta, props.dsl, props.onDslChange)}/>
            <UboldInvalidFeedbackList errors={props.dsl.errors}/>
        </div>
    </div>;
}

BasicDropzoneUploadWebFormComponent.propTypes = {}

BasicDropzoneUploadWebFormComponent.defaultProps = {}