import React, {RefObject, useEffect, useRef} from 'react';
import {D3Chart, D3ChartMargins, D3ChartRenderable, SvgInHtml} from "./D3Chart";

interface D3ChartComponentProps {
    width: number,
    height: number,
    chartRenderables: D3ChartRenderable[],
    margins: D3ChartMargins
}

export function D3ChartComponent(props: D3ChartComponentProps) {

    const chartRef = useRef<D3Chart | null>(null);
    const svgRef = React.useRef<SvgInHtml>();

    useEffect(() => {
        if (svgRef.current === undefined) {
            return;
        }
        chartRef.current = D3Chart.createOnSvgInHtml(
            svgRef.current,
            props.chartRenderables,
            props.margins,
            {innerHeight: props.height - props.margins.top - props.margins.bottom, innerWidth: props.width - props.margins.left - props.margins.right});

        chartRef.current.initialize();
        chartRef.current.render();
    }, []);

    return <svg
        ref={svgRef as unknown as RefObject<SVGSVGElement>}
        style={{
            width: props.width,
            height: props.height,
            margin: "0 0 0 0"
        }}>
    </svg>;
}

D3ChartComponent.propTypes = {}

D3ChartComponent.defaultProps = {}
