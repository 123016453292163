import {CriteriaCard, LabeledEditor} from "../pages/selection/useFanCriteria";
import React from "react";
import {SimulationRequestJson} from "../business/ApiJsonTypes";
import {createInternationalizationPrefixer} from "../platform/i18n/i18n";
import {OneOfEditor} from "../form/editors/OneOfEditor";
import {OptionalEditor} from "../form/editors/OptionalEditor";
import {MeasureEditorValue} from "../form/editors/MeasureEditor";

// Note prefix is reused with criteria due to duplication and to avoid duplicating language keys
const t = createInternationalizationPrefixer("FanSelectionCriteriaControls");


export function MechanicalConfigurator(props: {
    availableShaftDiameters: number[],
    predefinedCriteria: SimulationRequestJson, // Bit unexpected type, but it is used to generate datasheet also

    supportAndClutchDiscSideEditor: OneOfEditor;
    shaftDiameterEditor: OptionalEditor<string>;
    impellerHeightEditor: OptionalEditor<MeasureEditorValue>;
    currentlyConfiguredSimulationRequestJson: SimulationRequestJson;
    airflowRelativeClutchMountingSideEditor: OneOfEditor;
    shaftLengthEditor: OptionalEditor<MeasureEditorValue>;
    fanMountingOrientationEditor: OneOfEditor;
}) {


    return <CriteriaCard>
        {/*<LabeledEditor label={t("Wykonanie łopat")} editor={bladeManufacturingTypeEditor}/>*/}
        {/*<LabeledEditor label={t("Materiał konstrukcji")} editor={preferredConstructionMaterialEditor}/>*/}
        {/*<LabeledEditor label={t("Materiał części złącznych")} editor={preferredFastenersMaterialEditor}/>*/}

        <LabeledEditor label={t("Średnica wału")} editor={props.shaftDiameterEditor}/>
        <LabeledEditor label={t("Długość wału")} editor={props.shaftLengthEditor}/>
        <LabeledEditor label={t("Wysokość wirnika")} editor={props.impellerHeightEditor}/>

        <LabeledEditor label={t("Kierunek wirnika")} editor={props.fanMountingOrientationEditor}/>

        {/*<LabeledEditor label={t("Wykonanie ze sprzęgłem")} editor={realizeWithClutchEditor}/>*/}

        <LabeledEditor label={t("Pozycja sprzęgła")} editor={props.airflowRelativeClutchMountingSideEditor}/>
        <LabeledEditor label={t("Podpory i sprzęgło wzgl. tarczy")} editor={props.supportAndClutchDiscSideEditor}/>

        {/*<LabeledEditor label={t("Miękki start")} editor={softStartPresentEditor}/>*/}

    </CriteriaCard>

}