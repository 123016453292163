import React, {useState} from "react";
import {SelectionCriteriaVariant, useFanCriteria} from "./useFanCriteria";
import {useFetchAllObjs} from "../../platform/hooks/PlatformTypeScriptHooks";
import {PressureCorrector} from "../../business/ImpellerSimulationCriteria";
import {BladeProfileJson, useFetchAllBladeProfiles} from "../profile/BladeProfileService";
import {Material} from "../../business/mechanical/Material";
import {Card} from "../../components/Card";
import useFanSelection from "./SelectionService";
import UboldSpinner from "../../ubold-components/UboldSpinner";
import {SelectionMatchedFansChart} from "./prototype/SelectionMatchedFansChart";
import {AdjustableImpellerOverviewJson, useFetchImpellerOverviewsReport} from "../impeller/AdjustableImpellerService";
import {SelectionRequestJson} from "../../business/ApiJsonTypes";
import {FanSelectionResultBrowser} from "./FanSelectionResultBrowser";
import {toFanSelectionMatches} from "./toFanSelectionMatches";
import {createInternationalizationPrefixer} from "../../platform/i18n/i18n";


export function FanSelectionPage() {
    // TODO Remove code duplication
    const availableInletShapes = useFetchAllObjs<PressureCorrector[] | null>("/api/inlet-shapes");
    const availableProfiles = useFetchAllBladeProfiles();
    const availableMaterials = useFetchAllObjs<Material[]>("/api/available-materials");
    const availableShaftDiameters: number[] | null = useFetchAllObjs<number[]>("/api/mechanical/available-shaft-diameters/all");
    const adjustableImpellerOverviewsOrNull = useFetchImpellerOverviewsReport();

    if (availableInletShapes == null || availableProfiles === null || availableMaterials === null || adjustableImpellerOverviewsOrNull.result == null || availableShaftDiameters == null) {
        return <div className={"text-center mt-5"}><UboldSpinner/></div>;
    }

    return <FanSelectionPageLoaded
        availableInletShapes={availableInletShapes}
        availableProfiles={availableProfiles}
        availableMaterials={availableMaterials}
        adjustableImpellerOverviews={adjustableImpellerOverviewsOrNull.result}
        availableShaftDiameters={availableShaftDiameters}/>;

}

const t = createInternationalizationPrefixer("Preselection");

export function FanSelectionPageLoaded({
                                           availableInletShapes,
                                           availableProfiles,
                                           availableMaterials,
                                           availableShaftDiameters,
                                           adjustableImpellerOverviews
                                       }: {
    availableInletShapes: PressureCorrector[],
    availableProfiles: BladeProfileJson[],
    availableMaterials: Material[],
    adjustableImpellerOverviews: AdjustableImpellerOverviewJson[],
    availableShaftDiameters: number[]
}) {

    const [showNotificationsLog, setShowNotificationsLog] = useState(false);
    const [importedCriteriaSignal, setImportedCriteriaSignal] = useState<SelectionRequestJson | null>(null);
    const {
        selectionRequestJsonOrIncorrect,
        formElement
    } = useFanCriteria(availableInletShapes,
        availableProfiles,
        availableMaterials,
        availableShaftDiameters,
        adjustableImpellerOverviews,
        SelectionCriteriaVariant.GENERIC_FAN_SELECTION,
        null);

    const {
        results,
        loading
    } = useFanSelection(selectionRequestJsonOrIncorrect);

    const [pickedMatchIdentifier, setPickedMatchIdentifier] = useState<string>("not-existing");

    return <>
        {formElement}


        {loading === true && <Card additionalClassNames={"mb-3 mt-3"}>
            <div className={" d-flex justify-content-center"}>
                <UboldSpinner/>
                <div style={{minHeight: "800px"}} data-role="improves experience on live search - removes jumping scroll"/>
            </div>
        </Card>}

        {!loading && results.criteriaUsed !== null &&
            <Card additionalClassNames={"mb-3 mt-3"} header={t("Wyniki selekcji - dobór klasyczny")}
                  subHeader={t("Dobór klasyczny w oparciu o zadaną średnicę i RPM, rozszerzony o warianty kątów łopatowych (posortowanych wg sumy błędów)")}>
                {results.fanSelectionMatchedFans.length > 0 && <>
                    <h5 className={"text-center m-0"}>{t("Charakterystyka przepływowa")}</h5>
                    <SelectionMatchedFansChart
                        pickedMatchIdentifier={pickedMatchIdentifier}
                        matchedFans={results.fanSelectionMatchedFans.filter(mf => mf.selectionStrategyCode === "Optisel1")}
                        requestedPoint={{
                            airFlow: results.criteriaUsed.fanWorkingPointQuery != null ? results.criteriaUsed.fanWorkingPointQuery.requestedFanAirFlow ?? 0.0 : results.criteriaUsed.installationWorkingPointQuery!.requestedInstallationAirFlow ?? 0.0,
                            pressure: results.criteriaUsed.fanWorkingPointQuery != null ? results.criteriaUsed.fanWorkingPointQuery.requestedFanStaticPressure ?? 0.0 : results.criteriaUsed.installationWorkingPointQuery!.requestedInstallationStaticPressure ?? 0.0
                        }}/>

                    <FanSelectionResultBrowser
                        pickedMatchIdentifier={pickedMatchIdentifier}
                        setPickedMatchIdentifier={setPickedMatchIdentifier}
                        matches={toFanSelectionMatches(results.fanSelectionMatchedFans.filter(mf => mf.selectionStrategyCode === "Optisel1"), results.criteriaUsed)}/>

                </>}
                {results.fanSelectionMatchedFans.length === 0 && <>
                    <div className={"text-center text-muted p-4 fs-3"}>Brak wyników</div>
                </>}
            </Card>}

        {!loading && results.criteriaUsed?.fanWorkingPointQuery == null && <Card header={t("Wyniki selekcji - dobór rozszerzony")}>
            <div className={"text-center text-muted p-4 fs-3"}>{t("Dobór rozszerzony (automatyczna średnica i RPM) jest obecnie dostępny tylko w opcji \"Zadano ciśnienie samego wentylatora\", gdyż nie obsługuje korekt.")}</div>
        </Card>}
        {!loading && results.criteriaUsed !== null && results.criteriaUsed?.fanWorkingPointQuery != null && results.fanSelectionMatchedFans.length == 0 && <Card header={t("Wyniki selekcji - dobór rozszerzony")}>
            <div className={"text-center text-muted p-4 fs-3"}>{t("Brak wyników")}</div>
            {/* WARNIN THIS IS DUPLICATED ALSO IN FAN SELECTION FAN LIST */}
        </Card>}

        {!loading && results.criteriaUsed !== null && results.criteriaUsed?.fanWorkingPointQuery != null && results.fanSelectionMatchedFans.length > 0 && <Card additionalClassNames={"mb-3"} header={t("Wyniki selekcji - dobór rozszerzony")}
                                                                                                                                                                subHeader={t("Dobór rozszerzony, ignorujący zadane RPM i średnicę. Dobór opiera się o próbkowanie kilku(nastu) punktów charakterystyki każdej nieułamkowej opcji kątowej, ustawiając charakterystykę za pomocą średnicy i prędkości, by próbkowany punkt wypadał w zadanym punkcie pracy, a z uzyskanych nastawów wybierane są po 4 per jeden nastawialny wirnik - optymalne pod kątem średnicy, prędkości, sprawności i SPL. Analiza tylko całkowitych (nieułamkowych) opcji kątowych i tylko do kilkunastu punktów ch-ki dla tych kątów jest obecnie podytkowana wydajnością/czasem doboru.")}>
            <h5 className={"text-center m-0"}>{t("Charakterystyka przepływowa")}</h5>
            <SelectionMatchedFansChart
                pickedMatchIdentifier={pickedMatchIdentifier}
                matchedFans={results.fanSelectionMatchedFans.filter(mf => mf.selectionStrategyCode === "flexible")}
                requestedPoint={{
                    airFlow: results.criteriaUsed.fanWorkingPointQuery != null ? results.criteriaUsed.fanWorkingPointQuery.requestedFanAirFlow ?? 0.0 : results.criteriaUsed.installationWorkingPointQuery!.requestedInstallationAirFlow ?? 0.0,
                    pressure: results.criteriaUsed.fanWorkingPointQuery != null ? results.criteriaUsed.fanWorkingPointQuery.requestedFanStaticPressure ?? 0.0 : results.criteriaUsed.installationWorkingPointQuery!.requestedInstallationStaticPressure ?? 0.0
                }}/>
            <div className={"text-muted text-center my-4"}>
                <FanSelectionResultBrowser
                    pickedMatchIdentifier={pickedMatchIdentifier}
                    setPickedMatchIdentifier={setPickedMatchIdentifier}
                    matches={toFanSelectionMatches(results.fanSelectionMatchedFans.filter(mf => mf.selectionStrategyCode === "flexible"), results.criteriaUsed)}/>

            </div>
        </Card>}


        {results.notifications.length > 0 && showNotificationsLog && <Card additionalClassNames={"mb-3 mt-3 text-gray-400"}>
            {results.notifications.map((n, i) => <div key={i}>{n}</div>)}
        </Card>}

        {!showNotificationsLog && <>
            <button className={"btn btn-outline btn-secondary"} onClick={() => setShowNotificationsLog(true)}>{t("Pokaż obliczenia")}</button>
        </>}
    </>;

}
