import {FanSelectionMatch} from "./toFanSelectionMatches";
import React, {useState} from "react";
import {
    airFlowErrorProperty,
    airFlowMatchProperty,
    apiAirflowReserveProperty,
    apiPressureReserveProperty,
    BASIC_MATCH_PROPERTIES_PROVIDER,
    bladeAngleMatchProperty,
    bladeEndSpeedProperty,
    fanPowerWorkingPointProperty,
    FanSelectionMatchProperty,
    impellerDiameterMatchPropertyProvider,
    installationStaticPressureMatchProperty,
    nameMatchPropertyProvider,
    pressureErrorProperty,
    pwlInOptimalConditionsProperty,
    rpmMatchProperty,
    staticEfficiencyProperty, workingPointInstallationTotalEfficiencyPercentProperty,
    workingPointPwlProperty
} from "./FanSelectionMatchProperty";
import {SimulateButton} from "../simulation/SimulateButton";
import {DatasheetButton} from "../datasheet/DatasheetButton";
import {useSelectionFiltering} from "./useSelectionFiltering";
import {createInternationalizationPrefixer} from "../../platform/i18n/i18n";


export type FanSelectionResultBrowserProps = {
    matches: FanSelectionMatch[],
    setPickedMatchIdentifier: (identifier: string) => void,
    pickedMatchIdentifier: string
}

type SelectionMatchGroup = {
    groupingKey: string,
    primaryMatch: FanSelectionMatch,
    secondaryMatches: FanSelectionMatch[]
}

const t = createInternationalizationPrefixer("Preselection");

export function FanSelectionResultBrowser(props: FanSelectionResultBrowserProps) {
    const {activeFiltersComponent, activatorElement, filteredMatches} = useSelectionFiltering(props.matches);

    const {sortedMatches, reorderByProperty, currentOrderPropertyId, currentOrderDirection} = useResultSorting(filteredMatches);
    const currentGroupingFunction = (match: FanSelectionMatch): string => match.fanName;

    const resultGroups = groupMatches(sortedMatches, currentGroupingFunction);

    const ReorderClickable = (props: { property: FanSelectionMatchProperty<any> }) => {
        return <span onClick={() => reorderByProperty(props.property.id)} style={{cursor: "pointer", fontWeight: (currentOrderPropertyId === props.property.id ? "bold" : "normal")}}>
                    {props.property.name} {currentOrderPropertyId === props.property.id && (currentOrderDirection === "asc" ? "▲" : "▼")}
                </span>;
    }

    const headerRow = <tr>
        <th className={"p-0 w-200px text-center"}>
            <div><ReorderClickable property={nameMatchPropertyProvider()}/></div>
            <div><ReorderClickable property={impellerDiameterMatchPropertyProvider()}/></div>
        </th>
        <th className={"p-0 w-125px text-center"}>
            <ReorderClickable property={airFlowMatchProperty}/>
            <br/>
            <ReorderClickable property={installationStaticPressureMatchProperty}/>
        </th>
        <th className={"p-0 w-50px text-center"}>
            <div><ReorderClickable property={airFlowErrorProperty}/></div>
            <div><ReorderClickable property={pressureErrorProperty}/></div>
        </th>
        <th className={"p-0 w-80px text-center"}>
            <div><ReorderClickable property={rpmMatchProperty}/></div>
            <div><ReorderClickable property={bladeEndSpeedProperty}/></div>
        </th>
        <th className={"p-0 text-center"}>
            <ReorderClickable property={bladeAngleMatchProperty}/>
        </th>
        <th className={"p-0 text-center"}>
            <div><ReorderClickable property={pwlInOptimalConditionsProperty}/></div>
            <div><ReorderClickable property={workingPointPwlProperty}/></div>

        </th>
        <th className={"p-0 text-center"}>
            <div><ReorderClickable property={staticEfficiencyProperty}/></div>
            <div><ReorderClickable property={workingPointInstallationTotalEfficiencyPercentProperty}/></div>


        </th>
        <th className={"p-0 text-center"}>
            <div><ReorderClickable property={fanPowerWorkingPointProperty}/></div>
            {/*<div><ReorderClickable property={apiAirflowReserveProperty}/></div>*/}
            {/*<div><ReorderClickable property={apiPressureReserveProperty}/></div>*/}
        </th>
        <th className={"p-0 text-center"}>
            <div><ReorderClickable property={apiAirflowReserveProperty}/></div>
            <div><ReorderClickable property={apiPressureReserveProperty}/></div>
        </th>
        <th className={"p-0 w-90px"}>

        </th>
        <th className={"p-0 w-90px"}>

        </th>

    </tr>;


    return <>
        {activeFiltersComponent}
        <div className={"mb-5"}>{activatorElement}</div>
        <table className="table align-middle gs-1 gy-1">
            <thead style={{userSelect: "none", color: "#0000aa"}}>
            {headerRow}
            </thead>
            <tbody className={"fs-7"}>
            {resultGroups.map(group => <>
                <TrForMatchGroup
                    setPickedMatchIdentifier={props.setPickedMatchIdentifier}
                    pickedMatchIdentifier={props.pickedMatchIdentifier}
                    group={group} key={JSON.stringify(group.primaryMatch.fanName)}/> {/* tehc debt not sure about key */}
            </>)}
            </tbody>
        </table>
    </>;

}

const TrForMatchGroup = (props: {
    group: SelectionMatchGroup,
    setPickedMatchIdentifier: (identifier: string) => void,
    pickedMatchIdentifier: string
}) => {
    const [expanded, setExpanded] = useState(false);

    const match = props.group.primaryMatch;

    const trForPrimaryMatch = <tr
        onClick={() => props.setPickedMatchIdentifier(match.matchIdentifier)}
        className={"preselection-result p-5 border-top-1 border-top" + (match.matchIdentifier === props.pickedMatchIdentifier ? " bg-light-warning" : "")}>
        <td className={"text-end"}>
            <div className={"fs-5"}>{nameMatchPropertyProvider().renderer(nameMatchPropertyProvider().extractor(match))}</div>
            <div>{impellerDiameterMatchPropertyProvider().renderer(impellerDiameterMatchPropertyProvider().extractor(match))}</div>
        </td>
        <td className={"p-0 text-end"}>
            {airFlowMatchProperty.renderer(airFlowMatchProperty.extractor(match))}
            {installationStaticPressureMatchProperty.renderer(installationStaticPressureMatchProperty.extractor(match))}
        </td>
        <td className={"p-0 text-end text-muted"}>
            {airFlowErrorProperty.renderer(airFlowErrorProperty.extractor(match))}
            {pressureErrorProperty.renderer(pressureErrorProperty.extractor(match))}
        </td>
        <td className={"p-0 text-end"}>
            {rpmMatchProperty.renderer(rpmMatchProperty.extractor(match))}
            {bladeEndSpeedProperty.renderer(bladeEndSpeedProperty.extractor(match))}
        </td>
        <td className={"p-0 text-end"}>
            {bladeAngleMatchProperty.renderer(bladeAngleMatchProperty.extractor(match))}
        </td>
        <td className={"p-0 text-end"}>
            {pwlInOptimalConditionsProperty.renderer(pwlInOptimalConditionsProperty.extractor(match))}
            {workingPointPwlProperty.renderer(workingPointPwlProperty.extractor(match))}

        </td>
        <td className={"p-0 text-end"}>
            {staticEfficiencyProperty.renderer(staticEfficiencyProperty.extractor(match))}
            {workingPointInstallationTotalEfficiencyPercentProperty.renderer(workingPointInstallationTotalEfficiencyPercentProperty.extractor(match))}

        </td>
        <td className={"p-0"}>
            {fanPowerWorkingPointProperty.renderer(fanPowerWorkingPointProperty.extractor(match))}
        </td>
        <td  className={"p-0"}>
            {apiAirflowReserveProperty.renderer(apiAirflowReserveProperty.extractor(match))}
            {apiPressureReserveProperty.renderer(apiPressureReserveProperty.extractor(match))}
        </td>
        <td className={"p-0"}>
            {props.group.secondaryMatches.length > 0 && <>
                <button className={"btn btn-sm btn-outline btn-outline-secondary text-nowrap"} onClick={() => setExpanded(prev => !prev)}>
                    + {props.group.secondaryMatches.length} {t("opcji")}
                </button>
            </>}
        </td>
        <td className={"p-0"}>
            {match.criteriaToSimulate !== undefined && <>
                <div className={"d-flex flex-row"}>
                    <DatasheetButton simulationRequest={match.criteriaToSimulate}/>
                    <SimulateButton simulationRequest={match.criteriaToSimulate}/>
                </div>
            </>}
        </td>
    </tr>;

    const trsForSecondaryMatches = props.group.secondaryMatches.map(secondaryMatch => <tr
        key={JSON.stringify(secondaryMatch.fanName + "" + secondaryMatch.bladeAngle)}
        onClick={() => props.setPickedMatchIdentifier(secondaryMatch.matchIdentifier)}
        className={"preselection-result p-5  bg-light " + (secondaryMatch.matchIdentifier === props.pickedMatchIdentifier ? " bg-light-warning" : "")}
    >
        <td className={"pt-3 text-end"}>
            <div>{impellerDiameterMatchPropertyProvider().renderer(impellerDiameterMatchPropertyProvider().extractor(secondaryMatch))}</div>
        </td>
        <td className={"p-0 pt-3 text-end"}>
            {airFlowMatchProperty.renderer(airFlowMatchProperty.extractor(secondaryMatch))}
            {installationStaticPressureMatchProperty.renderer(installationStaticPressureMatchProperty.extractor(secondaryMatch))}
        </td>
        <td className={"p-0 text-end text-muted"}>
            {airFlowErrorProperty.renderer(airFlowErrorProperty.extractor(secondaryMatch))}
            {pressureErrorProperty.renderer(pressureErrorProperty.extractor(secondaryMatch))}
        </td>
        <td className={"p-0 text-end"}>
            {rpmMatchProperty.renderer(rpmMatchProperty.extractor(secondaryMatch))}
            {bladeEndSpeedProperty.renderer(bladeEndSpeedProperty.extractor(secondaryMatch))}
        </td>
        <td className={"p-0 text-end"}>
            {bladeAngleMatchProperty.renderer(bladeAngleMatchProperty.extractor(secondaryMatch))}
        </td>
        <td className={"p-0 text-end"}>
            {pwlInOptimalConditionsProperty.renderer(pwlInOptimalConditionsProperty.extractor(secondaryMatch))}
            {workingPointPwlProperty.renderer(workingPointPwlProperty.extractor(secondaryMatch))}

        </td>
        <td className={"p-0 text-end"}>
            {staticEfficiencyProperty.renderer(staticEfficiencyProperty.extractor(secondaryMatch))}
            {workingPointInstallationTotalEfficiencyPercentProperty.renderer(workingPointInstallationTotalEfficiencyPercentProperty.extractor(secondaryMatch))}

        </td>
        <td className={"p-0"}>
            {fanPowerWorkingPointProperty.renderer(fanPowerWorkingPointProperty.extractor(secondaryMatch))}

        </td>
        <td className={"p-0 "}>
            {apiAirflowReserveProperty.renderer(apiAirflowReserveProperty.extractor(secondaryMatch))}
            {apiPressureReserveProperty.renderer(apiPressureReserveProperty.extractor(secondaryMatch))}
        </td>
        <td>

        </td>
        <td className={"p-0 "}>
            {secondaryMatch.criteriaToSimulate !== undefined && <>
                <div className={"d-flex flex-row"}>
                    <DatasheetButton simulationRequest={secondaryMatch.criteriaToSimulate}/>
                    <SimulateButton simulationRequest={secondaryMatch.criteriaToSimulate}/>
                </div>
            </>}
        </td>
    </tr>);

    return <>
        {trForPrimaryMatch}
        {expanded && trsForSecondaryMatches}
    </>
}


function useResultSorting(unsortedMatches: FanSelectionMatch[]) {
    const [orderPropertyId, setOrderPropertyId] = useState<string>("name");
    const [orderDirection, setOrderDirection] = useState<"asc" | "desc">("asc");

    const toggleOrderDirection = () => {
        setOrderDirection(prev => {
            if (prev === "asc") {
                return "desc";
            } else {
                return "asc"
            }
        })
    }

    const changeOrderToPropertyDefault = (propertyId: string) => {
        const propertyToUse = BASIC_MATCH_PROPERTIES_PROVIDER().filter(property => property.id === propertyId)[0];
        setOrderDirection(propertyToUse.defaultOrder);
        setOrderPropertyId(propertyToUse.id);
    }

    const sortedMatches = [...unsortedMatches];
    sortedMatches.sort((a, b) => {
        const property = BASIC_MATCH_PROPERTIES_PROVIDER().filter(p => p.id == orderPropertyId)[0];
        const aValue = property.extractor(a);
        const bValue = property.extractor(b);
        if (aValue > bValue) {
            return orderDirection === "asc" ? 1 : -1;
        } else {
            if (bValue > aValue) {
                return orderDirection === "asc" ? -1 : 1;
            }
            return 0;
        }
    });

    const reorderByProperty = (newOrderPropertyId: string) => {
        if (orderPropertyId === newOrderPropertyId) {
            toggleOrderDirection();
            return;
        }
        changeOrderToPropertyDefault(newOrderPropertyId);
    }

    return {
        sortedMatches,
        reorderByProperty,
        currentOrderPropertyId: orderPropertyId,
        currentOrderDirection: orderDirection
    }
}

function groupMatches(allMatchesInOrder: FanSelectionMatch[], groupingKeyFunction: (match: FanSelectionMatch) => string): SelectionMatchGroup[] {
    const result: SelectionMatchGroup[] = [];
    for (const inputMatch of allMatchesInOrder) {
        const key = groupingKeyFunction(inputMatch);
        const alreadyExistingGroup = result.find(r => r.groupingKey === key);
        if (alreadyExistingGroup !== undefined) {
            alreadyExistingGroup.secondaryMatches.push(inputMatch);
        } else {
            result.push({
                groupingKey: key, primaryMatch: inputMatch, secondaryMatches: []
            });
        }
    }

    return result;
}
