export function hasFormDslAnyError(formDsl) {
    if (Array.isArray(formDsl.errors)) {
        throw Error("Incorrect formDsl format, no .errors array");
    }
    return hasObjectStructureAnyString(formDsl.errors);

}

export function hasObjectStructureAnyString(object) {
    if (object instanceof Object) {
        for (let [, value] of Object.entries(object)) {
            if (hasObjectStructureAnyString(value)) {
                return true;
            }
        }
    }
    if (Array.isArray(object)) {
        for (const attribute of object) {
            if (hasObjectStructureAnyString(attribute)) {
                return true;
            }
        }
    }
    if (isString(object)) {
        return true;
    }
    return false;

}

function isString(x) {
    return Object.prototype.toString.call(x) === "[object String]"
}