import React, {useCallback, useContext, useState} from "react";
import {useFetchAllObjsWithRefetchCapability} from "../hooks/PlatformTypeScriptHooks";
import {TranslationRuleJson, TranslationRuleJsonWithMeta} from "../../pages/i18n/TranslationRulesPage";
import {debounce} from "lodash";
import AjaxService from "../ajax/AjaxService";
import {TRANSLATION_RULES} from "../../pages/i18n/OptiselStaticTranslationRules";
import UboldSpinner from "../../ubold-components/UboldSpinner";


const LOCAL_STORAGE_CURRENT_LANGUAGE_KEY = "currentLanguage";

/**
 * Internationalized name
 * (usually to pass server-driven internationalization)
 * May be used as JSON representing backend value
 */
export type Istring = {
    pl: string,
    en: string,
    de: string,
    ru: string,
}

/**
 * This represents the TRANSLATION KEY, but usually readable in polish
 * (e.g. "Punkt pracy", but also may be "param-Wydajność"
 * usually, Ik is resolved to get text in current language, or resolved to Istring to have all variants
 */
export type Ik = string;

export function resolveIstring(istring: Istring): string {
    const currentLanguage = getCurrentLanguage();
    if (currentLanguage === "en") {
        return istring.en;
    }
    if (currentLanguage === "pl") {
        return istring.pl;
    }
    if (currentLanguage === "de") {
        return istring.de;
    }
    if (currentLanguage === "ru") {
        return istring.ru;
    }
    throw new Error("unknon language: " + currentLanguage);
}

/**
 * For simplification, parameter values that are strings, sometimes may start with ik:
 * which mens, they should be treated as text to translate. not directly.
 * @param paramValue
 */
export function resolveParameterValue(paramValue: string): string {
    if (paramValue.startsWith("@ik:")) {
        return i18n(paramValue);
    }
    return paramValue;
}


export function getCurrentLanguage() {
    const language = localStorage.getItem(LOCAL_STORAGE_CURRENT_LANGUAGE_KEY);
    if (language === null) {
        return "pl";
    }
    return language;
}

export function setStoredLanguage(language: string) {
    localStorage.setItem(LOCAL_STORAGE_CURRENT_LANGUAGE_KEY, language);
}

export function i18n(code: string) {
    const currentLanguage = getCurrentLanguage();

    //return "aaa";/*

    const filteredRules = staticRules.filter(sr => sr.code === code);
    if (filteredRules.length > 0) {
        if (currentLanguage === "en" && filteredRules[0].en.length > 0) {
            return filteredRules[0].en;
        }
        if (currentLanguage === "pl" && filteredRules[0].pl.length > 0) {
            return filteredRules[0].pl;
        }
        if (currentLanguage === "de" && filteredRules[0].de && filteredRules[0].de.length > 0) {
            return filteredRules[0].de;
        }
        if (currentLanguage === "ru" && filteredRules[0].ru && filteredRules[0].ru.length > 0) {
            return filteredRules[0].ru;
        }
    }

    console.warn("Found missing translation", code, currentLanguage);
    handleUnknownTranslation(code, currentLanguage);

    if (getCurrentLanguage() === "en") {
        return "[[[" + code + "]]]";
    } else {
        return "@@@" + code + "@@@";
    }//*/
}

export function createInternationalizationPrefixer(prefixName: string) {
    if ( prefixName.length === 0){
        return (text: string) => i18n( text);
    }
    return (text: string) => i18n(prefixName + ":" + text);
}

export function T(props: { children: React.ReactNode }) {
    if (typeof props.children === "string") {
        return i18n(props.children)
    }
    console.error("TRANSLATION PROBLEM", props.children); // TODO, report stacktrace
    return <div style={{border: "2px solid red"}}>(TRANSLATION PROBLEM) {props.children}</div>
}

export function LanguageSelector(props: {}) {

    const languageContextValue = useContext(LanguageContext);

    const setCurrentLanguage = (language: string) => {

        languageContextValue.updateLanguage(language);
    }


    return <div>
        <button className={"btn btn-xs " + (languageContextValue.currentLanguage === "pl" ? "btn-outline-success" : "btn-outline-light")} onClick={() => setCurrentLanguage("pl")}>PL</button>
        <button className={"btn btn-xs " + (languageContextValue.currentLanguage === "en" ? "btn-outline-success" : "btn-outline-light")} onClick={() => setCurrentLanguage("en")}>EN</button>
        <button className={"btn btn-xs " + (languageContextValue.currentLanguage === "de" ? "btn-outline-success" : "btn-outline-light")} onClick={() => setCurrentLanguage("de")}>DE</button>
        <button className={"btn btn-xs " + (languageContextValue.currentLanguage === "ru" ? "btn-outline-success" : "btn-outline-light")} onClick={() => setCurrentLanguage("ru")}>RU</button>
    </div>
}


export const LanguageContext = React.createContext<{
    currentLanguage: string,
    updateLanguage: (newLang: string) => void
}>({
    currentLanguage: "pl",
    updateLanguage: (language: string) => {

    }
});


let staticRules: TranslationRuleJson[] = [];

export function useAllEffectiveTranslationRules(): [rules: TranslationRuleJsonWithMeta[], loaded: boolean, refetchBackendRules: () => void] {
    const backendRules = useFetchAllObjsWithRefetchCapability<TranslationRuleJson[]>("/api/translation-rules");
    const staticRules = TRANSLATION_RULES;


    if (backendRules.result === null) {
        return [staticRules, false, backendRules.refetch];
    }


    const translationIndex: any = {};

    for (const rule of backendRules.result) {
        translationIndex[rule.code] = {};
        if (rule.pl.length > 0) {
            translationIndex[rule.code]['pl'] = rule.pl;
            translationIndex[rule.code]['plsource'] = rule.plH ? 'static':'api';
        }
        if (rule.en.length > 0) {
            translationIndex[rule.code]['en'] = rule.en;
            translationIndex[rule.code]['ensource'] = rule.enH ? 'static':'api';
        }
        if (rule.de && rule.de.length > 0) {
            translationIndex[rule.code]['de'] = rule.de;
            translationIndex[rule.code]['desource'] = rule.deH ? 'static':'api';
        }
        if (rule.ru && rule.ru.length > 0) {
            translationIndex[rule.code]['ru'] = rule.ru;
            translationIndex[rule.code]['rusource'] =rule.ruH ? 'static':'api';
        }
    }



    const allRules: TranslationRuleJsonWithMeta[] = [];
    for (const code in translationIndex) {
        allRules.push({
            code: code,
            en: translationIndex[code]['en'] || "",
            pl: translationIndex[code]['pl'] || "",
            de: translationIndex[code]['de'] || "",
            ru: translationIndex[code]['ru'] || "",
            ensource: translationIndex[code]['ensource'] || "",
            plsource: translationIndex[code]['plsource'] || "",
            desource: translationIndex[code]['desource'] || "",
            rusource: translationIndex[code]['rusource'] || "",

        } as TranslationRuleJsonWithMeta)
    }
    return [
        allRules,
        true,
        backendRules.refetch
    ];
}

export function LanguageContextProvider(props: { children: React.ReactNode }) {
    const [currentLanguage, setCurrentLanguage] = useState(getCurrentLanguage());

    const updateLanguage = useCallback((lang: string) => {
        setStoredLanguage(lang);
        //setCurrentLanguage(lang);

        setTimeout(()=>{
            window.location.reload();
        },200); // timeout just in case. could work without it
    }, [setCurrentLanguage]);
    const contextValue = {
        currentLanguage: currentLanguage,
        updateLanguage: updateLanguage
    }

    // Note rules are not passed to context, but published "statically"
    const [translationRules, translationRulesLoaded] = useAllEffectiveTranslationRules();
    if (!translationRulesLoaded) {
        return <div className={"text-center mt-10"}><UboldSpinner/></div>;
    }
    staticRules = translationRules; // TODO useEffect?

    return <LanguageContext.Provider value={contextValue}>
        {props.children}
    </LanguageContext.Provider>;
}

const codesWithNoTranslation = new Set<{ code: string, language: string }>();


function handleUnknownTranslation(code: string, language: string) {
    codesWithNoTranslation.add({code, language});
    submitMissingTranslationsToBackend();
}

const submitMissingTranslationsToBackend = debounce(() => {
    console.info("Registering missing translations on server", codesWithNoTranslation.size);
    AjaxService.postJson("/api/translation-rule/register-missing", Array.from(codesWithNoTranslation));
    codesWithNoTranslation.clear();
}, 3000);
