export default class AuthTokenStorageService {

    static getCurrentToken() {
        return localStorage.getItem("access_token");
    }

    static setCurrentToken(token) {
        localStorage.setItem("access_token", token);
    }

    static removeCurrentToken() {
        localStorage.removeItem("access_token");
    }
}