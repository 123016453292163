import React, {useContext, useState} from 'react';
import {Navigate} from "react-router-dom";
import AuthenticationService from "../platform/authentication/AuthenticationService";
import UboldFullPageSignIn from "../ubold-components/UboldFullPageSignIn";
import {AuthenticationContext} from "../platform/authentication/AuthenticationContextProvider";

export function OptiselSignInPage(props) {

    const authContextValue = useContext(AuthenticationContext);

    const [formData, setFormData] = useState({
        email: "",
        password: ""
    });


    const viewStates = {
        FORM: "form",
        LOADING: "loading",
        REDIRECTING: "redirecting"
    }

    const [currentViewState, setCurrentViewState] = useState(viewStates.FORM);
    const [generalErrorMessages, setGeneralErrorMessages] = useState([]);

    const processSignIn = event => {
        setCurrentViewState(viewStates.LOADING);
        event.preventDefault();
        setGeneralErrorMessages([]);
        const passwordToBeSent = formData.password;
        setFormData({...formData, password: ""});
        AuthenticationService.signIn(formData.email, passwordToBeSent)
            .then(function () {
                // A no-op, as it will anyway redirect from this page if already-authenticated
                // (updating state here would end up with updating unmounted component likely)
            })
            .catch(function (errorMessage) {
                setCurrentViewState(viewStates.FORM);
                setGeneralErrorMessages([errorMessage]);
            });
    };

    if (authContextValue.isAuthenticated()) {
        console.log("Redirecting to / as user is authenticated");
        return <Navigate to={"/"}/>;
    }


    return <UboldFullPageSignIn
        logoImgSrc={"/img/wentech_logo.svg"}
        intro={
            [<div key={1} className="text-muted mt-3">
                <h3>Optisel 2</h3>
                Logowanie
            </div>]}
        errorMessage={(generalErrorMessages.length > 0 ? generalErrorMessages[0] : "")}
        emailLabel={"Adres e-mail"}
        emailPlaceholder={""}
        passwordLabel={"Hasło"}
        passwordPlaceholder={""}
        submitLabel={"Zaloguj"}
        copyrightVendor={"Wentech"}
        onLoginChange={v => setFormData({
            ...formData,
            email: v.target.value,
        })}
        onPasswordChange={v => setFormData({
            ...formData,
            password: v.target.value,
        })}
        loginValue={formData.email}
        passwordValue={formData.password}
        processingInProgress={currentViewState !== viewStates.FORM}
        onSubmit={processSignIn}
        additionalComponents={<React.Fragment>


        </React.Fragment>}
    />
        ;
}

OptiselSignInPage.propTypes = {}

OptiselSignInPage.defaultProps = {}
