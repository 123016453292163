import {SelectionCriteriaVariant, useFanCriteria} from "pages/selection/useFanCriteria";
import React from "react";
import {isFailed, isSuccessful, useImpellerSimulation} from "../business/useImpellerSimulation";
import {Card} from "../components/Card";
import {SimulationDatasheet} from "../components/domain/SimulationDatasheet";
import {createInternationalizationPrefixer} from "../platform/i18n/i18n";
import UboldSpinner from "../ubold-components/UboldSpinner";
import {useFetchAllObjs} from "../platform/hooks/PlatformTypeScriptHooks";
import {PressureCorrector} from "../business/ImpellerSimulationCriteria";
import {BladeProfileJson, useFetchAllBladeProfiles} from "./profile/BladeProfileService";
import {Material} from "../business/mechanical/Material";
import {AdjustableImpellerOverviewJson, useFetchImpellerOverviewsReport} from "./impeller/AdjustableImpellerService";
import {useSearchParams} from "react-router-dom";
import {Buffer as NpmBuffer} from "buffer";
import {SimulationRequestJson} from "../business/ApiJsonTypes";
import {resolveParamTranslation} from "../business/core";

const t = createInternationalizationPrefixer("SimulationPage");

export function SimulationPage() {
    // TODO Remove code duplication
    const availableInletShapes = useFetchAllObjs<PressureCorrector[] | null>("/api/inlet-shapes");
    const availableProfiles = useFetchAllBladeProfiles();
    const availableMaterials = useFetchAllObjs<Material[]>("/api/available-materials");
    const availableShaftDiameters: number[] | null = useFetchAllObjs<number[]>("/api/mechanical/available-shaft-diameters/all");
    const adjustableImpellerOverviewsOrNull = useFetchImpellerOverviewsReport();

    if (availableInletShapes == null || availableProfiles === null || availableMaterials === null || adjustableImpellerOverviewsOrNull.result == null || availableShaftDiameters == null) {
        return <div className={"text-center mt-5"}><UboldSpinner/></div>;
    }
    return <SimulationPageLoaded
        availableInletShapes={availableInletShapes}
        availableProfiles={availableProfiles}
        availableMaterials={availableMaterials}
        availableShaftDiameters={availableShaftDiameters}
        adjustableImpellerOverviews={adjustableImpellerOverviewsOrNull.result}/>;

}

export function useQueryParamPredefinedCriteria(): SimulationRequestJson | null {

    const [searchParams, setSearchParams] = useSearchParams();

    //var blueprintId = searchParams.get("blueprintId"); TODO

    var b64PredefinedCriteriaJson = searchParams.get("b64Criteria");
    let predefinedCriteria: SimulationRequestJson | null = null;
    if (b64PredefinedCriteriaJson !== null) { // TODO syntax refactoring, remove overwriting
        const a = NpmBuffer.from(b64PredefinedCriteriaJson, 'base64').toString();
        predefinedCriteria = JSON.parse(a);
    }
    return predefinedCriteria;
}

export function SimulationPageLoaded({
                                         availableInletShapes,
                                         availableProfiles,
                                         availableMaterials,
                                         availableShaftDiameters,
                                         adjustableImpellerOverviews
                                     }: {
    availableInletShapes: PressureCorrector[],
    availableProfiles: BladeProfileJson[],
    availableMaterials: Material[],
    availableShaftDiameters: number[],
    adjustableImpellerOverviews: AdjustableImpellerOverviewJson[]
}) {

    const predefinedCriteria = useQueryParamPredefinedCriteria();

    const {
            simulationRequestJson,
            formElement,
        } = useFanCriteria(
            availableInletShapes,
            availableProfiles,
            availableMaterials,
            availableShaftDiameters,
            adjustableImpellerOverviews,
            SelectionCriteriaVariant.SPECIFIC_FAN_SIMULATION,
            predefinedCriteria
        )
    ;

    const {
        simulationResponse,
        loading
    } = useImpellerSimulation(simulationRequestJson);

    return <>


        {formElement}

        {!loading && isSuccessful(simulationResponse) && simulationResponse.result && <SimulationDatasheet
            simulationResult={simulationResponse.result}
            memoizedId={simulationResponse.memoizedId}
        />}

        {!loading && isFailed(simulationResponse) && simulationResponse.error && <React.Fragment>
            <div className={"alert alert-danger mt-5 fw-boldest fs-3"}><i
                className="fas fa-exclamation-circle"></i> {simulationResponse.error}</div>
            <Card header={t("Obliczenia")} subHeader={t("Objaśnienie obliczeń")}>
                {simulationResponse.calculations.map((calc) => (<div className="row mb-3">
                    <div className="col-3 text-end">
                        <div className="badge badge-light-info">{resolveParamTranslation(calc.humanFriendlyNameIk)}</div>
                    </div>
                    <div className="col-9">
                        {calc.logs.map(log => <div>{log}</div>)}
                    </div>
                </div>))}
            </Card>
        </React.Fragment>}

        {loading && <><UboldSpinner/>
            <div style={{minHeight: "800px"}} data-role="improves experience on live search - removes jumping scroll"/>
        </>}
    </>;
}
