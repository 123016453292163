import {HotIssueSummary, useHotIssue} from "./useHotIssue";
import {createContext} from "react";

type HotIssueContextType = {
    currentHotIssueSummary: null | HotIssueSummary;
    clearHotIssue: () => void;
    makeHotIssue: (issueId: string) => void;
};

export const HotIssueContext = createContext<HotIssueContextType>({
    currentHotIssueSummary: null,
    clearHotIssue: () => {
        console.error("Context not yet initialized, cannot clear hot issue");
    },
    makeHotIssue: (issueId: string) => {
        console.error("Context not yet initialized, cannot set hot issue");
    }
});

export function HotIssueContextProvider(props: { children: JSX.Element }) {
    const {clearHotIssue, currentHotIssueSummary, makeHotIssue} = useHotIssue();

    return (
        <HotIssueContext.Provider value={{currentHotIssueSummary, clearHotIssue, makeHotIssue}}>
            {props.children}
        </HotIssueContext.Provider>
    );
}