import {MeasureEditor} from "../form/editors/MeasureEditor";
import {useObjectFromUrlResourceOrNull} from "../platform/hooks/PlatformHooks";
import {OptionalEditor} from "../form/editors/OptionalEditor";
import React from "react";


export function ShaftLengthBonusElements(props: { editor: MeasureEditor, shaftDiameterEditor: OptionalEditor<string> }) {

    const suggestedLengtsh: number[] = ((props.shaftDiameterEditor.value === null) ? ([14]) : [parseFloat(props.shaftDiameterEditor.value), parseFloat(props.shaftDiameterEditor.value) * 2]);

    const commonShafts = useObjectFromUrlResourceOrNull(props.shaftDiameterEditor.value === null ? null : "/api/mechanical/common-shaft-lengths?shaftDiameter=" + props.shaftDiameterEditor.value) as (number[] | null);


    if (commonShafts === null) {
        return <></>;
    }
    return <div className={"d-flex gap-1"}>
        {commonShafts.map(sugLen => <button className={"fs-7 border-0"} style={{borderBottomLeftRadius: "9px", borderBottomRightRadius: "9px"}} onClick={() => props.editor.populate(sugLen)}>{sugLen} mm</button>)}
    </div>

}