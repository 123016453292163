import React, {useContext, useState, useEffect} from 'react';
import {NavLink, Redirect} from "react-router-dom";
import {useParams} from "react-router";
import PropTypes from 'prop-types';
import DynamicWebServiceDrivenForm from "../../platform/webform/DynamicWebServiceDrivenForm";
import * as d3 from "d3";

export function DemoChart(props) {

    const ref = React.useRef();


    const [pressureCurvePoints, setPressureCurvePoints] = useState(props.pressureCurvePoints);
    const [uncorrectedPressureCurvePoints, setUncorrectedPressureCurvePoints] = useState(props.uncorrectedPressureCurvePoints);


    const renderChart =
        (e) => {

            const svg = d3.select(e);

            let maxX = -999;
            let maxY = -999;
            for ( let i = 0 ; i < pressureCurvePoints.length ; i++ ) {
                if ( pressureCurvePoints[i].x > maxX) {
                    maxX = pressureCurvePoints[i].x;
                }
                if ( pressureCurvePoints[i].y > maxY) {
                    maxY = pressureCurvePoints[i].y;
                }
            }

            const width = 500;
            const height = 400;

            const minFlowValue = 0; // todo calculate
            const maxFlowValue = maxX * 1.3; // todo calculate
            const minPressureValue = 0;
            const maxPressureValue = maxY * 1.3;

            const chartDataAreaWidth = width; // todo calculate
            const chartDataAreaHeight = height; // todo calculate

            // if ( was =-= true) {
            //     return;
            // }
            // was = true;


            const d3RootG = svg.append("g").attr("class", "rootg");//.attr("transform", "translate(" + margin.left + "," + margin.top + ")");
            const d3ScaleX = d3.scaleLinear().range([0, chartDataAreaWidth]).domain([minFlowValue, maxFlowValue]);
            d3RootG.append("g")
                .attr("class", "axis axis--x")
                .attr("transform", "translate(0," + height + ")")
                .call(d3.axisBottom(d3ScaleX).ticks(15))
                .selectAll("text").attr("y", -5).attr("x", 7)
                //.attr("dy", ".35em")
                .attr("transform", "rotate(90)")
                .style("text-anchor", "start");

            const d3ScaleY = d3.scaleLinear().range([chartDataAreaHeight, 0]).domain([minPressureValue, maxPressureValue]);
            d3RootG.append("g")
                .attr("class", "axis axis--y")
                //.attr("transform", "translate(0," + height + ")")
                .call(d3.axisLeft(d3ScaleY).ticks(15));

            const lineFunc = d3.line()
                .x(function (domainPoint) {
                    return d3ScaleX(domainPoint.x)
                })
                .y(function (domainPoint) {
                    return d3ScaleY(domainPoint.y)
                });




            d3RootG.append("path")
                .attr("class", "sound-level-line")
                .attr("d", lineFunc(pressureCurvePoints));


            d3RootG.append("path")
                .attr("class", "sound-level-line")
                .attr("d", lineFunc(uncorrectedPressureCurvePoints));

            svg.selectAll("text")
                .attr("font-family", "Poppins");

            d3RootG.append("circle")
                .attr("cx", d3ScaleX(props.workingPointFi))
                .attr("cy", d3ScaleY(props.workingPointPsi))
                .attr("r", 5)
                .attr("fill", "black");

            d3RootG.append("text")
                .attr("font-size", "12px")
                .attr("fill", "black")
                .attr("x", 4+d3ScaleX(props.workingPointFi))
                .attr("y", -4+d3ScaleY(props.workingPointPsi))

                .text("fi: "+props.workingPointFi.toFixed(3)+" psi: "+props.workingPointPsi.toFixed(3));

        };

    useEffect(() => {
        renderChart(ref.current);
    }, [pressureCurvePoints]);


    return <div className={"p-0"}>
        <svg
            ref={ref}
            viewBox={"-40 -40 520 520"}
            style={{
                height: 500,
                width: 500,
                marginRight: "10px",
                marginLeft: "10px"
            }}>
            <g className="plot-area"/>
            <g className="x-axis"/>
            <g className="y-axis"/>
        </svg>
    </div>;
}

DemoChart.propTypes = {}

DemoChart.defaultProps = {}
