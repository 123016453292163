/*
case 0:
				// Obliczanie srednicy obudowy(D) z srednicy wirnika(dw) i szczeliny(S)
				//D = -((dw*100)/(S-100));
				//D = -((dw*100)/((S/2)-100));
				D=dw/(1-2*(S/100));
				cd("Obliczam srednice D z dw="+dw+" i S="+S+"  D=Dw/(1-2*(S/100))");
				break;
			case 1:
				// Obliczanie szczeliny(S) z srednicy wirnika(dw) i srednicy obudowy(D)
				//S = ((D-dw)/D)*100;
				//S = 2*(100-(dw*100) / D);
				 S=100*(D-dw)/(2*D);
				cd("Obliczam szczeline S z dw="+dw+" i D="+D+"    S=100*(D-Dw)/(2*D)");
				break;
			case 2:
				// Obliczanie srednicy wirnika(dw) ze szczeliny(S) i srednicy obudowy(D)
				//dw = ((100-S)/100)*D;
				//dw = (-D*( (S/2)-100 ))/100;
				//dw=D-(S/100)*(2*D)
				dw=D-(2*D*S/100);
 */


import {WentechMeasuringStandards} from "../measurement/WentechMeasurementStandards";
import {createEditorForMeasurementStandard, MeasureEditorValue} from "../../form/editors/MeasureEditor";
import {LabeledEditor} from "../../pages/selection/useFanCriteria";
import {createInternationalizationPrefixer} from "../../platform/i18n/i18n";
import React, {useEffect, useState} from "react";
import {MeasurementStandard} from "../../platform/measurement/MeasurementStandard";
import {OptionalEditor} from "../../form/editors/OptionalEditor";
import {useEditor} from "../../form/EditorReact";

export type SpeedDetails = "speed-autoselection" | {
    rpm: number,
}

export interface SpeedEditorValue {
    speedDetails: SpeedDetails | "invalid-due-to-incorrect-impeller-diameter";
    editorComponent: JSX.Element,
    reset: () => void,
    prepopulate: (speedDetails: SpeedDetails) => void
}

enum SpeedEditorMode {
    AUTOSELECT_SPEED,
    SPECIFY_RPM,
    SPECIFY_BLADE_END_SPEED
}

const t = createInternationalizationPrefixer("FanSelectionCriteriaControls");

export function useSpeedEditorFacade(impellerDiameter: number | undefined): SpeedEditorValue {

    const [mode, setMode] = useState<SpeedEditorMode>(SpeedEditorMode.SPECIFY_RPM);

    const rpmEditor = useSpeedMeasureEditor(WentechMeasuringStandards.ROTATIONAL_SPEED, true, () => {
    });
    const bladeEndSpeedEditor = useSpeedMeasureEditor(WentechMeasuringStandards.BLADE_END_SPEED, false, () => {
    });

    console.log("RP",rpmEditor);

    useEffect(() => {
        if (mode === SpeedEditorMode.AUTOSELECT_SPEED) {
            rpmEditor.disable();
            bladeEndSpeedEditor.disable();
        }
        if (mode === SpeedEditorMode.SPECIFY_RPM) {
            rpmEditor.enable();
            bladeEndSpeedEditor.disable();
        }
        if (mode === SpeedEditorMode.SPECIFY_BLADE_END_SPEED) {
            rpmEditor.disable();
            bladeEndSpeedEditor.enable()
        }
    }, [mode]);

    let rpm: MeasureEditorValue;
    let bladeEndSpeed: MeasureEditorValue;
    if (mode === SpeedEditorMode.SPECIFY_RPM) {
        rpm = rpmEditor.value;
        if (rpm == null || impellerDiameter == null) {
            bladeEndSpeed = null;
        } else {
            bladeEndSpeed = calculateBladeEndSpeed(impellerDiameter, rpm); //  TODO use impeller diameter
        }
    } else if (mode === SpeedEditorMode.SPECIFY_BLADE_END_SPEED) {
        bladeEndSpeed = bladeEndSpeedEditor.value;
        if (bladeEndSpeed === null || impellerDiameter == null) {
            rpm = null;
        } else {
            rpm = calculateRpm(impellerDiameter, bladeEndSpeed); //  TODO use impeller diameter
        }
    } else {
        // AUTOSELECTION
        rpm = null;
        bladeEndSpeed = null;
    }

    // Propagate calculated value back to editor if changed
    useEffect(() => {
        if (mode === SpeedEditorMode.SPECIFY_RPM) {
            bladeEndSpeedEditor.populate(bladeEndSpeed);
        }
    }, [bladeEndSpeed])
    useEffect(() => {
        if (mode === SpeedEditorMode.SPECIFY_BLADE_END_SPEED) {
            rpmEditor.populate(rpm);
        }
    }, [rpm])

    const reset = () => {

        setMode(SpeedEditorMode.SPECIFY_RPM);
        rpmEditor.reset();
    }

    const prepopulate = (speedDetails: SpeedDetails) => {
        if (speedDetails === "speed-autoselection") {
            setMode(SpeedEditorMode.AUTOSELECT_SPEED);
        } else {
            setMode(SpeedEditorMode.SPECIFY_RPM);
            rpmEditor.populate(speedDetails.rpm);
        }
    }

    const speedDetails: SpeedDetails | "invalid-due-to-incorrect-impeller-diameter" = (mode === SpeedEditorMode.AUTOSELECT_SPEED) ? "speed-autoselection" : (rpm === null ? "invalid-due-to-incorrect-impeller-diameter" : {rpm: rpm});

    const editorComponent = <>
        <div className={"d-flex justify-content-end gap-2 mb-2"}>
            <button className={"btn btn-xxs " + (mode === SpeedEditorMode.SPECIFY_RPM ? "btn-primary" : "btn-secondary")}
                    onClick={() => setMode(SpeedEditorMode.SPECIFY_RPM)}>{t("Zadaj RPM")}
            </button>
            <button className={"btn btn-xxs " + (mode === SpeedEditorMode.SPECIFY_BLADE_END_SPEED ? "btn-primary" : "btn-secondary")}
                    onClick={() => setMode(SpeedEditorMode.SPECIFY_BLADE_END_SPEED)}>{t("Zadaj prędkość końca łopaty")}
            </button>
            <button className={"btn btn-xxs " + (mode === SpeedEditorMode.AUTOSELECT_SPEED ? "btn-success" : "btn-secondary")}
                    onClick={() => setMode(SpeedEditorMode.AUTOSELECT_SPEED)}>{t("Autodobór prędkości")}
            </button>
        </div>
        {mode !== SpeedEditorMode.AUTOSELECT_SPEED && <LabeledEditor label={t("Prędkość obrotowa")} editor={rpmEditor}/>}
        {mode !== SpeedEditorMode.AUTOSELECT_SPEED && <LabeledEditor label={t("Prędkość końca łopaty")} editor={bladeEndSpeedEditor}/>}
    </>;

    return {
        speedDetails,
        editorComponent,
        reset,
        prepopulate
    };
}

function calculateBladeEndSpeed(impellerDiameterMillimeters: number, rpm: number) {
    return Math.PI * (impellerDiameterMillimeters / 1000.0) * rpm / 60.0;
};

function calculateRpm(impellerDiameterMillimeters: number, bladeEndSpeed: number) {
    return 60 * bladeEndSpeed / (Math.PI * (impellerDiameterMillimeters / 1000))
};

export type MeasureOrPassiveOrAutoselectionEditorValue = { type: "passive", passiveValue: number | null } | { type: "entered", enteredValue: number | null } | { type: "autoselection" };

function useSpeedMeasureEditor(standard: MeasurementStandard, initiallyEnabled: boolean = true, onRequestToCalculate: () => void): OptionalEditor<MeasureEditorValue> {
    return useEditor<OptionalEditor<MeasureEditorValue>>(() =>
        new OptionalEditor<MeasureEditorValue>(
            createEditorForMeasurementStandard(standard),
            initiallyEnabled,
            (toggleEnablement, delegate) =>
                <>
                    <div className={"pt-2 pb-2"}>
                        {delegate.value} {standard.measuringOptions.baseUnit.displayText}
                    </div>
                </>,
            (DelegatedEditorComponent, toggleEnablement) =>
                <div className={"d-flex"}>
                    <div>
                        <DelegatedEditorComponent/>
                    </div>
                    <div className={"ps-3"}>

                    </div>
                </div>
        )
    )
}