import {NavLink} from "react-router-dom";
import React from "react";
import {OptiselEventJson} from "./OptiselEventHistoryPage";

export function OptiselEventTable({results,additionalActionComponents}: {
    results: OptiselEventJson[],
    additionalActionComponents?: (eventId: string) => JSX.Element
}) {

    if ( results.length === 0 ) {
        return <div className="alert alert-secondary texte-center">Brak przypisanych zdarzeń</div>;
    }
    return <table className={"table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3"}>
        <thead>
        <tr className="fw-bolder text-muted">
            <th className="w-100px">Wentylator / ID</th>
            <th className="min-w-140px">Autor / Źródło</th>
            <th className="min-w-120px">Utworzono</th>
            <th className="text-end">Akcje</th>
        </tr>
        </thead>
        <tbody>
        {results.map(result => <tr className={result.persistent ? "" : "opacity-50"}>
            <td className="align-top text-nowrap">
                <div className="text-gray-600 fs-8">{result.typeDisplayName}</div>
                <div className="fw-bold">{result.discriminatorName}</div>
                <div className="text-gray-300 fs-8">{result.id}</div>
            </td>
            <td className="align-top">
                <div>{result.performerDisplayName}</div>
                <div className={"text-gray-400"}>{result.sourceSystemName}</div>
            </td>
            {/*<td className="align-top">
                <span className={"badge badge-secondary p-2 text-nowrap"}>{result.caseIdentification.inquiryNumber ?? ""}</span>
                <span className={"badge badge-secondary p-2 text-nowrap"}>{result.caseIdentification.caseNumber ?? ""}</span>
                <span className={"badge badge-secondary p-2 text-nowrap"}>{result.caseIdentification.mechanicalDesignIdentifier ?? ""}</span>
            </td>*/}
            <td className="align-top">{new Date(result.creationMillis).toLocaleString("pl")}</td>
            <td className="align-top text-end">
                <NavLink to={`/optisel-event/${result.id}`} className={"btn btn-sm btn-light-primary"}>Zobacz</NavLink>
                {additionalActionComponents?.(result.id)}
            </td>
        </tr>)}
        </tbody>
    </table>;
}