import React from 'react';
import UboldInvalidFeedbackList from "../UboldInvalidFeedbackList";

export default function UboldSelect(props) {
    return <div className="row mt-2">
        <label className="col-4 col-form-label">{props.label}</label>
        <div className="col-8">
            <select className={"form-control" + (props.errors.length > 0 ? " is-invalid" : "")} onChange={props.onChange} value={props.value}>
                {props.options.map(opt => <option key={opt.value} value={opt.value}>{opt.label}</option>)}
            </select>
            <UboldInvalidFeedbackList errors={props.errors}/>
        </div>
    </div>;
}

export function UboldSelectInline(props) {
    return <div className="form-group mx-sm-1">
        <label className="mr-1">{props.label}</label>

        <select className={"form-control" + (props.errors.length > 0 ? " is-invalid" : "")} onChange={props.onChange} value={props.value}>
            {props.options.map(opt => <option key={opt.value} value={opt.value}>{opt.label}</option>)}
        </select>
        <UboldInvalidFeedbackList errors={props.errors}/>

    </div>;
}

UboldSelect.propTypes = {}

UboldSelect.defaultProps = {}