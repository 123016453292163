import React from 'react';
import DynamicWebServiceDrivenForm from "../../platform/webform/DynamicWebServiceDrivenForm";
import {StandardLayout} from "../../layout/StandardLayout";
import {Card} from "../../components/Card";
import {useNavigate} from "react-router";
import {useSearchParams} from "react-router-dom";

export function SetTranslationRulePage(props: {}) {

    var navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const query = searchParams.get("code") == null ? "" : "?code="+searchParams.get("code");
    return <>
        <Card header={"Ustaw tłumaczenie"}>
            <DynamicWebServiceDrivenForm dslUrl={"/api/translation-rule/form"+query}
                                         submissionUrl="/api/translation-rule/submit"
                                         submitLabel="Ustaw"
                                         successHandler={() => {
                                             //alert('Reguła tłumaczenia została ustawiona');
                                             navigate("/translation-rules");
                                             window.location.reload();
                                         }}/>
        </Card>
    </>
}
