import React from 'react';
import {cloneJson} from "../util/JsonObjectCloner";
import UboldInvalidFeedbackList from "../../ubold-components/UboldInvalidFeedbackList";

export function UBoldManyOfCheckbox(props) {

    function onCheckboxSelectionChange(e) {
        const val = e.target.value;
        if (e.target.checked) {
            let newValue = cloneJson(props.value);
            newValue.push(val);
            props.onChange(newValue);
        } else {
            let newValue = cloneJson(props.value);
            newValue = newValue.filter(item => item !== val);
            props.onChange(newValue);
        }
    }

    return <div className="row mb-4">
        <label className="col-12 col-md-4 col-form-label text-md-end">{props.label}</label>
        <div className="col-8 pt-2">
            {props.options.map(opt =>
                <div key={opt.value}>
                    <input type="checkbox" value={opt.value} onChange={onCheckboxSelectionChange}
                           checked={props.value.includes(opt.value)}/> {opt.label}
                </div>
            )}
            <UboldInvalidFeedbackList errors={props.errors}/>
        </div>
    </div>;
}

export default function ManyOfCheckboxWebFormComponent(props) {
    const dsl = props.dsl;

    function change(val) {
        const newDsl = cloneJson(dsl);
        newDsl.value = val;
        newDsl.errors = [];
        props.onDslChange(newDsl);
    }

    return <UBoldManyOfCheckbox label={dsl.configuration.label}
                                value={dsl.value}
                                errors={dsl.errors}
                                onChange={change}
                                options={dsl.configuration.options}/>
}

ManyOfCheckboxWebFormComponent.propTypes = {}

ManyOfCheckboxWebFormComponent.defaultProps = {}