import {BaseEditor} from "form/Editor";
import {MetronicMultiButtonManyOfEditor} from "../metronic/MetronicMultiButtonManyOfEditor";
import {SelectionKey, SelectionOption} from "./OneOfEditor";

export class ManyOfEditor extends BaseEditor<SelectionKey[]> {

    private _value: SelectionKey[];
    private readonly _options: SelectionOption[];
    private defaultValue: SelectionKey[];
    private readonly RenderComponent: (props: { editor: ManyOfEditor }) => JSX.Element;

    constructor(options: SelectionOption[], RenderComponent?: (props: { editor: ManyOfEditor }) => JSX.Element, value?: SelectionKey[]) {
        super();
        if (options.length === 0) {
            throw new Error("Select editor cannot be created without any options");
        }
        if (value !== undefined) {
            value.forEach(val => {
                if (!options.find(o => o.key === val)) {
                    throw new Error("Incorrectly created 'many of' editor - current value has invalid option");
                }
            })
        }

        this.RenderComponent = RenderComponent ?? MetronicMultiButtonManyOfEditor
        this._value = value !== undefined ? value : [];
        this._options = options;
        this.defaultValue = this._value;
    }

    get value(): SelectionKey[] {
        return this._value;
    }

    get options(): SelectionOption[] {
        return this._options;
    }

    populate(value: SelectionKey[]): void {
        value.forEach(val => {
            if (!this._options.find(o => o.key === val)) {
                console.warn("Discaring selection population, one of values in value not found in possible options", {
                    value,
                    options: this._options
                });
                return;
            }
        })

        this._value = value;
        this.notifyListeners();
    }

    toggle = (option: SelectionKey) => {
        if (this.value.includes(option)) {
            this.populate(this.value.filter(v => v != option));
        } else {
            this.populate([...this.value, option]);
        }
    }

    reset(): void {
        this._value = this.defaultValue;
        this.notifyListeners();
    }

    commit(): void {
    }

    Component = (): JSX.Element => {
        const ThisRenderComponent = this.RenderComponent;
        return <ThisRenderComponent editor={this}/>;
    }

    get errorList(): string[] {
        return [];
    }

    get isValid(): boolean {
        return true;
    }

}
