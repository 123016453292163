import {Calculation, resolveParamTranslation} from "../../business/core";
import React, {useState} from "react";


export function FormattedMechanicalSelectionCalculationLog({calculations}: { calculations: Calculation[] }): React.ReactElement {


    return <>
        {calculations.map(calc => <div className="row mb-3">
            <div className="col-3 text-end">
                <div className="badge badge-light-info text-wrap">{resolveParamTranslation(calc.humanFriendlyNameIk)}</div>
                {calc.unit !== "" ? <div className="text-gray-400">[{calc.unit}]</div> : ""}
            </div>
            <div className="col-9">
                <SingleCalculationLogFormatted calculation={calc}/>
            </div>
        </div>)}
    </>;

}

export interface LogSection {
    title: string,
    messages: string[];
}

function SingleCalculationLogFormatted({calculation}: { calculation: Calculation }): React.ReactElement {

    const [expandedSectionTitle, setExpandedSectionTitle] = useState<string | null>(null);
    const sections: LogSection[] = [];

    for (const log of calculation.logs) {
        let lastSection = sections.length > 0 ? sections[sections.length - 1] : null;

        if (lastSection == null || log.includes("[@form-start]")) {
            sections.push({
                title: log,
                messages: []
            });
            lastSection = sections[sections.length - 1];
            continue;
        }
        lastSection.messages.push(log);
    }

    return <>
        {sections.map((section, idx) =>
            <section>
                <h2>{section.title.replace("[@form-start]", "")} &nbsp;
                    {idx != 0 && <button className="btn btn-sm btn-primary" onClick={() => setExpandedSectionTitle(section.title)}>Szczegóły</button>}
                </h2>
                {(expandedSectionTitle === section.title || idx == 0) && section.messages.map(msg => <div className={msg.toLowerCase().includes("uwaga!") ? "text-danger fs-3 fw-bolder" : ""}>
                    {msg.replace("[@form-end]", "")}
                </div>)}
            </section>)}
    </>
}