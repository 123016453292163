import * as d3Selection from 'd3-selection';

// https://stackoverflow.com/questions/63165896/what-is-the-type-of-the-svg-element-in-typescript
export type SvgInHtml = HTMLElement & SVGElement;
export type D3SvgSelection = d3Selection.Selection<HTMLElement & SVGElement, unknown, null, undefined>;
export type D3GSelection = d3Selection.Selection<ElementTagNameMap["g"], unknown, null, undefined>;

export interface D3ChartRenderable {
    /**
     * @param svgSelection D3 selection of the whole SVG's .select
     * @param rootG D3 selection of root <g> SVG element that has APPLIED MARGINS TRANSFORM already
     */
    initialize: (svgSelection: D3SvgSelection, rootG: D3GSelection, unClippedRootG: D3GSelection, size: D3ChartSize) => void
    render: () => void
    onMouseMove?: (x: number, y: number) => "repaint" | "norepaint";
    onMouseOut?: () => "repaint" | "norepaint";
}

export interface D3ChartMargins {
    top: number,
    right: number,
    bottom: number,
    left: number
}

export interface D3ChartSize {
    innerWidth: number,
    innerHeight: number
}

export interface AxisTickManaging {
    getTickValues: () => number[]
}

export class D3Chart {

    /**
     * A main "G" svg element that will have margins applied and likely all chart elements will be appened to it, to
     * @private
     */
    private rootG: D3GSelection;
    private unClippedRootG: D3GSelection;

    constructor(private svgSelection: D3SvgSelection,
                private renderables: D3ChartRenderable[],
                public readonly margins: D3ChartMargins,
                public readonly size: D3ChartSize) {
        this.unClippedRootG = svgSelection.append("g");
        this.unClippedRootG.attr("transform", `translate(${margins.left},${margins.top})`)

        this.rootG = svgSelection.append("g");
        this.rootG.attr("transform", `translate(${margins.left},${margins.top})`)
    }

    static createOnSvgInHtml(svgInHtml: SvgInHtml,
                             renderables: D3ChartRenderable[],
                             margins: D3ChartMargins,
                             size: D3ChartSize): D3Chart {
        return new D3Chart(d3Selection.select(svgInHtml), renderables, margins, size);
    }

    initialize() {
        this.renderables.forEach(renderable => {
            renderable.initialize(this.svgSelection, this.rootG, this.unClippedRootG, this.size);
        })

        // Note without transparent rect in background, mouseout would fire every time cursor is not over some exact element drawn on the graph
        this.rootG.append("rect")
            .attr('data-meta', 'transparent-inner-rect-for-mouseout-handling')
            .attr('x', 0)
            .attr('y', 0)
            .attr('width', this.size.innerWidth)
            .attr('height', this.size.innerHeight)
            .attr('stroke', 'transparent')
            .attr('fill', 'transparent');

        const clipPathId = Math.random() + ""; // TODO improve, avoid collision for sure
        this.rootG.append("clipPath")
            .attr("id", clipPathId)
            .append("rect")
            .attr('x', 0)
            .attr('y', 0)
            .attr('width', this.size.innerWidth)
            .attr('height', this.size.innerHeight);

        this.rootG.attr("clip-path", "url(#" + clipPathId + ")");


        // @ts-ignore
        this.svgSelection.on("pointermove", this.handleMouseMoveOnRootG)
        this.svgSelection.on("mouseout", this.handleMouseOutOnRootG)

    }

    handleMouseMoveOnRootG = (event: any) => { // Arrow func to preserve this
        const svgCoords: [number, number] = d3Selection.pointer(event);
        const marginAdjustedCoords = [svgCoords[0] - this.margins.left, svgCoords[1] - this.margins.top];
        let repaintNeeded = false;
        this.renderables.forEach(renderable => {
            if (renderable.onMouseMove?.(marginAdjustedCoords[0], marginAdjustedCoords[1]) === "repaint") {
                repaintNeeded = true;
            }
        })
        if (repaintNeeded) {
            this.render();
        }
    }

    handleMouseOutOnRootG = () => {
        let repaintNeeded = false;
        this.renderables.forEach(renderable => {
            if (renderable.onMouseOut?.() === "repaint") {
                repaintNeeded = true;
            }
        })
        if (repaintNeeded) {
            this.render();
        }
    }

    render() {
        this.renderables.forEach(renderable => {
            renderable.render();
        })
    }
}
