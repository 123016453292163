import React, {RefObject, useEffect, useRef, useState} from "react";
import {D3Chart, D3SvgSelection, SvgInHtml} from "../platform/d3chart/D3Chart";
import * as d3Selection from "d3-selection";


export function LegacyLocatedSplChart(props: {
    operation: "induced-ssacy" | "forced-tloczacy",
    housingDiameterMilimeter: number,
    pwlTot: number
}) {

    // MOST OF THE CODE IMPORTED FROM OPTISEL 1 chart-akustyka file

    const [splText, setSplText] = useState("");
    const chartRef = useRef<D3Chart | null>(null);
    const svgRef = React.useRef<SvgInHtml>();

    const akustykaDistanceScale = props.operation == "induced-ssacy" ? 1 / 55.0 : 1 / 53.25;
    const width = props.operation == "induced-ssacy" ? 587 : 587;
    const height = props.operation == "induced-ssacy" ? 582 : 582;
    const centerPointX = props.operation == "induced-ssacy" ? 297 : 298;
    const centerPointY = props.operation == "induced-ssacy" ? 298 : 256;
    const imagePath = props.operation == "induced-ssacy" ? "/img/legacy/wykresakustyka_ssacy.png" : "/img/legacy/wykresakustyka_tloczacy.png";
    const indicatorVisibilityPart = props.operation == "induced-ssacy" ? 'top' : 'bottom';

    const housingDiameter = props.housingDiameterMilimeter;
    const pwlTot = props.pwlTot;

    function log10(arg: number) {
        return Math.log(arg) / Math.LN10;
    }

    const processRelativeClickedPoint = function (deltaX: number, deltaY: number) {
        if (deltaX < 0) {
            deltaX = -deltaX;
        }
        if (deltaY < 0) {
            deltaY = -deltaY;
        }


        var distanceScale = akustykaDistanceScale;
        console.log("var distanceScale = akustykaDistanceScale;");
        console.log("= " + distanceScale);

        var d = Math.sqrt(deltaX * deltaX + deltaY * deltaY) * distanceScale;
        console.log("d = Math.sqrt(deltaX * deltaX + deltaY * deltaY) * distanceScale;");
        console.log("d =" + d);


        var rad = Math.atan2(deltaY, deltaX);
        console.log("rad = Math.atan2(deltaY, deltaX);");
        console.log("rad = " + rad);

        var st = 57.2957795 * rad;
        console.log("st = 57.2957795 * rad;");
        console.log("st = " + st);

        var sre = housingDiameter / 1000.0;
        console.log("sre = housingDiameter / 1000.0;");
        console.log("sre = " + sre);

        var kliknietaIloscSrednicOdCentrumWMetrach = d / 5.0;
        console.log("kliknietaIloscSrednicOdCentrumWMetrach = d / 5.0;");
        console.log("kliknietaIloscSrednicOdCentrumWMetrach = " + kliknietaIloscSrednicOdCentrumWMetrach);

        var kliknietaOdlegloscOdCentrumWMetrach = kliknietaIloscSrednicOdCentrumWMetrach * sre;
        console.log("kliknietaOdlegloscOdCentrumWMetrach = kliknietaIloscSrednicOdCentrumWMetrach * sre;");
        console.log("kliknietaOdlegloscOdCentrumWMetrach = " + kliknietaOdlegloscOdCentrumWMetrach);

        var wejsciowaMocAkustyczna = pwlTot;

//    console.log("wejsciowaMocAkustyczna = "+wejsciowaMocAkustyczna);
//    console.log("kliknietaOdlegloscOdCentrumWMetrach = "+kliknietaIloscSrednicOdCentrumWMetrach);
//    console.log("sre = "+sre);
//    console.log("kliknietaOdlegloscOdCentrumWMetrach = "+kliknietaOdlegloscOdCentrumWMetrach);
        //alert(kliknietaOdlegloscOdCentrumWMetrach);

        var pro = sre / 2.0;
        console.log("pro = sre / 2.0;")
        console.log("pro = " + pro)

        var dl = (d * sre) / 5.0;
        console.log("dl = (d * sre) / 5.0;")
        console.log("dl = " + dl)

        var dodi = "";
        var wart = pwlTot - 10 * log10(2 * Math.PI * kliknietaOdlegloscOdCentrumWMetrach * kliknietaOdlegloscOdCentrumWMetrach);
        console.log("pwlTOt = "+pwlTot);
        console.log("wart = pwlTot - 10 * log10(2 * Math.PI * kliknietaOdlegloscOdCentrumWMetrach * kliknietaOdlegloscOdCentrumWMetrach);");
        console.log("wart = " + wart);

        dodi += "SPL =  " + (wart).toFixed(1) + " dB(A) (" + dl.toFixed(2) + " m)";
        console.log(dodi);
        setSplText(dodi);

        /**
         * //alert("d="+d+", rad="+rad+", st="+st);
         // SPLX=PWL -10*log10(2*pi*R^2)
         //var sre = {/literal}{$wypis.srednica_wirnika}{literal}/1000;
         //var pro = sre/2;
         var dl = parseFloat(d/5);
         var dodi = "";
         var wart = parseFloat({/literal}{$cmoca}{literal}) - 10 * log10 ( 2*Math.PI*dl*dl );
         dodi += "SPL =  "+(wart).toFixed(1)+" {/literal}{$l_jednostka_dba}{literal} ("+dl.toFixed( 2 )+" {/literal}{$l_jednostka_m}{literal})<br>";
         $("#clickdiv2").html( "<div style='margin-left: 100px; margin-top: 10px; font-size: 18px; margin-right: 100px; padding: 5px; border: 1px solid #555;'>"+dodi +"</div>" );
         */


    };


    /*

        var akustykaPlot;
    if ( akustykaSsacy ) {
        document.akustykaDistanceScale = 1/55;
        akustykaPlot = new AkustykaPlot("rys/wykresakustyka_ssacy.png", 'top' , 587, 582, 297, 298, document.processRelativeClickedPoint);
    } else {
        document.akustykaDistanceScale = 1/53.25;
        akustykaPlot = new AkustykaPlot("rys/wykresakustyka_tloczacy.png", 'bottom', 587, 582, 298, 256, document.processRelativeClickedPoint);
    }
     */


    useEffect(() => {
        if (svgRef.current === undefined) {
            return;
        }
        const svgSelection: D3SvgSelection = d3Selection.select(svgRef.current);
        const d3RootG = svgSelection.append("g");

        d3RootG.append('image')
            .attr('xlink:href', imagePath)
            .attr("x", "0")
            .attr("y", "0")
            .attr("width", width)
            .attr("height", height);

        d3RootG.append('line')
            .attr('class', 'lineindicator')
            .attr("stroke-width", 2)
            .attr("stroke", 'black')
            .attr('x1', centerPointX)
            .attr('x2', 0)
            .attr('y1', centerPointY)
            .attr('y2', 0)
            .attr('opacity', '0');

        d3RootG.append('line')
            .attr('class', 'selectedPointIndicator')
            .attr("stroke-width", 3)
            .attr("stroke", 'blue')
            .attr('x1', centerPointX)
            .attr('x2', 0)
            .attr('y1', centerPointY)
            .attr('y2', 0)
            .attr('opacity', '0');

        d3RootG.on('mousemove', function (event: any) {
            const coords: [number, number] = d3Selection.pointer(event);

            var mouseXPos = coords[0];
            var mouseYPos = coords[1];


            d3RootG.selectAll('.lineindicator')
                .attr('x1', centerPointX)
                .attr('x2', mouseXPos)
                .attr('y1', centerPointY)
                .attr('y2', mouseYPos);


            if (mouseYPos < centerPointY && indicatorVisibilityPart == 'top') {
                d3RootG.selectAll('.lineindicator').attr('opacity', '1');
            } else {
                if (mouseYPos > centerPointY && indicatorVisibilityPart == 'bottom') {
                    d3RootG.selectAll('.lineindicator').attr('opacity', '1');
                } else {
                    d3RootG.selectAll('.lineindicator').attr('opacity', '0');
                }
            }
        });

        svgSelection.on('mouseleave', function () {
            d3RootG.selectAll('.lineindicator').attr('opacity', '0');
        });


        d3RootG.on('click', function (event: any) {
            const coords: [number, number] = d3Selection.pointer(event);
            var mouseXPos = coords[0];
            var mouseYPos = coords[1];

            if (mouseYPos < centerPointY && indicatorVisibilityPart == 'bottom') {
                return;
            }
            if (mouseYPos > centerPointY && indicatorVisibilityPart == 'top') {
                return;
            }

            d3RootG.selectAll('.selectedPointIndicator')
                .attr('opacity', '1')
                .attr('x1', centerPointX)
                .attr('x2', mouseXPos)
                .attr('y1', centerPointY)
                .attr('y2', mouseYPos);

            processRelativeClickedPoint(mouseXPos - centerPointX, mouseYPos - centerPointY);
        });


    }, []);

    return <>
        <div className={"text-center"}>
            <svg
                ref={svgRef as unknown as RefObject<SVGSVGElement>}
                style={{
                    width: width,
                    height: height,
                    margin: "0 0 0 0"
                }}>
            </svg>
        </div>
        <div className={"text-center fw-boldest"}>{splText}</div>
    </>;


}