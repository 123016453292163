import AjaxService from "../../platform/ajax/AjaxService";
import {OptiselEventJson} from "../optiselevent/OptiselEventHistoryPage";
import {useFetchAllObjs, useFetchAllObjsWithRefetchCapability} from "../../platform/hooks/PlatformTypeScriptHooks";


export const callApiAttachOptiselEventToIssue = (optiselEventId: string, issueId: string) => {
    AjaxService.postJson(`/api/issue/${issueId}/attach-event?eventId=${optiselEventId}`, {}).then(result => {

        console.log("Received  response", result);

    }).finally(() => {

    });
}

export const callApiDetachOptiselEventToIssue = (optiselEventId: string, issueId: string) => {
    return AjaxService.postJson(`/api/issue/${issueId}/detach-event?eventId=${optiselEventId}`, {}).then(result => {

        console.log("Received  response", result);

    }).finally(() => {

    });
}


export const useAllIssueAttachedOptiselEvents = (issueId: string): { result: OptiselEventJson[] | "loading"; refetch: () => void } => {
    const {refetch, result} = useFetchAllObjsWithRefetchCapability<OptiselEventJson[]>(`/api/issue/${issueId}/attached-events`);
    return {
        result: result ?? "loading",
        refetch
    }
}