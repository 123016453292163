    import React, {useEffect} from 'react';
import {StandardLayout} from "../../layout/StandardLayout";
import UboldSpinner from "../../ubold-components/UboldSpinner";

import {Card} from "../../components/Card";
import {createAsyncResultsProviderViaApiWithGetParams, useSearch, useTextFilterManager} from "../../platform/search/PlatformSearch";
import {CurrentlyUsedFilters, SearchQueryBar} from "../../components/search/MetronicSearchComponents";
import {useParams} from "react-router";

export interface MechanicalDesignShortInfo {
    identifier: string,
    fanName: string,
    inquiryNumber: string,
    requestSource: string,
    creationInstantMillis: number,
    authorName: string,
    caseNumber: string
}

export function BrowseMechanicalComponentsPage(props: {}) {
    const queryFilterManager = useTextFilterManager("query", "Szukaj");

    const {datatype} = useParams();

    const {
        currentlyActiveFilterManagers,
        refresh,
        isLoading,
        results,
        reset,
        currentOrderId,
        changeOrderId,
        paginationDriver: {
            currentPage,
            switchToNextPage,
            nextPageSwitchAvailable,
        }
    } = useSearch<any, any>(
        createAsyncResultsProviderViaApiWithGetParams<any>(`/api/mechanical-component/${datatype}/get-all`), // TODO why Can I create useSearct<any,_concrete> with supplier of <any>
        [queryFilterManager],
        [],
        1000);

    let headers: string[] = [];

    if (results && results.length > 0) {
        for (const attr of Object.keys((results[0] as unknown as object))) {
            headers.push(attr as unknown as string);
        }
    }

    return <>
        <Card className={"card mb-4"}>
            <SearchQueryBar queryFilterManager={queryFilterManager} reset={reset} refresh={refresh}/>
            <div className="separator separator-dashed mt-6 mb-4"/>
            <div className="row">

            </div>
        </Card>
        <Card>
            <div className={"d-flex mb-2"}>
                <div className={"flex-grow-1"}>
                    <CurrentlyUsedFilters currentlyActiveFilterManagers={currentlyActiveFilterManagers}/>
                </div>

            </div>

            <div>
                {isLoading ? <div className="text-center mt-5 mb-5"><UboldSpinner/></div> : null}
            </div>
            {!isLoading && results.length === 0 && <div className="text-center fs-4 text-gray-600 mt-5 mb-5">Brak rezultatów</div>}

            {!isLoading && results.length > 0 && <div className={"table-responsive"}>
                <table className={"table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3"}  style={{maxHeight:"200px", overflow: "scroll"}}>
                    <thead>
                    <tr className="fw-bolder text-muted"  style={{position:"sticky"}}>
                        {headers.map(h => <th>{h}</th>)}
                    </tr>
                    </thead>
                    <tbody>
                    {results.map(result => <tr>
                        {headers.map(h => <td>{result[h]}</td>)}
                    </tr>)}
                    </tbody>
                </table>

                {nextPageSwitchAvailable && <div>
                    <span className={"text-muted me-4"}>Strona {currentPage}</span>
                    <button className={"btn btn-outline btn-outline-dashed btn-sm"} onClick={switchToNextPage}>Szukaj więcej</button>
                </div>}
            </div>}
        </Card></>;

}

BrowseMechanicalComponentsPage.propTypes = {}

BrowseMechanicalComponentsPage.defaultProps = {}
