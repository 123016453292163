import React, {useState} from 'react';
import {useNavigate, useParams} from "react-router";
import UBoldModal from "../../ubold-components/UBoldModal";
import DynamicWebServiceDrivenForm from "../../platform/webform/DynamicWebServiceDrivenForm";
import {FORMATTED_ROLE_INFO} from "./AddRolePage";
import {createInternationalizationPrefixer} from "../i18n/i18n";
import {Card} from "../../components/Card";

const t = createInternationalizationPrefixer("AddRolePage");

export default function EditRolePage(props) {

    const [showSuccess, setShowSuccess] = useState(false);
    const {id} = useParams();
    const navigate = useNavigate();

    return <React.Fragment>
        <UBoldModal header={t('Sukces')} opened={showSuccess} closeable={false}>
            <div className="text-center">
                <div className={"h3 mb-3"}>{t('EditRolePage:Rola została zapisana')}</div>
                <button className="btn btn-success" onClick={() => navigate('/roles')}>{t('OK')}</button>
            </div>
        </UBoldModal>
        <div className="row justify-content-center">
            <div className="col-xl-9 col-lg-9 col-md-12 col-sm-12">
                <Card header={t('Edytuj rolę')}>

                    <DynamicWebServiceDrivenForm dslUrl={"/api/role/" + id + "/form"}
                                                 submitLabel={"Zapisz"}
                                                 submissionUrl={"/api/role/" + id + "/update"}
                                                 successHandler={() => setShowSuccess(true)}/>

                </Card>
            </div>
            <div className=" col-xl-3 col-lg-3 col-md-12 col-sm-12">
                <div className=" card">
                    <div className=" card-body">
                        <h4 className="card-title text-success"><i className="fe-info fe-xl"></i> {t('Informacje')}</h4>
                        {FORMATTED_ROLE_INFO}
                    </div>
                </div>
            </div>
        </div>

    </React.Fragment>;
}

EditRolePage.propTypes = {}

EditRolePage.defaultProps = {}