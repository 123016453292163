import React from 'react';
import {cloneJson} from "../util/JsonObjectCloner";
import UboldSelect from "../../ubold-components/form/UboldSelect";

export default function OneOfWebFormComponent(props) {
    const dsl = props.dsl;

    function change(e) {
        const newDsl = cloneJson(dsl);
        newDsl.value = e.target.value;
        newDsl.errors = [];
        props.onDslChange(newDsl);
    }

    return <UboldSelect label={dsl.configuration.label} value={dsl.value} errors={dsl.errors} onChange={change} options={dsl.configuration.options}/>
}

OneOfWebFormComponent.propTypes = {}

OneOfWebFormComponent.defaultProps = {}