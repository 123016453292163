import * as d3Scale from "d3-scale";
import * as d3Axis from "d3-axis";
import {AxisTickManaging, D3ChartRenderable, D3ChartSize, D3GSelection, D3SvgSelection} from "./D3Chart";
import * as d3Selection from "d3-selection";


export class D3ChartAxis implements D3ChartRenderable, AxisTickManaging {

    public scale: d3Scale.ScaleLinear<number, number, never>;
    private g: D3GSelection | null = null;
    private tickValues: number[] = [];
    private labelG: d3Selection.Selection<SVGTextElement, unknown, null, undefined> | null = null;

    constructor(public domain: number[], // WHY TUPLE NOT WORKING??? [number,number]
                public range: number[],
                private type: "left" | "bottom" | "right",
                public readonly ticks: number = 10,
                public className: string = "",
                public readonly additionalYOffset: number = 0,
                public readonly additionalXOffset: number = 0,
                public readonly label: string = "") {
        this.scale = d3Scale.scaleLinear().domain(domain).range(range);
    }

    initialize(svgSelection: D3SvgSelection, rootG: D3GSelection, unClippedRootG: D3GSelection, size: D3ChartSize): void {
        this.g = unClippedRootG.append("g").attr('class', this.className);
        this.labelG = unClippedRootG.append("text")
            .attr("class", "axis-label-marker")
            .text(this.label)
            .attr("font-size", "13px")
            .attr("font-weight", "600")
            .attr("fill","rgb(150,150,150)");
        if (this.type === "bottom") {
            // Bottom axis must be moved down for the height of the whole chart, to not be drawn on the top of the chart
            this.g.attr("transform", `translate(${this.additionalXOffset}, ${size.innerHeight + this.additionalYOffset})`)
            this.labelG.attr("x", size.innerWidth  - 5); // text anchor end is still not enough so added marigin
            this.labelG.attr("y" , size.innerHeight - 5);
            this.labelG.attr("text-anchor", "end");

            // Dirty hack by css class to not draw arrow on "hidden" line
            if ( this.className !== "d3-axis-nodomainline") {
                this.g.append("path")
                    .attr("transform", `translate(${size.innerWidth - 5}, ${0})`)
                    .attr("d", "M0,-5L10,0L0,5")
                    .attr("fill", "rgb(200,200,200)");
            }

        } else {
            this.g.attr("transform", `translate(${this.additionalXOffset}, ${this.additionalYOffset})`)
            this.labelG.attr("x", 5); // 5 "into" the chart area, never moved with axis additonal offset (keep in chart area)
            this.labelG.attr("y", this.range[0] + 5); // hanging baseline is still not enough so added margin
            this.labelG.attr("dominant-baseline", "hanging");

            // Dirty hack by css class to not draw arrow on "hidden" line
            if ( this.className !== "d3-axis-nodomainline") {
                this.g.append("path")
                    .attr("transform", ` translate(${0}, ${10}) rotate(-90 ,0,0)`)
                    .attr("d", "M0,-5L10,0L0,5")
                    .attr("fill", "rgb(200,200,200)");
            }
        }



    }

    render(): void {
        this.tickValues = this.scale.ticks(this.ticks);
        if (this.g === null) {
            return;
        }
        if (this.type === "bottom") {
            this.g.transition().duration(22).call(d3Axis.axisBottom(this.scale).tickValues(this.tickValues));

        }
        if (this.type === "left") {
            this.g.transition().duration(22).call(d3Axis.axisLeft(this.scale).tickValues(this.tickValues))
        }


    }

    getTickValues(): number[] {
        return this.tickValues;
    }

}