import React from 'react';
import {NavLink} from "react-router-dom";
import {StandardLayout} from "../../layout/StandardLayout";
import {Card} from "../../components/Card";
import * as AdjustableImpellerService from "../impeller/AdjustableImpellerService";
import UboldSpinner from "../../ubold-components/UboldSpinner";


export function BladeProfilesPage(props: any) {
    const profiles = AdjustableImpellerService.useFetchBladePorofilesReport();

    const onDeleteBladeProfileClicked = (id: string) => {
        AdjustableImpellerService.deleteBladeProfile(id);
        alert("Profil łopatowy został usunięty");
        profiles.refetch();
    }


    if (profiles.result === null) {
        return <><UboldSpinner/></>;
    }

    const profileElements = profiles.result === null ? null : profiles.result.entries.map(entry => <tr>
        <td key={entry.name} className="align-top">
            <div className="d-flex flex-center flex-shrink-0 bg-light rounded w-70px h-70px">
                <img className="w-70px h-70px" src={entry.thumbnailUrl} alt="profile thumbnail"/>
            </div>
        </td>
        <td className="align-top">
            <div>
                <a href="#/" className="text-dark fw-bolder text-hover-primary fs-2x">{entry.displayName}</a>
            </div>
            <div className={"text-muted"}>
                β +{entry.betaAngleIncrementation}°
            </div>

        </td>
        <td className="align-top">
            <a href="#/" className="text-dark fw-bolder text-hover-primary d-block mb-1">{entry.minDiameterLimit.toFixed(0)} - {entry.maxDiameterLimit.toFixed(0)} mm</a>
            <span className="text-muted fw-bold text-muted d-block fs-7">limitowany [mm]</span>

        </td>
        <td className="align-top">
            <a href="#/" className="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6">{entry.minBladeEndSpeedLimit.toFixed(1)} - {entry.maxBladeEndSpeedLimit.toFixed(1)}</a>
            <span className="text-muted fw-bold text-muted d-block fs-7">końca łopaty, limitowany [m/s]</span>
            <a href="#/" className="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6">{entry.minBladeAngleLimit.toFixed(1)} - {entry.maxBladeAngleLimit.toFixed(1)}</a>
            <span className="text-muted fw-bold text-muted d-block fs-7">dodatkowy limit na kat łop. [°]</span>
        </td>
        <td className="align-top">
            {entry.statistics.map(stat => <div>
                <span className="text-dark fw-bolder text-hover-primary mb-1 fs-6">{stat.bladeCount}</span> <span className="text-muted fw-bold text-muted fs-7">({stat.diameterAgnosticImpellerName} {stat.minBladeAngle.toFixed(1)}&deg; - {stat.maxBladeAngle.toFixed(1)}&deg;)</span>
            </div>)}
        </td>
        <td className="text-end align-top">
            {entry.demonstrational && <div className={"badge badge-light-danger mt-2"}>Demo (nieedytowalny)</div>}
            {!entry.demonstrational && <div>
                <NavLink to={`/edit-blade-profile/${entry.id}`} className="btn btn-bg-light btn-active-color-primary btn-sm me-1">
                    <i className="fas fa-edit"></i>
                    Edytuj
                </NavLink>
                <button className="btn btn-bg-light btn-active-color-primary btn-sm me-1" onClick={() => onDeleteBladeProfileClicked(entry.id)}>
                    <i className="fas fa-trash-alt"></i>
                    Usuń
                </button>
            </div>}
        </td>
    </tr>);

    return <>

        <Card header="Profile łopatowe" subHeader="Profile łopatowe wraz z ich ustawieniami"
              toolbarElements={<NavLink to={"/create-blade-profile"} className={"btn btn-sm btn-primary"}><i className={"fas fa-plus-circle"}/> Utwórz profil</NavLink>}>

            <div className={"alert alert-secondary mb-5 p-3"}><i className="fas fa-info-circle"></i> Niektóre profile są zdefiniowane na stałe jako demonstracyjne (Demo) i niezmienialne, w celu uzyskania zawsze dostępnego, powtarzalnego przykładu podczas rozwoju aplikacji.</div>
            <div className={"alert alert-secondary mb-5 p-3"}><i className="fas fa-info-circle"></i> Funkcjonalność wgrywania obrazów miniatur dla profili nie jest zaimplementowana, dlatego używana jest zawsze ta sama, przykładowa grafika.</div>
            <div className={"table-responsive"}>
                <table className={"table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3"}>
                    <thead>
                    <tr className="fw-bolder text-muted">
                        <th className="w-100px">Obraz</th>
                        <th className="min-w-140px">
                            <div>Nazwa</div>
                            <div>Inkr. kąta na znaczniku</div>

                        </th>
                        <th className="min-w-120px">Zakres średnic</th>
                        <th className="min-w-120px">Zakres prędkości i kąta</th>
                        <th className="min-w-120px">Konfiguracje łopatowe</th>
                        <th className="min-w-100px text-end">Akcje</th>
                    </tr>
                    </thead>
                    <tbody>
                    {profileElements}

                    </tbody>

                </table>
            </div>
        </Card>


    </>
        ;
}

BladeProfilesPage.propTypes = {}

BladeProfilesPage.defaultProps = {}

