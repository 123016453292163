import {D3ChartRenderable, D3ChartSize, D3GSelection, D3SvgSelection} from "../platform/d3chart/D3Chart";
import * as d3Scale from "d3-scale";


export interface EfficiencyHeatMapEntry {
    flow: number,
    pressure: number,
    efficiency: number
}

export class EfficiencyHeatMapD3ChartRenderable implements D3ChartRenderable {

    private g: D3GSelection | undefined;
    private rects: number[][] = [[]];
    private rectsAsObj: { x: number, y: number, power: number }[] = [];
    private xDomainCellSize: number = -1;
    private yDomainCellSize: number = -1;
    private xPixelCellSize: number = -1;
    private yPixelCellSize: number = -1;

    constructor(public scaleX: d3Scale.ScaleLinear<number, number, never>,
                public scaleY: d3Scale.ScaleLinear<number, number, never>,
                public readonly xCellCount: number,
                public readonly yCellCount: number,
                private entries: EfficiencyHeatMapEntry[]) { // TODO parametrized strategy e.g. avg or max
    }

    initialize(svgSelection: D3SvgSelection, rootG: D3GSelection, unClippedRootG: D3GSelection, size: D3ChartSize): void {
        this.g = rootG.append("g");

        // WARNING - calculations are being made in initializ phase, becasue scale domains/ranges could be modified by scale manager at first render initialization !

        this.xDomainCellSize = (Math.max(this.scaleX.domain()[0], this.scaleX.domain()[1]) - Math.min(this.scaleX.domain()[0], this.scaleX.domain()[1])) / this.xCellCount;
        this.yDomainCellSize = (Math.max(this.scaleY.domain()[0], this.scaleY.domain()[1]) - Math.min(this.scaleY.domain()[0], this.scaleY.domain()[1])) / this.yCellCount;

        this.xPixelCellSize = (Math.max(this.scaleX.range()[0], this.scaleX.range()[1]) - Math.min(this.scaleX.range()[0], this.scaleX.range()[1])) / this.xCellCount;
        this.yPixelCellSize = (Math.max(this.scaleY.range()[0], this.scaleY.range()[1]) - Math.min(this.scaleY.range()[0], this.scaleY.range()[1])) / this.yCellCount;


        this.rects = [];
        for (let xIdx = 0; xIdx < this.xCellCount; xIdx++) {
            for (let yIdx = 0; yIdx < this.yCellCount; yIdx++) {
                this.rects[xIdx] = [];
                this.rects[xIdx][yIdx] = 0;
            }
        }

        this.entries.forEach(entry => {
            const xIdx = Math.floor(entry.flow / this.xDomainCellSize);
            const yIdx = Math.floor(entry.pressure / this.yDomainCellSize);
            this.rects[xIdx][yIdx] = Math.max(this.rects[xIdx][yIdx] || 0, entry.efficiency);
        })

        this.rectsAsObj = [];
        for (let xIdx = 0; xIdx < this.xCellCount; xIdx++) {
            for (let yIdx = 0; yIdx < this.yCellCount; yIdx++) {
                this.rectsAsObj.push({x: xIdx, y: yIdx, power: (typeof this.rects[xIdx][yIdx] === 'undefined' ? 0 : this.rects[xIdx][yIdx])});
            }
        }

        var color = d3Scale.scaleLinear()
            .domain([0, 1]) // Number of points in the bin?
            // @ts-ignore
            .range(["transparent", "#00770044"])

        this.g?.selectAll("rect")
            .data(this.rectsAsObj)
            .enter().append("rect");

        this.g?.selectAll("rect")
            .data(this.rectsAsObj)
            .attr("x", d => {
                return this.scaleX(d.x * this.xDomainCellSize)
            })
            .attr("y", d => {
                return this.scaleY(d.y * this.yDomainCellSize) - this.yPixelCellSize;
            })
            .attr("width", this.xPixelCellSize)
            .attr("height", this.yPixelCellSize)
            .attr("fill", d => {
                return color(d.power);
            })
    }

    render(): void {



    }

}