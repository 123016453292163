import React from 'react';
import {GeneralSimulationResultJson, MechanicalDesignCraftingResultJson, PinLoadDetailsJson, ResonancesAtSpeedJson} from "../../business/useImpellerSimulation";
import {ResonanceCampbellChart} from "./ResonanceCampbellChart";
import {calculationToParamCardTr, hasCalculationComplexResult, ParamCard, ParamCardTr} from "./SimulationDatasheet";
import {CalculationExplainer} from "./CalculationExplainer";
import {Card} from "../Card";

export function MechanicalSimulationReport({simulationResult}: { simulationResult: GeneralSimulationResultJson }) {
    const trsForSimpleMechanicalAssemblyParams = simulationResult.calculations.filter(c => c.tag === "mechanical-assembly" && !hasCalculationComplexResult(c)).map((c) => calculationToParamCardTr(c, simulationResult.calculations));
    const trsForMechanicalSolutionParams = simulationResult.calculations.filter(c => c.tag === "mechanical-solution" && !hasCalculationComplexResult(c)).map((c) => calculationToParamCardTr(c, simulationResult.calculations));
    const trsForComplexMechanicalAssemblyParams = simulationResult.calculations.filter(c => (c.tag === "mechanical-assembly" || c.tag === "mechanical-solution") && hasCalculationComplexResult(c)).map((c) => calculationToParamCardTr(c, simulationResult.calculations));

    const workingPointResonances = simulationResult.mechanicalDesignCraftingResult?.resonanceReport.workingPointResonances as ResonancesAtSpeedJson;
    const pinLoadDetails = simulationResult.mechanicalDesignCraftingResult?.pinLoadDetails as PinLoadDetailsJson;

    return <div className={"row g-6 g-xl-9 mb-6"}>
        <div className="col-6">
            <ParamCard header={"Parametry mechaniczne - złożenie"} subheader={"Obliczania związane ze złożeniem mechanicznym wirnika"} trs={trsForSimpleMechanicalAssemblyParams}/>
            <ParamCard header={"Parametry mechaniczne - obciążenia podstawowe"} subheader={"Obliczenia symulacji mechanicznej"} trs={trsForMechanicalSolutionParams}/>


        </div>
        <div className="col-6">
            <Card header={"Wykres rezonansów"} additionalClassNames={"card mb-6 mb-xl-9 mt-1"}>
                <ResonanceCampbellChart
                    resonanceCurve={(simulationResult.mechanicalDesignCraftingResult as MechanicalDesignCraftingResultJson).resonanceReport.resonanceCurve}
                    workingPointResonances={(simulationResult.mechanicalDesignCraftingResult as MechanicalDesignCraftingResultJson).resonanceReport.workingPointResonances}/>
            </Card>


            <ParamCard header={"Parametry rezonansowe"} subheader={"Parametry rezonansowe punktu pracy"} trs={[
                <ParamCardTr name={"Prędkość obrotowa"} value={workingPointResonances.rpm} unit={"[min-1]"}  extras={<CalculationExplainer calculations={simulationResult.calculations} explainedCalculationCode={"resonanceReport"}/>}/>,
                <ParamCardTr name={"Częstotliwość drgań łopaty"} value={workingPointResonances.bladeResonanceFrequency.toFixed(2)} unit={"[Hz]"}  extras={<CalculationExplainer calculations={simulationResult.calculations} explainedCalculationCode={"resonanceReport"}/>}/>,
                <ParamCardTr name={"fDyn"} value={workingPointResonances.fDynFrequency.toFixed(2)} unit={"[Hz]"}  extras={<CalculationExplainer calculations={simulationResult.calculations} explainedCalculationCode={"resonanceReport"}/>}/>,
                <ParamCardTr name={"RF"} value={workingPointResonances.rfFrequency.toFixed(2)} unit={"[Hz]"}  extras={<CalculationExplainer calculations={simulationResult.calculations} explainedCalculationCode={"resonanceReport"}/>}/>,
                <ParamCardTr name={"RFx2"} value={workingPointResonances.rfDoubledFrequency.toFixed(2)} unit={"[Hz]"}  extras={<CalculationExplainer calculations={simulationResult.calculations} explainedCalculationCode={"resonanceReport"}/>}/>,
                <ParamCardTr name={"BPF"} value={workingPointResonances.bpfFrequency.toFixed(2)} unit={"[Hz]"}  extras={<CalculationExplainer calculations={simulationResult.calculations} explainedCalculationCode={"resonanceReport"}/>}/>,
                <ParamCardTr name={"Wsp. bezpieczeństwa z dołu"} value={workingPointResonances.lowerSafetyFactor.toFixed(2)} unit={"[-]"}  extras={<CalculationExplainer calculations={simulationResult.calculations} explainedCalculationCode={"resonanceReport"}/>}/>,
                <ParamCardTr name={"Wsp. bezpieczeństwa z góry"} value={workingPointResonances.upperSafetyFactor.toFixed(2)} unit={"[-]"}  extras={<CalculationExplainer calculations={simulationResult.calculations} explainedCalculationCode={"resonanceReport"}/>}/>,
                <ParamCardTr name={"Dolna częstotliwość bezpieczna"} value={workingPointResonances.lowerSafetyFrequency.toFixed(2)} unit={"[Hz]"}  extras={<CalculationExplainer calculations={simulationResult.calculations} explainedCalculationCode={"resonanceReport"}/>}/>,
                <ParamCardTr name={"Górna częstotliwość bezpieczna"} value={workingPointResonances.upperSafetyFrequency.toFixed(2)} unit={"[Hz]"}  extras={<CalculationExplainer calculations={simulationResult.calculations} explainedCalculationCode={"resonanceReport"}/>}/>
            ]}/>

            <ParamCard header={"Parametry obciążenia czopa"} subheader={"Parametry rezonansowe punktu pracy"} trs={[
                <ParamCardTr name={"Naprężenie zredukowane Von-Misesa"} value={(pinLoadDetails.vonMisesLoad/1_000_000).toFixed(2)} unit={"[MPa]"}  extras={<CalculationExplainer calculations={simulationResult.calculations} explainedCalculationCode={"pinLoadDetails"}/>}/>,
                <ParamCardTr name={"Siła odśrodkowa Fo"} value={pinLoadDetails.gravityForce_Fm.toFixed(1)} unit={"[]"}  extras={<CalculationExplainer calculations={simulationResult.calculations} explainedCalculationCode={"pinLoadDetails"}/>}/>,
                <ParamCardTr name={"Siła od mocy silnika Fp"} value={pinLoadDetails.centrifugalForce_Fo.toFixed(1)} unit={"[]"}  extras={<CalculationExplainer calculations={simulationResult.calculations} explainedCalculationCode={"pinLoadDetails"}/>}/>,
                <ParamCardTr name={"Siła od ciśnienia przepływu Fc"} value={pinLoadDetails.enginePowerForce_Fp.toFixed(1)} unit={"[]"}  extras={<CalculationExplainer calculations={simulationResult.calculations} explainedCalculationCode={"pinLoadDetails"}/>}/>,
                <ParamCardTr name={"Moment od silnika Mp"} value={pinLoadDetails.flowPressureForce_Fc.toFixed(1)} unit={"[]"}  extras={<CalculationExplainer calculations={simulationResult.calculations} explainedCalculationCode={"pinLoadDetails"}/>}/>,
                <ParamCardTr name={"Moment od parcia przepływu Mc"} value={pinLoadDetails.motorInducedTorque_Mp.toFixed(1)} unit={"[]"}  extras={<CalculationExplainer calculations={simulationResult.calculations} explainedCalculationCode={"pinLoadDetails"}/>}/>,
                <ParamCardTr name={"Moment od siły ciężkości Mm"} value={pinLoadDetails.flowPushInducedTorque_Mc.toFixed(1)} unit={"[]"}  extras={<CalculationExplainer calculations={simulationResult.calculations} explainedCalculationCode={"pinLoadDetails"}/>}/>
            ]}/>

            <ParamCard header={"Parametry złożone - mechaniki"} subheader={"Parametry złożone, często pośrednie do obliczeń"} trs={trsForComplexMechanicalAssemblyParams}/>
        </div>
    </div>;
}

MechanicalSimulationReport.propTypes = {}

MechanicalSimulationReport.defaultProps = {}
