import React from "react";
import {OldChanges} from "./OldChanges";

export const LATEST_OPTISEL_VERSION : string = "14.0.0";

export function Changelog() {
    return <>

        <h2>{LATEST_OPTISEL_VERSION}</h2>
        <ul>
            <li>Wersja beta zarządzania sprawami</li>
            <li>Nowy ekran - sprawy - z listą utworoznych spraw i wyszukiwarką z filtrami i sortowaniem</li>
            <li>Ekran prezentujący szczegóły pojedynczej sprawy, można do niego przejść z ekranu "Sprawy"</li>
            <li>Możliwość przypisania zdarzenia doboru do sprawy, na stronie tego zdarzenia (Z Historii doborów)</li>
            <li>Mozliwość odpięcia zdaerzenia (doboru) od sprawy</li>
            <li>Edycja sprawy (zmiana numerów referencyjnych, nazwy)</li>
            <li>Funkcja "Pracy ze sprawą" - po wyborze sprawy jako aktywnej, dla obecnej sesji (użytkownika) będzie aktywny baner na górze strony a wydruki PDF będą automatycznie do tej sprawy przypinane. Funkcja dostepna przyciskiem "Pracuj z" na liście spraw oraz na widoku jednej sprawy</li>

        </ul>

        <h2>13.51.0</h2>
        <ul>
        <li>Naprawiono inną usterkę przy pobieraniu historycznych plików DriveWorks</li>
        </ul>

        <h2>13.50.0</h2>
        <ul>
            <li>Naprawiono możliwość pobierania historycznych plików oraz DriveWorks</li>
            <li>Naprawiono zbyt restrykcyjny dostęp do API mechanicznego Optisel 1 (co mogło powodować nieprawidłowe działanie doboru mechanicznego w Optisel 1)</li>
            <li>Naprawiono przywracanie kryterium kąta łopat z pliku kryteriów (ładowanie pliku kryteriów nie wpływają na kryterium kąta łopat)</li>
            <li>Wprowadzono nowy system zapisu doboru po wydruku karty wentylatora PDF - nowe zdarzenia doboru prezentują teraz kartę WWW wentylatora (oprócz PDF do pobrania) i umożliwiają ponowny dobór z kryteriami pod taki wentylator jednym kliknięciem. Wprowadza to nowe machanizmy zapisu wewnątrz programu pod przyszłe
                zarządzanie sprawami
            </li>
        </ul>

        <h2>13.49.0</h2>
        <ul>
            <li>Rozbudowano typoszereg możliwych średnic wału o typowo spotykane długości wałów na podstawie dostarczonej tabeli przekładni/motoreduktorów</li>
            <li>Zmieniono możliwe do wyboru w kryteriach doboru średnice wałów z całego typoszeregu wałów w programie, na tylko średnice występujące jako średnice otworów wgranych manufakturyzacji sprzęgieł</li>
            <li>Zaimplementowano w Konfiguratorze Mechanicznym małe widżety wybierające typową długość wału z kilku proponowanych przez kliknięcie - pod warunkiem, że średnica wału jest wybrana, a długość wału jest zadana (nie Autodobór)</li>
            <li>Zmieniono sposób generowania nazwy tarczy, tak aby wartości .0 były formatowane bez podawania liczby po przecinku</li>
            <li>Wzbogacono system przywilejów użytkowników i prototypowo podzielono na: "Zarządzanie użytkownikami", "Zarządzanie autoryzacją", "Symulacja przepływowa", "Składanie wirników", "Historia doborów", "Zarządzanie danymi wirników i mechaniki". Z ekranu "Role" można tworzyć role zdefiniowane wybranym zestawem z pośród tych
                przywilejów i przypisywać je do użytkowników, efektywnie wpływając na to co dany użytkownik widzi
            </li>
            <li>Przesunięto oznaczenie BPF na wykresach Campbell bliżej środka wykresu.</li>
            <li>Oznaczenia "Δ-SAFE" przeniesiono pod linię na wykresie Campbell</li>
            <li>Ograniczono na wysokość wymiar obrazka łopaty standard (nie cichej) przy konfiguratorze mechanicznym w opcji "Poziomo", tak by cały konfigurator nie rozrastał się na zbyt dużą wysokość</li>
        </ul>
        <h2>13.48.0</h2>
        <ul>
            <li>Poprawiono układ i niepoprawne skalowanie obrazu podglądu wymiarów łopaty w konfiguratorze mechanicznym na stronie karty wentylatora</li>
            <li>Zmieniono oznaczenie "bpf" na "BPF" na wykresie Campbell</li>
            <li>Przesunięto oznaczenia "m=1" i "m=2" bliżej środka na wykresie Campbell</li>
            <li>Oznacznia "Δ-SAF" i "Δ+SAF" zmieniono na "Δ-SAFE" i "Δ+SAFE" na wykresie Campbell</li>
            <li>Zamieniono oznaczenia + i - dla "Δ-SAFE" i "Δ+SAFE" na wykresie Campbell</li>
            <li>Wprowadzono obliczanie punktu najwyżej sprawności dla dobranego kąta, i naniesiono wartość jako zielony punkt na wykresie WEB ciśnienia wentylatora</li>
            <li>Wprowadzono zaznaczanie jaśniejszym punktem punkt najwyższej sprawności na wykresie PDF karty wentylatora</li>
            <li>Zaznaczono punkt najwyższej sprawności także jako zieloną kropkę na wykresie WWW karty wentylatora</li>
        </ul>

        <h2>13.47.0</h2>
        <ul>
            <li>Zamieniono nieprawidłowy algorytm zapasów API na nowy (poprzedni skanował tylko krzywe oporu w miejscach maksymalnych ciśnień każdej krzywej, zamiast "dla każdej wydajności")</li>
            <li>Zmieniono nieco układ obrazków/tabeli geometrii w konfiguratorze mechanicznym i zmieniono orientację rysunków wymiarów łopaty na pionową przy układzie pionowym</li>
            <li>Poprawiono (na wartości dokładniejsze) kilka wartości typoszeregu "Formuły korygujące SPL - przeszkoda na wlocie"</li>
        </ul>

        <h2>13.46.0</h2>
        <ul>
            <li>Poprawiono długie nazwy materiałów "EN AC-42000" i "EN AC-42200"</li>
            <li>Poprawiono błąd nadawania priorytetów na liście materiałów, gdzie nakładka używała wartości priorytetu obejmy, zamiast własnego</li>
        </ul>

        <h2>13.45.0</h2>
        <ul>
            <li>Zmieniono nazwy długie materiałów (te używane w tabeli materiałów) "EN AC-42000" oraz "EN AC-42200" na "Odlew aluminium"</li>
            <li>Zmieniono nazwę kolumny strategii montażu "podpora/ obejma/podkładka" na "podpora/obejma/nakładka" (proszę zwrócić uwagę na usuniętą spację)</li>
            <li>Przeniesiono parametr "Wykonanie łopat" karty wentylatora do sekcji "Konstrukcja wirnik-obudowa" tak, by parametr nie znikał w przypadku niedobrania rozwiązania mechanicznego i tym samym pomijania sekcji mechanicznej parametrów</li>
            <li>Ustawiono domyślną wartośc kryterium temperatury wlotowej na doborze na 35°C</li>
            <li>Zaktualizowano XLS demo strategii montażu osadzony wewnątrz programu o nową nazwę kolumny /nakładka</li>
            <li>Zmieniono bazową ilość (czyli w m3/s) miejsc po przecinku dla kryterium wydajności na 1 w formularzu zadawania kryteriów</li>
            <li>Przy wartościach parametrów diffuser/obstacle (inlet oraz outlet)/crosswind dla wartości "Brak" dodano mozliwość tłumaczenia tego słowa (na liście tłumaczeń widoczne pod kodem "@ik:Brak")</li>
            <li>Wprowadzono zaokrąglanie w górę do pół dla wartości pomniejszenia (dawniej korekty) A na tarczy</li>
        </ul>

        <h2>13.44.0</h2>
        <ul>
            <li>Naprawiono działanie obliczeń mechanicznych, gdy musza zostać przeprowadzone obliczenia obciążeniowe</li>
        </ul>

        <h2>13.43.0</h2>
        <ul>
            <li>Dodano interpretację kolumn w XLS strategii montażu łopat: "Nakładka (pełna nazwa)", "Materiał nakładki", "Masa nakładki", "Nr rysunku nakładki"</li>
            <li>Dodano wariant mocowania konfiguracji podpora/obejma/podkładka "P+O+N"</li>
            <li>Dodano wariant mocowania konfiguracji podpora/obejma/podkładka "P+O"</li>
            <li>Rozdzielono w budowie wirnika mechanicznego i na liście materiałowej pozycję obejmy i nakładki osobno</li>
            <li>Zaktualizowano wzór na masę całkowitą, by zawierał także masę z nakładki (z dedykowanych kolumn XLS strategii montażu)</li>
            <li>Zmieniono wzór na masę wirnika bez sprzęgła, tak by zawierał także osobno modelowane nakładki (wcześniej masa nakładki była "w obejmie")</li>
            <li>Zmieniono wzór na masę piasty, tak by zawierał także osobno modelowane nakładki (wcześniej masa nakładki była "w obejmie")</li>
            <li>W XLS strategi montażu łopat, tam gdzie kolumny były dwuznaczne ("... objemy / nakładki") usunięto " / nakładki", gdyż nakładka posiada własne kolumny</li>
            <li>Do parametrów DriveWorks dodano "Pełna nazwa nakładki" (wartość pusta, gdy nie ma nakładki)</li>
            <li>Zmodyfikowany szczegółowy widok złożenia mechanicznego na WWW tak by osobno uwidaczniał nakładke/podporę</li>
            <li>W parametrach DriveWorks "Pełna nazwa obejmy" nigdy nie zawiera juz nazwy nakładki, nawet jeżeli nie ma obejmy. Jest to spowodowane obecnością dedykowanych pól dla obejmy i nakładki osobno</li>
            <li>Od teraz teksty wartości parametru wykonania łopat mechaniki podlegają tłumaczeniom (np.: "Standard (-40˚C - +65˚C)", "AS/SG (-40˚C - +65˚C)")</li>
            <li>Wyśrodkowano tekst "Widmo akustyczne wirnika nie uwzględnia interferencji...." na PDF</li>
            <li>Na wykresie oktaw akustycznych dodano tolerancję ±2 dla ostatniego słupka mocy (web i pdf)</li>
            <li>Naprawiono przycisk Karta/Symulacja dla opcji dodatkowych (przy lekko zmienionym kącie) wybranych z listy wyników doboru (naprawiono defekt że zawsze używało kąta łopaty z pozycji głównej rezultatu, a nie pod-opcji)</li>
            <li>Zaktualizowano dane demo strategii montazu łopat wymieniając dawno porzucone kody materiałów i zastępując tymi nowej generacji (HDG/A2/A4/316/316L)</li>
            <li>Do danych demo w strategiach montażu łopat na potrzeby testów dodano montaż P+O+N do podpór przy wybranym materiale 361L</li>
            <li>Zmieniono domyślny priorytet listy materiałowej obejmy z 77 na 76 (by nie kolidować z nakładką). Natomiast zmiana te jest efektywna tylko jeżeli ustawienia nie zostały ręcznie zapisane. Zaleca się przegląd ustawień administracyjnych i ustawienie priorytetu obejmy innego niż nakładki, jeżeli ustawienia są zapisane
                ręcznie.
            </li>
            <li>Zarejestrowano nowy materiał o identyfikatorze (kodzie) "EN AC-42000". Krótka nazwa: "EN AC-42000", długa nazwa: "EN AC-42000" (ta pojawi się na liście materiałów), gęstość: 2.7, klasa gdy śruba: ""(brak), klasa gdy podkładka: ""(brak), klasa gdy nakrętka: ""(brak), wybieralny dla konstrukcji: Nie, konwencja
                nazewnicza śruba-nakrętka: brak. (dane te zawsze należy podać przy rejestrowaniu materiału)
            </li>
            <li>Zarejestrowano nowy materiał o identyfikatorze (kodzie) "EN AC-42200". Krótka nazwa: "EN AC-42200", długa nazwa: "EN AC-42200" (ta pojawi się na liście materiałów), gęstość: 2.7, klasa gdy śruba: ""(brak), klasa gdy podkładka: ""(brak), klasa gdy nakrętka: ""(brak), wybieralny dla konstrukcji: Nie, konwencja
                nazewnicza śruba-nakrętka: brak. (dane te zawsze należy podać przy rejestrowaniu materiału)
            </li>
            <li>Nowy materiał będzie używany tylko przechodnio przez odwołanie w XLS strategii montazu łopat z "Material rzeczywisty podpory", ponieważ nie jest on wybieralny</li>
        </ul>


        <h2>13.42.0</h2>
        <ul>
            <li>Prototypowo (nie na użytek produkcyjny) umożliwiono przeprowadzanie doboru mechanicznego na znacznie większej ilości kombinacji, przez zwiększenie parametru ustawień mechanicznych: 'Maks. ilość testowanych sprzęgieł bez zadania śr. wału
                ' (typowa i domyślna wartość to: 1). Umożliwia to czasowo eksperymentalne zwiększenie ilości testowanych konfiguracji mechanicznych dla uwidocznienia możliwości konstrukcyjnych/pracy algorytmu.
            </li>
        </ul>

        <h2>13.41.0</h2>
        <ul>
            <li>Usunięto od dawna porzucaną już stronę: "(Porzucane) Dobory mechaniczne", nie była utrzymywana i model wyświetlanych danych nie był zgodny z przechowywanymi w bazie danych, powodując błedy wyświetlania - należy używać "Historia doborów"</li>
            <li>Usunięto od dawna porzucaną już stronę: "(Porzucane) Dobory nieudane", nie była utrzymywana i model wyświetlanych danych nie był zgodny z przechowywanymi w bazie danych, powodując błedy wyświetlania - należy używać "Historia doborów"</li>
            <li>Na konfiguratorze mechanicznym dodano dodatkowy obrazek poglądowy w wariantach cichy/normalny, objaśniający wymiary x1, x2, y1, y2</li>
            <li>Wprowadzono prezentację wartości tolerancji oktaw akustycznych na wykresie oktaw WWW</li>
            <li>Wprowadzono prezentację wartości tolerancji oktaw akustycznych na wykresie oktaw PDF</li>
            <li>Dodano tekst informacyjny (podlegający tłumaczeniom) "Widmo akustyczne wirnika nie uwzględnia interferencji, odbić oraz innych urządzeń działających w tle." pod wykresem widm akustycznych WWW</li>
            <li>Dodano tekst informacyjny (podlegający tłumaczeniom) "Widmo akustyczne wirnika nie uwzględnia interferencji, odbić oraz innych urządzeń działających w tle." pod wykresem widm akustycznych PDF</li>
        </ul>

        <h2>13.40.0</h2>
        <ul>
            <li>Wprowadzono uproszczoną legendę nanoszącą oznaczenia lini wykresu Campbell na wykres rezonansów WWW</li>
            <li>Wprowadzono uproszczoną legendę nanoszącą oznaczenia lini wykresu Campbell na wykres rezonansów PDF</li>
            <li>Wprowadzono konfigurowalne (Administracja/Ustawienia) wartości parametrów bezpieczeństwa rezonansów (FDWmin FDWmax)</li>
        </ul>

        <h2>13.39.0</h2>
        <ul>
            <li>Wprowadzono nowy algorytm obliczania zapasów API (Zarówno w doborze i liscie rezultatów, jak i w symulacji/karcie jednego wentylatora) oparty o wcześniej uprzednio przeliczone maxPsi/Zeta dla każdego Fi</li>
            <li>Pozostawiono uprzednie obliczenia zapasów API informacyjnie w logu obliczeniowym jako "starym sposobem"</li>
            <li>Zapisano kompletny zestaw tłumaczeń ze środowiska optisel2.online na dzień 19.08.2024 jako zestaw domyślnych tłumaczeń aplikacji</li>
        </ul>


        <h2>13.38.0</h2>
        <ul>
            <li>Przywrócono nagłówek "Konfigurator mechaniczny" na karcie wentylatora</li>
            <li>Zmieniono sposób obierania skali osi wykresu rezonansu WWW i PDF, by lepiej wykorzystać przestrzeń wykresu</li>
            <li>Zaimplementowano algorytm, który ogranicza rysowanie lini na wykresach PDF do granic obszaru wykresu, tak by nie wychodziły poza wykres</li>
        </ul>

        <h2>13.38.0</h2>
        <ul>
            <li>Poprawiono kolejność parametrów rezonansu od-do (zamiast do-od) na karcie wentylatora</li>
            <li>W tabeli geometri karty PDF zmieniono wymiary na całkowite, zamiast do jednego miejsca po przecinku</li>
            <li>Zmieniono zaokrąglanie "Siła urwania łopaty" na karcie wentylatora na zaokrąglanie do całości</li>
            <li>Zmieniono zaokrąglanie "Moment bezwładności" na karcie wentylatora na zaokrąglanie do całości</li>
            <li>Zmieniono umiejscowienie jednostek na osiach X wykresów PDF, by nie nachodziły na liczby z podziałki poziomej</li>
            <li>Zmieniono formatowanie szerokości rowka wpustowego na rysunku wymiarowym detalu wału na liczę całkowitą zamiast 1 miejsca po przecinku</li>
            <li>Teksty uwag na końcu karty PDF od teraz są tłumaczone na inne języki (podlegają mechanizmowi tłumaczenia)</li>
        </ul>

        <h2>13.37.0</h2>
        <ul>
            <li>Wyłączono możliwość zadawnia dyfuzora w układzie Tłoczącym (przycisk jest wtedy wyszarzony)</li>
            <li>Ujednolicono jednostkę wykresu momentu na Web z obr-1 na min-1</li>
            <li>Dostosowano wybór szczegółowego rysunku wymiarowego do nowych nazw pozycji, zamiast prezentacji obrazka ze sprzęgłem na dole dla "1,2,5,6", teraz jest on prezentowany dla "WAS1, WAS2, WCS1, WCS2" (uwaga: szczegłowe obrazwymiarowe ze sprzęgłem po tej samej tronie co podpory nie były i nadal nie są wspierane)</li>
            <li>Poprawiono obliczanie poprawki akustycznej na średnicę z parametrów a/b/c: zmiast używać średnicy w metrach, teraz używana jest średnica w milimetrach</li>
            <li>Wyłączono możliwość zawijania do drugiej linii dla wartości parametrów wentylatora na karcie WWW</li>
            <li>Do typoszeregu średnic wału dodano wartość 35mm, przez co jest ona od teraz obecna w rozwijanej liście średnic wału na stronie "Złożenie wirnika"</li>
            <li>W nagłówku "W punkcie pracy..." karty wentylatora zmieniono zaokrąglanie wydajności i ciśnienia z dwóch do jednego miejsca po przecinku</li>
            <li>Dla niektórych parametrów na karcie aktywowano używanie "prefereowanej ilości miejsc po przecinku przy formatowaniu" okreslonej w przeszłości, co poprawi wyświetlanie niektórych parametrów. Przykładem jest kąt łopaty, albo "Sprawność statyczna w punkcie pracy", od teraz zaokrąglany do 1 miejsca po przecinku a nie
                2
            </li>
            <li>Ustawiono prefereowaną prezezentację dokłądności po przecinku dla "Ciśnienie dynamiczne w punkcie pracy" na 1 miejsce po przecinku</li>
            <li>Ustawiono prefereowaną prezezentację dokłądności po przecinku dla "Prędkość obwodowa końca łopaty" na 1 miejsce po przecinku</li>
            <li>Ustawiono prefereowaną prezezentację dokłądności po przecinku dla "Sprawność całkowita w punkcie pracy" na 1 miejsce po przecinku</li>
            <li>Ustawiono prefereowaną prezezentację dokłądności po przecinku dla "Moc na wale w punkcie pracy" na 1 miejsce po przecinku</li>
            <li>Ustawiono prefereowaną prezezentację dokłądności po przecinku dla "Zapas ciśnienia API" na 1 miejsce po przecinku</li>
            <li>Ustawiono prefereowaną prezezentację dokłądności po przecinku dla "Zapas wydajności API" na 1 miejsce po przecinku</li>
            <li>Ustawiono prefereowaną prezezentację dokłądności po przecinku dla "Zapas wydajności (dla kąta)" na 1 miejsce po przecinku</li>
            <li>Ustawiono prefereowaną prezezentację dokłądności po przecinku dla "Zapas ciśnienia (dla kąta)" na 1 miejsce po przecinku</li>
            <li>Ustawiono prefereowaną prezezentację dokłądności po przecinku dla "Kąt na znaczniku (β)" na 1 miejsce po przecinku</li>
            <li>Ustawiono prefereowaną prezezentację dokłądności po przecinku dla "Wiatr poprzeczny" na 1 miejsce po przecinku</li>
            <li>Ustawiono prefereowaną prezezentację dokłądności po przecinku dla "Moc akustyczna w punkcie pracy" (najpewniej tłumaczone na "Całkowita moc akustyczna") na 1 miejsce po przecinku</li>
            <li>Ustawiono prefereowaną prezezentację dokłądności po przecinku dla "Ogólna moc akustyczna w warunkach optymalnych PWLOpt" (najpewniej tłumaczone na "Optymalny poziom mocy akustycznej") na 1 miejsce po przecinku</li>
            <li>Ustawiono prefereowaną prezezentację dokłądności po przecinku dla "Siła urwania łopaty" na 1 miejsce po przecinku</li>
        </ul>

        <h2>13.36.0</h2>
        <ul>
            <li>Umożliwiono uzyskanie estymat H3, gdy nie zadano wału: Zamiast całkowicie ignorować estymowanie wału przy niezadaniu H3, estymata jest liczona z wielu indywidualnych pod-estymat dla takich wałów, jakie zostały auto-dobrane w różnych możliwych konstrukcjach mechanicznych (często oznacza to estymowanie z najkrótszego
                możliwego wału dla danej kombinacji, czyli wału długości równej średnicy otworu najkrótszego sprzęgła z manufakturyzacji sprzęgła)
            </li>
            <li>Na "Złożenie wirnika" w sekcji prezentującej estymaty H3, po rozwinięciu "Szczegóły" dodano dla każdego sampla pod estymowanie H3 informację, dla jakiej długości wału dostarczał on estymatę - przydatne przy analizie przypadków, gdy nie zadamy wału</li>
            <li>Wprowadzono mechanizm limitowania kątów łopatowych dla profili przepływowych przy dobrze przepływowym (dostarczono domyślne wielkości dla profili demo, a dla profili wcześniej nie mających zdefiniowanego ograniczenia przyjęto 4-28)</li>
            <li>Wprowadzono możliwość ustawiania oigraniczenia kątowego dla profili przepływowych na stronie "Profile łopatowe"</li>
            <li>Usunięto ograniczenie przy edycji profilu przepływowego, że powiększenie konta na znaczniku musi być większe niż 0 (0 jest dopuszczalne). Czasami blokowało to mozliwosc zapisania profilu.</li>
        </ul>

        <h2>13.35.0</h2>
        <ul>
            <li>Poprawiono rysunek dla pozycji WBS1 w konfiguratorze mechanicznym (był dla niej prezentowany ten sam co WBS2)</li>
            <li>Poprawino zapis jednoski z "kw" na "kW" w nazwe pliku karty PDF</li>
            <li>W konfiguratorze mechanicznym wyprowadzono uproszczoną tabelę geometrii (tylko dobrany kąt) (jednak tylko w przypadku udanego doboru mechaniki, bo tylko wtedy generowane są dane geometryczne)</li>
        </ul>

        <h2>13.34.0</h2>
        <ul>
            <li>W nazwie pliku karty PDF zmieniono zaokrąglenie prędkości do całości a mocy do jednego miejsca po przecinku</li>
            <li>Dodano animację ładującą na ekranie karty podczas jej generowania (lub regenerowania po zmianie konfiguracji)</li>
            <li>Wizualnie odzielono kartę danych technicznych od konfiguratora nagłówkiem "Karta danych technicznych wentylatora"</li>
            <li>Usprawniono estymowanie H3 w konfiguratorze mechanicznym - Estymaty nadal będą wyliczane, nawet jeżeli użytkownik zada wał, powodujący brak doboru mechaniki</li>
            <li>Poprawiono skalowanie różnych wariantów obrazów wymiarów w karcie PDF - od teraz obraz będzie zawsze na stałą szerokość, nawet jeżeli pliki źródłowe różnią się szerokością</li>
            <li>Naprawiono defekt błędnie nazwanego typu aplikacji, niezgodnego z oryginalnym wyborem w przypadku ("Skraplacz pary (ACC) (bez odzysku)" zamieniony z "Wymiennik ciepła chłodzony powietrzem (ACHE) (bez odzysku)")</li>
        </ul>

        <h2>13.33.0</h2>
        <ul>
            <li>Dodano wpis typoszeregu zaokrągleń tarcz: 745mm - 752mm jest zaokrąglane do 750mm</li>
            <li>Usunięto główny widok "demo wczesnej wersji" i po wizycie na aplikacji użytkownik jest kierowany od razu na formularz doboru</li>
            <li>Ograniczono stosunek pól powierzchni wirnika do przeszkody do max 0.3</li>
            <li>Ograniczono stosunek odległości przeszkody do minimum 0.05</li>
            <li>Wprowadzono generowanie bardziej zróżnicowanej nazwy pobieranego pliku PDF karty wentylatora: WENTECH + nazwa wirnika(uproszczona) + prędkość + moc</li>
            <li>Wprowadzono możliwość zadawania prędkości obrotowej przy doborze i symulacji za pomocją prędkości końca łopaty, wraz z przeliczaniem pomiędzy RPM i prędkością końca</li>
        </ul>

        <h2>13.32.0</h2>
        <ul>
            <li>Wprowadzono podpowiadanie mniej więcej możliwych średnic wału na konfiguratorze mechanicznym. Używając profilu wybranego wirnika i średnicy, przez przeliczenie +/- 10% średnicy, sięgnięcie po formowania w tej średnicy/profilu, następnie kompatybilności typów sprzęgieł, i do manufaktyruzacji sprzęgieł (a z nich
                możliwe otwory). Możliwe, że w algorytmie tym dałobysię zawęzić poszukiwania jeszcze mocniej.
            </li>
            <li>Wprowadzono tłumaczony tekst "Brak podglądu" dla pozycji w konfiguratorze mechanicznmym, w której wirnik jest pionowo a sprzęgło na wylocie</li>
            <li>Wprowadzono tekst zastępczy, gdy na konfiguratorze mechanicznym z przyczyn technicznych nie jest dostępna estymana H3 (np. brak zadania długości wału)</li>
            <li>Zmieniono wyrównanie/wielkosć elementów w konfiguratorze mechanicznym, by wysoki obrazek nie "rozpychał" całego konfiguratora</li>
        </ul>

        <h2>13.31.0</h2>
        <ul>
            <li>Prototypowy konfigurator mechaniczny na stronie karty wetnylatora (można modyfikować parametry mechaniczne, regenerując tym samym kartę tego samego wentylatora dla nowej konfiguracji</li>
            <li>Podpowiadanie zakresów H3 na konfiuguratorze mechanicznym na karcie wentylatora</li>
            <li>Prototypowe obrazy poglądowe na konfiguratorze mechanicznym, w zależności od wybranej konfiguracji</li>
            <li>Usunięto małą zbędną liczbe wyświetlającą się bez żadnego objaśnienia w kwadracie prezentującym zakresy H3 dla danej konfiguracji sprzęgła/formy/długości łopaty</li>
            <li>Wprowadzono nigdy wcześniej nie istniejące ograniczenie mechaniczne, by nie dopuścić rozwiązania, gdzie wyliczona długość sprzęgła H1 przekracza maksymalną długość sprzęgła z manufakturyzacji</li>
            <li>Przeprogramowano algorytm doboru wysokości wirnika, gdy nie zadano: Zamiast najmniejszej możliwej wysokości, domyślnie będzie użyta maksymalna wysokość dla obranej konfiguracji stron podpór/sprzęgła (często na najkrótszym możliwym wale, jeżeli nie zadano)</li>
            <li>Na stronie "Złożenie wirnika" umożliwiono dobór ze zbyt długim sprzęgłem (wyłącznie na żądanie)</li>
        </ul>

        <h2>13.30.0</h2>
        <ul>
            <li>Zmieniono "typoszereg średnic wspornika sprzęgła H4" tak, że dla S50A/60, S50/60 i S50A/65 wartośc H4 wynosi zero</li>
            <li>W prototypowym estymowaniu zakresu wartości H3 na "Złożenie wirnika" usunięto ograniczenie, że wysokość nie może być większa niż trzykrotność długości wału.</li>
            <li>Na stronie Złozenie wirnika wprowadzono estymowanie H3 pod konkretną opcję formy/sprzęgła/długości łopaty. Jeżeli estymaty zostały wyliczone, zostaną wyświetlone pod przyciskami ilości śrub.</li>
        </ul>

        <h2>13.29.0</h2>
        <ul>
            <li>Przywrócono interpretację kolumny "Długość maksymalna sprzęgła [mm]" w manufakturyzacjach sprzęgła, na potrzeby przywracania obliczeń maksymalnej możliwej wysokości wirnika</li>
            <li>Na stronie "Złożenie wirnika" wprowadzono prototypową sekcję estymowania możliwych zakresów wysokości H3. Estymowanie to wymaga zadnia długości wału na ten moment</li>
            <li>Na stronie "Złożenie wirnika" rozbudowano logi na dole strony o szczegółowe obliczenia dotyczące estymowania H3</li>
        </ul>

        <h2>13.28.0</h2>
        <ul>
            <li>Naprawiono interpretację wartości wzmocnień i mas z XLS form łopat: usunięto interpretację jako liczby całkowite i zastąpiono liczbami zmiennoprzecinkowymi, by nie tracić dokładnosci</li>
            <li>W wywdaniu tym należy ponownie wgrać XLS form łopat, by dokładne wartości zostały zinterpretowane i zapisane w bazie programu</li>
            <li>W formowaniach łopat demo dla (1DEM) wprowadzono wszsytkie częstotliwości jako estymowane (ujemne) dla uławienia testowania tej funkcjonalności</li>
            <li>Skopiowano tłumaczenia ustawione na serwerze w z dnia 02.06.2024 jako domyślne dla programu</li>
            <li>Wprowadzono konfigurowalny współczynnik dla częstotliwości rezonansowej łopaty: nowe konfigurowalne ustawienie aplikacji: "Współczynnik globlany częstotliwości rezonansowych łopat (1.0 = tak jak w XLS)"</li>
            <li>Ustawiono wartość domyślną (poczatkową) dla globalnego współczynnika częstotliwości rezonansowej na 0.9, co oznacza, że użyta w programie częsottliwość będzie 10% mniejsza, niż ta załadowana z XLS</li>
            <li>W szczegółach rozwiązania mechanicznego na stronie "Złożenie wirnika" dodano prezentację wykresu rezonansów Campbell, ale tylko pod warunkiem, że miała miejsce symulacja obciążeniowa (czyli zadano rpm/moc/cisnienie)</li>
            <li>Naprawiono defekt, który polegał na tym, że po skorzsytaniu z edycji kryteriów na symulacji, na stronie doboru mógł zniknać selektor profili. Obecnie po skorzystaniu ze zmiany kryteriów na symulacji - gdzie nie ma selektorja profili, po powrocie na dobór, wszystkie profile będą zaznaczone.</li>
        </ul>

        <h2>13.27.0</h2>
        <ul>
            <li>Wprowadzono koncept estymowanych obliczeń rezonansu na podstawie estymowanej częstotliwości rezonansu łopaty dla danego wzmocnienia defniowanej przez ujemną wartość parametru w XLS form łopat (np. -20Hz jest ładowane jako 20Hz estymowane)</li>
            <li>Estymowana wartość rezonansu łopaty, rezonansów BONF z BPF od-do oraz wykres Campbell są oznaczane na karcie wirnika i PDF ostrzeżeniem</li>
            <li>Zmieniono wymagane nazwy kolumn dla częstotliwości własnych dodając "(ujemne=estymowane)" poprzedzone spacją. Przykładowo: "W10_S [Hz] (ujemne=estymowane)" (pełny przykład w XLS form łopat dostępnym na stronie ładowania danych)</li>
        </ul>

        <h2>13.26.0</h2>
        <ul>
            <li>Poprawiono, by wyliczona wielomianem wartość wpływu szczeliny na hałas, aplikowałą się do niego bezwzględnie w db(A), a nie procentowo</li>
            <li>Przywrócono wypis poszczególnych wpływów czynników na hałas - na symulacji i karcie wentylatora (wcześniej zostały ukryte, gdyż pomijały wpływ procentowy, którego koncept teraz znowu nie jest obecny)</li>
            <li>W kilkunastu miejscach ujednolicono jednostkę prędkości obrotowej, pozbywając się "1/min" na rzecz "min-1"</li>
            <li>Dodano osie poziome z opisem jednostki [Hz] na wykresach PDF oktaw akustycznych</li>
            <li>W kilku miejscach przemianowano "Charakterystyka sprawności" na "Charakterystyka sprawności statycznej"</li>
            <li>Zmieniono zakres osi X wykresu rezonansu Campbell na maksimum 105% RPM punktu pracy</li>
            <li>Poprawiono odstępmy między tutułami a wykresami na karcie PDF (strona 2, wyrównania)</li>
            <li>Zagęszczono podziałkę osi Y wykresu Campbell na PDF</li>
            <li>Zmniejszono nieco wykres Campbell na PDF, tak by jednostka [Hz] nie wchodziła na tytuł wykresu</li>
        </ul>

        <h2>13.25.0</h2>
        <ul>
            <li>Zwiększono "Przyrost średnicy przez dyfuzor" 1000-krotnie ponieważ był liczony w metrach, zamiast w milimetrach (w następnym kroku jest dodawany do średnicy podanej w milimetrach)</li>
            <li>Poprawiono udział procentowych czynników korygujących (np. szczelina) w "Całkowita poprawka aku." (np. przy 0.6% zamiast "* 1.006" całkowita poprawka była mnożona "* 0.006")</li>
            <li>Poprawiono błąd, który w momencie podania dwóch przeszkód o takich samych parametrach (wlot i wylot) prezentował wartość korekty jako parametr na karcie dla tylko jednego z nich</li>
        </ul>


        <h2>13.24.0</h2>
        <ul>
            <li>Przywrócono wzory obliczające korektę hałasu oraz lambda (współczynnika pod moc/sprawność) pochodzące z korekty szczeliny</li>
            <li>Dla lamby i hałasu wprowadzono analogiczny mechanizm korekt jak do ciśnienia statycznego, to jest z rozróżnieniem na czynniki korygujące na absolutną wartość oraz procentową wartość</li>
            <li>Zmieniono znaczenie paramtru "Sprawność statyczna w punkcie pracy (bezwymiarowa)", wcześniej odnosił się on do wartości prosto z charakterystyki wentylatora. Teraz jest to sprawność instalacji, po wpływie szczelina na krzywą lambda</li>
            <li>Zmieniono znaczenie parametru "Moc na wale w punkcie pracy" - od teraz jest to moc instalacji (nadal ta sama nazwa), ponieważ do obliczeń używa on "Sprawność statyczna w punkcie pracy (bezwymiarowa)" w nowym znaczeniu (czyli po wpływie szczeliny na lambda)</li>
            <li>Zmieniono znaczenie parametru "Sprawność całkowita w punkcie pracy" - od teraz jest to sprawnośc instalacji, ponieważ do obliczeń używa "Moc na wale w punkcie pracy", który zawiera wpływ szczeliny na lambda. Nazwa zostałą ta sama</li>
            <li>Inaczej mówiąc, parametry dotyczące mocy i sprawności na karcie są teraz parametrami instalacji, gdzie lambdę poprawiono o wpływ szczeliny</li>
            <li>Od teraz charakterystyki sprawności na karcie i w symulacji są charakterystykami instalacji, nie wentylatora, ponieważ wywodzą się z lambdy po obliczonym wpływie szczeliny</li>
            <li>Uwaga! Do wyliczenia mocy instalacji nadal używane jest ciśnienie wentylatora, a nie instalacji (tutaj bez zmian, ale może to sugerować, że obliczone moce/sprawności są "częściowo jak wentylatora/częściowo jak instalacji" co może być błędne (Moc na wale instalacji w punkcie pracy kW = moc użyteczna wentylatora w
                punkcie pracy / sprawność statytczna instalacji w punkcie pracy)
            </li>
            <li>(inaczej mówiąc, podczas liczenia mocy na wale łączone we wzorze jest ciśnienie BEZ wpływu szczeliny, za to lambda z wpływem.</li>
            <li>Ponieważ krzywa mocy jest liczona ze sprawności, od teraz także należy ją interpretować jako krzywą instalacji</li>
            <li>Efektem powyższych zmian jest to, że zmniejszenie szczeliny powoduje wyliczenie nieco większej mocy na karcie/symulacji i nieco mniejsze wartości sprawności i na wykresie sprawności. Analogicznie zwiększenie szczeliny obniża moc i podnosi sprawność</li>
            <li>Niestety ze względu na etap, na którym aplikowana jest korekta lambda oraz optymalizacje wydajnościowe, logi obliczeniowe dla charakterystyki sprawności nie są łatwo dostępne - najlepszą metodą obserwacji jest log obliczeniowy w Symulacji parametru "całkowita poprawka akustyczna"</li>
            <li>Poprawka lambda na szczelinę obecnie działa nie jak "cena przed rabatem" (jak korekta ciśnienia), tylko zwyczajnie: lambda wentylatora jest przemnażane przez procenowy zysk lambda.</li>
        </ul>

        <h2>13.23.0</h2>
        <ul>
            <li>Naprawiono przekazywanie preferencji strony sporzęgła, orientacji i strony sprzęgła wlot/wylot z popziomu doboru/symulacji do modułu mechanicznego (preferencje te były przekazywane poprawnie tylko w Złożeniu wirnika)</li>
            <li>Osadzono do aplikacji stan tłumaczeń z serwera online na dzień 13.05.2023 jako stan domyślny. (przy braku/zresetowaniu tłumaczeń, będą takie jak na serwerze online 13.05) - gdyż zauważono, że wiekszość tekstów została już wstępnie przetłumaczona</li>
            <li>Wprowadzono dynamiczne nazywanie parameteru "śr.wirnika po powiększeniu dla SPL" i od teraz przy obecności dyfuzora i układu ssącego będzie on nazwany "Średnica obudowy po powiększeniu dla SPL"</li>
        </ul>

        <h2>13.22.0</h2>
        <ul>
            <li>Przeprojektowano sposób działania korekt wpływających procentowo (aplikacja, szczelina) korekty te budują teraż różnicę procentową pomiędzy instalacją a wentylatorem po korektach nieprocentowych. (przykładowo instalacja 19% i dyfuzor 5Pa dobiorą z zadanego 100Pa wartość 100 - (100*0.19) - 5)</li>
            <li>Wprowadzono inny sposób podawania wielkości dokonanej korekty ciśnienia na ekranie symulacji. Wartość obliczana jest analogicznie do korygowania przy doborze, a log obliczeniowy rozbija korektę na stałą i procentową np. "Składowa stała (Pa) korekty: 0.0 oraz składowa procentowa (10.0%): 22.96".</li>
            <li>Poprawiono opisy typoszeregu korekt na aplikację (odzysk, zamiast strata)</li>
        </ul>

        <h2>13.21.0</h2>
        <ul>
            <li>Zmieniono wzór na SPL P3 w ssaniu z "SPL P2 - 4" na "SPL P1 - 4"</li>
            <li>Wprowadzono dynamiczne nazywanie wpływu szczeliny na ciśnienie statyczne: "Odzysk ciśnienia - szczelina" (dla małych szczelin, podawany z minusem) oraz "Strata ciśnienia - szczelina" (dla dużych szczelin, podawana z plusem). Podawanie minusa przy "odzysk" jest ujednolicone z dotychczasowym podejściem do dyfuzora.
            </li>
            <li>Dodano brakujące tłumaczneie "śr.wirnika po powiększeniu dla SPL" powodujące wyświetlanie "@@@" w symulacji</li>
            <li>Zmieniono nazwę w logach obliczeniowych "Krzywa ciśnienia (skorygowana)" na "Krzywa ciśnienia (instalacji)"</li>
            <li>Wprowadzono krytyczne zmiany co do liczenia korekt ciśnienia wentylatora. Zastosowano osobne wzory do przeliczenia ciśnienia wentylatora na nieskorygowane, oraz kryterium na skorygowane - naprawiając problemy z procentowymi korektami na aplikację.</li>
            <small>Objaśnienie: Wyszukiwanie wartości ciśnienia instalacji w Symulacja, posiadając ciśnienie wentylatora, było wcześniej obliczane na zasadzie "zwiększ ciśnienie wentlatora o 19% odzysku".
                Teraz jest obliczane na zasadzie "Jaka wartość zostałą pomniejszona o 19%, że uzyskano obecne ciśnienie wentylatora". Natomiast w przypadku kryteriów doboru odbywa się to inaczej: jeżeli zadano X i ACC, to obniż X o 19% i szukaj takiego ciśnienia wentylatora. Z tego powodu istnieją teraz osobne wzory, ze względu na
                kierunek dokonywania korygowania. Przywróciło to zgodność po przejśicu z wyniku doboru do symulacji.</small>
        </ul>

        <h2>13.20.0</h2>
        <ul>
            <li>Wprowadzono obliczanie SPL w punktach (parametry karty, nie klikalny wykres) z powiększaniem średnicy dla tłoczącego, gdy kształ wlotu inny niż cylinder prosty (powiększenie 0.2* średnica obudowy)</li>
            <li>Wprowadzono obliczenie SPL w punktach (parametry karty, nie klikalny wykres) z powiększeniem średnicy dla ssącego, gdy jest dyfuzor (2.0 * wys.dyfuzora * tan( kąt.dyfuzora ))</li>
            <li>Dodano obsługę rysunków wymiarowych z szablonów _3 (sprzęgło u góry na rysunku - dla pozycji innych niż 1/2/5/6). Odzwierciedlono to w zmienionym typoszeregu "Algorytm dopasowania obrazów wymiarowych"</li>
            <li>Ukryto w "Złożenie wirnika" możliwość zadawania powiększenia kąta na znaczniku beta (które jest wejściem do modułu mechanicznego) - pozostawiając jedynie możliwość pobierania tego kąta z ustawień przepływowych profilu</li>
        </ul>

        <h2>13.19.0</h2>
        <ul>
            <li>Znacznie poprawiono szybkość doboru</li>
        </ul>

        <h2>13.18.0</h2>
        <ul>
            <li>Przywrócono niedostępne czasem wartości hałasu w punktach: Zmieniono wzór na k1 przy SPL (k1 = 2.0 - 6.8 * (1 - sqrt(cos[rad](KątAlfaOdOsiDeg * (pi/180.0))))) by używał sqrt( abs( cosinus ) ) (wartości absolutnej po cosinus), gdyż ze względu na komputerowe
                błędy liczb zmiennnoprzecinkowych (standard IEEE 754) cosinus czasem uzyskiwał wartości typu -0.00000001. Niemożność policzenia sqrt() z liczby ujemnej uniemożliwiał wyliczenie tych punktów i system je ukrywał (oprócz symulacji, gdzie było "NaN").
                Problem zaczał występować po zmianie L1H na L1V we wcześniejszym wzorze
            </li>
            <li>Naprawiono błąd eksportu DriveWorks płaskotekstowy wywołany zmianą parametru sprzęgła H3 ze zmeinnoprzecinkowej na całkowitą</li>
            <li>Zmieniono formatowanie komórki Excel numerycznej w nie-płaskotekstowym pliku eksportu DriveWorks, by wyświetlało 1 miejsca po przecinku dla grubości kołpaka (przykładowo, Excel zawierał grubość kołpaka 3,3 jednak formatowanie komórki ograniczało wyświetlanie do "3")</li>
            <li>Dodano do typoszeregu średnic podtrzymujących sprzęgło: "S50_52" i "S50A_52" na średnicę podtrzymującą 75</li>
            <li>Do typoszeregu możliwych średnic wału (który np. służy podpowiadaniu w kryteriach) dodano średnicę 52mm</li>
            <li>Usunięto z rysunków wymiarowych oznaczenie "ee" przy średnicy zewnętrznej tulei E</li>
            <li>Na rysunkach wymiarowych dodano symbol "Ø" do średnicy wirnika i tarczy</li>
            <li>Na rysunkach wymiarowych zmieniono zaokrąglenie wymiaru szerokości wpustu z 2 do 1 miejsca po przecinku</li>
            <li>Pole kryterium doboru "Wykonanie ze sprzęgłem" przeniesiono nad "Pozycja sprzęgła"</li>
            <li>Wprowadzono wybór z listy dla średnicy wału na "Złożenie wirnika" - reużyto komponenty zadawania kryteriów wału i wysokości z normalnego doboru, rozpoczynając stopniowe ujednolicanie selektorów kryteriów pomiędzy doborem/symulacją, a złożeniem wirnika</li>
        </ul>

        <h2>13.17.0</h2>
        <ul>
            <li>Kryteria doboru i symulacji są zapamiętywane w preferencjach przeglądrki, aż do ich zresetowania, w związku z czym po powrotach przcyiskiem "Wstecz" przeglądarki lub z menu, operaujemy na ostatnio użytych kryteriach</li>
        </ul>


        <h2>13.16.0</h2>
        <ul>
            <li>Dodano możliwość prezentacji rysunków wymiarowych przy rozwiązaniach narzędzia "Złożenie wirnika" - za pomocą przycisku pod tabelą prezentującą wymiary geometryczne</li>
            <li>Średnica wału w kryteriach doboru i symulacji jest teraz listą wybieralną z opcjami ładowanymi z typoszeregu możliwych średnic wałów (zamiast podawanie ręcznie wartości liczbowej)</li>
        </ul>


        <h2>13.15.0</h2>
        <ul>
            <li>Wymiar sprzęgła H3 w ekporcie DriveWorks jest teraz eksportowany jako wartośc 0 zamiast "Brak", gdy parametr ten nie występuje</li>
            <li>Do typoszeregu wpustów sprzęgieł dodano wpis: dla średnicy otwory 35mm szerokość wpustu 10mm</li>
            <li>Do typoszeregu "Standard średnicy otwory F do otworu z wpustu J" dodano wpis 35mm / 38,3mm</li>
            <li>Poprawiono obliczanie akustyki w punkcie P1 dodajać brakujący nawias dla kolejności działań w członie "4*(1-Rz/(śr.obudowy*0.001))"</li>
            <li>Poprawiono, by przy liczeniu "Kąt alfa od osi obrotu" podczas obliczeń hałasu w punktach, używana była wartość wejściowa L1V a nie L1H</li>
            <li>Zmieniono log obliczeniowy przy liczeniu hałasu w punktach dla k1, zamieniając wartość "0.0174533" na "pi/180.0" by lepiej oddać w logu intencję, że jest to przeliczenie stopni na radiany</li>
            <li>Do algorytmu składania wirnika dodano pobieranie kąta beta z ustawień przepływowych profilu (z domyślną wartością 0 jezeli taki profil przepływowy nie istnieje)</li>
            <li>W "Złożenie wirnika" dodano opcjonalne kryterium "Powiększenie kąta na znaczniku [°]". Przy braku jego zadania, program postara się odnaleźć powiększenie wśród ustawień przepływowych profili lub 0, gdy go nie znajdzie (objaśniającą informację o tym dodano obok formularza)</li>
            <li>Z formowań usunięto interpretację kolumny "Powiększenie kąta beta [°]"</li>
            <li>Usunięto kolumnę "Powiększenie kąta beta [°]" z przykładowych danych demo/szablonów XLS do pobrania</li>
        </ul>


        <h2>13.14.0</h2>
        <ul>
            <li>Dodano interpretację nowych kolumn XLS kolumnę formowania: "Średnica minimalna [mm]" oraz "Średnica maksymalna [mm]"</li>
            <li>Dobór mechaniczny od teraz pomija dobieranie formowań, gdzei zadana średnica wirnika nie mieści się w min-max dozwolonej formowania. (Uwaga: nadal istnieje jeszcze dodatkowa liczona zasada min-max z -10% i +10% średnicy nominalnej)</li>
            <li>Wyprowadzono parametry kołpaka i jego części złącznych do eksportu do pliku DriveWorks</li>
            <li>Wzbogacono dane demo form łopat o przykładowe kolumny min-max średnicy</li>
            <li>Usunięto niepotrzebne "[mm]" z niektórych parametrów eksportu driveworks (typu: Pełna nazwa...)</li>
            <li>Zmieniono dane demo geometryczne dla form łopat, tak by x1/x2/y1/y2 były różne umożliwiając łatwiesze wyłapanie błędów</li>
            <li>Naprawiono błąd powielania cały czas wartości x1 zamiast x1,x2,y1,y2 w tabeli geometrii karty PDF</li>
            <li>Zwiększono lewy margines wykresu mocy podczas symulacji WWW i na karcie WWW, by nie ucinać wartości mocy 100 kW i większych</li>
            <li>Skrócono czas do wyświetlenia fomularza doboru/symulacji przy wejściu na stronę lub jej odświeżeniu</li>
            <li>Skrócono wyraźnie czas doboru</li>
            <li>Zastosowano nową, bardziej skondensowaną czionkę do wydruku PDF, która zawiera symbole greckie, umożliwiając wydruk β i α</li>
            <li>Zamieniono w kilku miejscach wystąpienia słów "alfa" i "beta" na znaki α i β</li>
            <li>Zmieniono wspis typoszeregu średnic podtrzymujących S50_65/82mm na S50A_65/82mm</li>
            <li>Dodano interpretację nowej kolumny formowań łopaty: "Powiększenie kąta beta [°]" (obecnie jest to pewna duplikacja z ustawieniami profilu przepływowego)</li>
            <li>Uzupełniono dane/szablon demo formowań łopat o nową kolumnę "Powiększenie kąta beta [°]"</li>
            <li>Dodano wyprowadzanie kąta beta w kolumnie dobranego kąta tabeli geomterii wirnika (WWW i PDF)</li>
        </ul>

        <h2>13.13.0</h2>
        <ul>
            <li>Dodano interpretację kolumn Geo_ w formowaniu łopaty w celu odczytu wymiarów dla kąta</li>
            <li>Wprowadzono obliczenia geometrii wirnika z aproksymacją dla konta dobranego i wyprowadzono tabelę wymiarów po kliknięciu "Szczegóły" na złożeniu wirnika WWW</li>
            <li>Uzupełniono XLS demo o przykładowe wartości geometrii</li>
            <li>Wyprowadzono prostą tabelę geometrii na kartę wentylatora WWW (gdy rozwiązano mechanikę)</li>
            <li>Wyłączono z prezentacji tabeli geometrii wartości dla kąta 0 oraz dla 36.0</li>
            <li>Wyprowadzono prostą tabelę geometrii na kartę wentylatora PDF (gdy rozwiązano mechanikę)</li>
            <li>Przy obliczaniu geometrii kąta zastosowano zaokrąglanie w góre do 5 z ograniceniem do maksymalnego wymiaru równemu takiemu jak dla kąta wyższego</li>
        </ul>

        <h2>13.12.0</h2>
        <ul>
            <li>Wzbogacono wykres rezonansów Campbell na WWW o jednostki</li>
            <li>Wprowadzono wykres rezonansów Campbell na kartę WWW wentylatora</li>
            <li>Wprowadzono wykres rezonansów Campbell na kartę PDF wentylatora</li>
            <li>Zmieniono grupowanie wyników na stronie Złożenie wirnika - grupę defniuje nie tylko długośc łopaty, ale też profil łopaty</li>
            <li>Na stronie żłożenie wirnika dodano nazwę profilu po numerze formy</li>
            <li>Na stronie złozenie wirnika wyprowadzono wielkość czopa przy długości łopaty</li>
            <li>Ustalono nowe sortowanie pod-wyników na złożeniu wirnika: najpierw czopy od najmniejszego, potem łopaty od najdłuższej</li>
        </ul>

        <h2>13.11.0</h2>
        <ul>
            <li>Rozbudowano "Standard średnic podtrzymujących sprzęgieł" o kilka nowych wartości</li>
            <li>Przy parametrze "Wykonanie łopat" karty wentylatora dodano zakres temperatur: (-40˚C - +65˚C) - zawsze taki sam, osadzony w programie</li>
            <li>Poprawiono wartość parametru Wiatr poprzeczny na karcie wentylatora - wyprowadzana jest teraz zadana prędkośc, zamiast napisu "Wiatr poprzeczny"</li>
            <li>Uzupełniono szereg brakujących tłumaczeń</li>
        </ul>

        <h2>13.10.0</h2>
        <ul>
            <li>Dodano krzywą oporu na wykresie ciśnienia karty PDF wentylatora</li>
            <li>Poprawiono jednostkę wydajności na wykresach PDF (było m/s)</li>
            <li>Na wykresach PDF dodano drugą oś X wydajności w m3/h</li>
            <li>Dodano możliwość konfigurowania powiększenia kąta na znaczniku w ustawieniach przepływowych profilu</li>
            <li>Dodano wyliczanie i prezentację na karcie wynikowej kąta na znaczniku beta (parametr jest zawsze dostępny nawet, gdy nie wyliczono mechaniki z sukcesem)</li>
            <li>Przyjąto zwiększenie dla kąta na znaczniku 0 dla wszytkich wcześniej stworoznych profili, oraz pewne przykładowe wartości dla profili demo</li>
            <li>Ukryto parametr kąta łopaty beta, jeżeli będzie on taki sam jak kąt łopaty alfa (czyli inkrementacja 0 stopni)</li>
            <li>Dodano obliczanie i wyprowadzono na kartę (sekcja parametrów mechanicznych) wartość siły osiowej od ciśnienia</li>
        </ul>

        <h2>13.9.0</h2>
        <ul>
            <li>Naprawiono błąd wydruku karty PDF dla wentylatora z nieudanym doborem mechanicznym (plik 0 bajtów)</li>
            <li>Wyrównano wielkość nagłówków wykresów na drugiej stronie karty PDF (tytuł wykresu mocy był większy)</li>
            <li>Dodano tytuł wykresu "Charakterystyka przepływowa" na karcie PDF</li>
            <li>Poprawiono oś X wykresu momentu (obroty, nie wydajność) i ujednolicono opis: "n[obr-1]"</li>
            <li>Usunięto zduplikowany parametr ciśnienia statycznego z karty wentylatora</li>
            <li>Poprawiono formatowanie wartości 0 na wykresach PDF (po prostu 0 zamiast 0.000)</li>
            <li>Dodano możliwość zadania realizacji z/bez sprzęgła w sekcji Wykonania, bez wpływu na mechanikę, ale z wyprowadzeniem wybranego wariantu do tytułu karty wentylatora</li>
            <li>Na karcie wentylatora WWW dodano linię oporu przechodzącą przez dobrany punkt</li>
        </ul>

        <h2>13.8.0</h2>
        <ul>
            <li>Zmieniono wzór na moment bezwładności piasty, dzieląc promień kołnierza sprzęgła przez 1000.0 ([mm] na [m]) i podnosząc do kwadratu ((promienKolnierzaSprzegla/1000.0)^2)</li>
            <li>Poprawiono przycięte logo na karcie PDF</li>
            <li>Wyprowadzono opis wykonania/wzmocnienia łopat obok tytułu na karcie WWW i PDF np. "Standard/W20". Człon wzmocnienia np. "/W20" występuje tylko przy właściwie policzonej mechanice.</li>
            <li>Do parametrów najistotniejszych (góra karty) na karcie PDF i WWW dodano gęstość. Lekko zorganizowano szerokości i skrócono "Kąt łopaty" na "Kąt łop."</li>
            <li>Naprawiono ucinanie jednostki na końcu osi X wykresów PDF</li>
            <li>Ulepszono formatowanie opisów osi na wydrukach PDF, unikając formatowania typu 3.00000004 (widocznego jako 0004)</li>
            <li>Na karcie wentylatora przeniesiono parametry prędkości (RPM, Uz) do grupy pierwszej</li>
            <li>Na karcie wentylatora przeniesiono parametry orientacji i strony sprzęgła do grupy drugiej</li>
            <li>Zmieniono tytuł drugiej sekcji na karcie wentylatora na "Konstrukcja wirnik-obudowa" (zamiast "Konstrukcja")</li>
            <li>Z karty wentylatora usunięto prezentację dodatkowych krzywych ciśnienia, poza dobranym kątem</li>
            <li>Zmieniono sposób wyliczania ciśnienia całkowitego w punkcie pracy: używane będzie ciśnienie statyczne wentylatora jako składnik, a nie ciśnienie instalacji</li>
            <li>Dodano do standardu średnic podtrzymującyhc sprzęgło wpis "S80_90" - "108"</li>
            <li>Wyłączono możliwość zadawania Dyfuzora w układzie tłoczącym (wcześniej to ograniczenie występowało tylko przy wentylatorach ACHE, teraz już dla każdego typu aplikacji)</li>
            <li>Zmieniono nazwę parametru karty "Częstotliwość drgań własnych" na "Częstotliwość drgań własnych BONF"</li>
            <li>Zmieniono nazwę parametru karty "Częstotliwość robocza" na "Częstotliwość robocza wirnika RF"</li>
            <li>Zmieniono nazwę parametru karty "Częstotliwość przejścia BPF" na "Częstotliwość przejścia łopat BPF"</li>
            <li>Zmieniono nazwę parametru karty "Rezonans BONF z BPD Do" na "Rezonans BONF z BPF Od"</li>
            <li>W wielu miejscach (w tym "Zgrubna...") zmieniono nazwę "siła odśrodkowa od łopaty" na "siła urwania łopaty". Pozostawiono "Siła odśrodkowa Fo" przy obliczeniach obciążenia czopa bez zmian.</li>
            <li>W wartości parametru Dyfuzor na karcie wentylatora wyprowadzono wysokośc i kąt</li>
            <li>Wyprowadzono na kartę wentylatora zadaną temperaturę ("Temperatura wlot")</li>
            <li>Na karcie wentylatora zastąpiono "Moc użyteczna w punkcie pracy" na rzecz "Moc na wale w punkcie pracy"</li>
            <li>Na karcie wentylatora dodano 4 nowy parametry: zapasy API oraz zapasy dla kąta</li>
            <li>Zmniejszono wysokość wykresu ciśnienia na karcie wentylatora PDF, ponieważ parametry nie mieściły się na stronie</li>
            <li>Przywróciono utracony przypadkiem tekst w nagłówku karty WWW opisu punktu pracy np. "W punkcie pracy 234.26 m³/s - 123.85 Pa"</li>
            <li>Wyprowadzono nazwę aplikacji nad tytułem karty wentylatora (nad nazwę wentylatora)</li>
            <li>Zmniejszono czcionki w tytułach karty WWW i PDF, by zaakomodować miejsce na nowe teksty</li>
            <li>Ujednolicono zakres skali wydajności (osi X) na charakterystykach karty wentylatora</li>
        </ul>

        <OldChanges/>
    </>;
}
