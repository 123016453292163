import React from 'react';
import {DimensionalImageDescriptor} from "../../business/core";
import {createInternationalizationPrefixer, resolveIstring} from "../../platform/i18n/i18n";

export function DimensionalImg(props: { descriptor: DimensionalImageDescriptor }) {

    let params = "";

    Object.entries(props.descriptor.dynamicLabelValues).forEach(([key, val]) => {
        params += "&" + key + "=" + resolveIstring(val)
    });

    return <img className={"img-fluid mx-auto d-block"} src={"/api/dynamic-image?template=" + props.descriptor.templateName + params}/>
}

DimensionalImg.propTypes = {}

DimensionalImg.defaultProps = {}
