import React, {useEffect, useState} from 'react';
import {StandardLayout} from "../../layout/StandardLayout";
import UboldSpinner from "../../ubold-components/UboldSpinner";
import MechanicalVerificationService from "./MechanicalVerificationService";
import {Card} from "../../components/Card";
import {FanConstructionMaterialsTable, hasCalculationComplexResult, singleCalculationLogListing} from "../../components/domain/SimulationDatasheet";
import {CalculationExplainer} from "../../components/domain/CalculationExplainer";
import {resolveParamTranslation} from "../../business/core";



function TextInput(props) {
    return <div className={"row" + (props.margin ? " mb-3" : "")}>
        <label className="col-12 col-md-4 col-form-label-sm text-md-end">{props.label}</label>
        <div className="col-12 col-md-8 col-xl-6">
            <input className="form-control form-control-sm" name={props.name} type="text" value={props.value} onChange={props.onChange}/>
        </div>


    </div>
}

function TextSelect(props) {
    return <div className={"row" + (props.margin ? " mb-3" : "")}>
        <label className="col-12 col-md-4 col-form-label-sm text-md-end">{props.label}</label>
        <div className="col-12 col-md-8 col-xl-6">
            <select name={props.name} className="form-control form-control-sm" onChange={props.onChange}>
                {props.options.map(o => <option key={o} id={o} value={o} checked={(props.value === o ? "checked" : null)}>{o}</option>)}
            </select>
        </div>
    </div>;
}

export default function MechanicalVerificationPage(props) {

    const [loading, setLoading] = useState(false);
    const [criteria, setCriteria] = useState({
        bladeProfile: "(14D)",
        bladeCount: 4,
        bladeAngle: 20.1,
        impellerDiameter: 2795,
        manufacturingTypeCode: "standard",
        rpm: 150,
        power: 3100,
        shaftDiameter: 38,
        shaftLength: 80,
        expectedImpellerHeight: 140,
        enforcedAssemblyId: null,
        enforcedFormingId: null,
        enforcedClutchId: null,
        joinElementsMaterial: "hdg",
        inquiryNumber: "0",
        caseNumber: "0"
    });
    const [calculatedAttributes, setCalculatedAttributes] = useState([]);
    const [mainLog, setMainLog] = useState([]);
    const [solutionLog, setSolutionLog] = useState([]);
    const [availablePofiles, setAvailableProfiles] = useState(["..."]);
    const [resultCalculations, setResultCalculations] = useState([]);
    const [resultType, setResultType] = useState(null);
    const [fanConstructionMaterials, setFanConstructionMaterials] = useState([]);

    useEffect(() => {
        MechanicalVerificationService.getAvailableProfiles().then(profiles => setAvailableProfiles(profiles));
    }, []);

    function formChanged(e) {
        if (e.target.name === 'bladeAngle' ||
            e.target.name === 'impellerDiameter' ||
            e.target.name === 'rpm' ||
            e.target.name === 'power' ||
            e.target.name === 'shaftDiameter' ||
            e.target.name === 'shaftLength') {
            e.target.value = e.target.value.replace(",", ".");
        }
        setCriteria({...criteria, [e.target.name]: e.target.value});
    }

    function verify() {
        setLoading(true);
        const verificationImpellerName = 'weryfikacja-' + criteria.bladeCount + '-' + criteria.impellerDiameter + '-' + criteria.bladeProfile;
        setCalculatedAttributes([]);
        setMainLog([]);
        setSolutionLog([]);
        setResultCalculations([]);
        setResultType(null);
        setFanConstructionMaterials([])
        MechanicalVerificationService.performVerificationAsync({...criteria, requestSource: 'verification', impellerName: verificationImpellerName})
            .then(mechanicalVerificationResponse => {
                console.log(mechanicalVerificationResponse);
                setCalculatedAttributes(mechanicalVerificationResponse.calculatedAttributes);
                setMainLog(mechanicalVerificationResponse.mainLog);
                setSolutionLog(mechanicalVerificationResponse.solutionLog);
                setResultCalculations(mechanicalVerificationResponse.calculations);
                setResultType(mechanicalVerificationResponse.resultType);
                setFanConstructionMaterials(mechanicalVerificationResponse.fanConstructionMaterials);
            })
            .finally(() => {
                setLoading(false);
            }); // TECHDEBT catch error
    }

    const calculatesAttributesTable = <table className="table table-sm table-striped">
        <thead>
        <tr>
            <th>Atrybut</th>
            <th>Wartość</th>
            <th>Wpisy obliczeń zawierające atrybut</th>
        </tr>
        </thead>
        <tbody>
        {calculatedAttributes.map(ca => <tr>
            <td className="text-right" style={{width: "30%"}}>{ca.name}</td>
            <td className="text-left" style={{width: "20%"}}><strong>{ca.value}</strong></td>
            <td className="text-left">
                {solutionLog.filter(msg => msg.includes(ca.name)).map((item, key) => {
                    return <span style={{fontFamily: 'monospace', fontSize: '12px', lineHeight: '8px', letterSpacing: "-0.5px"}} key={key}>{item}<br/></span>
                })}
            </td>
        </tr>)}
        </tbody>
    </table>;

    const calculationToTr = (calculation) => {
        return <tr>
            <td className="text-end text-dark fw-bolder fs-6">{resolveParamTranslation(calculation.humanFriendlyNameIk)}</td>
            {typeof calculation.value === "string" && <td className="text-end text-dark fw-bolder fs-6 pe-0">{calculation.value}</td>}
            {typeof calculation.value === "number" && <td className="text-end text-dark fw-bolder fs-6 pe-0">{calculation.value.toFixed(2)}</td>}
            {hasCalculationComplexResult(calculation) && <td className="text-end text-dark fw-bolder fs-6 pe-0">???</td>}
            <td className="text-end text-muted fw-bold">{calculation.unit}</td>
            <td className="">
                <CalculationExplainer calculations={resultCalculations} explainedCalculationCode={calculation.code}/>
            </td>
        </tr>;
    };

    const resultsCard = <div className="col-12">
        <Card header={"Rezultaty"} additionalClassNames={"mt-4"}>

            {(loading ? <div className={"text-center"}><UboldSpinner/></div> : null)}

            {resultType && resultType === "SUCCESS" && <div className={"alert alert-success"}>Udało się dokonać poprawnego doboru mechanicznego</div>}
            {resultType && resultType === "NO_RESULTS" && <div className={"alert alert-danger"}>Nie udało się dokonać poprawnego doboru mechanicznego</div>}
            {fanConstructionMaterials.length > 0 && <FanConstructionMaterialsTable materials={fanConstructionMaterials}/>}

            {<table>
                <table className="table align-middle gs-1 gy-2">
                    <thead>
                    <tr>
                        <th className="p-0 min-w-150px"></th>
                        <th className="p-0 min-w-120px"></th>
                        <th className="p-0 min-w-40px"></th>
                        <th className="p-0"></th>
                    </tr>
                    </thead>
                    <tbody>
                    {resultCalculations.filter(c => !hasCalculationComplexResult(c)).map(calculationToTr)}
                    </tbody>
                </table>
            </table>}
        </Card>
    </div>;

    const troubleshootingCard = <div className="row">
        <div className="col-12">
            <Card header={"Logi obliczeniowe"} additionalClassNames={"mt-4"}>
                <h5 className="card-title">Pełny jednolity log obliczeniowy</h5>
                {(loading ? <div className={"text-center"}><UboldSpinner/></div> : null)}



                {resultCalculations.map((calc) => singleCalculationLogListing(calc))}
            </Card>
        </div>
    </div>;

    return <>
        <div className="row">
            <div className="col-12">
                <Card header={"Weryfikacja doboru mechanicznego"}>
                    <div className="row">
                        <div className="col-6">
                            <h4 className="text-gray-400 fw-bold mb-2 text-center">Podstawowe dane z doboru wentylatora</h4>
                            <TextSelect label="Profil łopat" name="bladeProfile" options={availablePofiles} margin onChange={formChanged} value={criteria.profile}/>
                            <TextInput label="Ilość łopat [szt]" name="bladeCount" margin onChange={formChanged} value={criteria.bladeCount}/>
                            <TextInput label="Kąt łopat [°]" name="bladeAngle" margin onChange={formChanged} value={criteria.bladeAngle}/>
                            <TextInput label="Średnica wirnika [mm]" name="impellerDiameter" margin onChange={formChanged} value={criteria.impellerDiameter}/>
                            <TextSelect label="Wykonanie" name="manufacturingTypeCode" options={["standard", "assg"]} margin onChange={formChanged} value={criteria.manufacturingTypeCode}/>
                            <TextInput label="Prędkość obrotowa [min-1]" name="rpm" margin onChange={formChanged} value={criteria.rpm}/>
                            <TextInput label="Moc w punkcie pracy [W]" name="power" margin onChange={formChanged} value={criteria.power}/>
                        </div>
                        <div className="col-6">
                            <h4 className="text-gray-400 fw-bold mb-2 text-center">Dane mechaniczne użytkownika</h4>
                            <TextInput label="Średnica wału" name="shaftDiameter" margin onChange={formChanged} value={criteria.shaftDiameter}/>
                            <TextInput label="Długość wału" name="shaftLength" margin onChange={formChanged} value={criteria.shaftLength}/>
                            <TextInput label="Oczekiwana wysokość wirnika" name="expectedImpellerHeight" margin onChange={formChanged} value={criteria.expectedImpellerHeight}/>
                            <hr/>

                            <h4 className="text-gray-400 fw-bold mb-2 text-center">Zawężenie możliwych konstrukcji</h4>
                            <TextInput label="Identyfikator formowania" name="enforcedFormingId" margin onChange={formChanged} value={criteria.enforcedFormingId}/>
                            <TextInput label="Identyfikator sprzęgła" name="enforcedClutchId" margin onChange={formChanged} value={criteria.enforcedClutchId}/>
                            <TextInput label="Kod materiału części zł. (hdg, a2, a4)" name="joinElementsMaterial" margin onChange={formChanged} value={criteria.joinElementsMaterial}/>
                        </div>
                        <div className="col-12 mt-3 text-center">
                            <button className="btn btn-primary" onClick={verify}>Oblicz</button>
                        </div>
                    </div>
                </Card>
            </div>

            {(resultType !== null || loading) && resultsCard}
            {resultType !== null && troubleshootingCard}

        </div>


    </>;
}

MechanicalVerificationPage.propTypes = {}

MechanicalVerificationPage.defaultProps = {}