import React, {useCallback} from 'react';
import Dropzone from 'react-dropzone-uploader'
import {PreviewForPendingFileWithUboldProgressBar, processStatusChange, removeFileFromDslValueById} from "./BasicDropzoneUploadWebFormComponent";
import './dropzone-styles-customizer.css'
import UboldInvalidFeedbackList from "../../ubold-components/UboldInvalidFeedbackList";

/**
 * One of the functions related to carshing when file was dragged over the field on Safari.
 * Somehow related to: https://github.com/react-dropzone/react-dropzone/issues/726
 * Fortunatelly library creator allowed to inject custom implementations into component.
 * The change is about let items = null to let items =[]
 * But actually what was crucial for that fix is rather inject dummy getDataTransferItemsFromEvent
 * why ? because that function was called on drag OVER, not drop, and drag over was problematic.
 * More details: there was massive spam with unresolved promises when draging over the field.
 * If system had unhenadler promise rejection function it end up with error page.
 * If system had not, then it was freezing for some time and then working ok and processing uploaded file.
 */
export const getFilesFromEventFixed = (
    event, // : React.DragEvent<HTMLElement> | React.ChangeEvent<HTMLInputElement>
)/*: Array<File | DataTransferItem> */=> {

    let items = []

    if ('dataTransfer' in event) {
        const dt = event.dataTransfer

        // NOTE: Only the 'drop' event has access to DataTransfer.files, otherwise it will always be empty
        if ('files' in dt && dt.files.length) {
            items = dt.files
        } else if (dt.items && dt.items.length) {
            items = dt.items
        }
    } else if (event.target && event.target.files) {
        items = event.target.files
    }

    return Array.prototype.slice.call(items)
}

/**
 * @see getFilesFromEventFixed
 */
export const getDatatransferFixed = () => {
    return [];
}


export default function BasicDropzoneImageUploadWebFormComponent(props) {

    const getUploadParams = ({file, meta}) => {
        return {
            mathod: 'post',
            url: '/api/hosted-image/create',
            headers: {"Accept": "application/json"}
        }
    };

    const dropFilesHere = <div className="text-center mt-3 mb-2">
        <i className="fe-upload-cloud  text-muted"></i> {props.dsl.configuration.uploadLabel}
    </div>;


    // Input customization is done mostly because original <label> thrown warning about no key=
    const Input = ({accept, onFiles, files, getFilesFromEvent}) => {

        return (
            <label style={{cursor: 'pointer'}}>
                <input
                    style={{display: 'none'}}
                    type="file"
                    accept={accept}
                    multiple={props.dsl.configuration.maxFileCount < 2 ? null : ""}
                    onChange={async e => {
                        const target = e.target
                        const chosenFiles = await getFilesFromEventFixed(e)
                        onFiles(chosenFiles)
                        target.value = null
                    }}
                />
                {dropFilesHere}
            </label>
        )
    };

    const PresenterForValueFile = useCallback((presenterProps) => {
        return <div className="row mt-2 mb-2">
            <div key={"thumbnail"} className="col-11 text-left">
                <img alt="thumbnail" className={"img-thumbnail"} src={"/api/hosted-image/preview/" + encodeURIComponent(presenterProps.fileValue.id) + "?processingDsl=" + encodeURIComponent(presenterProps.thumbnailProcessingDsl)}/>
                <button className="btn btn-xs  btn-link" onClick={(e) => removeFileFromDslValueById(e, presenterProps.dsl, presenterProps.fileValue.id, presenterProps.onDslChange)}><i className="fe-x-circle h4 text-danger"></i></button>
            </div>
            <div key={"additional-column"} className="col-1 text-left">
            </div>
        </div>
    }, []);

    const Lay = useCallback(({input, previews, submitButton, dropzoneProps, files, extra}) => {
        const maxFiles = extra.maxFiles;
        const dsl = props.dsl;
        return (
            <div>
                <div key={"previews"}>

                    {previews}
                </div>
                {dsl.value.length < maxFiles &&
                [
                    <div key={"input"} {...dropzoneProps}>
                        {dsl.value.length < maxFiles && input}
                    </div>,
                    <div key={"unused-submit"}>
                        {dsl.value.length > 0 && submitButton}
                    </div>
                ]}
            </div>
        )
    }, [props]);

    return <div className="row mt-2">
        <label className="col-4 col-form-label">{props.dsl.configuration.label}</label>
        <div className="col-8">
            {(props.dsl.value.map(v => <PresenterForValueFile
                key={v.id}
                fileValue={v}
                dsl={props.dsl}
                thumbnailProcessingDsl={props.dsl.configuration.thumbnailProcessingDsl}
                onDslChange={props.onDslChange}/>))}
            <Dropzone accept={"image/*"}
                      getFilesFromEvent={getFilesFromEventFixed}
                      getDataTransferItemsFromEvent={getDatatransferFixed}
                      multiple={false}
                      autoUpload={true}
                      disabled={false}
                      maxFiles={props.dsl.configuration.maxFileCount}
                      canCancel={true}
                      canRemove={true}
                      getUploadParams={getUploadParams}
                      canRestart={false}
                      inputContent={dropFilesHere}
                      inputWithFilesContent={dropFilesHere}
                      submitButtonDisabled={true}
                      submitButtonContent={<span vale={33}></span>}
                      LayoutComponent={Lay}
                      InputComponent={Input}
                      PreviewComponent={PreviewForPendingFileWithUboldProgressBar}
                      onChangeStatus={(fileWithMeta, status, filesWithMeta) => processStatusChange(fileWithMeta, status, filesWithMeta, props.dsl, props.onDslChange)}/>
            <UboldInvalidFeedbackList errors={props.dsl.errors}/>

        </div>
    </div>;
}

BasicDropzoneImageUploadWebFormComponent.propTypes = {}

BasicDropzoneImageUploadWebFormComponent.defaultProps = {}