import React from 'react';
import DynamicWebServiceDrivenForm from "../../platform/webform/DynamicWebServiceDrivenForm";
import {useHistory, useNavigate, useParams} from "react-router";
import {Card} from "../../components/Card";
import {createInternationalizationPrefixer} from "../i18n/i18n";

const t = createInternationalizationPrefixer("AdminChangePasswordPage");

export default function AdminChangePasswordPage(props) {

    const {id} = useParams();
    const navigate = useNavigate();


    return <Card header={t("Zmień hasło użytkownika")}>
        <div className="card">
            <div className="card-body">
                <DynamicWebServiceDrivenForm
                    dslUrl={"/api/user-account/" + id + "/change-password"}
                    submissionUrl={"/api/user-account/" + id + "/change-password"}
                    submitLabel={t("Ustaw")}
                    successHandler={() => {
                        alert(t("Hasło zostało zmienione!"));
                        navigate('/accounts')
                    }}/>
            </div>
        </div>
    </Card>;
}

AdminChangePasswordPage.propTypes = {}

AdminChangePasswordPage.defaultProps = {}