import React, {useCallback} from "react";
import {OneOfEditor} from "form/editors/OneOfEditor";

export function MetronicOneOfEditor(props: { editor: OneOfEditor, additionalClassString?: string }) {
    const propagateChangeEventToEditor = useCallback((event: React.FormEvent<HTMLSelectElement>) => {
        props.editor.populate(event.currentTarget.value);
    }, [props.editor]);

    return <select className={"form-control form-control form-control-solid fs-7 p-2 " + (props.additionalClassString ?? "")} value={props.editor.value}
                   onChange={propagateChangeEventToEditor}>
        {props.editor.options.map(opt => <option value={opt.key} key={opt.key}>{opt.label}</option>)}
    </select>
}

MetronicOneOfEditor.withAdditionalClassString = (classString: string) => {
    return (props: { editor: OneOfEditor }) => MetronicOneOfEditor({...props, additionalClassString: classString});
}
