import React from 'react';
import {cloneJson} from "../util/JsonObjectCloner";
import {MetronicInputText} from "../../metronic-components/MetronicInputText";

export default function OptionalLongFieldWebFormComponent(props) {

    const dsl = props.dsl;

    function change(e) {
        const newDsl = cloneJson(dsl);
        newDsl.value = e.target.value;
        newDsl.errors = [];
        if (isNaN(newDsl.value)) {
            newDsl.value = null;
        } else {
            newDsl.value = parseInt(newDsl.value);
        }
        console.log("Optional Long field requests update to '" + newDsl.value + "'");
        props.onDslChange(newDsl);
    }

    // "no integer" is represented as null, which would trigger warning on text react text field. Convert to empty string for presentation
    if (dsl.value === null) {
        dsl.value = "";
    }
    return <MetronicInputText type="number" label={dsl.configuration.label} value={dsl.value} errors={dsl.errors} onChange={change} placeholder={dsl.configuration.placeholder}/>

}

OptionalLongFieldWebFormComponent.propTypes = {}

OptionalLongFieldWebFormComponent.defaultProps = {}