import React, {useEffect, useState} from 'react';
import {Card} from "../../components/Card";
import UboldSpinner from "../../ubold-components/UboldSpinner";
import {SelectOption, useSelectFilterManager, useTextFilterManager} from "../../platform/search/PlatformSearch";
import {AdditionalSelectFilter, AdditionalTextFilter} from "../../components/search/MetronicSearchComponents";
import useImpellerAssemblySearch, {BLADE_MANUFACTURING_TYPES, BladeManufacturingTypeCode, ImpellerAssemblyCriteria, MechanicalSelectionChoiceJson, MechanicalSelectionH3RangeEstimationJson} from "./ImpellerAssemblyService";
import {useFetchAllObjs} from "platform/hooks/PlatformTypeScriptHooks";
import {FormattedMechanicalSelectionCalculationLog} from "./FormattedMechanicalSelectionCalculationLog";
import {ImpellerAssemblyResultsForForm} from "./ImpellerAssemblyResultsForForm";
import {ErrorTreePresenter} from "./ErrorTreePresenter";
import {WentechMeasuringStandards} from "../../business/measurement/WentechMeasurementStandards";
import {LabeledEditor, useOptionalMeasurementEditor, useOptionalOneOfEditor} from "../selection/useFanCriteria";
import {createInternationalizationPrefixer} from "../../platform/i18n/i18n";
import {ImpellerAssemblyH3EstimationResultCard} from "./ImpellerAssemblyH3EstimationResultCard";


const t = createInternationalizationPrefixer("FanSelectionCriteriaControls");

export function ImpellerAssemblyPage() {

    const availableShaftDiameters: number[] | null = useFetchAllObjs<number[]>("/api/mechanical/available-shaft-diameters/all");
    if (availableShaftDiameters == null) {
        return <div className={"text-center mt-5"}><UboldSpinner/></div>;
    }
    return <ImpellerAssemblyPageLoaded availableShaftDiameters={availableShaftDiameters}/>;
}

export function ImpellerAssemblyPageLoaded(props: {
    availableShaftDiameters: number[]
}) {

    /// NEW UI - gradualy migrating to reuse shared criteria components

    const shaftDiameterEditor = useOptionalOneOfEditor(props.availableShaftDiameters.map(asd => ({key: "" + asd, label: asd + " mm"})), "" + props.availableShaftDiameters[0], false);
    const shaftLengthEditor = useOptionalMeasurementEditor(WentechMeasuringStandards.SHAFT_LENGTH, false);
    const impellerHeightEditor = useOptionalMeasurementEditor(WentechMeasuringStandards.IMPELLER_HEIGHT, false);


    /// OLD UI


    const availableBladeProfiles = useFetchAllObjs<string[]>("/api/mechanical-blade-profiles");

    const bladeCountFilterManager = useTextFilterManager("bladeCount", "Ilość łopat", true, "", true);
    const bladeManufacturingFilterManager = useSelectFilterManager("bladeManufacturing", "Wykonanie łopat", BLADE_MANUFACTURING_TYPES.map<SelectOption>(bmt => ({
        label: bmt.name,
        value: bmt.typeCode
    })));
    const impellerDiameterFilterManager = useTextFilterManager("impellerDiameter", "Średnica wirnika [mm]", true, "", true);
    //const shaftDiameterFilterManager = useTextFilterManager("shaftDiameter", "Średnica wału [mm]", true, "", true);
    //const shaftLengthFilterManager = useTextFilterManager("shaftLength", "Długość wału [mm]", true, "", true);
    //const expectedImpellerHeightFilterManager = useTextFilterManager("expectedImpellerHeight", "Oczekiwana wysokość wirnika [mm]", true, "", true);
    //const bladeProfileDisplayNameFilterManager = useTextFilterManager("bladeProfileDisplayName", "Profil łopaty np. (14)");


    const fetchedProfilesAsOptions: {
        label: string,
        value: string
    }[] = availableBladeProfiles !== null ? (availableBladeProfiles.map(p => ({label: p, value: p}))) : [];
    const bladeProfileDisplayNameFilterManager = useSelectFilterManager("bladeProfileDisplayName", "Profil łopaty", [
        {label: "Dowolny", value: ""},
        ...fetchedProfilesAsOptions
    ]);

    const bladeAngleFilterManager = useTextFilterManager("bladeAngle", "Kąt łopat [°]", true, "", true);

    // temporarily not displayed
    const betaIncrementationFilterManager = useTextFilterManager("betaIncrementation", "Powiększenie kąta na znaczniku [°]", true, "", true);

    const rpmFilterManager = useTextFilterManager("rpm", "RPM [min-1]", true, "", true);
    const bladeEndSpeedFilterManager = useTextFilterManager("bladeEndSpeed", "Prędkość końca łopaty [m/s]", true, "", true);
    const powerAtWorkingPointFilterManager = useTextFilterManager("powerAtWorkingPoint", "Moc w punkcie pracy [kW]", true, "", true);
    const totalPressureAtWorkingPointFilterManager = useTextFilterManager("totalPressureAtWorkingPoint", "Ciśnienie całkowite w punkcie pracy [Pa]", true, "", true);
    const softStartPresentFilterManager = useSelectFilterManager("softStartPresent", "Obecny softstart/falownik", [
        {value: "false", label: "Nie"},
        {value: "true", label: "Tak"}
    ]);
    const preferredConstructionMaterialCodeFilterManager = useSelectFilterManager("preferredConstructionMaterialCode", "Materiał cz. konstrukcyjncych", [
        {label: "HDG (Wykonanie standardowe)", value: "HDG"},
        {label: "A2", value: "A2"},
        {label: "A4", value: "A4"},
        {label: "AISI 316", value: "AISI 316"},
        {label: "AISI 316L", value: "AISI 316L"},
    ]);
    const preferredFastenersMaterialCodeFilterManager = useSelectFilterManager("preferredFastenersMaterialCode", "Materiał cz. złącznych", [
        {label: "HDG (Wykonanie standardowe)", value: "HDG"},
        {label: "A2", value: "A2"},
        {label: "A4", value: "A4"},
        {label: "AISI 316", value: "AISI 316"},
        {label: "AISI 316L", value: "AISI 316L"},
    ]);
    // TODO Fetch from backend, now there is duplication
    const fanMountingPositionNameFilterManager = useSelectFilterManager("fanMountingPositionName", "Pozycja wirnika", [
        {value: "WAS1", label: "WAS1 - Poziomo, sprzęgło na wlocie, podpory strona przeciwna do sprzęgła"},
        {value: "WAS2", label: "WAS2 - Poziomo, sprzęgło na wlocie, podpory strona sprzęgła"},
        {value: "WBS1", label: "WBS1 - Poziomo, sprzęgło na wylocie, podpory strona przeciwna do sprzęgła"},
        {value: "WBS2", label: "WBS2 - Poziomo, sprzęgło na wylocie, podpory strona sprzęgła"},
        {value: "WCS1", label: "WCS1 - Pionowo, sprzęgło na wlocie, podpory strona przeciwna do sprzęgła"},
        {value: "WCS2", label: "WCS2 - Pionowo, sprzęgło na wlocie, podpory strona sprzęgła"}
    ]);
    // TODO Fetch from backend, now there is duplication
    const auditSeverityFilterManager = useSelectFilterManager("auditSeverityName", "Rygor audytu mechaniki", [
        {value: "OVERLOADED_CLUTCH_ALLOWED", label: "Pozwól tylko na przeciążone sprzęgło"},
        {value: "ALL_OVERLOADS_ALLOWED", label: "Dopuść wszystkie rozwiązania (w tym czop i długie sprzęgło)"}
    ]);
    const allowDemoDataFilterManager = useSelectFilterManager("allowDemoData", "Użycie przykładowych danych demo", [
        {value: "false", label: "Nie"},
        {value: "true", label: "Tak"}
    ]);
    const allowTooLongClutch = useSelectFilterManager("allowTooLongClutch", "Pozwól przekroczyć maks. dł. sprzęgła", [
        {value: "false", label: "Nie"},
        {value: "true", label: "Tak"}
    ]);

    const [criteria, setCriteria] = useState<undefined | ImpellerAssemblyCriteria>();

    const {
        result: {
            choices,
            calculations: mainCalculations,
            h3RangeEstimation,
            errorTree
        },
        loading
    } = useImpellerAssemblySearch(criteria);


    useEffect(() => {
        if (bladeCountFilterManager.currentFilterOrNull?.value !== undefined && parseInt(bladeCountFilterManager.currentFilterOrNull.value as string) > 0 &&
            impellerDiameterFilterManager.currentFilterOrNull?.value !== undefined && parseInt(impellerDiameterFilterManager.currentFilterOrNull.value as string) > 0 &&
            (bladeManufacturingFilterManager.currentFilterOrNull?.value !== undefined && bladeManufacturingFilterManager.currentFilterOrNull.value) &&
            (preferredConstructionMaterialCodeFilterManager.currentFilterOrNull?.value !== undefined && preferredConstructionMaterialCodeFilterManager.currentFilterOrNull.value) &&
            (preferredFastenersMaterialCodeFilterManager.currentFilterOrNull?.value !== undefined && preferredFastenersMaterialCodeFilterManager.currentFilterOrNull.value) &&
            (fanMountingPositionNameFilterManager.currentFilterOrNull?.value !== undefined && fanMountingPositionNameFilterManager.currentFilterOrNull.value) &&
            (auditSeverityFilterManager.currentFilterOrNull?.value !== undefined && auditSeverityFilterManager.currentFilterOrNull.value)
        ) {

            setCriteria({
                bladeCount: parseInt(bladeCountFilterManager.currentFilterOrNull.value as string),
                impellerDiameter: parseInt(impellerDiameterFilterManager.currentFilterOrNull.value as string),
                bladeManufacturing: bladeManufacturingFilterManager.currentFilterOrNull.value as BladeManufacturingTypeCode,
                shaftDiameter: shaftDiameterEditor.value === null ? null : parseInt(shaftDiameterEditor.value as string),
                shaftLength: shaftLengthEditor.value === null ? null : shaftLengthEditor.value,
                expectedImpellerHeight: impellerHeightEditor.value === null ? null : impellerHeightEditor.value,
                bladeProfileDisplayName: bladeProfileDisplayNameFilterManager.currentFilterOrNull === null ? null : bladeProfileDisplayNameFilterManager.currentFilterOrNull.value as string,
                bladeAngle: bladeAngleFilterManager.currentFilterOrNull === null ? null : parseFloat(bladeAngleFilterManager.currentFilterOrNull.value as string),
                betaIncrementation: betaIncrementationFilterManager.currentFilterOrNull === null ? null : parseFloat(betaIncrementationFilterManager.currentFilterOrNull.value as string),
                preferredConstructionMaterialCode: preferredConstructionMaterialCodeFilterManager.currentFilterOrNull.value as string,
                preferredFastenersMaterialCode: preferredFastenersMaterialCodeFilterManager.currentFilterOrNull.value as string,
                rpm: rpmFilterManager.currentFilterOrNull === null ? null : parseInt(rpmFilterManager.currentFilterOrNull.value as string),
                bladeEndSpeed: bladeEndSpeedFilterManager.currentFilterOrNull === null ? null : parseFloat(bladeEndSpeedFilterManager.currentFilterOrNull.value as string),
                powerAtWorkingPoint: powerAtWorkingPointFilterManager.currentFilterOrNull === null ? null : parseFloat(powerAtWorkingPointFilterManager.currentFilterOrNull.value as string),
                totalPressureAtWorkingPoint: totalPressureAtWorkingPointFilterManager.currentFilterOrNull === null ? null : parseFloat(totalPressureAtWorkingPointFilterManager.currentFilterOrNull.value as string),
                softStartPresent: softStartPresentFilterManager.currentFilterOrNull === null ? null : softStartPresentFilterManager.currentFilterOrNull.value as string === "true",
                fanMountingPositionName: fanMountingPositionNameFilterManager.currentFilterOrNull.value as string,
                auditSeverityName: auditSeverityFilterManager.currentFilterOrNull.value as string,
                allowDemoData: allowDemoDataFilterManager.currentFilterOrNull === null ? null : allowDemoDataFilterManager.currentFilterOrNull.value as string === "true",
                allowTooLongClutch: allowTooLongClutch.currentFilterOrNull === null ? null : allowTooLongClutch.currentFilterOrNull.value as string === "true",
            });
        }

    }, [
        bladeCountFilterManager.currentFilterOrNull,
        impellerDiameterFilterManager.currentFilterOrNull,
        bladeManufacturingFilterManager.currentFilterOrNull,
        shaftDiameterEditor.value,
        shaftLengthEditor.value,
        impellerHeightEditor.value,
        bladeProfileDisplayNameFilterManager.currentFilterOrNull,
        bladeAngleFilterManager.currentFilterOrNull,
        betaIncrementationFilterManager.currentFilterOrNull,
        preferredConstructionMaterialCodeFilterManager.currentFilterOrNull,
        preferredFastenersMaterialCodeFilterManager.currentFilterOrNull,
        rpmFilterManager.currentFilterOrNull,
        bladeEndSpeedFilterManager.currentFilterOrNull,
        powerAtWorkingPointFilterManager.currentFilterOrNull,
        totalPressureAtWorkingPointFilterManager.currentFilterOrNull,
        softStartPresentFilterManager.currentFilterOrNull,
        fanMountingPositionNameFilterManager.currentFilterOrNull,
        auditSeverityFilterManager.currentFilterOrNull,
        allowDemoDataFilterManager.currentFilterOrNull,
        allowTooLongClutch.currentFilterOrNull,
    ])


    return <>
        <Card header={"Złożenie wirnika"}
              subHeader={"Funkcja labolatoryjna do dokonywania mechanicznego złożenia wirnika (bez konkretnych warunków pracy i wykonania części symulacyjnej mechaniki)"}
              additionalClassNames={"mb-3"}>
            <div className="row">
                <div className="col-9">
                    <div className={"col-12 mb-3"}>
                        <div className={"row"}>
                            <div className="col-4 mb-3">
                                <AdditionalTextFilter fanNameFilterManager={bladeCountFilterManager}/>
                            </div>
                            <div className="col-4 mb-3">
                                <AdditionalTextFilter fanNameFilterManager={impellerDiameterFilterManager}/>
                            </div>
                            <div className="col-4 mb-3">

                            </div>
                            <div className="col-4 mb-3">
                                <AdditionalSelectFilter filterManager={bladeManufacturingFilterManager}/>
                            </div>
                            <div className="col-4 mb-3">
                                <AdditionalSelectFilter filterManager={preferredConstructionMaterialCodeFilterManager}/>
                            </div>
                            <div className="col-4 mb-3">
                                <AdditionalSelectFilter filterManager={preferredFastenersMaterialCodeFilterManager}/>
                            </div>

                            <div className="col-12 mb-3">
                                <AdditionalSelectFilter filterManager={fanMountingPositionNameFilterManager}/>
                            </div>

                            <div className="col-8 mb-3">
                                <AdditionalSelectFilter filterManager={auditSeverityFilterManager}/>
                            </div>
                            <div className="col-4 mb-3">
                                <AdditionalSelectFilter filterManager={allowDemoDataFilterManager}/>
                            </div>
                            <div className="col-4 mb-3">
                                <AdditionalSelectFilter filterManager={allowTooLongClutch}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-3">
                    <p className={"text-muted pt-3"}>
                    Minimalny wymagany zestaw parametrów konstrukcji mechanicznej.
                    </p>
                </div>

                <div className="col-12">
                    <div className="separator separator-dashed mb-4"/>
                </div>

                <div className={"col-9 mb-3"}>
                    <div className={"row"}>
                        <div className={"col-4 mb-3"}>
                            <LabeledEditor label={t("Średnica wału")} editor={shaftDiameterEditor} leftCols={5} rightCols={7}/>
                        </div>
                        <div className={"col-4 mb-3"}>
                            <LabeledEditor label={t("Długość wału")} editor={shaftLengthEditor} leftCols={5} rightCols={7}/>
                        </div>
                        <div className={"col-4 mb-3"}>
                            <LabeledEditor label={t("Wysokość wirnika")} editor={impellerHeightEditor} leftCols={5} rightCols={7}/>
                        </div>

                        <div className={"col-4 mb-3"}>
                            <AdditionalSelectFilter filterManager={bladeProfileDisplayNameFilterManager}/>
                        </div>
                        <div className={"col-4 mb-3"}>
                            <AdditionalTextFilter fanNameFilterManager={bladeAngleFilterManager}/>
                        </div>
                        {/* <div className={"col-4 mb-3"}>
                            <AdditionalTextFilter fanNameFilterManager={betaIncrementationFilterManager}/>
                        </div>*/}

                        <div className={"col-4 mb-3"}>
                            <AdditionalTextFilter fanNameFilterManager={rpmFilterManager}/>
                        </div>
                        <div className={"col-4 mb-3"}>
                            <AdditionalTextFilter fanNameFilterManager={bladeEndSpeedFilterManager}/>
                        </div>
                    </div>
                </div>
                <div className={"col-3"}>
                    <p className={"text-muted pt-3"}>
                        Kryteria opcjonalne
                    </p>
                    <p className={"text-muted pt-3"}>
                        Przy braku zadania powiększenia kąta na znaczniku, program spróbuje pobrać je z ustawień profili przepływowych lub przyjmie 0, gdy nie odnajdzie w ustawieniach przepływowych.
                    </p>
                </div>

                <div className="col-12">
                    <div className="separator separator-dashed mb-4"/>
                </div>

                <div className={"col-9 mb-3"}>
                    <div className={"row"}>
                        <div className={"col-4 mb-3"}>
                            <AdditionalTextFilter fanNameFilterManager={powerAtWorkingPointFilterManager}/>
                        </div>

                        <div className={"col-4 mb-3"}>
                            <AdditionalTextFilter fanNameFilterManager={totalPressureAtWorkingPointFilterManager}/>
                        </div>

                        <div className={"col-4 mb-3"}>
                            <AdditionalSelectFilter filterManager={softStartPresentFilterManager}/>
                        </div>

                    </div>
                </div>
                <div className={"col-3"}>
                    <p className={"text-muted pt-3"}>
                        Kryterium przeprowadzenia nie tylko złożenia, ale też symulacji mechanicznej (uwaga - tylko przy
                        podanym także rpm).
                    </p>
                </div>

                <div className="col-12">
                    <div className="separator separator-dashed mb-4"/>
                </div>

                <p className={"text-muted pt-3"}>
                    W przypadku braku zadania średnicy wału, poszukiwanie złożenia dla konkretnej kombinacji
                    formy/podpór zostanie przerwane po znalezieniu jakiegokolwiek jednego udanego złożenia na dowolnym
                    sprzęgle/wale (w celu ograniczenia ilości możliwych rezultatów i uniknięcia wyników dla każdej
                    możliwej średnicy wału).
                    Przy braku informacji o długości wału, zostanie użyty najkrótszy (równy średnicy wału pasującego
                    sprzęgła).
                    Przy braku informacji o wysokości wirnika, zostanie przyjęta minimalna fizycznie możliwa wysokość
                    wirnika dla danej kombinacji.
                    Przy braku informacji o kącie łopaty, zostanie dobrany najmniejszy dopuszczalny kąt formy.
                    Przy braku informacji o prędkości zostanie użyta prędkość minimalna dla jak najmniejszej możliwej
                    tarczy (1 m/s).
                    Kryterium prędkości można zadać przez RPM lub prędkość końca łopaty, nie oba.
                    Jeżeli podane będą RPM oraz moc w punkcie pracy, to dla każdego złożenia nastąpi także symulacje i
                    optymalizacja mechaniczna - nadal jednak w przeciwieństwie do symulacji przepływowej czy doboru,
                    zaprezentowane zostaną wszystkie rozważane konstrukcje mechaniczne, nawet nieakceptowalne lub
                    nieoptymalne.
                </p>
            </div>
        </Card>

        {loading === true && <Card additionalClassNames={"mb-3"}>
            <div className={" d-flex justify-content-center"}>
                <UboldSpinner/>
                <div style={{minHeight: "800px"}} data-role="improves experience on live search - removes jumping scroll"/>
            </div>
        </Card>}

        {h3RangeEstimation !== "loading" && criteria !== undefined && <ImpellerAssemblyH3EstimationResultCard estimation={h3RangeEstimation}/>}

        {!loading && h3RangeEstimation !== "loading" && <ImpellerAssemblyResults choices={choices} selectionH3RangeEstimation={h3RangeEstimation}/>}

        {loading === false && errorTree !== undefined && <Card additionalClassNames={"mb-3 mt-3"} header={"Przebieg złożenia / drzewo błędów"}><ErrorTreePresenter root={errorTree}/></Card>}

        {loading === false && mainCalculations.length > 0 && <Card header={"Obliczenia"}
                                                                   subHeader={"Obliczenia toku ogólnego konstrukcji wirników (bez obliczeń dla każdego konkretnego złożenia)"}
                                                                   additionalClassNames={"mb-3"}>
            <FormattedMechanicalSelectionCalculationLog calculations={mainCalculations}/>
        </Card>}

    </>;
}


export function ImpellerAssemblyResults({choices, selectionH3RangeEstimation}: { choices: MechanicalSelectionChoiceJson[] , selectionH3RangeEstimation : MechanicalSelectionH3RangeEstimationJson | null }) {
    const temporaryIds = Array.from(new Set(choices.map(ch => ch.impellerAssembly.temporaryIdentity)));
    const choicePresentersByTemporaryId = temporaryIds.map(tempId => <ImpellerAssemblyResultGroup
        choices={choices.filter(ch => ch.impellerAssembly.temporaryIdentity === tempId)}
        selectionH3RangeEstimation={selectionH3RangeEstimation}/>);

    return <>{choicePresentersByTemporaryId}</>;
}

export function ImpellerAssemblyResultGroup({choices, selectionH3RangeEstimation}: { choices: MechanicalSelectionChoiceJson[] , selectionH3RangeEstimation : MechanicalSelectionH3RangeEstimationJson | null}) {
    return <>
        <ImpellerAssemblyResultsForForm choices={choices} selectionH3RangeEstimation={selectionH3RangeEstimation}/>

    </>
}

ImpellerAssemblyPage.propTypes = {}

ImpellerAssemblyPage.defaultProps = {}

