import {AxisTickManaging, D3ChartRenderable, D3ChartSize, D3GSelection, D3SvgSelection} from "./D3Chart";
import * as d3Scale from "d3-scale";

import * as d3Axis from "d3-axis";

export class D3ChartGridLines implements D3ChartRenderable {

    public scale: d3Scale.ScaleLinear<number, number, never>;
    private size: D3ChartSize = {innerWidth: 0, innerHeight: 0};
    private g: D3GSelection | null = null;

    constructor(scale: d3Scale.ScaleLinear<number, number, never>,
                public readonly axisTickManaging:AxisTickManaging, // scale above is only for drawing and this is to extract actual tick values, to not recalculate them
                public readonly type: "horizontal" | "vertical",
                public className: string = "d3-gridlines") {
        this.scale = scale;
    }

    initialize(svgSelection: D3SvgSelection, rootG: D3GSelection, unClippedRootG: D3GSelection, size: D3ChartSize): void {
        this.size = size;
        this.g = rootG.append("g");
    }

    render(): void {
        if (this.g === null) {
            return;
        }
        if (this.type === "vertical") {
            var gridlinesX = d3Axis.axisBottom(this.scale).tickValues(this.axisTickManaging.getTickValues()).tickFormat(s => "").tickSize(-this.size.innerHeight);
            this.g.attr("transform", "translate(0," + this.size.innerHeight + ")").attr("class", this.className).call(gridlinesX);
        } else {
            var gridlinesY = d3Axis.axisLeft(this.scale).tickValues(this.axisTickManaging.getTickValues()).tickFormat(s => "").tickSize(-this.size.innerWidth);
            this.g.attr("class", this.className).call(gridlinesY);
        }

        this.g.select("path.domain").remove(); // Removes one wierd line added by axis func for the actual domain axis line. Was problmatic for horizontal because it was on top of chart, not at 0
    }
}