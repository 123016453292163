import React from 'react';
import DynamicWebServiceDrivenForm from "../../platform/webform/DynamicWebServiceDrivenForm";
import {Card} from "../../components/Card";
import {useNavigate} from "react-router";

export function CreateIssuePage() {
    var navigate = useNavigate();
    return <>
        <Card header={"Utwórz sprawę"}>
            <DynamicWebServiceDrivenForm dslUrl="/api/issue/form"
                                         submissionUrl="/api/issue/submit"
                                         submitLabel="Utwórz"
                                         successHandler={() => {
                                             alert('Sprawa została utworzona');
                                             navigate("/browse-issues");
                                         }}/>
        </Card>
    </>
}
