import React from 'react';

import {curveToPoints, FlowSimulationResultJson} from "../../business/useImpellerSimulation";
import {D3ChartAxis} from "../../platform/d3chart/D3ChartAxis";
import {D3ChartGridLines} from "../../platform/d3chart/D3ChartGridLines";
import {D3ChartLine} from "../../platform/d3chart/D3ChartLine";
import {D3ChartScaleManager} from "../../platform/d3chart/D3ChartScaleManager";
import {D3ChartRenderable} from "../../platform/d3chart/D3Chart";
import {ResistanceCurveD3ChartRenderable} from "../../charting/ResistanceCurveD3ChartRenderable";
import {D3ChartComponent} from "../../platform/d3chart/D3ChartComponent";
import {D3ChartPoint} from "../../platform/d3chart/D3ChartPoint";
import {MouseGuidesD3ChartRenderable} from "../../charting/MouseGuidesD3ChartRenderable";
import {HoverExplainerD3ChartRenderable} from "charting/HoverExplainerD3ChartRenderable";
import {FlowCurve, FlowPoint} from "../../business/core";


export function SimulationResultFanChartForSimulationResult(props: { flowSimulationResult: FlowSimulationResultJson, mainBladeAngleFormatted: string }) {
    return <SimulationResultFanChart
        mainPressureCurve={props.flowSimulationResult.fanStaticPressureCurve}
        dashedPressureCurve={props.flowSimulationResult.installationStaticPressureCurve}
        workingPointToMark={props.flowSimulationResult.fanStaticPressureWorkingPoint}
        alternativeCurves={props.flowSimulationResult.allAngleVariantsFanStaticPressureCurves}
        apiReservesDefiningPoint={props.flowSimulationResult.apiReservesDefiningPoint}
        angleFanBestEfficiencyPoint={props.flowSimulationResult.angleFanBestEfficiencyPoint}
        currentAngleReservesDefiningPoint={props.flowSimulationResult.currentAngleReservesDefiningPoint}
        mainBladeAngleFormatted={props.mainBladeAngleFormatted}
    />;
}

export function SimulationResultInstallationChartForSimulationResult(props: { flowSimulationResult: FlowSimulationResultJson, mainBladeAngleFormatted: string }) {
    return <SimulationResultFanChart
        mainPressureCurve={props.flowSimulationResult.installationStaticPressureCurve}
        dashedPressureCurve={props.flowSimulationResult.fanStaticPressureCurve}
        workingPointToMark={props.flowSimulationResult.installationStaticPressureWorkingPoint}
        alternativeCurves={props.flowSimulationResult.allAngleVariantsInstallationPressureCurves}
        mainBladeAngleFormatted={props.mainBladeAngleFormatted}
    />;
}

export type SimulationResultFanChartProps = {
    // Note "Fan" chart is related to UNCORRECTED values, its NOT AN INSTALLATION!
    mainPressureCurve: FlowCurve,
    alternativeCurves: { angle: number, flowCurve: FlowCurve }[],
    workingPointToMark: FlowPoint,
    mainBladeAngleFormatted: string,

    dashedPressureCurve?: FlowCurve,
    angleFanBestEfficiencyPoint?: FlowPoint,
    apiReservesDefiningPoint?: FlowPoint,
    currentAngleReservesDefiningPoint?: FlowPoint,
}

export function SimulationResultFanChart(props: SimulationResultFanChartProps) {

    const chartSize = {width: 1200, height: 500};
    const chartMargins = {left: 40, top: 0, right: 5, bottom: 40};

    const flowAxis = new D3ChartAxis([0.0, -1.0],
        [0.0, chartSize.width - chartMargins.left - chartMargins.right],
        "bottom",
        15,
        "d3-axis-slight-domain-line",
        0,
        0,
        "Q [m³/s] [m³/h]");
    const flowAxis_m3h = new D3ChartAxis([0, -1],
        [0, chartSize.width - chartMargins.left - chartMargins.right],
        "bottom",
        7,
        "d3-axis-nodomainline",
        20);
    const pressureAxis = new D3ChartAxis([-1.0, 0.0],
        [0, chartSize.height - chartMargins.top - chartMargins.bottom],
        "left",
        20,
        "d3-axis-slight-domain-line",
        0,
        0,
        "Pst [Pa]");

    const verticalGridLines = new D3ChartGridLines(flowAxis.scale, flowAxis, "vertical", "d3-stroke-1 d3-opacity-05 d3-stroke-black");
    //const horizontalGridLines = new D3ChartGridLines(pressureAxis.scale, "horizontal", 20, "d3-stroke-1 d3-opacity-05 d3-stroke-black");
    const horizontalGridLinesStrong = new D3ChartGridLines(pressureAxis.scale, pressureAxis, "horizontal", "d3-stroke-1 d3-opacity-10 d3-stroke-black");

    const alternativeUncorrectedPressureLines = props.alternativeCurves
        .map(angleVariant => {
            const line = new D3ChartLine<{ flow: number, value: number }>(
                flowAxis.scale,
                pressureAxis.scale,
                curveToPoints(angleVariant.flowCurve),
                chPoint => chPoint.flow,
                chPoint => chPoint.value,
                "d3-stroke-0_3 d3-stroke-black d3-opacity-50 ");
            line.setDescriber(point => "... " + angleVariant.angle.toFixed(2) + "° " + point.flow.toFixed(2) + " m3/s " + point.value.toFixed(2) + " Pa");
            return line;
        });

    const mainPressureLine = new D3ChartLine<{ flow: number, value: number }>(
        flowAxis.scale,
        pressureAxis.scale,
        curveToPoints(props.mainPressureCurve),
        chPoint => chPoint.flow,
        chPoint => chPoint.value,
        "d3-stroke-2 d3-stroke-primary");
    mainPressureLine.setDescriber(point => "Dobrany " + props.mainBladeAngleFormatted + "°: " + point.flow.toFixed(2) + " m3/s " + point.value.toFixed(2) + " Pa");

    const dashedPressureLine = props.dashedPressureCurve === undefined ? [] : [new D3ChartLine<{ flow: number, value: number }>(
        flowAxis.scale,
        pressureAxis.scale,
        curveToPoints(props.dashedPressureCurve),
        chPoint => chPoint.flow,
        chPoint => chPoint.value,
        "d3-stroke-1 d3-stroke-primary",
        "5,5")];
    if (dashedPressureLine.length > 0) {
        dashedPressureLine[0].setDescriber(point => "Instalacji " + props.mainBladeAngleFormatted + "°: " + point.flow.toFixed(2) + " m3/s " + point.value.toFixed(2) + " Pa");
    }

    const uncorrectedWorkingPoint = new D3ChartPoint(flowAxis.scale, pressureAxis.scale, props.workingPointToMark.flow, props.workingPointToMark.value, 5, undefined, "d3-fill-primary");
    const apiReservesDefiningPoint = props.apiReservesDefiningPoint === undefined ? [] : [new D3ChartPoint(flowAxis.scale, pressureAxis.scale, props.apiReservesDefiningPoint.flow, props.apiReservesDefiningPoint.value, 3, undefined, ".d3-fill-128")];
    const angleFanBestEfficiencyPoint = props.angleFanBestEfficiencyPoint === undefined ? [] : [new D3ChartPoint(flowAxis.scale, pressureAxis.scale, props.angleFanBestEfficiencyPoint.flow, props.angleFanBestEfficiencyPoint.value, 4, "green", )];
    const currentAngleReservesDefiningPoint = props.currentAngleReservesDefiningPoint === undefined ? [] : [new D3ChartPoint(flowAxis.scale, pressureAxis.scale, props.currentAngleReservesDefiningPoint.flow, props.currentAngleReservesDefiningPoint.value, 3, undefined, ".d3-fill-128")];

    const scaleManager = new D3ChartScaleManager([mainPressureLine, ...alternativeUncorrectedPressureLines], 1.2);
    const scaleManager_m3h = new D3ChartScaleManager([mainPressureLine, ...alternativeUncorrectedPressureLines], 1.2 * 3600, flowAxis_m3h.scale, null);

    // Working point resistance curve
    const workingPointStaticResitanceCurve = new ResistanceCurveD3ChartRenderable(flowAxis.scale, pressureAxis.scale).withTargetPoint(props.workingPointToMark.flow, props.workingPointToMark.value).disableMouse()

    const renderables: D3ChartRenderable[] = [
        scaleManager, // Scale manager comes first, to recalculate axes scales!
        scaleManager_m3h,
        flowAxis,
        flowAxis_m3h,
        pressureAxis,
        verticalGridLines,
        //horizontalGridLines,
        horizontalGridLinesStrong,
        //new ResistanceCurveD3ChartRenderable(flowAxis.scale, pressureAxis.scale),
        ...(props.apiReservesDefiningPoint === undefined ? [] : [new ResistanceCurveD3ChartRenderable(flowAxis.scale, pressureAxis.scale).withTargetPoint(props.apiReservesDefiningPoint.flow, props.apiReservesDefiningPoint.value).disableMouse()]),
        ...alternativeUncorrectedPressureLines,
        ...dashedPressureLine,
        mainPressureLine,
        ...apiReservesDefiningPoint,
        ...angleFanBestEfficiencyPoint,
        ...currentAngleReservesDefiningPoint,
        uncorrectedWorkingPoint,
        workingPointStaticResitanceCurve,
        new ResistanceCurveD3ChartRenderable(flowAxis.scale, pressureAxis.scale),
        new MouseGuidesD3ChartRenderable(flowAxis.scale, pressureAxis.scale),
        new HoverExplainerD3ChartRenderable([...alternativeUncorrectedPressureLines, mainPressureLine])
    ];

    return <D3ChartComponent width={chartSize.width} height={chartSize.height} chartRenderables={renderables}
                             margins={chartMargins}/>
}
