import {createEditorForMeasurementStandard} from "../form/editors/MeasureEditor";
import {WentechMeasuringStandards} from "../business/measurement/WentechMeasurementStandards";
import {LabeledEditor} from "../pages/selection/useFanCriteria";
import {useEffect} from "react";
import {useEditor} from "../form/EditorReact";
import {NumberEditorValue} from "../form/editors/NumberEditor";

export type AirDensityCalculatorProps = {
    onSuccessfulSubmission: (calculatedDensity: number) => void;
    airTemperature: NumberEditorValue
}

export function AirDensityCalculator(props: AirDensityCalculatorProps) {

    const humidityEditor = useEditor(() => createEditorForMeasurementStandard(WentechMeasuringStandards.AIR_HUMIDITY));
    const barometricPressureEditor = useEditor(() => createEditorForMeasurementStandard(WentechMeasuringStandards.BAROMETRIC_PRESSURE));
    const heightAboveSeaLevelEditor = useEditor(() => createEditorForMeasurementStandard(WentechMeasuringStandards.HEIGHT_ABOVE_SEA_LEVEL));


    useEffect(() => {
        let pcz;
        const Tc = props.airTemperature;
        const fiw = humidityEditor.value;
        const Pb = barometricPressureEditor.value;
        const hwys = heightAboveSeaLevelEditor.value;
        if (fiw === null || Pb === null || hwys == null || Tc === null) {
            props.onSuccessfulSubmission(0.0);
            return;
        }


        if (Tc >= 0) {
            pcz = (7.0347E-02) * Tc * Tc * Tc - (9.1761E-02) * Tc * Tc + (6.0358E+01) * Tc + 5.6839E+02; // constans
            console.log("pcz=(7.0347E-02)*" + Tc + "*" + Tc + "*" + Tc + "-(9.1761E-02)*" + Tc + "*" + Tc + "+(6.0358E+01)*" + Tc + "+5.6839E+02");
            console.log("pcz=" + pcz);
        } else {
            pcz = 0;
            console.log("pcz=0 poniewaz Tc < 0");
        }

        //var x = 0.622*0.01*fiw*pcz/(Pb*100-0.01*fiw*pcz);
        var x = 0.622 * 0.01 * fiw * pcz / (Pb * 100 - 0.01 * fiw * pcz); //info: wyrzucone 0.622* na poczatku wzoru
        console.log("x=0.622*0.01*" + fiw + "*" + pcz + "/(" + Pb + "-0.01*" + fiw + "*" + pcz + ")");
        console.log("x=" + x);

        var R = 462 * ((x + 0.622) / (1 + x));

        console.log("Stala gazowa R:");
        console.log("R=462*((" + x + "+0.0622)/(1+" + x + "))");
        console.log("R=" + R);

        var pwys = (1.2 * 9.81 * hwys) / 100;

        console.log("Cisnienie na wysokosci pwys:");
        console.log("pwys=(1.2*9.81*" + hwys + ")/100");
        console.log("pwys=" + pwys);

        var Pbk = Pb - pwys;

        console.log("Cisnienie skorygowane wysokoscia:");
        console.log("Pbk=" + Pb + "-" + pwys);
        console.log("Pbk=" + Pbk);

        const Ro = (100 * Pbk) / (R * (Tc + 273));

        console.log("Gestosc powietrza Ro:");
        console.log("Ro=(100*" + Pbk + ")/(" + R + "*(" + Tc + "+273))");
        console.log("Ro=" + Ro);

        props.onSuccessfulSubmission(Ro);
    }, [humidityEditor.value, barometricPressureEditor.value, heightAboveSeaLevelEditor.value, props.airTemperature]);

    return <>
        <LabeledEditor label={"Wilgotność powierza"} editor={humidityEditor}/>
        <LabeledEditor label={"Ciśnienie barometryczne"} editor={barometricPressureEditor}/>
        <LabeledEditor label={"Wysokość nad poziomem morza powierza"} editor={heightAboveSeaLevelEditor}/>
    </>
}

/*

		if ( $('input[name=check_oblicz_gestosc]').attr('checked') )
		{
			var pcz;

			cdw("Cisnienie czastkowe:");

			if ( Tc >= 0 )
			{
				pcz = (7.0347E-02)*Tc*Tc*Tc-(9.1761E-02)*Tc*Tc+(6.0358E+01)*Tc+5.6839E+02; // constans
				cd("pcz=(7.0347E-02)*"+Tc+"*"+Tc+"*"+Tc+"-(9.1761E-02)*"+Tc+"*"+Tc+"+(6.0358E+01)*"+Tc+"+5.6839E+02");
				cd("pcz="+pcz);
			}
			else
			{
				pcz=0;
				cd("pcz=0 poniewaz Tc < 0");
			}

			//var x = 0.622*0.01*fiw*pcz/(Pb*100-0.01*fiw*pcz);
			var x = 0.622*0.01*fiw*pcz/(Pb*100-0.01*fiw*pcz); //info: wyrzucone 0.622* na poczatku wzoru

			cdw("X:");
			cd("x=0.622*0.01*"+fiw+"*"+pcz+"/("+Pb+"-0.01*"+fiw+"*"+pcz+")");
			cd("x="+x);

			var R= 462*((x+0.622)/(1+x));

			cdw("Stala gazowa R:");
			cd("R=462*(("+x+"+0.0622)/(1+"+x+"))");
			cd("R="+R);

			var pwys=(1.2*9.81*hwys)/100;

			cdw("Cisnienie na wysokosci pwys:");
			cd("pwys=(1.2*9.81*"+hwys+")/100");
			cd("pwys="+pwys);

			var Pbk = Pb - pwys;

			cdw("Cisnienie skorygowane wysokoscia:");
			cd("Pbk="+Pb+"-"+pwys);
			cd("Pbk="+Pbk);

			Ro = (100*Pbk)/(R*(Tc+273));

			cdw("Gestosc powietrza Ro:");
			cd("Ro=(100*"+Pbk+")/("+R+"*("+Tc+"+273))");
			cd("Ro="+Ro);

			reversevals(); // Update po policzeniu Ro
		}


 */