import AjaxService from "../../platform/ajax/AjaxService";

export default class MechanicalSettingsService {

    static async fetchSettings() {
        const response = await AjaxService.getJson("/api/settings");
        if (!response.ok) {
            throw Error("Response not ok");
        }
        return response.json;
    }

    static async saveSettings(settings) {
        const response = await AjaxService.postJson("/api/settings", settings);
        if (!response.ok) {
            throw Error("Response not ok");
        }
        return response.json;
    }
}