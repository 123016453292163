import {createInternationalizationPrefixer, Ik, Istring} from "../platform/i18n/i18n";

export interface Calculation { // TODO move to core
    code: string,
    humanFriendlyNameIk: Ik,
    value: any, // TODO not any??? looks like candidate for generic. This may include { pl: "..." , en: "... }
    unit: string,
    logs: string[],
    tag: string,
    relatedCalculations: string[]
}

const paramT: (text: string) => string = createInternationalizationPrefixer(""); // TODO cleanup - prefix was removed to avoid duplication with pdf datasheet


export function resolveParamTranslation(ik: Ik): string {
    return paramT(ik);
}

export interface FlowCurve {
    discreteAirFlows: number[],
    discreteValuesForDiscreteAirFlows: number[]
}

export interface FiCurve {
    discreteFis: number[],
    valuesForDiscreteFis: number[],
    pointDescriber?: (point: { fi: number, value: number }) => string;
}

export interface FlowPoint {
    flow: number,
    value: number
}

export interface DimensionalImageDescriptor {
    templateName: string,
    dynamicLabelValues: Record<string, Istring>
}

export interface PartListEntry {
    pieceCount: number,
    name: string,
    materialName: string,
    standardName: string,
    pieceMass: number,
    humanFriendlyTag: string
}