import {ImpellerAssemblyJson} from "../../pages/mechanical/ImpellerAssemblyService";
import React, {useState} from "react";
import {CalculationExplainer} from "../domain/CalculationExplainer";
import {FanConstructionMaterialsTable, singleCalculationLogListing} from "../domain/SimulationDatasheet";
import {ImpellerGeometryTable} from "./ImpellerGeometryTable";
import {DimensionalImageDescriptor} from "../../business/core";
import {DimensionalImg} from "../domain/DimensionalImg";

export function AssemblyDetails({assembly, includeMaterialTable = false, dimensionalImageDescriptors}: { assembly: ImpellerAssemblyJson, includeMaterialTable?: boolean, dimensionalImageDescriptors: DimensionalImageDescriptor[] }) {

    const [allCalculationsListingVisible, setAllCalculationsListingVisible] = useState(false);
    const [dimensionImagesVisible, setDimensionImagesVisible] = useState(false);


    const PartAttribute = ({name, val}: { name: string, val: string | number }) => <div className={"d-flex flex-row flex-nowrap mb-2"}>
        <div className={"flex-fill me-2"}>{name}</div>
        <div className={"flex-fill text-end fw-bolder"}>{val}</div>
    </div>;

    return <div className="col-12">

        <h3 className={"text-center mt-5 mb-3"}>Lista materiałowa</h3>
        {includeMaterialTable === true && <FanConstructionMaterialsTable assemblyPartList={assembly.assemblyPartList}/>}

        <h3 className={"text-center mt-5 mb-3"}>Geometria</h3>
        <ImpellerGeometryTable geometryReport={assembly.geometryReport} variant={"full-table"}/>

        <div className={"text-center"}>
            {!dimensionImagesVisible && dimensionalImageDescriptors.length > 0 && <button className={"btn btn-sm btn-secondary"} onClick={()=>setDimensionImagesVisible(true)}>Pokaż rysunki</button>}
            {dimensionalImageDescriptors.length === 0 && <div className={"m-3"}>Brak rysunków wymiarowych dla tego rozwiązania</div>}
            {dimensionImagesVisible && dimensionalImageDescriptors.map(descriptor => <div className={"mb-20"}><DimensionalImg descriptor={descriptor}/></div>)}
        </div>

        <h3 className={"text-center mt-5 mb-3"}>Szczegóły komponentów</h3>
        <div className="d-flex flex-row flex-nowrap">
            <div className="border border-2 border-top-0 border-secondary rounded me-3 mb-3 d-flex flex-column">
                <div className={"px-3 mb-4"}>
                    <div className="fs-4 fw-bolder">{assembly.clutch.partDisplayName}</div>
                </div>
                <div className={"flex-fill px-3"}>
                    <PartAttribute name={"Nazwa"} val={assembly.clutch.clutchDisplayName}/>
                    <PartAttribute name={"Typ"} val={assembly.clutch.clutchTypeName}/>
                    <PartAttribute name={"Średnica otworu na wał [mm]"} val={assembly.clutch.shaftHoleDiameter.toFixed(0)}/>
                    <PartAttribute name={"Średnica otworu centralnego [mm]"} val={assembly.clutch.centralHoleDiameter.toFixed(2)}/>
                    <PartAttribute name={"Długość wybierania H4 [mm]"} val={assembly.clutch.dlugoscWybieraniaH4.toFixed(2)}/>
                    <PartAttribute name={"Głębokość osadzenia tarczy [mm]"} val={assembly.clutch.glebokoscOsadzeniaTarczy.toFixed(2)}/>
                    <PartAttribute name={"Ilość śrub mocujących"} val={assembly.clutch.mountingScrewCount.toFixed(0)}/>
                    <PartAttribute name={"Średnica gwintu dla ST"} val={"M" + assembly.clutch.srednicaGwintuDlaZestawuSrubowegoSt.toFixed(0)}/>
                    <PartAttribute name={"Średnica otworu pod śrubę zabezpieczającą [mm]"} val={assembly.clutch.srednicaOtworuPodSrubeZabepieczajaca.toFixed(0)}/>
                    <PartAttribute name={"Średnica gwintu dla śruby zabezpieczającej"} val={"M" + assembly.clutch.mForSecuringScrew.toFixed(0)}/>
                    <PartAttribute name={"Standard tol. otworu sprzęgła"} val={assembly.clutch.standardTolerancjiOtworuSprzegla}/>
                    <PartAttribute name={"Nr rysunku"} val={assembly.clutch.drawingNumber}/>
                    <PartAttribute name={"Materiał"} val={assembly.clutch.materiaName}/>
                    <PartAttribute name={"Dlugość H1 [mm]"} val={assembly.clutch.dlugoscSprzeglaH1.toFixed(2)}/>
                    <PartAttribute name={"Dlugość otw. pasowania pod wał [mm]"} val={assembly.clutch.dlugoscOtworuPasowaniaPodWalek.toFixed(2)}/>
                    <PartAttribute name={"Dlugość części pasowania H3 [mm]"} val={assembly.clutch.dlugoscPrzerwCzesciPasowaniaH3 == null ? "Brak" : assembly.clutch.dlugoscPrzerwCzesciPasowaniaH3.toFixed(2)}/>
                    <PartAttribute name={"Średnica podtrzymująca H4 [mm]"} val={assembly.clutch.srednicaPodtrzymujacaSprzegloH4.toFixed(2)}/>
                    <PartAttribute name={"Moment dopuszczalny [Nm]"} val={assembly.clutch.momentDopuszczalny.toFixed(2)}/>
                    <PartAttribute name={"Średnica zew. tulei E [mm]"} val={assembly.clutch.srednicaZewnetrznaTuleiE.toFixed(2)}/>
                    <PartAttribute name={"Masa"} val={assembly.clutch.mass.toFixed(2) + " kg"}/>
                    <PartAttribute name={"Szerokość wpustu \"K\" [mm]"} val={assembly.clutch.szerokoscWpustuK.toFixed(0)}/>
                    <PartAttribute name={"Otwór z wpustem \"J\""} val={assembly.clutch.otworZWpustemJ.toFixed(2)}/>
                    <PartAttribute name={"Parametr h2 [mm]"} val={assembly.clutch.h2Parameter.toFixed(0)}/>
                    <PartAttribute name={"Promień kołnierza [mm]"} val={assembly.clutch.promienKolnierza.toFixed(0)}/>


                </div>
                <div className={"d-flex flex-row-reverse align-self-end px-3"}>
                    <CalculationExplainer calculations={assembly.calculations} explainedCalculationCode={"clutch"}/>
                </div>
                <div className={"d-flex flex-column bg-light-info px-3 pt-3 mt-2 pb-3"}>
                    {assembly.clutchToShaftFasteningElements.map(fe => <div className={"flex-fill"}>{fe.pieceCount}x {fe.name}</div>)}
                    <div className={"d-flex flex-row-reverse"}>
                        <CalculationExplainer calculations={assembly.calculations} explainedCalculationCode={"securingScrew"}/>
                    </div>
                </div>
            </div>

            <div className="border border-2 border-top-0 border-secondary rounded me-3 mb-3 d-flex flex-column">
                <div className={"px-3 mb-4"}>
                    <div className="fs-4 fw-bolder">{assembly.disc.partDisplayName}</div>
                </div>
                <div className={"flex-fill px-3"}>
                    <PartAttribute name={"Nazwa"} val={assembly.disc.displayName}/>
                    <PartAttribute name={"Średnica [mm]"} val={assembly.disc.discDiameter.toFixed(2)}/>
                    <PartAttribute name={"Materiał"} val={assembly.disc.materialName}/>
                    <PartAttribute name={"Norma"} val={assembly.disc.standardName}/>
                    <PartAttribute name={"Masa [kg]"} val={assembly.disc.mass.toFixed(2) + " kg"}/>
                    <PartAttribute name={"Grubość [mm]"} val={assembly.disc.discThickness}/>
                    <PartAttribute name={"\"A\" na tarczy"} val={assembly.disc.aNaTarczy.toFixed(2)}/>
                    <PartAttribute name={"Korekta Ant"} val={assembly.disc.korektaANaTarczy.toFixed(2)}/>
                    <PartAttribute name={"Średnica otworu centralnego [mm]"} val={assembly.disc.srednicaOtworuCentralnego.toFixed(2)}/>
                    <PartAttribute name={"Średnica otworu D4"} val={assembly.disc.srednicaOtworuD4.toFixed(2)}/>
                    <PartAttribute name={"Średnica otworu D5"} val={assembly.disc.srednicaOtworuD5.toFixed(2)}/>
                    <PartAttribute name={"Śruba mocująca (M)"} val={assembly.disc.mForClutchAndDiscFasteningScrew.toFixed(0)}/>
                    <PartAttribute name={"Śruba obejm (M)"} val={assembly.disc.mForDiscAndClampScrew.toFixed(0)}/>
                </div>
                <div className={"d-flex flex-row-reverse align-self-end px-3"}>
                    <CalculationExplainer calculations={assembly.calculations} explainedCalculationCode={"disc"}/>
                </div>
                <div className={"d-flex flex-column bg-light-info px-3 pt-3 mt-2 pb-3"}>
                    {assembly.discToClutchFasteningElements.map(fe => <div className={"flex-fill"}>{fe.pieceCount}x {fe.name}</div>)}
                    <div className={"d-flex flex-row-reverse"}>
                        <CalculationExplainer calculations={assembly.calculations} explainedCalculationCode={"discToClutchFasteningElements"}/>
                    </div>
                </div>
                {assembly.hubCap !== null && <>
                    <div className={"px-3 mb-4"}>
                        <div className="fs-4 fw-bolder">{assembly.hubCap.name}</div>
                    </div>
                    <div className={"flex-fill px-3"}>
                        <PartAttribute name={"Średnica [mm]"} val={assembly.hubCap.diameter.toFixed(1)}/>
                        <PartAttribute name={"Grubość [mm]"} val={assembly.hubCap.thickness.toFixed(1)}/>
                        <PartAttribute name={"Wycięcie"} val={assembly.hubCap.cutPresent ? "Tak" : "Nie"}/>
                        <PartAttribute name={"Wycinanie od kąta"} val={assembly.hubCap.minimumAngleToApplyCut.toFixed(2)}/>
                        <PartAttribute name={"Norma"} val={assembly.hubCap.standardName}/>
                        <PartAttribute name={"Masa [kg]"} val={assembly.hubCap.mass.toFixed(2)}/>
                        <PartAttribute name={"Materiał"} val={assembly.hubCap.materialName}/>
                    </div>
                    <div className={"d-flex flex-row-reverse align-self-end px-3"}>
                        <CalculationExplainer calculations={assembly.calculations} explainedCalculationCode={"hubCap"}/>
                    </div>
                    <div className={"d-flex flex-column bg-light-info px-3 pt-3 mt-2 pb-3"}>
                        {assembly.hubCapFasteningElements.map(fe => <div className={"flex-fill"}>{fe.pieceCount}x {fe.name}</div>)}
                        <div className={"d-flex flex-row-reverse"}>
                            <CalculationExplainer calculations={assembly.calculations} explainedCalculationCode={"hubCapFasteningElements"}/>
                        </div>
                    </div>
                </>}
            </div>

            <div className="border border-2 border-top-0 border-secondary rounded me-3 mb-3 d-flex flex-column">
                <div className={"px-3 mb-4"}>
                    <div className="fs-4 fw-bolder">{assembly.podpora.name}</div>
                </div>
                <div className={"flex-fill px-3"}>
                    <PartAttribute name={"Ilość"} val={assembly.podpora.pieceCount}/>
                    <PartAttribute name={"Masa [kg]"} val={assembly.podpora.pieceMass.toFixed(2)}/>
                    <PartAttribute name={"Nr rysunku"} val={assembly.podpora.standardName}/>
                    <PartAttribute name={"Materiał"} val={assembly.podpora.materialName}/>
                </div>
                <div className={"d-flex flex-row-reverse align-self-end px-3"}>
                    <CalculationExplainer calculations={assembly.calculations} explainedCalculationCode={"supportBladeMount"}/>
                </div>
                <div className={"d-flex flex-column bg-light-info px-3 pt-3 mt-2 pb-3"}>
                    {assembly.supportToDiscFasteningElements.map(fe => <div className={"flex-fill"}>{fe.pieceCount}x {fe.name}</div>)}
                    <div className={"d-flex flex-row-reverse"}>
                        <CalculationExplainer calculations={assembly.calculations} explainedCalculationCode={"supportToDiscFasteningElements"}/>
                    </div>
                </div>
            </div>

            <div className="border border-2 border-top-0 border-secondary rounded me-3 mb-3 d-flex flex-column">
                {assembly.obejma !== null && <>
                    <div className={"px-3 mb-4"}>
                        <div className="fs-4 fw-bolder">{assembly.obejma.name}</div>
                    </div>
                    <div className={"flex-fill px-3"}>
                        <PartAttribute name={"Ilość"} val={assembly.obejma.pieceCount}/>
                        <PartAttribute name={"Masa [kg]"} val={assembly.obejma.pieceMass.toFixed(2)}/>
                        <PartAttribute name={"Nr rysunku"} val={assembly.obejma.standardName}/>
                        <PartAttribute name={"Materiał"} val={assembly.obejma.materialName}/>
                    </div>
                    <div className={"d-flex flex-row-reverse align-self-end px-3"}>
                        <CalculationExplainer calculations={assembly.calculations} explainedCalculationCode={"obejma"}/>
                    </div>
                </>}
                {assembly.nakladka !== null && <>
                    <div className={"px-3 mb-4"}>
                        <div className="fs-4 fw-bolder">{assembly.nakladka.name}</div>
                    </div>
                    <div className={"flex-fill px-3"}>
                        <PartAttribute name={"Ilość"} val={assembly.nakladka.pieceCount}/>
                        <PartAttribute name={"Masa [kg]"} val={assembly.nakladka.pieceMass.toFixed(2)}/>
                        <PartAttribute name={"Nr rysunku"} val={assembly.nakladka.standardName}/>
                        <PartAttribute name={"Materiał"} val={assembly.nakladka.materialName}/>
                    </div>
                    <div className={"d-flex flex-row-reverse align-self-end px-3"}>
                        <CalculationExplainer calculations={assembly.calculations} explainedCalculationCode={"nakladka"}/>
                    </div>
                </>}

                <div className={"d-flex flex-column bg-light-info px-3 pt-3 mt-2 pb-3"}>
                    {assembly.bladeClampingFasteningElements.map(fe => <div className={"flex-fill"}>{fe.pieceCount}x {fe.name}</div>)}
                    <div className={"d-flex flex-row-reverse"}>
                        <CalculationExplainer calculations={assembly.calculations} explainedCalculationCode={"bladeClampingFasteningElements"}/>
                    </div>
                </div>
            </div>

            <div className="border border-2 border-top-0 border-secondary rounded me-3 mb-3 d-flex flex-column">
                <div className={"flex-fill  px-3"}>
                    <div className="fs-4 fw-bolder">{assembly.blade.partDisplayName}</div>
                    <PartAttribute name={"Materiał"} val={assembly.blade.materialName}/>
                    <PartAttribute name={"Nazwa formy"} val={assembly.blade.formName}/>
                    <PartAttribute name={"Masa [kg]"} val={assembly.blade.mass.toFixed(2)}/>
                    <PartAttribute name={"Czop [mm]"} val={assembly.blade.pinDiameter.toFixed(0)}/>
                    <PartAttribute name={"Profil"} val={assembly.blade.bladeProfileDisplayName}/>
                    <PartAttribute name={"Wykonanie"} val={assembly.blade.manufacturingTypeName}/>
                    <PartAttribute name={"Kąt łopaty [°]"} val={assembly.blade.bladeAngle.toFixed(1)}/>
                    <PartAttribute name={"Długość łopaty [mm]"} val={assembly.blade.formBladeLength.toFixed(0)}/>
                    <PartAttribute name={"Promień położenia środka masy [mm]"} val={assembly.blade.promienPolozeniaSrodkaMasyLopaty.toFixed(0)}/>
                    <PartAttribute name={"Zgrubny promień położenia środka masy [mm]"} val={assembly.blade.zgrubnyPromienPolozeniaSrodkaMasyLopaty.toFixed(0)}/>
                    <PartAttribute name={"Częstotliwość drgań wlasnych [Hz]"} val={assembly.blade.vibrationFrequency.toFixed(2)}/>
                    <PartAttribute name={"Cz.drgań estymowana/mierzona"} val={assembly.blade.vibrationFrequencyEstimated ? "estymowana" : "mierzona"}/>
                    <PartAttribute name={"Wystawanie czopa z podpory [mm]"} val={assembly.wystawanieCzopaZPodpory.toFixed(1)}/>

                </div>
                <div className={"d-flex flex-row-reverse align-self-end px-3"}>
                    <CalculationExplainer calculations={assembly.calculations} explainedCalculationCode={"blade"}/>
                </div>
            </div>
        </div>

        {assembly.memoizedAsBlueprintId !== null && <div className={"my-3"}>
            <a className={"btn btn-secondary btn-sm"} href={`/api/impeller-assembly-xls-export/${assembly.memoizedAsBlueprintId}`}><i className="fas fa-download"></i> Pobierz parametry DriveWorks</a>
            <a className={"btn btn-secondary btn-sm"} href={`/api/impeller-assembly-xls-export/${assembly.memoizedAsBlueprintId}/workaround`}><i className="fas fa-download"></i> (pobierz wariant płasko-tekstowy)</a>
            <a className={"btn btn-secondary btn-sm"} href={`/api/export-bill-of-materials-xlsx/${assembly.memoizedAsBlueprintId}`}><i className="fas fa-download"></i> Pobierz listę materiałową (BOM)</a>
        </div>}

        {!allCalculationsListingVisible && <div className={"text-center mt-3"}>
            <button className="btn btn-link m-0 p-0" onClick={() => setAllCalculationsListingVisible(true)}>Pokaż wszystkie obliczenia <i className="fas fa-calculator"/></button>
        </div>}
        {allCalculationsListingVisible === true && <div>
            <div className={"text-center mt-3"}>
                <button className="btn btn-link m-0 p-0" onClick={() => setAllCalculationsListingVisible(false)}>Ukryj obliczenia</button>
            </div>
            {assembly.calculations.map((calc) => singleCalculationLogListing(calc))}
        </div>}
    </div>
}