import {useContext} from "react";
import {HotIssueContext} from "./HotIssueContextProvider";
import {NavLink} from "react-router-dom";

export function HotIssueInformationBanner() {

    const {clearHotIssue, currentHotIssueSummary} = useContext(HotIssueContext);

    if (currentHotIssueSummary == null) {
        return <div rel="no-hot-issue-loaded"></div>;
    }

    return <div className="alert alert-success m-3 d-flex">

        <div className={"flex-grow-1"}>
            Praca w kontekście sprawy: <strong>{currentHotIssueSummary.name}</strong> (wydruki będą automatycznie przypinane)
            <br/>
            <small>Utworzona przez: {currentHotIssueSummary.originalCreatorName} o {new Date(currentHotIssueSummary.creationTimestampIso).toLocaleString("pl-PL")}</small>
        </div>
        <div className={"d-flex align-items-center justify-content-end gap-2"}>
            <NavLink to={`/show-issue/${currentHotIssueSummary.id}`} className={"btn btn-sm btn-light-secondary"}>Idź do sprawy</NavLink>
            <button className="btn btn-sm btn-danger" onClick={clearHotIssue}>Dezaktywuj</button>
        </div>

    </div>
}