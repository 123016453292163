import {Editor} from "../../form/Editor";
import {i18n} from "../../platform/i18n/i18n";
import {useEditor} from "../../form/EditorReact";
import {ObjectEditor, SubEditorMapping} from "../../form/editors/ObjectEditor";
import {NumberEditorValue} from "../../form/editors/NumberEditor";
import {createEditorForMeasurementStandard} from "../../form/editors/MeasureEditor";
import {WentechMeasuringStandards} from "../measurement/WentechMeasurementStandards";
import {OptionalEditor} from "../../form/editors/OptionalEditor";
import React from "react";


export interface CrosswindDetails {
    metersPerSecond: NumberEditorValue
}


export function useCrosswindEditor(): OptionalEditor<CrosswindDetails> {
    const t = (txt: string) => i18n("CrosswindEditor:" + txt);

    return useEditor(() => {

        const crosswindSpeedEditor: SubEditorMapping<NumberEditorValue> = {
            editor: createEditorForMeasurementStandard(WentechMeasuringStandards.WIND_SPEED),
            propertyName: "metersPerSecond",
            propertyType: "number",
            propertyDisplayName: t("Prędkość wiatru")
        };

        const crosswindDetailsEditor = new ObjectEditor<CrosswindDetails>([crosswindSpeedEditor]);


        return new OptionalEditor<CrosswindDetails>(
            crosswindDetailsEditor,
            false,
            (toggleEnablement) => <></>,
            (DelegatedEditorComponent, toggleEnablement) =>
                <div className="text-center pt-3">
                    <DelegatedEditorComponent/>
                    <div className={"d-flex flex-row-reverse"}>
                        <button className={"btn btn-link btn-sm"} onClick={toggleEnablement}><i className="fas fa-times fa-3x"/> {t("Nie uwzględniaj wiatru poprzecznego")}</button>
                    </div>
                </div>
        )
    });
}

export function useCrosswindEditorLegacy(): Editor<CrosswindDetails | null> {
    const t = (txt: string) => i18n("CrosswindEditor:" + txt);

    return useEditor<Editor<CrosswindDetails | null>>(() => {

        const crosswindSpeedEditor: SubEditorMapping<NumberEditorValue> = {
            editor: createEditorForMeasurementStandard(WentechMeasuringStandards.WIND_SPEED),
            propertyName: "crosswindSpeed",
            propertyType: "number",
            propertyDisplayName: t("Prędkość wiatru")
        };

        const crosswindDetailsEditor = new ObjectEditor<CrosswindDetails>([crosswindSpeedEditor]);


        return new OptionalEditor<CrosswindDetails>(
            crosswindDetailsEditor,
            false,
            (toggleEnablement) =>
                <div className="text-center pt-3">
                    <button className="btn btn-sm btn-light" onClick={toggleEnablement}>{t("Uwzględnij wiatr poprzeczny")}</button>
                </div>,
            (DelegatedEditorComponent, toggleEnablement) =>
                <div className="text-center pt-3">
                    <DelegatedEditorComponent/>
                    <div className={"d-flex flex-row-reverse"}>
                        <button className={"btn btn-link btn-sm"} onClick={toggleEnablement}><i className="fas fa-times fa-3x"/> {t("Nie uwzględniaj wiatru poprzecznego")}</button>
                    </div>
                </div>
        )
    });
}