import {BaseEditor} from "form/Editor";
import {debounce} from "lodash";
import {MetronicNumberEditor} from "../metronic/MetronicNumberEditor";

export type NumberEditorValue = number | null;

/**
 * Normally this would be lodash DebouncedFunc<> but the interface is not exported
 */
interface DebouncedInternalTextToValueUpdateFunction {
    (...args: Parameters<(newText: string) => void>): ReturnType<(newText: string) => void> | undefined;

    cancel(): void;

    flush(): void;
}

/**
 * Contract for the formatter:
 * Format valid numbers in a way, that corresponding parsers will interpret them as the same value (unit tests recommended)
 * TODO: Join formatter & parser into single class, because of this contractual requirement
 */
export type NumberEditorFormatter = (value: NumberEditorValue) => string;

/**
 * Contract for the parser:
 * Return number if the input is OK, return null when it's considered "valid but empty",
 * and return number of NaN when the input is incorrect
 */
export type NumberEditorParser = (text: string) => NumberEditorValue;


// HISTORY
// - Allowing empty value
// - Idea of parser vs formatter
// - Debounce can over-write user input
// - Handle NaN internally as something else than null. But still never display/return NaN outise (pretends it's no value = null)
// - Formater-parser contracts + autochecks
// - Exclusion from formatting when input is incorrect (preserve user input)
// - parser & formatter vs very large numbers and formatting to scientific notation
// - "commit" aka immediate debounce? when user is faster than debounce and submits the form. or just on blur? Protect when value getter is called but still debounce is in progress?
// - cancel pending debounce on reset!
// - In measure editor - when someone populates with too gradual value like 4.000000005, the formatter vs parser warning is produced. Applied correctedValue = parse(format(...))
// - If parser can produce granular values from input (more than precision of formatter), it will alwyas result in warning. Updated default parser to apply "toFixed" (this one is bit dirty, see long stories here https://stackoverflow.com/questions/11832914/how-to-round-to-at-most-2-decimal-places-if-necessary)
// TODO: Consider generic DebouncedParseableTextEditor with pluggable parser/formatter instead of coupling to numbers here
export class NumberEditor extends BaseEditor<NumberEditorValue> {

    /**
     * Number when valid number was provided
     * Null when empty or unparseable
     * (you can use unparseableTextPresent() to see if the value is null due to unparseable text)
     */
    private _value: NumberEditorValue;
    private _currentText: string;
    private _readOnly: boolean = false;
    private readonly scheduleValueUpdate: DebouncedInternalTextToValueUpdateFunction;

    constructor(private readonly initialValue: NumberEditorValue,
                private readonly format: NumberEditorFormatter,
                private readonly parse: NumberEditorParser) {
        super();
        if (initialValue !== null && (isNaN(initialValue) || initialValue === Infinity || initialValue === -Infinity)) {
            throw new Error("Initial value cannot be NaN/infinity");
        }
        this._value = initialValue;
        this._currentText = this.formatValue(initialValue);
        // TODO introduce option to not debounce
        const scheduleValueUpdate = (newText: string) => {
            try {
                this._currentText = newText; // Note updating value is more important, but this text synchronization is "just in case"
                if (newText.length === 0) {
                    this._value = null;
                    return;
                }
                const parsedValue = this.parseValue(newText); // TODO introduce parsing capabolities
                this._value = parsedValue;
                if (parsedValue !== null && (isNaN(parsedValue) || parsedValue === Infinity || parsedValue === -Infinity)) {
                    // Do not format incorrect value - keep user input
                } else {
                    this._currentText = this.formatValue(this._value);
                }
            } finally {
                this.notifyListeners();
            }
        }
        this.scheduleValueUpdate = debounce(scheduleValueUpdate, 1500); // TODO confiugrable debounce
    }

    commit = (): void => {
        this.scheduleValueUpdate.flush();
    }

    /**
     * Default uses the dot as decimal separator, but allows "," convertig it to dot
     * Amount of decimal places as function enables for dynamic adjustment of decimal places
     * (origin: different unit of measurement may like to have different decimal places formatting)
     */
    public static defaultWithFixedDecimalPlaces(initialValue: NumberEditorValue,
                                                decimalPlaces: () => number): NumberEditor {
        // TODO refine & unit test implementation e.g. singleton consts, localization, thausand separator
        // TODO are we sure format shoudl be toFixed?
        // TODO dirty tech debt (decimalPLaces vs customformatter)
        const formatter: NumberEditorFormatter = value => value === null ? "" : value.toFixed(decimalPlaces());
        const parser: NumberEditorParser = text => {
            if (text.trim().length === 0) {
                return null;
            }
            return text.match(/^-?[0-9]+[.,]?[0-9]*$/) === null ? NaN : parseFloat(parseFloat(text.replace(",", ".")).toFixed(decimalPlaces()));
        }
        return new NumberEditor(initialValue, formatter, parser);
    }

    /**
     * A valid (non NaN, non Inifinite) number, or null
     */
    get value(): NumberEditorValue {
        return this._value !== null && !isNaN(this._value) && this._value !== Infinity && this._value !== -Infinity ? this._value : null;
    }

    get unparseableTextPresent(): boolean {
        return this._value !== null && (isNaN(this._value) || this._value === Infinity || this._value === -Infinity);
    }

    populate(value: NumberEditorValue): void {
        this.scheduleValueUpdate.cancel();

        // TODO: Introduce population/parsing mode - like is it possible to store more detailed value under the hood.
        // A hack to avoid situation where high precision number is passed to populate eg. 230.00000000000003
        // which would actually make it different a bit on second parse (after it's formated).
        // We use format-and-parse chain to ensure value will be the same on reparse.
        // This will also remove warnings in self-validation mechanism about that possibility.
        const formattedForValidation = this.format(value);
        const reparsedForValidation = this.parse(formattedForValidation);

        if (formattedForValidation !== this.format(reparsedForValidation)) {
            console.log("Incorrect value via populate - resetting state to incorrect value", {
                populationValue: value,
                formattedForValidation,
                reparsedForValidation,
                reFormattedParsed: this.format(reparsedForValidation)
            });
            this._value = null;
            this._currentText = "";
            this.notifyListeners();
            return;
        }
        const correctedValue = this.parse(this.format(value));
        this._value = correctedValue;
        this._currentText = this.formatValue(correctedValue);
        this.notifyListeners();
    }

    get readOnly() : boolean {
        return this._readOnly;
    }

    /**
     * Switches into mode when user should not be able to interact with value, where automated
     * prepopulation is still allowed
     */
    makeReadonly = () => {
        this._readOnly = true;
        this.notifyListeners();
    }

    /**
     * Switches to editable mode if currently in read only
     */
    makeEditable = () => {
        this._readOnly = false;
        this.notifyListeners();
    }

    updateText(text: string) {
        this._currentText = text;
        this.scheduleValueUpdate(text);
        this.notifyListeners();
    }

    reset(): void {
        this.scheduleValueUpdate.cancel();
        this._value = this.initialValue;
        this._currentText = this.formatValue(this.initialValue);
        this.notifyListeners();
    }

    get currentText(): string {
        return this._currentText;
    }

    private formatValue(value: NumberEditorValue): string {
        const text = this.format(value);
        if (this.parse(text) !== value) {
            console.warn("Number editor formatter vs parser misconfiguration warning. Parser check failed after formatting the value", {
                value,
                formatted: text,
                parsed: this.parse(text)
            });
        }
        return text;
    }

    private parseValue(text: string): NumberEditorValue {
        const value = this.parse(text);
        const performOptionalValidation = value === null || (!isNaN(value) && value !== Infinity && value !== -Infinity);
        if (performOptionalValidation && this.parse(this.format(value)) !== value) {
            console.warn("Internal parser/formatter check failed (risk detected). Re-parsing the value after formatting will result in different value", {
                inputText: text,
                originalParseValue: value,
                formattingResult: this.format(value),
                parsingAfterFormatResult: this.parse(this.format(value))
            });
            return NaN;
        }
        return value;
    }

    /**
     * Reparses numerical value to a value that can be safely populated without any value adjustments caused by formatting
     * e.g. to make more avare population with "1.234" value where formatter will change it to "1.2", reparse may be used
     * to get the "1.2" value upfront, to base business logic on it's value. For example, if we want to limit the editor
     * value to minimum value of 1.234, but populate(1.234) would actually populate "1.2" making and inifte loop effectively,
     * we can compromise business logic and agree for 1.2 as the expected minimum (as 1.234 is unachievable with formater)
     * and populate with 1.2 effectively avoiding infinite loop.
     * TODO: Consider making this populate by-text to avoid any flaating points numbers risks
     */
    public probeValueAfterReparsing(value: number): NumberEditorValue {
        return this.parse(this.format(value));
    }

    Component = (): JSX.Element => {
        return <MetronicNumberEditor editor={this}/>;
    }

    get errorList(): string[] {
        return []; // TODO return non-empty when "unparseableTextPresent"
    }

    get isValid(): boolean {
        return true;
    }

}