import React from 'react';
import {Card} from "../components/Card";
import {LegacyLocatedSplChart} from "../charting/LegacyLocatedSplChart";

export function ComponentsShowcasePage() {


    return <Card>
        <LegacyLocatedSplChart operation={"induced-ssacy"} housingDiameterMilimeter={4800} pwlTot={80}/>
    </Card>;
}

ComponentsShowcasePage.propTypes = {}

ComponentsShowcasePage.defaultProps = {}
