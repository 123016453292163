import React from 'react';
import {StandardLayout} from "../../layout/StandardLayout";
import {Card} from "../../components/Card";
import {deleteAdjustableImpeller, useFetchImpellerOverviewsReport} from "./AdjustableImpellerService";
import {NavLink} from "react-router-dom";


export function AdjustableImpellersPage(props) {
    const adjustableImpellersWithRefetchFunction = useFetchImpellerOverviewsReport();

    const deleteAdjustableImpellerClicked = (id) => {
        deleteAdjustableImpeller(id);
        alert("Wirnik został usunięty");
        adjustableImpellersWithRefetchFunction.refetch();
    }


    const listElements = adjustableImpellersWithRefetchFunction.result === null ? null : adjustableImpellersWithRefetchFunction.result.map(adjustableImpeller => <tr>
        <td key={adjustableImpeller.name} className="align-top">
            <div className="d-flex flex-center flex-shrink-0 bg-light rounded w-70px h-70px">
                <img className="w-70px h-70px" src="/img/example-14-profile.png" alt="profile thumbnail"/>
            </div>
        </td>
        <td className="align-top text-nowrap">
            <div href="#/" className="text-dark fw-bolder text-hover-primary fs-2x">{adjustableImpeller.diameterAgnosticName}</div>
            <div href="#/" className="text-muted text-hover-primary fs-6">{adjustableImpeller.nameTemplate}</div>
        </td>
        <td className="align-top">
            <div className="row">
                <div className="col-12 col-md-4 mb-3">
                    <a href="#/" className="text-dark fw-bolder text-hover-primary d-block mb-1">{adjustableImpeller.measurementImpellerDiameter}</a>
                    <span className="text-muted fw-bold text-muted d-block fs-7">Średnica pomiarów [mm]</span>


                    <a href="#/" className="text-dark fw-bolder text-hover-primary d-block mb-1">{adjustableImpeller.minimumAllowedDiameter} - {adjustableImpeller.maximumAllowedDiameter}</a>
                    <span className="text-muted fw-bold text-muted d-block fs-7">Zakres średnic [mm]</span>

                </div>
                <div className="col-12 col-md-4 mb-3">
                    <a href="#/" className="text-dark fw-bolder text-hover-primary d-block mb-1">{adjustableImpeller.bladeProfileDisplayName}</a>
                    <span className="text-muted fw-bold text-muted d-block fs-7"> profil łopaty</span>

                </div>
                <div className="col-12 col-md-4 mb-3">
                    <a href="#/" className="text-dark fw-bolder text-hover-primary d-block mb-1">{adjustableImpeller.bladeCount}</a>
                    <span className="text-muted fw-bold text-muted d-block fs-7"> liczba łopat</span>
                </div>
                <div className="col-12 col-md-4 mb-3">
                    <a href="#/" className="text-dark fw-bolder text-hover-primary d-block mb-1">{adjustableImpeller.minAngle.toFixed(1)} - {adjustableImpeller.maxAngle.toFixed(1)}</a>
                    <span className="text-muted fw-bold text-muted d-block fs-7"> zakres kątowy [&deg;]</span>

                </div>
                <div className="col-12 col-md-4 mb-3">
                    <a href="#/" className="text-dark fw-bolder text-hover-primary d-block mb-1">{adjustableImpeller.bladeConfigurationCount}</a>
                    <span className="text-muted fw-bold text-muted d-block fs-7"> liczba konfiguracji kątowych</span>
                </div>

            </div>


        </td>


        <td className="text-end align-top">
            {adjustableImpeller.demonstrational && <div className={"badge badge-light-danger mt-2 mb-4"}>Demo (nieedytowalny)</div>}
            {!adjustableImpeller.demonstrational && <a className="btn btn-light-warning btn-sm me-1 d-block mb-1 text-nowrap" onClick={() => deleteAdjustableImpellerClicked(adjustableImpeller.id)}><i className="fas fa-trash-alt"></i> Usuń</a>}
            {!adjustableImpeller.demonstrational && <a className="btn btn-light-primary btn-sm me-1 d-block mb-1 text-nowrap" onClick={() => alert('Implementacja funkcjonalności wkrótce, przepraszamy.')}><i className="fas fa-chart-line"></i> Symuluj</a>}
            <NavLink to={`/adjustable-impeller/${adjustableImpeller.id}`} className="btn btn-light-info btn-sm me-1 d-block mb-1 text-nowrap"><i className="fas fa-chart-area"></i> Przegląd</NavLink>
        </td>
    </tr>);

    return <>

        <Card header="Wirniki" subHeader="Złączone w średnico- i kątowo- agnostyczne grupy (tzw. wirniki &quot;nienastawione&quot;)"
              toolbarElements={<a href={"#/"} className={"btn btn-sm btn-primary"} onClick={() => alert('Implementacja funkcjonalności wkrótce, przepraszamy.')}><i className={"fas fa-trash"}/> Usuń wszystkie</a>}>

            <div className={"alert alert-secondary mb-5 p-3"}><i className="fas fa-info-circle"></i> Niektóre wirniki są zdefiniowane na stałe jako demonstracyjne (Demo) i niezmienialne, w celu uzyskania zawsze dostępnego, powtarzalnego przykładu podczas rozwoju aplikacji.</div>
            <div className={"alert alert-secondary mb-5 p-3"}><i className="fas fa-info-circle"></i> Funkcjonalność wgrywania obrazów miniatur dla profili nie jest zaimplementowana, dlatego dla wirnika używana jest zawsze ta sama, przykładowa grafika.</div>


            <div className={"table-responsive"}>
                <table className={"table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3"}>
                    <thead>
                    <tr className="fw-bolder text-muted">
                        <th className="w-100px">Obraz</th>
                        <th className="min-w-140px">Nazwa (i szablon)</th>
                        <th className="min-w-120px">Dane podstawowe</th>
                        <th className="min-w-100px text-end">Akcje</th>
                    </tr>
                    </thead>
                    <tbody>
                    {listElements}

                    </tbody>

                </table>
            </div>
        </Card>


    </>
        ;
}

AdjustableImpellersPage.propTypes = {}

AdjustableImpellersPage.defaultProps = {}

