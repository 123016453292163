import React, {useEffect, useState} from 'react';

export default function SimpleVersionNumberFromVersionJson(props) {

    const [version, setVersion] = useState(null);

    useEffect(() => {
        setVersion(null);
        fetch("/version.json").then(response => response.json()).then(resp => setVersion(resp)).catch(r => {
            setVersion("-");
        });
    }, []);

    if (version == null) {
        return ".";
    }

    return <span>{version}</span>;
}

SimpleVersionNumberFromVersionJson.propTypes = {}

SimpleVersionNumberFromVersionJson.defaultProps = {}