import React from 'react';

export default function UboldInvalidFeedbackList(props) {

    if (!props.errors) {
        return null;
    }
    return props.errors.map(err => {
        return <div className="invalid-feedback" key={err}>
            <i className="fe-alert-circle"></i> {err}
        </div>;
    });
}

UboldInvalidFeedbackList.propTypes = {}

UboldInvalidFeedbackList.defaultProps = {}