import React, {useState} from "react";
import {FanSelectionMatch} from "./toFanSelectionMatches";
import {createInternationalizationPrefixer} from "../../platform/i18n/i18n";

const t = createInternationalizationPrefixer("PreselectionFilter");

export type ActivePreselectionFilter = {
    filterId: string, // FROM FanSelectionMatchProperty<number>,
    limitingValue?: number // for incorrectly specified filter value, filter may be active for edit, but not actually filtering
}

export type PreselectionFilter = {
    filterId: string,
    filterName: string,
    unit: string,
    passPredicate: (value: number, match: FanSelectionMatch) => boolean
}

// Need to be lazy for i18n
const AVAILABLE_FILTERS_PROVIDER: () => PreselectionFilter[] = () => [
    {
        filterId: "minimumImpellerDiameter",
        filterName: t("Średnica od"),
        passPredicate(value: number, match: FanSelectionMatch): boolean {
            return match.impellerDiameter >= value;
        },
        unit: "mm"
    },
    {
        filterId: "maximumImpellerDiameter",
        filterName: t("Średnica do"),
        passPredicate(value: number, match: FanSelectionMatch): boolean {
            return match.impellerDiameter <= value;
        },
        unit: "mm"
    },
    {
        filterId: "maxPwl",
        filterName: t("Maks. PWL"),
        passPredicate(value: number, match: FanSelectionMatch): boolean {
            return match.workingPointPwl <= value;
        },
        unit: "dB(A)"
    },
    {
        filterId: "minAirflow",
        filterName: t("Min. Q"),
        passPredicate(value: number, match: FanSelectionMatch): boolean {
            return match.airFlow >= value;
        },
        unit: "m³/s"
    },
    {
        filterId: "minPressure",
        filterName: t("Min. P"),
        passPredicate(value: number, match: FanSelectionMatch): boolean {
            return match.installationStaticPressure >= value;
        },
        unit: "Pa"
    },
    {
        filterId: "maxAirflowError",
        filterName: t("Maks. ΔQ"),
        passPredicate(value: number, match: FanSelectionMatch): boolean {
            return match.airFlowError * 100 <= value;
        },
        unit: "%"
    },
    {
        filterId: "maxPressureError",
        filterName: t("Maks. ΔP"),
        passPredicate(value: number, match: FanSelectionMatch): boolean {
            return match.pressureError * 100 <= value;
        },
        unit: "%"
    },
    {
        filterId: "minApiAirflowReserve",
        filterName: t("Min. zapas API Q"),
        passPredicate(value: number, match: FanSelectionMatch): boolean {
            return match.apiAirflowReserve >= value;
        },
        unit: "%"
    },
    {
        filterId: "minApiPressureReserve",
        filterName: t("Min. zapas API P"),
        passPredicate(value: number, match: FanSelectionMatch): boolean {
            return match.apiPressureReserve >= value;
        },
        unit: "%"
    },
    {
        filterId: "minStaticEfficiency",
        filterName: t("Min. spr."),
        passPredicate(value: number, match: FanSelectionMatch): boolean {
            return match.staticEfficiencyFactor >= value;
        },
        unit: "%"
    },
    {
        filterId: "maxFanPowerWorkingPointProperty",
        filterName: t("Maks. moc"),
        passPredicate(value: number, match: FanSelectionMatch): boolean {
            return match.fanPowerWorkingPoint <= value;
        },
        unit: "kW"
    },
    {
        filterId: "minBladeAngle",
        filterName: t("Kąt min."),
        passPredicate(value: number, match: FanSelectionMatch): boolean {
            return match.bladeAngle >= value;
        },
        unit: "°"
    },
    {
        filterId: "maxRpm",
        filterName: t("Maks. RPM"),
        passPredicate(value: number, match: FanSelectionMatch): boolean {
            return match.rpm <= value;
        },
        unit: "°"
    },
    {
        filterId: "maxBladeAngle",
        filterName: t("Kąt maks."),
        passPredicate(value: number, match: FanSelectionMatch): boolean {
            return match.bladeAngle <= value;
        },
        unit: "°"
    },
    {
        filterId: "maxBladeEndSpeed",
        filterName: t("Uz maks."),
        passPredicate(value: number, match: FanSelectionMatch): boolean {
            return match.bladeEndSpeed <= value;
        },
        unit: "m/s"
    },

]


export function useSelectionFiltering(inputMatches: FanSelectionMatch[]) {

    const [activeFilters, setActiveFilters] = useState<ActivePreselectionFilter[]>([]);

    const activateFilter = (filterId: string) => {
        const filterDef = AVAILABLE_FILTERS_PROVIDER().find(f => f.filterId === filterId)!;
        setActiveFilters(prev => [...prev, {filterId: filterDef.filterId, limitingValue: undefined}]);
    }

    const deActivateFilter = (filterId: string) => {
        setActiveFilters(prev => prev.filter(af => af.filterId !== filterId));
    }

    const setActiveFilterLimitingValue = (filterId: string, newLimitingValue?: number) => {
        setActiveFilters((prev) => {
            const newValue = [...prev];
            newValue.forEach((f, idx) => {
                if (f.filterId === filterId) {
                    newValue[idx].limitingValue = newLimitingValue;
                }
            });
            return newValue;
        });
    }


    const activeFiltersComponent = <ActiveFiltersComponent activeFilters={activeFilters} deActivateFilter={deActivateFilter} setActiveFilterLimitingValue={setActiveFilterLimitingValue}/>;

    const activatorElement = <FilterActivatorComponent activateFilter={activateFilter} activeFilters={activeFilters}/>

    const filteredMatches: FanSelectionMatch[] = [];
    for (const inputMatch of inputMatches) {
        let passed = true;
        for (const activeFilter of activeFilters) {
            const filterDef = AVAILABLE_FILTERS_PROVIDER().find(f => f.filterId === activeFilter.filterId)!;
            if (activeFilter.limitingValue === undefined) {
                continue;
            }
            if (!filterDef.passPredicate(activeFilter.limitingValue, inputMatch)) {
                passed = false;
            }
        }
        if (passed) {
            filteredMatches.push(inputMatch);
        }
    }

    return {
        activeFiltersComponent: activeFiltersComponent,
        activatorElement: activatorElement,
        filteredMatches
    }
}

export function ActiveFiltersComponent(props: {
    activeFilters: ActivePreselectionFilter[],
    deActivateFilter: (filterId: string) => void,
    setActiveFilterLimitingValue: (filterId: string, newLimitingValue?: number) => void
}) {


    return <div className={"d-flex flex-wrap gap-2 flex-row mb-2"}>
        {props.activeFilters.map(af => <ActiveFilterPresenter activeFilter={af} deActivateFilter={props.deActivateFilter} setActiveFilterLimitingValue={props.setActiveFilterLimitingValue}/>)}
    </div>
}

export function ActiveFilterPresenter(props: {
    activeFilter: ActivePreselectionFilter,
    deActivateFilter: (filterId: string) => void,
    setActiveFilterLimitingValue: (filterId: string, newLimitingValue?: number) => void
}) {
    const [textValue, setTextValue] = useState<string>("");

    const onValueChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setTextValue(e.target.value);
        if (isFinite(parseFloat(e.target.value))) {
            const newNumericValue = parseFloat(e.target.value);
            props.setActiveFilterLimitingValue(props.activeFilter.filterId, newNumericValue);
        } else {
            props.setActiveFilterLimitingValue(props.activeFilter.filterId, undefined);
        }
    }

    const filterDef = AVAILABLE_FILTERS_PROVIDER().find(f => f.filterId === props.activeFilter.filterId)!;
    return <div className={"flex-nowrap bg-light rounded-3 p-2 d-flex flex-row gap-1 align-items-center"}>
        <strong className={"text-nowrap"}>🝖 {filterDef.filterName}</strong>:
        <input className={"form-control fs-8 pt-1 pb-1 ps-2 pe-2" + (props.activeFilter.limitingValue === undefined ? " border-danger" : "")} type={"text"} onChange={onValueChange} value={textValue}/>
        <span className={"text-gray-400"}>{filterDef.unit}</span>
        <button className={"btn-text-danger btn btn-xxs"} onClick={() => props.deActivateFilter(props.activeFilter.filterId)}>
            <i className="fas fa-times"/></button>
    </div>;
}

export function FilterActivatorComponent(props: { activateFilter: (filterId: string) => void, activeFilters: ActivePreselectionFilter[] }) {
    const activeFilterIds = props.activeFilters.map(af => af.filterId);
    return <div className={"d-flex flex-wrap gap-1"}>
        {AVAILABLE_FILTERS_PROVIDER().filter(availableFilter => !activeFilterIds.includes(availableFilter.filterId)).map(availableFilter => <>
            <button className={"btn btn-xxs btn-light"} onClick={() => props.activateFilter(availableFilter.filterId)}>🝖 {availableFilter.filterName}</button>
        </>)}
    </div>;
}
