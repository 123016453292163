import {createInternationalizationPrefixer} from "../../platform/i18n/i18n";

export type FanApplicationType = {
    id: string,
    name: string
}

const t = createInternationalizationPrefixer("FanApplicationTypes");

// Need to be lazy for u18n
export const FAN_APPLICATION_TYPES_PROVIDER: () => FanApplicationType[] = () => {
    return [
        {id: "UNSPECIFIED", name: t("Nieokreślona (brak korekt)")},
        {id: "WET_COOLING_TOWER", name: t("Mokra chłodnia")},
        {id: "DRY_COOLER", name: t("Sucha chłodnia")},
        {id: "HYBRID_COOLER", name: t("Chłodnia hybrydowa")},
        {id: "AIR_COOLER_HEAT_EXCHANGER", name: t("Wymiennik ciepła chłodzony powietrzem (ACHE) (odzysk)")},
        {id: "AIR_COOLER_CONDENSER", name: t("Skraplacz pary (ACC) (odzysk)")},
        {id: "AIR_COOLER_HEAT_EXCHANGER_UNCORRECTED", name: t("Wymiennik ciepła chłodzony powietrzem (ACHE) (bez odzysku)")},
        {id: "AIR_COOLER_CONDENSER_UNCORRECTED", name: t("Skraplacz pary (ACC) (bez odzysku)")},
    ];
}
