import React, {useState} from 'react';
import {SimulationDatasheetProps} from "./SimulationDatasheetProps";
import {Card} from "../Card";
import {CalculationExplainer} from "./CalculationExplainer";
import {DemoChart} from "../d3/DemoChart";
import {DimensionalImg} from "./DimensionalImg";
import {AssemblyOverview} from "../assembly/AssemblyOverview";
import {FanEfficiencyChart} from "./FanEfficiencyChart";
import {FanTorqueChart} from "./FanTorqueChart";
import {PartListEntryJson} from "../../pages/mechanical/ImpellerAssemblyService";
import {MechanicalSimulationReport} from "./MechanicalSimulationReport";
import {createInternationalizationPrefixer, getCurrentLanguage, resolveParameterValue} from "../../platform/i18n/i18n";
import {Calculation, resolveParamTranslation} from "../../business/core";
import {Summaries} from "../Summaries";
import {SimulationResultFanChartForSimulationResult, SimulationResultInstallationChartForSimulationResult} from "./SimulationResultFanChart";
import {OctavesSplBarChart} from "./OctavesSplBarChart";
import {PowerCurvesChart} from "./PowerCurvesChart";
import {AcousticAmendmentCurveChart} from "./AcousticAmendmentCurveChart";
import {LATEST_OPTISEL_VERSION} from "../Changelog";

export function SimulationDatasheet(props: SimulationDatasheetProps) {

    const [presentedTab, setPresentedTab] = useState<"parameters" | "mechanics" | "calculations" | "dimensions">("parameters"); // TODO this is temporary, do better tab handling
    const flowSimulationResult = props.simulationResult.flowSimulationResult;
    const complexCalculationToTr = (calculation: Calculation) => {
        return <tr>
            <td className="text-end text-dark fw-bolder fs-6">{resolveParamTranslation(calculation.humanFriendlyNameIk)}</td>
            <td className="">
                <CalculationExplainer calculations={props.simulationResult.calculations} explainedCalculationCode={calculation.code}/>
            </td>
        </tr>;
    };

    const trsForSimpleFlowParams = props.simulationResult.calculations.filter(c => c.tag === "" && !hasCalculationComplexResult(c)).map((c) => calculationToParamCardTr(c, props.simulationResult.calculations));
    const trsForComplexFlowParams = props.simulationResult.calculations.filter(c => c.tag === "" && hasCalculationComplexResult(c)).map((c) => calculationToParamCardTr(c, props.simulationResult.calculations));

    const trsForReserves = props.simulationResult.calculations.filter(c => c.tag === "reserves").map((c) => calculationToParamCardTr(c, props.simulationResult.calculations));
    const trsForAcoustics = props.simulationResult.calculations
        .filter(c => c.tag === "acoustics")
        .map((c) => calculationToParamCardTr(c, props.simulationResult.calculations, 1));

    const trsForCorrections = props.simulationResult.calculations.filter(c => c.tag === "corrections").map((c) => calculationToParamCardTr(c, props.simulationResult.calculations));


    const psiFiPoints = [];
    for (let i = 0; i < flowSimulationResult.psiCurve.discreteFis.length; i++) {
        psiFiPoints.push({x: flowSimulationResult.psiCurve.discreteFis[i], y: flowSimulationResult.psiCurve.valuesForDiscreteFis[i]});
    }


    const pressurePoints: { x: number, y: number }[] = [];
    for (let i = 0; i < flowSimulationResult.installationStaticPressureCurve.discreteAirFlows.length; i++) {
        pressurePoints.push({x: flowSimulationResult.installationStaticPressureCurve.discreteAirFlows[i], y: flowSimulationResult.installationStaticPressureCurve.discreteValuesForDiscreteAirFlows[i]});
    }

    const uncorrectedPressurePoints: { x: number, y: number }[] = [];
    for (let i = 0; i < flowSimulationResult.fanStaticPressureCurve.discreteAirFlows.length; i++) {
        uncorrectedPressurePoints.push({x: flowSimulationResult.fanStaticPressureCurve.discreteAirFlows[i], y: flowSimulationResult.fanStaticPressureCurve.discreteValuesForDiscreteAirFlows[i]});
    }

    return <React.Fragment>
        <div className="card mb-4 mt-5">
            <div className="card-body pt-9 pb-0">
                <div className="d-flex flex-wrap flex-sm-nowrap mb-6">
                    <div className="d-flex flex-center flex-shrink-0 bg-light rounded w-100px h-100px w-lg-150px h-lg-150px me-7 mb-4">
                        <img className="mw-100" src="/img/example-14-profile.png" alt="profile thumbnail"/>
                    </div>
                    <div className="flex-grow-1">
                        <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                            <div className="d-flex flex-column">
                                <div className="d-flex align-items-center mb-1">
                                    <a className="text-gray-800 text-hover-primary fs-2 fw-bolder me-3" href="#/">{props.simulationResult.parameterCatalog.fanName}</a>
                                    <span className="badge badge-light-success me-auto">Szkic symulacyjny</span>
                                </div>
                                <div className="d-flex flex-wrap fw-bold mb-4 fs-5 text-gray-400">
                                    w punkcie pracy {flowSimulationResult.installationStaticPressureWorkingPoint.flow.toFixed(1)} m&sup3;/s
                                    &bull; {flowSimulationResult.installationStaticPressureWorkingPoint.value.toFixed(1)} Pa instalacja &bull; {props.simulationResult.flowSimulationResult.fanStaticPressureWorkingPoint.value.toFixed(1)} Pa wentylator
                                    {/*dobrany na wale Ø {props.simulationResult.parameterCatalog.shaftDiameter.toFixed(0)}mm / L={props.simulationResult.parameterCatalog.shaftLength.toFixed(0)}mm (H={props.simulationResult.parameterCatalog.impellerHeight.toFixed(0)})*/}
                                </div>
                            </div>
                            <div className="d-flex mb-4">

                                <a className="btn btn-sm btn-primary me-3" href={"/api/simulation/pdf-export/" + props.memoizedId + "?appVersion=" + LATEST_OPTISEL_VERSION + "&lang=" + getCurrentLanguage()}>Wydruk ({getCurrentLanguage()})</a>

                            </div>
                        </div>
                        <Summaries>
                            <Summaries.Box label={t("Profil")} value={props.simulationResult.parameterCatalog.profileDisplayName}/>
                            <Summaries.Box label={t("średnica") + " [mm]"} value={props.simulationResult.parameterCatalog.impellerDiameter.toFixed(0)}/>
                            <Summaries.Box label={t("prędkość") + " [min-1]"} value={props.simulationResult.parameterCatalog.rotationsPerMinute.toFixed(0)}/>
                            <Summaries.Box label={t("Kąt łopaty") + " [°]"} value={props.simulationResult.parameterCatalog.bladeAngle.toFixed(1)}/>
                        </Summaries>
                    </div>
                </div>
                <div className="separator"></div>
                <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder">
                    <li className="nav-item"><a className={"nav-link text-active-primary py-5 me-6" + (presentedTab === "parameters" ? " active" : " ")} href="#!" onClick={() => setPresentedTab("parameters")}>{t("Charakterystyka i parametry")}</a></li>
                    <li className="nav-item"><a className={"nav-link text-active-primary py-5 me-6" + (presentedTab === "dimensions" ? " active" : " ")} href="#!" onClick={() => setPresentedTab("dimensions")}>{t("Wymiary montażowe")}</a></li>
                    <li className="nav-item"><a className={"nav-link text-active-primary py-5 me-6" + (presentedTab === "mechanics" ? " active" : " ")} href="#!" onClick={() => setPresentedTab("mechanics")}>{t("Mechanika")}</a></li>
                    <li className="nav-item"><a className={"nav-link text-active-primary py-5 me-6" + (presentedTab === "calculations" ? " active" : " ")} href="#!" onClick={() => setPresentedTab("calculations")}>{t("Obliczenia")}</a></li>
                </ul>
            </div>
        </div>

        {props.simulationResult.mechanicalSimulationFailureReason !== null &&
            <div className={"alert alert-warning"}><i className="fas fa-exclamation-triangle"></i> {t("Brak rozwiązania mechanicznego")}</div>
        }

        {props.simulationResult.mechanicalSimulationFailureReason == null && props.simulationResult.mechanicalDesignCraftingResult?.mechanicalDesign.auditedImpellerAssembly?.clutchOverloaded === true &&
            <div className={"alert alert-warning"}><i className="fas fa-exclamation-triangle"></i>{t("Uwaga - Sprzęgło w symulowanych warunkach jest przeciążone")}</div>
        }


        {presentedTab === "parameters" && <div className="row g-6 g-xl-9 mb-6">
            <div className="col-12">
                <Card header={"Charakterystyka przepływowa"}
                      subHeader={"Wszystkie ciągłe krzywe na charakterystyce są zaprezentowane po uwzględnieniu spadków spowodowanych czynnikami korygującymi (np. przeszkodami). Krzywa przerywana prezentuje oryginalną charakterystykę wentylatora dla dobranego kąta (przed uwzględnieniem spadków)"}>
                    <h3>Wentylatora</h3>
                    <SimulationResultFanChartForSimulationResult flowSimulationResult={props.simulationResult.flowSimulationResult} mainBladeAngleFormatted={props.simulationResult.parameterCatalog.bladeAngle.toFixed(1)}/>
                    <h3>Instalacji</h3>
                    <SimulationResultInstallationChartForSimulationResult flowSimulationResult={props.simulationResult.flowSimulationResult} mainBladeAngleFormatted={props.simulationResult.parameterCatalog.bladeAngle.toFixed(1)}/>
                </Card>
            </div>

            <div className="col-12">
                <Card header={"Charakterystyka mocy wentylatora"}>

                    <PowerCurvesChart
                        mainBladeAngleFormatted={props.simulationResult.parameterCatalog.bladeAngle.toFixed(1)}
                        allAngleVariantsPowerCurves={props.simulationResult.flowSimulationResult.allAngleVariantsFanPowerCurves}
                        powerCurve={props.simulationResult.flowSimulationResult.fanPowerCurve}
                        powerWorkingPoint={props.simulationResult.flowSimulationResult.fanPowerWorkingPoint}
                    />
                </Card>
            </div>
            <div className="col-6">
                <ParamCard header={t("Parametry przepływowe podstawowe")} subheader={t("Parametry obliczone dla wentylatora i dobranego punktu pracy")} trs={trsForSimpleFlowParams}/>
                <ParamCard header={t("Parametry dotyczące zapasów")} subheader={t("Parametry dotyczące zapasów API i kąta")} trs={trsForReserves}/>
                <ParamCard header={t("Parametry dotyczące korekt")} subheader={t("Parametry dotyczące korekt")} trs={trsForCorrections}/>
                <ParamCard header={t("Parametry złożone - przeływu")} subheader={t("Parametry złożone, często pośrednie do obliczeń")} trs={trsForComplexFlowParams}/>
            </div>
            <div className="col-6">
                <Card header={t("Charakterystyka sprawności statycznej")} subHeader={t("Sprawność statyczna na tle krzywej ciśnienia i oporu")} additionalClassNames={"card mb-6 mb-xl-9 mt-1"}>
                    <FanEfficiencyChart
                        efficiencyCurve={props.simulationResult.flowSimulationResult.installationStaticEfficiencyCurve}
                        workingPoint={props.simulationResult.flowSimulationResult.fanStaticPressureWorkingPoint}
                        pressureCurve={props.simulationResult.flowSimulationResult.fanStaticPressureCurve}/>
                </Card>

                <Card header={t("Charakterystyka momentu")} subHeader={t("Moment obrotowy w funkcji obrotów wirnika")} additionalClassNames={"card mb-6 mb-xl-9 mt-1"}>
                    <FanTorqueChart torqueCurve={props.simulationResult.flowSimulationResult.fanTorqueCurve}/>
                </Card>
                <Card header={t("Poglądowa charakterystyka Psi/Fi")} subHeader={""} additionalClassNames={"card mb-6 mb-xl-9 mt-1"}>
                    {/*  @ts-ignore */}
                    <DemoChart pressureCurvePoints={psiFiPoints}
                               uncorrectedPressureCurvePoints={psiFiPoints}
                               workingPointFi={props.simulationResult.parameterCatalog.workingPointFi}
                               workingPointPsi={props.simulationResult.parameterCatalog.workingPointPsi}/>
                </Card>

                <Card header={t("Krzywa poprawek akustycznych")} subHeader={""} additionalClassNames={"card mb-6 mb-xl-9 mt-1"}>
                    <AcousticAmendmentCurveChart pressureCurve={props.simulationResult.flowSimulationResult.installationStaticPressureCurve}
                                                 uncorrectedSoundPressureLevelCurve={props.simulationResult.flowSimulationResult.fanStaticPressureCurve}
                                                 acousticAmendmentCurve={props.simulationResult.soundSimulationResultCatalog.acousticAmendmentCurve}
                                                 workingPoint={props.simulationResult.flowSimulationResult.fanStaticPressureWorkingPoint}/>
                </Card>
                <Card header={t("Oktawowy rozkład mocy akustycznej")} additionalClassNames={"card mb-6 mb-xl-9 mt-1"}>
                    <h5>{t("Moc akustyczna dB(A)")}</h5>
                    <OctavesSplBarChart octavesSpl={props.simulationResult.soundSimulationResultCatalog.octavesPwls.filter(o => o.octaveFrequency > 32 && o.octaveFrequency < 15000)}
                                        octaveSplExtractor={spl => spl.pwlDba}
                                        totalValue={props.simulationResult.soundSimulationResultCatalog.workingPointPwl}
                                        unitName={"dB(A)"}/>
                    <h6>{t("Moc akustyczna dB(Lin)")}</h6>
                    <OctavesSplBarChart octavesSpl={props.simulationResult.soundSimulationResultCatalog.octavesPwls.filter(o => o.octaveFrequency > 32 && o.octaveFrequency < 15000)}
                                        octaveSplExtractor={spl => spl.pwlLin}
                                        totalValue={props.simulationResult.soundSimulationResultCatalog.workingPointPwlLin}
                                        unitName={"dB"}/>
                </Card>

                <ParamCard header={t("Parametry dotyczące hałasu")} subheader={t("Parametry dotyczące hałasu")} trs={trsForAcoustics}/>

            </div>
        </div>}

        {presentedTab === "mechanics" && <div className="col-12">
            {props.simulationResult.mechanicalSimulationFailureReason !== null && <Card header={""}>
                <div className={"alert alert-danger"}>{t("Nie można wyświetlić listy materiałowej - Brak rozwiązania mechanicznego")}: {props.simulationResult.mechanicalSimulationFailureReason}</div>
            </Card>}
            {props.simulationResult.mechanicalSimulationFailureReason === null && <>
                <div className="row g-6 g-xl-9">
                    <div className={"col-12"}>
                        <Card header={t("Lista materiałowa")} additionalClassNames={"card mb-6 mb-xl-9 mt-1"}>
                            <FanConstructionMaterialsTable assemblyPartList={{
                                originalList: [],
                                mergedList: props.simulationResult.mechanicalDesignCraftingResult!.parts
                            }}/>
                        </Card>
                        <Card header={t("Złożenie mechaniczne")} additionalClassNames={"card mb-6 mb-xl-9 mt-1"}>
                            {props.simulationResult.mechanicalDesignCraftingResult !== null && props.simulationResult.mechanicalDesignCraftingResult?.mechanicalDesign.auditedImpellerAssembly !== null &&
                                <AssemblyOverview assembly={props.simulationResult.mechanicalDesignCraftingResult.mechanicalDesign.auditedImpellerAssembly.impellerAssembly} detailsExpandedByDefault={true} includeMaterialTable={false}
                                                  resonanceReport={null}
                                dimensionalImageDescriptors={[]}/>} {/* NO DIMENSIONAL IMAGES HERE BECAUSE DEDICATED TAB IS TO PRESENT DIMENSIONS */}
                        </Card>
                    </div>
                </div>
                <MechanicalSimulationReport simulationResult={props.simulationResult}/>
            </>
            }
        </div>}

        {presentedTab === "dimensions" && <div className="col-12">
            {props.simulationResult.mechanicalSimulationFailureReason !== null && <div className={"alert alert-danger"}>{t("Nie można wyświelić rysunków wymiarowych - Brak rozwiązania mechanicznego")}: {props.simulationResult.mechanicalSimulationFailureReason}</div>}
            {props.simulationResult.mechanicalSimulationFailureReason === null && <Card header={t("Wymiary montażowe")} additionalClassNames={"min-h-500px"}>
                {props.simulationResult.mechanicalDesignCraftingResult!.dimensionalImageDescriptors.map(descriptor => <div className={"mb-20"}><DimensionalImg descriptor={descriptor}/></div>)}
            </Card>}

        </div>}


        {presentedTab === "calculations" && <div className="col-12">
            <Card header={"Obliczenia"} subHeader={"Objaśnienie obliczeń"}>
                {props.simulationResult.calculations.map((calc) => singleCalculationLogListing(calc))}
            </Card>
        </div>}

    </React.Fragment>;
}


export function hasCalculationComplexResult(calculation: Calculation) {
    return (typeof calculation.value !== "number" && typeof calculation.value !== "string") || calculation.value === "N/A";
}

export function singleCalculationLogListing(calc: Calculation) { // TODO functional component
    return <div className="row mb-3">
        <div className="col-3 text-end">
            <div className="badge badge-light-info text-wrap">{resolveParamTranslation(calc.humanFriendlyNameIk)}</div>
            {calc.unit !== "" ? <div className="text-gray-400">[{calc.unit}]</div> : ""}
        </div>
        <div className="col-9">
            {calc.logs.map(log => <div>{log}</div>)}
        </div>
    </div>;
}

export function FanConstructionMaterialsTable(props: {
    assemblyPartList: {
        originalList: PartListEntryJson[],
        mergedList: PartListEntryJson[]
    }
}) {

    const [presentMergedPartList, setPresentMergedPartList] = useState(true);
    const partListEntries: PartListEntryJson[] = presentMergedPartList ? props.assemblyPartList.mergedList : props.assemblyPartList.originalList;

    const toggleMergedPresentation = () => {
        setPresentMergedPartList(prev => !prev);
    }

    let lp = partListEntries.length;
    return <table className={"table table-row-bordered table-row-gray-100 table-striped gs-0 gy-2"}>
        <thead>
        <tr className="fw-bolder text-muted">
            <th>Sztuk</th>
            <th>Nazwa</th>
            <th>l.p.</th>
            <th>Materiał</th>
            <th>Norma</th>
            <th>Masa jedn. [kg]</th>
            <th>Masa całkowita [kg]</th>
            <th>
                {props.assemblyPartList.originalList.length > 0 && <button className="btn btn-text-muted btn-sm" onClick={toggleMergedPresentation}>{presentMergedPartList ? "Pokaż niescalone" : "Pokaż scalone"}</button>}
            </th>
        </tr>
        </thead>
        <tbody>
        {partListEntries.map((part) => (<tr className={"m-4"}>
            <td className={"text-center fs-5"}>{part.pieceCount}</td>
            <td><strong>{part.name}</strong></td>
            <td>{lp--}</td>
            <td>{part.materialName}</td>
            <td>{part.standardName}</td>
            <td>{part.pieceMass.toFixed(2)}</td>
            <td>{(part.pieceCount * part.pieceMass).toFixed(2)}</td>
            <td>
                <div className={"badge badge-light-info"}>{part.humanFriendlyTag}</div>
            </td>
        </tr>))}
        </tbody>
    </table>;
}


export function ParamCard(props: { header: string, subheader: string, trs: React.ReactNode }) {

    return <div className="card mb-4 mt-1">
        {(props.header.length > 0 || props.subheader.length > 0) && <div className="card-header border-0 pt-5">
            <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bolder fs-3 mb-1">{props.header}</span>
                <span className="text-muted mt-1 fw-bold fs-7">{props.subheader}</span>
            </h3>
        </div>}
        <div className="card-body pt-1 pb-0">
            <div className="table-responsive">
                <table className="table align-middle gs-1 gy-1 table-striped">
                    <thead>
                    <tr>
                        <th className="p-0 min-w-150px"></th>
                        <th className="p-0 min-w-120px"></th>
                        <th className="p-0 min-w-40px"></th>
                        <th className="p-0"></th>
                    </tr>
                    </thead>
                    <tbody>
                    {props.trs}
                    </tbody>
                </table>
            </div>
        </div>
    </div>
}

export function ParamCardTr(props: { name: string, value: React.ReactNode, unit: React.ReactNode, extras?: React.ReactNode, estimated?: boolean }) {
    let estimatedWarning = <></>;
    if ( props.estimated ) {
        estimatedWarning = <span className={"text-warning text-nowrap fs-8"}>(<i className="fas fa-exclamation-triangle"></i>{t("est.")})</span>;
    }
    return <tr>
        <td className="text-end text-dark fw-bolder fs-6">{props.name}</td>
        <td className="text-end text-dark fw-bolder fs-6 pe-0 text-nowrap">{estimatedWarning}{props.value}</td>
        <td className="text-end text-muted fw-bold">{props.unit != "-" && props.unit}</td>
        <td className="">{props.extras}</td>
    </tr>;
}

export const calculationToParamCardTr = (calculation: Calculation, allCalculations: Calculation[], prefferedDecimalPlaces: number = 2) => {
    return <ParamCardTr
        key={resolveParamTranslation(calculation.humanFriendlyNameIk)}
        name={resolveParamTranslation(calculation.humanFriendlyNameIk)}
        value={<>
            {typeof calculation.value === "string" && calculation.value !== "N/A" && resolveParameterValue(calculation.value)}
            {typeof calculation.value === "number" && calculation.value.toFixed(prefferedDecimalPlaces)}
        </>}
        unit={calculation.unit}
        extras={<CalculationExplainer calculations={allCalculations} explainedCalculationCode={calculation.code}/>}/>
};


const t = createInternationalizationPrefixer("SimulationDatasheet");


SimulationDatasheet.propTypes = {}

SimulationDatasheet.defaultProps = {}
