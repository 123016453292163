import React, {useState} from "react";
import {StandardLayout} from "../../layout/StandardLayout";
import {Card} from "../../components/Card";
import UboldSpinner from "../../ubold-components/UboldSpinner";
import {useObjectFromUrlResourceOrNull} from "../../platform/hooks/PlatformHooks";


export function StandardListingPage(props) {
    const listings = useObjectFromUrlResourceOrNull("/api/standard-listing");

    if (listings === null) {
        return <UboldSpinner/>;
    }

    const Listing = (props) => {
        const [opened,setOpened] = useState(false);

        return <Card>
            <div className={"d-flex gap-5"}>
            <button className={"btn btn-sm btn-outline btn-outline-primary"} onClick={()=>setOpened(prev=>!prev)}>{opened ? "zwiń" : "rozwiń"}</button>
            <h3 className={"flex-grow-1"}>{props.listing.name}</h3>
            </div>
            {!!opened && <div className={"table-responsive"}>
                <table className={"table table-row-bordered table-row-gray-100 align-middle gs-0 gy-1"}>
                    <thead>
                    <tr className="fw-bolder text-muted">
                        {props.listing.headers.map(h=><th>{h}</th>)}
                    </tr>
                    </thead>
                    <tbody>
                    {props.listing.entries.map(entry=><tr>
                        {entry.map(ee=><td>{ee}</td>)}
                    </tr>)}

                    </tbody>

                </table>
            </div>}
        </Card>
    }

    return <Card header={"Typoszeregi / standardy"} subHeader={"Typoszeregi / standardy to zestawy informacji zaszyte w aplikacji bez możliwości ich zmiany przez użytkownika"}>
            {listings.map(l=><Listing listing={l}/>)}
        </Card>;
}

StandardListingPage.propTypes = {}

StandardListingPage.defaultProps = {}

