import {DatasheetParameterGroup, InstalledFanDatasheet} from "../../business/InstalledFanDatasheet";
import React, {ReactNode, useContext, useState} from "react";
import {ParamCard, ParamCardTr} from "../../components/domain/SimulationDatasheet";
import {OctavesSplBarChart} from "../../components/domain/OctavesSplBarChart";
import {Card} from "../../components/Card";
import {createInternationalizationPrefixer, getCurrentLanguage, resolveIstring, resolveParameterValue} from "../../platform/i18n/i18n";
import {Summaries} from "../../components/Summaries";
import {SimulationResultFanChart} from "../../components/domain/SimulationResultFanChart";
import {PowerCurvesChart} from "../../components/domain/PowerCurvesChart";
import {FanEfficiencyChart} from "../../components/domain/FanEfficiencyChart";
import {FanTorqueChart} from "../../components/domain/FanTorqueChart";
import {DimensionalImg} from "../../components/domain/DimensionalImg";
import {LATEST_OPTISEL_VERSION} from "../../components/Changelog";
import {LegacyLocatedSplChart} from "../../charting/LegacyLocatedSplChart";
import {ResonanceCampbellChart} from "../../components/domain/ResonanceCampbellChart";
import {MechanicalDesignCraftingResultJson} from "../../business/useImpellerSimulation";
import {ImpellerGeometryTable} from "../../components/assembly/ImpellerGeometryTable";
import {HotIssueContext} from "../issues/hotssue/HotIssueContextProvider";


const t = createInternationalizationPrefixer("InstalledFanDatasheet"); // reused SimulationDatasheet

export type ParamsForInteractiveLocatedSplChart = {
    housingDiameterMilimeter: number,
    pwlTot: number,
    operation: "INDUCED_DRAFT" | "FORCED_DRAFT"

}

export function DatasheetComponent({
                                       datasheet,
                                       memoizedDatasheetId,
                                       blueprintId,
                                       paramsForInteractiveLocatedSplChart
                                   }: {
    datasheet: InstalledFanDatasheet,
    memoizedDatasheetId?: string,
    blueprintId?: string,
    paramsForInteractiveLocatedSplChart: ParamsForInteractiveLocatedSplChart
}): JSX.Element {

    const [commentForPrint, setCommentForPrint] = useState<string>("");

    const {currentHotIssueSummary} = useContext(HotIssueContext);

    const pdfExportLinkAutolinkEnhancement = currentHotIssueSummary == null ? "" : "&assignIssueId=" + currentHotIssueSummary.id;

    return <div className={"row"}>
        <div className={"col-12 mb-3"}>
            <div className={"text-center mt-3 mb-5"}>
                <h2>{t("Karta danych technicznych wentylatora")}</h2>
            </div>
            <Card>
                <div className={"d-flex"}>

                    <div className={"flex-grow-1"}>
                        <div className={"fs-7"}>{resolveIstring(datasheet.fanGeneralFanInformation.applicationTypeName)}</div>
                        <a className="text-gray-800 text-hover-primary fs-2 fw-bolder" href="#/">
                            {datasheet.fanGeneralFanInformation.fanName}
                        </a>
                        <div className={"fs-6"}>{resolveIstring(datasheet.fanGeneralFanInformation.manufacturingDescription)}</div>
                        <div className="d-flex flex-wrap fw-bold mb-4 fs-7 text-gray-600">{resolveIstring(datasheet.fanGeneralFanInformation.fanWorkingPointDescription)}</div>
                    </div>
                    <Summaries>
                        {datasheet.importantParameters.map(importantParam =>
                            <Summaries.Box
                                label={resolveIstring(importantParam.name) + (importantParam.unit === "-" || importantParam.unit.length === 0 ? "" : " [" + importantParam.unit + "]")} value={importantParam.value}/>)}

                        {memoizedDatasheetId !== undefined && <div className="d-flex mb-4">
                            {blueprintId && <>
                                <a className={"btn btn-sm btn-secondary me-2 ps-2 pe-2"} href={`/api/impeller-assembly-xls-export/${blueprintId}`}>
                                    <div className={"mb-1 mt-2"}><i className="fas fa-download fs-2"></i></div>
                                    DW
                                </a>
                                <a className={"btn btn-sm btn-secondary me-2 ps-2 pe-2"} href={`/api/impeller-assembly-xls-export/${blueprintId}/workaround`}>
                                    <div className={"mb-1 mt-2"}><i className="fas fa-download fs-2"></i></div>
                                    DW TXT
                                </a>
                                <a className={"btn btn-sm btn-secondary me-2 ps-2 pe-2"} href={`/api/export-bill-of-materials-xlsx/${blueprintId}`}>
                                    <div className={"mb-1 mt-2"}><i className="fas fa-download fs-2"></i></div>
                                    BOM
                                </a>
                            </>}
                            <a className="btn btn-sm btn-primary me-3" href={"/api/simulation/pdf-export/" + memoizedDatasheetId + "?appVersion=" + LATEST_OPTISEL_VERSION + "&comment=" + encodeURIComponent(commentForPrint) + "&lang=" + getCurrentLanguage() + pdfExportLinkAutolinkEnhancement}>
                                <div className={"mb-1 mt-2"}><i className="far fa-file-pdf fs-1"></i></div>
                                {t("Wydruk")}</a>
                        </div>}
                    </Summaries>
                </div>
            </Card>
        </div>

        <div className={"col-12 mb-3"}>
            <Card>
                <h3 className="card-title align-items-start flex-column">
                    <span className="card-label fw-bolder fs-3 mb-1">{t("Charakterystyka przepływowa")}</span>
                </h3>
                <SimulationResultFanChart
                    mainPressureCurve={datasheet.fanStaticPressureCurve}
                    workingPointToMark={datasheet.fanStaticPressureWorkingPoint}
                    alternativeCurves={datasheet.angleVariantFanStaticPressureCurves}
                    mainBladeAngleFormatted={datasheet.importantParameters.filter(p => p.identifier === 'requestedBladeAngle')[0].value}
                    angleFanBestEfficiencyPoint={datasheet.angleFanBestEfficiencyPoint}/>
            </Card>
        </div>

        <div className={"col-6"}>
            {parameterGroupToParamCard(datasheet.parameterGroups[0])}
            {parameterGroupToParamCard(datasheet.parameterGroups[1])}
            {parameterGroupToParamCard(datasheet.parameterGroups[2])}
        </div>
        <div className={"col-6"}>


            {datasheet.parameterGroups[4] !== undefined && parameterGroupToParamCard(datasheet.parameterGroups[4])}

            {datasheet.parameterGroups[3] !== undefined && parameterGroupToParamCard(datasheet.parameterGroups[3])}
            <Card additionalClassNames={"card mb-6 mb-xl-9 mt-1"}>
                <h5>{t("Moc akustyczna dB(A)")}</h5>
                <OctavesSplBarChart octavesSpl={datasheet.octavesPwls.filter(o => o.octaveFrequency > 32 && o.octaveFrequency < 15000)}
                                    octaveSplExtractor={spl => spl.pwlDba}
                                    totalValue={parseFloat(datasheet.workingPointPwlTot.value)}
                                    unitName={"dB(A)"}/>
                <h6>{t("Moc akustyczna dB(Lin)")}</h6>
                <OctavesSplBarChart octavesSpl={datasheet.octavesPwls.filter(o => o.octaveFrequency > 32 && o.octaveFrequency < 15000)}
                                    octaveSplExtractor={spl => spl.pwlLin}
                                    totalValue={parseFloat(datasheet.workingPointPwlTotLin.value)}
                                    unitName={"dB"}/>
                <p className={"pt-3 fs-7 text-center"}>
                    {t('Widmo akustyczne wirnika nie uwzględnia interferencji, odbić oraz innych urządzeń działających w tle.')}
                </p>


            </Card>

        </div>


        <div className={"col-6 mb-4"}>
            <Card header={t("Charakterystyka mocy")}>
                <PowerCurvesChart
                    powerWorkingPoint={datasheet.fanPowerWorkingPoint}
                    powerCurve={datasheet.fanPowerCurve}
                    allAngleVariantsPowerCurves={datasheet.angleVariantFanPowerCurves}
                    mainBladeAngleFormatted={datasheet.importantParameters.filter(p => p.identifier === 'requestedBladeAngle')[0].value}/>
            </Card>
        </div>


        <div className={"col-6"}>
            <Card header={t("Charakterystyka sprawności statycznej")} subHeader={t("Sprawność statyczna na tle krzywej ciśnienia i oporu")} additionalClassNames={"card mb-6 mb-xl-9 mt-1"}>
                <FanEfficiencyChart
                    efficiencyCurve={datasheet.installationStaticEfficiencyCurve}
                    workingPoint={datasheet.fanStaticPressureWorkingPoint}
                    pressureCurve={datasheet.fanStaticPressureCurve}/>
            </Card>
        </div>
        <div className={"col-6"}>
            <Card header={t("Charakterystyka momentu")} subHeader={t("Moment obrotowy w funkcji obrotów wirnika")} additionalClassNames={"card mb-6 mb-xl-9 mt-1"}>
                <FanTorqueChart torqueCurve={datasheet.fanTorqueCurve}/>
            </Card>
        </div>
        {datasheet.mechanicalResultDatasheetParameters != undefined && <div className={"col-6 mb-5"}>
            <Card header={t("Charakterystyka rezonansowa")}>
                {datasheet.mechanicalResultDatasheetParameters.resonanceReport.estimated && <>
                    <div className={"alert alert-warning"}><i className="fas fa-exclamation-triangle"></i> {t("Wartości rezonansów estymowane!")}</div>
                </>}
                <ResonanceCampbellChart
                    resonanceCurve={datasheet.mechanicalResultDatasheetParameters.resonanceReport.resonanceCurve}
                    workingPointResonances={datasheet.mechanicalResultDatasheetParameters.resonanceReport.workingPointResonances}/>
            </Card>
        </div>}

        <div className={"col-12 mb-5"}>
            <Card>
                <LegacyLocatedSplChart
                    operation={paramsForInteractiveLocatedSplChart.operation == "INDUCED_DRAFT" ? "induced-ssacy" : "forced-tloczacy"}
                    housingDiameterMilimeter={paramsForInteractiveLocatedSplChart.housingDiameterMilimeter}
                    pwlTot={paramsForInteractiveLocatedSplChart.pwlTot}/>
            </Card>
        </div>


        {datasheet.mechanicalResultDatasheetParameters != undefined && <div className={"col-12 mb-5"}>
            <Card header={t("Geometria")}>
                <ImpellerGeometryTable geometryReport={datasheet.mechanicalResultDatasheetParameters.geometryReport}
                variant={"full-table"}/>
            </Card>
        </div>}

        <div className={"col-12"}>
            {datasheet.dimensionalImageDescriptors.length > 0 && <Card header={t("Wymiary montażowe")} additionalClassNames={"min-h-500px"}>
                <div className={"row"}>
                    {datasheet.dimensionalImageDescriptors.map((descriptor, idx) =>
                        <div className={(idx == 0 ? "col-12" : "col-6 mt-20")}><DimensionalImg descriptor={descriptor}/></div>)}
                </div>
            </Card>}
        </div>

        <Card header={t("Komentarz")} additionalClassNames={"mt-3"}>
            <textarea className={"w-100"}
                      onChange={e => setCommentForPrint(e.target.value)} value={commentForPrint}/>
        </Card>


    </div>

}


function parameterGroupToParamCard(parameterGroup: DatasheetParameterGroup): ReactNode {
    return <ParamCard
        header={resolveIstring(parameterGroup.name)}
        subheader={resolveIstring(parameterGroup.description)}
        trs={parameterGroup.parameters.map(p => <ParamCardTr name={resolveIstring(p.name)} unit={p.unit} value={resolveParameterValue(p.value)} estimated={p.estimated}/>)}/>
}