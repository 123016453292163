import React from 'react';
import {cloneJson} from "../util/JsonObjectCloner";
import UboldInputTextarea from "../../ubold-components/form/UboldInputTextarea";
import {MetronicInputText} from "../../metronic-components/MetronicInputText";

export default function TextFieldWebFormComponent(props) {

    const dsl = props.dsl;

    function change(e) {
        const newDsl = cloneJson(dsl);
        newDsl.value = e.target.value;
        newDsl.errors = [];
        props.onDslChange(newDsl);
    }

    if (dsl.configuration.multiline) {
        return <UboldInputTextarea label={dsl.configuration.label} value={dsl.value} errors={dsl.errors} onChange={change} placeholder={dsl.configuration.placeholder}/>
    } else {
        return <MetronicInputText type={dsl.configuration.type} label={dsl.configuration.label} value={dsl.value} errors={dsl.errors} onChange={change} placeholder={dsl.configuration.placeholder}/>
    }

}

TextFieldWebFormComponent.propTypes = {}

TextFieldWebFormComponent.defaultProps = {}