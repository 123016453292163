import React from 'react';

export function MetronicInvalidFeedbackList(props) {
    if (!props.errors) {
        return null;
    }
    return props.errors.map(err => {
        return <div className="invalid-feedback mt-0" key={err}>
            <i className="fe-alert-circle"></i> <strong>{err}</strong>
        </div>;
    });
}

MetronicInvalidFeedbackList.propTypes = {}

MetronicInvalidFeedbackList.defaultProps = {}
