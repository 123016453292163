import React, {useState} from 'react';
import FormComponentByDsl from "./FormComponentByDsl";
import {cloneJson} from "../util/JsonObjectCloner";

export default function MultiplierWebFormComponent(props) {

    // There is a major problem with maintaining just values array vs React components
    // Each componentds needs a key=, but we do not have a key in the value JSON model
    // And do not want to artificially add one. But, from the time of first render react
    // needs stable keys - thus, they are assigned on first render, and on consequitive
    // "Add" operations.
    const [instanceUids, setInstanceUids] = useState(props.dsl.value.map(e => Math.random()));
    const dsl = props.dsl;

    function changeInstanceValue(idx, newVal, newErrs) {
        console.log("Pre instance-update instances length is: " + dsl.value.length);
        console.log("Multiplier updates under index ", idx);
        console.log("new instance value", newVal);
        const newDsl = cloneJson(dsl);
        newDsl.value[idx] = newVal; // Even that component reports dsl update, we stick (assume) that dsl is based on template, and only value & error updated
        newDsl.errors[idx] = newErrs;
        props.onDslChange(newDsl);
    }

    function requestInstanceRemoval(e, idx) {
        console.log("Requested instance removal ", idx);
        e.preventDefault();
        const newDsl = cloneJson(dsl);
        newDsl.value.splice(idx, 1);
        newDsl.errors.splice(idx, 1);
        props.onDslChange(newDsl);
        instanceUids.splice(idx, 1);
        setInstanceUids(instanceUids);
    }

    function onAddClick(e) {
        e.preventDefault();
        const newValue = cloneJson(dsl.configuration.instanceValueTemplate);
        const newErrors = cloneJson(dsl.configuration.instanceErrorsTemplate);
        let newDsl = JSON.parse(JSON.stringify(dsl));
        newDsl.value.push(newValue);
        newDsl.errors.push(newErrors);
        props.onDslChange(newDsl);
        instanceUids.push(Math.random());
        setInstanceUids(instanceUids);
    }

    const formDom = [];
    dsl.value.forEach((instanceValue, idx) => {
        const instanceDsl =
            {
                configuration: cloneJson(dsl.configuration.instanceConfigurationTemplate),
                value: instanceValue,
                errors: dsl.errors[idx]
            };

        const uid = instanceUids[idx];

        formDom.push(
            <div className="clearfix" key={uid}>
                <FormComponentByDsl dsl={instanceDsl} onDslChange={(newDsl) => changeInstanceValue(idx, newDsl.value, newDsl.errors)}/>
                <div className="float-right" style={{marginBottom: "-5px"}}>
                    {instanceDsl.uid}
                    <button className="btn btn-xs btn-rounded btn-soft-info" onClick={(e) => requestInstanceRemoval(e, idx)}><i className="fe-arrow-up"></i> delete</button>
                </div>
            </div>
        );
    })


    formDom.push(<div className="row" key={"add"}>
        <div className="col-8 offset-4 mt-2 text-right">
            <button className={"btn btn-soft-info btn-xs"} onClick={onAddClick}>
                <i className="fe-plus"/> Add item
            </button>
        </div>
    </div>);

    return <div>
        {(dsl.configuration.label ? <div className="col-8 offset-4 mt-2">
            <div className="separator">{dsl.configuration.label}</div>
        </div> : null)}

        {formDom}
    </div>;
}

MultiplierWebFormComponent.propTypes = {}

MultiplierWebFormComponent.defaultProps = {}