import React from 'react';

import {curveToPoints} from "../../../business/useImpellerSimulation";
import {D3ChartAxis} from "../../../platform/d3chart/D3ChartAxis";
import {D3ChartGridLines} from "../../../platform/d3chart/D3ChartGridLines";
import {D3ChartLine} from "../../../platform/d3chart/D3ChartLine";
import {D3ChartScaleManager} from "../../../platform/d3chart/D3ChartScaleManager";
import {D3ChartRenderable} from "../../../platform/d3chart/D3Chart";
import {D3ChartComponent} from "../../../platform/d3chart/D3ChartComponent";
import {D3ChartPoint} from "../../../platform/d3chart/D3ChartPoint";
import {FanSelectionMatchedFan} from "../SelectionService";
import {MouseGuidesD3ChartRenderable} from "../../../charting/MouseGuidesD3ChartRenderable";
import {HoverExplainerD3ChartRenderable} from "../../../charting/HoverExplainerD3ChartRenderable";
import {FanSelectionMatch} from "../toFanSelectionMatches";


export function SelectionMatchedFansChart(props: {
    matchedFans: FanSelectionMatchedFan[],
    requestedPoint: { airFlow: number, pressure: number },
    pickedMatchIdentifier: string
}) {

    const chartSize = {width: 1200, height: 500};
    const chartMargins = {left: 40, top: 0, right: 5, bottom: 40};

    const flowAxis = new D3ChartAxis([0.0, -1.0], [0.0, chartSize.width], "bottom", 15, "d3-axis-slight-domain-line", 0, 0, "Q [m³/s]");
    const pressureAxis = new D3ChartAxis([-1.0, 0.0], [0.0, chartSize.height - chartMargins.top - chartMargins.bottom], "left", 10, "d3-axis-slight-domain-line", 0, 0, "Pst [Pa]");

    const verticalGridLines = new D3ChartGridLines(flowAxis.scale, flowAxis, "vertical", "d3-stroke-1 d3-opacity-05 d3-stroke-black");
    const horizontalGridLinesStrong = new D3ChartGridLines(pressureAxis.scale, pressureAxis, "horizontal", "d3-stroke-1 d3-opacity-10 d3-stroke-black");


    const adjustableImpellersAdded: string[] = [];
    const pressureLines: D3ChartLine<{ flow: number, value: number }>[] = props.matchedFans.map(matchedFan => {

        const firstResultForImpellerId = !adjustableImpellersAdded.includes(matchedFan.adjustableImpellerId);
        adjustableImpellersAdded.push(matchedFan.adjustableImpellerId)

        if (!firstResultForImpellerId && props.pickedMatchIdentifier !== matchedFan.matchIdentifier) {
            //return null;
        }
        const line = new D3ChartLine<{ flow: number, value: number }>(
            flowAxis.scale,
            pressureAxis.scale,
            curveToPoints(matchedFan.generalSimulationResult.flowSimulationResult.fanStaticPressureCurve),
            chPoint => chPoint.flow,
            chPoint => chPoint.value,
            ""+(firstResultForImpellerId ? "d3-stroke-black" : "") + (matchedFan.matchIdentifier === props.pickedMatchIdentifier ? " d3-stroke-3 d3-stroke-black" :" d3-stroke-1 d3-stroke-black d3-opacity-10"));
        line.setDescriber(point =>
            matchedFan.generalSimulationResult.parameterCatalog.fanName +
            " " +
            point.flow.toFixed(1) + "m3/s " +
            point.value.toFixed(1) + "Pa")
        return line;
    }).filter(l => l != null) as D3ChartLine<{ flow: number, value: number }>[];

    const requestedPoint = new D3ChartPoint(flowAxis.scale, pressureAxis.scale, props.requestedPoint.airFlow, props.requestedPoint.pressure, 5, undefined, "d3-fill-primary");

    const pickedMatch : FanSelectionMatchedFan | undefined = props.matchedFans.find(m=>m.matchIdentifier === props.pickedMatchIdentifier);

    const foundPoint = pickedMatch === undefined ?
        null :
        new D3ChartPoint(
            flowAxis.scale,
            pressureAxis.scale,
            pickedMatch.generalSimulationResult.flowSimulationResult.fanStaticPressureWorkingPoint.flow,
            pickedMatch.generalSimulationResult.flowSimulationResult.fanStaticPressureWorkingPoint.value,
            5, undefined, "d3-fill-secondary");

    const scaleManager = new D3ChartScaleManager([...pressureLines], 1.2);

    const renderables: D3ChartRenderable[] = [
        scaleManager, // Scale manager comes first, to recalculate axes scales!
        flowAxis,
        pressureAxis,
        verticalGridLines,
        horizontalGridLinesStrong,
        ...pressureLines,
        requestedPoint,
        new MouseGuidesD3ChartRenderable(flowAxis.scale, pressureAxis.scale),
        new HoverExplainerD3ChartRenderable([...pressureLines], true)
    ];
    foundPoint !== null && renderables.push(foundPoint);

    return <D3ChartComponent width={chartSize.width} height={chartSize.height} chartRenderables={renderables} margins={chartMargins} key={props.pickedMatchIdentifier}/>
}
