import {ErrorTreeNode} from "./ImpellerAssemblyService";


export function ErrorTreePresenter( props: {root:ErrorTreeNode}) {
    return <div className={"ps-10"}>

        <div>{props.root.name} <strong className={"text-warning"}>{props.root.error}</strong></div>
        <div>
            {props.root.children.map(ch=> <ErrorTreePresenter root={ch}/>)}
        </div>
    </div>
}