import React from "react";
import {FanSelectionMatch} from "./toFanSelectionMatches";
import {createInternationalizationPrefixer} from "../../platform/i18n/i18n";

const t = createInternationalizationPrefixer("Preselection");

const TechnicalNumericValue = (props: { value: string, unit: string, unitWidthClass? : string }) => <div className={"technical-value-with-unit"}>
    <span className={"technical-value"}>{props.value}</span> <span className={(props.unitWidthClass ?? "w-40px")+" text-muted d-inline-block text-start"}>{props.unit}</span>
</div>;
export type FanSelectionMatchProperty<T> = {
    id: string,
    name: string,
    unit?: string,
    renderer: (value: T) => React.ReactNode,
    extractor: (match: FanSelectionMatch) => T;
    defaultOrder: "asc" | "desc"
}

export const nameMatchPropertyProvider: () => FanSelectionMatchProperty<string> = () => ({
    id: "name",
    name: t("Nazwa"),
    renderer(value: string): React.ReactNode {
        return <strong>{value}</strong>;
    },
    extractor(match: FanSelectionMatch) {
        return match.fanName;
    },
    defaultOrder: "asc"
});

export const impellerDiameterMatchPropertyProvider: () => FanSelectionMatchProperty<number> = () => ({
    id: "impellerDiameter",
    name: t("Średnica wirnika"),
    renderer(value: number): React.ReactNode {
        return <TechnicalNumericValue value={value.toFixed(0)} unit={"mm"}/>
    },
    extractor(match: FanSelectionMatch) {
        return match.impellerDiameter;
    },
    defaultOrder: "asc"
});

export const airFlowMatchProperty: FanSelectionMatchProperty<number> = {
    id: "airFlow",
    name: "Q",
    renderer(value: number): React.ReactNode {
        return <TechnicalNumericValue value={value.toFixed(1)} unit={"m³/s"}/>;
    },
    extractor(match: FanSelectionMatch) {
        return match.airFlow;
    },
    defaultOrder: "asc"
}
export const installationStaticPressureMatchProperty: FanSelectionMatchProperty<number> = {
    id: "installationPressure",
    name: "P",
    renderer(value: number): React.ReactNode {
        return <TechnicalNumericValue value={value.toFixed(1)} unit={"Pa"}/>;

    },
    extractor(match: FanSelectionMatch) {
        return match.installationStaticPressure;
    },
    defaultOrder: "desc"
};
export const rpmMatchProperty: FanSelectionMatchProperty<number> = {
    id: "rpm",
    name: "n",
    renderer(value: number): React.ReactNode {
        return <TechnicalNumericValue value={value.toFixed(1)} unit={"min-1"}/>;
    },
    extractor(match: FanSelectionMatch) {
        return match.rpm;
    },
    defaultOrder: "asc"
};
export const bladeEndSpeedProperty: FanSelectionMatchProperty<number> = {
    id: "bladeEdnSpeed",
    name: "Uz",
    renderer(value: number): React.ReactNode {
        return <TechnicalNumericValue value={value.toFixed(1)} unit={"m/s"}/>;
    },
    extractor(match: FanSelectionMatch) {
        return match.bladeEndSpeed
    },
    defaultOrder: "asc"
};
export const bladeAngleMatchProperty: FanSelectionMatchProperty<number> = {
    id: "bladeAngle",
    name: "α",
    renderer(value: number): React.ReactNode {
        return <TechnicalNumericValue value={value.toFixed(1)} unit={"˚"}/>;
    },
    extractor(match: FanSelectionMatch) {
        return match.bladeAngle;
    },
    defaultOrder: "asc"
}
export const workingPointPwlProperty: FanSelectionMatchProperty<number> = {
    id: "workingPointPwl",
    name: "PWLtot",
    renderer(value: number): React.ReactNode {
        return <TechnicalNumericValue value={value.toFixed(1)} unit={"dB(A)"}/>;
    },
    extractor(match: FanSelectionMatch) {
        return match.workingPointPwl;
    },
    defaultOrder: "asc"
};
export const pwlInOptimalConditionsProperty: FanSelectionMatchProperty<number> = {
    id: "pwlInOptimalConditions",
    name: "PWL",
    renderer(value: number): React.ReactNode {
        return <TechnicalNumericValue value={value.toFixed(1)} unit={"dB(A)"}/>;
    },
    extractor(match: FanSelectionMatch) {
        return match.pwlInOptimalConditions;
    },
    defaultOrder: "asc"
};
export const staticEfficiencyProperty: FanSelectionMatchProperty<number> = {
    id: "staticEfficiency",
    name: "\u03B7ₛ", // note subscript s here (static)
    renderer(value: number): React.ReactNode {
        return <TechnicalNumericValue value={value.toFixed(1)} unit={"%"} unitWidthClass={"w-20px"}/>;
    },
    extractor(match: FanSelectionMatch) {
        return match.staticEfficiencyFactor * 100;
    },
    defaultOrder: "desc"
};
export const workingPointInstallationTotalEfficiencyPercentProperty: FanSelectionMatchProperty<number> = {
    id: "workingPointInstallationTotalEfficiencyPercentProperty",
    name: "\u03B7ₜ", // note subscript t here (total)
    renderer(value: number): React.ReactNode {
        return <TechnicalNumericValue value={value.toFixed(1)} unit={"%"} unitWidthClass={"w-30px"}/>;
    },
    extractor(match: FanSelectionMatch) {
        return match.workingPointInstallationTotalEfficiencyPercentProperty;
    },
    defaultOrder: "desc"
};
export const fanPowerWorkingPointProperty: FanSelectionMatchProperty<number> = {
    id: "fanPowerWorkingPoint",
    name: "N",
    renderer(value: number): React.ReactNode {
        return <TechnicalNumericValue value={value.toFixed(1)} unit={"kW"}/>;
    },
    extractor(match: FanSelectionMatch) {
        return match.fanPowerWorkingPoint;
    },
    defaultOrder: "desc"
};
export const airFlowErrorProperty: FanSelectionMatchProperty<number> = {
    id: "airFlowError",
    name: "ΔQ",
    renderer(value: number): React.ReactNode {
        return <TechnicalNumericValue value={value.toFixed(1)} unit={"%"}/>;
    },
    extractor(match: FanSelectionMatch) {
        return match.airFlowError * 100;
    },
    defaultOrder: "asc"
};
export const pressureErrorProperty: FanSelectionMatchProperty<number> = {
    id: "pressureError",
    name: "ΔP",
    renderer(value: number): React.ReactNode {
        return <TechnicalNumericValue value={value.toFixed(1)} unit={"%"}/>;
    },
    extractor(match: FanSelectionMatch) {
        return match.pressureError * 100;
    },
    defaultOrder: "asc"
};
export const apiAirflowReserveProperty: FanSelectionMatchProperty<number> = {
    id: "apiAirflowReserve",
    name: "API Q",
    renderer(value: number): React.ReactNode {
        return <TechnicalNumericValue value={value.toFixed(1)} unit={"%"} unitWidthClass={"w-20px"}/>;
    },
    extractor(match: FanSelectionMatch) {
        return match.apiAirflowReserve;
    },
    defaultOrder: "desc"
};
export const apiPressureReserveProperty: FanSelectionMatchProperty<number> = {
    id: "apiPressureReserve",
    name: "API P",
    renderer(value: number): React.ReactNode {
        return <TechnicalNumericValue value={value.toFixed(1)} unit={"%"} unitWidthClass={"w-20px"}/>;
    },
    extractor(match: FanSelectionMatch) {
        return match.apiPressureReserve;
    },
    defaultOrder: "desc"
};
// lazy due to i18n
export const BASIC_MATCH_PROPERTIES_PROVIDER: () => FanSelectionMatchProperty<any>[] = () => [
    nameMatchPropertyProvider(),
    airFlowMatchProperty,
    installationStaticPressureMatchProperty,
    workingPointInstallationTotalEfficiencyPercentProperty,
    rpmMatchProperty,
    bladeEndSpeedProperty,
    bladeAngleMatchProperty,
    impellerDiameterMatchPropertyProvider(),
    workingPointPwlProperty,
    pwlInOptimalConditionsProperty,
    staticEfficiencyProperty,
    fanPowerWorkingPointProperty,
    airFlowErrorProperty,
    pressureErrorProperty,
    apiAirflowReserveProperty,
    apiPressureReserveProperty
];