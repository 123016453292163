import {MeasurementUnit} from "platform/measurement/MeasurementUnits";
import {ValidNumber} from "../../platform/measurement/ValidNumber";

const BASE_UNIT_CHARACTERISTIC = {
    toBaseUnit: (customValue: ValidNumber) => customValue,
    fromBaseUnit: (baseUnitValue: ValidNumber) => baseUnitValue,
    additionalDecimalPlaces: 0
}

export const UNMEASURABLE_BASE_UNIT: MeasurementUnit = {
    id: "unmeasurable",
    displayText: "-",
    ...BASE_UNIT_CHARACTERISTIC
}

export const PASCAL_BASE_UNIT: MeasurementUnit = {
    id: "pascal",
    displayText: "Pa",
    ...BASE_UNIT_CHARACTERISTIC
}

export const HECTO_PASCAL_BASE_UNIT: MeasurementUnit = {
    id: "hectopascal",
    displayText: "hPa",
    ...BASE_UNIT_CHARACTERISTIC
}

export const KILO_PASCAL_UNIT_ON_PASCAL_BASE: MeasurementUnit = {
    id: "kilopascal",
    displayText: "kPa",
    toBaseUnit: customUnitValue => (customUnitValue * 1000),
    fromBaseUnit: baseUnitValue => (baseUnitValue * 0.001),
    additionalDecimalPlaces: 3
}
export const KILO_PASCAL_UNIT_ON_HECTO_PASCAL_BASE: MeasurementUnit = {
    id: "kilopascal",
    displayText: "kPa",
    toBaseUnit: customUnitValue => (customUnitValue * 10),
    fromBaseUnit: baseUnitValue => (baseUnitValue * 0.1),
    additionalDecimalPlaces: 1
}
export const HECTO_PASCAL_UNIT_ON_PASCAL_BASE: MeasurementUnit = {
    id: "hectopascal",
    displayText: "hPa",
    toBaseUnit: customUnitValue => (customUnitValue * 100),
    fromBaseUnit: baseUnitValue => (baseUnitValue * 0.01),
    additionalDecimalPlaces: 2
}
export const MMH2O_ON_PASCAL: MeasurementUnit = {
    id: "mm2ho",
    displayText: "mmH2O",
    toBaseUnit: customUnitValue => (customUnitValue / 0.10197162129779283),
    fromBaseUnit: baseUnitValue => (baseUnitValue * 0.10197162129779283),
    additionalDecimalPlaces: 2
}
export const PSI_ON_PASCAL: MeasurementUnit = {
    id: "psi",
    displayText: "Psi",
    toBaseUnit: customUnitValue => (customUnitValue / 0.000145038),
    fromBaseUnit: baseUnitValue => (baseUnitValue * 0.000145038),
    additionalDecimalPlaces: 5
}


export const MILLIMETER_BASE_UNIT: MeasurementUnit = {
    id: "millimeter",
    displayText: "mm",
    ...BASE_UNIT_CHARACTERISTIC
}

export const PERCENT_BASE_UNIT: MeasurementUnit = {
    id: "percent",
    displayText: "%",
    ...BASE_UNIT_CHARACTERISTIC
}

export const METER_UNIT_ON_MILLIMETER_BASE: MeasurementUnit = {
    id: "meter",
    displayText: "m",
    toBaseUnit: customValue => customValue * 1000,
    fromBaseUnit: baseUnitValue => baseUnitValue * 0.001,
    additionalDecimalPlaces: 3
}

export const FOOT_UNIT_ON_MILLIMETER_BASE: MeasurementUnit = {
    id: "foot",
    displayText: "ft",
    toBaseUnit: customValue => customValue * (1 / 0.00328084),
    fromBaseUnit: baseUnitValue => baseUnitValue * 0.00328084,
    additionalDecimalPlaces: 3
}

export const METER_BASE_UNIT: MeasurementUnit = {
    id: "meter",
    displayText: "m",
    ...BASE_UNIT_CHARACTERISTIC
}

export const FEET_UNIT_ON_METER_BASE: MeasurementUnit = {
    id: "feet",
    displayText: "ft",
    toBaseUnit: customValue => customValue / 3.28084,
    fromBaseUnit: baseUnitValue => baseUnitValue * 3.28084,
    additionalDecimalPlaces: 0
}

export const MILIMETER_UNIT_ON_METER_BASE: MeasurementUnit = {
    id: "milimeter",
    displayText: "mm",
    toBaseUnit: customValue => customValue / 1000,
    fromBaseUnit: baseUnitValue => baseUnitValue * 1000,
    additionalDecimalPlaces: -3
}

export const INCH_UNIT_ON_METER_BASE: MeasurementUnit = {
    id: "inch",
    displayText: "in",
    toBaseUnit: customValue => customValue / 39.3701,
    fromBaseUnit: baseUnitValue => baseUnitValue * 39.3701,
    additionalDecimalPlaces: -2
}


export const FlowMeasuringOptions = {
    IMPELLER_PRESSURE: {
        baseUnit: PASCAL_BASE_UNIT,
        customUnits: [KILO_PASCAL_UNIT_ON_PASCAL_BASE, HECTO_PASCAL_UNIT_ON_PASCAL_BASE]
    }
}

export const DEGREE_BASE_UNIT: MeasurementUnit = {
    id: "degree",
    displayText: "°",
    ...BASE_UNIT_CHARACTERISTIC
}


export const METER_PER_SECOND_BASE_UNIT: MeasurementUnit = {
    id: "meterpersecond",
    displayText: "m/s",
    ...BASE_UNIT_CHARACTERISTIC
}
export const KILOMETER_PER_HOUR_ON_METER_PER_SECOND: MeasurementUnit = {
    id: "kilometerperhour",
    displayText: "km/h",
    toBaseUnit: customValue => customValue / 3.6,
    fromBaseUnit: baseUnitValue => baseUnitValue * 3.6,
    additionalDecimalPlaces: 0
}

export const KILOGRAM_PER_CUBIC_METER_BASE_UNIT: MeasurementUnit = {
    id: "kilogrampercubicmeter",
    displayText: "kg/m³",
    ...BASE_UNIT_CHARACTERISTIC
}

export const POUNDS_PER_CUBIC_FEETON_KILOGRAM_PER_CUBIC_METER_BASE_UNIT: MeasurementUnit = {
    id: "pundPerCubicFeet",
    displayText: "lb/ft³",
    toBaseUnit: customValue => customValue / 0.062427960576145,
    fromBaseUnit: baseUnitValue => baseUnitValue * 0.062427960576145,
    additionalDecimalPlaces: 2
}

export const CELCIUS_BASE_UNIT: MeasurementUnit = {
    id: "celcius",
    displayText: "°C",
    ...BASE_UNIT_CHARACTERISTIC
}

export const KELVIN_ON_CELCIUS: MeasurementUnit = {
    id: "kelvin",
    displayText: "K",
    toBaseUnit: customValue => customValue - 273.15,
    fromBaseUnit: baseUnitValue => baseUnitValue + 273.15,
    additionalDecimalPlaces: 1
}

export const FARENHEIT_ON_CELCIUS: MeasurementUnit = {
    id: "farenheit",
    displayText: "°F",
    toBaseUnit: customValue => (customValue - 32) * (5.0 / 9.0),
    fromBaseUnit: baseUnitValue => (baseUnitValue * (9.0 / 5.0)) + 32,
    additionalDecimalPlaces: 0
}

export const CUBIC_METER_PER_SECOND_BASE_UNIT: MeasurementUnit = {
    id: "cubicmeterpersecond",
    displayText: "m³/s",
    ...BASE_UNIT_CHARACTERISTIC
}

export const CUBIC_METER_PER_HOUR_ON_CUBIC_METER_PER_SECOND: MeasurementUnit = {
    id: "cubicmeterperhour",
    displayText: "m³/h",
    toBaseUnit: customValue => customValue / (60.0 * 60.0),
    fromBaseUnit: baseUnitValue => baseUnitValue * (60.0 * 60.0),
    additionalDecimalPlaces: 0
}

export const CUBIC_FOOT_PER_HOUR_ON_CUBIC_METER_PER_SECOND: MeasurementUnit = {
    id: "cubicfootperhour",
    displayText: "ft³/h",
    toBaseUnit: customValue => customValue / (127133),
    fromBaseUnit: baseUnitValue => baseUnitValue * (127133),
    additionalDecimalPlaces: 0
}

export const ROTATION_PER_MINUTE_BASE_UNIT: MeasurementUnit = {
    id: "rotationPerMinute",
    displayText: "min-1",
    ...BASE_UNIT_CHARACTERISTIC
}