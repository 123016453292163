import {NumberEditor} from "form/editors/NumberEditor";
import React, {useCallback} from "react";

export function MetronicNumberEditor(props: { editor: NumberEditor }) {

    const propagateChangeEventToEditor = useCallback((event: React.FormEvent<HTMLInputElement>) => {
        props.editor.updateText(event.currentTarget.value);
    }, [props.editor]);


    return <>
        <input
            type="text"
            className="form-control form-control form-control-solid fs-7 p-2"
            value={props.editor.currentText}
            onChange={propagateChangeEventToEditor}
            onBlur={props.editor.commit}
            disabled={props.editor.readOnly}/>
        {props.editor.unparseableTextPresent ? <strong className={"text-danger"}>X</strong> : null}
    </>
}