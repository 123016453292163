import {useFetchAllObjs, useFetchAllObjsWithRefetchCapability} from "../../platform/hooks/PlatformTypeScriptHooks";
import AjaxService from "../../platform/ajax/AjaxService";
import {FiCurve} from "../../business/core";


export interface ImpellerAdjustOptionOverviewJson {
    bladeAngle: number
}

export interface AdjustableImpellerOverviewJson {
    id: string,
    diameterAgnosticName: string,
    nameTemplate: string,
    bladeConfigurationCount: number,
    minAngle: number,
    maxAngle: number,
    bladeCount: number
    measurementImpellerDiameter: number,
    bladeProfileName: string,
    bladeProfileDisplayName: string,
    minimumAllowedDiameter: number,
    maximumAllowedDiameter: number,
    impellerAdjustOptions: ImpellerAdjustOptionOverviewJson[]
};


export interface ImpellerAdjustOptionJson {
    bladeAngle: number,
    psiFiCurve: FiCurve,
    lambdaFiCurve: FiCurve,
    efficiencyFiCurve: FiCurve,
    acousticAmendmentFiCurve: FiCurve
}

export interface AdjustableImpellerJson {
    id: string,
    diameterAgnosticName: string,
    nameTemplate: string,
    bladeProfileId: string,
    minimumAllowedDiameter: number,
    maximumAllowedDiameter: number,
    impellerAdjustOptions: ImpellerAdjustOptionJson[]
}

export function useFetchImpellerOverviewsReport() {
    return useFetchAllObjsWithRefetchCapability<AdjustableImpellerOverviewJson[]>("/api/adjustable-impeller-report");
}

export function useFetchAdjustableImpeller(id: string) {
    return useFetchAllObjs<AdjustableImpellerJson>(`/api/adjustable-impeller/${id}`);
}

export function deleteAdjustableImpeller(id: string) {
    AjaxService.delete("/api/adjustable-impeller/" + id);
}

export interface BladeProfileReportJsonEntryStatistic {
    bladeCount: number,
    diameterAgnosticImpellerName: string,
    minBladeAngle: number,
    maxBladeAngle: number
}

export interface BladeProfileReportJsonEntry {
    id: string,
    thumbnailUrl: string,
    name: string,
    displayName: string,
    minDiameterLimit: number,
    maxDiameterLimit: number,
    minBladeEndSpeedLimit: number,
    maxBladeEndSpeedLimit: number,
    minBladeAngleLimit: number,
    maxBladeAngleLimit: number,
    betaAngleIncrementation: number,
    statistics: BladeProfileReportJsonEntryStatistic[],
    demonstrational: boolean
}

export interface BladeProfileReportJson {
    entries: BladeProfileReportJsonEntry[]
}

export function useFetchBladePorofilesReport() {
    return useFetchAllObjsWithRefetchCapability<BladeProfileReportJson>("/api/blade-profiles-report");
}


export function deleteBladeProfile(id: string) {
    AjaxService.delete("/api/blade-profile/" + id);
}
