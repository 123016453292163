import {useEffect, useState} from 'react';
import BrowserService from "./BrowserService";

export default function BrowserContainer(props) {

    const [results, setResults] = useState([]);
    const [fetching, setFetching] = useState(false);
    const [criteria, setCriteria] = useState({...props.initialCriteria});

    const propsUrl = props.url;
    const propsCriteriaPreprocessor = props.criteriaPreprocessor;
    useEffect(() => {
        let criteriaToSend = criteria;
        if (propsCriteriaPreprocessor) {
            criteriaToSend = propsCriteriaPreprocessor(criteriaToSend);
        }

        if (!criteriaToSend) {
            return;
        }

        setFetching(true);

        BrowserService.fetchResults(propsUrl, criteriaToSend)
            .then(response => {
                console.log("Browser response", response);
                setResults(response);
            }).finally(() => {
            setFetching(false);
        });

    }, [criteria, propsUrl, propsCriteriaPreprocessor]);


    function changeCriteria(newCriteria) {
        setCriteria(newCriteria);
        // Note it will automatically call useEffect
    }

    function resetCriteria() {
        setCriteria({...props.initialCriteria});
    }

    const layoutComponent = props.layoutComponentFactory({
        results: results,
        fetching: fetching,
        criteria: criteria,
        changeCriteriaListener: changeCriteria,
        resetCriteriaListener: resetCriteria
    });

    return layoutComponent;
}

BrowserContainer.propTypes = {}

BrowserContainer.defaultProps = {}