import React, {useState} from 'react';
import {Calculation, resolveParamTranslation} from "../../business/core";


export function CalculationExplainer({calculations, explainedCalculationCode}: { calculations: Calculation[], explainedCalculationCode: string }) {

    const [expanded, setExpanded] = useState(false);
    const [presentedCalculationCodes, setPresentedCalculationCodes] = useState([explainedCalculationCode]);


    if (calculations.filter(c => c.code === explainedCalculationCode).length < 1) {
        return null; // Do not present explainer if context do not contain this calculation TODO improve design (silent fail)
    }

    const appendPresentedCalculation = (code: string) => {
        setPresentedCalculationCodes(prevState => [...prevState, code]);
    }

    const expandedComponents = [];
    if (expanded) {
        for (const code of presentedCalculationCodes) {
            const thisCalculation = calculations.filter(calc => calc.code === code)[0];

            if (!thisCalculation) {
                console.warn("NO CALC", code);
                continue;
            }

            expandedComponents.push(<div className="row pb-2 mb-2 border-bottom-dashed border-gray-200">
                <div className="col-2 text-active-gray-600 text-center">
                    <strong>{resolveParamTranslation(thisCalculation.humanFriendlyNameIk)} </strong>
                    <div className="text-gray-400">[{thisCalculation.unit}]</div>
                    <div className="text-gray-200">({thisCalculation.code})</div>
                </div>
                <div className="col-10" style={{fontFamily: "consolas"}}>
                    {thisCalculation.logs.map(logMessage => <div><pre style={{margin:0}}>{logMessage}</pre></div>)}
                    <div>
                        {thisCalculation.relatedCalculations.map(relCode => <div className="btn btn-sm btn-light-info py-1 px-3 m-1 badge-sm me-3 cursor-pointer" onClick={() => appendPresentedCalculation(relCode)}>{resolveParamTranslation(calculations.filter(c=>c.code===relCode)[0]?.humanFriendlyNameIk) ?? "Brak nazwy "+relCode}</div>)}
                    </div>
                    <div>
                    {calculations.filter(potentiallyRelCalc => potentiallyRelCalc.relatedCalculations.includes(thisCalculation.code)).map(dependentCalc=>

                            <div className="btn btn-sm btn-outline-primary py-1 px-3 m-1 badge-sm me-3 cursor-pointer" onClick={() => appendPresentedCalculation(dependentCalc.code)}>Użyte w: {resolveParamTranslation(dependentCalc.humanFriendlyNameIk)}</div>

                    )}
                    </div>
                </div>

            </div>);
        }
    }

    if (expanded) {
        return <div>
            <div className="modal bg-white" tabIndex={-1} id="kt_modal_2" style={{display: "block"}}>
                <div className="modal-dialog modal-fullscreen">
                    <div className="modal-content shadow-none">
                        <div className="modal-header">
                            <h5 className="modal-title">Objaśnienie obliczeń</h5>
                            <div className="btn btn-icon btn-sm btn-active-light-primary ms-2" aria-label="Close" onClick={() => setExpanded(false)}>
                                <i className="fas fa-times fa-3x"/>
                            </div>
                        </div>
                        <div className="modal-body">
                            {expandedComponents}
                        </div>

                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary" onClick={() => setExpanded(false)}>Zamknij</button>
                        </div>
                    </div>
                </div>
            </div>

        </div>;
    }

    return <button className="btn btn-custom m-0 p-0" onClick={() => setExpanded(true)}><i className="fas fa-calculator"/></button>;
}

CalculationExplainer.propTypes = {}

CalculationExplainer.defaultProps = {}
