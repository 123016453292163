import React from "react";


export function OptiselModal(props: {
    opened: boolean,
    header: string,
    closeable: boolean,
    onClose: () => void,
    children: React.ReactNode
}) {
    if (!props.opened) {
        return null;
    }

    return <div className="modal  show" id="centermodal" role="dialog" style={{display: "block"}} aria-modal="true">
        <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
                <div className="modal-header">
                    <h4 className="modal-title" id="myCenterModalLabel">{props.header}</h4>
                    {props.closeable && <button type="button" className="close" data-dismiss="modal" aria-hidden="true" onClick={props.onClose}>×</button>}
                </div>
                <div className="modal-body">
                    {props.children}
                </div>
            </div>
        </div>
    </div>;
}