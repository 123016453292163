import {SelectionRequestJson} from "../../../business/ApiJsonTypes";
import {convertCriteriaToExportFormat} from "./ExportedCriteriaV1";
import React from "react";


export function SelectionCriteriaDownloader(props: { criteria: SelectionRequestJson }) {

    const exportCriteria = () => {
        const exportedCriteria = convertCriteriaToExportFormat(props.criteria);
        const fileData = JSON.stringify(exportedCriteria);
        const blob = new Blob([fileData], {type: "text/plain"});
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.download = 'selection-criteria-' + (new Date().toLocaleString('sv').replace(' ', 'T')) + '.json'; // ISO date
        link.href = url;
        link.click();
    }

    return <button className={"btn btn-sm btn-outline btn-outline-primary"} onClick={exportCriteria}><i
        className="fas fa-save"></i></button>;

}