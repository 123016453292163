import React from 'react';
import {MechanicalSelectionScoreJson} from "pages/mechanical/ImpellerAssemblyService";


export function AssemblyScoreBar({mechanicalSelectionScore}:{mechanicalSelectionScore:MechanicalSelectionScoreJson}) {
    return <div className={"d-flex justify-content-center"}>
        <div className={"badge " + (mechanicalSelectionScore.kryt1MoMsPassed ? "badge-light-success" : "badge-light-danger")}>
            <i className={mechanicalSelectionScore.kryt1MoMsPassed ? "fas fa-check me-2" : "fas fa-times me-2"}></i>
            Kryt. 1 MoMs ({mechanicalSelectionScore.kryt1MoMsValue.toFixed(2)})
        </div>
        <div className={"badge " + (mechanicalSelectionScore.kryt2MbMsPassed ? "badge-light-success" : "badge-light-danger")}>
            <i className={mechanicalSelectionScore.kryt2MbMsPassed ? "fas fa-check me-2" : "fas fa-times me-2"}></i>
            Kryt. 2 MbMs ({mechanicalSelectionScore.kryt2MbMsValue.toFixed(2)})
        </div>
        <div className={"badge " + (mechanicalSelectionScore.kryt3NrNdPassed ? "badge-light-success" : "badge-light-danger")}>
            <i className={mechanicalSelectionScore.kryt3NrNdPassed ? "fas fa-check me-2" : "fas fa-times me-2"}></i>
            Kryt. 3 NrNd ({mechanicalSelectionScore.kryt3NrNdValue.toFixed(2)})
        </div>
        <div className={"badge " + (mechanicalSelectionScore.kryt4NrNzPassed ? "badge-light-success" : "badge-light-danger")}>
            <i className={mechanicalSelectionScore.kryt4NrNzPassed ? "fas fa-check me-2" : "fas fa-times me-2"}></i>
            Kryt. 4 NrNz ({mechanicalSelectionScore.kryt4NrNzValue.toFixed(2)})
        </div>
        <div className={"badge " + (mechanicalSelectionScore.resonanceRfPassed ? "badge-light-success" : "badge-light-danger")}>
            <i className={mechanicalSelectionScore.resonanceRfPassed ? "fas fa-check me-2" : "fas fa-times me-2"}></i>
            Rezonans RF
        </div>
        <div className={"badge " + (mechanicalSelectionScore.resonanceRfX2Passed ? "badge-light-success" : "badge-light-danger")}>
            <i className={mechanicalSelectionScore.resonanceRfX2Passed ? "fas fa-check me-2" : "fas fa-times me-2"}></i>
            Rezonans RF X2
        </div>
        <div className={"badge " + (mechanicalSelectionScore.resonanceBpfPassed ? "badge-light-success" : "badge-light-danger")}>
            <i className={mechanicalSelectionScore.resonanceBpfPassed ? "fas fa-check me-2" : "fas fa-times me-2"}></i>
            Rezonans BPF
        </div>
        <div className={"badge " + (mechanicalSelectionScore.vonMisesPinLoadPassed ? "badge-light-success" : "badge-light-danger")}>
            <i className={mechanicalSelectionScore.vonMisesPinLoadPassed ? "fas fa-check me-2" : "fas fa-times me-2"}></i>
            Czop
        </div>
    </div>;
}

AssemblyScoreBar.propTypes = {}

AssemblyScoreBar.defaultProps = {}
