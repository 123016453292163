import React from 'react';
import {NavLink} from "react-router-dom";
import {StandardLayout} from "../../layout/StandardLayout";
import {Card} from "../../components/Card";
import UboldSpinner from "../../ubold-components/UboldSpinner";
import AjaxService from "../../platform/ajax/AjaxService";
import {useAllEffectiveTranslationRules} from "../../platform/i18n/i18n";


export interface TranslationRuleJson {
    code: string,
    pl: string,
    en: string,
    de?: string,
    ru?: string,
    plH: boolean,
    enH: boolean,
    deH: boolean,
    ruH: boolean,
}

export interface TranslationRuleJsonWithMeta extends TranslationRuleJson {
    plsource?: string,
    ensource?: string,
    desource?: string,
    rusource?: string
}

function b64EncodeUnicode(str:string) {
    // first we use encodeURIComponent to get percent-encoded Unicode,
    // then we convert the percent encodings into raw bytes which
    // can be fed into btoa.
    return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
        function toSolidBytes(match, p1) {
            return String.fromCharCode(('0x' + p1) as unknown as number);
        }));
}

function TranslationsTable(props: {
                               rules: TranslationRuleJsonWithMeta[],
                               onDeleteClicked: (code: string) => void
                           },
) {
    return <div className={"table-responsive"}>
        <table className={"table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3"}>
            <thead>
            <tr className="fw-bolder text-muted">
                <th className="w-100px">Kod</th>
                <th className="min-w-140px">PL</th>
                <th className="min-w-120px">EN</th>
                <th className="min-w-120px">DE</th>
                <th className="min-w-120px">RU</th>
                <th className="w-200px">Akcje</th>
            </tr>
            </thead>
            <tbody>
            {props.rules.map(rule => <tr>
                <td>{rule.code}</td>
                <td>
                    <div>{rule.pl}</div>
                    {rule.pl && (typeof rule.plsource !== undefined) && rule.plsource === "static" &&
                        <div className={"badge badge-sm badge-light-secondary"}>Domyślne</div>}
                    {rule.pl && (typeof rule.plsource !== undefined) && rule.plsource === "api" &&
                        <div className={"badge badge-sm badge-warning"}>Ustawione</div>}
                </td>
                <td>
                    <div>{rule.en}</div>
                    {rule.en && (typeof rule.ensource !== undefined) && rule.ensource === "static" &&
                        <div className={"badge badge-sm badge-light-secondary"}>Domyślne</div>}
                    {rule.en && (typeof rule.ensource !== undefined) && rule.ensource === "api" &&
                        <div className={"badge badge-sm badge-warning"}>Ustawione</div>}
                </td>
                <td>
                    <div>{rule.de}</div>
                    {rule.de && (typeof rule.desource !== undefined) && rule.desource === "static" &&
                        <div className={"badge badge-sm badge-light-secondary"}>Domyślne</div>}
                    {rule.de && (typeof rule.desource !== undefined) && rule.desource === "api" &&
                        <div className={"badge badge-sm badge-warning"}>Ustawione</div>}
                </td>
                <td>
                    <div>{rule.ru}</div>
                    {rule.ru && (typeof rule.rusource !== undefined) && rule.rusource === "static" &&
                        <div className={"badge badge-sm badge-light-secondary"}>Domyślne</div>}
                    {rule.ru && (typeof rule.rusource !== undefined) && rule.rusource === "api" &&
                        <div className={"badge badge-sm badge-warning"}>Ustawione</div>}
                </td>
                <td>
                    <button className="btn btn-bg-light btn-active-color-primary btn-sm me-2"
                            onClick={() => props.onDeleteClicked(rule.code)}>
                        <i className="fas fa-trash-alt"></i>
                        Usuń
                    </button>
                    <NavLink to={"/set-translation-rule?code=" + b64EncodeUnicode(rule.code)}
                             className="btn btn-primary btn-sm me-1">
                        <i className="fas fa-plus-circle"></i>
                        Ustaw
                    </NavLink>
                </td>
            </tr>)}
            </tbody>
        </table>
    </div>;
}

export function TranslationRulesPage(props: any) {
    const [translationRules, translationRulesLoaded, refetchBackendRules] = useAllEffectiveTranslationRules();

    const onDeleteClicked = (code: string) => {
        AjaxService.delete("/api/translation-rules/" + encodeURIComponent(code))
            .then(result => {
                alert("Usunięto regułę tłumaczenia");
                window.location.reload();
                refetchBackendRules();
            }).catch(error => {
            alert("WYSTĄPIŁ BŁĄD PRZY USUWANIU REGUŁY");
            console.error(error);
            refetchBackendRules();
        });

    }

    const onDeleteAllClicked = () => {
        AjaxService.delete("/api/translation-rules").then(result => {
            alert("Usunięto wszystkie ustawione reguły tłumaczenia (pozostawiając domyślne).");
            refetchBackendRules();
        }).catch(error => {
            console.error(error);
            alert("Wystapił błąd przy usuwaniu wszystkich reguł tłumaczeń.");
            refetchBackendRules();
        });


    }


    if (!translationRulesLoaded) {
        return <><UboldSpinner/></>;
    }

    return <>

        <Card header="Reguły tłumaczeń" subHeader="Reguł tłumaczenia tekstów występujących w aplikacji"
              toolbarElements={<NavLink to={"/set-translation-rule"} className={"btn btn-sm btn-primary"}><i
                  className={"fas fa-plus-circle"}/> Ustaw tłumaczenie</NavLink>}>

            <h3>Tłumaczenia z brakami</h3>
            <TranslationsTable rules={translationRules.filter(r => !r.pl || !r.en || !r.de || !r.ru)} onDeleteClicked={onDeleteClicked}/>

            <h3>Wszystkie pozostałe tłumaczenia</h3>
            <TranslationsTable rules={translationRules.filter(r => r.pl && r.en && r.de && r.ru)} onDeleteClicked={onDeleteClicked}/>
        </Card>
        <Card additionalClassNames={"mt-2"}>
            <p>
                <strong>Usuwanie wszsytkich ustawionych reguł tłumacznia.</strong> Klikając w poniższy przycisk można
                usunąć wszystkie zapisane na konkretnym (obecnym) środowisku reguły tłumaczenia,
                przez co aplikacja wróci do wartości domyślnych osadzonych w kodzie programu.
            </p>
            <p>
                <button className={"btn btn-sm btn-danger"} onClick={onDeleteAllClicked}>Usuń wszystkie ustawione reguły (wróć do domyślnych)</button>
            </p>
            <p>
                Typowy tok pracy z tłumaczeniami powinien wyglądać następująco: podczas rozwoju programu zostają
                napotkane na interfejsie użytkownika brakujące tłumaczenia, wyświetlane tekstem objętym "@@@",
                przykładowo "@@@-param-Strata ciśnienia z kształtu wlotu@@@". Napotkanie takiego tekstu spowoduje, że
                aplikacja zarejetruje go w rejestrze tłumaczeń automatycznie jako brakujący, gdzie można
                z poziomu interfejsu użytkownika uzupełnić zestaw tłumaczeń we wszsytkich językach dla tego komuniaktu
                (następnie należy odświeżyć stronę). Od tego momentu "@@@" nie będą wyświetlane, jednak nowe
                tłumaczenia nie będą osadzone na zawsze w aplikacji, a jedynie zapisane w bazie danych obecnego
                środiwska. Aby utrwalić je na stałe, i z autmoatu przenieść je także przy wdrożeniu na nowe środowiska,
                należy obecny stan bazy tłumaczeń osadzić w aplikacji jako domyślny - narzędziem developerskim dostępnym
                poniżej - i wydać nową wersję aplikacji. Po tej operacji, można skorzystać z opcji "Usuń wszystkie
                reguły",
                ponieważ poleganie na lokalnie zapisanych tłumaczeniach w bazie danych środowiska nie będzie konieczne -
                nowe tłumaczenia będą już osadzone w aplikacji na stałe, i dostępne nawet jeżeli aplikacja
                zostanie wdrożona na nowe środowisko, z nową, pustą bazą danych.
            </p>
        </Card>
        <Card additionalClassNames={"mt-2"}>
            <p>
                Narzędzie developerskie: <a href={"/api/translation-rules"} target={"_blank"}>zestawienie
                tłumaczeń</a> do eksportowania skonfigurowanych na danym środowisku tłumaczeń w celu
                osadzenia ich w kodzie aplikacji jako nowe domyślne.
            </p>
        </Card>


    </>
        ;
}
