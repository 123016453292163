import React from 'react';

export default function StandardBrowserPaginationComponent(props) {

    function seekToNextPage(offset) {
        props.onCriteriaChange({...props.criteria, offset: offset})
    }

    if (props.fetching === true) {
        // Do not render if there is no results yet
        return null;
    }

    const nextPageOffset = props.criteria.offset + props.criteria.limit;
    let previousPageOffset = props.criteria.offset - props.criteria.limit;
    if (previousPageOffset <= 0) {
        previousPageOffset = 0;
    }

    return <div className={"text-right mt-3"}>
        {props.criteria.offset > 0 && <button className={"btn btn-primary btn-rounded mr-2"} onClick={() => seekToNextPage(previousPageOffset)}>See previous</button>}
        {props.results.length === props.criteria.limit && <button className={"btn btn-primary btn-rounded"} onClick={() => seekToNextPage(nextPageOffset)}>See next</button>}

    </div>;
}

StandardBrowserPaginationComponent.propTypes = {}

StandardBrowserPaginationComponent.defaultProps = {}