import {MechanicalSelectionH3RangeEstimationJson} from "./ImpellerAssemblyService";


/**
 * Takes ALL estimations with samples as argument, and together with given setup (pin/clutch) display possible range for jus t thoe pin/clutch/form length
 * (gets some redundant data on input and filters some out)
 */
export function MiniSelectiveH3RangeEstimationBadge( props: {
    selectionH3RangeEstimation: MechanicalSelectionH3RangeEstimationJson | null,
    pinDiameter: number,
    formBladeLength: number, // double
    clutchTypeName: string
}) {

    const samplesForThisSetup = props.selectionH3RangeEstimation?.samples.filter(sample=> {
        return sample.pinDiameter === props.pinDiameter && sample.clutchTypeName === props.clutchTypeName && Math.abs(props.formBladeLength - sample.formBladeLength) < 1; // Warning. Conversion somewhere happened and blade length is rounded?
    });

    if ( samplesForThisSetup === undefined || samplesForThisSetup.length === 0) {
        return <></>;
    }

    const sameSideMin = Math.min(...samplesForThisSetup.map(s=>s.range.sameSideGuess.min));
    const sameSideMax = Math.max(...samplesForThisSetup.map(s=>s.range.sameSideGuess.max));
    const oppoSideMin = Math.min(...samplesForThisSetup.map(s=>s.range.oppositeRangeGuess.min));
    const oppoSideMax = Math.max(...samplesForThisSetup.map(s=>s.range.oppositeRangeGuess.max));

    // pin{props.pinDiameter} len{props.formBladeLength} clu{props.clutchTypeName}
    return <div className={"border-dashed border-1 m-2 p-1 text-gray-500 fs-8"}>
        <div>
            <strong>H3 (przeciwne)</strong> {oppoSideMin}mm - {oppoSideMax}mm
        </div>
        <div>
            <strong>H3 (te same)</strong> {sameSideMin}mm - {sameSideMax}mm
        </div>
    </div>;
}