import React from 'react';
import {NavLink} from "react-router-dom";
import {
    FanSelectionMatchedFan,
    matchedFanToSimulationCriteria
} from "../selection/SelectionService";
import {i18n} from "../../platform/i18n/i18n";
import {SelectionRequestJson, SimulationRequestJson} from "../../business/ApiJsonTypes";
import {AdminOnly} from "../../layout/AdminOnly";
import {Protected} from "../../layout/Protected";


export function SimulateButtonForSelectionResult(props: { selectionRequestUsed: SelectionRequestJson, matchedFan: FanSelectionMatchedFan }) {
    const simulationCriteria = matchedFanToSimulationCriteria(props.selectionRequestUsed, props.matchedFan);
    return <SimulateButton simulationRequest={simulationCriteria}/>
}

export function SimulateButton(props: { simulationRequest: SimulationRequestJson }) {
    const base64Criteria = btoa(JSON.stringify(props.simulationRequest));
    return <Protected privilege={"IMPELLER_SIMULATION"}><NavLink to={"/simulation?b64Criteria=" + base64Criteria} className={"btn btn-sm btn-outline btn-outline-dashed me-3 text-nowrap"}><i className="fas fa-chart-line"></i> {i18n("SimulateButton:Symuluj")}</NavLink></Protected>;
}
