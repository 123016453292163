import React from 'react';

export default function UBoldModal(props) {

    if (!props.opened) {
        return null;
    }

    return <div className="modal  show" id="centermodal" tabIndex="-1" role="dialog" style={{display: "block"}} aria-modal="true">
        <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
                <div className="modal-header">
                    <h4 className="modal-title" id="myCenterModalLabel">{props.header}</h4>
                    {props.closeable && <button type="button" className="close" data-dismiss="modal" aria-hidden="true" onClick={props.onClose}>×</button>}
                </div>
                <div className="modal-body">
                    {props.children}
                </div>
            </div>
        </div>
    </div>;
}

UBoldModal.propTypes = {}

UBoldModal.defaultProps = {
    closeable: true
}