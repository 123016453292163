import React from "react";
import {BaseEditor, Editor} from "../Editor";
import {LabeledEditor} from "../../pages/selection/useFanCriteria";

export interface SubEditorMapping<T> {
    propertyName: string,
    propertyDisplayName: string,
    editor: Editor<T>
    propertyType: string;
    // TODO instead of non type save propertyName, use: applicator: (obj:OBJECT, editorValue: T)=>any; and "populator" (reverse way)
}

export class ObjectEditor<OBJECT> extends BaseEditor<OBJECT> {

    constructor(private readonly subEditorMappings: SubEditorMapping<any>[]) {
        super();
        subEditorMappings.forEach(subEditorMapping => {
            subEditorMapping.editor.addChangeListener(this.notifyListeners);
        })
    }

    Component = (): JSX.Element => {
        return <>
            {this.subEditorMappings.map(mapping =>
                <LabeledEditor key={mapping.propertyName} label={mapping.propertyDisplayName} editor={mapping.editor}/>
            )}</>;
    }

    commit(): void {
        this.subEditorMappings.forEach(mapping => mapping.editor.commit());
    }

    get errorList(): string[] {
        const errors: string[] = [];
        this.subEditorMappings.forEach(mapping => mapping.editor.errorList.forEach(e => errors.push(e)))
        return errors;
    }

    populate(value: OBJECT): void {
        this.subEditorMappings.forEach(mapping => {
            if (typeof value[mapping.propertyName as keyof OBJECT] !== mapping.propertyType) {
                console.error("Cannot use value to populate sub editor",{
                    typeRequired: mapping.propertyType,
                    typeGivenInObjectAttribute: (typeof value[mapping.propertyName as keyof OBJECT]),
                    value,
                    mapping,
                    editor: mapping.editor
                });
                throw new Error("Cannot populate object editor with value");
            }
            const propertyValue = value[mapping.propertyName as keyof OBJECT];
            mapping.editor.populate(propertyValue);
        });
    }

    reset(): void {
        this.subEditorMappings.forEach(mapping => mapping.editor.reset());
    }

    get value(): OBJECT {
        const object: { [key: string]: any } = {}; // TODO lot of Typescript typing?
        this.subEditorMappings.forEach(mapping => {
            object[mapping.propertyName] = mapping.editor.value
        });

        return object as OBJECT;
    }

}