import {BaseEditor} from "form/Editor";
import {MetronicOneOfEditor} from "../metronic/MetronicOneOfEditor";

export type SelectionKey = string;
export type SelectionLabel = string;

export interface SelectionOption {
    key: SelectionKey;
    label: SelectionLabel;
}

// Idea: For type safety this one of editor could be specific type based, not string based, for literal enums
export class OneOfEditor extends BaseEditor<SelectionKey> {

    private _value: SelectionKey;
    private readonly _options: SelectionOption[];
    private defaultValue: SelectionKey;
    private readonly RenderComponent: (props: { editor: OneOfEditor }) => JSX.Element;

    constructor(options: SelectionOption[], RenderComponent?: (props: { editor: OneOfEditor }) => JSX.Element, value?: SelectionKey) {
        super();
        if (options.length === 0) {
            throw new Error("Select editor cannot be created without any options");
        }
        if (value !== undefined && !options.find(o => o.key === value)) {
            console.error("Incorrect selection editor value", {value, options});
            throw new Error("Incorrectly created selection editor - current value is not a valid option");
        }
        this.RenderComponent = RenderComponent ?? MetronicOneOfEditor
        this._value = value !== undefined ? value : options[0].key;
        this._options = options;
        this.defaultValue = this._value;
    }

    get value(): SelectionKey {
        return this._value;
    }

    get options(): SelectionOption[] {
        return this._options;
    }

    populate(value: SelectionKey): void {
        if (!this._options.find(o => o.key === value)) {
            console.warn("Discaring selection population, value not found in possible options", {
                value,
                options: this._options
            });
            return;
        }
        this._value = value;
        this.notifyListeners();
    }

    reset(): void {
        this._value = this.defaultValue;
        this.notifyListeners();
    }

    commit(): void {
    }

    Component = (): JSX.Element => {
        const ThisRenderComponent = this.RenderComponent;
        return <ThisRenderComponent editor={this}/>;
    }

    get errorList(): string[] {
        return [];
    }

    get isValid(): boolean {
        return true;
    }

}