import React from 'react';
import TextFieldWebFormComponent from "./TextFieldWebFormComponent";
import MultiplierWebFormComponent from "./MultiplierWebFormComponent";
import OneOfWebFormComponent from "./OneOfWebFormComponent";
import KeyValueWebFormComponent from "./KeyValueWebFormComponent";
import GroupWebFormComponent from "./GroupWebFormComponent";
import BasicDropzoneUploadWebFormComponent from "../upload/BasicDropzoneUploadWebFormComponent";
import BasicDropzoneImageUploadWebFormComponent from "../upload/BasicDropzoneImageUploadWebFormComponent";
import ManyOfCheckboxWebFormComponent from "./ManyOfCheckboxWebFormComponent";
import HiddenWebFormComponent from "./HiddenWebFormComponent";
import OptionalLongFieldWebFormComponent from "./OptionalLongFieldWebFormComponent";
import AsyncAutocompleteWebFormComponent from "./AsyncAutocompleteWebFormComponent";
import OptionalDoubleFieldWebFormComponent from "./OptionalDoubleFieldWebFormComponent";

export default function FormComponentByDsl(props) {
    if (props.dsl.configuration.typeCode === 'text') {
        return <TextFieldWebFormComponent dsl={props.dsl} onDslChange={props.onDslChange}/>;
    }
    if (props.dsl.configuration.typeCode === 'optionallong') {
        return <OptionalLongFieldWebFormComponent dsl={props.dsl} onDslChange={props.onDslChange}/>;
    }
    if (props.dsl.configuration.typeCode === 'optionalDouble') {
        return <OptionalDoubleFieldWebFormComponent dsl={props.dsl} onDslChange={props.onDslChange}/>;
    }
    if (props.dsl.configuration.typeCode === 'hidden') {
        return <HiddenWebFormComponent dsl={props.dsl} onDslChange={props.onDslChange}/>;
    }
    if (props.dsl.configuration.typeCode === 'group') {
        return <GroupWebFormComponent dsl={props.dsl} onDslChange={props.onDslChange}/>;
    }
    if (props.dsl.configuration.typeCode === 'oneof') {
        return <OneOfWebFormComponent dsl={props.dsl} onDslChange={props.onDslChange}/>;
    }
    if (props.dsl.configuration.typeCode === 'manyofcheckbox') {
        return <ManyOfCheckboxWebFormComponent dsl={props.dsl} onDslChange={props.onDslChange}/>;
    }
    if (props.dsl.configuration.typeCode === 'keyvalue') {
        return <KeyValueWebFormComponent dsl={props.dsl} onDslChange={props.onDslChange}/>;
    }
    if (props.dsl.configuration.typeCode === 'multiplier') {
        return <MultiplierWebFormComponent dsl={props.dsl} onDslChange={props.onDslChange}/>;
    }
    if (props.dsl.configuration.typeCode === 'fileupload') {
        return <BasicDropzoneUploadWebFormComponent dsl={props.dsl} onDslChange={props.onDslChange}/>
    }
    if (props.dsl.configuration.typeCode === 'imageupload') {
        return <BasicDropzoneImageUploadWebFormComponent dsl={props.dsl} onDslChange={props.onDslChange}/>
    }
    if (props.dsl.configuration.typeCode === 'asyncautocomplete') {
        return <AsyncAutocompleteWebFormComponent dsl={props.dsl} onDslChange={props.onDslChange}/>;
    }
    

    console.error("Unknown form component type - cannot create form component by dsl", props.dsl.configuration.typeCode);
    return <div>ERROR - UNKNOWN ELEMENT</div>;
}

FormComponentByDsl.propTypes = {}

FormComponentByDsl.defaultProps = {}