import React, {useEffect, useState} from 'react';

import {StandardLayout} from "../../layout/StandardLayout";
import UboldSpinner from "../../ubold-components/UboldSpinner";
import MechanicalSettingsService from "./MechanicalSettingsService";
import {Card} from "../../components/Card";
import {NavLink} from "react-router-dom";

function TextInput(props) {
    return <div className={"row" + (props.margin ? " mt-2" : "")}>
        <div className="col-4 text-right mt-1">
            <label>{props.label}</label>
        </div>
        <div className="col-3">
            <input className="form-control" name={props.name} type="text" value={props.value} onChange={props.onChange}/>
        </div>
    </div>
}

export default function MechanicalSettingsPage(props) {

    const [settingsValues, setSettingsValues] = useState({});
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        MechanicalSettingsService.fetchSettings()
            .then(data => {
                setSettingsValues(data);
            })
            .finally(() => {
                setLoading(false);
            })
    }, []);

    function save() {
        setLoading(true);
        MechanicalSettingsService.saveSettings(settingsValues)
            .then(data => {
                //    setSettingsValues(data);
            })
            .finally(() => {
                setLoading(false);
            })
    }

    function onFieldChange(e) {
        setSettingsValues({
            ...settingsValues,
            [e.target.name]: e.target.value
        });
    }

    const fields = [];
    Object.keys(settingsValues).forEach(function (key) {
        fields.push(<TextInput name={key} label={key} margin={"mt-2"} value={settingsValues[key]} onChange={onFieldChange}/>);

    });

    const form = <React.Fragment>{fields}
        <div className="row">
            <div className="col-4 mt-3">
            </div>
            <div className="col-4 mt-3">
                <button className="btn  btn-primary" onClick={save}>Zapisz</button>
            </div>
        </div>
    </React.Fragment>;

    return <Card header={"Ustawienia mechaniczne"}>
            <div className={"alert alert-warning mb-5 p-3"}><i className="fas fa-exclamation-triangle"></i>
                Uwaga - zmiana ustawień mechanicznych może spowodować niepoprawne działanie przykładów Demo,
                ze względu na inne rezultaty obliczeń mechanicznych.
                Dane demonstracyjne są przygotowane dla ustawień: Czas nagłego zatrzymania: 2; Dopuszczalna klasa niewyważenia: 18,9; Stosunek masy AS/SG do Standard: 1,2; Współczynnik bezpieczeństwa sprzęgła: 4
            </div>
            {loading ? <div className={"text-center"}><UboldSpinner/></div> : form}
        </Card>;
}

MechanicalSettingsPage.propTypes = {}

MechanicalSettingsPage.defaultProps = {}