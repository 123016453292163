import React from 'react';
import {cloneJson} from "../util/JsonObjectCloner";
import {MetronicInputText} from "../../metronic-components/MetronicInputText";
import {isNaN} from "lodash";

export default function OptionalDoubleFieldWebFormComponent(props: {
    dsl: {
        value: string
    },
    onDslChange: (dsl: {
        value: string
    }) => object
}) {

    const dsl = props.dsl;

    // TODO: Please note this is bascially a text field! Currently hoping for backend to do the dirty work of "optionalizing", formating, rounding etc. Implement proper numeric handler with rounding on focus lost to configuration's decimal places
    function change(e: React.ChangeEvent<HTMLInputElement>) {
        const newDsl = cloneJson(dsl);
        newDsl.value = e.target.value;
        newDsl.errors = [];
        if (isNaN(newDsl.value)) {
            newDsl.value = null;
        } else {
            newDsl.value = parseFloat(e.target.value);
        }
        console.log(`Optional number/double field requests update to '${newDsl.value}' based on raw value of ${e.target.value}`);
        props.onDslChange(newDsl);
    }

    // "no integer/double" is represented as null, which would trigger warning on text react text field. Convert to empty string for presentation
    if (dsl.value === null) {
        dsl.value = "";
    }
    // @ts-ignore
    return <MetronicInputText type="number" label={dsl.configuration.label} value={dsl.value} errors={dsl.errors} onChange={change} placeholder={dsl.configuration.placeholder}/>

}
