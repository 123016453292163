import React from "react";
import UboldSpinner from "../../ubold-components/UboldSpinner";
import {isSuccessful, useImpellerDatasheet} from "../../business/useImpellerDatasheet";
import {DatasheetComponent} from "./DatasheetComponent";
import {Card} from "../../components/Card";
import {useFetchAllObjs} from "../../platform/hooks/PlatformTypeScriptHooks";
import {useMechanicalConfiguratorCriteriaControls} from "../../mechanicalconfig/useMechanicalConfiguratorCriteriaControls";
import {MechanicalConfigPreviewImage} from "../../mechanicalconfig/MechanicalConfigPreviewImage";
import {createInternationalizationPrefixer} from "../../platform/i18n/i18n";
import {SimulationRequestJson} from "../../business/ApiJsonTypes";
import {useQueryParamPredefinedCriteria} from "../SimulationPage";
import {ImpellerGeometryTable} from "../../components/assembly/ImpellerGeometryTable";

const t = createInternationalizationPrefixer("FanSelectionCriteriaControls");


export function DatasheetPage() {

    const predefinedCriteria: SimulationRequestJson = useQueryParamPredefinedCriteria()!; // Note ! (supposingly, sure to be present here, datasheet always comes with predefined criteria)

    const impellerDiameter = predefinedCriteria.fanFlowSpecification.fanDiameters.impellerDiameter;
    const adjustableImpellerId = predefinedCriteria.fanFlowSpecification.adjustableImpellerId;

    const availableShaftDiameters: number[] | null = useFetchAllObjs<number[]>("/api/mechanical/available-shaft-diameters/for-configuration?impellerDiameter=" + impellerDiameter + "&adjustableImpellerId=" + encodeURI(adjustableImpellerId));
    if (availableShaftDiameters == null) {
        return <div className={"text-center mt-5"}><UboldSpinner/></div>;
    }
    return <DatasheetPageLoaded availableShaftDiameters={availableShaftDiameters} predefinedCriteria={predefinedCriteria}/>;
}

export function DatasheetPageLoaded(props: { availableShaftDiameters: number[], predefinedCriteria: SimulationRequestJson }) {

    const {
        currentlyConfiguredSimulationRequestJson,
        configuratorComponent
    } = useMechanicalConfiguratorCriteriaControls(props.availableShaftDiameters, props.predefinedCriteria);

    const {
        datasheetResponse,
        loading
    } = useImpellerDatasheet(currentlyConfiguredSimulationRequestJson);

    const housingDiameter = currentlyConfiguredSimulationRequestJson.fanFlowSpecification.fanDiameters.impellerDiameter / ((1.0 - 2.0 * (currentlyConfiguredSimulationRequestJson.fanFlowSpecification.fanDiameters.gap / 100.0)));

    const isSilentTypeFanSelected = isSuccessful(datasheetResponse) && (datasheetResponse.result.fanGeneralFanInformation.fanName.includes("(14") || datasheetResponse.result.fanGeneralFanInformation.fanName.includes("(15"));

    const miniGeometryTable = !loading && isSuccessful(datasheetResponse) && datasheetResponse?.result?.mechanicalResultDatasheetParameters != undefined &&
        <div className="d-flex justify-content-center flex-grow-1">
            <div className={"w-50"}>
                <ImpellerGeometryTable geometryReport={datasheetResponse.result.mechanicalResultDatasheetParameters.geometryReport}
                                       variant={"mini"}/>
            </div>
        </div>;


    const h3RangeComponent = <><h2 className={"pt-2 text-center"}>
        {isSuccessful(datasheetResponse) && datasheetResponse.h3RangeEstimationJson?.h3RangeEstimation && currentlyConfiguredSimulationRequestJson.mechanicalPreferences.preferredSupportAndClutchDiscSideCode == "SUPPORTS_AND_CLUTCH_SAME_SIDE" && <>
            H3 {datasheetResponse.h3RangeEstimationJson?.h3RangeEstimation?.sameSideGuess!.min} mm- {datasheetResponse.h3RangeEstimationJson?.h3RangeEstimation?.sameSideGuess!.max} mm
        </>}
        {isSuccessful(datasheetResponse) && datasheetResponse.h3RangeEstimationJson?.h3RangeEstimation && currentlyConfiguredSimulationRequestJson.mechanicalPreferences.preferredSupportAndClutchDiscSideCode == "SUPPORTS_AND_CLUTCH_OPPOSITE_SIDES" && <>
            H3 {datasheetResponse.h3RangeEstimationJson?.h3RangeEstimation?.oppositeRangeGuess!.min} mm - {datasheetResponse.h3RangeEstimationJson?.h3RangeEstimation?.oppositeRangeGuess!.max} mm
        </>}
        {isSuccessful(datasheetResponse) && !datasheetResponse.h3RangeEstimationJson && <div className={"text-center fs-5 text-gray-400 fw-light"}>{t("Estymata możliwych wysokości wirnika jest niedostępna")}</div>}
    </h2>
    <h3 className={"pt-2 text-secondary text-center"}>
        {isSuccessful(datasheetResponse) && datasheetResponse.h3RangeEstimationJson?.h3RangeEstimation && currentlyConfiguredSimulationRequestJson.mechanicalPreferences.preferredSupportAndClutchDiscSideCode == "SUPPORTS_AND_CLUTCH_SAME_SIDE" && <>
            H3 {datasheetResponse.h3RangeEstimationJson?.h3RangeEstimation?.oppositeRangeGuess!.min} mm- {datasheetResponse.h3RangeEstimationJson?.h3RangeEstimation?.oppositeRangeGuess!.max} mm
        </>}
        {isSuccessful(datasheetResponse) && datasheetResponse.h3RangeEstimationJson?.h3RangeEstimation && currentlyConfiguredSimulationRequestJson.mechanicalPreferences.preferredSupportAndClutchDiscSideCode == "SUPPORTS_AND_CLUTCH_OPPOSITE_SIDES" && <>
            H3 {datasheetResponse.h3RangeEstimationJson?.h3RangeEstimation?.sameSideGuess!.min} mm - {datasheetResponse.h3RangeEstimationJson?.h3RangeEstimation?.sameSideGuess!.max} mm
        </>}
    </h3></>;

    return <>
        <div className={"row"}>
            <div className={"col-12 mb-3"}>
                <Card header={"Konfigurator mechaniczny"}>
                    <div className="d-flex flex-row">
                        {configuratorComponent}
                        <div className={"flex-grow-1"}>

                            {currentlyConfiguredSimulationRequestJson.mechanicalPreferences.fanMountingOrientationCode === "VERTICAL" && <div className={"d-flex flex-row"}>
                                <MechanicalConfigPreviewImage
                                    orientantion={"vertical"}
                                    clutchOnInlet={currentlyConfiguredSimulationRequestJson.mechanicalPreferences.airflowRelativeClutchMountingSideCode === "CLUTCH_ON_INLET"}
                                    silent={isSilentTypeFanSelected}
                                    sameSide={currentlyConfiguredSimulationRequestJson.mechanicalPreferences.preferredSupportAndClutchDiscSideCode === "SUPPORTS_AND_CLUTCH_SAME_SIDE"}/>
                                <div>
                                    <img className={"mw-150px"} src={isSilentTypeFanSelected ? "/img/dimensionpreview/x_y_silent_preview_vertical.png" : "/img/dimensionpreview/x_y_normal_preview_vertical.png"}/>
                                </div>
                                <div className={"flex-grow-1"}>
                                    {miniGeometryTable}
                                    {h3RangeComponent}
                                </div>
                            </div>}

                            {currentlyConfiguredSimulationRequestJson.mechanicalPreferences.fanMountingOrientationCode === "HORIZONTAL" && <>
                                <MechanicalConfigPreviewImage
                                    orientantion={"horizontal"}
                                    clutchOnInlet={currentlyConfiguredSimulationRequestJson.mechanicalPreferences.airflowRelativeClutchMountingSideCode === "CLUTCH_ON_INLET"}
                                    silent={isSilentTypeFanSelected}
                                    sameSide={currentlyConfiguredSimulationRequestJson.mechanicalPreferences.preferredSupportAndClutchDiscSideCode === "SUPPORTS_AND_CLUTCH_SAME_SIDE"}/>
                                <div className={"d-flex"}>
                                    <div className={"w-120px"}>
                                        <img className={"mh-300px"} src={isSilentTypeFanSelected ? "/img/dimensionpreview/x_y_silent_preview.png" : "/img/dimensionpreview/x_y_normal_preview_vertical.png"}/>
                                    </div>
                                    <div className={"flex-grow-1"}>
                                        {miniGeometryTable}
                                        {h3RangeComponent}
                                    </div>
                                </div>
                            </>}


                        </div>

                    </div>
                </Card>
            </div>
        </div>

        {loading && <Card>
        <div className={"p-5 text-center"}>
                <UboldSpinner/>
            </div>
        </Card>}
        {!loading && isSuccessful(datasheetResponse) && datasheetResponse.result &&
            <DatasheetComponent
                datasheet={datasheetResponse.result}
                memoizedDatasheetId={datasheetResponse.memoizedId}
                blueprintId={datasheetResponse.blueprintId ?? undefined}
                paramsForInteractiveLocatedSplChart={{
                    housingDiameterMilimeter: housingDiameter,
                    operation: currentlyConfiguredSimulationRequestJson!.installationSpecification.operationalSetupCode as "INDUCED_DRAFT" | "FORCED_DRAFT",
                    pwlTot: parseFloat(datasheetResponse.result.workingPointPwlTot.value) // bit of a hack to reparse

                }}
            />}
    </>;


}
