import React, {useContext, useState, useEffect} from 'react';
import {useParams} from "react-router";
import {StandardLayout} from "../../layout/StandardLayout";
import {useObjectFromUrlResourceOrNull} from "../../platform/hooks/PlatformHooks";
import {NavLink} from "react-router-dom";
import {Card} from "../../components/Card";

export function MechanicalDesignResultPage(props) {

    const {id} = useParams();

    const foundDesign = useObjectFromUrlResourceOrNull("/api/mechanical/mechanical-design-result?identifier="+id);

    let lp =1;
    const designPresenter = !foundDesign ? null : <div>
        <h3>Oznaczenie wirnika: {foundDesign.fanName}</h3>
        <table className="table table-sm table-striped">
            <thead>
            <tr>
                <th className={"text-center"}>Sztuk</th>
                <th className={"text-center"}>Nazwa</th>
                <th className={"text-center"}>l.p.</th>
                <th className={"text-center"}>Materiał</th>
                <th className={"text-center"}>Norma</th>
                <th className={"text-center"}>Masa jedn. [kg]</th>
                <th className={"text-center"}>Masa całkowita [kg]</th>
            </tr>
            </thead>
            <tbody>
            {foundDesign.designMaterials.map(m => <tr>
                <td className="text-center" style={{width: "10%"}}>{m.pieceCount}</td>
                <td className="text-center" style={{width: "20%"}}>{m.name}</td>
                <td className="text-center" style={{width: "10%"}}>{1+foundDesign.designMaterials.length-lp++}</td>
                <td className="text-center" style={{width: "20%"}}>{m.material}</td>
                <td className="text-center" style={{width: "20%"}}>{m.nameOfStandard}</td>
                <td className="text-center" style={{width: "10%"}}>{parseFloat(m.pieceMass.toFixed(3)).toLocaleString()}</td>
                <td className="text-center" style={{width: "10%"}}>{parseFloat((m.pieceMass * m.pieceCount).toFixed(3)).toLocaleString()}</td>
            </tr>)}
            </tbody>
        </table>
        <a className={"mb-3 btn btn-secondary"} href={"/api/mechanical/mechanical-design-result/xlsx?identifier="+ encodeURIComponent(foundDesign.identifier)}>Pobierz XLSX</a>
        <h5 className="card-title">Log obliczeniowy</h5>
        <div style={{fontFamily:'Courier new', fontSize:'12px'}}>
            {foundDesign.creationLogsMultiline.split('\n').map(e=><div>{e}</div>)}
        </div>
    </div>;

    return <Card>
        <div className="card-body ">
        <div className={"d-flex flex-row-reverse"}>
            <NavLink to={"/browse-mechanical-designs"} className={"btn btn-sm btn-secondary"}>Wróc do listy doborów</NavLink>
        </div>
        {designPresenter}
        </div>

    </Card>;
}
