import AuthTokenStorageService from "./AuthTokenStorageService";
import AjaxService from "../ajax/AjaxService";

const generalProcessingError = {
    processingEvents: [
        {
            eventType: "generalError",
            message: "Sorry, internal error ocurred"
        }
    ]
};

const AUTH_STATUS_PENDING = {
    authenticated: false,
    authenticationStatusKnown: false,
    accountName: undefined,
    accountId: undefined
};

const AUTH_STATUS_FOR_UNAUTHENTICATED = {
    authenticated: false,
    authenticationStatusKnown: true,
    accountName: undefined,
    accountId: undefined
};
let authStatus = AUTH_STATUS_FOR_UNAUTHENTICATED;
const authStatusChangeListeners = [];

export default class AuthenticationService { // TECHDEBT service naming is not a best one for stateful

    /**
     * @typedef {Object} authStatus
     * @property {authenticated} true/false if authenticated
     * @property {authenticationStatusKnown} true/false depending if auth state is known (verified). Good to handle intermidient state
     * @property {accountId} identifier of the account
     * @property {accountName} name of the account owner
     */

    /**
     * @returns {authStatus}
     */
    static getCurrentAuthStatus() {
        return authStatus;
    }

    /**
     * @param listenerFunc
     */
    static addAuthStatusChangeListener(listenerFunc) {
        authStatusChangeListeners.push(listenerFunc);
    }

    static deAuthenticate() {
        AuthTokenStorageService.removeCurrentToken();
        AuthenticationService._updateCurrentAuthStatus(AUTH_STATUS_FOR_UNAUTHENTICATED);
    }

    /**
     * Resolves only if login was successful (with current auth status, which contains user details)
     * Rejects with human friendly error message if failed
     * @returns {Promise<{ token: "...." }>}
     */
    static signIn(email, password) {
        return new Promise(function (resolve, reject) {
            AjaxService.postJson("/api/auth/login", {
                email: email,
                password: password
            }, () => {
                // No-op unathorized interceptor. 401 is exceptionally natural here
            }).then(response => {
                if (!response.ok) {
                    console.log("Response not 'ok' (check HTTP code)", response.ok)
                    if (response.json.message) {
                        reject(response.json.message);
                    } else {
                        reject("Incorrect credentials");
                    }
                    return;
                } else {
                    // response.ok is enough for login!
                    AuthenticationService.setupAuthenticationWithLoginResponse(response.json);
                    return;
                }
            }).catch(error => {
                console.log(error); // TECHDEBT
                reject(generalProcessingError.processingEvents[0].message);
            })
        });
    }

    static _updateCurrentAuthStatus(newAuthStatus) {
        console.log("Updating current auth status", newAuthStatus);
        authStatus = newAuthStatus;
        if (authStatusChangeListeners.length > 0) {
            console.log("Notifying number of listeners of auth change", authStatusChangeListeners.length);
            for (const l of authStatusChangeListeners) {
                l(newAuthStatus);
            }
        }
    }

    /**
     * @returns {Promise<any>} that resolves with received detailed auth status
     */
    static _refreshCurrentAuthStatusBasedOnStoredTokenState() {
        return new Promise(function (resolve, reject) {
            const token = AuthTokenStorageService.getCurrentToken();
            if (token) {
                AuthenticationService._updateCurrentAuthStatus(AUTH_STATUS_PENDING)
            } else {
                AuthenticationService._updateCurrentAuthStatus(AUTH_STATUS_FOR_UNAUTHENTICATED);
                return;
            }

            AuthenticationService._loadCurrentAuthStatus()
                .then(receivedAuthStatus => {
                    const newAuthStatus = {...receivedAuthStatus, authenticationStatusKnown: true};
                    AuthenticationService._updateCurrentAuthStatus(newAuthStatus);
                    resolve(newAuthStatus);
                    if (token && !receivedAuthStatus.authenticated) {
                        // Invalid token is currently stored - clear it
                        AuthTokenStorageService.removeCurrentToken();
                    }
                })
                .catch(error => {
                    console.error(error);
                    AuthenticationService.deAuthenticate();
                    // TECHDEBT throw global error that authentication data cannot be loaded
                    reject(error);
                });
        });
    }

    /**
     * @returns {Promise<any>} promise that resolves if authentication details are fetched
     */
    static setupAuthenticationWithLoginResponse(loginResponse) {
        AuthTokenStorageService.setCurrentToken(loginResponse.accessToken);
        AuthenticationService._updateCurrentAuthStatus({...loginResponse.authStatus, authenticationStatusKnown: true});
    }

    /**
     * @returns {Promise<authStatus>}
     */
    static _loadCurrentAuthStatus() {
        return new Promise(function (resolve, reject) {
            const token = AuthTokenStorageService.getCurrentToken();
            if (!token) {
                resolve(AUTH_STATUS_FOR_UNAUTHENTICATED);
                return;
            }
            AjaxService.getJson("/api/auth/current-authentication")
                .then(response => {
                    if (!response.ok) {
                        resolve(AUTH_STATUS_FOR_UNAUTHENTICATED);
                        return;
                    }
                    resolve(response.json);
                });
        });
    }

}

// Static initialiation
AuthenticationService._refreshCurrentAuthStatusBasedOnStoredTokenState();