import AjaxService from "../ajax/AjaxService";

export default class WorkspaceService {

    static async getWorkspaceData() {
        const resp = await AjaxService.getJson("/api/workspace");
        return resp.json;
    }

}
