import {useCurrentUserPrivileges} from "../platform/hooks/PlatformHooks";
import {ReactNode} from "react";


export function AdminOnly(props: { children: JSX.Element }) : JSX.Element {
    const privileges: string[] | "loading" = useCurrentUserPrivileges();

    if ( privileges === "loading") {
        return <></>;
    }
    if (privileges.includes("AUTHORIZATION_MANAGEMENT")) {
        return props.children;
    } else {
        return <span>🔒</span>;
    }
}