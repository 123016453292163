import React from 'react';
import {D3ChartAxis} from "../../platform/d3chart/D3ChartAxis";
import {D3ChartGridLines} from "../../platform/d3chart/D3ChartGridLines";
import {D3ChartLine} from "../../platform/d3chart/D3ChartLine";
import {D3ChartScaleManager} from "../../platform/d3chart/D3ChartScaleManager";
import {D3ChartRenderable} from "../../platform/d3chart/D3Chart";
import {ResistanceCurveD3ChartRenderable} from "../../charting/ResistanceCurveD3ChartRenderable";
import {D3ChartComponent} from "../../platform/d3chart/D3ChartComponent";
import {FiCurve} from "../../business/core";
import {MouseGuidesD3ChartRenderable} from "../../charting/MouseGuidesD3ChartRenderable";
import {HoverExplainerD3ChartRenderable} from "charting/HoverExplainerD3ChartRenderable";

function fiCurveToPoints(curve: FiCurve) {
    const points: { fi: number, value: number }[] = [];
    for (const idx in curve.discreteFis) {
        points.push({fi: curve.discreteFis[idx], value: curve.valuesForDiscreteFis[idx]});
    }
    return points;
}

export function SimpleMulticurveChart(props: { curves: FiCurve[], width?: number, xLabel: string, yLabel: string }) {

    const chartSize = {width: props.width || 1200, height: 500};
    const chartMargins = {left: 40, top: 0, right: 5, bottom: 40};

    const fiAxis = new D3ChartAxis([0.0, -1.0], [0.0, chartSize.width], "bottom", 10, "d3-axis-nodomainline", 0,0, props.xLabel);
    const yAxis = new D3ChartAxis([-1.0, 0.0], [0.0, chartSize.height - chartMargins.top - chartMargins.bottom], "left", 10, "d3-axis-nodomainline",0,0,props.yLabel);

    const verticalGridLines = new D3ChartGridLines(fiAxis.scale, fiAxis, "vertical", "d3-stroke-1 d3-opacity-05 d3-stroke-black");
    const horizontalGridLines = new D3ChartGridLines(yAxis.scale, yAxis, "horizontal", "d3-stroke-1 d3-opacity-05 d3-stroke-black");
    const horizontalGridLinesStrong = new D3ChartGridLines(yAxis.scale, yAxis, "horizontal", "d3-stroke-1 d3-opacity-10 d3-stroke-black");

    const linesToDraw = props.curves.filter(
        (l, idx) => idx === 0 || idx == props.curves.length - 1 || idx % 4 === 0
    ).map(curve => {
        const line = new D3ChartLine<{ fi: number, value: number }>(
            fiAxis.scale,
            yAxis.scale,
            fiCurveToPoints(curve),
            chPoint => chPoint.fi,
            chPoint => chPoint.value,
            "d3-stroke-0_3 d3-stroke-black d3-opacity-50 ");
        if (curve.pointDescriber !== undefined) {
            line.setDescriber(p => curve.pointDescriber!(p));
        }
        return line;
    });

    const scaleManager = new D3ChartScaleManager([...linesToDraw], 1.2);


    const renderables: D3ChartRenderable[] = [
        scaleManager, // Scale manager comes first, to recalculate axes scales!
        fiAxis,
        yAxis,
        verticalGridLines,
        horizontalGridLines,
        horizontalGridLinesStrong,
        new ResistanceCurveD3ChartRenderable(fiAxis.scale, yAxis.scale),
        new MouseGuidesD3ChartRenderable(fiAxis.scale, yAxis.scale),
        ...linesToDraw,
        new HoverExplainerD3ChartRenderable(linesToDraw)
    ];

    return <D3ChartComponent width={chartSize.width} height={chartSize.height} chartRenderables={renderables}
                             margins={chartMargins}/>
}
