import {GeometryReportJson} from "../../pages/mechanical/ImpellerAssemblyService";
import React from "react";
import {variance} from "d3";


export function ImpellerGeometryTable(props: { geometryReport: GeometryReportJson, variant: "full-table" | "mini" }) {

    const angleRow: React.ReactNode[] = [];
    const x1Row: React.ReactNode[] = [];
    const x2Row: React.ReactNode[] = [];
    const y1Row: React.ReactNode[] = [];
    const y2Row: React.ReactNode[] = [];

    angleRow.push(<strong>α / β</strong>);
    x1Row.push(<td><strong>x1</strong></td>);
    x2Row.push(<td><strong>x2</strong></td>);
    y1Row.push(<td><strong>y1</strong></td>);
    y2Row.push(<td><strong>y2</strong></td>);

    let selectedAngleOutputed = false;
    for (const measuredAngleBoundingBox of props.geometryReport.measuredAngleBoundingBoxes) {
        if ( props.geometryReport.selectedAngleBoundingBox != null) {
            if (props.geometryReport.selectedAngleBoundingBox.bladeAngle < measuredAngleBoundingBox.bladeAngle && !selectedAngleOutputed) {
                selectedAngleOutputed = true;
                angleRow.push(<strong>{props.geometryReport.selectedAngleBoundingBox.bladeAngle.toFixed(1)}° / {props.geometryReport.betaAngle.toFixed(1)}°</strong>);
                x1Row.push(<td className={props.variant === "mini" ? "" :"bg-secondary"}>{props.geometryReport.selectedAngleBoundingBox.x1.toFixed(0)} <span className="text-muted">mm</span></td>);
                x2Row.push(<td className={props.variant === "mini" ? "" :"bg-secondary"}>{props.geometryReport.selectedAngleBoundingBox.x2.toFixed(0)} <span className="text-muted">mm</span></td>);
                y1Row.push(<td className={props.variant === "mini" ? "" :"bg-secondary"}>{props.geometryReport.selectedAngleBoundingBox.y1.toFixed(0)} <span className="text-muted">mm</span></td>);
                y2Row.push(<td className={props.variant === "mini" ? "" :"bg-secondary"}>{props.geometryReport.selectedAngleBoundingBox.y2.toFixed(0)} <span className="text-muted">mm</span></td>);
            }
        }
        if (props.variant !== "mini" && (props.geometryReport.selectedAngleBoundingBox == null || Math.abs(measuredAngleBoundingBox.bladeAngle - props.geometryReport.selectedAngleBoundingBox.bladeAngle) > 0.01)) {
            angleRow.push(<strong>{measuredAngleBoundingBox.bladeAngle.toFixed(1)}°</strong>)
            x1Row.push(<td>{measuredAngleBoundingBox.x1.toFixed(0)} <span className="text-muted">mm</span></td>);
            x2Row.push(<td>{measuredAngleBoundingBox.x2.toFixed(0)} <span className="text-muted">mm</span></td>);
            y1Row.push(<td>{measuredAngleBoundingBox.y1.toFixed(0)} <span className="text-muted">mm</span></td>);
            y2Row.push(<td>{measuredAngleBoundingBox.y2.toFixed(0)} <span className="text-muted">mm</span></td>);
        }
    }

    return <>

        <div className="table-responsive">
            <table className="table align-middle gs-1 gy-1">
                <thead>
                <tr>
                    {angleRow.map(elem => <th>{elem}</th>)}
                </tr>
                </thead>
                <tbody>
                <tr>{x1Row.map(elem => elem)}</tr>
                <tr>{x2Row.map(elem => elem)}</tr>
                <tr>{y1Row.map(elem => elem)}</tr>
                <tr>{y2Row.map(elem => elem)}</tr>
                </tbody>
            </table>
        </div>
    </>;
}