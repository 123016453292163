import React from "react";

export function OldChanges() {
    return <>
        <h2>13.7.0</h2>
        <ul>
            <li>Naprawiono błąd resetowania całej strony na złożeniu wirnika, gdy podano zarówno RPM jak i Uz tracąc przez to wartości w formularzu</li>
            <li>Z ekranu symulacji usunięto parametr "Sprawność statyczna w punkcie pracy (bezwymiarowa)" ponieważ był zbyt jednoznaczny z "Sprawność statyczna w punkcie pracy" (który pozostawiono). W logach obliczeniowych nadal może się pojawić z natury, ponieważ jest parametrem przejściowym do kolejnych obliczeń.</li>
            <li>Przywrócono niepoprawnie wyliczane wartości API Q i API P jako kolumnę na ekranie preselekcji (nadal pozostają wyliczane niepoprawnie)</li>
            <li>Wyprowadzono wartość promienia kołnierza sprzęgła w szczegółąch złożenia na liście parametrów sprzęgła</li>
            <li>Zmienino wzór liczenia momentu bezwładności piasty na nowy: "Moment bezwładności piasty = 0.5 * ( masaPiasty - masaSprzegla )* Math.pow((srednicaTarczy/1000.0/2.0),2.0) + 0.5 * masaSprzegla * promienKolnierzaSprzegla"</li>
            <li>Przywrócono na kartę WWW i PDF blok parametrów mechanicznych, który został zastąpiony przez nowy blok Akustyka powodując jego nieobecność. (blok ten jest widoczny tylko przy uzyskaniu poprawnego rozwiązania mechanicznego)</li>
            <li>Wprowadzono jednostkę momentu bezwładności na karcie wirnika: "kg*m²" (zamiast "???")</li>
            <li>Dodano obliczanie parametru "Siła resztkowa od niewyważenia G6,3" (siłą od niewyważenia wirnika / 3.0)</li>
            <li>Wyprowadzono wartość nowego parametru "Siła resztkowa od niewyważenia G6,3" na kartę wentylatora, do sekcji mechanicznej</li>
            <li>Zoptymalizowano zapisywanie brakujących tłumaczeń, tak by zmniejszyć szansę przegapienia brakującego tłumaczenia podczas gdy w systemie brakuje wielu tłumaczeń</li>
        </ul>

        <h2>13.6.0</h2>
        <ul>
            <li>Naprawiono problem powdójnego korygowania ciśnienia w preselekcji</li>
            <li>Zamiast opcjonalnego bloku na karcie wentylatora "Korekty hałasu" oraz "hałasów w punktach" zaraz pod wykresami oktaw, wyprowadzono dedykowaną sekcję Akustyka</li>
            <li>Do dedykowanej sekcji Akustyka karty wentylatora dodano PWL i PWLopt</li>
        </ul>

        <h2>13.5.1</h2>
        <ul>
            <li>Naprawiono bląd niemożności wyświetlenie karty wentylatora w styuacji kiedy nie udało się złożyć mechaniki (objaw "Error data sending to server")</li>
        </ul>

        <h2>13.5.0</h2>
        <ul>
            <li>Obliczanie parametry H3 sprzęgła jest od teraz opcjonalne - nie występuje, gdy w manufakturyzacji ustawiono "Wstaw h2,h3 do nazwy (0/1)" na 0</li>
            <li>Przy braku wartości H3 w zestawieniu szczegółow parametrów komponentów złożenia mechanicznego, dla H3 będzie napisane "Brak" zamiast wartości</li>
            <li>Przy braku wartości H3 w pliku eksportu dla DriveWOrks, dla H3 będzie napisane "Brak" zamiast wartości</li>
            <li>Dodano możliwość pobrania plikó DriveWorks i BOM z karty wentylatora WWW po doborze</li>
            <li>Przywrócono naglówki na sekcjach parametrów na karcie wentylatora WWW, które zniknęły w jednej z niedawnych wersji.</li>
            <li>W obliczaniu "Siła odśrodkowa od łopaty" wyprowadzono do loga obliczeń uzyskany wynik (były tylko wartości podstawione we wzorze, bez końcowego)</li>
            <li>Zmieniono nazwę parametru przy obliczeniach mechanicznych: "Promień położenia środka masy" na "Zgrubny promień położenia środka masy"</li>
            <li>Stare obliczanie siły odśrodkowej od łopaty (estymujące położenie środka ciężkości, a nie używające koluny XLS) nazwano "Zgrubna siła odśrodkowa od łopaty"</li>
            <li>Ujednolicono obliczanie "Siła odśrodkowa od łopaty" do wzoru jak dawniej "CentrifugalForce_Fo" czyli z użyciem wartości z XLS "Położenie środka ciężkości [-]"</li>
            <li>"Naprężenie zginające w tulei sprzęgła od urwania łopaty" obliczane jest teraz z dokładnej (nie-zgrubnej) siły odśrodkowej z racji ujednolicenia</li>
            <li>"Moment bezwładności łopat" obliczane jest teraz z dokładnego (nie-zgrubnego) promienia położenia środka masy łopaty</li>
            <li>Usunięto człon *pierwiastek(2) z wzoru "Moment bezwładności łopat"</li>
            <li>Dodano wyliczanie i wypis parametru "Promień położenia środka masy" (jest to parameter dokładny, nie zgrubny)</li>
            <li>Do logu obliczeniowego momentu bezwładności łopat dodano porównawczy wzór wyliczający "Moment bezwładności łopat OPTISEL 1"</li>
        </ul>

        <h2>13.4.0</h2>
        <ul>
            <li>Zamieniono kolejnością wartośći PWL w kolumnie preselekcji, oraz wartości sprawności w kolumnie preselekcji</li>
            <li>Poprawiono wartość nanoszoną na obrazie wymiarowym dotyczącym sprzęgła - wymiar na długości czopu wału silnika jest teraz brany z zadanej długości wału (był: prawdopodobnie średnicą wału)</li>
            <li>Wprowadzono warunek, że przy użyciu manufakturyzacji sprzęgła skonfigurowanej "Wstaw h2,h3 do nazwy (0/1)" na 0, dla liczenia wartości parametru sprzęgła h2 będzie po prostu użyta wartość "Maksymalna długość wałka napędowego [mm]" z XLS</li>
            <li>Na kartę wentylatora (WWW i PDF) wyprowadzono parametr: "Moc użyteczna w punkcie pracy"</li>
            <li>Z nagłówków typu "Moc akustyczna z podziałem na oktawy" usunięto fragment tekstu "z podziałem na oktawy"</li>
            <li>Zwiększono lewy margines wykresów oktaw akustyki PDF by nie przcyinać wartości większych niż 100 na osi pionowej</li>
            <li>Zmniejszono zagęszczenie lini pomocniczych na wykresach oktawowych akustyki w PDF</li>
            <li>Poprawiono zaokrąglanie wartości hałasu dla oktawy na wykresie PDF do jednego miejsca po przecinku (było 0)</li>
        </ul>


        {/*
        BONUSES (meeting about performance was here)
        DAY 1
        - problems spliting large teams card "6. Test doboru na bazie w14025 [wątpliwości dot. długości śruby zabezp.] 20240219"
        - Failed solution for "Dodać nowe wyliczanie h2 dla przypadków bez wstawiania h2,h3 do nazwy.", because it breaks h3 parameter
        - Siwthcing order of parameters in preselection
        */}

        <h2>13.3.0</h2>
        <ul>
            <li>Wprowadzono możliwość zawarcia parametry h3 w nazwie sprzęgła</li>
            <li>Wprowadzono różnicowanie obecności (lub nie) końcówki -h2-h3 w nazwie sprzęgła - na podstawie nowej kolumny w XLS</li>
            <li>Dodano nową kolumnę w XLS manufakturyzacji sprzęgieł: "Wstaw h2,h3 do nazwy (0/1)". Wartości powinny być "1", gdy chcemy na końcu nazwy -h2-h3 lub "0", gdy nie. Kolumna została dodana do XLS demonstracyjnego.</li>
            <li>Zmieniono sposób liczenia parametru h2 sprzęgła z zaokrąglania 1/3 dł wału do najbliższego 5, na zaokrąglanie "w góry" do 5.</li>
            <li>Zmieniono w dwóch miejscach nomenklaturę "Parametr H2" (sprzęgła) na "Parametr h2"</li>
            <li>Zmieniono sposób liczenia parametru h3 sprzęgła na: dł.wału - 2*h2 (wcześniej było: 1/3 dł.wałka zaokrąglona do najbliższego 5)</li>
            <li>Zmieniono sposób wyszukiwania średnicy podtrzymującej H4 - Zamiast tylko po średnicy wałka, wyszukiwanie następuje po średnicy wałka i typie sprzęgła</li>
            <li>Wymieniono typoszereg do szukanai H4 na nowy - oparty o typ sprzęgła i średnicę wałka, na podstawie XLS z lutego 2024 (na razie przyjęto brak doboru/wartości dla D1=60mm i S50A/S50), ponieważ program nie obsługuje sytuacji "sprzęgło obsługuje rozmiar średnicy D1, brak wybrania")</li>
            <li>Rozszerzono typoszereg H4 o dane dla sprzęgieł demo (DEMOS50A, DEMOS100)</li>
        </ul>

        <h2>13.2.0</h2>
        <ul>
            <li>Dodano obliczanie parametrów akustycznych "P1 – 1m 45˚od płaszczyzny wlotu", "P2 – 1m od wlotu w jego płaszczyźnie", "P3 – 1m od obudowy FRP", "P4 – 1 m pod płaszczyzną wlotu" dla tloczenia (symulacja) na tłoczeniu</li>
            <li>Dodano obliczanie parametrów akustycznych "SPL P1 – 1m 45˚od płaszczyzny wylotu", "SPL P2 – 1m od wylotu w jego płaszczyźnie", "SPL P3 – 1m od pow. bocznej dyfuzora" (symulacja) na ssaniu</li>
            <li>Dodano kilkadziesiąt brakujących tłumaczeń (tylko j. polski, by uniknąć wyświetlania jako @@@tekst@@@)</li>
            <li>Poprawiono wydajność programu, gdy jest za bardzo obciążony zbyt dużą ilością brakujących tłumaczeń</li>
            <li>Dodano nowe parametry SPL w punktach do karty wentylatora WWW</li>
            <li>Dodano nowe parametry SPL w punktach do karty wentylatora PDF</li>
            <li>Osadzono interaktywny wykres z Optisel 1 obliczający hałas w punkcie - na karcie wentylatora PDF</li>

        </ul>

        <h2>13.1.0</h2>
        <ul>
            <li>Przywrócono brak działania czynników korygujących dla wartości PWL w preselecki (korekty były tam wyłączone, gdyż to kryteria są korygowane, a nie krzywe - odkonano podziału na ignorowanie tylko przy ciśnienia, ale uwzględnianie przy hałasie)</li>
            <li>Dodano obliczanie całkowitej sprawności wentylatora - wartość opublikowano na preselekcji oraz na karcie wentylatora</li>
            <li>Wprowadzono typoszereg śrub DIN 912 (oprócz M1,5 i M2,5 ze względu na brak wsparcia technicznego) i wymuszono te śruby jako TP dla czopów 50 i 99 (99 pod dane demo)</li>
            <li>Usunięto nagłówek grupujący "Oktawowy rozkład mocy akustycznej" z widoku web karty wentylatora, by zliżyć wygląd do PDF</li>
            <li>Naprawiono defekt w rejestracji brakujących tłumaczeń (wykryte mogły nie pojawiać się na stronie "Tłumaczenia")</li>
            <li>Naprawiono tłumaczenie nazw parametrów na stronie Symulacja</li>
            <li>Dodano kilkadziesiąt brakujących reguł tłumaczeń na stronie Symulacja (strona jest administracyjna, ale już od dawna używała mechanizmu tlumaczeń)</li>
            <li>Naprawiono wyświetlanie wartości parametrów na ekranie symulacji, których wartość była tekstowa (tłumaczenia - przed wartością mozna było zobacyzć "@ik:" czego nie powinno być widać)</li>
            <li>Zmieniono precyzję zadawania kryterium ciśnienia statycznego o jedno więcej miejsce po przecinku (czyli do .1 dla Pascal, ale odpowiednio też o 1 cyfrę precyzyjniej dla innych jednostek)</li>
            <li>Naprawiono defekt, kiedy lista wyników doboru mogła być pusta (ale bez napisu "brak wyników"), kiedy rezultatem doboru był tylko 1 wiersz.</li>
            <li>W kilku miejscach zaokrąglono prędkość obrotową do 1 miejsca po przecinku (zamiast 0 miejsc)</li>
            <li>Przemianowano parametry sprawności tam gdzie nie było słowo "statyczna", tak aby to słowo się pojawiało (dla jasności, że chodzi o sprawność statyczną)</li>
            <li>Tymczasowo ukryto zapasy API w tabeli preselekcji ze względu na zlożoność obliczeń, gdyby je naprawiono</li>
        </ul>


        <h2>13.0.0</h2>
        <ul>
            <li>Kompleksowe pokrycie aplikacji przełączalnymi językami w obrębie komponentów głównie przeznaczonych dla użytkowników końcowych</li>
            <li>Usunięto wszystkie zdefiniowane tłumaczenia (dokonano pełnego resetu mechanizmu tłumaczeń), tak aby pozbyć się wielu nieużywanych kodów tłumaczeń</li>
            <li>Dodano populowanie pozostałych pól formularza przy ustawianiu tłumaczeń, dzięki czemu nie trzeba tłumaczeń dla wszystkich języków podawać od nowa</li>
            <li>Na nowo zdefiniowano wszystkie tłumaczenia PL i wiele EN</li>
            <li>Usunięto duplikacje kodów tłumaczeń parametrów z "param-" na początku i bez</li>
            <li>Na stronie tłumaczeń posortowano tłumaczenia wpierw wg ilośći braków (brak w większej ilości języków - wyżej)</li>
            <li>Wprowadzono tłumaczenie fraz serwerowych - takich jak elementy karty wentylatora, typy przeszkód, kształtów wlotu itp. (widoczne w tłumaczeniach z początkiem @ik:)</li>
            <li>Naprawiono niemożność tłumaczenia pewnych fraz, które ładowały się przed załadowaniem ustalonych tłumaczeń (typy aplikacji, atrybuty filtrowania w preseleckji)</li>
            <li>Wprowadzono mechanizm detekcji i zapisywania braków tłumaczeń po stronie serwerowej</li>
            <li>Wprowadzono mechanizm tłumaczenia wartości parametrów, a nie tylko ich nazw (np. gdy w tabeli pojawia się nazwa przeszkody czy typu aplikacji)</li>
            <li>Naprawiono defekt niemożności ustalania tłumaczeń dla kodów tłumaczeń zawierających % np. "DimensionEditor:Szczelina (% średnicy na stronę)"</li>
            <li>Naprawiono przełącznik języków (odświeża stronę przy przeładowaniu w celu ponownego załadowania nowszych tłumaczń)</li>
            <li>Dodano automatyczne przeładowanie strony po zmianie reguły tłumaczenia, tak aby przeglądarka ponownie załadowała tłumaczenia.</li>
        </ul>


        <h2>12.2.0</h2>
        <ul>
            <li>Zmieniono oznaczenia kolumn w preselekcji z długich nazw własności na symbole</li>
            <li>Wprowadzono wyświetlanie na preselekcji wartości prędkości końca łopaty Uz</li>
            <li>Wprowadzono możliwośc filtrowania po maksymalnym Uz w preselekcji</li>
            <li>Poprawiono defekt, polegający na tym, że na ekranie symulacji, przekazywano do obliczeń mechanicznych długość wałka zawsze równą wprowadzonej średnicy wałka, zamiast zadanej długości wałka, co wpływało na zły dobór śruby zabezpieczającej</li>
        </ul>

        <h2>12.1.0</h2>
        <ul>
            <li>Ujednolicono tytuły wykresów hałasu - "Moc akustyczna dB(A)" oraz "Moc akustyczna dB(Lin)"</li>
            <li>Na webowej stronie prezentacji karty oraz stronie prezentacji symulacji zmieniono tytuł grupy dla wykresów hałasu: "Oktawowy rozkład mocy akustycznej"</li>
            <li>Zmieniono opis słupka "Całk." w podziale hałasu na "∑"</li>
            <li>Na ekranie symulacji zmieniono zaokrąglanie parametrów hałasu na 1 miejsce po przecinku</li>
            <li>Na karcie wentylatora (www i PDF), zmieniono zaokrąglanie wartości korekt ciśnienia do 1 miejsca po przecinku</li>
            <li>Na liście preselekcji wyprowadzono sortowalną pwartość PWLopt</li>
            <li>Na liście preselekcji wyprowadzono sortowalną wartość mocy w punkcie pracy</li>
            <li>Dodano filtrowanie na maksimum RPM w preselekcji</li>
            <li>Dodano filtrowanie na maksimum moc w preselekcji</li>
            <li>Pod logiem aplikacji na widoku WWW oraz w stopce wydruku PDF dodano aktualną wersję aplikacji</li>
        </ul>

        <h2>12.0.0</h2>
        <ul>
            <li>Nowa lista preselekcji - większa ilość prezentowaych danych, rozwijanie innych wariantów, sortowanie po kolumnach, filtrowanie, zaznaczanie i podświetlanie ch-ki zaznaczonej opcji</li>
        </ul>

        <h2>11.5.0</h2>
        <ul>
            <li>Zmieniono ograniczenie zadawanej gęstości na od "0.6" zamiast od "1.0"</li>
            <li>Poprawiono liczbowe wyświetlanie parametrów korekty hałasu w symulacji np. "Instalacyjny przyrost hałasu - ..." (gdzie brakowało prezentacji obliczonej wartości)</li>
            <li>Zmieniono w kryteriach domyślną wartość stosunku odległości dla przeszkody z 0.1 na 0.15</li>
            <li>Naprawiono wyświetlnaie słupka "Całk." na wykresie akustycznym dB(Lin) w przypadku karty WWW wentylatora (na PDF i w symulacji nie brakowało)</li>
            <li>Na wykresie słupkowym oktaw dB(Lin) pozostawiono samą jednostkę "dB" (wcześniej było oznaczenie osi dB(A)) - w symulacji + karcie WWW i PDF</li>
            <li>Na karcie wentylatora WWW i PDF dodano sekcję z wartościami korekt hałasu (ale tylko niezerowymi. Sekcja jest obecna, gdy wystąpiła co najmniej jedna niezerowa korekta)</li>
            <li>Zmniejszono wysokość obszaru przeznaczonego na wykres charakterystyki ciśnienia na PDF by zrobić miejsce na dodatkowe parametry np. korekty hałasu</li>
            <li>Zmieniono sposób obliczania krzywej momentu obrotowego (usunięto dzielenie przez 1000.0) w toku przepływowym oraz usunięto mnożenie *10 z wykresu momentu na karcie i symulacji WWW. Skutkuje to wykresami momentu symulacji i karty PDF/WWW dla 4877-5-(14DEM) pod rząd więlkości ~3000 Nm przy 200 rpm</li>
            <li>Dodano więcej lewego marginesu na wykresie momentu obrotowego PDF co powinno wystarczyć na rysowanie wartości do 99 999 Nm bez ucinania pierwszych cyfr</li>
            <li>Zmieniono nazwę parametru "Poprawka akustyczna na średnicę" na "Współczynnik do obl. popr. akustycznej na średnicę"</li>
            <li>Naprawiono bląd niepozwalający ustawić tłumaczenia frazy "Krzywa ciśnienia wentylatora" (spowodowany niepotrzebną spacją na końcu + dodano zabezpieczenie by zmniejszyć ryzyko błędu w przyszłości)</li>
        </ul>


        <h2>11.4.0</h2>
        <ul>
            <li>Dodano nadawanie konkretnego wygenerowanego numeru doboru przy wydruku PDF karty wentylatora, który to jednocześnie jest identyfikatorem w historii doborów</li>
            <li>Wprowadzono obliczanie całkowitej (łącznej z oktaw) mocy akustycznej w skali Lin i dodano ją na wykresach słupkowych oktaw hałasu www/pdf</li>
            <li>Zmieniono formt daty/czasy na wydruku PDF dla podpisu "wygenerowano" na: YYYY.MM.dd HH:mm (bez sekund i literki T)</li>
            <li>Poprawiono w kilku miejscach zapis db(A) na dB(A)</li>
            <li>Z nagłówków typu "(dB(Lin))" usunięto dodatkowe nawiasy pozostawiając "dB(Lin)" (to samo dla dB(A))</li>
            <li>Zwiększono zapas marginesu osi Y (maksimum) u góry wykresów akustyki w oktawach, aby jednostka nie zachodziła na słupki w nitkórych przypadakch</li>
            <li>Skrócono nazwenictwo wartości przeszkody na wlocie/wylocie, by nie powielać: Przeszkoda wlot: przeskoda na wlocie Ap/Ac....</li>
            <li>Zmieniono format prezentacji tabelek paremtrów na wydruku PDF z wyszarzonych teł na delikatne linie pomiędzy parametrami</li>
            <li>Z karty wentylatora usunięto parametry minimalnego i maksymalnego kąta łopat</li>
            <li>Zmieniono dozwolony zakres podawanej gęstości na 1.0-1.5</li>
            <li>Na karcie PDF dodano wykres momentu obrotowego (wpis tej zmiany został dodany podczas prac nad 11.5+ gdyż został oryginalnie pominiety)</li>
        </ul>

        <h2>11.3.0</h2>
        <ul>
            <li>Dodano automatyczny zapis eksportowanego PDF karty wentylatora jako persystentny wpis histori doborów, z możliwością późniejszego ponownego pobrania (z przyczyn technicznych jeszcze bez informacji o oryginalnym autoarze i bez identyfikatoru doboru)</li>
            <li>Poprawiono podziałkę pionowej osi na wykresach akustycznych w PDF</li>
            <li>Naprawiono błąd wykresów akustyki PDF, gdzie rysowane były wartości dB(A) na obu wykresach zamiast Lin na drugim</li>
            <li>Poprawiono wartość szceliny na karcie wentylatora (była niepotrzebnie mnożona przez 100 - np. 50% zamiast 0,5%)</li>
            <li>Przeniosiono umiejscowienie parametry szczeliny na karcei wentylatora pod średnicę obudowy (zamiast na końcu sekcji)</li>
            <li>Usunięto znaczek "-" przy jednostce na karcie wentylatora WWW dla parametrów, które są typowo tekstowe (np. Wykonanie łopat)</li>
            <li>Podzielono wartości na wykresie mocy w PDF przez 1000, by były prezentowane w kW</li>
            <li>Wyprowadzono punkt pracy instalacji na wykresie ciśnienia instalacji w symulacji</li>
            <li>Dodano na ekranie symulacji krzywą poprawek akustycznych (po wydajności)</li>
            <li>Zastąpiono stary wykres poprawek SPL/Fi (obecnie niedziałający) na stronie przeglądu wirnika, nowym wykresem krzywych poprawek akustycznych po Fi (dla wielu kątów)</li>
            <li>Poprawiono nazywanie zdarzeń doboru - formatowanie liczb, by uniknąć zdarzen nazwanych w stylu: "4877.0mm 20.700000000000024°......"</li>
        </ul>

        <h2>11.2.0</h2>
        <ul>
            <li>Na ekranie doboru wprowadzono możliwość eksportu kryteriów do pliku i importu kryteriów z pliku</li>
        </ul>

        <h2>11.1.0</h2>
        <ul>
            <li>Naprawiono prezentację całkowitej wartości PWL dB(A) na wykresie oktaw</li>
            <li>Na karcie wentylatora WWW dodano pole na komentarz pod kartą, który jest załączany w stopce pierwszej strony wdyruku karty do PDF</li>
            <li>W stopce wydruku PDF dodano datę wygenerowania wydruku (zawsze w polskiej strefie czasowej, format ISO data/czas)</li>
            <li>Przeniesiono pozycję prędkości końca łopaty na karcie www przez co będzie ta wartość zawsze obecna (a nie tylko w przypadku obecności udanej symulacji mechanciznej)</li>
            <li>Dodano na kartę wentylatora parametry: pozycja sprzęgła, szczelina nadłopatowa</li>
            <li>Poprawiono sposób wypisywania wartości tekstowych na karcie wentylatora PDF - kolumna na jednostki nie ucina już dlugich tekstów (np. typ aplikacji), obecnych w kolumnie drugiej. Jednostka "-" dla wartości tekstowych nie jest już wypisywana wcale.</li>
        </ul>

        <h2>11.0.1</h2>
        <ul>
            <li>Poprawiono obliczanie poprawki akustycznej na średnicę, zamiast parametrów a,b,c z arkusza XLS wirnika był używany 3x parametr a.</li>
            <li>Zmieniono wybór materiału dla podkładki Nord Lock - od teraz jest to materiał części złącznych, a nie konstrukcyjny (uwaga, dawniej jasno podano wymaganie, że Nord Lock miał być z wyboru konstrukcyjnych)</li>
            <li>Dodano obliczanie sprawności w punkcie pracy procentowo a nie bezwymiarowo - i te wielkość dodano na kartę wentylatora</li>
            <li>Wprowadzono formatowanie sprawności w punkcie pracy na karcie wentylatora zawsze do 1 miejsca, zamiast automatycznego dobierania ilości miejsc po przecinku</li>
            <li>Naprawiono wyświetlanie wartości sprawności na liście preselekcji (zamiast -99900% po ostatnich zmianach dotyczących korygowania)</li>
        </ul>

        <h2>11.0.0</h2>
        <ul>
            <li>Szereg generalnych zmian polegających głownie na prezentacji i liczeniu wartości bazująć na wartościach wentylatora, a nie instalacji:</li>
            <li>Usunięto koncept "nieskorygowanej sprawności" i korygowania sprawności. Teraz istnieje tylko sprawność wentylatora (nie "instalacji") i nie podlega ona korektom.</li>
            <li>Przemianowano koncept "nieskorygowanej krzywej ciśnienia" i ogólnie ciśnień nieskorygowanych, na "krzywą ciśnienia wentylatora" i ogólnie ciśnienie wentylatora</li>
            <li>Przemianowano koncept "skorygowanej krzywej ciśnienia" i ogólnie ciśnień skorygowanych, na "krzywą ciśnienia instalacji" i ogólnie ciśnienie instalacji</li>
            <li>Zastąpiono koncepty skorygowane i nieskorygowanej krzywej ciśneinia akustycznego wentylatora, konceptem "krzywych poprawek akustycznych wirnika". Krzywa poprawek nie podlega korektom, gdyż są one używane w działaniach osobno.</li>
            <li>Zmieniono by moc na wale z powrotem używała sprawności statycznej wentylatora (ponieważ usunięto sprawność "instalacji")</li>
            <li>Zmieniono by moc użyteczna obliczana była z ciśnienia wentylatora a nie instalacji.</li>
            <li>Usunięto koncept mocy instalacji (zwanej dwuznacznie "skorygowaną"), pozostawiono tylko wentylatora - zwaną wcześniej dwuznacznie "nieskorygowaną" ("skorygowaną" wg Optisel 1).</li>
            <li>Usunięto krzywe hałasu z symulacji/karty ponieważ obecnie jedyną krzywą hałasu jest krzywa poprawke, a nie właściwego ciśnienia akustycznego</li>
            <li>Usunięto mechanizm korygowania ciśnienia akustycznego - teraz czynniki korygujące jak przeszkody zbierają jedynie poprawkę na instalację (samą poprawkę, a nie korygują wartość absolutną)</li>
            <li>Przeprojektowano kompleksowo algorytmy hałasu - użyto w nich tylko wartości wentylatora (a nie instalacji), usunięto SPL i zamiast wykresów PWL/SPL są PWL dB(A) i PWL Lin, wprowadzono algorytm przelicznaia oktaw na Lin, zmieniono nazwy i wprowadzono parametry takie jak poprawka całkowita, poprawka z instalacji</li>
            <li>Zmieniono calkowicie nazewnictwo "nieskorygowanej krzywej ciśnienia" (W optisel 2 oznaczała ona wentylatora) na krzywą ciśnienia wentylatora, unikając słowa związanego z "korygowaniem"</li>
            <li>Poniważ wzory na koretkę przez szczelinę dotyczyły zmian procentowych (tzn. nie jest to bezwzględny przyrost np. hałasu a procentowa zmiana) obecne algorytmy nie pasują założeniami (nie operują na ciśnieniu akustycznym tylko poprawkach) i w związku z tym szczelina obecnie może korygować tylko ciśnienie statyczne,
                nie sprawność i hałas
            </li>
            <li>Usunięto wykres mocy instalacji, jako że taki koncept już nie istnieje</li>
            <li>Wyprowadzono oba ciśnienia statyczne na kartę (wentylatora i "dobrane" czyli instalacji)</li>
        </ul>

        <h2>10.2.1</h2>
        <ul>
            <li>Zmieniono długą nazwę materiału "Żeliwo EN-GJL 200" na "Odlew żeliwo"</li>
            <li>Zmieniono długą nazwę materiału "Żeliwo 230-450" na "Odlew staliwo"</li>
            <li>Usunięto materiały od 230-451 do 230-455</li>
            <li>Usunięto ciśnienia akustyczne SPL i tymczasowo ich wykresy</li>
        </ul>
        <h2>10.2.0</h2>
        <ul>
            <li>Naprawiono wyświetlanie parametru profilu łopaty w nagłówku karty wentylatora WWW i PDF oraz poprawiono nieco wygląd (nazwy parametrów, umiejscowienie jednostek)</li>
            <li>Dodano obliczenie: "Moc użyteczna w punkcie pracy - nieskorygowana" (z wydajności i nieskorygowanego ciśnienia)</li>
            <li>Dodano obliczenie: "Moc na wale w punkcie pracy - nieskorygowana" (z "Moc użyteczna w punkcie pracy - nieskorygowana" oraz "Nieskorygowana sprawność statyczna w punkcie pracy"</li>
            <li>Zmieniono wzór obliczania krzywej mocy instalacji: wcześniej używał skorygowanego ciśnienia statycznego oraz nieskorygowanej sprawności. Teraz używa skorygowanego ciśnienia statycznego i skorygowanej sprawności</li>
            <li>W funkcji symulacji dodano podział na dwa wykresy mocy (wliczając warianty kątowe i punkty pracy): Moc nieskorygowaną (wentylatora) oraz instalacji (skorygowanej). Nieskorygowana operuje na nieskorygowanych ciśnieniach, sprawności itd., a skorygowana na skorygowanych instalacją.</li>
            <li>Do karty WWW i PDF wentylatora wyprowadzono moce nieskorygowane instalacją (moc punktu pracy, wykres mocy)</li>
            <li>Zmieniono krzywę momentu tak, by była liczona zawsze z mocy nieskorygowanej</li>
            <li>Zmieniono symulację mechaniki tak, by rozważała nieskorygowaną moc na wale (czyli wentylatora, nie instalacji) do obliczeń obciążeniowych</li>
        </ul>
        <h2>10.1.0</h2>
        <ul>
            <li>Przeorganizowano parametry na karcie wentylatora (WWW i PDF) i dodano szereg parametrów w celu pokrycia zakresu parametrów bardzo podobnego do Optisel 1</li>
            <li>Na karcie wentylatora (WWW i PDF) dodano sekcję mechaniczną, której obecność zależna jest od sukcesu doboru mechaniki</li>
            <li>Usunięto niepotrzebne człony nowego formatu nazwy sprzęgła</li>
            <li>Zarejestrowano próbnie nowe materiały w typoszeregu materiałów, robiąc je na wzór żeliwa (gęstość 7.2). Materiały mają kody "EN-GJL 200" oraz "230-450"..."230-455". Nazwy i parametry mogą być nadal do dokładniejszego określenia.</li>
            <li>Dane ilości łopat, średnic i wielomianych demo wirników 14DEM zostały zrównane z wzorcami "20231106 WIP14D 12-36 + udziały 3-5 łopat SPL 792(3).xlsx" z 7.11.2023, 13:37:23</li>
            <li>Informacja: W wersji tej, dane SPL wirnika 14DEM wydają się być nieprawidłowe, często dając wyniki ujemne/uszkodzone wykresy (odpowiednio z 20231106 WIP14D)</li>
        </ul>

        <h2>10.0.1</h2>
        <ul>
            <li>Średnicą w ogólnej mocy akustycznej w optimum podzielono przez 1000.0, aby w obliczeniu była w metrach, a nie milimetrach</li>
            <li>Ze wzoru na moc na wale w punkcie pracy zdjęto dzielenie przez 1000.0 gdyż po dodaniu tego dzielenia do mocy użytecznej przechodnio dochdoziło do dzielenia przez 1 000 000</li>
            <li>Zmieniono sposób rysowania punktu mocy na wykresie mocy odejmując dzielenie przez 1000.0, ponieważ od pewnego momentu moc dostarczana do wykresu jest już dzielona na 1000 (kW)</li>
            <li>Nowy sposób nazywania sprzęgiel: [typ]-[średnica czopa]-B[ilość śrub]-[długość sprzęgła h1]-[średnica otworu i tolerancja]-[parametr h2]-[wysokość wirnika H3]</li>
        </ul>

        <h2>10.0.0</h2>
        <ul>
            <li>Wprowadzono koncept karty wentylatora WWW. Jest to widok danych typowo wentylatorowych bez informacji o instalacji i kontrolek sterujących symulacją - odpowiadający mniej więcej 1:1 wydrukowi karty PDF</li>
            <li>Wprowadzono nowe obliczenia hałasów - wraz z ogólną mocą akustyczną w optimum i poprawką na średnicę z wielomianu</li>
            <li>Do XLS wentylatora wielomianowego dodano wymagany parametr "Parametr C mocy akustycznej w optymalnym punkcie" i jego interpretację/ładowanie (domyślnie 0 dla wcześniej załadowanych wirników)</li>
            <li>Do XLS wentylatora wielomianowego dodano wymagane parametry a,b,c współczynników poprawki mocy akustycznej na średnicę (domyślnie 0 dla wcześniej załadowanych wirników)</li>
            <li>Do karty wentylatora WWWW oraz PDF dodano stronę z rysunkami mechanicznymi - przy udanym złożeniu mechanicznym</li>
            <li>W korekcie ciśnienia na typ aplikacji - Wymuszono zysk/stratę z ciśnienia dynamicznego równą 0 jeżeli układ pracy jest ssący</li>
            <li>Dodano parametr "Typ wzmocnienia" do eksportu DriveWorks XLSX</li>
            <li>Naprawiono zadawanie kąta łopat w Złożeniu Mechanicznym i jednocześnie w eksporcie DriveWroks - kąt łopaty zawsze dla całości obliczeń był sprowadzany do liczby całkowitej, obecnie już nie jest</li>
            <li>Rozszerzono wynik doboru o podział na krzywą sprawności skorygowaną(instalacji/symulacyjną) i nieskorygowaną (wentylatora). W symulacji nadal pokazywana jest instalacji.</li>
            <li>Do liczenia wykresów mocy (tu jeszce nie ma podziału na skorygowane/nieskorygowane) od teraz zostaje użyta nieskorygowana sprawność wentylatora (a nie instalacji), ponieważ nie ma wykresu mocy dla instalacji osobno</li>
            <li>Do karty wentylatora (WWW i PDF) wyprowadzana jest tylko sprawność nieskorygowana i tylko wykresy mocy liczone ze sprawności nieskorygowanej. Powyższe punkty w raz z tym adresują problem zniekształconych charakterystyk mocy, szczególnie gdy wzory korekt na sprawność sprowadzają ją poniżej 0 (do zaadresowania)</li>
        </ul>

        <h2>9.1.0</h2>
        <ul>
            <li>Rozszerzono komunikat o braku doboru przez brak danych do estymat naprężeń czop-płat - teraz dla każdej kombinacji mechanicznej zostanie wypisane jakich kryteriów czop płat używano</li>
            <li>Rozszerzono komunikat przy złożeniach wirnika o problemie z L-weryfikacja - wypisując dokładne wartości jakie przeszkodziły w udanym doborze</li>
            <li>Zmieniono numery ogólne form XLS demo dla naprężeń czop płat ze starych typu "9992000" na nowe typu "14014", gdyż przez tę zapomnianą zmianę nie dobierana była mechanika wentylatorów demo</li>
            <li>Zmieniono numery ogólne form XLS demo dla grubości tarcz ze starych typu "9992000" na nowe typu "14014", gdyż przez tę zapomnianą zmianę nie dobierana była mechanika wentylatorów demo</li>
            <li>Zmieniono kąt alfa łopat profilu demo (14DEM) na 13 stopień, ponieważ wcześniejsza wartość 44 stopnia mająca "zawsze włączać" pomniejszanie A na tarczy powodowała niespełnianie L weryfikację i brak doboru mechanicznego</li>
        </ul>
        <h2>9.0.0</h2>
        <ul>
            <li>Wprowadzono wydruk do karty wentylatora PDF z ekranu symulacji</li>
            <li>Na osiach ciśnienia na wykresach zmieniono oznaczenie "P" na "Pst"</li>
            <li>Moment obrotowy w obliczeniach przepływowych zmniejszono 1000-krotnie (modyfikacja wzoru oblicznaia krzywej momentu)</li>
            <li>Zmieniono wzory w przepływowych obliczeniach mocy użytecznej i na wale dodając dzielenie przez 1000.0 i zmieniono jednostkę na kW</li>
            <li>Dodano tytuł "Charakterystyka przepływowa" nad wykresami charakterystyk wyników doboru</li>
            <li>Zmieniono wyrażenie "Moment obrotowy względem RPM" na "Moment obrotowy w funkcji obrotów wirnika"</li>
            <li>Dodano możliwość zwijania sekcji "Inne warianty" na wynikach doboru</li>
            <li>Dodano możliwość przełączania na języki DE i RU oraz możliwość uzupełniania dla nich tłumaczeń (natomiast duża część aplikacji nadal używa statycznych nieprzełączalnych polskich tekstów)</li>
        </ul>
        <h2>8.5.0</h2>
        <ul>
            <li>Dodano opisy osi na wykresach</li>
            <li>Dodano brakujące osie X na wykresach (Ale tylko dla głównych osi, by nie rysować zbyt wielu lini przy wielu osiach w jednym kierunku np. m3/s plus m3/h</li>
            <li>Dodano strzałki na końcu osi wykresów</li>
            <li>Naprawiono ucięte duże wykresy charakterystyk, które były ucięte, gdyż były rysowane większe niż dopuszczalne dla nich okno widoku</li>
            <li>Usunięto jedną zbędną dodatkową linię siatki na samej górze wykresu, która nie zachowywała równego odstępu do innych linii siatki w tle wykresu</li>
            <li>Naprawiono defekt, gdzie na liście wyników doboru zamiast wartości ciśnienia w Poscalach, byłą podawana numeryczna wartośc mocy w punkcie pracy</li>
            <li>Naprawiono defekt ze znikającymi sekcjami wyników doboru klasycznego lub rozszerzonego w zależności od kryteriów (obecnie sekcje powinny być zawsze dostępne, ale najwyżej z opisem, że brak wyników lub, że dobór rozszerzony jest niedostępny dla takich kryteriów)</li>
        </ul>
        <h2>8.4.0</h2>
        <ul>
            <li>Wprowadzono autodobór prędkości w doborze klasycznym - testowane są stopniowo prędkości z przedziąłu min-max końca łopaty profilu. Jeżeli nie zadano kąta - podział na 25 prób. Jeżeli zadno kąt - podział na 200 prób</li>
            <li>Dodano możliwośc obliczania gęstości, używając wzorów zapożyczonych z Optisel 1 (wyjątkowo tutaj logi obliczeniowe są widoczne po otwarciu narzędzia Console przeglądarki internetowej, a nie w interfejsie użytkownika)</li>
            <li>Dodano na karcie symulacji wykres mocy (jest to moc na wale - Q * P / sprawność). Wykres zawiera krzywą dobraną, co 10-tą krzywą z pozostałych oraz oznaczoną moc w dobranym punkcie pracy</li>
            <li>Uruchomiony niezaimplementowany wcześniej (ale dostępny w interfejsie) filtr wybranych profili łopatowych przy doborze</li>
            <li>Usunięto przestarzały komunikat na stronie Wirniki: "Pliki demo zostały utworzony na podstawie relatywnie wiekowych XLS wirników z 2016 roku......"</li>
            <li>W podpowiedziach po najechaniu myszą niektórych wykresów, zmieniono jednostkę m3/h na m3/s, gdyż była niewłaściwa</li>
        </ul>

        <h2>8.3.1</h2>
        <ul>
            <li>Wprowadzono edytor wymiarów w kryteriach doboru i symulacji, z uwzględnieniem szczeliny i możliwością obliczania jednego z trzech (średnica wirnika, wentylatora, szczeliny)</li>
            <li>Wprowadzono zróżnicowane limity dopuszczalnej szczeliny w zależności od wybranego wykonania (standard 0.35% - 0.65% średnicy na stronę; AS/SG 0.5% - 1.0% średnicy na stronę). W przypadku przekroczenia niewykonywanie doboru i komunikat pod wymiarami.</li>
            <li>Oprócz dodanego wcześniej wypisu Fi i Psi punktu pracy jako parametr na karcie, dodano także oznaczenie punktem na wykresie z w wypisanymi na punkcie wartościami (wykres ten ma uproszczony charakter i będzie najpewniej porzucony lub migrowany do ujednoliconej technologi) (dotyczy to widoku Symulacja)</li>
            <li>Naprawiono skomplikowany defekt wybierania nieprwidłowego kąta po przejściu z doboru do symulacji, kiedy alfebetycznie pierwszy wirnik w systemie miał większy minimalny kąt łopaty niż aktualnie dobrany w doborze kąt łopaty (np. dobrano 9.8st, ale pierwszy wirnik w systemie posiadał kąt minimalny 12.0 - wtedy na
                widoku symulacji formularz był populowany kątem 12 z przyczyn technicznych)
            </li>
            <li>Przeniesiono wartości poszczególnych korekt na karcie symulacji z sekcji podstawowej do sekcji informującej koretkach</li>
            <li>Zaimplementowanie korygowanie kryteriów w doborze dla korekty na szczelinę (podczas doboru nie następują korekty całej charakterystyki jak w symulacji, a jedynie punktowa koretka kryteriów, czyli np. poszukiwane ciśnienie jest modyfikowane ze względu na szczelinę)</li>
            <li>Ujednolicono wzór na szczelinę przy przejściu z wyniku doboru do symulacji, gdyż poprzedni potrafił dawać nieco inny wynik niż w kalkulatorze wymiarów i zmieniać szczelinę np. z 0.9% na 0.91% przy przejściu z doboru do symulacji)</li>
        </ul>

        <h2>8.3.0</h2>
        <ul>
            <li>Dodano możliwość zapisu wybranego złożenia na stronie "Złożenie wirnika" jako utrwalone zdarzenie w "Historii doborów" z pełną listą szczegółow, z zapytnaiem użytkownika o numer sprawy podczas zapisu</li>
            <li>Dodano możliwość anulowania zapisu wybranego złożenia na stronie "złożenie wirnika" jeżeli omyłkowo kliknięto przycisk</li>
            <li>Poprawiono liczenie hałasów w oktawach, gdzie obecnie wskaźniki SPL w dB(A) były liczone z PWL</li>
            <li>Zaimplementowano liczenie, oraz drugi wykres, PWL całkowitego podzielonego na oktawy (oktawy SPL są liczone z udziałów ciśnienia akustycznego całkowitego, a oktawy mocy z udziałów w mocy akustycznej)</li>
            <li>Dodano wartości numeryczne w oktawach nad słupkami na wykresie</li>
            <li>Na wykresach hałasu w oktawach dodano dodatkowy słupek z wartością całkowitą hałasu</li>
            <li>Zmieniono nazwę kolumn XLS "Promień kołnieża (dla pozycji 2,4,6)" na "Promień kołnierza (dla pozycji 2,4,6)"</li>
            <li>Dodano wyprowadzanie do pliku eksportu DriveWorks parametru: "Średnica podtrzymująca H4 [mm]"</li>
            <li>Dodano wyprowadzenie do pliku eksportu DriveWorks parametru: "Korekta Ant". Wyprowadzono także tę wartość na szczegóły złożenia wirnika.</li>
            <li>W wypadku okazji kolizji typu "Podpory i sprzęgło po tej samej stronie tarczy", zmieniono jedno z ograniczeń utrzymujących "A na tarczy przed potencjalnym pomniejszeniem": zamiast po prostu promień kołnierza, dodano wzór wyliczający "Minimalne A Na tarczy Dla Uniknięcia Kolizji Czop-OtworD4" i wartość przed
                pomniejszeniem nie możę być mniejsza niż ta wyliczona, przy czym należy pamiętać, że nadal może dojść do pomniejszenia A na tarczy dla niskich kątów łopatowych
            </li>
            <li>Dodano dodatkowe objaśnienie w logu obliczeniowym A na tarczy starą metodą, która jest tylko iformacyjnie i potwierdzono, że nie jest używana w docelowych wynikach: "(ale wynik starą metodą zostanie kompletnie zignorowany)"</li>
            <li><strong>Wersja niekompatybilna wstecz. Należy ponownie wgrać XLS mechaniczne ze względu na zmiany, a gdyby istniały zapisane dobory mechaniczne starszej wersji, mogą się źle wczytywać przez brak "Korekta Ant"</strong></li>
        </ul>

        <h2>8.2.0</h2>
        <ul>
            <li>Wprowadzono nowy sposó przeglądania ścieżk i błędów doborów mechanicznych - "Drzewo błędów". Prezentuje one wszystkie testowane kombinacje mechaniczne z ostatecznym komunikatem błędu, niezależnie od tego czy kombinacja zakończyła się na hierarchicznym poziomie całego doboru, formy, sprzęgła czy wariantu śrubowego
            </li>
            <li>Odwrócono znak przy algorytmie pomniejszania A na tarczy dla małych kątów - wartość pomniejszania jest dodawana do bazowego A na tarczy przed pomniejszaniem, a nie odejmowana jak wcześniej</li>
            <li>Dla wymiennika ciepła i skraplacza, zmieniono opisy dotyczące korekt na "(odzysk)" i "(bez odzysku)", unikając słowa korekty oraz podawania dokładnej wartości korekty</li>
            <li>Dodano koncept definiujący, czy dany czynnik korygujący ciśnienie to strata czy odzysk ("branżowo") i wg tego ustalono nazwę parametru na kartę ze słowem strata lub odzysk (dla typu aplikacji oraz dyfuzora: odzysk, reszta strata). Wcześniej opierało się to na oszukanym wpisie w rejestrze tłumaczeń, gdzie strata
                ciśnienia na dyfuzor lub typ była prezentowana po polsku jako odzysk.
            </li>
            <li>Odwrócono działanie dyfuzora, ponieważ obecnie działał on jako strata ciśnienia (w przeciwną stornę niż typ aplikacji). Obecnie Dyfuzor daje faktycznie zysk ciśnienia instalacji (porównując do ciśnienia wentylatora), ale na karcie wynikowej będzie branżowo podawany ze słowem "odzysk" i znakiem ujemnym</li>
            <li>Poprawiony logi obliczeniowe dla krzywch Fi generalnie - w tym SPL/Fi i Lambda/Fi</li>
        </ul>

        <h2>8.1.0</h2>
        <ul>
            <li>Dodano śledzneie historii wgranych plików wraz z nowym ekranem historii i możliwością pobrania ostatnich plików (10 per typ, przy czym dla wirników każdy profil ma swój własny typ, więc dla wirników może być dowolnie dużo, ale maks 10 per profil)</li>
            <li>Wprowadzono algorytm pomniejszania A na tarczy w przypadku kątków mniejszych niż "Kąt A" dla formowania, używając współczynników (A*kąt + B) z formowania łopaty</li>
            <li>Dodano interpretację kolumn dla pomniejszania A na tarczy oraz dodano wartości dla przykładowych danych demo: "Kąt a [°]", "Zmniejszenie Ant poniżej kąta minimalnego - wsp.A", "Zmniejszenie Ant poniżej kąta minimalnego - wsp.B". W danych demo: (1DEM) nigdy nie pomniejszane, (14DEM) zawsze pomniejszane o 0,02*a+0,3
            </li>
            <li>Naprawiono defekt w nowym selektorze wariantów mechanicznych, gdzie numer formy zawsze był prezentowany jako dokładnie ciąg znaków "111111" zamiast faktyczny numer formy</li>
            <li>Poprawiono dane demo - formowania łopat - by formy dla (1DEM) nie miały tego samego numeru formy co dla (14DEM)</li>
            <li>Wzbogacono komunikat o braku doboru mechanicznego ewentualnymi dodatkowymi informacjami, jednak w przypadku niekrytycznych błędów nadal informacje mogą być szczątkowe i przyczyn należy szukać na końcu zakładki "Obliczenia"</li>
        </ul>

        <h2>8.0.1</h2>
        <ul>
            <li>Odwrócono warunek nieudanego doboru ze względu na długość sprzęgła - teraz dobór będzie nieudany, gdy sprzęgło jest krótsze niż wał</li>
        </ul>

        <h2>8.0.0</h2>
        <ul>
            <li>Wprowadzono obliczenia poziomu ciśnienia akustycznego w oktawach, razem z importem udziału oktaw z XLS wirnika oraz wykresem oktaw na karcie symulacji</li>
            <li>Zmieniono nazwę parametru "Ciśnienie akustyczne w punkcie pracy" na "Całkowite ciśnienie akustyczne w punkcie pracy"</li>
            <li>Zaktualizowano plik demo wirnika wielomianowego o udziały hałasu w oktawach (na ten moment udziały nadal są przypisane do wirnika, nie do profilu)</li>
            <li>Nowy selektor widocznego rozwiązania mechicznego na stronie Złożenie Wirnika (większy numer formy + podział na duże grupy długości łopat + typy sprzęgieł jedno pod drugim + przy każdym sprzęgle ilości śrub</li>
            <li>Szybki podgląd na masę (drobny napis pod ilością śrub) i zdanie kryteria Kryt 1 sprzęgła (wyróżnienie kolorem, gdy nie zaliczone) na nowym selektorze złożeń wirnika</li>
        </ul>

        <h2>7.1.0</h2>
        <ul>
            <li>Usunięto z programu wszelkie zmiennoprzecinkowe wartości grubości tarczy i mechanizmy zaokrąglania i zastąpiono grubość tarczy wartością całkowitą (co 1mm)</li>
            <li>Dodano ograniczenie, by długość wału nie mogła być mniejsza niż średnica wału (a w łaściwie średnica otworu sprzęgła)</li>
            <li>Wprowadzono obliczanie skróconej nazwy tarczy</li>
            <li>Zmieniono wartość nazwy tarczy występującej w pliku DriveWorks z pełnej na skróconą</li>
            <li>Usunięto stary mechanizm zabezpieczenia hasłem całej aplikacji (ponieważ istnieje już logowanie użytkownika)</li>
            <li>Do formularzów doboru/symulacji dodano przycisk resetujący kryteria do domyślnych (w prawym górnym rogu)</li>
            <li>Zmieniono korekty na typ aplikacji: ssący ACHE i kondensator: odzysk 19% ciśnienia statycznego, tłoczący ACHE i kondensator: odzysk 60% ciśnienia dynamicznego</li>
            <li>Zmieniono nazwy typów aplikacji zamieniając dokładniejszy opis korekty np. "odzysk -19%" na bardziej ogólny np. "Wymiennik ciepła chłodzony powietrzem (ACHE) (z odzyskiem)", gdyż teraz zależy od wyboru tłoczący/ssący (a kiedyś były jednakowe dla tych trybów pracy)</li>
            <li>Usunięto prototypowy mechanizm automatycznego przerzucania podpór i zamiast tego dodano prostą walidację: dobór nieudany, gdy długość sprzęgła H1 przekracza długość wału. W tej sytuacji, gdy podpory są po przeciwnej stronie niż sprzęgło, w komunikacie błedu jest sugestia proponująca pozycję 2. W przypadku gdy
                podpory już są po stronie sprzęgła, następuje błąd bez sugestii.
            </li>
            <li>Usunięto kontrolkę UI odpowiadającą za zadawanie kryterium - czy pozwalać na automatyczne przerzucanie podpór</li>
        </ul>

        <h2>7.0.0</h2>
        <ul>
            <li>Utworzono zupełnie nowy mechanizm rejestrowania doborów i symulacji - strona Historia doborów</li>
            <ul>
                <li>Podział zapisanych doborów na utrwalone oraz tymczasowe (wyszarzone, ale też możliwe do oglądania). W przyszłości nieutrwalone prawdopodobnie będą samoczynnie usuwane</li>
                <li>Wprowadzono rejestrowanie doborów mechanicznych przez API z Optisel 1 do Historii Doborów. Dobór z Optisel 1 jest rejestrowany jako "utrwalony" (nie tymczasowy)</li>
                <li>Wprowadzono rejestrowanie procesu składania wirników do katalogu Historii Doborów (jako tymczasowe)</li>
                <li>Wprowadzono rejestrowanie wykonanych udanych symulacji z mechaniką (jako tymczasowe) do katalogu Historii Doborów</li>
                <li>Wprowadzono rejestrowanie wykonanych symulacji bez udanej mechaniki (jako tymczasowe) do katalogu Historii Doborów</li>
                <li>Wprowadzono rejestrowanie przeprowadzonych doborów (jako tymczasowe) do katalogu Historii Doborów</li>
                <li>Wprowadzono rejestrowanie do katalogu doborów jako tymczasowe: Nieudanych symulacji (zakończnych błędem), nieudanych złożeń mechanicznych (zakończonych błędem)</li>
                <li>Wprowadzono rejestrowanie do katalogu doborów jako trwałe: nieudane dobory z Optisel 1</li>
                <li>Zintegrowano katalog zdarzeń z obecnie zalogowanym użytkownikiem Optisel 2 by rejestrować autora (podczas, gdy dla Optisel 1 używany jest autor dyktowany przez API z poziomu zalogowanego użytkownika Optisel 1)</li>
                <li>Oprócz numeru zapytania i sprawy dodano na stronie doborów mechanicznych identyfikator złożenia mechanicznego, które uzyskał Optisel 1 (tylko w przypadku udanego złożenia)</li>
                <li>Na nowej stronie "Historia doborów" w porównaniu do poprzedniej wyszukiwarki ("Dobory nieudane" / "Dobory mechaniczne") dodano nowy filtr - "Identyfikator złożenia", który jest identyfikatorem złożenia mechanicznego, które jest także osadzany w PDF Optisel 1 i generowany automatycznie</li>
                <li>Wzbogacono stronę Historia doborów o filtr "tylko utrwalone" - domyślnie tak (ukrywając domyślnie samorejestrujące się dobory z doboru/symulacji/złożeń Optisel 2</li>
                <li>Wzbogacono wpisy historii doborów o uproszcozny zapisów kryteriów dla doborów z Optisel 1 oraz dla Złożenia wirnika</li>
                <li>Usprawniono proces obsługi błędów na stronie pojedynczego zdarzenia z Historii Doborów na wypadek braku kompatybilności starych danych z bazy danych z obecnymi - zostanie niewyświetlony tylko 1 blok, a nie cała strona</li>
            </ul>
            <li>Ze względu, że Historia Doborów nie jest już typowo "Mechaniczna", zreorganizowano menu u góry strony. Historia doborów jest dostępna w menu Administracja, a "Złożenie wirnika" jest dostępne bezpośrednio a nie z submenu.</li>
            <li>Naprawiono logi mechaniczne "Średnica wału dla doboru mechanicznego =" i "Długość wału dla doboru mechanicznego = (były one zamienione wartościami)</li>
            <li>Na stronach Złożenie wirnika, Symulacja, Dobór: podczas wyszukiwania (obracający się wskaźnik ładowania) dodano pusty wysoki element storny, tak, aby przeglądarka nie przeskakiwała na górę strony podczas wyszukiwania</li>
            <li>Wprowadzono ujednolicone zachowanie podczas błędu lub wylogowania/końca sesji na stronie złożenia wirnika (wcześniej strona ta nie regowała przekierowaniem na stronę logowania, gdy sesja wygasła)</li>
            <li>Poprawiono propagację komunikatu błędu "Nie znaleziono grubości tarczy (w bazie wymagań grubości tarcz) dla numeru ogólnego formy" dodając go także do loga obliczeniowego pojedynczego złożenia, a nie tylko loga złożeń jako całego procesu wyszkukiwania</li>
            <li>Usunięto ostrzeżenia na temat tego, że funkcjonalność doboru jest prototypowa, gdyż obecnie wszsytkie widoczne funkcjonalności powinny już działać poprawnie</li>
            <li>Zrezygnowano z ostrzeżenia o danych demo przy czopach np. 33m u góry strony Złożenia wirnika, ponieważ istnieje dedykowany, domyślnie wyłączony przełącznik "Użycie przykładowych danych demo"</li>
        </ul>

        <h2>6.1.0</h2>
        <ul>
            <li>Zmieniono wzór dla korekty na dyfuzor "Ciśnienie dynamiczne na wylocie ze stożka 'Pdwyl'" - dzielenie przez gęstość czynnika zastąpiono mnożeniem przez gęstość czynnika</li>
            <li>Zmieniono edytor parametrów dyfuzora tak, aby można było zadawać wielkości z precyzją do trzech miejsc po przecinku (w przypadku bazowej jednostki czyli metrów)</li>
            <li>Zmieniono precyzję podczas pracy z jednostką milimetr, gdy bazową jest metr tak, aby zamiast jeszcze bardziej zwiększać precyzję o 3 miejsca, zmniejszała tę precyzję o 3 miejsca (przykładowo, jeżeli w metrach mamy "1.555" to w milimetrach teraz usykamy "1555" a nie jak dotychczas "1555.000000"</li>
            <li>Zmieniono precyzję podczas pracy z jednostką "ft" i "in" podobnie jak z milimetrem, gdyż także działały w odwrotną stronę i jeszcze bardziej zwiększały precyzję po przecinku w stosunku do metra.</li>
            <li>Zmieniono możliwe do wyboru typy aplikacji: "Nieokreślona" zmieniono na "Nieokreślona (brak korekt)", do ACHE i ACC dopisano "(odzysk -19%)", dodano nowe opcje ACHE oraz ACC bez koret (z dopiskiem "(bez korekt)")</li>
            <li>Wprowadzono ograniczenie, aby obliczenia przepływowe kończyły się błędem przy próbie obliczania wentylatora typu ACHE na tłoczeniu z obecnym dyfuzorem</li>
            <li>Istniejące mapowanie profili dla Optisel 1 przy doborze (np. dobieranie (14HD) w Optisel 1 nadal powoduje przeszukanie mechaniki dla profilu (14) w danych mechanicznych) zastosowano także do selektroa profili na stronie Złożenie Wirnika. Efektywnie więc w tym selektorze nigdy nie pojawi się np. profil (14DR), nawet
                jeżeli dane mechaniczne dla takiego są wgrane. Dokładna lista mapowania jest dostępna na stronie Typoszeregi.
            </li>
            <li>Wydłużono czas sesji po zalogowaniu ze 100 minut do 12 godzin</li>
            <li>Zmieniono nomenklaturę "Strata ciśnienia - Typ aplikacji" na "Odzysk ciśnienia - Typ aplikacji" (np. -20 Pa)</li>
        </ul>

        <h2>6.0.0</h2>
        <ul>
            <li>Wersja beta zarządzania tożsamością (modułu użytkowników) - (zarządzanie kontami, logowanie, role i przywileje, zmiana hasła itp.)</li>
        </ul>


        <h2>5.6.0</h2>
        <ul>
            <li>Usunięto z wzorów na minimalną długość śruby zabezpieczającej grubość tarczy i jej osadzenia oraz zunifikowano wzoru do jednego, gdyż wzór nie zalezy już od tego czy sprzęgło jest na wlocie</li>
        </ul>

        <h2>5.5.0</h2>
        <ul>
            <li>Usunięto mechanizm całkowitego porzucania wyników (jeszcze przed wyborem optymalizacji), który kierował się wyłącznie ilością śrub ("...śrub zostaje uznane za ignorowane, gdyż istnieje nieprzeciążone z mniejszą ilością śrub mocujących....")</li>
            <li>Wprowadzono dedykowaną rozwijaną sekcję logu wyboru optymalnego wirnika, gdyż po dodaniu rozwijanych sekcji optymalizacja wpadała do sekcji ostatniego wrika, przez co ciężko było ją znaleźć</li>
            <li>Na etapie optymalizacji dodano w logach dokładne wartości porównywane (np. "...przyjęta jako nowa najlepsza (sprzęgło 4.9 kg), ze względu na mniejszą masę sprzęgła (niż 5.1 kg)...")</li>
            <li>Zmieniono opis pola formularza mocy na złożeniu wirnika z "Moc w punkcie pracy [kW]" na "Moc w punkcie pracy [W?kW?]"</li>
            <li>Ujednolicono wzory obliczania maksymalnej długości śruby zabezpieczającej, usuwając człony grubości tarczy i/lub osadzenia tarczy. Ujednolicono do jednego wzoru, gdyż oba warianty (sprzęgło wlot vs wylot) zaczęły wyglądać jednakowo</li>
            <li>Usunięto oznaczenie "S" przy typie sprzęgłą podczas wyliczania nazwy tarczy (które powodowało zdublowane S ze względu na jego obecność już w samym typie sprzęgła), czyli obecnie postać: TCyy-x-Bb-Dd-Gg-Aa-Nn (gdzie 'x' to typ sprzęgła)</li>
            <li>Poprawiono obliczanie członu tanges w korekcie na dyfuzor, gdzie brakowało konwersji stopni na radiany przed użyciem funkcji tangens</li>
        </ul>

        <h2>5.4.0</h2>
        <ul>
            <li>Zmieniono wzór na masę podkładki zabezpieczającej zmieniając przelicznik dotyczący różnicy jednostek z 1/1000*1000*1000 na 1/1 000 000 (gestoscMaterialuPodkladki/(1 000 000))</li>
            <li>Dodano oznaczenia literami G... i F... w nazwie podkładki zabezpieczającej ([nazwa typu podkładki]-G[grubość podkładki]-F[Średnica otworu pod śrubę zabezpieczającą [mm]])</li>
            <li>Wprowadzono rozwijane sekcje głównego loga obliczeniowego podczas doboru mechanicznego na stronie Złożenie wirnika oraz wyróżniono kolorem/czcionką wiele istotnych komunikatów błędów (zazwyczaj podsumowujących nieudany dobór danej formy)</li>
            <li>Wprowadzono nowy typ aplikacji- "Nieokreslona" - i jest on teraz opcją domyślną. Aplikacja ta nie podlega koretkom ciśnienia jak kondensator czy ACHE)</li>
            <li>Zmieniono domyślną wartość kąta dyfuzora na 7,5°</li>
        </ul>
        <h3>5.3.0</h3>
        <ul>
            <li>Usunięto mapowania nazw profili "(15M) na (15)" oraz "(15S) na (15)", więc od teraz w mechanice będą poszukiwane dokładnie takie nazwy profili jakie były w zapytaniu Optisel1 w przypadku tych profili, a nie (15)</li>
            <li>Usunięto niepotrzebny już od jakiegoś czasu komunikat ostrzegający na stronie ładowania danych: "Obecnie brak możliwości wgrywania arkuszy przeszkód. Korekty ciśnień są obecnie liczone z 4 przykładów osadzonych w aplikacji..."</li>
            <li>Usunięto typoszereg powiększeń tarcz (nie używany już od jakiegoś czasu)</li>
            <li>Usunięto koncept typu podkładki zabepieczającej PZU/PZK i związane z tym obliczenia</li>
            <li>Dodano wczytywanie kolumny "Maksymalna grubość podkładki zab." w manufakturyzacji sprzęgieł</li>
            <li>Usunięto wczytywanie kolumny "Masa podkłaki zabezpieczającej" w manufakturyzacji sprzęgieł</li>
            <li>Usunięto wczytywanie kolumny "Podkładka zabezpieczająca PZU" w manufakturyzacji sprzęgieł</li>
            <li>Usunięto wczytywanie kolumny "Podkładka zabezpieczająca PZK" w manufakturyzacji sprzęgieł</li>
            <li>Usunięto wczytywanie kolumny "Grubość podkładki zabezpieczającej [mm]" w manufakturyzacji sprzęgieł</li>
            <li>Dodano wczytywanie kolumn "Podkładka zab. PZC", "Norma podkładki zab. PZC" w manufakturyzacji sprzęgieł</li>
            <li>Z osadzonego w programie typoszeregu pozycji wirnika usunięto kolumnę "Podkładka zabezpieczająca"</li>
            <li>Odwrócono kolejność liczenia min/max długości śruby zabezpieczającej z obliczaniem podkłądki zabezpieczającej, gdyż obecnie do obliczenia min/max długości jest potrzebne obliczenie grubości podkładki, która to grubość wcześniej była od razu znana (XLS) i max długość była liczona najpierw (ale obecnie z
                pozostawieniem wyboru materiału podkładki przed wszystkim, gdyż jego gęstość jest potrzebna do ustalenia masy podkładki)
            </li>
            <li>Zmieniono zawsze stałą normę podkładki zabezpieczającej "018.S-7828-01" na pobieraną z XLS manufakturyzacji sprzęgieł</li>
            <li><strong>Wprowadzono obliczenia podkładki zabezpieczającej PZC</strong> - nowa nazwę, masę, warunek grubości, normę z XLS</li>
            <li>Dostosowano wzór na masę podkładki zabezpieczającej zmieniając oryginalnie proponowany człon gęstości na podzielony przez 1000*1000*1000, by użyć gęstości w kg/mm^3</li>
        </ul>

        <h3>5.2.0</h3>
        <ul>
            <li>Poprawiono wzór na korektę ciśnienia dla prędkości wiatru (prędkosć podniesiona do kwadratu - wcześniej brakowało podnoszenia do potęgi 2.)</li>
            <li>Ograniczono w wenętrznym algorytmie maksymalną prędkość wiatru do 10m/s (symulacja/dobór zakończą się błędem gdy większa)</li>
            <li>Ograniczono w interfejsie użytkownika symulacji i doboru maksymalną prędkość wiatru poprzecznego do 10m/s (nie powinno się dać wpisać większej wartości)</li>
            <li>Zmieniono kilka algorytmów dotyczących zapasów API, by korzystały z krzywych wentylatora (a nie "zduszonych" krzywych instalacji podczas symulacji jak dotychczas)</li>
            <li>Przeniosiono oznaczneie punktów zapasów API z wykresu instalacji na wykres wentylatora, ponieważ są one obliczane z ch-ki wentylatora</li>
            <li>Dodano linią przerywaną na wykresie dodatkową linię pokazującą charakterystykę instalacji ("zduszoną")</li>
            <li>Dodano krzywe oporu na wykresie wenyltora w karcie z symulacji</li>
            <li>Odwrócono koretkę ciśnienia z typu aplikacji (ACHE oraz skraplacz): teraz dają one zysk zamiast powodować stratę (na razie jeszcze na interfejsie nazwane stratą, ale z wartością ujemną, czyli zysk)</li>
            <li>Zmieniono wartości korekt instalacji (ACHE oraz skpracz) na 19% tam gdzie było to 60%</li>
            <li>W nazwach oreintacji montażowej wentylatora dodano objaśnienie dotyczące wału: "Poziomo (wał pionowy)" oraz "Pionowo (wał poziomy)"</li>
            <li>Dodano interpretację nowej kolumny w XLS strategii montazu łopat: "Standard montażu na tarczy"</li>
            <li>Wzbogacono pliki demo XLS mechaniczne o wartości dla "Standard montażu na tarczy" (wszędzie "007.S")</li>
            <li>Wprowadzono nowy algorytm obliczania nazwy tarczy. Nie ma już konceptu "bazowej nazwy tarczy" i cała nazwa tarczy jest kalkulowana z kilku parametrów</li>
            <li>Usunięto osadzony w programie typoszereg "Standard bazowego nazewnictwa tarcz"</li>


        </ul>
        <h3>5.1.0</h3>
        <ul>
            <li>Wprowadzono funkcjonalność <strong>rejestracji nieudanych doborów</strong> w raz podstroną, na której można przeglądać ostatnie 100 nieudanych doborów mechanicznych i ich szczegóły (funkcjonalnośc uproszczona)</li>
            <li>Wydzielono osobno zakładki dla długości łopati ilości śrub na liście wyników Złożenia Wirnika, tworząc dwu-poziomową hierarchię wyboru prezentwanego wariantu (najpierw wybór po długości łopaty z formy, potem po ilości śrub)</li>
            <li>Ustawiono "Nie" jako domyślną opcję "Dozwolone przerzucenie podpór" w Złożeniu Wirnika</li>
            <li>Zmieniono domyślny typ aplikacji na "Mokra chłodnia" by uniknąć domyślne silnich korekt na ACHE</li>
            <li>Umożliwono <strong>eksport listy materiałowej do pliku XLS dla wyników Złożenia Wirnika</strong> (eksporty te będą posiadały samą tabelę. Arkusz Obliczenia będzie pusty ze względu na to, że architektura wymaga utrzymywania wielu wyników w pamięci na poczet potencjalnego pobrania XLS dla któregoś, obliczenia są więc
                usuwane)
            </li>
            <li>Zmieniono nazwę pozycji menu "Dobory mechaniczne" na "Historia doborów mechanicznych"</li>
            <li>Naprawiono zbyt przeźroczyste tło na stronie pojedynczego doboru z historii doborów (brak białego tła)</li>

        </ul>

        <h3>5.0.1</h3>
        <ul>
            <li>Przywrócono składnik "+ 0.5*srednicaGwintuDlaZestawuSrubowegoSt" we wzorze "(dla A na tarczy) Minimalna odległość podpory od osi wirnika dla sprzęgła"</li>
            <li>Zmniejszono szansę komunikatu zbyt wielu dobórw naraz przy przejściu na Symulację z doboru (był tam pewien wyścig żądania symulacji z kryteriami domyślnym a po ułamku sekundy właściwymi dla symulacji z doboru)</li>
        </ul>
        <h3>5.0.0</h3>
        <ul>
            <li><strong>Całkowicie zmieniono algorytm doboru na zgodny z Optisel 1</strong></li>
            <ul>
                <li><strong>Domyślnie użytkownik zadaje ciśnienie statyczne instalacji</strong></li>
                <li>Na liście wyników doboru zawsze prezentowane jest ciśnienie punktu pracy wentylatora (nie "zduszone" instalacją)</li>
                <li>Dobierając na ciśnienie statyczne instalacji (standard Optisel 1) często będzie dobierane wyższe ciśnienie statyczne niż użytkownik zadał, tzw. "skorygowane" w nomenklaturze Optisel 1</li>
                <li>Na wykresie wyników doboru widzimy charakterystyki wentylatorów (nie "zduszone")</li>
                <li>Po przejściu na symulację, zobaczymy punkt pracy wentylatora (często niższy niż instalacji), a narysowane charakterystyki będą charakterystykami instalacji ("zduszonymi")</li>
                <li>Istnieje <strong>dodatkowa możliwość ponad Optisel 1 - zadania ciśnienia wentylatora jeżeli jest znane</strong>, i wtedy kryterium doboru ciśnienia nie będzie zwiększane pod opory</li>
                <li>Uwaga - Symulacja jest "kartą instalacji", natomiast w przyszlości będzie istotne wyświetlenie karty wentylatora, z wykresami wentylatora ("niezduszonymi")</li>
                <li>W przyszłości będzie też konieczne wyklarowanie terminu "skorygowane [ciśnienie]", gdyż wcześniej było one zrozumiane opacznie i w większości miejsc w Optisel 2 występuje ono jako "zduszenie" wentylatora, czyli jest to ciśnienie niższe niż wentylatora - czyli instalacji, podczas gdy w Optisel 1 terminologia była
                    odwrotna (skorygowane = wyższe niż zadał użytkownik)
                </li>
            </ul>
            <li><strong>Przywrócono prototypową funkcję doboru rozszerzonego</strong> (auto-średnica i auto-RPM pod 4 warianty: jak najmniejszej średnicy, jak najniższy hałas, jak najwyższa sprawność, jak najniższe rpm), jednak obecnie funkcja ta aktywuje się tylko dla doborów "Zadano ciśnienie samego wentylatora", gdyż nie
                obsługuje jeszcze korygowania kryteriów
            </li>
            <li>Wprowadzono na karcie symulacji rozróżnienie na ciśnienie instalacji i wentylatora (np. "w punkcie pracy 234.2 m³/s • 100.0 Pa instalacja • 123.4 Pa wentylator")</li>
            <li>Na ekranie symulacji rozróżniono tymczasowo charakterystykę na dwa wykresy dla jasności: instalacji (przeliczoną czynnikami korygującymi) oraz uproszczoną wentylatora (docelowo to właśnie wentylatora będzie na karcie do wydruku)</li>
            <li>Wprowadzono <strong>czynnik korygujący na typ aplikacji w układzie pracy</strong>, tj. ACHE lub kondensator dają stratę ciśnienia 19% lub 60% w zależności od układu pracy i aplikacji
                (kombinacje do wglądu w nowym typoszeregu)
            </li>
            <li>Zmieniono prezentację typoszeregów dodając zwijanie/rozwijanie tabeli, ze wszystkimi tabelami domyślnie zwiniętymi</li>
            <li>Ograniczono ryzyko "zlimitowania" (komunikat o przeciążeniu lub zbyt wielu doborach) doboru/symulacji przy szybkim przejściu między stronami, kiedy serwer jeszcze pracuje nad wynikiem</li>
            <li>Zrestrukturyzowano podstrony tak, zeby górne menu nie ładowało sie od nowa przy przechodzeniu pomiędzy stronami</li>
            <li>Wprowadzono ikonę ładowania w kilku miejscach, by uniknąć sytuacji kiedy trzeba na coś czekać (jest ładowane) a strona wizualna tego nie odzwierciedla</li>
            <li>Zrównoleglono dobór na wiele wątków, efektywnie przyspieszając dobór ok. 5x (zależnie od ilości rdzenia i szybkości pamięci RAM serwera)</li>
            <li>Dodano <strong>grupowanie rezultatów złożenia wirnika po numerze formy</strong></li>
            <li>Ze wzoru "(dla A na tarczy) Minimalna odległość podpory od osi wirnika dla sprzęgła" usunięto składnik " 0.5*srednicaGwintuDlaZestawuSrubowegoSt "</li>
            <li>Wprowadzono <strong>eksperymentalne przerzucanie podpór na drugą stronę tarczy</strong>, jednak może ono wymagać dodania innych założeń do pełnej funkcjonalności - ponieważ program pozwalał na bardzo krótkie, a nawet ujemne (!) długości sprzęgieł, typu 1mm czy nawet -5mm (!). Dlatego też miało zostać
                wprowadzona <strong>minimalna długość sprzęgła H1 = 30mm</strong>, jednak prawdopodobnie zaburzyła by ona uzyskaną wysokośc wirnika, a nie doprowadzała do przerzucania.
            </li>
            <li>Naprawiono defekt powodujący, że zadana prędkość wiatru poprzecznego w nowych interfejsach zawsze wynosiła 0.0m/s</li>
            <li>Przeprowadzono dużą restrukturyzację struktur danych w programie w celu utrzymania produktywności w przyszłości o zwiększenia poprawności/zmniejszenia ryzyka defektów pomięzy obszarami gdzie pewne kryteria są obowiązkowe (konkretny nastaw wirnika) a nieobowiązkowe (dobór)</li>
            <li>Tymczasowo usunięto presety z "Katalog przykładów" w celu dostosowania ich w przyszłości do nowych struktur danych kryteriów</li>
        </ul>

        <h3>4.3.0</h3>
        <ul>
            <li>Wprowadzono zupełnie nowy ujednolicony typoszereg norm tarcz</li>
        </ul>
        <h3>4.2.0</h3>
        <ul>
            <li>Dodano nowe wpisy typoszeregu "Standard nazewnictwa norm dla tarcz" oraz "Standard bazowego nazewnictwa tarcz" jednak istnieje duża szansa, że nie są one poprawne (ze względu na niezbyt jasne instrukcje)</li>
            <li>Zmieniono domyślne kryeteria doboru i sumulacji na wartości używane dotychczas w Optisel 1</li>
            <li>Zmieniono sortowanie wyników doboru z "wg najwyżśzej sprawności" na "wg najmniejszego błędu" (efektywnie też zmieniło to faktycznei wybierany wariant kątowy jako ten główny dla danego wirnika, pozostałe widać po kliknięciu "Pokaż inne kąty"</li>
            <li>Naprawiono defekt znikającego pola kryterium "Kąt łopat" wdoborze, kiedy klikniemy "Auto"</li>
            <li>Ograniczono występowanie starych tymczasowych logów na stronie podczas ładowania wirnika</li>
            <li>Naprawiono błąd nieprzekazywania wybranego kąta przy przejściu z doboru do symulacji (co często kończyło się użyciem pierwszego kąta z listy i różnice wyników dobór vs. symulacja)</li>
            <li>Naprawiono defekt ignorowania zadanego kąta w doborze wentylatorów</li>
            <li>Zmieniono kryterium kąta łopaty w doborze jako domyślnie "Autodobór"</li>
            <li>Naprawiono defekt braku widocznej odpowiedzi serwera przy wybirze "Auto" dla ciśnienia w doborze (obecnie w doborze klasycznym nie można dobierać ciśnienia w autodoborze, ale przynajmniej otrzymamy informujący o tym komunikat)</li>
            <li>Dodano informację o czasie przeprowadzanego doboru (pod wynikami doboru)</li>
            <li>Zoptymalizowano ilość przesyłanych z serwera danych przy doborze około 6x (dzięki nie wysyłaniu nieużywanych charakterystyk innych niż ciśnienia)</li>
            <li>Naprawiono błąd rysowania tylko 1 lini (dla pierwszego wentylatora) charakterystyki w doborze</li>
            <li>Dodano interaktywny opis po najechaniu myszą na wykresie wentylatorów dobranych</li>
        </ul>
        <h3>4.1.0</h3>
        <ul>
            <li>Odfiltrowano co 10-tą krzywą dla wyników symulacji po stronie serwera zamiast klienta, oszczędzając transfer danych z ~1.7MB per symulacja do ~0.5MB per symulacja</li>
            <li>Włączono kompresję komunikacji z serwerem oszczędzając często potrzebę transferu danych przez sieć kilkukrotnie (np. wynik symulacji 500kB do 100kB; cała strona symulacji z wynikiem przy pierwszej wizycie: z ~3.6MB do ~1.7MB)</li>
            <li>Wprowadzono anulowanie żądania sieciowego (w przeglądarce) o symulację przy zmianie kryteriów, dzięki czemu przeglądarka nie pobiera do końca wyników "zleconych" symulacji przy szybkich zmianach kryteriów</li>
            <li>Wprowadzono anulowanie trwającego doboru, symulacji, doboru mechanicznego w przypadku zlecenia doboru z nowymi kryteriami (oznacza to, że obecnie serwer może obliczać jeden dobór jednocześnie i próba wykonania większej ilości zakończy się komunikatem błędu - albo anulowaniem trwającego doboru, albo odrzuceniem
                zlecenia nowego doboru). W przyszłości ograniczenie to będzie znacznie mniej restrykcyjne z ograniczonym wpływem pomiędzy użytkownikami
            </li>
            <li>Usprawniono obsługę błędów przez co mniej dobórów może się zakończyć niepowodzeniem z brakiem żadnego komunikatu</li>
            <li>Usunięto defekt powodujący pokazanie starych wyników doboru jeżeli nowy dobór zakończył się błędem serwera (w szcególności przeciażeniem)</li>
            <li>Ograniczono możliwość równoczesnego przetwarzania wgranych plików XLS z danymi do pojedycznego przetwarzania równocześnie, aby ograniczyć możliwość głębokiego przeciążenia serwera wpływającego na wszystkie funkcjonalności, kiedy następowały kolejne próby wgrywnia plików XLS podczzas gdy w tle serwer był już
                przeciążony jeszcze przetwarzaniem poprzednich (czasem wielu przetwarzań nałożonych równocześnie). W przyszłości dopracowane zostaną komunikaty błędu oraz obecny stan trwającego przetwarzania w tle
            </li>
            <li>Zwiększono limity czasu na otrzymanie wyniki doboru/symulacji z 8sek do kilkudziesięciu, aby zmniejszyć ryzyko błędu maksymalnego czasu oczekiwania przy większej ilości wirników i programie działającym na serwerze o niskiej mocy obliczeniowej</li>
            <li><strong>Planowane są dalsze prace nad wydajnością</strong>, w szczególności spowalniającym czynnikiem jest logowanie obliczeń i zwracanie ich wraz z wynikami oraz fakt, że w doborze serwer zwraca wszystkie charakterystyki dla każdej jednej opcji kątowej, jednak nie są one rysowane na wykresie (jedynie 1
                charakterystyka per typ wirnika)
            </li>
        </ul>
        <h3>4.0.0</h3>
        <ul>
            <li>Zunifikowano formularze doboru i symulacji do nowej wersji i przywrócono możliwość symulowania wybranego rezultatu doboru przyciskiem "Symuluj"</li>
            <li>Dodano kilka możliwych jednostek na nowym formularzu doboru (np. Psi, mmH2O)</li>
            <li>Dodano możliwość zadania przeszkód wlot i wylot na nowym formularzu doboru</li>
            <li>Dodano możliwość zadania szczeliny i obliczanie obudowy w doborze klasycznym, z nowego formularza doboru</li>
            <li>Usunięto z programu wystąpienia wielkości "Powierzchnia celki chłodni" (obecnie obliczenia i tak nie były użyteczne)</li>
            <li>Dodano możliwość ustalania obecności miękkiego startu (soft start) z nowego formularza doboru</li>
            <li>Zaimplementowano by wybór ksztaltu wlotu w nowym formularzu doboru faktycznie aplikował się do procesu doboru (wcześniej był pomijany)</li>
            <li>Poprawiono obliczanie ctg podczas obliczania A na tarczy (zamiast ctg, uzyskiwanego obecnie z "1/tan", był użyty arcus-tanges)</li>
            <li>Naprawiono defekt umieszczanie zawsze pozycji "1" w pliku DriveWorkos (obecnie powinna być osadzana właściwa pozycja)</li>
            <li>Wprowadzono mapowanie nazw profili podczas doboru z Optisel 1 pozwalając dobrać np. mechanikę (14) dla doboru (14SD) w Optisel1, typoszereg można znaleźć na stronie typoszeregów: "Mapowanie nazw profili Optisel 1 przy doborze mechanicznym"</li>
            <li>Dodano możliwość schowania formularza kryteriów doboru/symulacji. Przy symulacji prosto z doboru domyślnie kryteria zostają schowane.</li>
        </ul>

        <h3>3.5.1</h3>
        <ul>
            <li>Zupełnie nowa wersja doboru prototypowego - rozpoczęcie prac nad formularzem doboru dla klientów, udostępniony jako bardzo wczesna wersja demo, lecz bardzo niestabilna, gdyż prace nad fomularzem nadal trwają</li>
        </ul>

        <h3>3.5.0</h3>
        <ul>
            <li>Wprowadzono możliwość zadawania <strong>dyfuzora</strong> i obliczanie jego korekt w Symulacji</li>
            <li>Wprowadzono możliwość zadawania <strong>wiatru poprzecznego</strong> i obliczanie jego korekt w Symulacji</li>
            <li>Poprawiono plik DriveWorks, by poprawnie uzupełniać nakrętkę T-P (zamiast zdublowanej podkładki)</li>
            <li>Przeniesiono umiejscowienie pozycji wirnika w eksporcie DriveWorks do znacznie wcześniejszego wiersza</li>
            <li>Dodano wpływ prędkości przepływu na korekty ciśnienia akustycznego z przeszkody wlot/wylot (... * (prędkość przepływu / 10)^2)</li>
            <li>
                Wstępnie ukończono pierwszą wersję biblioteki komponentó do docelowego zadawania kryterió tj. wartości wraz z przełącznikiem jednostek i regułami zaokrągleń. Komponenty można przetestować na kontrolkach zadających dyfuzor i prędkość wiatru poprzecznego w Symulacji.
                <small>
                    <ul>
                        <li>Kontrolki posiadają mechanizm reformatowania wpisanej wartości do oczekiwanej ilości miejsc po przecinku</li>
                        <li>Reformatowanie następuje po opuszczeniu "focusu" na polu (kliknięciu obok), lub zaprzestaniu pisania przez 1.5 sekundy</li>
                        <li>Każda wielkosć ma ustawialną bazową ilość miejsc po przecink (np. dyfuzor, wiatr - jedną)</li>
                        <li>Każda zdefiniowana jednostka, posiada zdefiniowaną ilość miejsc po przecinku w stosunku do bazowej. Przykładowo: Stopy o jeden więcej niż metr. Czyli wysokość dyfuzora, przy bazowej 1, w stopach będzie mieć dokładność 2 miejsc po przecinku</li>
                        <li>Istnieją ograniczenia minimum i maksimum aplikowane automatycznie po opuszczeniu pola (korekta automatyczna - a w przyszłości komunikat błędu</li>
                        <li>Z dośćskomplikowanych przyczyn technicznych związanych z ustalonym ograniczeniem min-max, ograniczenia min-max podczas podawania w innej jednostce niż domyślna są ustalane na "te same ograniczenia, ale po sformatowaniu do oczekiwanej ilości miejsc po przecinku". Oznacza to, że ograniczenia min-max w
                            innych jednostkach niż domyślna mogą być (szczególnie w przypadku skomplikowanych, nieliniowych, lub nie bazujących na mnożeniu "x10/x100 itp." nieco inne niż przy jednostce podstawowej. Jest to spowodwane tym, że wartość autoskorygowana po sformatowaniu do miejsc po przecinku mogła by znowu
                            przekroczyć dozwolony zakres w jednostce bazowej, co wywołało by nieskończoną pętlę. Przy poprawnym ustawieniu ilości miejcsc po przecinku, błedy te nie powinny przekroczyć 1% poza zakres, przy czym i tak algorytmy po stronie serwera będą się opierać na już bardzo dokładnym ograniczaniu zakresów, bez
                            błędów.
                        </li>
                        <li>Tymczasowo wpisanie tekstu zamiast liczby wyświetla pod polem komunikat "incorrect" i system zachowuje się tak, jakby liczba nie była wpisana wcale (a nie jakby było wpisane 0)</li>
                        <li>Kontrolki wspierają resetowanie do stanu domyślnego, ale na stronie Symulacji nie jest ono jeszcze użyte</li>
                    </ul>
                </small>
            </li>
        </ul>

        <h3>3.4.0</h3>
        <ul>
            <li>Wprowadzono korekty ciśnienia i hałasu ze względu na przeszkodę na wylocie (możliwą do zadania w Symulacja)</li>
        </ul>

        <h3>3.3.2</h3>
        <ul>
            <li>Wprowadzono nowe wartości tabel korekt dla ciśnienia i hałasu do przeszkody na wlocie</li>
            <li>Osadzono w programie (Typoszeregi) tabele współczynników dla wzorów korekt ciśnienia i hałasu dla przeszkód na wylocie (są osadzone i widoczne w typoszeregach, ale jeszcze nie używane)</li>
        </ul>

        <h3>3.3.1</h3>
        <ul>
            <li>Wprowadzono rozróżnienie pomiędzy nazwą łopaty dla BOM oraz "techniczną" nazwą łopaty dla DriveWorks</li>
            <li>W pliku eksportu do DriveWorks dodano normy śrub/podkładek/nakrętek</li>
        </ul>
        <h3>3.3.0</h3>
        <ul>
            <li>Wprowadzono <strong>obliczenia korekty ciśnienia przez przeszkodę na wlocie</strong> opartą o aproksymację ze stałych zdefiniowanych formuł wagowo z L/D + wzór z prędkoci przepływu i gęstości</li>
            <li>Wprowadzono <strong>obliczenia korekty poziomu ciśnienia akustycznego przez przeszkodę na wlocie</strong> opartą o aproksymację ze starył zdefiniowanych formuł wagowo z L/D</li>
            <li>Zwiększono ilość logów obliczeniowych dotyczących korekt ciśnienia i SPL</li>
            <li>Wyprowadzono osadzone typoszeregi formuł dla korekt dla przeszkód na stronę Typoszeregi</li>
            <li>Wprowadzono ograniczenia parametru L/D dla korekt ze względu na przeszkodę - musi on zawierać się w zakresie zdefiniowanych na stałe formuł, inaczej obliczenia nie zostaną wykonane</li>
            <li>Dodano możliwość <strong>zadania przeszkody wlotowej</strong> w symulacji</li>
        </ul>
        <h3>3.2.0</h3>
        <ul>
            <li>Usunięto dodatkową spacjęz nazwy łopaty (efektywnie także z nazwy łopaty w eksporcie DriveWorks)</li>
            <li>Usunięto nazwę formy z nazwy łopaty (efektycznie wakże z nazwy łopaty w eksporcie DriveWorks)</li>
            <li>Obliczając nazwę tarczy zamieniono grubość ze średnicą tj. "typ/średnica/Ggrubość/..." na "typ/Ggrubość/średnica..." (efektywnie także w eksporcie Driveworks)</li>
            <li>Dodano brakujące tłumaczenia dotyczące straty ciśnienia przez kształt wlotu oraz wzrostu ciśneinia akustycznego przez kształt wlotu</li>
            <li>Wprowadzono funkcjonalność usuwania wszystkich lokalnie nadpisanych na serwerze tłumaczen + objaśnienia na stronie tłumaczeń</li>
            <li>Wprowadzono funkcjonalność eksportu zdefiniowanych tłumaczeń w celu umieszcenia ich na stałe w kodzie aplikacji przy wydawaniu nowej wersji</li>
            <li>Zmieniono współczynnik "a" korekty ciśnienia akustycznego dla kształtu wlotu "Promień R/D=0,05" z 0.087 na 0.0087</li>
            <li>Przeniesiono stronę Typoszeregi z menu Mechanika do menu Administracja</li>
            <li>Na stronie typoszeregi dodano listing wszystkich parametrów korekt dotyczących możliwych kształtów wlotu</li>
            <li>Ograniczono możliwość wykreślania lini i punktów wykresów poza właściwy obszar wykresu</li>
        </ul>

        <h3>3.1.0</h3>
        <ul>
            <li>Zmieniono sposó budowania nazwy wirnika, usuwając z niej nazwę profilu (przy czym pośrednio w typowej sytuacji nadal ona tam będzie, ze względu na to, iż typowa nazwa formy zawiera nazwę profiu)</li>
            <li>Usunięto przypadkową, developerską blokadę na wymuszanie profilu "(14DEM)" dla żądań ze starego programu doboru, przez co wszelkie dobory z Optisel 1 kończyły się mechanicznymi danymi demo lub brakiem doboru</li>
            <li>Dodanio nowy wpis standardu nazewnictwa tarcz: Dla bazowej nazwy tarczy "T100-S50A" jest to norma "007-xxxx-05"</li>
            <li>Poprawiono współczynnik "ki" korygowania ciśneinia dla wlotu "Stożek H/D=0.1" z 0.026 na 0.26</li>
            <li>Zmieniono sposób prezentacji korekty ciśnienia z "zysk ciśnienia [ujemny]" na "Strata ciśnienia z kształtu wlotu" [i wartość dodatnia przy stracie]</li>
            <li>Zmieniono nomenklaturę "Wzrost hałasu z kształtu wlotu" na "Przyrost hałasu z kształtu wlotu"</li>
            <li>Zmieniono domyślne kryteria na ekranie prototypowego doboru: Wydajność 234 m3/s, Ciśnienie 123 Pa, Gęstość 1.155 kg/m3, Szczelina 0.5%, Średnica 4877mm, Prędkość obrotowa 202 obr/min</li>
            <li>Dodano nowo wyliczony wykres Eta/Fi na stronę przeglądu wirnika</li>
            <li>Usunięto oznaczenie "P: " z punktu wskazanego myszą na wykresie charakterystyki wentylatora (nie miało ono sensu)</li>
            <li>Wprowadzono podpowiadanie wartości wielu punktów w bliskości kursora myszy na wykresach</li>
        </ul>

        <h3>3.0.0</h3>
        <ul>
            <li>Zaimplementowano <strong>nowy mechanizm korekt ciśnienia ze względu na kształt wlotu (dostępne w Symulacji)</strong></li>
            <li>Zaimplementowano <strong>nowy mechanizm korekt hałasu ze względu na kształt wlotu (dostępne w Symulacji)</strong></li>
            <li>Usunięto stary mechanizm przykładowych czynników korygujących ciśnienie</li>
            <li>Wyprowadzono na kartę wentylatora <strong>wartość nieskorygowanego ciśnienia statycznego</strong></li>
            <li>Osadzono (tymczasowo na stałe) w programie listę dostępnych kształtów wlotowych wraz z czynnikami dla formuł korygujących ciśnienie i hałas</li>
            <li><strong>Całkowicie porzucono wsparcie importu wirników z XLS niewielomianowych</strong>, usunięto także przykładowe pliki</li>
            <li>Dostosowano przykłady symulacji na stronie Portal, by wskazywały na nowe przykłądowe wielomianowe wirniki demo</li>
            <li>Zmieniono pełną nomenklaturę w okół arkusza Eta/Fi (sprawności) na Lambda/Fi, ponieważ od migracji na wirniki wielomianowe w arkuszu tym nie są sprawności a współczynniki do jej obliczania</li>
            <li>Naprawiono defekt związany z niezaokrąglonymi kątami w symulacji przez co po przejściu z przykładów z Portalu pole dotyczące kąta mimo jego ustalenia z automatu nadal miało postać "Wybierz..."</li>
            <li>Zmiany Eta na Lambda psują kompatybilność danych wstecz w bazie danych, ze względu na optymalizację kosztów wsteczna kompatybilność nie będzie zapewniana i <strong>wdrożenie tej wersji wymaga wyczyszczenia bazy danych</strong></li>
            <li>Zmieniono domyślne początkowe <strong>kryteria szukania w doborze prototypowym</strong> (wydajność z 8m/s na 40m/s i średnica z 2222mm na 3000mm), ponieważ żaden wariant nowych danych demo wirnika wielomianowego nie był dobierany na dawnych kryteriach</li>
            <li>Dodano klilkanaście brakujących tłumaczeń polskich (pozostawiono nieco tłumaczen angielskich z brakami)</li>
            <li>Dodano <strong>sekcję obliczonych parametrów dotyczących korekt</strong> na karcie wentylatora</li>
            <li>Wzbogacono wykres SPL z symulacji o linię przerywaną reprezentującą nieskorygowane ciśnienie akustyczne</li>
            <li>Dodano szereg tłumaczeń PL i EN dotyczących korekt</li>
        </ul>

        <h3>2.21.0</h3>
        <ul>
            <li>Rozwinięto optymalizację wyboru najlepszego rozwiazania mechanicznego nie tylko o "fakt" przeciążenia sprzęgła, ale także o wybór rozwiązania z niższym Kryt1 MoM jeżeli i tak musi być przeciążone (zapewnia to "mniej przeciążony" dobór dla Optisel 1 - tj z największą ilością śrub)</li>
            <li>Rozbudowano API dla Optisel 1 o zwracanie informacji czy zostały osiąganięte niedozwolone/przeciążone wartości dla sprzęgła, rezonansów i obciążenia czopu w celu prezentacji w Optisel 1</li>
        </ul>
        <h3>2.20.0</h3>
        <ul>
            <li>Zmodyfikowano wzór na "Ciśnienie dynamiczne w punkcie pracy" wyrzucając człony związane z powierzchnią celki chłodni</li>
        </ul>
        <h3>2.19.0</h3>
        <ul>
            <li>Wprowadzono zabezpieczenie, by operacja mechaniczna (złożenie wirnika lub wyświetlanie tabeli danych) nie kończyłą się niczym/błędem, jeżeli jeden z rekordów nie posiada wymaganych kolumn. Zamiast tego rekord taki będzie ignorowany i nie wyświetlany w tabeli lub złożeniu wirnika (zazwyczaj oznacza to, że dane
                mechaniczne są starego typu - z brakującymi kolumnami - i należy ponownie wgrać dane mechaniczne)
            </li>
            <li>Poprawiono wzór obliczania "Moment od siły ciężkości Mm" dla opciążeń czopa dodając brakujące podzielenie jednego z członów wzoru przez 1000 (długości łopaty)</li>
            <li>We wzorze na "Moc użyteczna w punkcie pracy" zmieniono ciśnienei statyczne na ciśnienie całkowite</li>
            <li>Prototypowo, w celu weryfikacji konceptu <strong>Zmieniono sposób obliczania krzywej sprawności, zawsze zakładając, że w arkuszu "Eta" jest tak na prawdę "Lambda" i wprowadzono nowe wzoru. Wirniki nie-wielomianowe także będą zaafektowane i wirniki niewiolomiaonowe najpewniej nie działają w tym momencie
                poprawnie</strong>. Po weryfikacji i zrozumieniu obliczenia zostanie przywróćona obsługa niewielomianocyh ze sprawności zadanej bezpośrednio w XLS (nieobliczanej) jednak na ten moment zakłądana jest zawsze "lambda"
            </li>
        </ul>

        <h3>2.18.0</h3>
        <ul>
            <li>Wprowadzono interaktywne wskazanie konkretnego punktu myszą na większości wykresów, wraz z informacją o kącie</li>
            <li>Naprawiono defekt niepoprawnej aproksymacji wag dla wialomianiu wirnika wielomianowego (zamienione wagi punktów z których aproksymuje)</li>
        </ul>

        <h3>2.17.1</h3>
        <ul>
            <li>Poprawiono dwa elementy wzoru na obciążenie czop-płat Von-Mises dla "Moment od siły ciężkości (od siły Fm) [N*m]" - Zamieniono Rp na środek cięzkości, a środek ciężkości na długość łopaty z formy</li>
            <li>Zmieniono wzór "A na tarczy" zmieniając wartość jednego z komponentów kandydujących do maksimum: "Minimalna odległość podpory od osi wirnika dla sprzęgła" w wypadku wirników z możliwośćią kolizji podpora-śruba(ST), zmieniając średnicę gwintu zestawu śrubowego TO na ST oraz dodając jeszcze jeden skłądnik:
                wystawanie czopa z podpory
            </li>
            <li>Porzucono (usunięto interpretację) stare kolumny formowań łopaty: "Masa łopaty w wykonaniu standard [kg]", "Masa łopaty w wykonaniue AS/SG [kg]", "Częstotliwość drgań własnych (wyk. standard) [Hz]", "Częstotliwość drgań własnych (wyk. AS/SG) [Hz]" (gdyż ich zadanie jest teraz realizowane przez kolumny W10*)</li>
            <li>Wymuszono by wartości mas i częstotliwości standardowych tj. W10_* od teraz były obowiązkowe przy imporcie XLS formowań (wcześniej wszystkie W* były opcjonalne)</li>
            <li>Usunięto nieinterpretowane już kolumny z demo XLS dla formowań łopat</li>
        </ul>

        <h3>2.17.0</h3>
        <ul>
            <li>Dodano interpretację <strong>XLS "estymat naprężeń czop-płat"</strong></li>
            <li>Rozbudowano interpretację <strong>XLS form łopat o "Kryterium czop-płat [0 lub 1]"</strong> określający czy wirnika z łopatami na podstawie formy podlegją wzmocnieniom i ograniczeniom naprężen czop-płat</li>
            <li>Dodano <strong>interpretację kilkunastu kolumn XLS form łopat dotyczących nowych mas i częstotliwości drgań łopat</strong> w zależności od zastosowanego wzmienienia (np. "W10_S [Hz]", "W10_S [kg]" itp.)</li>
            <li>Zaktualizowano dane demo dla form łopat uzupełniając je o nowe masy i częstotliwości zgodnie z regułą, że każdy kolejny poziom wzmocnienia/masy to 1,5* masa/częstotliwość poprzedniego poziomu</li>
            <li>Wygenerowano dane demo estymat naprężeń czop-płat dla form demo w zakresach ilości łopat od 2 do 6, prędkości końca łopaty od 0.5 do 100 i kąta łopatowego od 4 do 32</li>
            <li>Wprowadzono <strong>obliczanie estymowanego obciążenia czop płat</strong> metodą uśredniania wagowo "owdrotnością odległości" po kątcie/prędkości do 4 innych puntków ze znanymi estymatami</li>
            <li>Zreorganizowano obliczenia masy i częstotliwości drgań łopaty ustalając masę i częśtotliwość na wcześniejszym etapie, tak by późniejsze moduły nie musiały analizować której wartości mas/częstotliwości z formowania użyć</li>
            <li>Dodano wypisywanie strategii wzmacniania łopaty (oraz czy AS/SG) w sekcji przeglądu złożenia mechanicznego (w kafelku łopaty)</li>
            <li>Naprawiono defekt algorytmu wyszukiwania grubości tarczy, gdzie przy uwzględnianiu danych demo, byłyby wyszukiwane tylko dane damo, a nie demo oraz załadowane</li>
        </ul>

        <h3>2.16.2</h3>
        <ul>
            <li>Poprawiono eksport DriveWorks płaskotekstowy, gdzie zamiast ilości łopat była powtórzona średnica wirnika</li>
            <li>Dodano nowy wpis typoszeregu standardu nazewnicwwa tarcz: Dla sprzęgla typu S50A i czopu 100 nazwą bazową będzie: T100-S50A</li>
            <li>Wprowadzono nowy parametr XLS strategii montażu łopat "Zapas bezpieczeństwa kolizji podpór" i użyto go przy obliczaniu minimalnego A na tarczy (detekcja kolizji)</li>
        </ul>

        <h3>2.16.1</h3>
        <ul>
            <li>Zmieniono obliczeanie 'A minimalne' dla kolizji na tarczy tymczasowo usuwając współczynnik bezpieczeństwa 4 ('ctg α/2' * (szerokość podpory / 2 + 4 + ...) (w przyszłości do zastąpienia wartością z XLS)</li>
        </ul>

        <h3>2.16.0</h3>
        <ul>
            <li>Poprawiono wzór "Odległość łba śruby od osi (+1mm)", który dokonywał niepotrzebnego przeliczenia stopni na radiany</li>
            <li>Zmieniono sposób obliczania kolizji na tarczy wykorzystując "Minimalna odległość podpory od osi wirnika dla sprzęgła"</li>
            <li>Wprowadzono algorytm weryfikacji średnic/długości łopaty/'a' na tarczy ("L weryfikacja")</li>
            <li>Wprowadzono mechanizm, który przy nie złożeniu żadnych wirników na "Złożenie wirnika" zaprezentuje pełny log obliczeniowy jednego dowolnego ze złożeń, by znacznie zwiększyć możliwości analizy błędów powodujących niemożność uzyskania żadnych złożeń (prezentacja logów wszystkich rozwiązań byłaby problematyczna ze
                względu na ich ilość)
            </li>
            <li>Wprowadzono koncept wielkości pomiarowych i jednostek dla nich, określając jednostki dla większości obliczanych wielkości</li>
            <li>Dodano <strong>możliwość ignorowania danych demo</strong></li>
            <li>Wprowadzono podstawowe <strong>grupowanie wyników różniących się tylko ilością śrub mocujących</strong> na stronie złożenie wirnika</li>
            <li>Zmieniono filtr profilu łopaty na złożenie wirnika z tekstowego na wybieralny, ładowany z listy profili łopatowych na serwerze (profili z danych mechanicznych - nie przepływowych)</li>
        </ul>

        <h3>2.15.0</h3>
        <ul>
            <li>Usunietog stałe G16 z typoszeregów dotyczących nazewnictw tarcz i wprowadzono obliczoną grubość do nazwy tarczy pomiędzy średnicę a "a na tarczy" (z literką G np. DEMOT33-50/760/G20/185,5/4)</li>
            <li>Rozszerzono typoszereg "Standard bazowego nazewnictwa tarcz" o obsługę sprzegła S50 (bez literki A na końcu) dla czopów 50 i 65. Bazowe nazwy będą odpowiednio: T50A dla S50 czop 50, oraz T65A dla S50 czop 65</li>
            <li>Wprowadzono koncept dozwalania lub nie na obecność kołpaka w zależności od pozycji wirnika - kołpak dozwolony tylko dla pozycji 1 i 5. W innym wypadku kołpak nie będzie stosowany nawet jeżeli formowanie wymaga stosowania</li>
            <li>Wprowadzono algorytm odgadywania pozycji wirnika na podstawie kryteriów kierunku przepływu wlot/wylot, orientacji pionowa/pozioma - który docelowo będzie ustalał pozycję na podstawie kryteriów z Optisel 1</li>
            <li>Rozszerzono XLS wymogów grubości tarcz o kolumnę "Średnica Czopa [mm]", wraz z interpretacją podczas wyszukiwania grubości tarczy oraz uzupełnieniem XLS demonstracyjnego dla grubości tarcz</li>
        </ul>

        <h3>2.14.1</h3>
        <ul>
            <li>Poprawiono wzór na obciążenie Von Mises (średnica czopa do potęgi trzeciej, a nie jak wcześniej do drugiej)</li>
            <li>Rozwiązano defekt niemożności zadawania prędkości przez prędkość końca łopaty na ekranie Złożenie wirnika</li>
        </ul>

        <h3>2.14.0</h3>
        <ul>
            <li>Dodano <strong>stronę informacyjną na temat wirnika, z charakterystykami względem "Fi"</strong>, dostępną z poziomu listy wirników (przycisk "Przegląd")</li>
            <li>Wprowadzono <strong>ograniczenie dopuszczalnych średnic</strong> na poziomie nastawialnego wirnika (minimalna i maksymalna średnica wirnika). Symulacja i dobór zostaną zablokowane na etapie skalowania wirnika na średnicę, jeżeli zadana średnica przepływowa wykroczy po za zakres (podobnie do już istniejącego
                ograniczenia na poziomie profili). Dla już wgranych wirników będzie przyjęte domyslne 0 mm - 30 000 mm
            </li>
            <li>Zaimplementowano import <strong>arkusza "Dane_o_ilosci_lopat"</strong> z XLS wirników opisanych wielomianami</li>
            <li>Naprawiono problem niepoprawnego oznaczania "kafelka" Czop dla rozwiązań złożeń mechanicznych z symulacją (Pa vs. MPa)</li>
            <li>Zabezpieczono prototypowy dobór elastyczny przed próbą autodoboru średnicy i RPM, w taki sposób, że krzywa ciśnienia wentylatora spada poniżej zera, co powodowało błąd wyświetlania całej strony. Sytuacja będzie produkować komunikat typu "UWAGA! Niepoparwne obliczenia dla próbnej krzywej #100 celującej w Fi
                0.22694874837707096 oraz Psi -0.006676198315944237 - niemożliwe obliczenie średnicy"
            </li>
            <li>Rozszerzono interpretację XLS wirnika (niewielomianowy) o kolumny "Minimalna dopuszczalna średnica [mm]" oraz "Maksymalna dopuszczalna średnica [mm]" a także ich interpretację (arkusz Informacje)</li>
            <li>Rozbudowano wbudowane przykłady XLS demo (niewielomianowe) o zakresy dopuszczalnych średnic</li>
            <li>Wprowadzone wymuszone ładowanie <strong>osadzonego pliku "demo-wirniki-wielomianowe-skalowalne.xlsx"</strong> przy starcie aplikacji, jako wirniki demo, dla utrzymania pewności, że jego format jest zawsze prawidłowy. Tworzy to wirniki przepływowe demonstracyjne WIELOM-3-(14DEM), WIELOM-4-(14DEM),
                WIELOM-5-(14DEM)
            </li>
            <li>Sposób <strong>obliczania H2 sprzęgła</strong> został zmieniony z zaokrąglania w góre do najbliższego 5, na zaokrąglanie do najbliższego 5 (niekoniecznie w górę)</li>
            <li>Rozwiązano problemy nierówno rozłożonych lini siatki w tle wykresów (tymczasowo rezygnując z grubszych/cieńszych linii, jendak znacznie poprawiając architekturę wykresów)</li>
            <li>Naprawiono problem możliwości dobrania zbyt długiej śruby zabezpieczającej (mimo prawidłowych logów obliczeniowych faktycznego warunku na śrubę nie-dłuższą-niż brakowało)</li>
            <li>Dodano <strong>interaktywne podziałki na wykresach</strong> pokazujące obecnie wskazaną myszą wartość osi X i Y</li>
            <li><u>Uwaga, import wirników wielomianowych w tym wydaniu ma raczej charakter eksperymentalny, do czasu uzyskania informacji jak wyliczać nowe sprawności i hałasy, gdyż zmieniły się wielkości fizyczne w tych arkuszach, a nadal traktowane są po staremu.</u></li>
            <li>Rozbudowano pełną nazwę łopaty by zawierała nazwę formy, co skutkuje <strong>rozszerzeniem pełnej nazwy łopaty w eksporcie do DriveWorks o nazwę formy</strong> pomiędzy nazwą skróconą łopaty a długością L</li>
        </ul>

        <h3>2.13.2</h3>
        <ul>
            <li>Naprawiono interpretację kolummny "Maksymalne naprężenie zredukowane Von-Misesa [MPa]", która była porównywana, jak gdyby była w Pa, a nie w MPa</li>
            <li>Rozwiązano problem pokazywania uprzednio dobranych wyników (dot. starych kryteriów), gdy użytkownik na żłożeniu wirnika zada zarówno RPM jak i prędkość końca łopaty (wprowadzono także widoczny komunikat)</li>
            <li>Rozwiązano problem pokazywania wyników dotyczących nieaktualnych kryteriów na złożeniu wirnika, jeżeli losowość sieci/obciążenia serwera zwróci wynik dotyczący poprzednich kryteriów później niż wynik dotyczący nowych kryteriów ("stare, później dostarczone wyniki, nadpisywały nowe")</li>
        </ul>

        <h3>2.13.1</h3>
        <ul>
            <li>Zwiększono masy dla nakrętek M10 i M12 w normie DIN 934</li>
            <li>Usunięto jedną dodatkową "ósemkę" w nazwie nakrętki sprzęgło-tarcza, która zawsze była dodawana do tej nazwy niezależnie od materiału, oprócz właściwej klasy (czyli "8 8" - ze spacją)</li>
            <li>Wprowadzono elastyczne nazewnictwo śrub i nakrętek z wariantami klasa+materiał oraz materiał+klasa (np. 8.8 HDG vs A2 70), szczegóły na stronie Typoszeregi</li>
            <li>Poprawiono wzór na "Siła od mocy silnika Fp" w obliczeniach obciążenia czopa (dodano dzielenie przez liczbę łopat)</li>
            <li>Dodano log obliczeniowy prezentujący obciążenie czopa w MPa oprócz Pa a także zmieniono domyślną prezentację obciążenia czopa w wyniku symulacji mechaniki z Pa na MPa</li>
        </ul>

        <h3>2.13.0</h3>
        <ul>
            <li>Poprawiono opisy w selektorze pozycji wirnika na stronie złożenie wirnika (Pionowo vs Poziomo)</li>
            <li>Naprawiono defekt ładowania kolumny "Podkładka zabezpieczająca PZK", który powodował niemożność doborów konfiguracji z podkładkami PZK</li>
            <li>Naprawiono błąd walidacji obciążenia czopa (omyłkowo pozostawione były dane testowe zawsze powodujące przeciążenie)</li>
            <li>Dodano mały kafelek prezentujący rezultat walidacji obciążenia czopa na stronie złożenia wirnika (dla wirników bez maksymalnej wartości będzie zawsze zaliczony)</li>
            <li>Wprowadzono w "złożeniu wirnika" nową opcję "Rygor audytu mechaniki", która pozwala wybrać roluźniony rygor, dobierający rozwiązania z przeciążonym czopem, co pozwala przejrzeć ich log obliczeniowy</li>
            <li>Rozszerzono dane XLS demo o kolumnę z nazwami podkładek PZK</li>
            <li>Poprawiono notatki do wydania 2.12.1 gdzie pomylony był warunek "z mniejszą"/"z większą" ilością śrub mocujących (wyszarzanie wyników na Złożenie wirnika)</li>
            <li>Usunięto z loga obliczeniowego stare błędne informacje o priorytetach cz. złącznich do kołpaka (np. priorytet 80 czy 56)</li>
            <li>Wprowadzono bardzo wczesną prototypową obsługę załadowania wirników (ze skalowalną geometrią) z pliku opisującego wielomianami (ubogie logowanie + brak ładowania ograniczeń średnicy dla poszczególnych ilości łopat)</li>
            <li>Dodano przykładowy plik demo XLS do pobrania, dla prototypowego ładowania wirników za pomocą pliku wielomianowego</li>
            <li>Osadzono w programie katalog nakrętek DIN 985 i zmieniono nakrętkę śruby TP na DIN 985</li>
        </ul>


        <h3>2.12.1</h3>
        <ul>
            <li>Zaimplementowano logikę <strong>pozycji wirnika</strong> i przełączanie wzorów z nich wynikająch. Możliwe pozycje wylistowano także z zależnościami na stronie Typoszeregi</li>
            <li>Dodano wybor pozycji wirnika na stronie złożenia wirnika</li>
            <li>Zaimplementowano wpływ kierunku przepływu wzlgędem sprzęgła (na wlocie, na wylocie) na nazwę użytej podkładki tj. PZU lub PZK</li>
            <li>Wprowadzono ustawienie systemowe 'Współczynnik bezpieczeństwa sprzęgła (falownik/softstart)' i podczas obliczania Kryt 1 MoM moment dopuszczalny będzie pomnażany przez tę wartość, jeżeli wybrano pracę z falownikiem/softstartem. Domyślna wartość parametru to 1.6</li>
            <li>Wprowadzono mechanizm zaokrąglania rozmiaru tarczy do bliskich wartości wymiaru blach, z których produkowane są tarcze. Zestaw zaokrągleń zaprezentowano na stronie Typoszeregi.</li>
            <li>Wprowadzono <strong>obliczenia obciążenia czopa</strong> i prezentację wyników w sekcji mechaniki symulacji</li>
            <li>Ograniczono rozważanie konsturkcji mechanicznych, dla których obciążenie czopa jest za duże, w przypadku, gdy dane formowania łopaty zawierają ograniczenie maksymalne (kolumna jest opcjonalna i nie ma sztywnego zakodowania na profile 5 i 7)</li>
            <li>Wdrożono algorytm <strong>doboru ilości śrub mocujących</strong> tzn. dla formy/sprzęgła testowanych jest kilka rozwiązań z możliwymi ilościami śrub. Każda opcja ilościowa śrub jest widoczna w złożeniach jako osobne rozwiązanie</li>
            <li>Wyszarzono złożenia mechaniczne, dla których istnieje inne złożenie z tych samych komponentów, spełniające kryteria obciażenia, ale z większą ilością śrub sprzęgła (komunikat <i>"Rozwiązanie nieistotne, istnieje optymalniejsze z podobnych komponentów spełniające kryteria obciążeń (np. mniejsza ilość śrub
                mocujących)."</i>). Dla jasności ma to miejsce tylko w przypadku symulacji mechanicznej (zadania mocy itp.).
            </li>
            <li>Na skrótowej prezentacji złożenia mechanicznego, zamiast samej nazwy sprzęgło, dodano ilość śrub mocujących np. <i>Sprzęgło (4 x śr. TS)</i> zamiast po prostu <i>Sprzęgło</i></li>
            <li>Wprowadzono <strong>możliwość zarządzania wartościami priorytetów części</strong> (kolejności) na listę materiałową za pomocą ustawień administracyjnych</li>
            <li>Wprowadzono <strong>mechanizm scalania pozycji na liście materiałowej</strong>, jeżeli są identyczne pod względem wszystkich parametrów (oprócz oczywiście ilości sztuk) (scalone pozycje posiadają w tagu scalone poprzednie tagi i nowy priorytet np. TO-30TO(K)-30-30)</li>
            <li>Wprowadzono możliwość obserwacji niescalonej listy materiałowej (link "pokaż niescalone" w górnym prawym rogu tabeli materiałów)</li>
            <li>Zmieniono kolumnę manufakturyzacji sprzęgieł "Skok gwintu śruby ST [mm]" na "Skok gwintu śruby zabezpieczającej [mm]" i zaktualizowano pliki demo</li>
            <li>Dodano interpretację kolumny "Wysokość do nakiełka wału + skok gwintu śruby zabezpieczającej [mm]" w manufakturyzacji sprzęgiel + zaktualizowano dane demo</li>
            <li>Zwiększono minimalną długość śruby zabezpieczającej o "Wysokość do nakiełka wału + skok gwintu śruby zabezpieczającej [mm]"</li>
            <li>Naprawiono błąd braku przycisku generowania pliku DriveWorks w złożeniach mechanicznych, które były mechaniczne symulowane (czyli, gdy podano moc/rpm)</li>
            <li>Zmieniono formatowanie liczb w liście materiałowej eksportowanej do XLS na "do 3 miejsc po przecinku". Formatowanie odbywa się regułą formatowania komórki XLS, jednak liczba jest umieszczana w skoroszycie nadal w pełnej dokładności</li>
            <li>Zrezygnowany z kolumny "Moment dopuszczalny [Nm]" oraz "Ilość śrub mocujących sprzęgło [szt]" manufakturyzacji sprzęgieł ze względu na nowy algorytm zmiennej ilości śrub mocujących</li>
            <li>Dodano interpretację kolumn manufakturyzacji sprzęgieł: "Minimalna ilość śrub mocujących sprzęgło [szt]", "Maksymalna ilość śrub mocujących sprzęgło [szt]", "Skok ilości śrub [szt]", "Moment dopuszczalny na 1 śrubę [Ms]"</li>
            <li>Dodano interpretację kolumny form łopat "Położenie środka ciężkości [-]" i uzupełniono na 0,5 w danych demo</li>
            <li>Dodano interpretację kolumny form łopat "Maksymalne naprężenie zredukowane Von-Misesa [MPa]", która jest opcjonalna, i jeżeli uzupełniono, będzie walidowane ograniczenie obliczonego obciążenia czopu (Von Mises)</li>
            <li>Dodano interpretację kolumny form łopat "Długość wkładki czopa [mm]" na potrzeby obliczeń obciążeń czopa</li>
            <li>Dodano interpretację kolumny manufakturyzacji sprzęgieł: "Podkładka zabezpieczająca PZK"</li>
            <li>Przeniesiono pozostałe parametry mechancizne podczas symulacji z ekranu przepływu na ekran mechaniki</li>
            <li>Zmieniono twarde ograniczenie maksymalnej wielkości wirnika na 30000mm</li>
            <li>Rozszerzono API dla Optisel 1, by zawsze zwracać log obliczeniowy, niezależnie czy dobór był udany czy nieudany</li>
        </ul>

        <h3>v2.11.0</h3>
        <ul>
            <li>Wprowadzono pierwszą, wstępną wersję eksportu parametrów mechanicznych do DriveWorks przez generowanie XLS (przycisk "Pobierz parametry DriveWorks" na dole szczegółow złożenia)</li>
            <li>Przeniesiono obliczanie szerokości wpustu i otworu z wpustem z symulacji mechanicznej, do etapu obliczania sprzęgła</li>
            <li>Wprowadzono obliczanie parametry H2 sprzęgła (1/3 długości wałka zaokrąglona w górę co 5)</li>
        </ul>

        <h3>v2.10.0</h3>
        <ul>
            <li>Zmieniono kolejność priorytetów w obrębie elementów zwiazanych ze śrubą sprzęgło-tarcza</li>
            <li>Dodano wczytywanie kolumny "Material rzeczywisty podpory" z XLS strategii montażu łopat, a dla już istniejących wcześniej przyjęto domyślne HDG (należy wgrać ponownie dane)</li>
            <li>Zmienino ustalanie materiału dla podpory z wybranego przez użytkownika materiału konstrukcyjnego na "Materiał rzeczywisty podpory" ze strategii montażu łopaty</li>
            <li>Usunięto z loga obliczeniowego wzmianki o typoszeregu (osadzonego w programie oryginalnie) montażu łopat, ponieważ nic z niego nie jest już pobierane</li>
            <li>Zmieniono normę śruby zabezpieczającej (normę do tablicy materiałowej, nie fizycznie) na "DIN 931 / DIN 933" niezależnie od tego z jakiej śruby norma zostanie dobrana</li>
            <li>Ulepszono silnik przeszukujący wgrane dane frazą tekstową, co pozwala szukać za pomocą wielu słów w kontekście całego wiersza np. "14D 4877 130"</li>
            <li>Zmieniono ilość rezultatów na stronie wyszukiwania części mechanicznych na 1000 (wcześniej 50)</li>
            <li>Dodano wczytywanie kolumny "Skok gwintu śruby ST [mm]" z XLS manufakturyzacji sprzęgieł (Rozszerzono osadzone arkusze demo o przykładowe skoki gwintów ST)</li>
            <li>Zmieniono algorytm doboru śruby zabezpieczającej przez wyliczenie maksymalnej długości (kolizja z nawierceniem wałka), minimalnej długości (złapanie co najmniej 6-cioma skokami gwintu) i szukanie najdłuższej w tym zakresie (wpierw DIN 931, przy braku DIN 933)</li>
            <li>Wprowadzono do typoszeregów podkladek wymiary wewnętrzne podkładek</li>
            <li>Zmieniono oznaczenia podkładek DIN z "Podkładka X" gdzie X było wielkością M podkładki, na X będące wymiarem wewnętrznym podkładki. Nie zmiania to zachowania podkł. zabezpieczających i podkładek Nord-Lock, gdyż ich nazwy są wgrywane.</li>
            <li>Zmieniono strukturę XLS wymogów grubości tarcz na trzy pierwsze kolumny kryteriów szukania: "Numer ogólny formy", "Profil łopaty", "Ilość łopat", rezygnując z nazwy formy. Zaktualizowano przykładowe dane demo</li>
            <li>Zmieniono sposób wyszukiwania wymaganej grubości tarczy na szukanie po numerze ogólnym formy, profilu i ilości łopat (zamiast konkretnej nazwie formy)</li>
            <li>Dodano funkcjonalność usuwania wszystkich danych mechanicznych (na potrzeby usunięcia danych, kiedy jednocześnie nie można ich zastąpić nowszymi, gdyż brak jest aktualnych produkcyjnych XLS zgodnych z formatem obecnych funkcjonalności)</li>
        </ul>

        <h3>v2.9.1</h3>
        <ul>
            <li>Naprawiono defekt przeszukiwania wgranych danych mechanicznych, powodujący nie zwracanie żadnego wyniku przy szukaniu frazą, gdy któraś dana nie posiadała wartości opcjonalnej (np. kąt pod kołpak)</li>
            <li>Przywrócono możliwość (naprawiono defekt) konstruowania wirnika z kołpakiem, gdzie każdy dobór kończył się "Nie można stwierdzić potrzeby wycięcia w kołpaku, gdyż mimo konieczności kołpaka nie ma informacji o kącie łopaty pod przycinanie dla formowania.". Warunek występowania kołpaka był odwrócony w stosunku do
                obecności kąta przez co także przy wirnikach bezkołpakowych pojawiały się błędy związane z kołpakiem.
            </li>
            <li>Przy liczeniu średnicy tarczy, zamiast uwzględniąć "połowę połowy szerokości podpory" jest uwzględniana teraz "połowa szerokości podpory"</li>
            <li>Zamieniono priorytety dla śruby (wcześniej 38) i nakrętki (wcześniej 42) na listę materiałową dla śruby sprzęgło-tarcza</li>
            <li>Dodano interpretację (z ładowanego XLS strategii montażów) nowych kolumn "NR rysunku podpory" i "NR rysunku obejmy / nakładki" (były już obecne w arkuszach demo)</li>
            <li>Zmieniono stałą, zawszę tę samą normę nakładki ("001.S-10373-01(2)") na daną wgrywaną z XLS (NR rysunku obejmy / nakładki)</li>
            <li>Zmieniono stałą, zawsze tę samą normaę obejmy ("ZN-01-004-2") na daną wgrywaną z XLS (NR rysunku obejmy / nakładki)</li>
            <li>Zmieniono normę podpory dla tablicy materiałowej z typoszeregu na wgrywaną z XLS (NR rysunku podpory)</li>
            <li>Usunięto typoszereg mocowania łopat, ponieważ powyższe zmiany efektywnie spowodowały, że żadna wartość z tego typoszeregu nie była już używana</li>
            <li>Naprawino ilość sztuk łopaty na liście materiałowej (właściwa ilość sztuk zamiast jednej).</li>
            <li>Usunięto mylący podpis "(... bez sprzęgła)" na krótkim podsumowaniu złożenia mechanicznego, ponieważ był on umieszczony pod wartością masy całkowitej (ze sprzęgłem)</li>
            <li>Na "złożeniu wirnika" w podsumowaniu każdej opcji umieszczono ostrzeżenie w przypadku nie przeprowadzenia symulacji obciążeniowej ("Nie przeprowadzono symulacji obciążeniowej - zadaj moc na wale i prędkość by obliczyć."</li>
            <li>Na "złożeniu wirnika" w podsumowaniu każdej opcji umieszczono w przypadku symulacji obciążeń nie tylko informację o tym czy dane kryterium zostało spełinione, ale także wartośc obliczeniową w nawiasach</li>
            <li>Wprowadzono system domyślnych tłumaczeń wielojęzykowych zaszytych w aplikacji, aby nie trzeba było na każdym nowym środowisku ustawiać tłumaczeń w bazie danych (jednak baza danych potrafi je nadpisywać)</li>
        </ul>

        <h3>v2.9</h3>
        <ul>
            <li>Mechanizm przełączania języków, rejestracji tłumaczeń, i zapamiętywania odnalezionych brakujących tłumaczeń.</li>
            <li>Eksperymentalne wprowadzenie tłumaczeń PL i EN dla stron potencjalnie przeznaczonych dla użytkowników końcowych (symulacja, dobór)</li>
        </ul>

        <h3>v2.8.1</h3>
        <ul>
            <li>Naprawiono defekt ładowania pliku XLS w obszarze interpretacji kolumny "Długość śruby ST (bez tarczy)"</li>
        </ul>

        <h3>v2.8</h3>
        <ul>
            <li>Zrezygnowano z kolumny XLS form łopat "Czy stosować kołpak" i obecność kołpaka jest teraz definiowana obecnością wartości kąta "Minimalny kąt łopaty dla kołpaka z wycięciem (czy stosować kołpak)"</li>
            <li>Zrezygnowano z użycia typoszeregu osadzonego w programie do obliczania długości śruby ST (w typoszeregu - bez tarczy), i zamiast tego wprowadzono kolumnę manufakturyzacji sprzęgieł: "Długość śruby ST (bez tarczy)"</li>
            <li>Zdefiniowano nowy materiał "AISI 304", niewybiueralny przez użytkownika do konstrukcji. Pełna nazwa: Stal nierdzewna, gęstośc: 7.93 g/cm3, klasa śruby: 70, klasa podkładki: brak, klasa nakrętki: 70</li>
            <li>Zmieniono gęstość materiału AISI 316 z 7.9 na 7.98 g/cm3</li>
            <li>Zmieniono gęstość materiału AISI 316L z 7.9 na 8.0 g/cm3</li>
            <li>Dodano "typoszereg" (a w zasadzie listę rozumianą przez program) materiałów na stronę Typoszeregi, dla uwidocznienia materiałów rozpoznawalnych przez program i ich parametrów</li>
        </ul>

        <h3>v2.7</h3>
        <ul>
            <li>Wprowadzono nowy sposób obliczania "A na tarczy" oraz średnicy tarczy przy składaniu wirnika</li>
            <li>Wprowadzono funkcjonalnośc edycji profili lopatowych</li>
            <li>Wzbogacono złożenie mechaniczne o walidację złożoną z ~70 warunków (głównie ujemne, zerowe lub zbyt duże wartości wymiarów)</li>
            <li>Refaktoryzacja kodu pozbywając się całkowicie nazewnictwa "grupa wirników" (Impeller group) na rzecz "nastawialnego wirnika" (Adjustable impeller)</li>
        </ul>

        <h3>v2.6 <i>"Integracja z Optisel 1"</i></h3>
        <ul>
            <li>Zaimplementowano interfejs API dedykowany dla Optisel 1</li>
            <li>Poprawiono zapis logu obliczeń przy doborze z Optisel 1, tak by w zapisanym doborze znalazły się i logi główne (konstruowanie kombinacji) i logi konkretnego złożenia mechanicznego oraz jego symulajcji obciążeniowej</li>
            <li>Poprawiono integrację symulacji z modułem mechanicznym pod względem przekazywania do zapisanych doborów mechanicznych nazwy wirnika, źródła doboru, autora itd. (lepsza historia doborów z symulacji) (wszystko przy okazji dużej refaktoryzacji przygotowującej do integracji z Optisel 1)</li>
        </ul>

        <h3>v2.5 <i>"Symulacja obciążeń mechaniki dla Złożeń Wirnika"</i></h3>
        <ul>
            <li>Wprowadzono mechaniczną symulację na ekranie "Złożenie wirnika" jeżeli zostanie podane RPM oraz moc w punkcie pracy, wraz z wyborem najbardzej optymalnego rozwiązania</li>
            <li>Rozszerzono "Złożenie wirnika" o prezentowanie w przypadku symulacji mechanicznej (rpm i moc podane) rezultatów walidacji 4 kryteriów przeciążenia sprzęgła i 3 kryteriów rezonansu w punkcie pracy</li>
        </ul>

        <h3>v2.4 <i>"Obliczenia rezonansów"</i></h3>
        <ul>
            <li>Wprowadzono nowe kolumny formy łopat - częstotliwości drgań dla wykonania standard i AS SG (obowiązuje nowy wzór XLS dla form łopat)</li>
            <li>Zaimplementowano obliczenia rezonansu, zarówno w punkcie pracy jaki w spektrum RPM podzielonym na stałą ilośc dyskretnych zakresów</li>
            <li>Dodano prezentację częstotliwości drgań własnych łopaty w szczegółach złożenia mechanicznego</li>
            <li>Zmieniono nazwę zakładki mechanicznej na karcie wentylatora (symulacja) z "Materiały" na "Mechanika" i przeniesiono tam paremtry mechaniczne</li>
            <li>Wyprowadzono sekcję wartości związanych z rezonansami w punkcie pracy na karcie wentylatora w zakładce mechanika</li>
            <li>Dodano wykres rezonansów Campbella na karcie wentylatora w zakładce mechanika</li>
            <li>Zastąpiono konfigurację O+P w strategii montażu łopaty konfiguracją P+2O (która używa 2x więcej obejm)</li>
            <li>Zaktualizowano listę wartości priorytetów na listę montażową prawie wszsytkich elementów (pod wzór Materiały 1.1, w tym zrezygnowano z dynamicznie zmienianych priorytetów w zależności od obecności kołpaka)</li>
        </ul>

        <h3>v2.3</h3>
        <ul>
            <li>Zmieniono sortowanie doboru klasycznego na "od największej sprawności" (uwaga - może prowokować dobór punktu daleko od charakterystyki, gdyż teraz próbnie max błąd Q i P jest ~10%)</li>
            <li>Dodano umieszcznie na liście materiałowej brakującej (już wcześniej liczonej, ale nie dodawanej) śruby mocującej kołpak (ilość = ilość łopat)</li>
            <li>Poprawiono błąd gdzie podkładka do kołpaka "na obejmę/nakładkę", która powinna być dobierana z DIN 125, była dobierana z DIN 127</li>
            <li>Wprowadzono dynamiczny wybór priorytetów części złącznych podkładek/nakrętek TP - dawny 36 i 38, oraz 75 i 79 w przypadku, gdy jest kołpak</li>
            <li>Do informacyjnej plakietki na liście materiałowej z której sekcji pochodzi część (np. "TP"), dodano tymczasowo priorytet ("TP-50")</li>
            <li>Naprawiono bląd pustych kolumn "Materiał" i "Norma" w tabeli materiałowej pod każdym z wyników złożenia mechanicznego (po rozwinięciu "Szczegóły")</li>
            <li>Zmieniono zasadę "co najmniej materiał A2 dla śrub TO (a TP jak wybrał użytkownik)", na zasadę "co najmniej materiał A2 dla śrub TP (a TO jak wybrał użytkownik)", inaczej mówiąc, od teraz TP zawsze ze stali nierdzewnej, TO nie zawsze</li>
            <li>W szczegółach złożenia mechanicznego zmieniono prezentację parametru "Średnica otworu pod śrubę zabezpieczającą" z "M" + liczba, na samą liczbę a do nazwy parametru dopisano jednostkę [mm]</li>
            <li>Dodano nowy parametr obliczonego sprzęgła (widoczny w szczegółach sprzęgła na złożeniu) - Średnica gwintu dla śruby zabezpieczającej, z obliczonym M (otwór minus 2mm)</li>
            <li>Poprawiono błąd oznaczenia wymiaru na obrazie wymiarowym sprzęgło-wał, gdzie dla śruby mocującej było użyte M od śruby TS. Zamiast tego użyto nowego obliczonego M od śruby zabezpieczającej</li>
            <li>Zmieniono nazwę parametru obliczonej mechanicznie łopaty z "Norma" na "Nazwa formy"</li>
            <li>Zmieniono nazwę parametru obliczonej mechanicznie łopaty z "Długość łopaty formy" na "Długość łopaty"</li>
            <li>Zmieniono nazwę prezentacji parametrów "Norma" dla podpory/obejm/nakładki na "Nr rysunku"</li>
            <li>Zmieniono nazwę parametru obliczonego sprzęgła "Norma" na "Nr rysunku"</li>
            <li>Naprawiono bład, gdzie w przypadku braku kołpaka ilość śrób TO nie była przemnożona przez ilość łopat</li>
            <li>Dodano wykreślanie punktu otrzymanego (dotychcas był tylko zadany) pracy - na wykresie wyniku prototypowegov2.3 doboru klasycznego</li>
        </ul>

        <h2>v2.2</h2>
        <ul>
            <li>Wprowadzono interpretację arkusza SPL wirnika, oblicznie i prezentację krzywej SPL i wartości SPL w punkcie pracy</li>
            <li>Rozbudowano składanie mechaniczne o stosowania kołpaka (w tym interpretację nowych kolumn XLS formownia - obecności kołpaka i minimalnego kąta pod wycięcie, zamienianie jednej ze śrub per-obejma/nakładka na kołpakową)</li>
            <li>Rozbudowano prezentację złożenia mechanicznego o opcjonalny kołpak (szczegóły kołpaka pod tarczą)</li>
            <li>Dodano możliwość bezpośredniego pobrania aktualnie obowiązujących przykładowych plików XLSX na stronie ładowania danych</li>
            <li>Rozbudowano prototypowy dobór o prezentację SPL w punkcie pracy oraz czwarty zoptymalizowany wynik doboru rozszerzonego - pod kątem najniższego SPL</li>
            <li>Uzupełniono arkusze wirników demonstracyjnych o macierze SPL przyjmując SPL równe sprawności (Eta)</li>
            <li>Naprawiono defekt, gdzie dla śrub tarcza-podpora na listę materiałową, była brana ilość nakrętek "nakrętek per śuba TP * ilość łopat", a nie "śrub kompletnych TP [na podporę] * nakrętek per śruba TP * ilość łopat" (to samo dla podkładek)</li>
            <li>Wprowadzono do programu typoszereg wydłużeń śrub dla kołpaka w zależności od M śruby</li>
            <li>Wprowadzono do programu typoszereg podkładek DIN 9021</li>
            <li>Zaktualizowano mechaniczne dane demo o to, by wirniki z formy dla profilu 1DEM były z kołpakiem (wycięcie od 3 stopni)</li>
            <li>Poprawiono czytelność błędów niemożności złożenia wirnika, który zamiast pełenj nazwy podpory ze strategii montazu łopaty wypisywał "... Strategia montażu łopaty" (nazwę parametru, zamiast jej faktyczną wartość)</li>
            <li>Wprowadzono wyraźny komunikat na wyniku symulacji wentylatora, w przypadku gdy sprzęgło jest przeciązone (niezaleznie od wybranej zakładki)</li>
            <li>Przeniesiono komunikat o braku rozwiązania mechaniczengo w wyniku symulacji wentylatora w taki sposób, by był wyświetlany zawsze, niezależnie od wybranej zakładki</li>
            <li>Wprowadzono persystencję w bazie danych dla załądowanych wirników i profili (a nie tylko w tymczasowo w pamięci RAM), więc dane te nie będą tracone co restart/wdrożenie aplikacji (persystowane są także załadowane pliki, jednak nie można ich jeszcze w łatwy sposób odczytać z powrotem)</li>
            <li>Przeniesiono środowisko testowe na adres domenowy dobor.online</li>
        </ul>

        <h2>v2.1</h2>
        <ul>
            <li>Wprowadzono algorytm doboru grubości tarczy na podstawie formowania i maksymalnej prędkości końca łopaty</li>
            <li>Dodano obsługę nowego typu danych importowanych plikiem XLSX - wymagań grubości tarcz</li>
            <li>Dodano nowe kryteria w Złożeniu wirnika - RPM oraz prędkośc końca łopaty (jednocześnie można używać jednego z nich)</li>
            <li>Wprowadzono drugi alternatywny dobór przepływowy - zautodoborem prędkości i średnicy, generujące opcje najmniejszą/najsprawniejszą/najwolniejszą</li>
            <li>Dodano do karty danych technicznych wentylatora wykres sprawności na tle ciśnienia</li>
            <li>Rozszerzono prototypowy dobór przepływowy o możliwość dokładnej symulacji przepływowo/mechanicznej wybranego z doboru wyniku</li>
            <li>Dodano poglądowe zbiorcze wykresy charakterystyk ciśnienia na stronie prototypowego doboru</li>
            <li>Wprowadzono regułę dobierania najcieńszej tarczy w przypadku nie zadania ani RPM ani prędkości końca łopaty przy złożeniu mechanicznym</li>
            <li>Dodano ograniczenie od góry dobieranej długosci śruby DIN, regułą 'jedno oczko w górę' tzn: do 8mm wyszukuje max 10mm, do 18mm wyszukuje 'zaokrąglij w górę do wielokrotności 2 i dodaj 2', do 85mm wysuzkuje 'zaokrąglij
                w górę do wielokrotności 5 i dodaj 5', dla pozostałych wyszukuje 'zaokrąglij w górę do wielokrotności 10 i dodaj 10'
            </li>
            <li>Naprawiono defekt braku linii ciśnienia nieskorygowanego na wykresie charakterystyki</li>
            <li>Naprawiono zachowanie kreślenia krzywej oporu pod kursorem myszy na charaketerystyce wentylatora - zaprzestano rysowania krzywej dla wartości ujemnych oraz zaprzestano pozostawiać krzywą po opuszczeniu muszą obszaru wykresu</li>
            <li>Dodano wykres momentu obrotowego na karcie danych technicznych wentylatora</li>
            <li>Wprowadzono odrzucanie XLS wirnika jeżel i nie zostanie wykryta co najmniej jedna opcja kątowa</li>
            <li>Przywrócono widoczność rezultatów obliczeń symulacji mechanicznej na karcie danych technicznych wentylatora, oraz zreorganizowano prezentację parametrów w grupach, a także zreorganizowano log obliczeniowy rozwiązując kilka niedogodności</li>
            <li>Zmieniono domyślne kryteria symulacji tak by odpowiadały oryginalnym z Optisel 1</li>
            <li>Dodano zamianę "," na "." podczas wprowadzania liczb na symulacji/złożeniu/selekcji (1,1 było interpretowane jako 1)</li>
        </ul>


        <h2>2.0 "Wydanie majowe"</h2>
        <ul>
            <li><strong>Zupełnie nowy mechanizm składania wirnika</strong> oparty o obliczeniowe dobieranie części złącznych (długości śrub wg. osadzonych norm DIN)</li>
            <li>Funkcjonalność <strong>Złożenia wirnika</strong> dostępna w menu Mechanika - pozwalająca składać wirniki (bez ich symulowania pod kątem przepływu/obciążeń)</li>
            <li><strong>Prototypowy dobór wentylatorów</strong></li>
            <li><strong>Zestawienie informacyjne szczegółow mechaniki</strong> dostępne pod tabelą materiałową w symulacji oraz w szczegółach złożenia wirnika</li>
            <li>Obsługa złożenia mechaniczengo pod <strong>zadane preferowane materiały części złącznych i części konstrukcyjnych</strong> (wraz z regułami doboru materiałów - nie zawsze dokładnie ten zadany przez użytkownika)</li>
            <li><strong>Obsługa nowych plików XLS mechaniki</strong> - zestawów formowania, manufakturyzacji sprzęgieł, strategii montażu łopat i kompatybilności sprzęgieł</li>
            <li>Integracja symulacji przepływowej z nową mechaniką, tabelą materiałów i szczegółami mechanicznymi</li>
            <li>Porzucenie wszystkich wsadowych plików XLS poprzedniego mechanizmu składania wirnika - formowań, sprzęgieł, mas, montaży, elementów montażowych</li>
            <li>Kompleksowe rozdzielenie doboru przepływowego od mechanicznego, uznając dobór przepływowy za udany nawet przy braku rozwiązania mechanicznego</li>
            <li>Obsługa <strong>automatycznego doboru przykładowej średnicy wału, długości wału i wysokości wirnika</strong> w przypadku ich nie zadania</li>
            <li>Zupełnie nowy system organizacji logów obliczeniowych dla mechaniki. Logi dla konkretnego przypadku można odczytać pod widokiem szczegółów mechanicznych (zarówno przez dobór przepływowy jak i złożenie). Logi dotyczące generlanego procesu składania wirników można przeczytać na dole strony Złożenie wirnika</li>
            <li>Rozwój nieusuwalnych przykładowych danych Demo - oprócz wirników i profili doszły rekordy wszystkich wsadów mechanicznych oraz informacje w osadzonych typoszeregach dla czopów 33 i 99 oraz sprzęgieł/tarcz DEMO*</li>
        </ul>

        <h2>v1.0 "Wydanie kwietniowe"</h2>
        <ul>
            <li>Migracja funkcjonalnośic weryfikacji doboru mechanicznego ze starego modułu mechanicznego</li>
            <li>Symulacja mechaniczna podczas symulacji przepływowej i prosty listing materiałów na karcie wentylatora</li>
            <li>Możliwość wgrywania XLS mechanicznych zgodnych ze starym modułem mechanicznym</li>
            <li>Podstrony umożliwające przeglądanie obecnie wgranych danych mechanicznych</li>
            <li>Możliwosć konfiguracji ustawień mechanicznych (np. czas nagłego zatryzmania)</li>
            <li>Prezentacja osadzonych w programie typoszeregów</li>
            <li>Przeglądarka zapisanych doborów mechanicznych</li>
            <li>Listing szczegółow na stronie wgrywania wirników, jakie pliki XLS są obsługiwane</li>
            <li>Prosta ochrona hasłem</li>
        </ul>
    </>
}