import {Card} from "../../components/Card";
import React, {useState} from "react";
import {H3RangeEstimationJson, MechanicalSelectionChoiceJson, MechanicalSelectionH3RangeEstimationJson} from "./ImpellerAssemblyService";
import {AssemblyOverview} from "../../components/assembly/AssemblyOverview";
import {AssemblyScoreBar} from "../../components/assembly/AssemblyScoreBar";
import {ResonanceCampbellChart} from "../../components/domain/ResonanceCampbellChart";
import {MiniSelectiveH3RangeEstimationBadge} from "./MiniSelectiveH3RangeEstimationBadge";

type LengthOption = {
    length: number,
    pinDiameter: number,
    clutchTypeOptions: ClutchTypeOption[]
}

type ClutchTypeOption = {
    clutchType: string,
    screwCountOptions: ScrewCountOption[]
}

type ScrewCountOption = {
    screwCount: number,
    theChoice: MechanicalSelectionChoiceJson
}


export function ImpellerAssemblyResultsForForm(props: { choices: MechanicalSelectionChoiceJson[], selectionH3RangeEstimation: MechanicalSelectionH3RangeEstimationJson | null }) {

    let initiallySelectedId = "";
    const lengthOptions: LengthOption[] = [];
    props.choices.forEach(choice => {
        const length = Math.round(choice.impellerAssembly.blade.formBladeLength);
        const pinDiameter = choice.impellerAssembly.blade.pinDiameter;
        let currentLengthOption = lengthOptions.find(l => l.length == length && l.pinDiameter == pinDiameter);
        if (currentLengthOption == undefined) {
            currentLengthOption = {length: length, clutchTypeOptions: [], pinDiameter: pinDiameter};
            lengthOptions.push(currentLengthOption);
        }
        let currentClutchTypeOption = currentLengthOption.clutchTypeOptions.find(cto => cto.clutchType == choice.impellerAssembly.clutch.clutchTypeName);
        if (currentClutchTypeOption == undefined) {
            currentClutchTypeOption = {clutchType: choice.impellerAssembly.clutch.clutchTypeName, screwCountOptions: []};
            currentLengthOption.clutchTypeOptions.push(currentClutchTypeOption);
        }
        const thisClutchOption = {
            screwCount: choice.impellerAssembly.clutch.mountingScrewCount,
            theChoice: choice
        };
        currentClutchTypeOption.screwCountOptions.push(thisClutchOption);
        if (choice.consideredBestPickInCurrentSelectionContext) {
            initiallySelectedId = choice.localId;
        }
    });

    if (initiallySelectedId === "" && props.choices.length > 0) {
        initiallySelectedId = props.choices[0].localId;
    }

    const [selectedLocalId, setSelectedLocalId] = useState(initiallySelectedId);

    lengthOptions.sort((loA, loB) => {
        if (loA.pinDiameter !== loB.pinDiameter) {
            return loA.pinDiameter - loB.pinDiameter; // Pin ascending, most important
        }
        return loB.length - loA.length; // same pins? then longer=first
    });

    const bladeLengthSections = lengthOptions.map(lengthOption => <section title="Blade Length" className={"bg-white ps-3 pe-3 pt-1"}>
        <strong>{lengthOption.length} mm / cz.{lengthOption.pinDiameter}</strong>

        <table>
            {lengthOption.clutchTypeOptions.map(clutchTypeOption => <tr>
                <td className={"text-end"}>{clutchTypeOption.clutchType}:</td>
                <td >
                    <div className={"d-flex flex-row gap-1 ps-3"}>
                        {clutchTypeOption.screwCountOptions.map(screwCountOption =>
                            <button
                                className={"btn btn-xxs " + (screwCountOption.theChoice.localId == selectedLocalId ? "btn-primary" : "btn-outline " + (screwCountOption.theChoice.mechanicalSelectionScore?.kryt1MoMsPassed === false ? "btn-outline-warning" : "btn-outline-default"))}
                                onClick={() => setSelectedLocalId(screwCountOption.theChoice.localId)}>
                                <div>{screwCountOption.screwCount} śr</div>
                                <div className={"text-gray-400"}>{screwCountOption.theChoice.impellerAssembly.totalMass.toFixed(0)} kg</div>
                            </button>)}
                    </div>

                    <MiniSelectiveH3RangeEstimationBadge selectionH3RangeEstimation={props.selectionH3RangeEstimation}
                                                         pinDiameter={lengthOption.pinDiameter}
                                                         formBladeLength={lengthOption.length}
                                                         clutchTypeName={clutchTypeOption.clutchType}/>
                </td>

            </tr>)}

        </table>
    </section>);

    return <>
        <div className={"d-flex flex-row justify-content-end align-items-start mt-5 "}>
            <strong className={"badge badge-light-dark fs-4 me-3"}>nr {props.choices[0]?.impellerAssembly.temporaryIdentity}</strong>
            {bladeLengthSections}
        </div>
        <Card>
            {props.choices
                .filter(ch => ch.localId == selectedLocalId)
                .map(choice =>
                    <Card
                        additionalClassNames={"mb-3 " + (choice.consideredBestPickInCurrentSelectionContext ? " bg-success-light" : "") + (choice.consideredIgnoredBecauseBetterVariantExistInCurrentSelectionContext ? " opacity-50" : "")}>
                        {choice.consideredBestPickInCurrentSelectionContext &&
                            <div className={"fs-3 text-center text-success"}>Rozwiązanie optymalne, proponowane
                                użytkownikowi.</div>}
                        {choice.consideredIgnoredBecauseBetterVariantExistInCurrentSelectionContext &&
                            <div className={"text-center text-grey"}>Rozwiązanie nieistotne, istnieje optymalniejsze z podobnych
                                komponentów spełniające kryteria obciążeń (np. mniejsza ilość śrub mocujących).</div>}

                        <AssemblyOverview assembly={choice.impellerAssembly} detailsExpandedByDefault={false}
                                          displayActionsPanel={true}
                                          includeMaterialTable={true}
                                          dimensionalImageDescriptors={choice.dimensionalImageDescriptors}
                                          resonanceReport={choice.resonanceReport}/>
                        {choice.mechanicalSelectionScore === null && <div className={"d-flex justify-content-center"}>
                            <div className={"badge badge-light-danger"}>Nie przeprowadzono symulacji obciążeniowej - zadaj moc
                                na
                                wale,
                                ciśnienie i prędkość by obliczyć.
                            </div>
                        </div>}
                        {choice.mechanicalSelectionScore !== null &&
                            <AssemblyScoreBar mechanicalSelectionScore={choice.mechanicalSelectionScore}/>}


                    </Card>)}
        </Card></>
}

/*

    const lengthOptions: LengthOption[] = [
        {
            clutchTypeOptions: [
                {
                    clutchType: "S50A5",
                    screwCountOptions: [
                        {
                            screwCount: 5,
                            theChoice: null
                        },
                        {
                            screwCount: 6,
                            theChoice: null
                        },
                        {
                            screwCount: 7,
                            theChoice: null
                        }

                    ]
                },
                {
                    clutchType: "S80A5",
                    screwCountOptions: [
                        {
                            screwCount: 8,
                            theChoice: null
                        },
                        {
                            screwCount: 9,
                            theChoice: null
                        }
                    ]
                }
            ], length: 905
        },
        {
            clutchTypeOptions: [
                {
                    clutchType: "S50A10",
                    screwCountOptions: [
                        {
                            screwCount: 50,
                            theChoice: null
                        },
                        {
                            screwCount: 60,
                            theChoice: null
                        },
                        {
                            screwCount: 70,
                            theChoice: null
                        }

                    ]
                },
                {
                    clutchType: "S80ZZA10",
                    screwCountOptions: [
                        {
                            screwCount: 80,
                            theChoice: null
                        },
                        {
                            screwCount: 90,
                            theChoice: null
                        }
                    ]
                }
            ],
            length: 910
        },
        {
            clutchTypeOptions: [
                {
                    clutchType: "S50A15",
                    screwCountOptions: [
                        {
                            screwCount: 500,
                            theChoice: null
                        },
                        {
                            screwCount: 600,
                            theChoice: null
                        },
                        {
                            screwCount: 700,
                            theChoice: null
                        }

                    ]
                },
                {
                    clutchType: "S80XA15",
                    screwCountOptions: [
                        {
                            screwCount: 800,
                            theChoice: null
                        },
                        {
                            screwCount: 900,
                            theChoice: null
                        }
                    ]
                }
            ],
            length: 915
        }
    ];

 */