// optisel-frontend/src/pages/issues/BrowseIssuesPage.tsx
import React, {useContext} from 'react';
import {Card} from "../../components/Card";
import {createAsyncResultsProviderViaApiWithGetParams, SearchResultsOrder, useSearch, useTextFilterManager} from "../../platform/search/PlatformSearch";
import {AdditionalTextFilter, CurrentlyUsedFilters, OrderSelector, SearchQueryBar} from "../../components/search/MetronicSearchComponents";
import {IssueApiJson} from "./IssueApiJson";
import UboldSpinner from "../../ubold-components/UboldSpinner";
import {NavLink} from "react-router-dom";
import {HotIssueContext} from "./hotssue/HotIssueContextProvider";

export function BrowseIssuesPage() {
    const queryFilterManager = useTextFilterManager("query", "Szukaj");
    const caseNumberFilterManager = useTextFilterManager("caseNumber", "Numer sprawy");
    const referenceNumberFilterManager = useTextFilterManager("inquiryNumber", "Numer zapytania");

    const {makeHotIssue} = useContext(HotIssueContext);

    const availableOrders: SearchResultsOrder[] = [
        {id: "last-activity-desc", name: "Ostatnio aktywne", informationalDirection: "desc"},
        {id: "creation-desc", name: "Najnowsze", informationalDirection: "desc"}
    ];

    const {
        currentlyActiveFilterManagers,
        refresh,
        isLoading,
        results,
        reset,
        currentOrderId,
        changeOrderId,
        paginationDriver: {
            currentPage,
            switchToNextPage,
            nextPageSwitchAvailable,
        }
    } = useSearch<any, IssueApiJson>(
        createAsyncResultsProviderViaApiWithGetParams<IssueApiJson>("/api/issues/search"),
        [queryFilterManager, caseNumberFilterManager, referenceNumberFilterManager],
        availableOrders,
        20
    );

    return <>
        <Card additionalClassNames={"card mb-4"}>
            <SearchQueryBar queryFilterManager={queryFilterManager} reset={reset} refresh={refresh}/>
            <div className="separator separator-dashed mt-6 mb-4"/>
            <div className="row">
                <div className="col-4">
                    <AdditionalTextFilter fanNameFilterManager={caseNumberFilterManager}/>
                </div>
                <div className="col-4">
                    <AdditionalTextFilter fanNameFilterManager={referenceNumberFilterManager}/>
                </div>
                <div className="col-4 d-flex flex-column justify-content-end">
                    <NavLink to={"/create-issue"} className={"btn btn-sm btn-primary"}><i className={"fas fa-plus-circle"}/> Utwórz sprawę</NavLink>
                </div>

            </div>
        </Card>
        <Card>
            <div className={"d-flex mb-2"}>
                <div className={"flex-grow-1"}>
                    <CurrentlyUsedFilters currentlyActiveFilterManagers={currentlyActiveFilterManagers}/>
                </div>
                <div className={"d-flex align-content-center"}>
                    <OrderSelector changeOrderId={changeOrderId} currentOrderId={currentOrderId} availableOrders={availableOrders}/>
                </div>
            </div>

            <div>
                {isLoading ? <div className="text-center mt-5 mb-5"><UboldSpinner/></div> : null}
            </div>
            {!isLoading && results.length === 0 && <div className="text-center fs-4 text-gray-600 mt-5 mb-5">Brak rezultatów</div>}

            {!isLoading && results.length > 0 && <div className={"table-responsive"}>
                <table className={"table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3"}>
                    <thead>
                    <tr className="fw-bolder text-muted">
                        <th className="w-100px">Nazwa / Nr / wew. identyfikator</th>

                        <th className="min-w-120px">Referencje</th>
                        <th className="min-w-120px">Aktywność / Utworzono</th>
                        <th className="text-end">Akcje</th>
                    </tr>
                    </thead>
                    <tbody>
                    {results.map(result => <tr key={result.id}>
                        <td className="d-flex flex-column gap-2 align-items-start">
                            <div className="fw-boldest fs-5">{result.name}</div>
                            {result.issueReferences.wentechCaseNumber !== "" && <div className={"badge fs-5 badge-secondary p-2 text-nowrap"}>{result.issueReferences.wentechCaseNumber}</div>}

                            <div className={"text-gray-200 text-nowrap"}>{result.id}</div>
                        </td>

                        <td className="align-top">
                            <div className={"d-flex flex-column gap-2"}>
                                {result.issueReferences.wentechInquiryNumber !== "" && <div className={"badge badge-light p-2 text-nowrap"}><span className="text-gray-400">Zapytanie (Wentech):</span> {result.issueReferences.wentechInquiryNumber}</div>}
                                {result.issueReferences.customerOrderNumber !== "" && <div className={"badge badge-light p-2 text-nowrap"}><span className="text-gray-400">Zamówienie (klienta):</span> {result.issueReferences.customerOrderNumber}</div>}
                                {result.issueReferences.customerReferenceNumber !== "" && <div className={"badge badge-light p-2 text-nowrap"}><span className="text-gray-400">Referencja (klienta):</span> {result.issueReferences.customerReferenceNumber}</div>}
                            </div>
                        </td>
                        <td className="d-flex flex-column gap-2 align-items-start">

                            <div className={"fw-boldest fs-5"}>{new Date(result.lastActivity).toLocaleString("pl-PL")}</div>
                            <div>{new Date(result.creationInstant).toLocaleString("pl-PL")}</div>
                            <div className={"text-gray-400"}>przez: {result.originalCreatorName}</div>
                        </td>
                        <td className="align-top text-end">
                            <div>
                                <NavLink to={`/show-issue/${result.id}`} className={"btn btn-sm btn-light-primary"}>Zobacz</NavLink>
                            </div>
                            <div>
                                <button className={"btn btn-sm btn-primary"} onClick={() => makeHotIssue(result.id)}>Pracuj z</button>
                            </div>
                        </td>
                    </tr>)}
                    </tbody>
                </table>

                {/* {nextPageSwitchAvailable && <div>
                    <span className={"text-muted me-4"}>Strona {currentPage}</span>
                    <button className={"btn btn-outline btn-outline-dashed btn-sm"} onClick={switchToNextPage}>Szukaj więcej</button>
                </div>} */}
            </div>}
        </Card>
    </>;
}