import React, {ReactNode} from 'react';


export function Summaries(props: { children: React.ReactNode }) {
    return <div className="d-flex flex-wrap justify-content-start">
        <div className="d-flex">
            {props.children}
        </div>
    </div>;
}

function Box(props: { value: React.ReactNode, label: string }) {
    return <div className="border border-gray-300 border-dashed rounded  py-3 px-4 me-4 mb-3">
        <div className="d-flex align-items-center">
            <div className="fs-5 fw-bolder">{props.value}</div>
        </div>
        <div className="fw-bold fs-8 text-gray-400">{props.label}</div>
    </div>
}


Summaries.Box = Box;

