import {D3ChartRenderable, D3ChartSize, D3GSelection, D3SvgSelection} from "../platform/d3chart/D3Chart";
import * as d3Scale from "d3-scale";
import * as d3Selection from "d3-selection";

export class MouseGuidesD3ChartRenderable implements D3ChartRenderable {

    private g: D3GSelection | undefined;
    private targetPoint: { x: number, y: number } = {x: 0, y: 0};
    private verticalLine: d3Selection.Selection<SVGLineElement, unknown, null, undefined> | undefined; // TODO declare simplified type
    private horizontalLine: d3Selection.Selection<SVGLineElement, unknown, null, undefined> | undefined;
    private xLabel: d3Selection.Selection<SVGTextElement, unknown, null, undefined> | undefined;
    private yLabel: d3Selection.Selection<SVGTextElement, unknown, null, undefined> | undefined;

    constructor(private scaleX: d3Scale.ScaleLinear<number, number, never>,
                private scaleY: d3Scale.ScaleLinear<number, number, never>) {
    }


    initialize(svgSelection: D3SvgSelection, rootG: D3GSelection, unClippedRootG: D3GSelection, size: D3ChartSize): void {
        this.g = rootG.append("g"); // TODO it is needed to add g? maybe path directly?
        this.verticalLine = this.g.append("line")
        this.horizontalLine = this.g.append("line")
        this.xLabel = this.g.append("text").attr("font-size","12px").attr("fill","black");
        this.yLabel = this.g.append("text").attr("font-size","12px").attr("fill","black");
    }

    onMouseMove(x: number, y: number): "repaint" | "norepaint" {
        this.targetPoint = {x, y};
        return "repaint";

    }

    onMouseOut(): "repaint" | "norepaint" {
        this.targetPoint = {x: -1, y: -1}; // TODO improve, may fail on charts with negative values presented
        return "repaint";
    }

    render(): void {
        const minXDomain = this.scaleX.domain()[0];
        const maxXDomain = this.scaleX.domain()[1];
        const minYDomain = this.scaleY.domain()[1];
        const maxYDomain = this.scaleY.domain()[0]; // 0 because Y-domain is usually inverted e.g. [10,0]


        const pickedYDomainValue = this.scaleY.invert(this.targetPoint.y);
        const pickedXDomainValue = this.scaleX.invert(this.targetPoint.x);

        if (pickedYDomainValue < minYDomain ||
            pickedYDomainValue > maxYDomain ||
            pickedXDomainValue > maxXDomain ||
            pickedXDomainValue < minXDomain) {
            this.verticalLine!.attr("stroke-dasharray", "0,1")
            this.horizontalLine!.attr("stroke-dasharray", "0,1")
            this.xLabel!.text("");
            this.yLabel!.text("");
            return;
        }

        this.verticalLine!
            .attr("stroke-dasharray", "5,5")
            .attr("class", "d3-stroke-1 d3-stroke-primary-muted")
            .attr("x1", this.targetPoint.x)
            .attr("y1", this.targetPoint.y)
            .attr("x2", this.targetPoint.x)
            .attr("y2", this.scaleY(0));

        this.horizontalLine!
            .attr("stroke-dasharray", "5,5")
            .attr("class", "d3-stroke-1 d3-stroke-primary-muted")
            .attr("x1", this.targetPoint.x)
            .attr("y1", this.targetPoint.y)
            .attr("x2", this.scaleX(0))
            .attr("y2", this.targetPoint.y);

        this.xLabel!
            .attr("x", this.scaleX(0)+5)
            .attr("y", this.targetPoint.y-5)
            .text(  pickedYDomainValue.toLocaleString("pl-PL",{maximumSignificantDigits:3})+"");

        this.yLabel!
            .attr("x", this.targetPoint.x+5)
            .attr("y", this.scaleY(0)-5)
            .text(  pickedXDomainValue.toLocaleString("pl-PL",{maximumSignificantDigits:3})+"");
    }

}