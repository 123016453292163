import {MechanicalSelectionH3RangeEstimationJson} from "./ImpellerAssemblyService";
import {Card} from "../../components/Card";
import {useState} from "react";


export function ImpellerAssemblyH3EstimationResultCard( props: { estimation : MechanicalSelectionH3RangeEstimationJson | null  }) {

    const [ expanded, setExpanded ] = useState(false);
    if ( props.estimation == null ) {
        return <Card>Brak estymaty zakresu H3. Brak kandydyjących sporzęgieł lub zadanej długości wału, by otrzymać sugestie dotyczące prawdopodobnie uzyskiwalnych wysokości wirnika H3.</Card>
    }

    return <Card additionalClassNames={"me-3"}>
        <div className={"d-flex"}>
            <div className={"flex-grow-1"}>
                <strong>H3</strong> dla przeciwnych stron tarczy: <strong>{props.estimation.h3RangeEstimation.oppositeRangeGuess.min} mm - {props.estimation.h3RangeEstimation.oppositeRangeGuess.max} mm</strong>,
                dla tej samej strony: <strong>{props.estimation.h3RangeEstimation.sameSideGuess.min} mm - {props.estimation.h3RangeEstimation.sameSideGuess.max} mm</strong>
            </div>

            <div className={"flex-grow-0"}>
                <button className={"btn btn-xxs btn-outline btn-outline-dashed"} onClick={()=>setExpanded( prev=> !prev)}>Szczegóły</button>
            </div>

        </div>
        {expanded && <div>
            {props.estimation.samples.map(estimationSample=> <>
                <div>
                    {estimationSample.name}:&nbsp;
                    <strong>H3</strong> (wał {estimationSample.shaftLength} mm) dla przeciwnych stron tarczy: <strong>{estimationSample.range.oppositeRangeGuess.min} mm - {estimationSample.range.oppositeRangeGuess.max} mm</strong>,
                    dla tej samej strony: <strong>{estimationSample.range.sameSideGuess.min} mm - {estimationSample.range.sameSideGuess.max} mm</strong>
                </div>
            </>)}
        </div>}
    </Card>
        ;
}