import {useEffect, useState} from "react";
import AjaxService from "../ajax/AjaxService";


// TODO rename to just fetch obj? (not all? there is no array at all)
export function useFetchAllObjs<Type>(url: string, additionalDependencies: any[] = []): Type | null {
    const [objectData, setObjectData] = useState<Type | null>(null);

    useEffect(() => {
        setObjectData(null as unknown as Type);
        AjaxService.getJson(url).then((obj) => {
            setObjectData(obj.json as unknown as Type);
        });
    }, [url, ...additionalDependencies]);

    return objectData;
}

export function useFetchAllObjsWithRefetchCapability<Type>(url: string, additionalDependencies: any[] = []) {
    const [objectData, setObjectData] = useState<Type | null>(null);

    const effect = () => {
        setObjectData(null as unknown as Type);
        AjaxService.getJson(url).then((obj) => {
            setObjectData(obj.json as unknown as Type);
        });
    };


    useEffect(effect, [url, ...additionalDependencies]);

    return {
        result: objectData,
        refetch: effect
    };
}



