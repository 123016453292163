import React, {Component} from 'react';
import UserUnathorizedException from "../authorization/UserUnauthorizedException";
import AuthenticationService from "../authentication/AuthenticationService";
import * as Sentry from "@sentry/react";

export default class PlatformErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = {hasError: false};
        var self = this;

        window.onerror = function (err, source, fileno, column, originalError) {
          //  self.setState({hasError: true});
            console.error("Unexpected error has been caught in Platform's error boundary by window.onerror", err);
            console.error("Original error", originalError);
            //Sentry.captureException(err);
            return true;
        }

        window.onunhandledrejection = function (event) {
            if (event.reason instanceof UserUnathorizedException) {
                console.log("Error boundary detected user unauthorized and will invalidate session and redirect to main page");
                //Sentry.captureMessage("Error boundary detected user unauthorized and will invalidate session and redirect to main page");
                AuthenticationService.deAuthenticate();
                window.location.href = '/';
                return false;
            }
            console.warn("Unhandled promise rejection has been caught in error boundary by window.onunhandledrejection", event);
            return true;
        }

        this.ignoreError = this.ignoreError.bind(this);
    }

    static getDerivedStateFromError = error => {
        return {hasError: true};
    };

    componentDidCatch(error, info) {
        console.log("ERR BOUND");
        console.log(error);
        this.setState({hasError: true});
    }

    ignoreError() {
        this.setState({hasError: false});
    }

    render() {
        if (this.state.hasError) {
            return <h1 style={{marginTop:"600px", textAlign:"center"}}>SORRY, ERROR OCCURED</h1>;
        }

        return this.props.children;
    }
}


