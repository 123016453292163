import {ImpellerAssemblyJson} from "../../pages/mechanical/ImpellerAssemblyService";
import AjaxService from "../../platform/ajax/AjaxService";
import * as assert from "assert";


type PublishMechanicalImpellerAssemblyRequest = {
    caseNumber: string,
    mechanicalImpellerAssemblyJson: string,
    assemblyName: string
}

export function AssemblyPublisher(props: { assembly: ImpellerAssemblyJson }) {


    const publish = () => {
        const body: PublishMechanicalImpellerAssemblyRequest = {
            assemblyName: props.assembly.blade.formName + " " + props.assembly.clutch.clutchDisplayName + " " + props.assembly.disc.displayName,
            caseNumber: prompt("Numer sprawy") ?? "N/A",
            mechanicalImpellerAssemblyJson: JSON.stringify(props.assembly)
        }
        if (body.caseNumber === "N/A") {
            alert("Anulowano zapis");
            return;
        }
        AjaxService.postJson("/api/publish-mechanical-impeller-assembly", body)
            .then(() => {
                alert("Złożenie zapisano w Historii doborów"); // TODO i18n
            })
    }

    return <button onClick={publish} className="btn btn-xxs btn-secondary">Opublikuj w Historii Doborów</button>

}