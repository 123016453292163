import {OptiselEventJson} from "./OptiselEventHistoryPage";
import {useNavigate, useParams} from "react-router";
import {Card} from "../../components/Card";
import {NavLink} from "react-router-dom";
import React, {useContext, useState} from "react";
import {useFetchAllObjs} from "../../platform/hooks/PlatformTypeScriptHooks";
import {ImpellerAssemblyJson, PartListEntryJson} from "../mechanical/ImpellerAssemblyService";
import {FanConstructionMaterialsTable} from "../../components/domain/SimulationDatasheet";
import {ErrorBoundary} from "@sentry/react";
import {AssemblyOverview} from "../../components/assembly/AssemblyOverview";
import {DatasheetComponent} from "../datasheet/DatasheetComponent";
import {InstalledFanDatasheet} from "../../business/InstalledFanDatasheet";
import {SimulateButton} from "../simulation/SimulateButton";
import {SelectionRequestJson} from "../../business/ApiJsonTypes";
import {EventToIssueAttacher} from "../issues/EventToIssueAttacher";
import {HotIssueContext} from "../issues/hotssue/HotIssueContextProvider";


export function ShowOptiselEventPage(props: { event: OptiselEventJson }) {


    const {id} = useParams();

    const event = useFetchAllObjs<OptiselEventJson>(`/api/optisel-event/${id}`);


    if (event == null) {
        return <Card>
            <div className="card-body ">...</div>
        </Card>
    }

    return <>
        <div className={"d-flex flex-row-reverse"}>
            <NavLink to={"/event-history"} className={"btn btn-sm btn-secondary"}>Wróc do listy doborów</NavLink>
        </div>
        <Card>

            <h3 className={"text-gray-500"}>{event.performerDisplayName} - {event.typeDisplayName} - {new Date(event.creationMillis).toLocaleString("pl")}</h3>

            {event.type === "datasheet-pdf-export" && <div>
                <EventToIssueAttacher eventId={event.id} renderer={openDialog => (<button className={"btn btn-xs btn-secondary"} onClick={openDialog}>Przypisz do sprawy</button>)}/>
            </div>}


        </Card>
        {event.payloads!.map(payload => <Card additionalClassNames={"mt-2"}>
            <ErrorBoundary fallback={(errorData) => <div className={"alert alert-warning"}>BŁĄD. Nie można wyświetlić załączonej informacji typu "{payload.typeCode}". Prawdopodobnie wersja zapisana w bazie danych jest przestarzała.</div>}>
                {payload.typeCode === 'logs' && <LogsPayloadView payload={payload}/>}
                {payload.typeCode === 'selection-results' && <SelectionResultsPayloadView payload={payload}/>}
                {payload.typeCode === 'mechanical-part-list' && <MechanicalPartListPayloadView payload={payload}/>}
                {payload.typeCode === 'physical-parameters' && <PhysicalParametersPayloadView payload={payload}/>}
                {payload.typeCode === 'error-message' && <ErrorMessagePayloadView payload={payload}/>}
                {payload.typeCode === 'mechanical-assembly-results' && <MechanicalAssemblyResultsPayloadView payload={payload}/>}
                {payload.typeCode === 'simple-json-object' && <SimpleJsonObjectPayloadView payload={payload}/>}
                {payload.typeCode === 'impeller-assembly-json' && <MechanicalImpellerAssemblyPayloadView payload={payload}/>}


                {payload.typeCode === 'BinaryPdfDatasheetPayload_v2' && <BinaryPdfDatasheetPayload_v2_Downloader payload={payload} eventId={event.id}/>}
                {payload.typeCode === 'typescript_SelectionRequestJson' && <SelectionCriteriaPayload_v1_PresenterAndDownloader payload={payload as unknown as SelectionRequestJson} eventId={event.id}/>}
                {payload.typeCode === 'typescript_InstalledFanDatasheet' && <DatasheetComponent datasheet={payload as unknown as InstalledFanDatasheet} memoizedDatasheetId={undefined} blueprintId={undefined} paramsForInteractiveLocatedSplChart={
                    {housingDiameterMilimeter: 2300, pwlTot: 11.1, operation: "INDUCED_DRAFT"}
                }/>}


            </ErrorBoundary>
        </Card>)}
    </>;
};

export function LogsPayloadView(props: { payload: any }) {
    const logs = props.payload.logs as string[];
    const [expanded, setExpanded] = useState(false);
    return <>
        <h3>Logi obliczeniowe</h3>
        <div className={"text-gray-400"}>(użyj paska przewijania na boki, bądź scroll+shift w celu przewijania w poziomie)</div>
        <div style={{fontFamily: 'Consolas, monospace', fontSize: "11px", maxHeight: (expanded ? undefined : "200px"), overflowY: "hidden", overflowX: "scroll", whiteSpace: "nowrap"}}>
            {!expanded && <button className={"btn btn-sm btn-primary"} onClick={() => setExpanded(true)}>Rozwiń</button>}
            {logs.map(log => <div>{log}</div>)}
        </div>
    </>;
}


export function SelectionResultsPayloadView(props: { payload: any }) {
    const selectionResults = props.payload.selectionResults as string[];
    return <>
        <h3>Rezultaty doboru</h3>
        <div>
            {selectionResults.map(resultName => <div>{resultName}
                <hr/>
            </div>)}
        </div>
    </>;
}

export function MechanicalAssemblyResultsPayloadView(props: { payload: any }) {
    const selectionResults = props.payload.resultNames as string[];
    return <>
        <h3>Rezultaty złożenie mechanicznego</h3>
        <div>
            {selectionResults.map(resultName => <div>{resultName}
                <hr/>
            </div>)}
        </div>
    </>;
}


export function MechanicalPartListPayloadView(props: { payload: any }) {
    const entries = props.payload.partListEntries as PartListEntryJson[];
    return <>
        <h3>Lista materiałowa</h3>
        <div>
            <FanConstructionMaterialsTable assemblyPartList={{
                originalList: [],
                mergedList: entries
            }}/>
        </div>
    </>;
}

type PhysicalParameterJson = {
    name: string,
    stringifiedValue: string
}

export function PhysicalParametersPayloadView(props: { payload: any }) {
    const parameters = props.payload.parameters as PhysicalParameterJson[];
    return <>
        <h3>Obliczone parametry</h3>
        <div>
            <table className={"table table-row-bordered table-row-gray-100 align-middle gs-0 gy-0"}>
                <thead>
                <tr className="fw-bolder text-muted">
                    <th className="w-25">Parametr</th>
                    <th className="min-w-140px">Wartość</th>
                </tr>
                </thead>
                <tbody>
                {parameters.filter(p => p.stringifiedValue != 'N/A' && p.stringifiedValue.length > 0).map(parameter => <tr>
                    <td>{parameter.name}</td>
                    <td>{parameter.stringifiedValue}</td>
                </tr>)}
                </tbody>
            </table>
        </div>
    </>;
}

export function ErrorMessagePayloadView(props: { payload: any }) {
    const message = props.payload.message as string;
    return <>
        <h3>Końcowy komunikat błędu</h3>
        <div>
            <h3 className={"text-warning"}>{message}</h3>
        </div>
    </>;
}

export function SimpleJsonObjectPayloadView(props: { payload: any }) {
    const json = props.payload.objectJson as string;
    const title = props.payload.title as string;
    const subCode = props.payload.subCode as string;

    return <>
        <h3>{title} (w formacie JSON)</h3>
        <div className={"text-gray-500"}>Obiekt jest prezentowany w takiej postaci ze względu na łatwość implementacji i uniknięcie kosztu transformacji na bardziej czytelny dla użytkownika format, nadal wnosząc walory użyteczne. W przyszłości kształt tego bloku może zostać lepiej sformatowany.</div>
        <div className={"text-gray-400"}>{subCode}</div>
        <div>
            <code style={{display: "block"}} className={"mt-3"}>
                <pre>{json}</pre>
            </code>
        </div>
    </>;
}

export function MechanicalImpellerAssemblyPayloadView(props: { payload: any }) {
    const impellerAssemblyJson = props.payload.impellerAssemblyJson as string;
    const impellerAssebmly = JSON.parse(impellerAssemblyJson) as ImpellerAssemblyJson;

    // The original blueprint ID may be saved, but blueprints are SHORT LIVING! so any driveworks links are likely no longer valid
    impellerAssebmly.memoizedAsBlueprintId = null;
    return <>
        <h3>Zapisane złożenie mechaniczne</h3>
        <div>
            <AssemblyOverview assembly={impellerAssebmly} detailsExpandedByDefault={true} includeMaterialTable={true} displayActionsPanel={false} dimensionalImageDescriptors={[]} resonanceReport={null}/>
        </div>
    </>
        ;
}

export function BinaryPdfDatasheetPayload_v2_Downloader(props: { payload: any, eventId: string }) {
    return <>
        <h3>Oryginalna karta wetnylatora PDF (plik binarny wygenerowany przy wydruku)</h3>
        <a href={"/api/optisel-event/" + props.eventId + "/download-pdf-payload"} className={"btn btn-primary"}>Pobierz</a>
    </>
}

export function SelectionCriteriaPayload_v1_PresenterAndDownloader(props: { payload: SelectionRequestJson, eventId: string }) {

    var navigate = useNavigate();

    const searchByThoseCriteria = () => {
        sessionStorage.setItem("preferredSelectionCriteria", JSON.stringify(props.payload));
        navigate('/selection')
    }
    console.log("Found criteria for re-selecting fan: ", props.payload);
    return <>
        <h3>Ponowny dobór</h3>
        <p>Istnieje możliwość wywołania doboru wentylatorów z kryteriami celującymi w dobór tego wentylatora. Większość kryteriów zostanie ustalona na takie jak przy doborze, przy którym
            uzyskano dobór tego wentylatora, jednak wydajność i kąt łopat zostaną ustawione tak, by była największa szansa na uzyskanie doboru tego samego punktu pracy. Funkcjonalność ta może
            służyć przeprowadzaniu ponownych rewizji doboru.</p>
        <button className={"btn btn-secondary"} onClick={searchByThoseCriteria}>Szukaj nowego doboru z kryteriami tego rowiązania</button>

    </>
}