import React, {useState} from 'react';
import DynamicWebServiceDrivenForm from "../../platform/webform/DynamicWebServiceDrivenForm";
import {StandardLayout} from "../../layout/StandardLayout";
import {Card} from "../../components/Card";
import {NavLink} from "react-router-dom";
import {useObjectFromUrlResourceOrNull} from "../../platform/hooks/PlatformHooks";
import {deleteAllMechanicalData} from "./XlsDataService";


const ErrorPresenter = props => {
    const [expanded, setExpanded] = useState(false);
    return <div>
        <div className="mt-2" role="alert">
            {props.error.message}
            {!expanded && props.error.technicalDescription && <button className={"btn btn-link btn-sm ms-2 p-0"} onClick={() => setExpanded(true)}>szczegóły</button>}
        </div>
        {expanded && <pre className="text-muted">{props.error.technicalDescription}</pre>}
    </div>
}

const SingleFileResultPresenter = props => {
    return <div className="card card-dashed h-xl-100 p-4 mt-3">
        <div>
            {!props.result.success && <div className={"fs-6 badge badge-danger me-3 "}><i className={"fas fa-exclamation-triangle me-2 text-white"}/> Błąd przetwarzania</div>}
            {props.result.success && <div className={"fs-6 badge badge-success me-3 "}><i className={"fas fa-check-circle me-2 text-white"}/> Sukces</div>}
            <span className={"fs-6 fw-bolder"}>{props.result.fileName}</span>
            {props.result.resultMessages.map(msg => <p className={"mt-2 mb-0"}>{msg}</p>)}
        </div>
        {props.result.errors && props.result.errors.map(e => <ErrorPresenter error={e}/>)}
    </div>;
}


const deleteAllData = () =>{
    deleteAllMechanicalData().then(()=>{
        alert("Dane zostały usunięte");
    })
}

export function UploadXlsDataPage(props) {

    /*
        [ {
            "resultMessages" : [ ],
            "success" : false,
            "errors" : [ {
                "userFriendlyMessage" : "Nie udało się poprawnie zinterpretować pliku XLSX",
                "technicalDescription" : "Cannot find a defined blade profile with name 14"
            } ],
            "fileName" : "(14D)-4.xls"
        } ]
     */
    const [xlsDataLoadResult, setXlsDataLoadResult] = useState(null);
    const importersInfo = useObjectFromUrlResourceOrNull("/api/xls-data-load/importers-info");


    return <>
        <Card header={"Załaduj dane z plików XLSX"} subHeader={"Typ plików Excel zostanie wykryty automatycznie. W przypadku danych mechanicznych następuje pełne zastąpienie obecnego zestawu danych."} additionalClassNames={"mb-4"}>
            {!xlsDataLoadResult && <DynamicWebServiceDrivenForm dslUrl="/api/xls-data-load/form"
                                                                submissionUrl="/api/xls-data-load/submit"
                                                                submitLabel="Przetwórz"
                                                                successHandler={responseJson => setXlsDataLoadResult(responseJson)}/>}

            {xlsDataLoadResult && xlsDataLoadResult.map(res => <SingleFileResultPresenter result={res}/>)}
        </Card>

        <Card header={"Obsługiwane pliki XLS"} subHeader={"Opis możliwych do przesłania typów plików Excel wraz z opisem metody ich rozpoznania oraz opcjonalnie przykładowym plikiem do pobrania. Uwaga, ponieważ przykładowe pliki najczęściej używają osadzonych na stałe danych demo, wgranie tych plików dosłownie jak są może doprowadzić do duplikacji danych w programie (danych osadzonych demo z danymi wgranymi przez użytkownika). Przykładowe pliki demo zostały załadowane i zinterpretowane podczas uruchamiania aplikacji w celu utworzenia danych demonstracyjnych, co jest najlepszą gwarancją ich poprawności."}>
            {importersInfo !== null && importersInfo.map(importer=><div>
                <div className={"mb-10"}>
                    <strong>{importer.name}</strong>
                    <p>{importer.description}</p>
                    {importer.exampleFiles.length > 0 && <p>
                        Pobierz przykład: {importer.exampleFiles.map(example=> <a className={"btn btn-sm btn-light-info me-3"} href={"/api/xls-data-load/download-example/"+example.fileName}>{example.fileName}</a>)}
                    </p>}
                </div>
            </div>)}
        </Card>

        <Card header={"Usuwanie danych mechanicznych"} subHeader={"Usuwanie danych mechanicznych w celu wyczyszczenia danych zdezaktualizowanych po wdrożeniu nowych możliwości"} additionalClassNames={"mt-4"}>
            <p>
                Po wdrożeniu nowych wersji programu, może się okazać, że stare dane mechaniczne wywołują nieprawidłowości w obliczeniach, ponieważ brakuje im nowych wymaganych atrybutów (pochodzących z nowododoanych kolumn XLS),
                lub też dają wynik nieprawidłowy, spowodowany przyjęciem domyślnych wartości dla nowych atrybutów w ramach już istniejących obiektów mechanicznych.
            </p>
            <p>
                Klikając poniższy przycisk można usunąć wszystkie mechaniczne dane oprócz danych demo. Dane przepływowe zostaną zachowane.
            </p>
            <button className={"btn btn-danger btn-sm"} onClick={deleteAllData}><i className="fas fa-trash-alt"></i> Potwierdzam usunięcie danych mechanicznych</button>
        </Card>
    </>
}

UploadXlsDataPage.propTypes = {}

UploadXlsDataPage.defaultProps = {}
