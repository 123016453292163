import {useEffect, useState} from "react";
import AjaxService from "../platform/ajax/AjaxService";
import {ImpellerAssemblyJson, PartListEntryJson} from "../pages/mechanical/ImpellerAssemblyService";
import {Calculation, DimensionalImageDescriptor, FiCurve, FlowCurve, FlowPoint} from "./core";
import {SimulationRequestJson} from "./ApiJsonTypes";


export interface ParameterCatalogJson {
    fanName: string,
    profileDisplayName: string,
    impellerDiameter: number,
    housingDiameter: number,
    rotationsPerMinute: number,
    bladeEndSpeed: number,
    bladeAngle: number,
    workingPointFi: number,
    workingPointPsi: number,
    apiAirflowReserve: number,
    apiPressureReserve: number,
    workingPointInstallationTotalEfficiencyPercent: number
}

export interface FlowSimulationResultJson {
    psiCurve: FiCurve,
    installationStaticPressureCurve: FlowCurve,
    fanStaticPressureCurve: FlowCurve
    allAngleVariantsFanStaticPressureCurves: { angle: number, flowCurve: FlowCurve }[],
    allAngleVariantsInstallationPressureCurves: { angle: number, flowCurve: FlowCurve }[],
    installationStaticEfficiencyCurve: FlowCurve,
    fanTorqueCurve: FlowCurve,
    fanPowerCurve: FlowCurve,
    allAngleVariantsFanPowerCurves: { angle: number, flowCurve: FlowCurve }[],
    fanStaticPressureWorkingPoint: FlowPoint,
    fanPowerWorkingPoint: FlowPoint,
    apiReservesDefiningPoint: FlowPoint,
    angleFanBestEfficiencyPoint: FlowPoint,
    currentAngleReservesDefiningPoint: FlowPoint,
    installationStaticEfficiencyWorkingPoint: FlowPoint,
    installationStaticPressureWorkingPoint: FlowPoint
}

export interface ResonancesAtSpeedJson {
    rpm: number
    bladeResonanceFrequency: number
    fDynFrequency: number
    rfFrequency: number
    rfDoubledFrequency: number
    bpfFrequency: number
    lowerSafetyFactor: number
    upperSafetyFactor: number
    lowerSafetyFrequency: number
    upperSafetyFrequency: number
    estimated: boolean
}

export interface ResonanceCurveJson {
    bladeResonanceFrequency: number
    lowerSafetyFactor: number
    upperSafetyFactor: number
    rpms: number[]
    fDynFrequenciesForRpms: number[]
    rfFrequenciesForRpms: number[]
    rfDoubledFrequenciesForRpm: number[]
    bpfFrequenciesForRpms: number[]
    lowerSafetyFrequenciesForRpms: number[]
    upperSafetyFrequenciesForRpms: number[]
}

export interface MechanicalDesignJson {
    auditedImpellerAssembly: null | {
        impellerAssembly: ImpellerAssemblyJson,
        clutchOverloaded: boolean,
        verificationPassed: boolean
    },
    identifier: string
}

export interface PinLoadDetailsJson {
    vonMisesLoad: number,
    gravityForce_Fm: number,
    centrifugalForce_Fo: number,
    enginePowerForce_Fp: number,
    flowPressureForce_Fc: number,
    motorInducedTorque_Mp: number,
    flowPushInducedTorque_Mc: number,
    gravityInducedTorque_Mm: number
}

export interface MechanicalDesignCraftingResultJson {
    parts: PartListEntryJson[],
    dimensionalImageDescriptors: DimensionalImageDescriptor[]
    mechanicalDesign: MechanicalDesignJson,
    resonanceReport: {
        resonanceCurve: ResonanceCurveJson,
        workingPointResonances: ResonancesAtSpeedJson,
        estimated: boolean
    }
    pinLoadDetails: PinLoadDetailsJson
}

export interface SoundSimulationResultCatalogJson {
    workingPointPwl: number,
    workingPointPwlLin: number,
    pwlInOptimalConditions: number,
    acousticAmendmentCurve: FlowCurve,
    octavesPwls: OctavePwl[]
}

export interface OctavePwl {
    octaveFrequency: number,
    pwlDba: number,
    pwlLin: number,
    toleranceDBa: number
}

export interface GeneralSimulationResultJson { // TODO keep naming in-sync with java (...Json)
    flowSimulationResult: FlowSimulationResultJson,
    mechanicalDesignCraftingResult: MechanicalDesignCraftingResultJson | null,
    mechanicalSimulationFailureReason: string | null,
    parameterCatalog: ParameterCatalogJson,
    soundSimulationResultCatalog: SoundSimulationResultCatalogJson,
    calculations: Calculation[],
}

export interface SuccessfulSimulationResponse {
    result: GeneralSimulationResultJson,
    memoizedId: string
}

export interface FailedSimulationResponse {
    error: string,
    calculations: Calculation[],
}

export function isSuccessful(resp?: SimulationResponse): resp is SuccessfulSimulationResponse {
    return resp !== undefined && resp.hasOwnProperty("result");
}

export function isFailed(resp?: SimulationResponse): resp is FailedSimulationResponse {
    return resp !== undefined && resp.hasOwnProperty("error");
}

export type SimulationResponse = SuccessfulSimulationResponse | FailedSimulationResponse;

export function curveToPoints(curve: FlowCurve) {
    const points: { flow: number, value: number }[] = [];
    for (const idx in curve.discreteAirFlows) {
        points.push({flow: curve.discreteAirFlows[idx], value: curve.discreteValuesForDiscreteAirFlows[idx]});
    }
    return points;
}

let simId = ""; // TODO avoid global variable

export function useImpellerSimulation(simulationRequest: SimulationRequestJson | "incorrect-request"): {
    simulationResponse?: SimulationResponse,
    loading: boolean
} {

    const [simulationResponse, setSimulationResponse] = useState<SimulationResponse>();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (simulationRequest === "incorrect-request") {
            setSimulationResponse(undefined)
            return;
        }

        let abortController: AbortController | null = new AbortController();

        simId = "__simid_" + Math.round((Math.random() * 10000000));
        setLoading(true);
        // TODO cancel legacy pending request
        AjaxService.postJson(
            "/api/simulation",
            simulationRequest,
            undefined,
            simId,
            abortController).then(result => {
            if (simId === result.requestId) {
                setSimulationResponse(result.json as SimulationResponse);
                setLoading(false); // Note this line is a lot more meaningful that it may be usspected. Non-loading causes remount of chart, and thus whole D3 reinitialization.
                // Making setLoading(false) in finally could result to remount and recalculation of the chart before proper result (simId) returns, making chart to be presented obsoletely on quick criteria change
                // TODO address this better than removing loading false from finally
            } else {
                console.log("Dropped response " + result.requestId + " at it does not serve latest request", result);
            }
        }).catch(err => {

        }).finally(() => {
            abortController = null; // Request completed or failed, no need to hold reference to abort controller
        });

        return () => {
            if (abortController != null) {
                console.log("Aborting request due to unmount or criteria change");
                abortController.abort();
            }
        }
    }, [JSON.stringify(simulationRequest)]);

    return {
        simulationResponse,
        loading
    }
}

