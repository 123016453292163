import AjaxService from "../ajax/AjaxService";

export default class BrowserService {

    static async fetchResults(url, criteria) {
        const urlSearchParams = new URLSearchParams();
        for (const [attribute, value] of Object.entries(criteria)) {
            urlSearchParams.append(attribute, value);
        }

        const wsUrl = url + "?" + urlSearchParams.toString();
        console.log("Fetching results", wsUrl);
        const resp = await AjaxService.getJson(wsUrl);
        return resp.json;
    }
}