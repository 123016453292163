import React from 'react';
import {NavLink} from "react-router-dom";
import PropTypes from 'prop-types';

export function NavLinkMenuItem(props) {
    return <div className="menu-item">
        <NavLink exact={"true"} className="menu-link py-3" to={props.to}><i className="mdi mdi-square-edit-outline"></i>
            <span className="menu-title">{props.label}</span>
        </NavLink>
    </div>;
}

NavLinkMenuItem.propTypes = {
    to: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired
}

NavLinkMenuItem.defaultProps = {}
