import React, {useContext} from 'react';
import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom'
import PlatformErrorBoundary from "./platform/errorboundary/PlatformErrorBoundary";
import {ComponentsShowcasePage} from "./pages/CompoentsShowcasePage";
import {HomePage} from "./pages/HomePage";
import {ChartShowcasePage} from "./pages/ChartShowcasePage";
import {FanChartShowcasePage} from "./pages/FanChartShowcasePage";

import {BladeProfilesPage} from "./pages/profile/BladeProfilesPage"
import "../node_modules/@fortawesome/fontawesome-free/css/all.min.css";
import {CreateBladeProfilePage} from "./pages/profile/CreateBladeProfilePage";
import {UploadXlsDataPage} from "./pages/xls/UploadXlsDataPage";
import {AdjustableImpellersPage} from "./pages/impeller/AdjustableImpellersPage";
import {SimulationPage} from "pages/SimulationPage";
import {StandardListingPage} from "./pages/standard/StandardListingPage";
import {SimulationExamplesPage} from "./pages/SimulationExamplesPage";
import {MechanicalDesignResultPage} from "./pages/mechanical/MechanicalDesignResultPage";
import MechanicalVerificationPage from "./pages/mechanical/MechanicalVerificationPage";
import {BrowseMechanicalComponentsPage} from "./pages/mechanical/BrowseMechanicalComponentsPage";
import {useParams} from "react-router";
import MechanicalSettingsPage from "./pages/mechanical/MechanicalSettingsPage";
import {StandardLayout} from "./layout/StandardLayout";
import {ImpellerAssemblyPage} from "./pages/mechanical/ImpellerAssemblyPage";
import {EditBladeProfilePage} from "./pages/profile/EditBladeProfilePage";
import {LanguageContext, LanguageContextProvider} from "./platform/i18n/i18n";
import {TranslationRulesPage} from "./pages/i18n/TranslationRulesPage";
import {SetTranslationRulePage} from "./pages/i18n/SetTranslationRulePage";
import {AdjustableImpellerPage} from "./pages/impeller/AdjustableImpellerPage";
import {FanSelectionPage} from "./pages/selection/FanSelectionPage";
import AdminChangePasswordPage from "./platform/account/AdminChangePasswordPage";
import AdminChangeEntitlementsPage from "./platform/account/AdminChangeEntitlementsPage";
import RolesPage from "./platform/authorization/RolesPage";
import AddRolePage from "./platform/authorization/AddRolePage";
import EditRolePage from "./platform/authorization/EditRolePage";
import {MainLayoutWebFormPage} from "./platform/webform/WebFormPages";
import BrowseUserAccountPage from "./platform/account/BrowseUserAccountPage";
import AuthenticationContextProvider, {AuthenticationContext} from "./platform/authentication/AuthenticationContextProvider";
import {OptiselSignInPage} from "./components/OptiselSignInPage";
import WorkspaceContextProvider from "./platform/workspace/WorkspaceContextProvider";
import {OptiselEventHistoryPage} from "./pages/optiselevent/OptiselEventHistoryPage";
import {ShowOptiselEventPage} from "./pages/optiselevent/ShowOptiselEventPage";
import {HistoricalUploadsPage} from "./pages/historicalupload/HistoricalUploadsPage";
import {DatasheetPage} from "./pages/datasheet/DatasheetPage";
import {BrowseIssuesPage} from "./pages/issues/BrowseIssuesPage";
import {ShowIssuePage} from "./pages/issues/ShowIssuePage";
import {CreateIssuePage} from "./pages/issues/CreateIssuePage";
import {EditIssuePage} from "./pages/issues/EditIssuePage";
import {HotIssueContextProvider} from "./pages/issues/hotssue/HotIssueContextProvider";


function App() {

    function ApplicationRouting() {
        // Note this is used only to trigger rerender if context value changes.
        useContext(LanguageContext);


        const authenticationContext = useContext(AuthenticationContext);
        const authenticated = authenticationContext.isAuthenticated();
        const authenticationStatusKnown = authenticationContext.isAuthenticationStatusKnown();

        const BrowseMechanicalDataPageProxy = (props) => {
            // A proxy for truly remounting of whole mechanical data browsing page on router path change
            const {datatype} = useParams();
            return <BrowseMechanicalComponentsPage key={datatype} {...props} />;
        }


        if (!authenticationStatusKnown) {
            return <div className={"text-center mt-4"}>...</div>;
        } else {
            if (!authenticated) {
                console.log("Not authenticated")
                return <Routes>
                    <Route exact path='/sign-in' element={<OptiselSignInPage/>}/>
                    <Route path={"*"} element={<Navigate to="/sign-in"/>}/>
                </Routes>
            }
        }


        return <StandardLayout includeContentCard={false}>
            <Routes>
                <Route exact path='/' element={<HomePage/>}/>
                <Route exact path='/components-showcase' element={<ComponentsShowcasePage/>}/>
                <Route exact path='/chart-showcase' element={<ChartShowcasePage/>}/>
                <Route exact path='/fan-chart-showcase' element={<FanChartShowcasePage/>}/>
                <Route exact path='/blade-profiles' element={<BladeProfilesPage/>}/>
                <Route exact path='/adjustable-impellers' element={<AdjustableImpellersPage/>}/>
                <Route exact path='/adjustable-impeller/:id' element={<AdjustableImpellerPage/>}/>
                <Route exact path='/create-blade-profile' element={<CreateBladeProfilePage/>}/>
                <Route exact path='/edit-blade-profile/:id' element={<EditBladeProfilePage/>}/>
                <Route exact path='/simulation' element={<SimulationPage/>}/>
                <Route exact path='/datasheet' element={<DatasheetPage/>}/>
                <Route exact path='/standard-listing' element={<StandardListingPage/>}/>
                <Route exact path='/upload-xls-data' element={<UploadXlsDataPage/>}/>
                <Route exact path='/historical-uploads' element={<HistoricalUploadsPage/>}/>
                <Route exact path='/simulation-examples' element={<SimulationExamplesPage/>}/>
                <Route exact path='/mechanical-design/:id' element={<MechanicalDesignResultPage/>}/>
                <Route exact path='/selection' element={<FanSelectionPage/>}/>
                <Route exact path='/event-history' element={<OptiselEventHistoryPage/>}/>
                <Route exact path='/optisel-event/:id' element={<ShowOptiselEventPage/>}/>
                <Route exact path='/mechanical-verification' element={<MechanicalVerificationPage/>}/>
                {/* eslint-disable-next-line no-restricted-globals */}
                <Route exact path='/mechanical-component/:datatype' element={<BrowseMechanicalDataPageProxy/>}/>
                <Route exact path='/mechanical-settings' element={<MechanicalSettingsPage/>}/>
                <Route exact path='/impeller-assembly' element={<ImpellerAssemblyPage/>}/>
                <Route exact path='/translation-rules' element={<TranslationRulesPage/>}/>
                <Route exact path='/set-translation-rule' element={<SetTranslationRulePage/>}/>
                <Route exact path='/browse-issues' element={<BrowseIssuesPage/>}/>
                <Route exact path='/show-issue/:id' element={<ShowIssuePage/>}/>
                <Route exact path="/create-issue" element={<CreateIssuePage/>}/>
                <Route exact path="/edit-issue/:id" element={<EditIssuePage/>}/>


                <Route exact path='/create-account' element={<MainLayoutWebFormPage
                    submitLabel={"Utwórz"}
                    title={"Utwórz konto użytkownika"}
                    webFormContext={"create-account"}
                    successHandler={() => {
                        alert("Konto użytkownika zostało utworzone");
                        window.location = '/accounts';
                    }}/>}/>
                <Route exact path='/accounts/change-password/:id' element={<AdminChangePasswordPage/>}/>
                <Route exact path='/change-password' element={<MainLayoutWebFormPage
                    title={"Zmień hasło"}
                    submitLabel={"Ustaw nowe hasło"}
                    webFormContext={"self-service-change-password"}
                    successHandler={() => {
                        alert("Hasło zostało zmienione");
                        window.location = '/';
                    }}/>}/>
                <Route exact path='/accounts/change-entitlements/:id' element={<AdminChangeEntitlementsPage/>}/>
                <Route exact path='/roles' element={<RolesPage/>}/>
                <Route exact path='/roles/add' element={<AddRolePage/>}/>
                <Route exact path='/roles/edit/:id' element={<EditRolePage/>}/>
                <Route exact path='/accounts' element={<BrowseUserAccountPage/>}/>


            </Routes>
        </StandardLayout>
    }

    // return <BrowserRouter>
    //     <ApplicationRouting/>
    // </BrowserRouter>;


    return <PlatformErrorBoundary>
        <AuthenticationContextProvider>
            <WorkspaceContextProvider>
                <LanguageContextProvider>
                    <HotIssueContextProvider>
                        <BrowserRouter>
                            <ApplicationRouting/>
                        </BrowserRouter>
                    </HotIssueContextProvider>
                </LanguageContextProvider>
            </WorkspaceContextProvider>
        </AuthenticationContextProvider>
    </PlatformErrorBoundary>;

}

export default App;
