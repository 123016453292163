import {useCurrentUserPrivileges} from "../platform/hooks/PlatformHooks";

/**
 * Pay attention to duplication with backend OptiselPrivilegeProvider
 */
export type OptiselPrivilege =
    "USER_ACCOUNT_MANAGEMENT" |
    "AUTHORIZATION_MANAGEMENT" |
    "IMPELLER_SIMULATION" |
    "IMPELLER_ASSEMBLY" |
    "SELECTION_HISTORY" |
    "IMPELLER_DATA_ADMINISTRATION";

export function Protected(props: { privilege: OptiselPrivilege, children: JSX.Element }): JSX.Element {
    const privileges: string[] | "loading" = useCurrentUserPrivileges();

    if (privileges === "loading") {
        return <></>;
    }
    if (privileges.includes(props.privilege)) {
        return props.children;
    } else {
        return <span>🔒</span>;
    }
}