import React from 'react';
import {D3ChartAxis} from "../../platform/d3chart/D3ChartAxis";
import {D3ChartGridLines} from "../../platform/d3chart/D3ChartGridLines";
import {D3ChartLine} from "../../platform/d3chart/D3ChartLine";
import {curveToPoints} from "../../business/useImpellerSimulation";
import {D3ChartPoint} from "../../platform/d3chart/D3ChartPoint";
import {D3ChartScaleManager} from "../../platform/d3chart/D3ChartScaleManager";
import {D3ChartRenderable} from "../../platform/d3chart/D3Chart";
import {ResistanceCurveD3ChartRenderable} from "../../charting/ResistanceCurveD3ChartRenderable";
import {D3ChartComponent} from "../../platform/d3chart/D3ChartComponent";
import {MouseGuidesD3ChartRenderable} from "../../charting/MouseGuidesD3ChartRenderable";
import {HoverExplainerD3ChartRenderable} from "charting/HoverExplainerD3ChartRenderable";
import {FlowCurve, FlowPoint} from "../../business/core";

export function AcousticAmendmentCurveChart(props: {
    pressureCurve: FlowCurve,
    uncorrectedSoundPressureLevelCurve: FlowCurve,
    acousticAmendmentCurve: FlowCurve,
    workingPoint: FlowPoint,
}) {


    const chartSize = {width: 500, height: 350};
    const chartMargins = {left: 60, top: 0, right: 5, bottom: 40};

    const flowAxis = new D3ChartAxis([0.0, -1.0], [0.0, chartSize.width], "bottom", 15, "d3-axis-slight-domain-line", 0, 0, "Q [m³/s] [m³/h]");
    const flowAxis_m3h = new D3ChartAxis([0, -1], [0, chartSize.width], "bottom", 7, "d3-axis-nodomainline", 20);
    const pressureAxis = new D3ChartAxis([-1.0, 0.0], [0.0, chartSize.height - chartMargins.top - chartMargins.bottom], "left", 10, "d3-axis-nodomainline", 0, -30);
    const splAxis = new D3ChartAxis([-1.0, 0.0], [0.0, chartSize.height - chartMargins.top - chartMargins.bottom], "left", 10, "d3-axis-slight-domain-line", 0, 0, "[Pa] [-]");

    const verticalGridLines = new D3ChartGridLines(flowAxis.scale, flowAxis, "vertical", "d3-stroke-1 d3-opacity-05 d3-stroke-black");
    const horizontalGridLines = new D3ChartGridLines(splAxis.scale, pressureAxis, "horizontal", "d3-stroke-1 d3-opacity-05 d3-stroke-black");
    const horizontalGridLinesStrong = new D3ChartGridLines(splAxis.scale, splAxis, "horizontal", "d3-stroke-1 d3-opacity-10 d3-stroke-black");

    const pressureLine = new D3ChartLine<{ flow: number, value: number }>(
        flowAxis.scale,
        pressureAxis.scale,
        curveToPoints(props.pressureCurve),
        chPoint => chPoint.flow,
        chPoint => chPoint.value,
        "d3-stroke-1 d3-stroke-primary",
        "3,3");

    const uncorrectedSplLine = new D3ChartLine<{ flow: number, value: number }>(
        flowAxis.scale,
        splAxis.scale,
        curveToPoints(props.uncorrectedSoundPressureLevelCurve),
        chPoint => chPoint.flow,
        chPoint => chPoint.value,
        "d3-stroke-3 d3-stroke-sound-pressure-level",
        "5,5");

    const splLine = props.acousticAmendmentCurve === undefined ? [] : [new D3ChartLine<{ flow: number, value: number }>(
        flowAxis.scale,
        splAxis.scale,
        curveToPoints(props.acousticAmendmentCurve),
        chPoint => chPoint.flow,
        chPoint => chPoint.value,
        "d3-stroke-3 d3-stroke-sound-pressure-level")];
    if (splLine.length > 0) {
        splLine[0].setDescriber(p => p.flow.toFixed(2) + " ; " + p.value.toFixed(2) );
    }

    const workingPoint = new D3ChartPoint(flowAxis.scale, pressureAxis.scale, props.workingPoint.flow, props.workingPoint.value, 5, undefined, "d3-fill-primary");
    const splScaleManager = new D3ChartScaleManager([...splLine], 1.2);
    const scaleManager = new D3ChartScaleManager([pressureLine], 1.2);
    const scaleManager_m3h = new D3ChartScaleManager([pressureLine], 1.2 * 3600, flowAxis_m3h.scale, null);

    const renderables: D3ChartRenderable[] = [
        scaleManager, // Scale manager comes first, to recalculate axes scales!
        scaleManager_m3h,
        splScaleManager,
        flowAxis,
        flowAxis_m3h,
        pressureAxis,
        splAxis,
        verticalGridLines,
        horizontalGridLines,
        horizontalGridLinesStrong,
        new ResistanceCurveD3ChartRenderable(flowAxis.scale, pressureAxis.scale),
        new MouseGuidesD3ChartRenderable(flowAxis.scale, splAxis.scale),
        pressureLine,
        uncorrectedSplLine,
        ...splLine,
        workingPoint,
        new HoverExplainerD3ChartRenderable([...splLine])
    ];

    return <D3ChartComponent width={chartSize.width} height={chartSize.height} chartRenderables={renderables}
                             margins={chartMargins}/>
}
