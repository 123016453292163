import {D3ChartRenderable, D3ChartSize, D3GSelection, D3SvgSelection} from "./D3Chart";
import * as d3Scale from "d3-scale";
import * as d3Shape from "d3-shape";


export class D3ChartLine<Point> implements D3ChartRenderable {

    private g: D3GSelection | undefined;

    private rendered: boolean = false;
    private pointDescriber: ((p: Point) => string) | null = null;

    constructor(public scaleX: d3Scale.ScaleLinear<number, number, never>,
                public scaleY: d3Scale.ScaleLinear<number, number, never>,
                public points: Point[],
                public xExtractor: (point: Point) => number,
                public yExtractor: (point: Point) => number,
                public className: string = "d3-stroke-1 d3-stroke-black",
                public strokeDashArray: string = "") {
    }

    public static forArrayOfXYObjects(scaleX: d3Scale.ScaleLinear<number, number, never>,
                                      scaleY: d3Scale.ScaleLinear<number, number, never>,
                                      points: { x: number, y: number }[]) {
        return new D3ChartLine<{ x: number, y: number }>(scaleX, scaleY, points, p => p.x, p => p.y);
    }


    public setDescriber(describer: (p: Point) => string): void {
        this.pointDescriber = describer;
    }

    public describe(p: Point): string {
        if (this.pointDescriber === null) {
            throw new Error("Line has no describer but was asked to describe point");
        }
        return this.pointDescriber(p);
    }

    public canDescribePoints(): boolean {
        return this.pointDescriber !== null;
    }

    initialize(svgSelection: D3SvgSelection, rootG: D3GSelection, unClippedRootG: D3GSelection, size: D3ChartSize): void {
        this.g = rootG.append("g"); // TODO it is needed to add g? maybe path directly?
        this.g.append("path"); // actual line
    }

    onMouseMove(x: number, y: number): "repaint" | "norepaint" {
        return "norepaint";
    }

    render(): void {
        if (this.rendered) {
            return; // note line is STATIC, and thus after first render we can skip next renders.
        }

        const lineFunc = d3Shape.line<Point>()
            //.curve(d3Shape.curveCardinal) // TODO INTERPOLATION ACTIVE!!! RETHINK (but its only visual!)
            .x(point => this.scaleX(this.xExtractor(point)))
            .y(point => this.scaleY(this.yExtractor(point)));

        this.g?.select("path")
            .attr("class", this.className)
            .attr("stroke-dasharray", this.strokeDashArray)
            .attr("d", lineFunc(this.points))
        //.attr("clip-path", "url(#plot-clip-path)");

        this.rendered = true;
    }

    public calculateMaximumValues(): { xMax: number, yMax: number } {
        const points: any[] = this.points;
        const xValues: number[] = points.map(p => this.xExtractor(p));
        const xMax = xValues.reduce(function (a, b) {
            return Math.max(a, b);
        }, -Infinity);

        const yValues: number[] = points.map(p => this.yExtractor(p));
        const yMax = yValues.reduce(function (a, b) {
            return Math.max(a, b);
        }, -Infinity);
        return {xMax, yMax};
    }

}