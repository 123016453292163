import React from 'react';
import {D3ChartAxis} from "../../platform/d3chart/D3ChartAxis";
import {D3ChartGridLines} from "../../platform/d3chart/D3ChartGridLines";
import {OctavePwl} from "../../business/useImpellerSimulation";
import {D3ChartRenderable} from "../../platform/d3chart/D3Chart";
import {D3ChartComponent} from "../../platform/d3chart/D3ChartComponent";
import {D3BarChartAxis} from "../../platform/d3chart/D3BarChartAxis";
import {D3ChartBar} from "../../platform/d3chart/D3ChartBar";

export function OctavesSplBarChart({octavesSpl, totalValue, octaveSplExtractor, unitName}: {
    octavesSpl : OctavePwl[],
    totalValue: number | null,
    octaveSplExtractor : (spl : OctavePwl) => number,
    unitName: string
}) {

    const domainValues = octavesSpl.map(octave => octave.octaveFrequency.toString());

    const splValues : OctavePwl[] = [...octavesSpl];
    if ( totalValue != null ) {
        domainValues.push("∑"); // see below, there is a hack
        splValues.push({
            pwlLin: totalValue, // A small hack that will alwyas return total value, whatever total is used
            pwlDba: totalValue,
            octaveFrequency: -12340,
            toleranceDBa: -12340
        });
    }

    const chartSize = {width: 550, height: 150};
    const chartMargins = {left: 30, top: 0, right: 25, bottom: 30};

    let maxValue = octaveSplExtractor(octavesSpl.reduce((pre, cur) => (octaveSplExtractor(pre) > octaveSplExtractor(cur) ? pre : cur))) * 1.2;
    if ( totalValue != null && totalValue * 1.35 > maxValue) {
        maxValue = totalValue * 1.35;
    }
    const frequencyAxis = new D3BarChartAxis(domainValues, [0, chartSize.width-chartMargins.left-chartMargins.right], "bottom", "", 0, 0, "[Hz]");
    const splAxis = new D3ChartAxis([maxValue, 0], [0.0, chartSize.height - chartMargins.top - chartMargins.bottom], "left", 6, "", 0, 0, "["+unitName+"]");


    //const verticalGridLines = new D3ChartGridLines(frequencyAxis.scale, frequencyAxis, "vertical", "d3-stroke-1 d3-opacity-05 d3-stroke-black");
    const horizontalGridLines = new D3ChartGridLines(splAxis.scale, splAxis, "horizontal", "d3-stroke-1 d3-opacity-05 d3-stroke-black");
    //const horizontalGridLinesStrong = new D3ChartGridLines(efficiencyAxis.scale, "horizontal", 10, "d3-stroke-1 d3-opacity-10 d3-stroke-black");

    const bars = new D3ChartBar<OctavePwl>(
        frequencyAxis.scale,
        splAxis.scale,
        splValues,
        chPoint => chPoint.octaveFrequency === -12340 ? "∑" : chPoint.octaveFrequency.toString(), // a hack to use Całk word instead of frequency
        chPoint => chPoint.octaveFrequency === -12340 ? "±2" : "±"+chPoint.toleranceDBa.toFixed(0),
        chPoint => octaveSplExtractor(chPoint),
        "d3-stroke-1 d3-stroke-primary",
        (point: OctavePwl) => octaveSplExtractor(point).toFixed(1));

    const renderables: D3ChartRenderable[] = [
        // scaleManager, // Scale manager comes first, to recalculate axes scales!
        // scaleManager_m3h,
        frequencyAxis,
        splAxis,
        horizontalGridLines,
        bars,

    ];

    return <D3ChartComponent width={chartSize.width} height={chartSize.height} chartRenderables={renderables}
                             margins={chartMargins}/>
}
